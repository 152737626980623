import SearchStore from 'store/modules/Search/Search'
import store from 'definitions/store'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule(name, SearchStore)
            Init.setSearchPageUrl(name)
        }
    },
    setSearchPageUrl(name) {
        store.commit(`${name}/setSearchPageUrl`, searchPageUrl)
    }
}

export default Init
