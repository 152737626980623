import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    const MailchimpForm = () => import(/* webpackChunkName: "snippet-items-mailchimp" */ 'components/SnippetTemplate/Items/MailchimpEmbeddedForm.vue')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(MailchimpForm, {props: eval(this.elemId)})
    })
  }
}
