import CookieUtils from 'utils/CookieUtils'
import conf from 'conf'

function genereateVersionValue(specification) {
  let j = 0
  const table = []

  Object.keys(specification).forEach((item) => {
    for (j = 0; j < specification[`${item}`] * 10; j++) {
      table.push(item)
    }
  })

  return table[Math.floor(Math.random() * table.length)]
}

class ABTestUtils {
  static getTestVersion(cookieName) {
    const cookieLifetime = 21
    if (cookieName === '') {
      return 'A'
    }

    const cookie = CookieUtils.getCookie(cookieName)

    if (cookie === 'A' || cookie === 'B') {
      return cookie
    }

    const versionValue = genereateVersionValue({
      A: conf.eHorizonVersionA,
      B: conf.eHorizonVersionB
    })
    CookieUtils.setCookie(cookieName, versionValue, cookieLifetime)

    return versionValue
  }

  static isNewVersionEnabled() {
    return true
  }
}

export default ABTestUtils
