<template>
  <div
    v-clickoutside="closeFlyout"
    class="dropdown-wrap"
    :class="{ active: forceOpen || isActive }"
  >
    <div
      v-if="!forceOpen"
      ref='dropdownField'
      class="dropdown-field"
      :class="dropdownFieldClass"
      :tabindex="forceFocus ? 0 : ''"
      @click="toggleClass()"
      @keyup.enter="toggleClass()"
    >
      <template v-if="label"> {{ label }}</template>
      <template v-else>{{ dropDownText }}</template>
    </div>
    <ul class="dropdown-list">
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="{
          selected: item.code === selected,
          disabled: hasDisabledItems && item.disabled
        }"
      >
        <a
          href="javascript:void(0);"
          :class="getItemClass(item.code)"
          @click="selectItem(item.code)"
        >
          {{ $t(item.name) }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import Clickoutside from 'element-ui/src/utils/clickoutside'

  export default {
    directives: {
      Clickoutside
    },
    props: {
      translationPrefix: {
        type: String,
        default: ''
      },
      dropdownClass: {
        type: String,
        default: '',
        validator: function (value) {
          return ['flag-', ''].indexOf(value) !== -1
        }
      },
      items: {
        type: Array,
        default: () => []
      },
      selectedItem: {
        type: String,
        default: ''
      },
      itemClass: {
        type: String,
        default: '',
        validator: function (value) {
          return ['flag-', ''].indexOf(value) !== -1
        }
      },
      forceOpen: {
        type: Boolean,
        default: false
      },
      forceSelectFn: {
        type: Function,
        default: null
      },
      label: {
        type: String,
        required: false,
        default: null
      },
      hasDisabledItems: {
        type: Boolean,
        default: false
      },
      forceFocus: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isActive: false,
        selected: this.selectedItem
      }
    },
    computed: {
      dropDownText() {
        if (this.selected === '') return ' '
        return this.$t(this.translationPrefix + this.selected)
      },

      dropdownFieldClass() {
        return this.dropdownClass ? this.dropdownClass + this.selected.toLowerCase() : ''
      }
    },
    watch: {
      selectedItem() {
        this.selected = this.selectedItem
      }
    },
    mounted() {
      this.$refs.dropdownField?.focus()
    },
    methods: {
      getItemClasses(code) {
        return typeof this.itemClasses === 'function' ? this.itemClasses(code) : ''
      },
      getDropdownClass(code) {
        return typeof this.dropdownClass === 'function' ? this.dropdownClass(code) : ''
      },
      toggleClass() {
        this.isActive = !this.isActive
      },
      closeFlyout() {
        this.isActive = false
      },
      selectItem(item) {
        if (item.disabled) {
          return
        }

        if (this.forceSelectFn) {
          this.forceSelectFn(item)
        } else {
          this.selected = item
          this.closeFlyout()
          this.$emit('select', item)
        }
      },

      getItemClass(code) {
        return this.itemClass ? this.itemClass + code.toLowerCase() : ''
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';
  @import '~assets/styles/flags-sprite-img.scss';

  .dropdown-wrap {
    &.active {
      .dropdown-list {
        display: block;
      }
    }

    &.countries {
      .dropdown-field,
      .dropdown-list li a {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 26px;
          height: 18px;
          background-repeat: no-repeat;

          @include bp-retina {
            background-size: 26px auto;
          }
        }

        &.flag-zz:before {
          background-color: #eeeeee;
        }
      }
    }

    .dropdown-field {
      position: relative;
      cursor: pointer;

      @include outline-focus;

      &:after {
        @include icon-font-thin();
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        cursor: pointer;
      }
    }

    .dropdown-list {
      display: none;
      overflow-y: auto;
      list-style: none;

      li {
        line-height: 40px;

        a {
          display: block;
          text-decoration: none;

          @include outline-focus;
        }
      }
    }
  }
</style>
