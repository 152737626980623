var Step1 = /** @class */ (function () {
    function Step1() {
        this.tripType = 'return';
        this.flexible = false;
        this.errorMsg = {};
        this.validations = {};
    }
    return Step1;
}());
export default Step1;
