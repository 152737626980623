import GTM from 'services/AirBaltic/GTM'
import Vue from 'vue'

class Profile {
  static push(data) {
    GTM.doDataLayerPush(data)
  }

  static signUpAction() {
    this.push({
      event: 'ga4_generic_event',
      generic_event_name: 'signup_request',
      eventCategory: 'sent'
    })
  }

  static registerAction() {
    this.push({
      event: 'ga4_generic_event',
      generic_event_name: 'registration',
      eventCategory: 'sucessful_registration'
    })
  }

  static loginHeaderClickAction(page) {
    this.push({
      event: 'login_event',
      eventCategory: 'login_header_click',
      page_type: page,
      site_language: Vue.i18n.locale()
    })
  }

  static loginCreateProfileAction() {
    this.push({
      event: 'login_event',
      eventCategory: 'create_profile_login_link',
      page_type: 'create_profile_page',
      site_language: Vue.i18n.locale()
    })
  }

  static loginSuccessAction(loginType, page) {
    this.push({
      event: 'login_event',
      eventCategory: 'successful_login',
      eventAction: loginType,
      page_type: page,
      site_language: Vue.i18n.locale()
    })
  }

  static forgotPasswordAction(loginType, page) {
    this.push({
      event: 'login_event',
      eventCategory: 'forget_password',
      eventAction: loginType,
      page_type: page,
      site_language: Vue.i18n.locale()
    })
  }

  static failedLoginAction(loginType, page, error) {
    this.push({
      event: 'login_event',
      eventCategory: 'failed_login',
      eventAction: loginType,
      error_message: error,
      page_type: page,
      site_language: Vue.i18n.locale()
    })
  }

  static loginEventEmail() {
    this.push({
      event: 'loginEvent',
      eventCategory: 'All pages',
      eventAction: 'Login',
      eventLabel: 'Method: email; Page: ' + window.location.href,
      nonInteraction: true,
      authMethod: 'email'
    })
  }
}

export default Profile
