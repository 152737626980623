import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import storeInit from 'initializations/pages/AnyDestination'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        storeInit.init("anyDestination")

        const AnyDestination = () => import(/* webpackChunkName: "any-destination-page" */ 'containers/Pages/AnyDestination/AnyDestination')

        new Vue({
            el: "#" + this.elemId,
            store,
            render: h => h(AnyDestination)
        })
    }
}
