<template>
  <div class="footer-social-wrapper">
    <ul class="footer-social">
      <li
        v-for="item in social"
        :key="item.name"
      >
        <a
          :aria-label="`airBaltic ${item.name} page`"
          :href="item.link"
          :target="item.target"
          :rel="item.rel"
        >
          <v-lazy-image
            :src="item.icon"
            :alt="item.name"
            width="24"
            height="24"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import VLazyImage from 'v-lazy-image'

  export default {
    name: 'FooterSocial',
    components: {
      VLazyImage
    },
    props: {
      social: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .footer-social {
    &-wrapper {
      order: 0;

      @include bp-mf-tablet {
        width: 45%;
        margin: 0 auto;
      }

      @include bp-mf-desktop {
        order: 1;
        width: auto;
      }
    }

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @include bp-mf-desktop {
      gap: 16px;
    }

    a {
      display: flex;
      padding: 8px;

      &:focus {
        img {
          filter: brightness(0.8);
        }
      }
    }

    li {
      list-style: none;
    }
  }
</style>
