import Vue from 'vue'
import get from 'lodash-es/get'
import find from 'lodash-es/find'
import cloneDeep from 'lodash-es/cloneDeep'
import sortBy from 'lodash-es/sortBy'
import { validationMixin } from 'vuelidate'
import Passenger from 'models/Payments/Passenger'
import FlightUtils from 'utils/FlightUtils'
import moment from 'moment'

const PassengerStore = function () {
  const passenger = new Passenger()
  const defaultState = {
    formErrors: passenger.errorMsg,
    passenger: passenger,
    passengerExcluded: [],
    protectedBaggage: false
  }

  const validator = new Vue({
    mixins: [validationMixin],
    computed: {
      state() {
        return defaultState
      }
    },
    validations() {
      const validation = cloneDeep(passenger.validation)
      const excluded = Object.values(module.state.passengerExcluded)

      excluded.forEach((exclude) => {
        delete validation.passenger[`${exclude}`]
      })

      return { state: validation }
    }
  })

  const module = {
    namespaced: true,
    state: defaultState,
    actions: {
      clearPassenger({ commit }) {
        commit('clearPassenger')
        validator.$v.state.passenger.$reset()
      },
      setPassengerData({ state, commit, dispatch, rootState }, data) {
        let flights = get(data, 'segments', [])
        const isCharterFlight = FlightUtils.isCharterFlight(flights)
        commit('payment/setIsCharter', isCharterFlight,  {
          root: true
        })

        if (
          !['invoices', 'booking_cancellation'].includes(
            rootState.payment.generic.activeItem.key
          )
        ) {
          flights = flights.filter((flight) => {
            return moment().diff(moment(flight.departureDate).subtract(12, 'hours')) < 0
          })
        }

        const passengers = get(data, 'passengers', [])
        const passenger = find(passengers, [
          'surname',
          rootState.payment.validation.form.surname
        ])

        if (flights.length > 0) {
          dispatch('payment/segments/convertAndSetSegments', flights, { root: true })
          dispatch(
            'payment/flightDetails/setFlightInfo',
            rootState.payment.segments.segments['segment_0'],
            { root: true }
          )
          dispatch('setSegments', flights)
        }
        if (typeof passenger !== 'undefined' && !isCharterFlight) {
          commit('setPassenger', new Passenger(passenger))

          commit('payment/contact/setTitle', state.passenger.title, { root: true })
          commit('payment/contact/setFirstName', state.passenger.firstName.trim(), {
            root: true
          })
          commit('payment/contact/setLastName', state.passenger.lastName.trim(), {
            root: true
          })
        }
      },
      validatePassenger() {
        return new Promise((resolve, reject) => {
          const vState = validator.$v.state.passenger
          vState.$touch()

          !vState.$invalid ? resolve(true) : reject('validatePassenger')
        })
      },
      validatePassengerSegment({ getters }, segment) {
        return new Promise((resolve, reject) => {
          const vState = getters[`${segment.namespace}/$validator`]
          vState.$touch()

          !vState.$invalid ? resolve(true) : reject('validatePassengerSegment')
        })
      },

      setSegments({ commit }, flights) {
        const segments = sortBy(flights, ['departureDate']).map((segment, key) => ({
          index: key,
          namespace: `segment_${key}`,
          value: segment
        }))

        commit('payment/passengers/setSegments', segments, { root: true })
      }
    },
    mutations: {
      clearPassenger(state) {
        state.passenger = new Passenger()
      },
      setPassenger(state, value) {
        state.passenger = value
      },
      setFirstName(state, value) {
        state.passenger.firstName = value
      },
      setLastName(state, value) {
        state.passenger.lastName = value
      },
      setTitle(state, value) {
        state.passenger.title = value
      },
      setCheckedBaggage(state, value) {
        state.passenger.checkedBaggage = value
      },
      setHeavyBaggage(state, value) {
        state.passenger.heavyBaggage = value
      },
      setExtras(state, value) {
        state.passenger.extras = value
      },
      setExcludedPassengerFields(state, value) {
        state.passengerExcluded = value
      },
      setProtectedBaggage(state, value) {
        state.passenger.protectedBaggage = value
      }
    },
    getters: {
      $validator() {
        return validator.$v.state.passenger
      },
      $passenger() {
        return Object.assign({}, validator.$v.state.passenger)
      },
      passengerValidationFormErrors(state) {
        return state.formErrors
      }
    }
  }

  return module
}

export default PassengerStore
