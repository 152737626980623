import isEmpty from 'lodash-es/isEmpty';
import has from 'lodash-es/has';
export default function notEmpty(value) {
    if (typeof value === 'object') {
        if (has(value, 'value')) {
            return !isEmpty(value.value);
        }
        else {
            return !isEmpty(value);
        }
    }
    else if (value == null || value.length === 0) {
        return false;
    }
    return true;
}
