import AssistanceService from 'services/AirBaltic/Forms/Assistance'
import GuidanceRequest from 'store/modules/Forms/Assistance/Sections/GuidanceRequest'
import AssistanceModel from 'models/Forms/Assistance/AssistanceForm'

const Guidance = {
  namespaced: true,
  state: Object.assign({}, new AssistanceModel()),
  modules: {
    request: GuidanceRequest
  },
  actions: {
    validateAndSubmit({ dispatch, rootGetters, rootState }) {
      const namespace = rootState.main.formNamespace
      const activeItem = rootGetters[`${namespace}/activeItem`].key

      const rules = [
        dispatch(`${namespace}/${activeItem}/request/validateRequest`, null, {
          root: true
        }),
        dispatch(`${namespace}/flights/validateFlights`, null, { root: true }),
        dispatch(`${namespace}/contact/validateContacts`, null, { root: true }),
        dispatch(`${namespace}/company/validateCompany`, null, { root: true }),
        dispatch(`${namespace}/files/touch`, null, { root: true }),
        dispatch(`${namespace}/consent/validateConsent`, null, { root: true })
      ]

      return dispatch(`${namespace}/generic/validateForm`, rules, { root: true })
        .then(() => dispatch('getDataAndSubmitForm'))
        .catch((err) => Promise.reject(err))
    },
    getDataAndSubmitForm({ dispatch }) {
      return dispatch('getData')
        .then((data) => dispatch('submitForm', data))
        .catch((err) => Promise.reject({ type: 'submit', msg: err }))
    },
    submitForm({ dispatch, rootState, rootGetters }, data) {
      const namespace = rootState.main.formNamespace
      const uploadedFiles = rootState[namespace].files?.files || []

      return new AssistanceService()
        .submitGuidanceForm(data, uploadedFiles)
        .then((response) => {
          return dispatch(`${namespace}/generic/clearForms`, null, { root: true })
            .then(() =>
              dispatch(`${namespace}/validation/clearForm`, null, { root: true })
            )
            .then(() => Promise.resolve(response))
            .catch((err) => Promise.reject(err))
        })
        .catch((err) => Promise.reject({ type: 'submit', msg: err }))
    },
    getData({ dispatch }) {
      return dispatch('collectDefaultFormData')
        .then((data) => Promise.resolve(data))
        .catch((err) => Promise.reject(err))
    },
    collectDefaultFormData({ dispatch, rootState, rootGetters }) {
      const namespace = rootState.main.formNamespace
      const activeItem = rootGetters[`${namespace}/activeItem`].key

      const generic = rootState[`${namespace}`]
      const flightDetails = generic.flights
      const contact = generic.contact.form
      const request = generic[`${activeItem}`].request.form

      const formData = {
        impairmentType: request.impairmentType,
        guideDog: request.accompaniedByGuideDog === 'Yes',
        message: request.requestMessage,
        pnr: generic.validation.form.pnr
      }

      dispatch(`${namespace}/generic/getFlightList`, flightDetails, { root: true }).then(
        (data) => (formData.flights = data)
      )

      dispatch(`${namespace}/generic/getContactData`, contact, { root: true }).then(
        (data) => {
          formData.contact = data
          formData.contact.language = rootState.main.selectedLang.toUpperCase()
        }
      )

      return formData
    }
  }
}

export default Guidance
