import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { numeric, between, required, maxValue, minValue } from 'vuelidate/lib/validators'
import isNaN from 'lodash-es/isNaN'

const errorMsg = {
  adults: {
    required: 'groupBooking.step1.paxSelector.error.adults.required',
    minValue: 'groupBooking.step1.paxSelector.error.adults.minValue',
    maxValue: 'groupBooking.step1.paxSelector.error.adults.maxvalue',
    numeric: 'groupBooking.step1.paxSelector.error.adults.numeric'
  },
  children: {
    between: 'groupBooking.step1.paxSelector.error.children.between',
    numeric: 'groupBooking.step1.paxSelector.error.children.numeric'
  },
  infants: {
    between: 'groupBooking.step1.paxSelector.error.infants.between',
    numeric: 'groupBooking.step1.paxSelector.error.infants.numeric',
    maxValue: 'groupBooking.step1.paxSelector.error.infants.maxValue'
  }
}

const defaultState = {
  adults: null,
  children: null,
  infants: null,
  dropdown: {
    open: false
  },
  showChildBlock: false,
  config: {
    minAdults: 6,
    maxAdults: 999,
    minChildren: 0,
    maxChildren: 999,
    minInfants: 0,
    maxInfants: 999
  },
  formErrors: errorMsg
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    const parsedChdCount = parseInt(PaxSelector.state.children, 10)
    const chdCount = isNaN(parsedChdCount) ? 0 : parsedChdCount

    const validations = {
      adults: {
        minValue: minValue(defaultState.config.minAdults - chdCount),
        maxValue: defaultState.config.maxAdults,
        numeric,
        required
      },
      children: {
        between: between(
          defaultState.config.minChildren,
          defaultState.config.maxChildren
        ),
        numeric
      },
      infants: {
        maxValue: maxValue(PaxSelector.state.adults),
        between: between(defaultState.config.minInfants, defaultState.config.maxInfants),
        numeric
      }
    }

    return {
      state: validations
    }
  }
})

const PaxSelector = {
  state: defaultState,
  getters: {
    $v() {
      return Object.assign({}, validator.$v.state)
    },
    $paxSelector() {
      return Object.assign({}, validator.$v)
    },
    errors(state) {
      return state.formErrors
    }
  },
  actions: {
    validatePax() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('pax')
      })
    }
  },
  mutations: {
    showChildBlock(state, value) {
      state.showChildBlock = value
    },
    showPaxDropdown(state) {
      state.dropdown.open = true
    },
    hidePaxDropdown(state) {
      state.dropdown.open = false
    },
    setAdults(state, value) {
      state.adults = value
    },
    setChildren(state, value) {
      state.children = value
    },
    setInfants(state, value) {
      state.infants = value
    }
  },
  namespaced: true
}

export default PaxSelector
