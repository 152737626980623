import GTM from 'services/AirBaltic/GTM'
import Vue from 'vue'
import moment from 'moment'

class FSF {
  static push(data) {
    GTM.doDataLayerPush(data)
  }

  static fsfError(group, text) {
    this.push({
      event: 'GAevent',
      eventCategory: 'Error',
      eventAction: 'P:index; Cat:FSF; G: ' + group + '; Lang: ' + Vue.i18n.locale(),
      eventLabel: text,
      nonInteraction: 1
    })
  }

  static onDestinSelected(originCode, destinCode) {
    this.push({
      event: 'GAevent',
      eventCategory: 'AB tests',
      eventAction: '[UC1] FSF Top destinations',
      eventLabel:
        'UC1; A:click; Orig:' +
        originCode +
        '; Dest:' +
        destinCode +
        '; Hardc:0; Lang: ' +
        Vue.i18n.locale(),
      nonInteraction: false
    })
  }

  static destinTypeaheadResultsShow(originCode, destinations) {
    this.push({
      event: 'GAevent',
      eventCategory: 'AB tests',
      eventAction: '[UC1] FSF Top destinations',
      eventLabel: `UC1; A:view; G:test; Orig:${originCode}; Dest:[${destinations}]; Lang: ${Vue.i18n.locale()}`,
      nonInteraction: true
    })
  }

  static destinTypeaheadNoResultsShow(originCode) {
    this.push({
      event: 'GAevent',
      eventCategory: 'AB tests',
      eventAction: '[UC1] FSF Top destinations',
      eventLabel:
        'UC1; A:view; G:control; Orig:' + originCode + '; Lang: ' + Vue.i18n.locale(),
      nonInteraction: true
    })
  }

  static getPaxDataForGTM(data) {
    if (typeof data.adults !== 'number') {
      data = data.paxSelector
    }
    let paxData = {
      ...data
    }
    paxData.total = paxData.adults + paxData.children + paxData.infants
    return paxData
  }

  static getTripType(data) {
    return [2, 'return'].includes(data.tripType) ? 'RT' : 'OW'
  }

  static getVoucher(data) {
    let voucher = ''
    if (data.promoCodes) {
      let arr = []
      data.promoCodes.promoCodes.map((item) => arr.push(item.code))
      voucher = arr.join(',')
    }
    return voucher
  }

  static flightSearch(eventCategory, formData) {
    let origin = formData.fromCode
    let destin = formData.toCode
    let tripType = this.getTripType(formData)
    let month = null

    if (eventCategory === 'flight_search' || eventCategory === 'any_destination') {
      origin = formData.origin.value.code
      destin = formData.destin.value.code
    }

    if (eventCategory === 'flight_search_calendar') {
      month = formData.month.replace('-', '_')
    }

    const paxData = this.getPaxDataForGTM(formData)

    this.push({
      event: 'ga4_generic_event',
      generic_event_name: 'fsf_index_page',
      // if it was click on the button Select dates, than eventCategory = flight_search,
      // if it was click on Any Destination, than eventCategory = any_destination,
      // if it was click on any month in calendar, then eventCategory = flight_search_calendar,
      // if visitor chose recent search, that eventCategory = recent_search
      eventCategory: eventCategory,
      'passenger.adultCount': paxData.adults, // selected number of adults
      'passenger.youngAdultCount': paxData.children, // selected number of children
      'passenger.childCount': paxData.infants, // selected number of infants
      'passenger.count': paxData.total, // total number of passengers, adults + children + infants
      origin_airport_code: origin, // airport of departure
      destination_airport_code: destin, // airport of destination (NULL in case of Any destination
      'dimensions.trip_type': tripType, // OW – for one way and RT – for round
      tripvoucher: this.getVoucher(formData), // if possible, then here should be the voucher code, which was entered into the voucher block - listed all separated by coma
      month_and_year: month // if eventCategory – “flight_search_calendar” – that here we should have month (number) and year on which person clicked, in other case return "null"
    })
  }

  static flightSearchOverlay(eventCategory, formData) {
    let origin = formData.fromCode
    let destin = formData.toCode
    let tripType = this.getTripType(formData)
    let selectedCategory = formData.destin.selectedCategory
    let selectedSubCategory = formData.destin.selectedSubCategoryLabel
    let monthYear = null
    let departureDate = null
    let returnDate = null

    if (eventCategory === 'flight_search' || eventCategory === 'any_destination') {
      origin = formData.origin.value.code
      destin = formData.destin.value.code
    }

    if (formData.calendar.departureDate) {
      eventCategory = 'flight_search_calendar'
      monthYear = moment(formData.calendar.departureDate, 'YYYY-MM-DD').format('MM_YYYY')
      departureDate = moment(formData.calendar.departureDate, 'YYYY-MM-DD').format('DDMMYYYY')
      returnDate = moment(formData.calendar.returnDate, 'YYYY-MM-DD').format('DDMMYYYY')
    }

    const paxData = this.getPaxDataForGTM(formData)
    this.push({
      event: 'fsf_index_event_new',
      generic_event_name: 'fsf_submit',
      eventCategory: eventCategory,
      eventLabel: selectedCategory,
      eventAction: selectedSubCategory,
      passenger_adultCount: paxData.adults,
      passenger_youngAdultCount: paxData.children,
      passenger_childCount: paxData.infants,
      passenger_count: paxData.total,
      origin_airport_code: origin,
      destination_airport_code: destin,
      trip_type: tripType,
      tripvoucher: this.getVoucher(formData),
      month_and_year: monthYear,
      departure_date: departureDate,
      return_date: returnDate,
    })
  }

  static flightHotelSearch(formData) {
    this.push({
      event: 'fsf_index_event_new',
      generic_event_name: 'fsf_hotel_flight',
      eventCategory: 'airbaltic_holidays',
      eventLabel: formData.country,
      eventAction: formData.city,
      passenger_adultCount: formData.adultCount,
      passenger_childCount: formData.childCount,
      passenger_count: formData.passengerCount,
      origin_airport_code: formData.origin,
      destination_airport_code: formData.destination,
      departure_date: formData.departure,
      return_date: formData.return
    })
  }

  static labelsSeen(position) {
    this.push({
      event: 'fsf_index_event_new',
      generic_event_name: 'fsf_labels',
      eventCategory: 'destination_labels_seen',
      eventLabel: position
    })
  }

  static selectedLabel(label, position) {
    this.push({
      event: 'fsf_index_event_new',
      generic_event_name: 'fsf_labels',
      eventCategory: 'destination_labels_click',
      eventAction: label,
      eventLabel: position
    })
  }

  static selectedSubLabel(subCat, label, position) {
    this.push({
      event: 'fsf_index_event_new',
      generic_event_name: 'fsf_labels_2nd_level',
      eventCategory: subCat,
      eventAction: position,
      eventLabel: label
    })
  }

  static fsfRecentSearch(data) {
    const totalPassengers = data.adults + data.children + data.infants
    const tripType = data.tripType === 1 ? 'OW' : 'RT'

    this.push({
      event: "fsf_index_event_new",
      generic_event_name: "fsf_submit",
      eventCategory: "recent_search",
      eventLabel: '',
      eventAction: '',
      passenger_adultCount: data.adults,
      passenger_youngAdultCount: data.children,
      passenger_childCount: data.infants,
      passenger_count: totalPassengers,
      origin_airport_code: data.fromCode,
      destination_airport_code: data.toCode,
      trip_type: tripType,
      tripvoucher: ''
    })
  }

  static overlayOpened(data) {
    const tripType = data.tripType === 1 ? 'OW' : 'RT'

    this.push({
      event: "fsf_index_event_new",
      generic_event_name: "fsf_overlay_opening",
      eventCategory: "flight_search",
      origin_airport_code: data.origin.value.code,
      destination_airport_code: data.destin.value.code,
      trip_type: tripType
    })
  }
}

export default FSF
