import AbstractView from 'abstract'
import Vue from 'vue'
import PartnershipInit from 'initializations/pages/Partnership'
import store from 'definitions/store'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId)
  }

  init() {
    PartnershipInit.init('partnership')

    const Partnership = () =>
      import(
        /* webpackChunkName: "partnership-page" */ 'containers/Pages/Partnership/Intro'
      )
    new Vue({
      el: '#' + this.elemId,
      store,
      render: (h) => h(Partnership)
    })
  }
}
