import BookingsStore from 'store/modules/Pages/ProfilePages/MyBookings'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, BookingsStore)

      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    store.commit('main/setPageType', 'profile')

    store.dispatch(`${name}/initBookingsStore`)
  }
}

export default Init
