import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { minLength } from 'vuelidate/lib/validators'
import {
  isDateValidOrEmptyWithDashes,
  alphaWithSpaces,
  isPaxSixteen
} from 'utils/validators'
import AxiosUtils from 'utils/AxiosUtils'
import divolteProfile from 'services/AirBaltic/Divolte/Profile'

const errorMsg = {
  form: {
    firstName: {
      alphaWithSpaces: 'reusable.validation.invalidCharacters',
      minLength: 'editProfilePage.form.validation.firstNameMinLength'
    },
    familyName: {
      alphaWithSpaces: 'reusable.validation.invalidCharacters',
      minLength: 'editProfilePage.form.validation.lastNameMinLength'
    }
  },
  birthDate: {
    date: {
      isDateValidOrEmptyWithDashes: 'editProfilePage.form.validation.dateInvalid',
      isPaxSixteen: 'editProfilePage.form.validation.paxNotSixteen'
    }
  }
}

const defaultState = {
  pageTitle: '',
  form: {
    title: '',
    firstName: '',
    familyName: '',
    birthMonth: '',
    birthYear: '',
    birthDate: '',
    dateOfBirth: ''
  },
  birthDate: {
    day: '',
    month: '',
    year: '',
    date: ''
  },
  formErrors: errorMsg,
  createdDate: {},
  lastUpdatedDate: {}
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    return {
      state: {
        form: {
          title: {},
          firstName: {
            alphaWithSpaces,
            minLength: minLength(2)
          },
          familyName: {
            alphaWithSpaces,
            minLength: minLength(2)
          }
        },
        birthDate: {
          date: {
            isPaxSixteen,
            isDateValidOrEmptyWithDashes
          }
        }
      }
    }
  }
})

const EditProfileStore = {
  state: defaultState,
  mutations: {
    setPageTitle(state, value) {
      state.pageTitle = value
    },
    setPaxTitle(state, value) {
      if (state.form.title === value) {
        state.form.title = ''
      } else {
        state.form.title = value
      }
    },
    setFirstName(state, value) {
      state.form.firstName = value
    },
    setFamilyname(state, value) {
      state.form.familyName = value
    },
    setBirthDay(state, value) {
      state.birthDate.day = value
    },
    setBirthMonth(state, value) {
      state.birthDate.month = value
    },
    setBirthYear(state, value) {
      state.birthDate.year = value
    },
    setFullDate(state, value) {
      state.birthDate.date = value
    },
    setCreatedDate(state, date) {
      state.createdDate = date
    },
    setLastUpdatedDate(state, date) {
      state.lastUpdatedDate = date
    }
  },

  actions: {
    setBirthDay({ commit }, data) {
      commit('setBirthDay', data)
    },
    setBirthMonth({ commit }, data) {
      commit('setBirthMonth', data)
    },
    setBirthYear({ commit }, data) {
      commit('setBirthYear', data)
    },
    validateDate({ commit, state }) {
      return new Promise((resolve) => {
        const day = state.birthDate.day
        const month = state.birthDate.month
        const year = state.birthDate.year

        let date = `${year}-${month}-${day}`
        if (!day && !month && !year) {
          date = ''
        }

        commit('setFullDate', date)
        resolve()
      })
    },

    touchEverything() {
      validator.$v.state.form.$touch()
    },

    validateForm({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('validateDate').then(() => {
          const vState = validator.$v.state.form
          dispatch('touchEverything')

          if (!vState.$invalid && !validator.$v.state.birthDate.$invalid) {
            dispatch('submitForm')
              .then((response) => {
                resolve(response)
              })
              .catch(() => {
                reject(new Error('ajax'))
              })
          } else {
            reject(new Error())
          }
        })
      })
    },

    submitForm({ state, rootState }) {
      return new Promise((resolve, reject) => {
        const data = {
          ...state.form,
          birthDate: state.birthDate.day,
          birthMonth: state.birthDate.month,
          birthYear: state.birthDate.year,
          dateOfBirth: state.birthDate.date
        }

        const obj = {
          controller: 'Ajax_Vue_Pax_Edit',
          action: 'update',
          form: JSON.stringify(data)
        }

        AxiosUtils.post(obj, '/ajax', true)
          .then((response) => {
            divolteProfile.profileUpdate({ ...rootState.main.user.userData, ...data })
            resolve(response.data.status)
          })
          .catch(() => {
            reject(false)
          })
      })
    }
  },

  getters: {
    $v() {
      return Object.assign({}, validator.$v.state)
    }
  },

  namespaced: true
}

export default EditProfileStore
