const SiloPageContent = {
  state: {
    pageTitle: '',
    desktopImg: '',
    tabletImg: '',
    mobileImg: '',
    items: []
  },

  mutations: {
    setPageTitle (state, value) {
      state.pageTitle = value
    },

    setDesktopImg (state, value) {
      state.desktopImg = value
    },

    setTabletImg (state, value) {
      state.tabletImg = value
    },

    setMobileImg (state, value) {
      state.mobileImg = value
    },

    setItems (state, value) {
      state.items = value
    }
  },

  namespaced: true
}

export default SiloPageContent
