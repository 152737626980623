import IndexPageStore from 'store/modules/Pages/IndexPage'
import store from 'definitions/store'
import CookieUtils from 'utils/CookieUtils'
import BBBService from 'services/AirBaltic/BBB'
import TripXStore from 'store/modules/Pages/TripX/TripX'
import FsfStore from 'store/modules/eHorizon/SearchForm/Fsf'

const Init = {
  init() {
    if (!store['indexPage']) {
      store.registerModule('indexPage', IndexPageStore)
      store.registerModule('tripX', TripXStore)
      store.registerModule('fsf', FsfStore)
      Init.initPageType(store)
      Init.initPageSections(store)
      Init.initInspirationalBanner(store)
      Init.initBestBuyBanners(store)
      Init.initCardSection(store)
      Init.initServiceSection(store)
      Init.initBlogSection(store)
      Init.initAdSection(store)
      Init.initWhyUsAdSection(store)
      Init.initLabelBannersSection(store)
      Init.initEveryMundoCardsSection(store)
      Init.initSubscribeSection(store)
    }
  },

  initPageSections() {
    if (typeof pageSections !== 'undefined') {
      store.commit('indexPage/setSections', pageSections)
    }

    if (typeof contentData_data_countries !== 'undefined') {
      store.commit(`indexPage/setCountries`, contentData_data_countries._children, {
        root: true
      })
    }
  },

  initInspirationalBanner(store) {
    if (typeof inspirationalBanner !== 'undefined') {
      store.commit('indexPage/setInspirationalBanner', inspirationalBanner)
    }
  },

  initBestBuyBanners(store) {
    if (typeof origDestLabels !== 'undefined') {
      store.commit('indexPage/bestBuyBanners/setLabels', origDestLabels)
    }

    if (typeof enableBBB !== 'undefined' && enableBBB) {
      store.commit('indexPage/bestBuyBanners/setDestinationPageLink', destinationsLink)
      if (typeof everyMundoLink !== 'undefined') {
        store.commit('indexPage/bestBuyBanners/setEveryMundoLink', everyMundoLink)
      }

      const bbbService = new BBBService()
      bbbService
        .setPosList(store.state.indexPage.bestBuyBanners.listName)
        .then((data) => {
          store.dispatch('indexPage/bestBuyBanners/setBestBuyCountries', data)
          let country =
            typeof pos !== 'undefined' && pos.length > 0
              ? pos
              : store.state.main.selectedCountry
          if (country && !data.includes(country)) {
            country = 'ZZ'
          }

          bbbService
            .setSinglePos(country, store.state.indexPage.bestBuyBanners.listName)
            .then((response) =>
              store.commit('indexPage/bestBuyBanners/setBestBuyBanners', response)
            )
            .catch(() => store.commit('indexPage/bestBuyBanners/setBestBuyBanners', []))
        })
        .catch(() => store.dispatch('indexPage/bestBuyBanners/setBestBuyCountries', []))
    }
  },

  initCardSection(store) {
    if (typeof cards !== 'undefined') {
      store.commit('indexPage/setCardSection', cards)
    }
  },

  initBlogSection(store) {
    if (typeof blog !== 'undefined') {
      store.commit('indexPage/setBlogSection', blog)
    }
  },

  initServiceSection(store) {
    if (typeof services !== 'undefined') {
      store.commit('indexPage/setServiceSection', services)
    }
  },

  initLabelBannersSection(store) {
    if (typeof labelBanners !== 'undefined') {
      store.commit('indexPage/setLabelBannerSection', labelBanners)
    }
  },

  initAdSection(store) {
    if (typeof ad !== 'undefined') {
      store.commit('indexPage/setAdSection', ad)
    }
  },

  initWhyUsAdSection(store) {
    if (typeof whyUs !== 'undefined') {
      store.commit('indexPage/setWhyUsSection', whyUs)
    }
  },

  initEveryMundoCardsSection(store) {
    if (typeof everyMundoCards !== 'undefined') {
      store.commit('indexPage/setEveryMundoCardsSection', everyMundoCards)
    }
  },

  initSubscribeSection(store) {
    if (typeof subscribe !== 'undefined') {
      store.commit('indexPage/setSubscribeSection', subscribe)
    }
  },

  initPageType(store) {
    store.commit('main/setPageType', 'index')
  },

  initActiveTab(store) {
    let tab = 'FLT'
    if (typeof activeIndexTab !== 'undefined' && activeIndexTab !== '') {
      tab = activeIndexTab
    }

    store.commit(`indexTabs/setActiveTab`, tab)
    CookieUtils.setCookie('activeTab', tab, 0)
  }
}

export default Init
