import ConfirmationPage from 'store/modules/Pages/Confirmation'
import store from 'definitions/store'

const Init = {
    init (name) {
        if (!store[name]) {
            store.registerModule(name, ConfirmationPage)

            Init.initPageData(name)
        }
    },
    initPageData(name) {
        store.commit("main/setPageType", "profile");

        if (typeof pageSuccessTitle !== 'undefined') {
            store.commit(`${name}/setPageSuccessTitle`, pageSuccessTitle)
        }

        if (typeof pageFailTitle !== 'undefined') {
            store.commit(`${name}/setPageFailTitle`, pageFailTitle)
        }

        if (typeof pageSuccessDescription !== 'undefined') {
            store.commit(`${name}/setPageSuccessText`, pageSuccessDescription)
        }

        if (typeof pageFailDescription !== 'undefined') {
            store.commit(`${name}/setPageFailText`, pageFailDescription)
        }

        if (typeof pageStatus !== 'undefined') {
            store.commit(`${name}/setStatus`, pageStatus)
        }

        if (typeof token !== 'undefined') {
            store.commit(`${name}/setToken`, token)
        }
    }
}

export default Init
