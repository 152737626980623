import moment from 'moment'
import FlightModel from 'models/Forms/Sections/Flights'
import FormValidationUtils from 'utils/FormValidation'
import get from 'lodash-es/get'

const data = {
  flightDay: '',
  flightMonth: '',
  flightYear: '',
}

const flight = Object.assign({}, new FlightModel(), data)

const defaultState = {
  flights: [flight],
  selectedFlights: [],
  hasFlights: false,
  formErrors: flight.errorMsg,
  category: ''
}

const validator = FormValidationUtils.initValidation(defaultState, flight.validation)

const Flights = {
  state: defaultState,
  mutations: {
    addFlight (state) {
      state.flights.push(flight)
    },

    removeFlight (state, index) {
      state.flights.splice(index, 1)
    },

    updateSelectedFlights (state, value) {
      state.selectedFlights = value
    },

    setHasFlights (state, value) {
      state.hasFlights = value
    },

    setFlights (state, value) {
      state.flights = value
    },

    setFlightNumber (state, value) {
      if (state.flights[value.index]) {
        state.flights[value.index].flightNumber = value.value.toUpperCase()
      }
    },

    setFlightDay (state, value) {
      if(state.flights[value.index]) {
        state.flights[value.index].flightDay = value.value
      }
    },

    setFlightMonth (state, value) {
      if(state.flights[value.index]) {
        state.flights[value.index].flightMonth = value.value
      }
    },

    setFlightYear (state, value) {
      if(state.flights[value.index]) {
        state.flights[value.index].flightYear = value.value
      }
    },

    setFlightDate (state, value) {
      if(state.flights[value.index]) {
        state.flights[value.index].flightDate = value.value
      }
    },
    reset (state) {
      state.flights = [Object.assign({}, new FlightModel(), data)]
      state.selectedFlights = []
    },
    setCategory (state, value) {
      state.category = value
    }
  },

  actions: {
    setFlightDate ({ state, commit }, index) {
      const flight = state.flights[`${index}`]
      const data = {
        value: {},
        index
      }

      if (typeof flight !== 'undefined') {
        const flightDate = `${flight.flightYear}-${flight.flightMonth}-${flight.flightDay}`
        const date = moment(flightDate, 'YYYY-M-D', true)

        if (date.isValid()) {
          data.value = date
        }
      }

      commit('setFlightDate', data)
    },

    clearForm ({ commit }) {
      validator.$v.state.flights.$reset()
      validator.$v.state.selectedFlights.$reset()
      commit('reset')
    },

    validateFlights ({ state }) {
      return new Promise((resolve, reject) => {
        let vState = validator.$v.state.flights


        if (state.hasFlights) {
          vState = validator.$v.state.selectedFlights
        }

        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('validateFlights')
      })
    }
  },

  getters: {
    $flights () {
      return Object.assign({}, validator.$v.state.flights)
    },

    checkedValidation () {
      return Object.assign({}, validator.$v.state.selectedFlights)
    },

    flightCount (state) {
      return state.flights.length
    },

    filteredFlights (state) {
      const flights = get(state, 'flights', [])
      const filteredFlights = flights.filter(flt => {
        const flightDate = get(flt, 'flightDate', '')
        if (flightDate !== '') {
          const diff = get(flt, 'flightDate', '').diff(moment(), 'minutes')
          return diff >= 0 && diff <= 3 * 24 * 60
        }
        return false
      })

      return Object.assign(
        { ...state },
        { flights: filteredFlights }
      )
    }
  },

  namespaced: true
}

export default Flights
