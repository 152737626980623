import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import Request from 'models/Payments/Request'
import RequestSection from 'models/Payments/Sections/Request'

const request = new Request()
const formData = {
  formErrors: request.errorMsg,
  form: request
}
const defaultState = Object.assign({}, new RequestSection(), formData)
const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    return {
      state: request.validation
    }
  }
})

export default {
  namespaced: true,
  state: defaultState,
  actions: {
    validateRequests({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('touchRequest')
          .then(() => resolve(true))
          .catch(() => reject('validateRequests'))
      })
    },
    touchRequest() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('touchRequest')
      })
    },
    clearRequests({ commit }) {
      commit('clearData', new Request())
      validator.$v.state.form.$reset()
    },
    setRequestData({ commit }, data) {
      commit('setRequestTitle', data.requestTitle)
      commit('setSpeqRequestTitle', data.sportEquipmentRequestTitle)
      commit('setSpeqRequestDescription', data.sportEquipmentRequestDescription)
      commit('setHeavyCabinBaggageRequestTitle', data.heavyCabinBaggageRequestTitle)
      commit(
        'setHeavyCabinBaggageRequestDescription',
        data.heavyCabinBaggageRequestDescription
      )
      commit('setCheckedBaggageRequestTitle', data.checkedBaggageRequestTitle)
      commit('setCheckedBaggageRequestDescription', data.checkedBaggageRequestDescription)
    }
  },
  mutations: {
    clearData(state, value) {
      state.form = value
    },
    ignoreValidations(state, { key, validation }) {
      state.form.validation.form[`${key}`][`${validation}`] = false
    },
    setMessage(state, value) {
      state.form.message = value
    },
    setRequestTitle(state, value) {
      state.requestTitle = value
    },
    setSpeqRequestTitle(state, value) {
      state.sportEquipmentRequestTitle = value
    },
    setSpeqRequestDescription(state, value) {
      state.sportEquipmentRequestDescription = value
    },
    setHeavyCabinBaggageRequestTitle(state, value) {
      state.heavyCabinBaggageRequestTitle = value
    },
    setHeavyCabinBaggageRequestDescription(state, value) {
      state.heavyCabinBaggageRequestDescription = value
    },
    setCheckedBaggageRequestTitle(state, value) {
      state.checkedBaggageRequestTitle = value
    },
    setCheckedBaggageRequestDescription(state, value) {
      state.checkedBaggageRequestDescription = value
    }
  },
  getters: {
    $request() {
      return Object.assign({}, validator.$v.state.form)
    },
    requestValidationFormErrors(state) {
      return state.formErrors
    }
  }
}
