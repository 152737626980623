import { mapState } from 'vuex'

const ImageHelpers = {
  computed: {
    ...mapState({
      $_ImageHelpers_deviceType: (state) => state.device.deviceType
    })
  },

  methods: {
    $_ImageHelpers_getBackgroundImage(img) {
      return { 'background-image': `url(${img})`}
    },
    $_ImageHelpers_responsiveImage (data) {
      const sizes = ['mobile', 'tablet', 'desktop']

      if (!data) {
        return
      }

      let lastScreen = sizes.find(size => data[`${size}`] && this.$_ImageHelpers_deviceType === size)

      if (!lastScreen) {
        lastScreen = sizes.find(size => data[`${size}`])
      }

      return data[`${lastScreen}`]
    }
  }
}

export default ImageHelpers
