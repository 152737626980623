<template>
  <div
    tabindex="-1"
    :class="className"
  >
    <button
      v-dompurify-html="text"
      :type="type"
      :title="text"
      :disabled="disabled"
      @click="onClick"
    />
  </div>
</template>

<script>
  export default {
    name: "Button",
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      text: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: false,
        default: 'button'
      },
      ghost: {
        type: Boolean,
        default: false
      },
      textBtn: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      className () {
        return ['btn-base', { 'btn-disabled': this.disabled, 'btn-ghost': this.ghost, 'btn-text': this.textBtn }]
      }
    },
    methods: {
      onClick (event) {
        return this.$emit('click', event)
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  .btn-base {
    height: 52px;
    background: $blue-medium;
    border-radius: 4px;
    margin-top: 24px;

    @include bp-mf-tablet {
      height: 56px;
    }

    /deep/ button {
      padding: 0 8px;
      color: white;
      font-size: em(14);
      font-weight: 400;
      letter-spacing: 0;
      line-height: 16px;
      border-radius: 4px;

      @include bp-mf-tablet {
        font-size: em(16);
        font-weight: bold;
        line-height: 19px;
      }

      &:focus {
        background-color: $blue-hover;
      }
    }

    &:hover{
      background-color: $blue-hover;
    }

    &.btn-disabled {
      background-color: $blue-disabled;

      /deep/ button {
        color: $blue-border;
      }
    }

    &.btn-ghost {
      background-color: transparent;
      border: 1px solid $blue-medium;

      /deep/ button {
        color: $blue-medium;

        &:focus {
          color: $blue-ghost-hover;
          background-color: transparent;
        }
      }

      &:hover {
        border-color: $blue-ghost-hover;

        /deep/ button {
          color: $blue-ghost-hover;
        }
      }

      &.btn-disabled {
        background-color: $blue-disabled;
        border: none;

        /deep/ button {
          color: $blue-border;
        }
      }
    }

    &.btn-text {
      background-color: transparent;
      border: none;

      /deep/ button {
        color: $blue-medium;
        text-align: left;
        padding: 0;
      }
    }
  }
</style>
