import AbstractView from 'abstract';
import Vue from 'vue'
import BookingsInit from 'initializations/pages/Pax/Bookings'
import SidebarInit from 'initializations/Sidebar'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        SidebarInit.init("sidebar")
        BookingsInit.init("bookings")

        const MyBookings = () => import(/* webpackChunkName: "pax-bookings" */ 'containers/Pages/Profile/MyBookings')

        new Vue({
            el: "#" + this.elemId,
            store,
            render: h => h(MyBookings)
        })
    }
}
