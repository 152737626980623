<template>
  <div class="disclaimers">
    <div
      v-dompurify-html="$t('footerWide.externalLinkText')"
      class="block external-link-mark"
    />
    <div
      v-if="googlePrivacyPolicyText !== ''"
      v-dompurify-html="googlePrivacyPolicyText"
      class="block googlePrivacy"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'FooterDisclaimers',
    computed: {
      ...mapState('footer', ['googlePrivacyPolicyText'])
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .disclaimers {
    padding: 28px 0 38px 30px;
    border-top: 1px solid $blue-border;

    @include bp-mf-tablet {
      padding: 28px 0 30px 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    @include bp-mf-desktop {
      padding: 0;
      border: none;
      width: 45%;
    }

    @include bp-desktop {
      width: 50%;
      margin-left: 60px;
    }

    .block {
      @include bp-mf-tablet {
        width: calc(50% - 15px);
        padding-right: 30px;
      }

      @include bp-mf-desktop {
        width: 50%;
        padding-right: 0;
      }
    }
  }

  .external-link-mark {
    position: relative;
    @include defaultFontValues($gray-text-muted, 11, 16);

    @include bp-mf-desktop {
      padding-left: 38px;
      width: 50%;
    }

    @include bp-desktop-small {
      padding-left: 38px;
      padding-right: 20px;
    }

    &:before {
      content: $icon-external-link-facelift;
      @include icon-font-thin();
      position: absolute;
      left: -28px;
      font-size: 20px;
      display: flex;
      height: 100%;
      align-items: center;

      @include bp-mf-desktop {
        left: 0;
        top: 0;
        align-items: flex-start;
      }
    }
  }

  .googlePrivacy {
    margin-top: 30px;
    position: relative;

    @include bp-mf-tablet {
      margin: 0;
    }

    @include bp-mf-desktop {
      width: 50%;
      margin-left: 25px;
      padding-left: 40px;
    }

    @include bp-desktop-small {
      margin: 0;
    }

    ::v-deep p {
      @include defaultFontValues($gray-text-muted, 11, 16);
      margin: 0;

      a {
        color: $gray-text-muted;

        &:hover {
          @include bp-mf-tablet {
            color: $blue-medium-hover;
          }
        }
      }
    }

    &:before {
      content: url('~assets/svg/captcha.svg');
      position: absolute;
      top: 2px;
      left: -28px;
      font-size: 20px;
      height: 100%;
      display: flex;
      align-items: center;
      width: 24px;

      @include bp-mf-desktop {
        left: 0;
        top: 0;
        align-items: flex-start;
      }
    }
  }
</style>
