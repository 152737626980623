import LandingPageStore from 'store/modules/Pages/CampaignLandingPrices'
import SearchFormStore from 'store/modules/eHorizon/SearchForm/SearchForm'
import store from 'definitions/store'
import getNearestAirport from 'utils/GetNearestAirport'
import OrigDest from 'utils/OrigDestData'
import isNull from 'lodash-es/isNull'

let ORIG_DEST_DATA = []

const Init = {
  init(name) {
    if (!store.hasModule('eHorizonSearchForm')) {
      store.registerModule('eHorizonSearchForm', SearchFormStore)
    }
    if (!store.hasModule(name)) {
      store.registerModule(name, LandingPageStore)
      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    if (typeof landingPageInit !== 'undefined') {
      store.commit(`${name}/setIntroText`, landingPageInit.introText)
      store.commit(`${name}/setTitle`, landingPageInit.title)
      store.commit(`${name}/setPageData`, landingPageInit.pageData)
      store.commit(`${name}/hideFilterBlock`, !!landingPageInit.hideFilterBlock)

      if (landingPageInit.timerEnabled) {
        store.commit(`${name}/setTimerEnabled`, true)
        store.commit(`${name}/setTimerTitle`, landingPageInit.timerTitle)
        store.dispatch(`${name}/setDeadline`, {
          date: landingPageInit.timerDate ? landingPageInit.timerDate.date : '',
          time: landingPageInit.timerTime
        })
      }
      var isOnd = !!landingPageInit.isOnD
      store.commit(`${name}/setIsOndCampaign`, isOnd)

      if (typeof clpOrigDestData !== 'undefined') {
        Init.setOriginData()
        Init.setOrigDestData(name)
        Init.setSelectedOrigin()
      }

      store.commit(`${name}/setFlightDetails`, landingPageInit.flightDetails)
      store.commit(`${name}/setTermsAndConditions`, landingPageInit.termsAndConditions)
      store.commit(`${name}/setIconType`, landingPageInit.iconType)
      store.commit(`${name}/setHideImages`, !!landingPageInit.hideImages)
      store.commit(`${name}/setOpenAllHistograms`, !!landingPageInit.openAllHistograms)
    }
  },

  setOriginData() {
    if (typeof origDestData !== 'undefined') {
      ORIG_DEST_DATA = OrigDest.parseData(origDestData)
    }
  },

  getOriginData(code) {
    return Object.keys(ORIG_DEST_DATA).length
      ? ORIG_DEST_DATA[code + 'A']
      : clpOrigDestData[code]
  },

  getOriginObject(origin) {
    if (origin?.apt && origin?.code) {
      return {
        ...origin,
        aptWithCode: `${origin.apt} (${origin.code})`
      }
    }

    if (origin?.city && origin?.code) {
      return {
        ...origin,
        aptWithCode: `${origin.city} (${origin.code})`
      }
    }

    return {}
  },

  setOrigDestData(name) {
    var origins = []
    var origData = []

    Object.keys(clpOrigDestData).forEach((code) => {
      const clpData = clpOrigDestData[code]
      const origin = Init.getOriginData(code)

      origins.push(Init.getOriginObject(origin))

      if (clpData?.city && clpData?.destinations) {
        clpData.destinations.forEach((item) => {
          origData.push({
            ...item,
            originCode: clpData.code,
            originCity: clpData.city,
            originCountry: clpData.country
          })
        })
      }
    })
    store.commit(`${name}/setClpOrigDestData`, origData)
    store.commit(`${name}/setOriginList`, origins)
  },
  getUrlParam(param) {
    const url = new URL(window.location.href)
    return url.searchParams.get(param) ?? ''
  },
  setSelectedOrigin() {
    var origin = ''
    var originObject = {}
    var allAvailableOrigins = Object.keys(clpOrigDestData)

    let originFromUrl = ''

    if (Init.getUrlParam('origin').toUpperCase().length === 3) {
      originFromUrl = Init.getUrlParam('origin').toUpperCase()
    }

    if (originFromUrl && allAvailableOrigins.includes(originFromUrl)) {
      origin = Init.getOriginData(originFromUrl)
    } else {
      const airport = getNearestAirport(true)
      if (!isNull(airport) && allAvailableOrigins.includes(airport)) {
        origin = Init.getOriginData(airport)
      } else {
        if (allAvailableOrigins.includes('RIX')) {
          origin = Init.getOriginData('RIX')
        } else {
          var firstKey = allAvailableOrigins[0]
          if (firstKey) {
            origin = Init.getOriginData(firstKey)
          }
        }
      }
    }

    if (origin) {
      originObject = Init.getOriginObject(origin)
    }

    store.commit(`eHorizonSearchForm/origin/setValue`, originObject)
  }
}

export default Init
