import SiloStore from 'store/modules/Pages/DestinationsSilo'
import store from 'definitions/store'
import OrigDest from 'utils/OrigDestData'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule(name, SiloStore)
            Init.initDefaultValues(name)
        }
    },

    initDefaultValues(name) {
        if (typeof destinationsSiloInit !== "undefined") {

            if (destinationsSiloInit.showTermsAndConditions && typeof termsAndConditions !== "undefined") {
                store.commit(`${name}/setTermsAndConditionsVisible`, true)
                store.commit(`${name}/setTermsAndConditions`, {
                    title: termsAndConditions.title.text,
                    content: termsAndConditions.content.text
                })
            }

            if (typeof destinationsSiloInit.country !== "undefined" && destinationsSiloInit.country !== null) {
                store.commit('destinationsSilo/setCountryFromUrl', destinationsSiloInit.country)
            }

            store.commit(`${name}/setPageData`, {
                'title': destinationsSiloInit.title,
                'description': destinationsSiloInit.description
            })

            store.commit(`${name}/setLabelOrder`, destinationsSiloInit.labelOrder || '')
        }

        if (typeof origDestData !== 'undefined') {
            store.dispatch(`${name}/setOrigDestData`, OrigDest.parseData(origDestData))
        }

        if (typeof origDestLabels !== 'undefined') {
            store.commit(`${name}/setLabels`, origDestLabels)
        }
    }
}

export default Init
