import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import Step4Model from 'models/Pax/GroupBooking/Step4'
import cloneDeep from 'lodash-es/cloneDeep'
import divolteGroupBooking from 'services/AirBaltic/Divolte/GroupBooking'

const step4Model = new Step4Model()
const formState = Object.assign({}, { formErrors: step4Model.errorMsg }, step4Model)

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return formState
    }
  },
  validations () {
    const validations = cloneDeep(step4Model.validations)

    if (Step4.state.contactType === 'agency') {
      if (Step4.state.handling) {
        delete validations.id
      }
    } else {
      delete validations.country
      delete validations.agencyName
      delete validations.id
    }

    return {
      state: validations
    }
  }
})

const Step4 = {
  namespaced: true,
  state: formState,
  mutations: {
    setContactType (state, value) {
      state.contactType = value
    },
    setTerms (state, value) {
      state.terms = value
    },
    setHandling (state, value) {
      state.handling = value
    },
    setTitle (state, value) {
      state.title = value
    },
    setId (state, value) {
      state.id = value
    },
    setAgencyName(state, value) {
      state.agencyName = value
    },
    setCountry (state, value) {
      state.country = value
    },
    setIntCode (state, value) {
      state.intCode = value
    },
    setFirstName (state, value) {
      state.firstName = value
    },
    setLastName (state, value) {
      state.lastName = value
    },
    setPhoneNumber (state, value) {
      state.phoneNumber = value
    },
    setEmail (state, value) {
      state.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setGroupName (state, value) {
      state.groupName = value
    },
    setGroupType (state, value) {
      state.groupType = value
    },
    setLanguage (state, value) {
      state.language = value
    }
  },
  actions: {
    clearStep({ commit }) {
      validator.$v.state.$reset()

      commit('setContactType', 'individual')
      commit('setHandling', false)
      commit('setId', '')
      commit('setAgencyName', '')
      commit('setCountry', '')
      commit('setTitle', 'Mr')
      commit('setFirstName', '')
      commit('setLastName', '')
      commit('setEmail', '')
      commit('setIntCode', '')
      commit('setPhoneNumber', '')
      commit('setGroupName', '')
      commit('setGroupType', 'standard')
      commit('setTerms', false)
      commit('setLanguage', '')
    },
    getData ({ state }) {
      const code = state.intCode.split("|")
      const data = {
        language: state.language.toUpperCase(),
        title: state.title,
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        phoneCountry: code[1],
        phoneNumber: state.phoneNumber,
        groupName: state.groupName,
        groupType: state.groupType,
      }

      return Promise.resolve(data)
    },
    getOtherData ({ state }) {
      const data = {
        contactType: state.contactType,
        id: state.id,
        agencyName: state.agencyName,
        country: state.country.toUpperCase(),
        handling: state.handling
      }
      return Promise.resolve(data)
    },
    validateStep ({ dispatch }) {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state

        vState.$touch()

        if (!vState.$invalid) {
          return dispatch('groupBooking/getDataAndSubmitForm', null, { root: true })
            .then((response) => {
              dispatch('passengerDetails')
              dispatch('clearStep')
              return resolve(response)
            })
            .catch((e) => reject(e))
        } else {
          return reject({ type: 'form' })
        }
      })
    },
    passengerDetails ({ rootState }) {
      divolteGroupBooking.passengerDetails(rootState.groupBooking)
    }
  },
  getters: {
    validator () {
      return validator.$v.state
    },
    $v () {
      return Object.assign({}, validator.$v.state)
    },
    step4FormErrors (state) {
      return state.formErrors
    }
  }
}

export default Step4
