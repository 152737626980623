<template>
  <li v-dompurify-html="$t(content)" />
</template>

<script>
  export default {
    props: {
      content: {
        type: String,
        required: true
      }
    }
  }
</script>
