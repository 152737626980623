import ABClub from './Sections/ABClub';
import Guardian from './Sections/Guardian';
import Notifications from './Sections/Notifications';
import { alphaWithSpaces, notEmpty, isDateValidOrEmpty, isDateAfter, isPaxOlderThan, isTrue, containsSpecialChar, containsUpperChar, containsLowerChar, containsNumber, containsForbidden, containsSpaces } from 'utils/validators';
import { required, minLength, maxLength, email, sameAs, not, requiredIf } from 'vuelidate/lib/validators';
var Profile = /** @class */ (function () {
    function Profile() {
        var _this = this;
        this.day = '';
        this.month = '';
        this.year = '';
        this.form = {
            title: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            email: '',
            residenceCountry: {},
            language: {},
            phone: {
                code: {},
                number: ''
            },
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
            isABCMember: false,
            abClub: new ABClub(),
            guardian: new Guardian(),
            notifications: new Notifications(),
            token: '',
            minorEmail: '',
            isMinorRegistration: false
        };
        this.passwordValidationRuleMapping = {
            minLength: {
                label: 'reusable.password.rules.countCharacters',
                rules: ['minLength', 'required']
            },
            containsLowerChar: {
                label: 'reusable.password.rules.lowerCase',
                rules: ['containsLowerChar']
            },
            containsUpperChar: {
                label: 'reusable.password.rules.upperCase',
                rules: ['containsUpperChar']
            },
            containsNumber: {
                label: 'reusable.password.rules.number',
                rules: ['containsNumber']
            },
            notSameAsEmail: {
                label: 'reusable.password.rules.matchingEmail',
                rules: ['notSameAsEmail']
            },
            notSameAsOldPassword: {
                label: 'reusable.password.rules.matchingPassword',
                rules: ['notSameAsOldPassword']
            },
            containsForbidden: {
                label: 'reusable.password.rules.forbiddenChars',
                rules: ['containsForbidden']
            },
            containsSpecialChar: {
                label: 'reusable.password.rules.special',
                rules: ['containsSpecialChar']
            },
            containsSpaces: {
                label: 'reusable.password.rules.spaces',
                rules: ['containsSpaces']
            },
            maxLength: {
                label: 'reusable.password.rules.maxLength',
                rules: ['maxLength']
            }
        };
        this.errorMsg = {
            title: {
                required: 'reusable.paxTitle.validation.required'
            },
            firstName: {
                alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
                required: 'reusable.firstName.validation.required'
            },
            lastName: {
                alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
                required: 'reusable.lastName.validation.required'
            },
            dateOfBirth: {
                valid: 'reusable.dateOfBirth.validation.valid',
                after: 'reusable.dateOfBirth.validation.after',
                maxAge: 'reusable.dateOfBirth.validation.maxAge',
                minAge: 'reusable.dateOfBirth.validation.minAge',
                required: 'reusable.dateOfBirth.validation.required'
            },
            email: {
                email: 'reusable.email.validation.invalid',
                required: 'reusable.email.validation.missing'
            },
            residenceCountry: {
                required: 'reusable.country.validation.required'
            },
            language: {
                required: 'reusable.contactLang.validation.required'
            },
            phone: {
                code: {
                    required: 'reusable.intCodeWithPhone.validation.intCode.required'
                },
                number: {
                    maxLength: 'reusable.intCodeWithPhone.validation.phoneNumber.maxLength',
                    minLength: 'reusable.intCodeWithPhone.validation.phoneNumber.minLength',
                    numeric: 'reusable.intCodeWithPhone.validation.phoneNumber.notNumeric',
                    required: 'reusable.intCodeWithPhone.validation.phoneNumber.required'
                }
            },
            abClub: {
                card: {
                    required: 'reusable.abClubCard.validation.required',
                    minLength: 'reusable.abClubCard.validation.minLength'
                }
            },
            guardian: {
                email: {
                    email: 'reusable.email.validation.invalid',
                    required: 'reusable.email.validation.required'
                },
                consent: {
                    required: 'reusable.consent.validation.required'
                },
                card: {
                    required: 'reusable.abClubCard.validation.required',
                    minLength: 'reusable.abClubCard.validation.minLength'
                }
            },
            oldPassword: {
                required: 'reusable.password.validation.missing',
                minLength: 'reusable.password.strengthRules.countCharactersOldPassword'
            },
            newPassword: {
                countCharacters: 'reusable.password.strengthRules.countCharacters',
                containsSpecialChar: 'reusable.password.strengthRules.special',
                containsUpperChar: 'reusable.password.strengthRules.upperCase',
                containsLowerChar: 'reusable.password.strengthRules.lowerCase',
                containsNumber: 'reusable.password.strengthRules.number',
                containsForbidden: 'reusable.password.strengthRules.forbiddenChars',
                containsSpaces: 'reusable.password.strengthRules.spaces',
                notSameAsEmail: 'reusable.password.strengthRules.matchingEmail',
                notSameAsOldPassword: 'reusable.password.strengthRules.matchingOldPassword',
                minLength: 'reusable.password.strengthRules.countCharacters',
                maxLength: 'reusable.password.strengthRules.maxCharacters',
                required: 'reusable.password.validation.missing'
            },
            newPasswordRepeat: {
                required: 'reusable.password.validation.missing',
                sameAs: 'reusable.password.validation.confirm'
            },
            minorEmail: {
                email: 'reusable.email.validation.invalid',
                required: 'reusable.email.validation.minor.required',
                isNotOwnEmail: 'reusable.email.validation.minor.ownEmail'
            }
        };
        this.translationVariables = {};
        this.validation = {
            form: {
                title: {
                    required: required
                },
                firstName: {
                    alphaWithSpaces: alphaWithSpaces,
                    required: required
                },
                lastName: {
                    alphaWithSpaces: alphaWithSpaces,
                    required: required
                },
                dateOfBirth: {
                    maxAge: isPaxOlderThan(function () { return _this.form.isMinorRegistration ? 16 : 100; }, 'DD / MM / YYYY'),
                    minAge: not(isPaxOlderThan(function () { return _this.form.isMinorRegistration ? 2 : 16; }, 'DD / MM / YYYY')),
                    after: not(isDateAfter('DD / MM / YYYY')),
                    valid: isDateValidOrEmpty('DD / MM / YYYY'),
                    required: required
                },
                email: {
                    email: email,
                    required: required
                },
                residenceCountry: {
                    required: notEmpty
                },
                language: {
                    required: notEmpty
                },
                phone: {
                    code: {
                        required: notEmpty
                    },
                    number: {
                        maxLength: maxLength(18),
                        minLength: minLength(5),
                        required: notEmpty
                    }
                },
                abClub: {
                    card: {
                        minLength: minLength(12),
                        required: requiredIf(function () { return _this.form.isABCMember; })
                    }
                },
                guardian: {
                    email: {
                        email: email,
                        required: required
                    },
                    consent: {
                        true: isTrue
                    },
                    card: {
                        minLength: minLength(12),
                        required: required
                    }
                },
                oldPassword: {
                    required: required,
                    minLength: minLength(5)
                },
                newPassword: {
                    minLength: minLength(8),
                    maxLength: maxLength(20),
                    containsSpecialChar: containsSpecialChar,
                    containsUpperChar: containsUpperChar,
                    containsLowerChar: containsLowerChar,
                    containsNumber: containsNumber,
                    containsForbidden: not(containsForbidden),
                    containsSpaces: not(containsSpaces),
                    notSameAsEmail: not(sameAs('email')),
                    notSameAsOldPassword: not(sameAs('oldPassword')),
                    required: required
                },
                newPasswordRepeat: {
                    sameAs: sameAs('newPassword'),
                    required: required
                },
                minorEmail: {
                    email: email,
                    required: required,
                    isNotOwnEmail: not(sameAs('email'))
                }
            }
        };
    }
    return Profile;
}());
export default Profile;
