import Api from 'services/AirBaltic/Api'

const defaultState = {
  bestBuyBanners: [],
  bestBuyCountries: [],
  labels: [],
  listName: 'main',
  destinationPageLink: {
    title: '',
    href: '',
    target: ''
  },
  everyMundoLink: {
    title: '',
    href: '',
    target: ''
  },
}

const BestBuyBanners = {
  state: defaultState,
  mutations: {
    setListName (state, value) {
      state.listName = value
    },
    setBestBuyBanners (state, value) {
      state.bestBuyBanners = value.slice(0, 20)
    },
    setBestBuyCountries (state, value) {
      state.bestBuyCountries = value
    },
    setBestBuyBannersForPos (state, data) {
      state.bestBuyBanners[data.pos] = data.list
    },
    setLabels (state, labels) {
      state.labels = labels
    },
    setDestinationPageLink (state, value) {
      state.destinationPageLink = value
    },
    setEveryMundoLink (state, value) {
      state.everyMundoLink = value
    }
  },
  actions: {
    setBestBuyCountries ({ commit }, data) {
      data = data.map(country => ({
        name: 'countries.' + country,
        code: country
      }))
      commit('setBestBuyCountries', data)
    },
    getBestBuyBannersPos ({ commit }, data) {
      new Api().get(`/banners/main/${data.country}`)
        .then((response) => {
          commit('setBestBuyBannersForPos', { list: response.data, pos: data.country })
        })
    }
  },
  namespaced: true
}

export default BestBuyBanners
