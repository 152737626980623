import Api from 'services/AirBaltic/Api'
import ErrorHandler from 'utils/ErrorHandler'
import conf from 'conf'
import * as Sentry from '@sentry/vue'

const ERRORS = {}
let path = '/contact/claim'

class Claims {
  constructor(type) {
    if (type === 'baggage') {
      path = '/contact/claim/baggage'
    } else if (type === 'flight') {
      path = '/contact/claim/flightIrregularities'
    }
  }

  submitForm(data) {
    return new Api()
      .post(path + conf.btUrlCors, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        console.log(error)
        console.log(data)
        Sentry.captureException('Error submitting claims form')

        return Promise.reject({
          msg: this.getErrors(error.response),
          type: 'submit'
        })
      })
  }

  getErrors(response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default Claims
