import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        const SubscribedTwoColumns = () => import(/* webpackChunkName: "snippet-items-subscribed-two-columns" */ 'components/SnippetTemplate/Items/TwoColumnsText')
        new Vue({
            el: "#" + this.elemId,
            store,
            render: h => h(SubscribedTwoColumns, {props: eval(this.elemId)})
        })
    }
}
