import Api from 'services/AirBaltic/Api'

class CheckIn {
  getHeaders (uuid) {
    return {
      'X-Correlation-ID': uuid
    }
  }

  getPnrData (pnr, surname, lang) {
    let bookingReference = ''
    if (pnr && pnr.length === 6) {
      bookingReference = `pnrRl=${pnr}`
    } else {
      bookingReference = `ticketNumber=${pnr}`
    }
    return new Api().get(`/checkin/dcc/rules?${bookingReference}&surname=${surname}&languageCode=${lang}`)
  }

  sendDocument (data, uuid) {
    const headers = this.getHeaders(uuid)
    return new Api().put('/checkin/dcc/documents', data, headers)
  }

  validate (data, uuid) {
    const headers = this.getHeaders(uuid)
    return new Api().post('/checkin/dcc/validate', data, headers)
  }

  validateAndSubmit (data, uuid) {
    const headers = this.getHeaders(uuid)
    return new Api().post('/checkin/dcc/submitDocuments', data, headers)
  }

  skip (pnr, languageCode, uuid) {
    const headers = this.getHeaders(uuid)
    return new Api().post('/checkin/dcc/skip?pnrRl=' + pnr + 'languageCode='+ languageCode, {}, headers)
  }
}

export default CheckIn
