import { regex } from 'utils/validators';
import { required } from 'vuelidate/lib/validators';
var CompanyDetails = /** @class */ (function () {
    function CompanyDetails() {
        this.form = {
            countryCode: '',
            city: '',
            postcode: '',
            street: '',
            name: '',
            regNo: '',
            vat: ''
        };
        this.errorMsg = {
            postcode: {
                alphaNumberSpaceDash: 'reusable.validation.alphaNumWithDash'
            },
            name: {
                required: 'reusable.validation.required'
            },
            countryCode: {
                required: 'reusable.validation.required'
            },
            regNo: {
                required: 'reusable.validation.required',
                alphaNumberSpaceDash: 'reusable.validation.alphaNumWithDash'
            },
            vat: {
                alphaNumberSpaceDash: 'reusable.validation.alphaNumWithDash'
            }
        };
        this.validation = {
            form: {
                postcode: {
                    alphaNumberSpaceDash: regex('alphaNumberSpaceDash')
                },
                name: {
                    required: required
                },
                regNo: {
                    alphaNumberSpaceDash: regex('alphaNumberSpaceDash'),
                    required: required
                },
                vat: {
                    alphaNumberSpaceDash: regex('alphaNumberSpaceDash')
                },
                city: {},
                street: {},
                countryCode: {
                    required: required
                }
            }
        };
    }
    return CompanyDetails;
}());
export default CompanyDetails;
