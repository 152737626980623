import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    const iFrame = () => import(/* webpackChunkName: "snippet-items-iframe" */ 'components/SnippetTemplate/Items/iFrame')
    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(iFrame, {props: eval(this.elemId)})
    })
  }
}
