import AbstractView from 'abstract'
import Vue from 'vue'
import store from 'definitions/store'
import selfServiceInit from 'initializations/pages/SelfCheckIn'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    selfServiceInit.init("selfCheckIn")

    const CustomerCheckIn = () => import(/* webpackChunkName: "customer-check-in-page" */ 'containers/Pages/CustomerCheckIn/Index')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(CustomerCheckIn)
    })
  }
}
