import BusinessLoungeStore from 'store/modules/Pages/BusinessLounge'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, BusinessLoungeStore)
      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    if (typeof pageData !== "undefined") {
      store.commit(`${name}/setPageTitle`, pageData.title)
      store.commit(`${name}/setDescription`, pageData.description)
      store.commit(`${name}/setPlaceholder`, pageData.placeholder)
      store.commit(`${name}/setPlaceholderMobile`, pageData.placeholderMobile)
      store.commit(`${name}/setVisibleItems`, parseInt(pageData.visibleItems))
      store.commit(`${name}/setTableHeader`, pageData.tableHeader)
      if (typeof termsAndConditions !== "undefined") {
        store.commit(`${name}/setTermsAndConditions`, {
          title: termsAndConditions.title.text,
          content: termsAndConditions.content.text
        })
      }

      if (typeof importedBusinessLoungeData !== "undefined") {
        store.dispatch(`${name}/setData`, importedBusinessLoungeData)
      }
    }
  }
}

export default Init
