import Api from 'services/AirBaltic/Api'
import Vue from 'vue'

const defaultState = {
  pageData: {},
  isFormOpen: false,
  formData: {
    formTitle: '',
    formInfoText: ''
  },
  countries: [],
  loadingStatus: false
}
const Partnership = {
  state: defaultState,
  mutations: {
    setPageData(state, data) {
      state.pageData = data
    },
    setIsFormOpen(state, data) {
      state.isFormOpen = data
    },
    setFormData(state, value) {
      state.formData = value
    },
    setCountries(state, value) {
      state.countries = value
    }
  },

  actions: {
    submitPartnershipForm({ state }, data) {
      const intCode = data.step5.intCode.split('|')
      const cooperationForms = []
      Object.keys(data.step2.cooperationForm).forEach((key) => {
        if (data.step2.cooperationForm[key]) {
          cooperationForms.push(key)
        }
      })
      const postObj = {
        language: Vue.i18n.locale().toUpperCase(),
        organizer: data.step1.organizer,
        eventDate: data.step1.eventDate,
        eventVenue: data.step1.venue,
        information: data.step1.information,
        audience: data.step3.targetAudience,
        expectedContacts: data.step3.expectedNumberOfContacts,
        partnerList: data.step3.listOfOtherPartners,
        collaboration: data.step3.collaboration,
        otherCollaboration: data.step3.collaborationOther,
        activities: data.step4.plannedActivities,
        promotional: data.step4.promoMaterials,
        benefits: data.step4.otherBenefits,
        cooperation: cooperationForms,
        firstName: data.step5.firstName,
        lastName: data.step5.lastName,
        companyName: data.step5.companyName,
        phoneCountry: intCode[1],
        phoneDialingCode: intCode[0],
        phoneNumber: data.step5.phoneNumber,
        email: data.step5.email
      }

      const formData = new FormData()

      data.step1.files.forEach((file) => {
        formData.append('files', file, file.name)
      })

      const form = new Blob([JSON.stringify(postObj)], {
        type: 'application/json'
      })

      formData.append('request', form)

      return new Api()
        .post('/contact/service/multipart/v2/collaboration', formData)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error.response))
    }
  },

  getters: {
    $v() {
      return Object.assign({}, validator.$v)
    },
    validationErrors(state) {
      return state.validationErrors
    }
  },
  namespaced: true
}

export default Partnership
