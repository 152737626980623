var Generic = /** @class */ (function () {
    function Generic(obj) {
        if (obj === void 0) { obj = {}; }
        this.list = [];
        this.note = '';
        this.forms = [];
        this.oldUrl = '';
        this.steps = '';
        this.type = '';
        this.pageTitle = '';
        this.description = '';
        this.activeStep = 'intro';
        this.activeItem = {};
        this.origDestData = [];
        this.protectedBaggage = false;
        if (Object.keys(obj).length > 0) {
            Object.assign(this, obj);
        }
    }
    return Generic;
}());
export default Generic;
