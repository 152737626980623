const Outlook = {
  state: {
    pageData: {
      pageTitle: '',
      pageDescription: '',
      advertise: {
        title: '',
        description: '',
        countries: ''
      }
    },
    selectedYear: '',
    selectedMonth: '',
    issues: []
  },
  mutations: {
    setPageData (state, value) {
      state.pageData = value
    },
    setActiveYear (state, value) {
      state.selectedYear = value
    },
    setActiveMonth (state, value) {
      state.selectedMonth = value
    }
  },

  namespaced: true
}

export default Outlook
