const CompareTable = {
  state: {
    pageData: {},
    termsAndConditions: {}
  },
  mutations: {
    setPageData (state, value) {
      state.pageData = value
    },

    setDescription (state, value) {
      state.description = value
    },

    setTermsAndConditions (state, value) {
      state.termsAndConditions = value
    }
  },

  actions: {
    setPageData ({ commit }, data) {
      commit('setPageData', data)
    },

    setDescription ({ commit }, data) {
      commit('setDescription', data)
    }
  },
  namespaced: true
}

export default CompareTable
