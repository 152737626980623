import LoginFormStore from 'store/modules/Header/LoginForm'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, LoginFormStore)
      this.storeForgotPasswordUrl(name)
    }
    this.initFB()
  },
  initFB() {
    if (typeof facebookAPI !== 'undefined') {
      facebookAPI.initialize()
    }
  },
  storeForgotPasswordUrl(name) {
    if (typeof forgotPasswordUrl !== 'undefined') {
      store.commit(`${name}/forgotPassword/setUrl`, forgotPasswordUrl)
    }
  }
}

export default Init
