<template>
  <div :class="['input-block', { 'error': error }]">
    <div :class="['input-field', field]">
      <input-base
        :type="passwordFieldType"
        :name="name"
        :field="field"
        :value="value"
        :disable="disabled"
        :max-length="maxLength"
        :class="{ 'not-empty': value !== '' }"
        :label="label"
        :autocomplete="false"
        placeholder=" "
        @input="onChange($event)"
        @keyup.enter="onChange($event)"
      />
      <label
        v-if="label.length"
        v-dompurify-html="fieldLabel"
        tabindex="-1"
        :for="name"
      />
      <password-strength-tooltip
        v-if="showStrengthMeter"
        :validator="passwordValidator"
        :rule-mapping="ruleMapping"
      />
      <span
        v-tooltip="passwordTooltip"
        tabindex="0"
        role="button"
        aria-pressed="false"
        :aria-label="$t('header.white.login.tooltip')"
        :class="['show-password', { 'active': showPassword }]"
        @click="toggleShowPassword"
        @keyup.enter="toggleShowPassword"
      />
    </div>
    <errors
      v-if="error"
      :errors="[errorMsg]"
    />
  </div>
</template>

<script>
  import Tooltip from 'utils/directives/Tooltip'
  import { mapGetters } from 'vuex'

  export default {
    name: "Password",
    components: {
      'input-base': () => import('components/Elements/Base/Input'),
      Errors: () => import(/* webpackChunkName: "element-errors" */ 'components/Elements/Errors'),
      PasswordStrengthTooltip: () => import(/* webpackChunkName: "strength-meter" */ 'components/ProfilePages/PasswordStrengthTooltip'),
    },
    directives: {
      Tooltip
    },
    props: {
      optional: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        required: false,
        default: 'text'
      },
      label: {
        type: String,
        default: '',
        required: false
      },
      name: {
        type: String,
        required: true
      },
      field: {
        type: String,
        required: true
      },
      error: {
        type: Boolean,
        default: false,
        required: false
      },
      errorMsg: {
        type: String,
        default: '',
        required: false,
      },
      value: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      maxLength: {
        type: Number,
        default: 1500,
        required: false
      },
      append: {
        type: Boolean,
        default: false,
        required: false
      },
      showTooltip: {
        type: Boolean,
        default: true,
        required: false
      },
      showStrengthMeter: {
        type: Boolean,
        required: false,
        default: false
      },
      passwordValidator: {
        type: Object,
        required: false,
        default: () => ({})
      },
      ruleMapping: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },
    data () {
      return {
        showPassword: false
      }
    },
    computed: {
      ...mapGetters('device', [
        'isMobile'
      ]),
      fieldLabel () {
        let label = this.label

        if (this.optional) {
          label += `<span> (${this.$t('optional')})</span>`
        }

        return label
      },
      passwordFieldType() {
        return this.showPassword ? 'text' : 'password'
      },
      passwordTooltip() {
        if (this.isMobile) {
          return {}
        }

        return {
          content: this.$t('header.white.login.tooltip'),
          position: 'top',
          class: 'js-login-tooltip'
        }
      },
    },
    methods: {
      toggleShowPassword() {
        this.showPassword = !this.showPassword
      },
      onChange(value) {
        this.$emit('input', value)
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";
  @import '~assets/styles/components/tooltip.scss';

  .js-tooltip.js-login-tooltip {
    @include tooltip();
    max-width: 200px;
    width: 100%;
    text-align: center;
  }

  .input-block {
    display: block;

    .input-field {
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid $blue-border;
      height: 58px;
      margin-bottom: 16px;
      position: relative;

      @include bp-mf-tablet {
        margin-bottom: 12px;
      }

      label {
        position: absolute;
        top: calc(50% - 10px);
        left: 0;
        padding: 0 60px 0 16px;
        width: 100%;
        transition: 0.2s ease all;
        @include text-overflow();
        font-size: em(16);
        line-height: 20px;
        pointer-events: none;
        color: $blue-light;
      }

      /deep/ .password-strength-validation {
        background: white;
        @include box-shadow;
        z-index: 2;
        @include bp-mobile {
          width: 100%;
          left: 0;
          z-index: 3;
        }

        @include bp-mf-desktop-large {
          right: -250px;
          left: auto;
        }

        &::before {
          display: none;

          @include bp-mf-desktop-large {
            display: block;
            border-right: 10px solid white;
            border-left: none;
            right: auto;
            left: -10px;
            top: 14px;
          }
        }

        &::after {
          @include bp-mf-tablet {
            border-left: 10px solid white;
            border-right: none;
            top: 14px;
            right: -10px;
            left: auto;
          }

          @include bp-mf-desktop-large {
            display: none;
          }
        }
      }

      &:focus-within {
        border-color: $blue-medium;

        /deep/ .password-strength-validation {
          display: block !important;
        }
      }

      input {
        width: 100%;
        height: 58px;
        border-radius: 4px;
        padding: 0 60px 0 16px;
        font-size: em(16);
        letter-spacing: 0;
        line-height: 20px;
        color: $blue-dark;

        &.not-empty,
        &:focus {
          padding-top: 10px;

          + label {
            top: 7px;
            font-size: em(12);
            letter-spacing: 0;
            line-height: 14px;
          }
        }

        &:not(:placeholder-shown) {
          padding-top: 10px;

          + label {
            top: 7px;
            font-size: em(12);
            letter-spacing: 0;
            line-height: 14px;
          }
        }

        &:focus {
          border-color: #b7b8be;

          + label + .password-strength-validation {
            display: block !important;
          }
        }
      }

      .show-password {
        position: absolute;
        top: 1px;
        right: 1px;
        width: 54px;
        height: 54px;
        display: block;
        background-color: transparent;
        text-align: center;
        cursor: pointer;

        &::after {
          content: $line-icon-eye;
          @include line-icon-font();
          font-size: em(20);
          line-height: 54px;
          color: #bac0c7;
        }

        &.active::after {
          color: $blue-medium;
        }

        &:hover::after {
          color: $blue-medium;
        }

        &:focus::after {
          border-left: 1px dotted $blue-medium;
          width: 54px
        }
      }
    }

    &.error {

      .input-field {
        border: 1px solid $red-error;
        margin-bottom: 6px;
      }
    }
  }

  input[type="password"] {
    font: small-caption !important;
    font-size: em(28) !important;
    letter-spacing: 5px !important;
    padding-top: 16px !important;
  }
</style>
