<template>
  <form
    ref="loginForm"
    class="login-form"
    @keyup.enter="submitLoginForm"
  >
    <div class="social-login">
      <div
        ref="pinsButton"
        tabindex="0"
        class="social-block with-pins full-width"
        @click="openPinsLoginModal()"
        @keyup.enter="openPinsLoginModal()"
      />
    </div>

    <div class="separator">
      <span>{{ $t('header.white.login.orText') }}</span>
    </div>

    <form-errors v-if="genericErrors.length > 0">
      <li
        v-for="(errorMessage, index) in genericErrors"
        :key="index"
        v-dompurify-html="$t(errorMessage)"
      />
    </form-errors>

    <div
      class="field-wrap dynamic email"
      :class="{ error: fieldHasError('email') }"
    >
      <input
        type="email"
        autocomplete="off"
        :value="form.email"
        :class="{ 'not-empty': form.email !== '' }"
        @input="onEmailInput($event)"
      />
      <label>{{ $t('header.white.login.placeholder.email') }}</label>

      <div
        v-if="getErrors('email')"
        class="error-msg"
      >
        {{ getErrors('email') }}
      </div>
    </div>

    <div
      class="field-wrap dynamic password"
      :class="{ error: fieldHasError('password') }"
    >
      <input
        :type="passwordFieldType"
        autocomplete="off"
        :class="{ 'not-empty': form.password !== '' }"
        @input="onPasswordInput($event)"
      />
      <label>{{ $t('header.white.login.placeholder.password') }}</label>
      <div
        v-tooltip="passwordTooltip"
        tabindex="0"
        class="show-password"
        :class="{ active: showPassword }"
        @click="toggleShowPassword()"
        @keyup.enter="toggleShowPassword()"
      >
        <i class="icon" />
      </div>

      <div
        v-if="getErrors('password')"
        class="error-msg"
      >
        {{ getErrors('password') }}
      </div>
    </div>

    <div class="manage-login">
      <div
        v-if="isLogin"
        tabindex="0"
        class="forgot-password"
        @click="openForgotPassword()"
        @keyup.enter="openForgotPassword()"
      >
        {{ $t('header.loginForm.forgotPasswordLink') }}
      </div>
    </div>

    <span
      v-dompurify-html="$t('privacyPolicyAgreement.loginOrSignup')"
      class="privacy-policy-agreement white"
    />

    <div
      class="btn btn-blue btn-login"
      @click="submitLoginForm"
    >
      <button type="button">
        {{ $t('header.white.login.submitButtonText') }}
      </button>
    </div>
    <small-loader />
  </form>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import FormErrors from 'components/FormErrors/FormErrors'
  import PinsLoginForm from 'components/LoginForm/PinsLoginForm'
  import SmallLoader from 'components/SmallLoader'
  import Tooltip from 'utils/directives/Tooltip'
  import FormMixin from 'components/Forms/FormMixin'

  const LOGIN_EVENT_ACTIONS = {
    pins: 'pins',
    email: 'email'
  }

  export default {
    directives: {
      Tooltip
    },

    components: {
      SmallLoader,
      FormErrors
    },

    mixins: [FormMixin],

    props: {
      showTooltip: {
        type: Boolean,
        default: true,
        required: false
      },
      internal: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        isLogin: true,
        showPassword: false
      }
    },

    computed: {
      passwordFieldType() {
        return this.showPassword ? 'text' : 'password'
      },

      passwordTooltip() {
        return {
          content: this.$t('header.white.login.tooltip'),
          position: 'top',
          class: 'js-login-tooltip'
        }
      },
      ...mapGetters('loginForm', ['$v', 'loginFormErrors']),
      ...mapState('loginForm', ['form', 'genericErrors']),
      ...mapState('main', ['selectedLang'])
    },

    destroyed() {
      this.$store.dispatch('loginForm/resetForm')
    },

    methods: {
      openForgotPassword() {
        this.$store.commit('loginForm/forgotPassword/setEmail', this.login)
        this.$emit('openForgotPassword')
      },

      closeModal() {
        this.$store.dispatch('main/closeModal')
        this.$store.dispatch('loginForm/forgotPassword/resetForm')
      },

      pinsSuccess() {
        this.$emit('success', 'pins')
      },

      pinsFail() {
        this.$emit('fail', { action: LOGIN_EVENT_ACTIONS.pins })
      },

      openPinsLoginModal() {
        this.$store.dispatch('main/openModal')
        this.$store.commit('main/setModalContent', PinsLoginForm)
        this.$store.commit('main/setModalCloseFn', this.closeModal)
        this.$store.commit('main/setModalType', 'sidebar')
        this.$store.commit('main/setModalClass', 'modal-white-sidebar')
        this.$store.commit('main/setShowModalHeaderWhiteNav', true)
        this.$store.commit('main/setShowModalHeader', false)
        this.$store.commit('main/setShowCloseIcon', true)
        this.$store.commit('main/setModalProps', {
          type: 'login',
          internal: this.internal
        })
        this.$store.commit('main/setModalEvents', {
          success: this.pinsSuccess,
          fail: this.pinsFail
        })
      },

      submitLoginForm() {
        this.$store.commit('main/setShowSmallLoader', true)
        this.$store
          .dispatch('loginForm/validateForm')
          .then(() => {
            this.$store
              .dispatch('loginForm/submitForm', this.internal)
              .then(() => this.$emit('success'))
              .catch((e) =>
                this.$emit('fail', { action: LOGIN_EVENT_ACTIONS.email, error: e })
              )
              .finally(() => this.$store.commit('main/setShowSmallLoader', false))
          })
          .catch(() => this.$store.commit('main/setShowSmallLoader', false))
      },

      toggleShowPassword() {
        this.showPassword = !this.showPassword
      },

      onEmailInput(event) {
        this.$store.commit('loginForm/setEmail', event.target.value)
      },

      onPasswordInput(event) {
        this.$store.commit('loginForm/setPass', event.target.value)
      },
      fieldHasError(field) {
        return this.$_formMixin_hasError(this.$v, field)
      },
      getErrors(field) {
        return this.$_formMixin_errorMsg(this.$v, this.loginFormErrors, field)
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
>
  @import '~assets/styles/components/tooltip.scss';

  .js-tooltip.js-login-tooltip {
    @include tooltip();
    max-width: 200px;
    width: 100%;
    text-align: center;
  }
</style>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .social-login {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .social-block {
    align-items: center;
    background-color: $blue-white;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    height: 92px;
    justify-content: center;
    padding: 15px;
    width: 100px;

    @include outline-focus;

    &:hover {
      background-color: $gray-light-hover;
    }

    &:before {
      display: block;
      width: 32px;
      height: 32px;
      margin: 0 auto 7px;
      border-radius: 50%;
    }

    span {
      display: block;
      font-size: em(12);
      font-weight: 500;
      text-align: center;
      white-space: normal;
      line-height: 16px;
      color: $blue-medium;
    }
  }

  .with-pins {
    background: url('~assets/images/logos/airBaltic-club-logo.svg') no-repeat;
    background-size: 67px 22px;
    text-align: center;
    background-position: center;
    width: 100px;
    background-color: $blue-white;

    &.full-width {
      background-size: 99px 33px;
    }
  }

  .full-width {
    width: 100%;
  }

  .manage-login {
    margin-top: 11px;
    @include clearfix();

    .forgot-password {
      float: right;
      margin-left: -4px;
      padding-left: 10px;
      @include text-overflow();
      text-align: right;
      font-weight: 500;
      line-height: 26px;
      color: $blue-medium;
      cursor: pointer;

      @include outline-focus;

      &:hover {
        color: $blue-light;
      }
    }
  }

  .login-form {
    .separator {
      position: relative;
      margin: 20px 0;
      text-align: center;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 9px;
        width: 100%;
        height: 1px;
        background-color: $grey-border;
      }

      span {
        position: relative;
        display: inline-block;
        padding: 0 10px;
        background-color: white;
        font-size: em(12);
        color: $gray-text-color;
      }
    }

    .field-wrap {
      &.email {
        margin-bottom: 16px;
      }

      &.password {
        input,
        label {
          padding-right: 66px;
        }
      }

      input {
        border: 1px solid $formInputBorder;

        &:focus {
          border: 1px solid $gray-focus-border;
        }

        @include outline-focus;
      }

      .show-password {
        position: absolute;
        top: 1px;
        right: 1px;
        width: 46px;
        height: 46px;
        background-color: white;
        border-left: 1px solid $formInputBorder;
        text-align: center;
        cursor: pointer;

        @include outline-focus;

        .icon:after {
          content: $line-icon-eye;
          @include line-icon-font();
          font-size: em(20);
          line-height: 48px;
          color: $gray-focus-border;
        }

        &.active .icon:after {
          color: $blue-medium;
        }

        &:hover {
          .icon:after {
            color: $blue-medium;
          }
        }
      }
    }

    .btn-login {
      width: 100%;
      height: 48px;

      button {
        padding: 0 16px;
        font-size: em(14);
        line-height: 48px;

        @include outline-focus;
      }
    }
  }

  .privacy-policy-agreement {
    margin: 6px auto 13px;
    max-width: 270px;
    line-height: 21px;
    text-align: center;

    ::v-deep a {
      font-weight: 500;

      @include outline-focus;
    }
  }
</style>
