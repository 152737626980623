import AbstractView from 'abstract';
import Vue from 'vue'
import loginInit from 'initializations/pages/Pax/Clean/Login'
import loginPage from 'containers/Pages/Profile/Clean/Login'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        loginInit.init("login")

        new Vue({
            store,
            ...loginPage
        }).$mount("#" + this.elemId)
    }
}
