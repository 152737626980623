import SingleDestination from 'store/modules/Pages/SingleDestination'
import store from 'definitions/store'
import OrigDestUtils from 'store/utils/eHorizonOrigDestUtils'
import OrigDest from 'utils/OrigDestData'
import getNearestAirport from 'utils/GetNearestAirport'
import isNull from 'lodash-es/isNull'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, SingleDestination)

      Init.initDefaultValues(name)
      Init.initOrigDestData()
    }
  },
  initDefaultValues(name) {
    if (typeof singleDestinationInit !== "undefined") {
      store.commit(`${name}/setPageLeadImg`, singleDestinationInit.image)
      store.commit(`${name}/setPageTitle`, singleDestinationInit.title)
      store.commit(`${name}/setPageTitleFromPrice`, singleDestinationInit.titleFromPrice)
      store.commit(`${name}/setHistogramTitle`, singleDestinationInit.titleHistogram)
      store.commit(`${name}/setCountry`, singleDestinationInit.country)

      store.commit(`${name}/setIntro`, singleDestinationInit.intro)
      store.commit(`${name}/setSecondIntro`, singleDestinationInit.secondaryIntro)
      store.commit(`${name}/setSecondFull`, singleDestinationInit.secondaryFull)

      store.commit(`${name}/setSections`, singleDestinationInit.sections)

      store.commit(`${name}/setMetaDescription`, singleDestinationInit.metaDescription)

      store.commit(`indexTabs/setBannersData`, singleDestinationInit.banners, { root: true })

      let origin = ''

      if (singleDestinationInit.origin) {
        origin = singleDestinationInit.origin
      } else {
        const nearest = getNearestAirport()
        origin = isNull(nearest) ? 'RIXA' : nearest
      }

      let hasSameDestination = (origin === singleDestinationInit.airportInfo.airport)

      store.commit(`${name}/setAirportInfo`, {
        ...singleDestinationInit.airportInfo,
        hasSameDestination
      })
      store.commit(`${name}/setOrigin`, !hasSameDestination ? origin : '')

      if (typeof origDestData !== 'undefined') {
        const data = OrigDest.parseData(origDestData)
        setTimeout(() => {
          let origData = (typeof data[origin] !== 'undefined') ? data[origin] : {}

          store.dispatch(`eHorizonSearchForm/selectOrigin`, !hasSameDestination ? origData : {})
        })
      }

      if (singleDestinationInit.showTermsAndConditions && typeof termsAndConditions !== "undefined") {
        store.commit(`${name}/setTermsAndConditionsVisible`, true)
        store.commit(`${name}/setTermsAndConditions`, {
          title: termsAndConditions.title.text,
          content: termsAndConditions.content.text
        })
      }
    }
  },

  initOrigDestData () {
    if (typeof origDestData !== 'undefined') {
      const data = OrigDest.parseData(origDestData)

      setTimeout(() => {
        store.commit(`eHorizonSearchForm/setOrigDestData`, data)
        store.commit(`eHorizonSearchForm/origin/setDestinations`, OrigDestUtils.prepareData(data).filter(o => o.isOrig), { root: true })
      }, 200)

    }
  }
}

export default Init
