
class FlightUtils {
  static isCharterFlight(flightList) {
    if (!Array.isArray(flightList)) {
      return false
    }
    let isCharter = false
    flightList.forEach(flight => {
      if ((flight.number >= 7000 && flight.number <= 7399)
        || (flight.number >= 7450 && flight.number <= 7739)
        || (flight.number >= 7750 && flight.number <= 8000)) {
        isCharter = true
      }
    })
    return isCharter
  }
}

export default FlightUtils
