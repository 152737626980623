export default (excludeType = false) => {
  let cookieContent = null
  const cookieName = 'nearestAirportData='
  const ca = document.cookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    const c = ca[parseInt(i)].trim()

    if (c.indexOf(cookieName) === 0) {
      cookieContent = JSON.parse(decodeURIComponent(c.substring(cookieName.length, c.length)))
    }
  }

  if (cookieContent !== null) {

    if (excludeType) {
      return cookieContent.code
    }

    let airportCode = 'A'

    if (cookieContent.isAllAirports) {
      airportCode = 'C'
    }

    return cookieContent.code + airportCode
  }

  return null
}
