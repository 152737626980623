import { betweenLength, isDate12HoursBefore, isDateAfter, isValidDate, regex } from 'utils/validators';
import { required } from 'vuelidate/lib/validators';
import * as moment from 'moment';
import isUndefined from 'lodash-es/isUndefined';
var id = 0;
var minLength = 3;
var maxLength = 6;
var Flight = /** @class */ (function () {
    function Flight(obj) {
        if (obj === void 0) { obj = {}; }
        this.flightNumber = '';
        this.flightDate = '';
        this.id = id;
        this.origin = '';
        this.destin = '';
        this.flightDay = '';
        this.flightMonth = '';
        this.flightYear = '';
        this.carrier = '';
        this.number = '';
        this.departureDate = '';
        this.errorMsg = {
            flightDate: {
                date12HoursBefore: 'reusable.flightDate.validation.date12HoursBefore',
                dateAfterToday: 'reusable.flightDate.validation.dateAfterToday',
                isValidDate: 'reusable.flightDate.validation.invalid',
                required: 'reusable.flightDate.validation.required',
            },
            flightNumber: {
                between: 'reusable.flightNumber.validation.between',
                flightNumberInvalid: 'reusable.flightNumber.validation.invalid',
                required: 'reusable.flightNumber.validation.required',
            },
            selectedFlights: {
                checked: 'reusable.checked.validation.isTrue',
            },
        };
        this.validation = {
            flights: {
                $each: {
                    flightDate: {
                        date12HoursBefore: isDate12HoursBefore,
                        dateAfterToday: isDateAfter,
                        isValidDate: isValidDate,
                        required: required,
                    },
                    flightNumber: {
                        between: betweenLength(minLength, maxLength),
                        flightNumberInvalid: regex('flightNumber'),
                        required: required,
                    },
                },
            },
            selectedFlights: {
                checked: function (value) { return value.length; },
            },
        };
        if (typeof obj !== 'undefined') {
            var date = moment(obj.departureDate);
            var parsedNumber = obj.flightNumber;
            if (!isUndefined(obj.carrier) && !isUndefined(obj.number)) {
                parsedNumber = ("".concat(obj.carrier).concat(obj.number)).trim();
            }
            var data = {
                flightNumber: parsedNumber,
                flightDate: date,
                origin: obj.origin,
                destin: obj.destin,
                flightDay: date.format('DD'),
                flightMonth: date.format('MM'),
                flightYear: date.format('YYYY'),
            };
            Object.assign(this, obj, data);
        }
        id++;
    }
    return Flight;
}());
export default Flight;
