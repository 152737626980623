import Generic from 'store/modules/Forms/EditMyProfile/Generic'
import EditProfileForm from 'store/modules/Forms/EditMyProfile/EditMyProfile'

const RegisterPage = {
  namespaced: true,
  modules: {
    generic: Generic,
    form: EditProfileForm,
  },
  actions: {
    setEmail ({ commit }, value) {
      commit('form/setEmail', value)
    },
    setMinorEmail ({ commit }, value) {
      commit('form/setMinorEmail', value)
    },
    setResidenceCountry ({ commit }, value) {
      commit('form/setResidenceCountry', value)
    },
    setLanguage ({ commit }, value) {
      commit('form/setLanguage', value)
    },
    setNotification ({ commit }, value) {
      commit('form/setNotification', value)
    },
    connectNotification ({ dispatch }, value) {
      dispatch('form/connectNotification', value)
    },
    setOldPassword ({ commit }, value) {
      commit('form/setOldPassword', value)
    },
    setPassword ({ commit }, value) {
      commit('form/setPassword', value)
    },
    setPasswordRepeat ({ commit }, value) {
      commit('form/setPasswordRepeat', value)
    },
    setDeleteConfirm ({ commit }, value) {
      commit('form/setDeleteConfirm', value)
    },
    clearForm ({ dispatch }) {
      dispatch('form/clearForm')
    },
    subscribeEmail ({ dispatch }) {
      return dispatch('form/subscribeEmail')
    },
    unsubscribeEmail ({ dispatch }) {
      return dispatch('form/unsubscribeEmail')
    }
  },
  getters: {
    form (state) {
      return state.form
    },
    pageTitle (state) {
      return state.generic.pageTitle
    },
    platforms (state) {
      return state.generic.platforms
    },
    formSections (state) {
      return state.generic.sections
    },
  }
}

export default RegisterPage
