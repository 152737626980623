import BusinessLoungeStore from 'store/modules/Pages/SearchTable'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, BusinessLoungeStore)
      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    if (typeof pageData !== "undefined") {
      store.commit(`${name}/setPageData`, pageData)
      if (typeof termsAndConditions !== "undefined") {
        store.commit(`${name}/setTermsAndConditions`, {
          title: termsAndConditions.title.text,
          content: termsAndConditions.content.text
        })
      }

      if (typeof tableData !== "undefined") {
        store.dispatch(`${name}/setInitTableData`, tableData)
      }
    }
  }
}

export default Init
