import BestBuyBanners from 'store/modules/Pages/BestBuyBanners'

const defaultState = {
  sections: [],
  inspirationalBanner: [],
  cardSection: {},
  blogSection: {},
  serviceSection: {},
  ad: {},
  everyMundoCards: {},
  subscribeSection: {},
  whyUsSection: {},
  labelBanners: {},
  countries: {}
}

const IndexPage = {
  state: defaultState,
  modules: {
    bestBuyBanners: BestBuyBanners
  },
  mutations: {
    setInspirationalBanner(state, value) {
      state.inspirationalBanner = value
    },
    setSections(state, value) {
      state.sections = value
    },
    setCardSection(state, value) {
      state.cardSection = value
    },
    setServiceSection(state, value) {
      state.serviceSection = value
    },
    setBlogSection(state, value) {
      state.blogSection = value
    },
    setAdSection(state, value) {
      state.adSection = value
    },
    setEveryMundoCardsSection(state, value) {
      state.everyMundoCards = value
    },
    setSubscribeSection(state, value) {
      state.subscribeSection = value
    },
    setWhyUsSection(state, value) {
      state.whyUsSection = value
    },
    setLabelBannerSection(state, value) {
      state.labelBanners = value
    },
    setCountries(state, value) {
      state.countries = value
    }
  },
  namespaced: true
}

export default IndexPage
