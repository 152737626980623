import AbstractView from 'abstract'
import Vue from 'vue'
import confirmationPageInit from 'initializations/pages/Confirmation'
import createProfilePageInit from 'initializations/pages/Pax/CreateProfile'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId)
    }

    init() {
        confirmationPageInit.init('confirmationPage')
        createProfilePageInit.init('createProfile')

        const SignUpConfirmationPage = () => import(/* webpackChunkName: "sign-up-confirmation-page" */ 'containers/Pages/Profile/SignUpConfirmation')

        new Vue({
            el: "#" + this.elemId,
            store,
            render: h => h(SignUpConfirmationPage)
        })
    }
}
