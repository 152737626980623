import AbstractView from 'abstract';
import Vue from 'vue'
import resetPasswordInit from 'initializations/pages/Pax/ResetPassword'
import SettingsInit from 'initializations/pages/Pax/ProfileSettings'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init () {
      resetPasswordInit.init("resetPassword")
      SettingsInit.init("profileSettings")

      const resetPasswordPage = () => import(/* webpackChunkName: "reset-password-page" */ 'containers/Pages/Profile/ResetPassword')
      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(resetPasswordPage)
      })
    }
}
