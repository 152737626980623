import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import bookingCancelationInit from 'initializations/pages/Payment/BookingCancelation'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    bookingCancelationInit.init('bookingCancelation')

    const BookingCancelation = () => import(/* webpackChunkName: "booking-cancelation" */ 'containers/Pages/Payment/BookingCancelation')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(BookingCancelation)
    })
  }
}
