const GiftPage = {
  state: {
    pageTitle: '',
    description: '',
    leftButtonTitle: '',
    leftButtonSubTitle: '',
    leftButtonLink: '',
    leftButtonTarget: '',
    leftButtonCaption: '',
    rightButtonTitle: '',
    rightButtonSubTitle: '',
    rightButtonLink: '',
    rightButtonTarget: '',
    rightButtonCaption: '',
    detailsTitle: '',
    detailsText: ''
  },
  mutations: {
    setPageTitle (state, value) {
      state.pageTitle = value
    },
    setDescription (state, value) {
      state.description = value
    },
    setLeftButtonTitle (state, value) {
      state.leftButtonTitle = value
    },
    setLeftButtonSubTitle (state, value) {
      state.leftButtonSubTitle = value
    },
    setLeftButtonLink (state, value) {
      state.leftButtonLink = value
    },
    setLeftButtonTarget (state, value) {
      state.leftButtonTarget = value
    },
    setLeftButtonCaption (state, value) {
      state.leftButtonCaption = value
    },
    setRightButtonTitle (state, value) {
      state.rightButtonTitle = value
    },
    setRightButtonSubTitle (state, value) {
      state.rightButtonSubTitle = value
    },
    setRightButtonLink (state, value) {
      state.rightButtonLink = value
    },
    setRightButtonTarget (state, value) {
      state.rightButtonTarget = value
    },
    setRightButtonCaption (state, value) {
      state.rightButtonCaption = value
    },
    setDetailsTitle (state, value) {
      state.detailsTitle = value
    },
    setDetailsText (state, value) {
      state.detailsText = value
    }
  },
  namespaced: true
}

export default GiftPage
