<template>
  <div
    :class="['e-tab', name, { 'external': external, 'active': active }]"
    @click="onTabClicked(name)"
  >
    <span class="label">
      {{ $t(`indexTabs.eHorizon.${nameLowerCase}TabLabel`) }}
    </span>
  </div>
</template>

<script>
  import gtmTabs from 'services/AirBaltic/GTM/Tabs'

  export default {
    props: {
      name: {
        type: String,
        required: true
      },
      href: {
        type: String,
        required: false,
        default: '#'
      },
      target: {
        type: String,
        default: '',
        required: false
      },
      external: {
        type: Boolean,
        required: false
      },
      active: {
        type: Boolean,
        required: false
      }
    },
    computed: {
      nameLowerCase () {
        return this.name.toLowerCase()
      }
    },
    methods: {
      onTabClicked (name) {
        gtmTabs.tabClick(name)

        if (this.external) {
          window.open(this.href, this.target)
        }

        this.$emit('tab-click', name)
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  .e-tab {
    border-radius: 20px;
    background: white;
    padding: 8px 16px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: em(12);
    font-weight: 500;
    line-height: 16px;
    color: $blue-dark;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: $green-base;
    }
  }
</style>
