import Generic from 'store/modules/Forms/RegisterProfile/Generic'
import RegisterProfileForm from 'store/modules/Forms/RegisterProfile/RegisterProfile'

const RegisterPage = {
  namespaced: true,
  modules: {
    generic: Generic,
    form: RegisterProfileForm,
  },
  actions: {
    validateToken ({ dispatch }, token) {
      return dispatch('form/validateToken', token)
    },
    setFirstName ({ commit }, value) {
      commit(`form/setFirstName`, value)
    },
    setLastName ({ commit }, value) {
      commit(`form/setLastName`, value)
    },
    setTitle ({ commit }, value) {
      commit(`form/setTitle`, value)
    },
    setDate ({ commit }, value) {
      commit('form/setDateOfBirth', value)
    },
    setDay ({ commit, dispatch }, value) {
      commit(`form/setDay`,  value)
      dispatch(`form/setDob`)
    },
    setMonth ({ commit, dispatch }, value) {
      commit(`form/setMonth`,  value)
      dispatch(`form/setDob`)
    },
    setYear ({ commit, dispatch }, value) {
      commit(`form/setYear`, value)
      dispatch(`form/setDob`)
    },
    setEmail ({ commit }, value) {
      commit('form/setEmail', value)
    },
    setIsABCMember ({ commit }, value) {
      commit('form/setIsABCMember', value)
    },
    setCardNumber ({ commit }, value) {
      commit('form/setCardNumber', value)
    },
    setResidenceCountry ({ commit }, value) {
      commit('form/setResidenceCountry', value)
    },
    setLanguage ({ commit }, value) {
      commit('form/setLanguage', value)
    },
    setNotification ({ commit }, value) {
      commit('form/setNotification', value)
    },
    setPassword ({ commit }, value) {
      commit('form/setPassword', value)
    },
    setPasswordRepeat ({ commit }, value) {
      commit('form/setPasswordRepeat', value)
    },
    setGuardianEmail ({ commit }, value) {
      commit('form/setGuardianEmail', value)
    },
    setGuardianCard ({ commit }, value) {
      commit('form/setGuardianCard', value)
    },
    setGuardianConsent ({ commit }, value) {
      commit('form/setGuardianConsent', value)
    },
    setIntCode ({ commit }, value) {
      commit('form/setIntCode', value)
    },
    setPhoneNumber({ commit }, value) {
      commit('form/setPhoneNumber', value)
    },
    setToken ({ commit }, value) {
      commit('form/setToken', value)
    }
  },
  getters: {
    form (state) {
      return state.form
    },
    pageTitle (state) {
      return state.generic.pageTitle
    },
    platforms (state) {
      return state.generic.platforms
    },
    formSections (state) {
      return state.generic.sections
    },
  }
}

export default RegisterPage
