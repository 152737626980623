import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import ContactUsInit from 'initializations/pages/ContactUsPage'
import SidebarInit from 'initializations/Sidebar'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        SidebarInit.init("sidebar")
        ContactUsInit.init("contactUsPage")

        const ContactUsPage = () => import(/* webpackChunkName: "container-contact-us-page" */ 'containers/Pages/ContactUs')
        new Vue({
            el: "#" + this.elemId,
            store,
            render: h => h(ContactUsPage)
        })
    }
}
