import Pimcore from 'services/AirBaltic/Pimcore'
import Vue from 'vue'
import conf from 'conf'
import get from 'lodash-es/get'
import DestinationSiloItem from 'models/Destinations/DestinationSiloItem'

class Destinations {
  convertData (response) {
    const result = []

    response.data.forEach((o) => {
      const item = new DestinationSiloItem()
      item.code = get(o, 'code', '')
      item.href = get(o, 'href', '')
      item.image = get(o, 'image', '')

      result.push(item)
    })

    return result
  }

  getDestinations (airportCodes) {
    return new Pimcore().get(`/destinations/${Vue.i18n.locale()}${conf.btUrlCors}`, airportCodes)
      .then(response => Promise.resolve(this.convertData(response)))
      .catch(error => Promise.reject(error))
  }
}

export default Destinations
