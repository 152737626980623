import findIndex from 'lodash-es/findIndex'
import cloneDeep from 'lodash-es/cloneDeep'
import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import Step3Modal from 'models/Pax/GroupBooking/Step3'

const step3Modal = new Step3Modal()
const formState = Object.assign({}, { formErrors: step3Modal.errorMsg }, step3Modal)

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return formState
    }
  },
  validations () {
    return {
      state: step3Modal.validations
    }
  }
})

const Step3 = {
  namespaced: true,
  state: formState,
  mutations: {
    setPrice (state, value) {
      state.price = value
    },
    setServices (state, value) {
      state.services = value
    },
    setAdditionalInfo (state, value) {
      state.additionalInfo = value
    },
    updateSelectedList (state, value) {
      state.selectedServices = value
    },
    setContactType (state, value) {
      state.contactType = value
    }
  },
  actions: {
    clearStep ({ commit }) {
      validator.$v.state.$reset()

      commit('setPrice', 0)
      commit('updateSelectedList', [])
      commit('setAdditionalInfo', '')
    },
    updateSelected({ commit, state }, value) {
      const arr = cloneDeep(state.selectedServices)
      const index = findIndex(arr, (key) => key === value)
      if (index === -1) {
        arr.push(value)
      } else {
        arr.splice(index, 1)
      }
      commit('updateSelectedList', arr)
    },
    validateStep () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state
        vState.$touch()

        return !vState.$invalid ? resolve() : reject()
      })
    },
    getData ({ state }) {
      const data = {
        preferredPrice: state.price !== null ? state.price : 0,
        travelExtras: state.selectedServices,
        message: state.additionalInfo
      }

      return Promise.resolve(data)
    }
  },
  getters: {
    $v () {
      return Object.assign({}, validator.$v.state)
    },
    step3FormErrors (state) {
      return state.formErrors
    }
  }
}

export default Step3
