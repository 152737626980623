import Vue from 'vue'
import store from 'definitions/store'
import headerInit from 'initializations/HeaderWhite'
import loginFormInit from 'initializations/LoginForm'
import AbstractView from 'abstract'
import HeaderWhite from 'templates/HeaderWhite'
import DeviceMixin from 'containers/DeviceMixin'
import mainInit from 'initializations/Main'
import searchInit from 'initializations/pages/Search'
import footerInit from 'initializations/FooterWide'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        mainInit.init("main")
        headerInit.init("header")
        searchInit.init("search")
        loginFormInit.init("loginForm")
        footerInit.init("footer")

        new Vue({
            el: '#header',
            mixins: [DeviceMixin],
            store,
            render: h => h(HeaderWhite)
        })
    }
}