import toPairs from 'lodash-es/toPairs'
import values from 'lodash-es/values'

class OrigDestUtils {
  static filterData (constraint, data, ignoreConstraint) {
    function aptCompare (a, b) {
      if (typeof a.apt !== 'undefined' && (a.apt !== null && b.apt !== null)) {
        return a.apt.localeCompare(b.apt)
      }
      return 0
    }

    if (!constraint || ignoreConstraint) {
      return data.slice().sort(aptCompare)
    }

    function escapeRegExp (str) {
      return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
    }

    const matchesCity = []
    const matchesAirport = []
    const matchesCountry = []
    const matchesCode = []
    const matchesCityCode = []
    const matchesAptWithCode = []
    const dividers = "^| |[(]|/|-|'|`"
    const substrRegex = new RegExp(`(${dividers})${escapeRegExp(constraint)}`, 'i')

    data.forEach((dest) => {
      if (substrRegex.test(dest.city)) {
        matchesCity.push(dest)
      } else if (substrRegex.test(dest.apt)) {
        matchesAirport.push(dest)
      } else if (substrRegex.test(dest.country)) {
        matchesCountry.push(dest)
      } else if (substrRegex.test(dest.code)) {
        matchesCode.push(dest)
      } else if (substrRegex.test(dest.cityCode)) {
        matchesCityCode.push(dest)
      } else if (substrRegex.test(dest.aptWithCode)) {
        matchesAptWithCode.push(dest)
      }
    })

    matchesCity.sort(aptCompare)
    matchesAirport.sort(aptCompare)
    matchesCountry.sort(aptCompare)
    matchesCode.sort(aptCompare)
    matchesCityCode.sort(aptCompare)
    matchesAptWithCode.sort(aptCompare)

    return matchesCity.concat(
      matchesAirport,
      matchesCountry,
      matchesCode,
      matchesCityCode,
      matchesAptWithCode
    )
  }

  static extractDestinations (origin, origDestData) {
    // filter items
    if (typeof origin === 'undefined' || origin.code === undefined) {
      return []
    }

    const destinationsMap = new Map(toPairs(origin.destinations))
    const destinationsArray = [...destinationsMap]
    return destinationsArray.map(([codeAndType, value]) => {
      const fullDestin = origDestData[`${codeAndType}`]
      const mergedDestin = Object.assign({}, value, fullDestin)
      delete mergedDestin.destinations
      return mergedDestin
    })
  }

  static toLabel (data) {
    if (data && data.apt && data.code) {
      return `${data.apt} (${data.code}) - ${data.country}`
    }
    return ''
  }

  static prepareData (data) {
    const withAirports = values(data).map((o) => {
      o.aptWithCode = OrigDestUtils.toLabel(o)
      return o
    })
    return withAirports
  }

  static sortLabelsBySpecificOrder(data, order) {
    if (!order) {
      return data
    }

    order = order.replace(/\s/g,'')

    const listOrder = order.split(',').reverse()

    return data.sort((a, b) => {
      if (listOrder.indexOf(a.key) > listOrder.indexOf(b.key)) {
        return -1
      }

      if (listOrder.indexOf(a.key) < listOrder.indexOf(b.key)) {
        return 1
      }

      return 0
    })
  }
}

export default OrigDestUtils
