import { createPopper } from '@popperjs/core'
import isEqual from 'lodash-es/isEqual'

class TooltipHelper {
  constructor(element, binding) {
    this.options = {
      event: 'mouseover',
      visible: false,
      position: 'top',
      class: '',
      content: '',
      over: false,
      disabled: false,
      flip: false
    }

    this.loadOptions(binding.value)

    this.element = element
    this.popperInstance = null
    this.tooltip = null
    this.arrow = null

    this.setEvents(binding)
  }

  loadOptions (options) {
    for (const key in options) {
      this.options[`${key}`] = options[`${key}`]
    }
  }

  setEvents (binding) {
    if (binding.value.visible !== undefined || this.options.disabled) {
      return
    }

    if (typeof binding.value !== 'object') {
      this.options.content = binding.value
    }

    this.element.addEventListener(this.options.event, () => {
      if (this.options.over && this.options.event === 'mouseover' || this.options.disabled) {
        return
      }

      this.options.over = true
      this.options.visible = !this.options.visible

      if (!this.options.visible) {
        this.destroy()
        return
      }

      this.init()
    })

    this.mouseClickEvent()
    this.mouseLeaveEvent()
    this.resizeEvent()
  }

  destroy() {
    if (!this.popperInstance) {
      return
    }

    this.options.visible = false
    this.popperInstance.destroy()
    this.popperInstance = null
    this.removeTooltip()
  }

  removeTooltip () {
    if (!this.tooltip) {
      return
    }

    if (!this.tooltip?.parentNode) return

    this.tooltip.parentNode.removeChild(this.tooltip)
    this.tooltip = null
  }

  update (binding) {
    if (isEqual(binding.oldValue, binding.value)) {
      return
    }

    this.loadOptions(binding.value)
    this.element.disabled = this.options.disabled

    if (binding.value.visible === undefined || this.options.disabled) {
      return
    }

    if (this.options.visible) {
      this.options.visible = !this.options.visible
      this.init()
      return
    }

    if (binding.value.visible) {
      this.options.over = false
    }

    this.destroy()
  }

  mouseClickEvent () {
    if (this.options.event !== 'click') {
      return
    }

    document.addEventListener('click', (e) => {
      if (!this.element.contains(e.target)) {
        if (this.options.visible) {
          this.options.visible = false
          this.destroy()
        }
      }
    })
  }

  mouseLeaveEvent () {
    if (this.options.event !== 'mouseover') {
      return
    }

    this.element.addEventListener('mouseleave', () => {
      this.options.over = false
      this.options.visible = false
      this.destroy()
    })
  }

  resizeEvent () {
    window.addEventListener('resize', () => {
      if (!this.options.visible) {
        return
      }

      this.options.visible = false
      this.options.over = false
      this.destroy()
    })
  }

  init () {
    this.createTooltipElement()

    const options = {
      placement: this.options.position,
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            padding: 8
          }
        }
      ]
    }

    if (this.options.flip) {
      options.modifiers.push({
        name: 'flip',
        options: {
          fallbackPlacements: this.options.flip
        }
      })
    }

    this.popperInstance = createPopper(this.element, this.tooltip, options)
  }

  createTooltipElement () {
    if (this.tooltip) {
      return
    }

    const tooltip = document.createElement('div')
    const tooltipCover = document.createElement('div')
    const arrow = document.createElement('div')

    arrow.className = 'tooltip-arrow'
    tooltipCover.setAttribute('data-popper-arrow', true)
    tooltipCover.className = 'tooltip-cover'
    tooltipCover.appendChild(arrow)
    tooltip.className = `js-tooltip ${this.options.class}`
    tooltip.innerHTML = this.options.content

    this.tooltip = tooltip
    this.arrow = arrow
    tooltip.appendChild(tooltipCover)
    document.body.appendChild(tooltip)
  }
}

export default {
  bind: (el, binding) => {
    el.tooltip = new TooltipHelper(el, binding)
  },

  update: (el, binding) => {
    el.tooltip.update(binding)
  }
}
