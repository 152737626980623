import AuthorizedApi from 'services/AirBaltic/AuthorizedApi'
import ErrorHandler from 'utils/ErrorHandler'
import moment from "moment"

const ERRORS = {
  WRONG_USERNAME_OR_PASSWORD: 'pins.errors.wrongUsernameOrPassword',
  EMAIL_ALREADY_SENT: 'generic.errors.emailAlreadySent'
}

class Profile {
  getPaxProfileData () {
    return new AuthorizedApi()
      .get('/profile/data')
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  }

  updatePaxProfile (profileData) {
    return new AuthorizedApi()
      .post('/profile/edit', profileData)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  }

  renewUserToken () {
    const refreshToken = window.localStorage.getItem('refreshToken')
    return new AuthorizedApi()
      .post('/profile/v3/login/refresh', {
        refreshToken
      })
      .then(response => {
        const token = response.data.refreshToken
        window.localStorage.setItem('refreshToken', token)
        return Promise.resolve(response.data)
      })
      .catch(error => Promise.reject(error))
  }

  deletePaxProfile () {
    return new AuthorizedApi()
      .delete('/profile/delete')
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  }

  notificationEmailSubscribe () {
    return new AuthorizedApi()
      .post('/profile/edit/subscribe')
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(ErrorHandler.getErrorTranslationKey(ERRORS, error.response)))
  }

  notificationEmailUnSubscribe() {
    return new AuthorizedApi()
      .post('/profile/edit/unsubscribe')
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error))
  }

  updatePassword (data) {
    const refreshToken = window.localStorage.getItem('refreshToken')
    data = {
      ...data,
      refreshToken
    }

    return new AuthorizedApi()
      .post('/profile/v2/edit/changePassword', data)
      .then(response => {
        const refreshToken = response.data.refreshToken
        window.localStorage.setItem('refreshToken', refreshToken)
        return Promise.resolve(response.data)
      })
      .catch(error => Promise.reject(ErrorHandler.getErrorTranslationKey(ERRORS, error.response)))
  }

  /**
   * @param {Object} data
   * @param {string} data.email
   * @param {string} data.language
   * @param {string} data.profileCreateLink
   */
  sendInvitationEmail (data) {
    return new AuthorizedApi()
      .post('/profile/new/v2/sendInvitationEmail', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(ErrorHandler.getErrorTranslationKey(ERRORS, error.response)))
  }
}

export default Profile
