import { alphaWithSpaces, pnr, ticketNumber } from 'utils/validators';
import { minLength, or, required } from 'vuelidate/lib/validators';
var PnrValidation = /** @class */ (function () {
    function PnrValidation() {
        this.surname = '';
        this.pnr = '';
        this.errorMsg = {
            pnr: {
                pnrOrTicketNumber: 'reusable.pnr.validation.invalid',
                required: 'reusable.pnr.validation.missingValue',
            },
            surname: {
                alphaWithSpaces: 'reusable.surname.validation.onlyLatin',
                minLength: 'reusable.surname.validation.missingValue',
                required: 'reusable.surname.validation.missingValue',
            },
        };
        this.errors = {
            generic: [],
            pnr: [],
            surname: [],
        };
        this.validation = {
            form: {
                pnr: {
                    pnrOrTicketNumber: or(pnr, ticketNumber),
                    required: required,
                },
                surname: {
                    alphaWithSpaces: alphaWithSpaces,
                    minLength: minLength(1),
                    required: required,
                },
            },
        };
    }
    return PnrValidation;
}());
export default PnrValidation;
