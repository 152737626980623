import SnippetTemplate from 'store/modules/Pages/SnippetTemplate'
import store from 'definitions/store'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule(name, SnippetTemplate)

            if (typeof snippetPage !== "undefined") {
                store.commit(`${name}/setPageTitle`, snippetPage.pageTitle)
                store.commit(`${name}/setPageDescription`, snippetPage.pageDescription)
                store.commit(`${name}/setPageTitlePosition`, snippetPage.titlePosition)
            }
        }
    }
}

export default Init
