import HeaderStore from 'store/modules/Header/Header'
import CookieUtils from 'utils/CookieUtils'
import store from 'definitions/store'
import conf from 'conf'

import Storyblok from '../storyblok'

Storyblok.init()

const Init = {
  init(name) {
    if (!store.hasModule[name]) {
      store.registerModule(name, HeaderStore)
    }

    Init.initPageVersion()
    Init.initBreadcrumbs(name)

    if (conf.storyblokEnabled) {
      Init.initTopNavigation(name, store)
      Init.initMainNavigation(name, store)
      Init.initHeaderCounter(name, store)
    } else {
      Init.initProfileMenuLinks(name)
      Init.initHeaderAlert(name)
    }
  },
  initPageVersion() {
    if (typeof fltInit !== 'undefined' && fltInit.version) {
      CookieUtils.setCookie(conf.fsfCookieName, fltInit.version.toUpperCase(), 14)
    }
  },
  initHeaderAlert(name) {
    if (typeof globalAlert !== 'undefined') {
      store.commit(`${name}/setGlobalAlert`, globalAlert)
    }
  },
  initBreadcrumbs(name) {
    if (typeof breadcrumbs !== 'undefined') {
      store.commit(`${name}/setBreadcrumbs`, breadcrumbs)
    }
  },
  initProfileMenuLinks(name) {
    if (typeof profileMenu !== 'undefined') {
      store.commit(`${name}/setProfileMenuLinks`, profileMenu)
    }

    if (typeof profileMenuClean !== 'undefined') {
      store.commit(`${name}/setProfileMenuCleanLinks`, profileMenuClean)
    }
  },
  async initMainNavigation(name, store) {
    try {
      const { mainLinks } = await Storyblok.mainNavigation(store.state.main.selectedLang)

      store.commit(`${name}/setWhiteHeaderLinks`, mainLinks.sections)
      store.commit(`${name}/setTabs`, mainLinks.tabs)
    } catch (error) {
      console.error('initMainNavigation error', error)
    }
  },
  async initTopNavigation(name, store) {
    try {
      const { logo, navigation, profileSection } = await Storyblok.topHeader(
        store.state.main.selectedLang,
        store
      )

      store.commit(`${name}/setLogo`, logo)
      store.commit(`${name}/setTopNavLinks`, navigation)
      store.dispatch(`${name}/setProfileSection`, profileSection)
    } catch (error) {
      console.error('initTopNavigation error', error)
    }
  },
  async initHeaderCounter(name, store) {
    try {
      const { counter } = await Storyblok.headerCounter(store.state.main.selectedLang)

      store.commit(`${name}/setCampaignCounter`, counter)
    } catch (error) {
      console.error('initHeaderCounter error', error)
    }
  }
}

export default Init
