import { alphaWithSpaces, isDateValidOrEmptyWithDashes, pnr, regex } from 'utils/validators';
import { email, maxLength, required } from 'vuelidate/lib/validators';
import ComplimentItems from './ComplimentItems';
var Compliment = /** @class */ (function () {
    function Compliment() {
        this.form = new ComplimentItems();
        this.errorMsg = {
            bookingReference: {
                pnr: 'compliment.validation.bookingReferenceInvalid'
            },
            date: {
                isDateValidOrEmptyWithDashes: 'compliment.validation.dateInvalid'
            },
            email: {
                email: 'compliment.validation.emailInvalid',
                required: 'compliment.validation.emailRequired'
            },
            firstName: {
                alphaWithSpaces: 'compliment.validation.invalidCharacters',
                required: 'compliment.validation.firstNameRequired'
            },
            flightDate: {},
            flightMonth: {},
            flightNumber: {
                invalid: 'reusable.flightNumber.validation.invalid'
            },
            flightYear: {},
            lastName: {
                alphaWithSpaces: 'compliment.validation.invalidCharacters',
                required: 'compliment.validation.lastNameRequired'
            },
            message: {
                maxLength: 'compliment.validation.messageMaxLength',
                required: 'compliment.validation.messageRequired'
            },
            title: {}
        };
        this.validation = {
            form: {
                bookingReference: {
                    pnr: pnr
                },
                date: {
                    isDateValidOrEmptyWithDashes: isDateValidOrEmptyWithDashes
                },
                email: {
                    email: email,
                    required: required
                },
                firstName: {
                    alphaWithSpaces: alphaWithSpaces,
                    required: required
                },
                flightDate: {},
                flightMonth: {},
                flightNumber: {
                    invalid: regex('flightNumber')
                },
                flightYear: {},
                lastName: {
                    alphaWithSpaces: alphaWithSpaces,
                    required: required
                },
                message: {
                    maxLength: maxLength(2000),
                    required: required
                },
                title: {}
            }
        };
    }
    return Compliment;
}());
export default Compliment;
