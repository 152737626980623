import MainStore from 'store/modules/Main'
import CookieUtils from 'utils/CookieUtils'
import moment from 'moment'
import conf from 'conf'
import store from 'definitions/store'
import PaxProfile from 'services/AirBaltic/Pax/Profile'
import PaxPins from 'services/AirBaltic/Pax/Pins'
import PromoPopupContent from 'components/PromoPopupContent'

import get from 'lodash-es/get'

import Storyblok from '../storyblok'

const lvLanguage = 'lv'
const lvCountry = 'LV'

Storyblok.init()

const Init = {
  init(name) {
    if (!store.hasModule(name)) {
      store.registerModule(name, MainStore)
    }

    Init.initLanguageLinks(name)
    Init.initCountries(name)
    Init.initUser(name)
    Init.initPromoPopup()

    if (conf.storyblokEnabled) {
      Init.initGlobalSettings(store)
    } else {
      Init.initCreateProfileLink(name)
      Init.storeRegisterPageUrl()
      Init.storeRegisterAccessibilityPageUrl()
      Init.initIsLoginDisabled()
    }
  },
  initPromoPopup() {
    if (CookieUtils.getCookie('btMobileApp')) {
      return
    }

    if (typeof popupData === 'undefined') {
      return
    }

    const data = popupData

    if (!data) return

    const { id } = data

    if (window.sessionStorage.getItem(`promo_popup_${id}`) === 'closed') {
      return
    }

    store.dispatch('main/openModal')
    store.commit('main/setModalContent', PromoPopupContent)
    store.commit('main/setModalType', 'small')
    store.commit('main/setShowCloseIcon', false)
    store.commit('main/setShowModalHeader', false)
    store.commit('main/setHideModalBackdropOnMobile', false)
    store.commit('main/setModalClass', 'promo-popup-modal')
    store.commit('main/setModalProps', {
      data
    })
  },
  storeRegisterAccessibilityPageUrl() {
    if (typeof accessibilityPageUrl !== 'undefined') {
      store.commit('main/setAccessibilityPageUrl', accessibilityPageUrl)
    }
  },
  storeRegisterPageUrl() {
    if (typeof registerPageUrl !== 'undefined') {
      store.commit(`main/setRegisterPageUrl`, registerPageUrl)
    }
  },
  initLanguageLinks() {
    if (typeof languageSwitcherLinks !== 'undefined') {
      store.commit(`main/location/setLanguageLinks`, languageSwitcherLinks)
    }
  },
  isValueInArray(value, array) {
    let result = false
    array.forEach((obj) => {
      if (obj.code === value) {
        result = true
      }
    })
    return result
  },
  initCountries(name) {
    if (typeof countryCodes !== 'undefined') {
      store.dispatch(`main/location/formatCountries`, countryCodes)
    }

    let lang = CookieUtils.getCookie('language') || lvLanguage
    let country = CookieUtils.getCookie('country') || lvCountry

    if (typeof LANGUAGE !== 'undefined' && LANGUAGE !== '') {
      lang = LANGUAGE
      CookieUtils.setCookie('language', lang)
    }

    if (typeof COUNTRY !== 'undefined' && COUNTRY !== '') {
      country = COUNTRY
      CookieUtils.setCookie('country', country)
    }

    if (!Init.isValueInArray(lang, store.state.main.location.languageLinks)) {
      lang = lvLanguage
    }

    if (!Init.isValueInArray(country, store.state.main.location.countries)) {
      country = lvCountry
    }

    moment.locale(lang)
    store.commit(`${name}/selectLang`, lang)
    store.commit(`${name}/selectCountry`, country)
  },
  initUser() {
    if (CookieUtils.getCookie(conf.jwtCookieName)) {
      new PaxProfile()
        .getPaxProfileData()
        .then((response) => {
          store.dispatch('main/user/init', {
            title: response.title ? response.title : '',
            firstName: response.firstName,
            lastName: response.lastName,
            email: response.email,
            clean: get(response, 'clean', false),
            pinsNumber: get(response, 'pinsNumber', ''),
            img: decodeURIComponent(CookieUtils.getCookie('user_img'))
          })
          this.checkPinsDataAndPush(response)
        })
        .catch(() => store.dispatch('main/user/clearUserData'))
        .then(() => store.commit('main/setApiResponded', true))
    } else {
      store.commit('main/setApiResponded', true)
    }
  },
  checkPinsDataAndPush(response) {
    const pinsNumber = get(response, 'pinsNumber', null)

    if (pinsNumber === null) {
      return false
    }

    new PaxPins()
      .getPinsData(pinsNumber)
      .then((pinsResponse) => {
        const stampsJoinedDate = get(pinsResponse, 'stampsJoinedDate', null)
        if (stampsJoinedDate === null) {
          return false
        }
      })
      .catch(() => {})
  },
  initCreateProfileLink() {
    if (typeof createProfileLink !== 'undefined') {
      store.commit(`main/setCreateProfileLink`, createProfileLink)
    }
  },
  initIsLoginDisabled() {
    const formattedIsLoginDisabled = !!Number(isLoginDisabled)
    store.commit('main/setIsLoginDisabled', formattedIsLoginDisabled)
  },
  async initGlobalSettings(store) {
    const lang = store.state.main.selectedLang
    const {
      registerPageUrl,
      createProfileLink,
      formattedIsLoginDisabled,
      ripTabs,
      globalAlert,
      mainLinks
    } = await Storyblok.globalSettings(lang)

    store.commit(`indexTabs/setRipTabs`, ripTabs)
    store.commit(`main/setRegisterPageUrl`, registerPageUrl)
    store.commit(`main/setCreateProfileLink`, createProfileLink)
    store.commit('main/setIsLoginDisabled', formattedIsLoginDisabled)

    if (store.hasModule('header')) {
      store.commit(`header/setSearchForm`, mainLinks.searchForm)
      store.commit(`header/setFlightStatus`, mainLinks.flightStatus)

      if (Object.keys(globalAlert).length > 0) {
        store.commit(`header/setGlobalAlert`, globalAlert)
      }
    }
  }
}

export default Init
