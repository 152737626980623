import GTM from 'services/AirBaltic/GTM'

class AnyDestination {
  static push(data) {
    GTM.doDataLayerPush(data)
  }

  static gtmData(payload) {
    let filters = payload.state.filterData
    let stateData = payload.state.stateData
    let eventName = payload.eventName
    let eventCategory = payload.eventCategory

    this.push({
      event: 'ga4_generic_event',
      generic_event_name: eventName,
      eventCategory: eventCategory,
      departure_date: stateData.departure_date,
      return_date: stateData.return_date,
      origin_airport_code: stateData.origin_airport_code,
      'passenger.adultCount': stateData.adults,
      'passenger.youngAdultCount': stateData.children,
      'passenger.childCount': stateData.infants,
      'passenger.count': stateData.passengers,
      ...filters
    })
  }

  static gtmDataBooking(payload) {
    let stateData = payload.stateData
    let cardData = payload.card

    this.push({
      event: 'ga4_generic_event',
      generic_event_name: 'any_destination_book_btn',
      departure_date: stateData.departure_date,
      return_date: stateData.return_date,
      origin_airport_code: stateData.origin_airport_code,
      'passenger.adultCount': stateData.adults,
      'passenger.youngAdultCount': stateData.children,
      'passenger.childCount': stateData.infants,
      'passenger.count': stateData.passengers,
      chosen_country: cardData.country,
      chosen_city: cardData.city,
      destination_airport_code: cardData.code,
      price_from: cardData.price
    })
  }
}

export default AnyDestination
