import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import CompanyHistoryInit from 'initializations/pages/CompanyHistory'
import SidebarInit from 'initializations/Sidebar'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    SidebarInit.init("sidebar")
    CompanyHistoryInit.init("companyHistory")

    const CompanyHistoryPage = () => import(/* webpackChunkName: "company-history-page" */ 'containers/Pages/CompanyHistory')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(CompanyHistoryPage)
    })
  }
}