import Api from 'services/AirBaltic/Api';
import { ERRORS } from './PnrRetrieve';
var PnrRetrievalHttpService = /** @class */ (function () {
    function PnrRetrievalHttpService() {
    }
    PnrRetrievalHttpService.validatePnr = function (_a) {
        var pnrRl = _a.pnrRl, surname = _a.surname;
        return new Api()
            .get('/pnr-retrieval/retrieve', { pnrRl: pnrRl, surname: surname })
            .then(function (_a) {
            var data = _a.data;
            return data;
        })
            .catch(function (error) {
            if (error.response && error.response.status === 404) {
                return Promise.reject(ERRORS.NOT_FOUND);
            }
            return Promise.reject(ERRORS.GENERIC);
        });
    };
    return PnrRetrievalHttpService;
}());
export default PnrRetrievalHttpService;
