import Api from 'services/AirBaltic/Api'
import ErrorHandler from 'utils/ErrorHandler'

const ERRORS = {}

class Flights {
  getDirect (data) {
    return new Api().get(`/2e/calendarData/${data.origCode}/${data.origType}/${data.destCode}/${data.destType}`)
      .then((response) => Promise.resolve(response.data))
      .catch(error => Promise.reject(this.getErrors(error.response)))
  }

  getErrors (response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default Flights
