import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import storeInit from 'initializations/pages/Claims'
import SidebarInit from 'initializations/Sidebar'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    SidebarInit.init("sidebar")
    storeInit.init("claims")

    const Claims = () => import(/* webpackChunkName: "claims-form-page" */ 'containers/Pages/Claims/Claims')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(Claims)
    })
  }
}
