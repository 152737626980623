import moment from 'moment'

const defaultState = {
  bannerDesktop: '',
  bannerTablet: '',
  bannerMobile: '',
  pageTitle: '',
  introText: '',
  origins: {},
  hasPrices: true,
  hasDates: false,
  selectedOrigin: '',
  selectedSorter: '',
  deadline: '',
  timerTitle: '',
  flightDetails: {
    book: {
      title: '',
      firstLine: '',
      secondLine: ''
    },
    travel: {
      title: '',
      firstLine: '',
      secondLine: ''
    }
  },
  flightType: '',
  selectedSorting: '',
  defaultFlightType: '',
  saleTitle: '',
  saleDescriptionItems: [],
  termsAndConditions: {},
  noteText: '',
  iconType: ''
}

const cityCompare = (a, b) => a.city.localeCompare(b.city)
const priceCompare = (a, b) => a.price - b.price

const CampaignLandingPage = {
  state: defaultState,
  mutations: {
    setBannerDesktop (state, value) {
      state.bannerDesktop = value
    },

    setBannerTablet (state, value) {
      state.bannerTablet = value
    },

    setBannerMobile (state, value) {
      state.bannerMobile = value
    },

    setOrigins (state, value) {
      state.origins = value
    },

    setHasPrices (state, value) {
      state.hasPrices = value
    },

    setHasDates (state, value) {
      state.hasDates = value
    },

    setSelectedOrigin (state, value) {
      state.selectedOrigin = value
    },

    setSelectedSorter (state, value) {
      state.selectedSorter = value
    },

    setDefaultFlightType (state, value) {
      state.defaultFlightType = value
    },

    setSaleDescriptionItems (state, value) {
      state.saleDescriptionItems = value
    },

    sortOrigins (state, value) {
      if (!state.origins[state.selectedOrigin]) {
        return
      }
      switch (value) {
        case 'destinationAsc':
          state.origins[state.selectedOrigin].destinations.sort(cityCompare)
          break
        case 'destinationDesc':
          state.origins[state.selectedOrigin].destinations.sort(cityCompare).reverse()
          break
        case 'priceAsc':
        default:
          state.origins[state.selectedOrigin].destinations.sort(priceCompare)
          break
        case 'priceDesc':
          state.origins[state.selectedOrigin].destinations.sort(priceCompare).reverse()
          break
      }
    },

    setFlightDetails (state, value) {
      state.flightDetails = value
    },

    setDeadline (state, value) {
      state.deadline = value
    },

    setTimerTitle (state, value) {
      state.timerTitle = value
    },

    setIntroText (state, value) {
      state.introText = value
    },

    setPageTitle (state, value) {
      state.pageTitle = value
    },

    setTermsAndConditions (state, value) {
      state.termsAndConditions = value
    },

    setNoteText (state, value) {
      state.noteText = value
    },

    setIconType (state, value) {
      state.iconType = value
    }
  },

  actions: {
    setDeadline ({ commit }, data) {
      const date = moment(data.date)
      if (data.time) {
        date.set({
          hours: data.time.split(':')[0],
          minutes: data.time.split(':')[1]
        })
      }

      commit('setDeadline', date)
    },

    setSelectedOrigin ({ commit, state }, data) {
      commit('setSelectedOrigin', data)
      if (state.selectedSorter) {
        commit('sortOrigins', state.selectedSorter)
      }
    },

    setSelectedSorter ({ commit }, data) {
      commit('setSelectedSorter', data)
      commit('sortOrigins', data)
    }
  },
  namespaced: true
}

export default CampaignLandingPage
