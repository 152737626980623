const defaultState = {
  pageTitle: '',
  description: '',
  activeStep: 'step1',
  countries: [],
  termsAndConditionsVisible: false,
  termsAndConditions: {},
  bookingUrl: ''
}

const Generic = {
  namespaced: true,
  state: defaultState,
  actions: {
    setGenericData ({ commit }, data) {
      commit('setPageTitle', data.title)
      commit('setDescription', data.description)
      commit('setBookingUrl', data.bookingUrl)
    }
  },
  mutations: {
    setTermsAndConditionsVisible (state, value) {
      state.termsAndConditionsVisible = value
    },
    setTermsAndConditions (state, value) {
      state.termsAndConditions = value
    },
    setCountries (state, value) {
      state.countries = value
    },
    setPageTitle (state, value) {
      state.pageTitle = value
    },
    setDescription (state, value) {
      state.description = value
    },
    setActiveStep(state, value) {
      state.activeStep = value
    },
    setBookingUrl (state, value) {
      state.bookingUrl = value
    }
  }
}

export default Generic
