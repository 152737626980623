import moment from 'moment'
import FlightsDataService from 'services/AirBaltic/FlightsData'

const departureCompare = (a, b) => {
  const localeCompare = a.departTime.localeCompare(b.departTime)
  if (localeCompare !== 0) {
    return localeCompare
  }

  return a.durationMinutes - b.durationMinutes
}

const getArrivalTime = (a) => {
  const durationHours = parseInt(a.duration.split(':')[0])
  const durationMinutes = parseInt(a.duration.split(':')[1])
  const departHour = parseInt(a.departTime.split(':')[0])
  const departMinute = parseInt(a.departTime.split(':')[1])

  const dateTime = moment().set({
    'hour': departHour,
    'minute': departMinute
  })

  dateTime.add(durationHours, 'hours')
  dateTime.add(durationMinutes, 'minutes')

  let hour = dateTime.hours()
  if ((hour < departHour) || (hour == departHour && a.durationMinutes > 60)) {
    hour += 24
  }
  if (hour < 10) {
    hour = '0' + hour
  }
  return hour + ':' + dateTime.get('minute')
}

const arrivalCompare = (a, b) => {
  const aArrival = getArrivalTime(a)
  const bArrival = getArrivalTime(b)
  return aArrival.localeCompare(bArrival)
}
const durationCompare = (a, b) => a.durationMinutes - b.durationMinutes

const TimetablePage = {
  state: {
    pageData: {
      pageTitle: '',
      description: '',
      noSearchDoneText: '',
      noResultsText: '',
      noFlightsText: '',
      departureFlightTitle: '',
      returnFlightTitle: '',
      pdfBlockTitle: '',
      flightDuration: '',
      connection: '',
      checkPrices: '',
      documents: []
    },
    formSubmitted: null,
    departureDay: '',
    returnDay: '',
    departureSorter: 'departure',
    returnSorter: 'departure',
    selectedDeparture: {},
    selectedReturn: {},
    departureData: [],
    returnData: []
  },
  mutations: {
    setPageData (state, value) {
      state.pageData = value
    },
    setFormSubmitted (state, value) {
      state.formSubmitted = value
    },

    setDepartureData (state, value) {
      state.departureData = value
    },

    setReturnData (state, value) {
      state.returnData = value
    },

    setDepartureDay (state, value) {
      state.departureDay = value
    },

    setReturnDay (state, value) {
      state.returnDay = value
    },

    setSelectedDepartureDate (state, value) {
      state.selectedDeparture = value
    },

    setSelectedReturnDate (state, value) {
      state.selectedReturn = value
    },

    setDepartureSorter (state, value) {
      state.departureSorter = value
      if (!state.departureData) {
        return
      }
      switch (value) {
        case 'departure':
        default:
          state.departureData.sort(departureCompare)
          break
        case 'arrival':
          state.departureData.sort(arrivalCompare)
          break
        case 'duration':
          state.departureData.sort(durationCompare)
          break
      }
    },

    setReturnSorter (state, value) {
      state.returnSorter = value
      if (!state.returnData) {
        return
      }
      switch (value) {
        case 'departure':
        default:
          state.returnData.sort(departureCompare)
          break
        case 'arrival':
          state.returnData.sort(arrivalCompare)
          break
        case 'duration':
          state.returnData.sort(durationCompare)
          break
      }
    }
  },
  actions: {
    getTimetableDeparture ({ rootState }) {
      const date = moment(rootState.eHorizonSearchForm.calendar.departureDate)
      const prevDate = date.clone().subtract(3, 'days')
      const nextDate = date.clone().add(3, 'days')
      const form = {
        orig: rootState.eHorizonSearchForm.origin.value.code,
        dest: rootState.eHorizonSearchForm.destin.value.code,
        periodStart: prevDate.format('YYYY-MM-DD'),
        periodEnd: nextDate.format('YYYY-MM-DD')
      }

      return new FlightsDataService().getTimetableFlights(form)
    },

    getTimetableReturn ({ rootState }) {
      const date = moment(rootState.eHorizonSearchForm.calendar.returnDate)
      const prevDate = date.clone().subtract(3, 'days')
      const nextDate = date.clone().add(3, 'days')
      const form = {
        orig: rootState.eHorizonSearchForm.destin.value.code,
        dest: rootState.eHorizonSearchForm.origin.value.code,
        periodStart: prevDate.format('YYYY-MM-DD'),
        periodEnd: nextDate.format('YYYY-MM-DD')
      }

      return new FlightsDataService().getTimetableFlights(form)
    },
    timetableParse ({ rootState, commit }, response) {
      if (rootState.eHorizonSearchForm.tripType === 'return') {
        if (typeof response[0] !== 'undefined' && typeof response[1] !== 'undefined')  {
          commit('setDepartureData', response[0])
          commit('setReturnData', response[1])

          commit(`setDepartureSorter`, 'departure')
          commit(`setReturnSorter`, 'departure')
        }
      } else {
        if (typeof response[0] !== 'undefined')  {
          commit('setDepartureData', response[0])
          commit(`setDepartureSorter`, 'departure')
        }
      }

      return Promise.resolve()
    }
  },
  namespaced: true
}

export default TimetablePage
