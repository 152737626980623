import { maxLength, required } from 'vuelidate/lib/validators';
var Request = /** @class */ (function () {
    function Request() {
        this.message = '';
        this.textMaxLength = 4500;
        this.translationVariables = {
            message: {
                maxLength: {
                    limit: this.textMaxLength,
                },
            },
        };
        this.errorMsg = {
            message: {
                maxLength: 'reusable.request.validation.maxLength',
                required: 'reusable.request.validation.required',
            },
        };
        this.validation = {
            form: {
                message: {
                    maxLength: maxLength(this.textMaxLength),
                    required: required,
                },
            },
        };
    }
    return Request;
}());
export default Request;
