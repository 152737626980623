import moment from 'moment'
import Payment from 'services/AirBaltic/Forms/Payment'

const Extras = {
  namespaced: true,
  state: {
    title: '',
    description: ''
  },
  mutations: {
    setTitle (state, value) {
      state.title = value
    },
    setDescription (state, value) {
      state.description = value
    },
  },
  actions: {
    submitForm ({ dispatch }, data) {
      return new Payment().submitExtrasForm(data)
        .then(response => {
          return dispatch('payment/generic/clearForms', data.form, { root: true })
            .then(() => Promise.resolve(response))
            .catch((err) => Promise.reject(err))
        })
        .catch(err => Promise.reject(err))
    },
    getPassengerData ( { rootState}, data) {
      const passengerData = {
        title: data.passenger.title,
        firstName: data.passenger.firstName,
        lastName: data.passenger.lastName
      }

      const ticketOrServices = []

      if (data.segments.length > 0) {
        data.segments.map((segment) => {
          const formName = data.form.replace('Form', '')

          const $segment = rootState.payment.passengers[`${data.passengerNamespace}`][`${segment.namespace}`][`${formName}`]

          if (typeof $segment !== 'undefined') {
            if ($segment.checked) {
              const obj = {
                flightNumber: `${segment.value.carrier}${segment.value.number}`,
                flightDate: moment(segment.value.departureDate).format('YYYY-MM-DD')
              }

              obj.type = rootState.payment.generic.activeItem.key

              ticketOrServices.push(obj)
            }
          }
        })
      }

      passengerData.ticketOrServices = ticketOrServices
      return passengerData
    }
  }
}

export default Extras
