import * as moment from 'moment';
import get from 'lodash-es/get';
var Leg = /** @class */ (function () {
    function Leg(obj) {
        if (obj === void 0) { obj = {}; }
        this.arrival = '';
        this.arrivalDate = '';
        this.arrivalTime = '';
        this.depart = '';
        this.departDate = '';
        this.departTime = '';
        this.dest = '';
        this.durationMinutes = 0;
        this.flightNumber = [];
        this.orig = '';
        this.connectionAirport = [];
        this.connectionTime = 0;
        this.nextDay = false;
        this.duration = '';
        this.changes = {};
        this.changesFromNetline = {};
        var arrival = moment.parseZone(obj.arrival);
        var departure = moment.parseZone(obj.depart);
        var changes = {
            depart: '',
            departDate: '',
            departTime: '',
            arrival: '',
            arrivalDate: '',
            arrivalTime: '',
            nextDay: null,
            destination: null,
        };
        var durationInMinutes = arrival.diff(departure, 'minutes');
        if (typeof obj.changesFromNetline !== 'undefined') {
            var changedDepart = get(obj, 'changesFromNetline.depart', null);
            var changedArrival = get(obj, 'changesFromNetline.arrival', null);
            var diverted = get(obj, 'changesFromNetline.dest', null);
            if (changedDepart !== null) {
                changes.depart = changedDepart;
                var changeDep = this.splitDate(changedDepart);
                changes.departDate = changeDep.dateDate;
                changes.departTime = changeDep.dateTime;
            }
            if (changedArrival !== null) {
                changes.arrival = changedArrival;
                var arrivalDep = this.splitDate(changedArrival);
                changes.arrivalDate = arrivalDep.dateDate;
                changes.arrivalTime = arrivalDep.dateTime;
            }
            if (diverted !== null) {
                changes.destination = diverted;
            }
            if (changedDepart !== null && changedArrival !== null) {
                changes.nextDay = !moment(changedArrival).isSame(moment(changedDepart), 'day');
            }
        }
        var data = {
            arrival: arrival.toISOString(),
            arrivalDate: arrival.format('YYYY-MM-DD'),
            arrivalTime: arrival.format('HH:mm'),
            depart: departure.toISOString(),
            departDate: departure.format('YYYY-MM-DD'),
            departTime: departure.format('HH:mm'),
            duration: this.getTimeFromMins(durationInMinutes),
            durationMinutes: durationInMinutes,
            nextDay: !arrival.isSame(departure, 'day'),
            changes: changes
        };
        Object.assign(this, obj, data);
    }
    Leg.prototype.splitDate = function (date) {
        var parsedDate = moment.parseZone(date);
        return {
            dateDate: parsedDate.format('YYYY-MM-DD'),
            dateTime: parsedDate.format('HH:mm')
        };
    };
    Leg.prototype.getTimeFromMins = function (mins) {
        var h = Math.floor(mins / 60);
        var m = mins % 60;
        var hC = h < 10 ? '0' + h : h;
        var mC = m < 10 ? '0' + m : m;
        return "".concat(hC, ":").concat(mC);
    };
    return Leg;
}());
export default Leg;
