import Vue from 'vue'
import startsWith from 'lodash-es/startsWith'
import { validationMixin } from 'vuelidate'
import { minLength, required, or } from 'vuelidate/lib/validators'
import { alphaWithSpaces, pnr, ticketNumber, alphaNumWithDash, dashPositionError } from 'utils/validators'

const defaultState = {
  surname: '',
  bookingRef: '',
  errors: [],
  asrError: false,
  errorRef: '',
  validationErrors: [],
  submitForm: false
}

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations () {
    const rules = {
      surname: {
        required,
        alphaWithSpaces
      },
      bookingRef: {
        required,
        alphaNumWithDash,
        dashError: dashPositionError(this.state.bookingRef),
      }
    }

    if (this.state.submitForm) {
      rules.surname.minLength = minLength(2)
      rules.bookingRef.validBookingRef = or(pnr, ticketNumber)
    }

    return { state: rules }
  }
})

const module = {
  namespaced: true,
  state: defaultState,
  getters: {
    $v () {
      return Object.assign({}, validator.$v)
    },
    formErrors (state) {
      return state.errors
    },
    validationErrors (state) {
      return state.validationErrors
    }
  },
  actions: {
    removeAllErrors ({ commit } ) {
      commit('removeErrors')
      commit('removeValidationErrors', 'bookingRef')
      commit('removeValidationErrors', 'surname')

      validator.$v.state.bookingRef.$reset()
      validator.$v.state.surname.$reset()
    },
    setBookingRef ({ commit, dispatch }, value) {
      commit('bookingRef', value)
      dispatch('validateBookingRef')
    },
    setSurname ({ commit, dispatch }, value) {
      commit('surname', value)
      dispatch('validateSurname')
    },
    validateForm ({ dispatch }) {
      validator.$v.state.$touch()
      dispatch('validateFields')
    },
    validateFields ({ dispatch }) {
      dispatch('validateBookingRef')
      dispatch('validateSurname')
    },
    validateBookingRef ({ dispatch, commit, state }) {
      commit('removeErrors')
      commit('removeValidationErrors', 'bookingRef')
      validator.$v.state.bookingRef.$touch()

      const errors = []

      if (validator.$v.state.bookingRef.$dirty) {
        if (state.submitForm) {
          errors.push('validBookingRef')
        }

        errors.push('dashError', 'alphaNumWithDash', 'required')
        dispatch('generateErrorMessages', { field: 'bookingRef', errors: errors })
      }
    },
    validateSurname ({ dispatch, commit, state }) {
      commit('removeErrors')
      commit('removeValidationErrors', 'surname')
      validator.$v.state.surname.$touch()
      const errors = ['alphaWithSpaces', 'required']

      if (state.submitForm) {
        errors.push('minLength')
      }

      if (validator.$v.state.surname.$dirty) {
        dispatch('generateErrorMessages', { field: 'surname', errors: errors })
      }
    },
    generateErrorMessages ({ commit }, data) {
      data.errors.forEach(error => {
        if (!validator.$v.state[`${data.field}`][`${error}`]) {
          commit('addValidationError', `checkInForm.bookingField.${data.field}.errors.${error}EHorizon`)
        }
      })
    }
  },
  mutations: {
    setSubmitForm (state, value) {
      state.submitForm = value
    },
    surname (state, value) {
      state.surname = value
    },
    bookingRef (state, value) {
      state.bookingRef = value
    },
    addError (state, errorKey) {
      if (state.errors.indexOf(errorKey) === -1) {
        state.errors.push(errorKey)
      }
    },
    removeErrors (state) {
      state.errors = []
    },
    setAsrError (state, value) {
      state.asrError = value
    },
    setErrorRef (state, value) {
      state.errorRef = value
    },
    addValidationError (state, errorKey) {
      if (state.validationErrors.indexOf(errorKey) === -1) {
        state.validationErrors.push(errorKey)
      }
    },
    removeValidationErrors (state, field) {
      const errorArray = []
      state.validationErrors.forEach(error => {
        if (!startsWith(error, `checkInForm.bookingField.${field}.errors`)){
          errorArray.push(error)
        }
      })
      state.validationErrors = errorArray
    }
  }
}

export default module
