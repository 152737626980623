import AbstractView from 'abstract';
import Vue from 'vue'
import pageInit from 'initializations/pages/BlockPage'
import SidebarInit from 'initializations/Sidebar'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
      SidebarInit.init("sidebar")
        pageInit.init("blockPage")

      const blockPage = () => import(/* webpackChunkName: "block-page" */ 'containers/Pages/BlockPage')
      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(blockPage)
      })
    }
}
