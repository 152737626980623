import { render, staticRenderFns } from "./TabPill.vue?vue&type=template&id=b1c90fb6&scoped=true&"
import script from "./TabPill.vue?vue&type=script&lang=js&"
export * from "./TabPill.vue?vue&type=script&lang=js&"
import style0 from "./TabPill.vue?vue&type=style&index=0&id=b1c90fb6&lang=scss&scoped=true&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1c90fb6",
  null
  
)

export default component.exports