import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import storeInit from 'initializations/pages/HelpAndContact'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    storeInit.init("helpAndContact")

    const HelpAndContact = () => import(/* webpackChunkName: "help-and-contact-page" */ 'containers/Pages/HelpAndContact')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(HelpAndContact)
    })
  }
}
