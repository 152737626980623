var ComplimentItems = /** @class */ (function () {
    function ComplimentItems() {
        this.title = '';
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.flightNumber = '';
        this.flightDate = '';
        this.flightMonth = '';
        this.flightYear = '';
        this.date = '';
        this.bookingReference = '';
        this.message = '';
    }
    return ComplimentItems;
}());
export default ComplimentItems;
