import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { required, email, numeric } from 'vuelidate/lib/validators'
import { alphaWithSpaces, pnr } from 'utils/validators'
import RepatService from 'services/AirBaltic/Forms/Repat'

const formErrors = {
  lastName: {
    required: 'reusable.lastName.validation.required',
    alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
  },
  firstName: {
    required: 'reusable.firstName.validation.required',
    alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
  },
  bookingRef: {
    pnrOrTicketNumber: 'reusable.pnr.validation.invalid'
  },
  paxCount: {
    required: 'reusable.validation.required',
    numeric: 'numeric'
  },
  nationality:  {
    required: 'reusable.validation.required'
  },
  passNumber: {
    required: 'reusable.validation.required',
  },
  passCountry: {
    required: 'reusable.validation.required'
  },
  whereFrom: {
    required: 'reusable.validation.required'
  },
  whereFromCity: {
    required: 'reusable.validation.required'
  },
  phoneNumber: {
    required: 'reusable.validation.required',
  },
  email: {
    required: 'reusable.email.validation.missing',
    email: 'reusable.email.validation.invalid',
  }
}

const defaultState = {
  pageData: {
    title: '',
    description: '',
    firstNameLabel: '',
    lastNameLabel: '',
    pnrLabel: '',
    nationalityLabel: '',
    paxCountLabel: '',
    passNumberLabel:'',
    passCountryLabel: '',
    whereFromLabel: '',
    whereFromOtherLabel: '',
    whereFromLabelCity: '',
    phoneLabel: '',
    commentsLabel: '',
    residentLabel:'',
    familyLabel: ''
  },
  formData: {
    firstName: '',
    lastName: '',
    bookingRef: '',
    paxCount: '',
    nationality: '',
    email: '',
    passNumber: '',
    passCountry: '',
    whereFrom: '',
    whereFromCity: '',
    phoneNumber: '',
    comments: '',
    resident: false,
    family: false,
  },
  formErrors: formErrors
}

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations () {
    return {
      state: {
        formData: {
          firstName: {
            required,
            alphaWithSpaces
          },
          lastName:{
            required,
            alphaWithSpaces
          },
          bookingRef: {
            pnrOrTicketNumber: pnr,
          },
          nationality: {
            required
          },
          paxCount: {
            required,
            numeric
          },
          email: {
            required,
            email
          },
          passNumber: {
            required
          },
          passCountry: {
            required
          },
          whereFrom: {
            required
          },
          whereFromCity: {
            required
          },
          phoneNumber: {
            required
          },
          comments: {
          },
          resident: {},
          family: {},
        },
      }
    }
  }
})

const DefaultForm = {
  state: defaultState,
  mutations: {
    setFirstName (state, value) {
      state.formData.firstName = value
    },
    setLastName (state, value) {
      state.formData.lastName = value
    },
    setBookingRef (state, value) {
      state.formData.bookingRef = value
    },
    setPaxCount (state, value) {
      state.formData.paxCount = value
    },
    setEmail (state, value) {
      state.formData.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setPhoneNumber (state, value) {
      state.formData.phoneNumber = value
    },
    setNationality (state, value) {
      state.formData.nationality = value
    },
    setPassNumber (state, value) {
      state.formData.passNumber = value
    },
    setPassCountry (state, value) {
      state.formData.passCountry = value
    },
    setWhereFrom (state, value) {
      state.formData.whereFrom = value
    },
    setWhereFromCity (state, value) {
      state.formData.whereFromCity = value
    },
    setComments (state, value) {
      state.formData.comments = value
    },
    selectResident (state, value) {
      state.formData.resident = value
    },
    selectFamily (state, value) {
      state.formData.family = value
    },
    setPageData (state, value) {
      state.pageData = value
    },
  },

  actions: {
    submitForm ({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('touchEverything')
          .then(() => {
            dispatch('sendRequest')
              .then(() => resolve())
              .catch((e) => reject(e))
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    clearForm ({ commit }) {
      validator.$v.state.$reset()

      commit('setFirstName', '')
      commit('setLastName', '')
      commit('setBookingRef', '')
      commit('setPaxCount', '')
      commit('setEmail', '')
      commit('setPhoneNumber', '')
      commit('setNationality', '')
      commit('setPassNumber', '')
      commit('setPassCountry', '')
      commit('setWhereFrom', '')
      commit('setWhereFromCity', '')
      commit('setComments', '')
      commit('selectResident', '')
      commit('selectFamily', '')
    },
    touchEverything () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state

        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('touchEverything')
      })
    },

    sendRequest({ state }) {
      const postObj = {
        name: state.formData.firstName,
        surname: state.formData.lastName,
        phone: state.formData.phoneNumber,
        email: state.formData.email,
        nationality: state.formData.nationality,
        numberOfAdults: state.formData.paxCount,
        passportNumber: state.formData.passNumber,
        passportIssuingCountry: state.formData.passCountry,
        originCountry: state.formData.whereFrom,
        originCity: state.formData.whereFromCity,
        destination: 'Riga',
        pnr: state.formData.bookingRef.toUpperCase(),
        comments: state.formData.comments,
        permanentResidentOfLatvia: state.formData.resident,
        familyInLatvia: state.formData.family,
        languageCode: Vue.i18n.locale(),
      }

      return new RepatService(state.categoryType).submitForm(postObj)
        .then(() => Promise.resolve(true))
        .catch((error) => Promise.reject(error))
    },
  },

  getters: {
    $v () {
      return Object.assign({}, validator.$v.state.formData)
    }
  },

  namespaced: true
}

export default DefaultForm
