<template>
  <div class="btn btn-print">
    <button @click="onClick()">
      {{ $t('printBtnText') }}
    </button>
  </div>
</template>

<script>
  export default {
    methods: {
      onClick () {
        this.$emit('print')
        setTimeout(() => {
          window.print()
        }, 200)
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  .btn-print {
    margin-bottom: 20px;
    width: 100%;
    min-width: unset;
    max-width: unset;
    border-radius: 4px;

    @include bp-tablet-only {
      right: 26px;
    }

    @include bp-mf-tablet {
      position: absolute;
      top: 0;
      margin: 0;
      width: auto;
    }

    @include bp-mf-desktop {
      right: 0;
    }

    @media print {
      display: none;
    }

    &.filled {
      height: 44px;
      background-color: $blue-grey-light;

      &:hover {
        background-color: $gray-light-hover;
      }
    }

    &.ghost {
      height: 48px;
      border: 1px solid $blue-medium;

      &:hover {
        border-color: $blue-hover;

        button {
          color: $blue-hover;
        }
      }
    }

    button {
      padding: 0 22px;
      font-weight: 500;
      color: $blue-medium;

      &:after {
        content: $line-icon-print;
        @include line-icon-font();
        vertical-align: middle;
        margin-left: 12px;
        font-size: em(22);
      }
    }
  }
</style>
