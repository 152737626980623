const SiloPageContent = {
  state: {
    pageData: {
      pageTitle: '',
      sections: []
    }
  },

  mutations: {
    setPageData (state, value) {
      state.pageData = value
    }
  },

  namespaced: true
}

export default SiloPageContent
