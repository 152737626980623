import Profile from 'services/AirBaltic/Pax/Profile'
import FormValidationUtils from 'utils/FormValidation'
import ErrorHandler from 'utils/ErrorHandler'
import Form from 'models/Forms/Profile/Profile'
import EmailChangeService from 'services/AirBaltic/Pax/EmailChange'
import PasswordService from 'services/AirBaltic/Pax/Password'

const ERRORS = {}

const formModel = new Form()

const formData = {
  userData: {},
  deleteConfirm: '',
  form: formModel.form,
  ruleMapping: formModel.passwordValidationRuleMapping,
  formErrors: formModel.errorMsg,
  errors: formModel.errors,
  confirmUrl: '',
  forgotPasswordResetLink: ''
}

const excluded = []

const defaultState = Object.assign({}, formData)

const validator = FormValidationUtils.initValidation(defaultState, formModel.validation, excluded)

const EditMyProfile = {
  namespaced: true,
  state: defaultState,
  mutations: {
    setUserData (state, value) {
      state.userData = value
    },
    setFirstName (state, value) {
      state.form.firstName = value
    },
    setLastName (state, value) {
      state.form.lastName = value
    },
    setEmail (state, value) {
      state.form.email = typeof value === 'string' ? value.replace(/ /g, '') : value
    },
    setMinorEmail(state, value) {
      state.form.minorEmail = typeof value === 'string' ? value.replace(/ /g, '') : value
    },
    setResidenceCountry (state, value) {
      state.form.residenceCountry = typeof value === 'string' ? value.toUpperCase() : value
    },
    setLanguage (state, value) {
      state.form.language = typeof value === 'string' ? value.toUpperCase() : value
    },
    setOldPassword (state, value) {
      state.form.oldPassword = value.trim()
    },
    setPassword (state, value) {
      state.form.newPassword = value.trim()
    },
    setPasswordRepeat (state, value) {
      state.form.newPasswordRepeat = value.trim()
    },
    setNotification (state, value) {
      state.form.notifications[`${value.platform}`].connected = value.connected
    },
    setDeleteConfirm (state, value) {
      state.deleteConfirm = value
    },
    reset (state) {
      state.form.oldPassword = ''
      state.form.newPassword = ''
      state.form.newPasswordRepeat = ''
    },
    setConfirmLink (state, value) {
      state.confirmUrl = value
    },
    setForgotPasswordResetLink (state, value) {
      state.forgotPasswordResetLink = value
    }
  },
  actions: {
    sendEmail ({ state, rootState }) {
      const data = {
        newEmail: state.form.email,
        language: rootState.main.selectedLang.toUpperCase(),
        confirmLink: state.confirmUrl,
        forgotPasswordResetLink: state.forgotPasswordResetLink
      }

      return new EmailChangeService().sendEmail(data)
    },
    sendInvitationEmail({ state, rootState }) {
      return new Profile()
        .sendInvitationEmail({
          email: state.form.minorEmail,
          language: rootState.main.selectedLang.toUpperCase(),
          profileCreateLink: rootState.main.registerPageUrl
        })
        .then((response) => Promise.resolve(response))
        .catch((errorMessage) => {
          return Promise.reject(errorMessage)
        })
    },
    updatePassword ({ state }) {
      const data = {
        oldPassword: state.form.oldPassword,
        newPassword: state.form.newPassword
      }

      return new PasswordService().change(data)
    },
    validatePassword () {
      return new Promise((resolve, reject) => {
        const vNewState = validator.$v.state.form.newPassword
        const vRepeatState = validator.$v.state.form.newPasswordRepeat
        const vOldState = validator.$v.state.form.oldPassword

        vNewState.$touch()
        vRepeatState.$touch()
        vOldState.$touch()


        !vNewState.$invalid && !vRepeatState.$invalid && !vOldState.$invalid?
          resolve(true) :
          reject({
            type: 'validation',
            msg: 'password submition failed'
          })
      })
    },
    validateEmail (_state, isMinorEmail = false) {
      return new Promise((resolve, reject) => {
        const vState = isMinorEmail
          ? validator.$v.state.form.minorEmail
          : validator.$v.state.form.email

        vState.$touch()

        !vState.$invalid ?
          resolve(true) :
          reject({
            type: 'validation',
            msg: 'email submition failed'
          })
      })
    },
    connectNotification ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const postData = {}
        if (data.platform === 'newsletter') {
          postData.emlSubscribe = data.connected
        }

        if (data.platform === 'smsDeals') {
          postData.smsSubscribe = data.connected
        }

        if (data.platform === 'whatsapp') {
          postData.whatsAppSubscribe = data.connected
        }

        return new Profile().updatePaxProfile(postData)
          .then((response) => {
            commit('setNotification', data)
            resolve(response)

          })
          .catch(err => {
            commit('setNotification', { platform: data.platform, connected: false })
            return reject({
              type: 'submit',
              msg: ErrorHandler.getErrorTranslationKey(ERRORS, err.response)
            })
          })
        })
    },
    validateLocaleFormAndSubmit ({ state }, type) {
      return new Promise((resolve, reject) => {
        const vLanguageState = validator.$v.state.form.language
        const vCountryState = validator.$v.state.form.residenceCountry

        vLanguageState.$touch()
        vCountryState.$touch()

        if (!vLanguageState.$invalid && !vCountryState.$invalid) {
          const data = {}

          if (type === 'location') {
            data.country = state.form.residenceCountry
          }

          if (type === 'language') {
            data.language = state.form.language
          }

          return new Profile().updatePaxProfile(data)
            .then((response) => resolve(response))
            .catch(err => {
              return reject({
                type: 'submit',
                msg: ErrorHandler.getErrorTranslationKey(ERRORS, err.response)
              })
            })
        } else {
          reject({
            type: 'validation',
            msg: 'locale submition failed'
          })
        }
      })
    },
    deleteProfile () {
      new Profile().deletePaxProfile()
        .then((response) => Promise.resolve(response))
        .catch(err => {
          return Promise.reject({
            type: 'delete',
            msg: ErrorHandler.getErrorTranslationKey(ERRORS, err.response)
          })
        })
    },
    clearForm ({ commit }) {
      validator.$v.state.form.newPassword.$reset()
      validator.$v.state.form.newPasswordRepeat.$reset()
      validator.$v.state.form.oldPassword.$reset()
      commit('reset')
    },
    subscribeEmail () {
      return new Profile().notificationEmailSubscribe()
    },
    unsubscribeEmail () {
      return new Profile().notificationEmailUnSubscribe()
    },
  },
  getters: {
    $form () {
      return Object.assign({}, validator.$v.state.form)
    },
    formErrors (state) {
      return state.formErrors
    }
  }
}

export default EditMyProfile
