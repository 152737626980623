import SearchFormStore from 'store/modules/eHorizon/SearchForm/SearchForm'
import OrigDestUtils from 'store/utils/eHorizonOrigDestUtils'
import CookieUtils from 'utils/CookieUtils'
import gtmFSF from 'services/AirBaltic/GTM/FSF'
import store from 'definitions/store'
import OrigDest from 'utils/OrigDestData'
import getNearestAirport from 'utils/GetNearestAirport'

const RETURN_TRIP_TYPE = 2

const Init = {
    init(name) {
        if (!store.hasModule(name)) {
            store.registerModule(name, SearchFormStore)

            Init.initOrigDestData(name)
            Init.initSearchFormConfig(name)
            Init.initSref(name)
            Init.initRecentSearch(name)

            if (typeof fltInit !== 'undefined') {
                Init.setOriginDestinValues(name)
                Init.setPaxSelectorValues(name)
                Init.setTripType(name)
                Init.setEvouchers(name)
                Init.addFormErrors(name)

                if (fltInit.origin === null) {
                    Init.setOriginToNearestAirpot(name)
                }
            }

            if (typeof mmbInit !== 'undefined' || typeof ckiInit !== 'undefined') {
                Init.setOriginToNearestAirpot(name)
            }
        }
    },

    isLastSearchEqualToInitValues(fltInit, cookie) {
        const initOrigin = fltInit.origin + fltInit.origin_type
        const lastCookieOrigin = cookie.fromCode + cookie.fromType
        if (initOrigin !== lastCookieOrigin) {
            return false
        }

        if (fltInit.numjrny !== cookie.flightType) {
            return false
        }

        if (fltInit.numadt !== cookie.adults || fltInit.numchd !== cookie.children || fltInit.numinf !== cookie.infants) {
            return false
        }

        if (fltInit.numjrny === RETURN_TRIP_TYPE) {
            if (fltInit.destin !== cookie.toCode) {
                return false
            }
        }

        return true
    },

    initSearchFormConfig(name) {
        store.commit(`${name}/setGroupBookingUrl`, GROUP_BOOKING_URL)
        store.commit(`${name}/setGroupBookingFormUrl`, GROUP_BOOKING_FORM)
    },
    setOriginToNearestAirpot(name) {
        const nearestAirport = getNearestAirport()

        if (typeof origDestData !== 'undefined') {
            const data = OrigDest.parseData(origDestData)

            if (nearestAirport !== null && typeof data[nearestAirport] !== 'undefined') {
                try {
                    store.dispatch(`${name}/selectOrigin`, data[nearestAirport])
                } catch (err) {
                    console.log(err)
                }
            }
        }
    },

    initOrigDestData(name) {
        if (typeof origDestLabels !== 'undefined') {
            store.commit(`${name}/destin/setLabels`, origDestLabels)
        }

        if (typeof origDestData !== 'undefined') {
            const data = OrigDest.parseData(origDestData)
            store.commit(`${name}/setOrigDestData`, data)
            store.commit(`${name}/origin/setDestinations`, OrigDestUtils.prepareData(data).filter(o => o.isOrig))
        }

        const country = CookieUtils.getCookie('country') || null
        if (country) {
            store.dispatch(`${name}/origin/setHomeCountry`, country)
        }
    },

    setPaxSelectorValues(name) {
        if (fltInit.numadt) {
            store.commit(`${name}/paxSelector/setAdults`, fltInit.numadt)
        }

        if (fltInit.numchd) {
            store.commit(`${name}/paxSelector/setChildren`, fltInit.numchd)
        }

        if (fltInit.numinf && fltInit.numadt) {
          store.commit(`${name}/paxSelector/setInfantCountExceeded`, fltInit.numinf <= fltInit.numadt)

          if (fltInit.numinf <= fltInit.numadt) {
            store.commit(`${name}/paxSelector/setInfants`, fltInit.numinf)
          } else {
            store.commit(`${name}/paxSelector/setInfants`, fltInit.numadt)
            store.commit(`${name}/paxSelector/showPaxDropdown`)
          }
        }
    },

    findDestinationByDestinCode(origin, destinCode) {
        let destin
        const data = OrigDest.parseData(origDestData)
        OrigDestUtils.extractDestinations(origin, data).forEach(function (element, key) {
            if (element.code === destinCode) {
                destin = element
                return
            }
        })

        return destin
    },

    setOriginDestinValues(name) {
        let origin
        if (fltInit.origin && fltInit.origin_type) {
            const data = OrigDest.parseData(origDestData)
            origin = data[fltInit.origin + fltInit.origin_type]
        }

        if (fltInit.destin) {
            let destin
            if (typeof origin !== 'undefined') {
                destin = Init.findDestinationByDestinCode(origin, fltInit.destin)
            } else {
                const nearestAirport = getNearestAirport()
                if (nearestAirport !== null) {
                    const data = OrigDest.parseData(origDestData)
                    origin = data[nearestAirport]
                    destin = Init.findDestinationByDestinCode(origin, fltInit.destin)
                }
            }

            if (typeof origin !== 'undefined' && typeof destin !== 'undefined') {
                store.dispatch(`${name}/selectOrigin`, origin)
                store.dispatch(`${name}/destin/onValueSelect`, destin)
            }
        } else if (typeof origin !== 'undefined') {
            store.dispatch(`${name}/selectOrigin`, origin)
        }
    },

    setTripType(name) {
        if (fltInit.tripType && ['return', 'oneway'].includes(fltInit.tripType)) {
          store.commit(`${name}/setTripType`, fltInit.tripType)
        } else if (fltInit.numjrny) {
          if (fltInit.numjrny === RETURN_TRIP_TYPE) {
            store.commit(`${name}/setTripType`, 'return')
          } else {
            store.commit(`${name}/setTripType`, 'oneway')
          }
        }
    },

    setEvouchers(name) {
        if (typeof fltInit.evoucher !== 'undefined') {
            if (Array.isArray(fltInit.evoucher)) {
                store.commit(`${name}/promoCodes/showPromoField`, true)
                fltInit.evoucher.map(function (code, index) {
                  const error = Init.getVoucherError(name, fltInit.voucherErrors, index)
                  store.commit(`${name}/promoCodes/add`)
                  store.commit(`${name}/promoCodes/update`, {index, code, error})
                })
            }
        }
    },

    getVoucherError (name, errors, index) {
      let error = ''
      let hasError = false

      if (typeof errors !== 'undefined' && typeof errors[index] !== 'undefined') {
        error = errors[index]
        hasError = true
      }

      store.commit(`${name}/promoCodes/showDropdown`, hasError)
      return error
    },

    addFormErrors(name) {
        if (fltInit.errors) {
            if (fltInit.errors.length > 0) {
                gtmFSF.fsfError('2E errors', fltInit.errors.join())
            }

            fltInit.errors.map((error) => store.commit(`${name}/addError`, error))
        }
    },

    initSref(name) {
        if (typeof searchFormSref !== 'undefined') {
            store.commit(`${name}/setSref`, searchFormSref)
        }
    },

    initRecentSearch(name) {
        const recentSearchCookie = CookieUtils.getCookie('recentSearch')
        if (recentSearchCookie) {
            try {
                let cookie = JSON.parse(recentSearchCookie)
                store.commit(`${name}/recentSearch/setSearchData`, cookie.slice(0, 3))
            } catch (e) {
                CookieUtils.setCookie('recentSearch', JSON.stringify([]), -1)
            }
        }
    },
}

export default Init
