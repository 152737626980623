const closeModal = (store) => () => store.dispatch('main/closeModal', { root: true })

const openModal = (store, content) => {
  store.dispatch('main/openModal', { root: true })
  store.commit('main/setShowModalHeader', false)
  store.commit('main/setModalContent', content, { root: true })
  store.commit('main/setModalType', 'centred')
  store.commit('main/setModalClass', 'modal-white-centred image-modal')
  store.commit('main/setShowCloseIcon', true)
  store.commit('main/setModalCloseFn', closeModal(store), { root: true })
}

const openPopup = (url) => {
  const params =
    'resizable=no,status=no,location=no,toolbar=no,menubar=no,fullscreen=no,scrollbars=no,dependent=no'
  window.open(url, '', params)
}

const execute = (el, binding = null, vnode = null) => {
  const elements = el.getElementsByClassName('inlineModal')

  for (let i = 0; i < elements.length; i++) {
    const element = elements[parseInt(i)]

    element.addEventListener('click', () => {
      let content = element.dataset.content
      const type = element.dataset.type

      if (type === 'image') {
        const label = element.innerHTML
        content = `<div class="center"><img class="full-width" src='${content}' alt='${label}'/></div>`
      }

      if (type === 'popup') {
        openPopup(content)
      } else {
        openModal(store, content)
      }
    })
  }
}

let store
export default {
  bind: (el, binding, vnode) => {
    store = vnode.context.$store
    execute(el, binding, vnode)
  },
  update: (handle) => {
    execute(handle)
  }
}
