import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import timetableInit from 'initializations/pages/Timetable'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    timetableInit.init("timetable")
    const Timetable = () => import(/* webpackChunkName: "timetable-page" */ 'containers/Pages/Timetable')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(Timetable)
    })
  }
}
