import FormValidationUtils from 'utils/FormValidation'
import { isTrue } from 'utils/validators'

const formData = {
  formErrors: {
    consent: {
      required: 'selfService.assistance.consent.required'
    }
  },
  form: {
    consent: false
  }
}
const defaultState = Object.assign({}, formData)
const validator = FormValidationUtils.initValidation(defaultState, {
  form: {
    consent: {
      required: isTrue
    }
  }
})

const module = {
  namespaced: true,
  state: defaultState,
  actions: {
    validateConsent() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        !vState.$invalid
          ? resolve(true)
          : reject('validate other assistance form request')
      })
    },
    clearRequest({ commit }) {
      validator.$v.state.form.$reset()
      commit('resetForm')
    }
  },
  mutations: {
    setConsent(state, value) {
      state.form.consent = value
    },
    resetForm(state) {
      state.form.consent = false
    }
  },
  getters: {
    $v() {
      return Object.assign({}, validator.$v.state.form)
    },
    consentErrors(state) {
      return state.formErrors
    }
  }
}

export default module
