import Vue from 'vue'
import flattenDeep from 'lodash-es/flattenDeep'
import get from 'lodash-es/get'
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import PaymentFormService from 'services/AirBaltic/Forms/Payment'
import PnrValidation from 'models/Payments/PnrValidation'
import PnrValidationSection from 'models/Payments/Sections/PnrValidationSection'
import FlightModel from 'models/Forms/Flights'

const pnrValidation = new PnrValidation()
const formData = {
  form: pnrValidation,
  setFromUrl: false,
  formErrors: pnrValidation.errorMsg,
  errors: pnrValidation.errors
}
const defaultState = Object.assign({}, new PnrValidationSection(), formData)

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    return {
      state: pnrValidation.validation
    }
  }
})

export default {
  namespaced: true,
  state: defaultState,
  actions: {
    clearForm({ commit }) {
      commit('pnr', null)
      commit('surname', null)
      commit('setFromUrl', false)
    },
    setPnrValidationData({ commit, dispatch }, data) {
      commit('setBookingTitle', data.bookingTitle)
      commit('setBookingDescription', data.bookingDescription)
      commit('setInfoBoxDescription', data.infoBoxDescription)
      commit('setInfoBoxCTA', data.infoBoxCTA)
      dispatch('payment/passengers/passenger_1/setPassengerData', data.validation, {
        root: true
      })
    },
    validatePnrForm({ dispatch, commit }, activeItem) {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        dispatch('validateFields', 'all')
        commit('removeError', {
          name: 'generic',
          msg: 'payments.booking.form.errors.generic.invalid'
        })
        commit('removeError', {
          name: 'generic',
          msg: 'payments.booking.form.errors.generic.noActiveFlights'
        })
        commit('removeError', {
          name: 'generic',
          msg: 'payments.booking.form.errors.generic.noActiveFlights12Hours'
        })

        if (!vState.$invalid) {
          dispatch('submitForm', activeItem)
            .then((res) => {
              dispatch('parseResponse', { activeItem, res })
                .then(() => resolve(res))
                .catch((e) => {
                  dispatch('payment/generic/clearForms', 'all', { root: true })

                  return reject(e)
                })
            })
            .catch(() => reject('payments.booking.form.errors.generic.invalid'))
        }
      })
    },
    parseResponse({ dispatch, commit }, data) {
      const flights = get(data.res, 'data.segments', [])

      if (flights.length === 0) {
        commit('payment/setPnr', '', { root: true })
      }

      if (!['invoices', 'booking_cancellation'].includes(data.activeItem)) {
        const totalSegments = flights.length
        const departedFlights = flights.filter((flight) => {
          return moment().diff(moment(flight.departureDate)) > 0
        })
        const prev12HourFlights = flights.filter((flight) => {
          return moment().diff(moment(flight.departureDate).subtract(12, 'hours')) > 0
        })

        if (totalSegments < 0 || totalSegments === departedFlights.length) {
          return Promise.reject('payments.booking.form.errors.generic.noActiveFlights')
        }

        if (totalSegments < 0 || totalSegments === prev12HourFlights.length) {
          return Promise.reject(
            'payments.booking.form.errors.generic.noActiveFlights12Hours'
          )
        }

        if (['sport_equipment'].includes(data.activeItem)) {
          commit('payment/flightList/setHasFlights', !!totalSegments, { root: true })
        }
      }

      dispatch('clearForm')
      commit('removeErrors')
      return Promise.resolve()
    },
    submitForm({ state, dispatch, commit }, activeItem) {
      return new Promise((resolve, reject) => {
        new PaymentFormService()
          .validatePnr(state.form.pnr, state.form.surname)
          .then((response) => {
            commit('payment/setPnrData', response.data, { root: true })
            dispatch('payment/passengers/passenger_1/setPassengerData', response.data, {
              root: true
            })
            dispatch('getFlightData', { flights: response.data.segments, activeItem })
            commit('payment/setPnr', state.form.pnr, { root: true })

            resolve(response)
          })
          .catch((e) => {
            commit('payment/contact/setTitle', '', { root: true })
            commit('payment/contact/setFirstName', '', { root: true })
            commit('payment/contact/setLastName', '', { root: true })
            return reject(e)
          })
      })
    },
    getFlightData({ commit }, response) {
      const flightData = []

      const data = {
        flightData: {
          flightNumber: '',
          flightDate: '',
          origin: '',
          destin: ''
        },
        flightDate: {
          flightDay: '',
          flightMonth: '',
          flightYear: ''
        }
      }

      response.flights.forEach((flight) => {
        const obj = Object.assign({}, data)

        obj.flightData = {
          flightNumber: flight.carrier + flight.number,
          departureDate: moment(flight.departureDate),
          origin: flight.origin,
          destin: flight.destin
        }

        const flightModel = new FlightModel(obj.flightData)

        obj.flightDate = {
          flightDay: flightModel.flightDate.format('DD'),
          flightMonth: flightModel.flightDate.format('MM'),
          flightYear: flightModel.flightDate.format('YYYY')
        }

        flightData.push(Object.assign({}, flightModel, obj.flightDate))
      })

      if (!response.flights.length) {
        const flightModel = new FlightModel(data.flightData)
        flightData.push(Object.assign({}, flightModel, data.flightDate))
      }

      if (
        ['booking_cancellation', 'change_flight_date_time'].includes(response.activeItem)
      ) {
        commit(`payment/flightList/setHasFlights`, !!response.flights.length, {
          root: true
        })
      }
      commit(`payment/flightList/setFlights`, flightData, { root: true })
    },
    validateFields({ dispatch }, field) {
      const vState = validator.$v.state.form

      if (field === 'pnr' || field === 'all') {
        vState.pnr.$touch()
        dispatch('checkField', {
          name: 'pnr',
          validator: 'required',
          key: 'missingValue'
        })
        dispatch('checkField', {
          name: 'pnr',
          validator: 'pnrOrTicketNumber',
          key: 'invalid'
        })
      }

      if (field === 'surname' || field === 'all') {
        vState.surname.$touch()
        dispatch('checkField', {
          name: 'surname',
          validator: 'required',
          key: 'missingValue'
        })
        dispatch('checkField', {
          name: 'surname',
          validator: 'alphaWithSpaces',
          key: 'onlyLatin'
        })
      }
    },
    checkField({ commit, state }, data) {
      if (data.name !== 'generic') {
        const obj = { name: data.name, msg: state.formErrors[data.name][data.validator] }
        if (!validator.$v.state.form[data.name][data.validator]) {
          commit('addError', obj)
        } else {
          commit('removeError', obj)
          commit('removeError', { name: 'generic' })
        }
      }
    }
  },
  mutations: {
    surname(state, value) {
      state.form.surname = value
    },
    removeErrors(state) {
      state.errors = new PnrValidation().errors
    },
    pnr(state, value) {
      state.form.pnr = value
    },
    setFromUrl(state, value) {
      state.setFromUrl = value
    },
    addError(state, error) {
      if (typeof state.errors[error.name] === 'undefined') {
        state.errors[error.name] = []
      }
      const found = state.errors[error.name].includes(error.msg)
      if (!found) {
        state.errors[error.name].push(error.msg)
      }
    },
    removeError(state, error) {
      if (error.name === 'generic') {
        state.errors.generic = []
      } else {
        const index = state.errors[error.name].indexOf(error.msg)
        if (index !== -1) {
          state.errors[error.name].splice(index, 1)
        }
      }
    },
    setBookingTitle(state, value) {
      state.bookingTitle = value
    },
    setBookingDescription(state, value) {
      state.bookingDescription = value
    },
    setInfoBoxDescription(state, value) {
      state.infoBoxDescription = value
    },
    setInfoBoxCTA(state, value) {
      state.infoBoxCTA = value
    }
  },
  getters: {
    $v() {
      return Object.assign({}, validator.$v.state.form)
    },
    validationFormErrors(state) {
      let filtered = state.errors
      if (typeof filtered !== 'undefined') {
        const values = Object.values(filtered)
        filtered = values.filter((n) => n)
      }
      return flattenDeep(filtered)
    }
  }
}
