import Vue from 'vue'
import conf from 'conf'
import * as Sentry from '@sentry/vue'
// import { Replay } from '@sentry/replay'
import { Integrations } from '@sentry/tracing'
import { detect } from 'detect-browser'
import isUndefined from 'lodash-es/isUndefined'
import get from 'lodash-es/get'

const supportedBrowsers = {
  ie: 11,
  firefox: 60,
  chrome: 68,
  ios: 11,
  opera: 50,
  safari: 11
}

const isBrowserUnsupported = () => {
  const browser = detect()
  const browserInList = browser.name in supportedBrowsers

  if (!browserInList) {
    return false
  }

  return parseInt(browser.version) < supportedBrowsers[browser.name]
}

const isUnhandledException = (event) => {
  return event.exception ? event.exception.values[0].type === 'UnhandledRejection' : false
}

const hasAxiosStacktrace = (event) => {
  let stacktrace = '/axios/lib/core/createError.js'

  if (!event.exception) {
    return false
  }

  if (event.extra) {
    if (
      [
        'WRONG_OR_EXPIRED_CREATE_PROFILE_TOKEN',
        'ALREADY_SUBSCRIBED',
        'WRONG_ORIGIN_DESTIN',
        'PINS_NUMBER_EXIST',
        'WRONG_ORIGIN_DESTIN',
        'WRONG_AIRPORT_CODE',
        'PROFILE_ALREADY_EXISTS',
        'ORDER_CANCELLED',
        'CANNOT_DISPLAY',
        'SAME_EMAIL',
        'TOO_OLD',
        'GROUP_PNR',
        'USED_PASSWORD_RESET_TOKEN',
        'WRONG_OR_EXPIRED_PASSWORD_RESET_TOKEN',
        'WRONG_ORIG_DESTINATION_PAIR'
      ].includes(event.extra.code)
    ) {
      return true
    }
  }

  let frames = get(event.exception.values[0], 'stacktrace.frames', null)

  if (frames) {
    return frames.find((frame) => frame.filename.includes(stacktrace))
  }
}

const isValidFailedToFetch = (event) => {
  if (!event.exception) return false

  const whitelist = ['googleads.g.doubleclick.net'];

  const validException = {
    type: "TypeError",
    value: "Failed to fetch"
  }

  const validExceptionList = event.exception.values.filter(v =>
      v.type === validException.type &&
      v.value === validException.value &&
      v.stacktrace
  )

  if (!validExceptionList.length) {
    return false
  }

  const whitelistedExceptions = validExceptionList.map(({ stacktrace }) => {
    let hasValidFrame = false
    whitelist.forEach(wl => {
      const validFrame = stacktrace.frames.find(f => f.filename.includes(wl))
      hasValidFrame = !!validFrame
    })

    return hasValidFrame
  })

  return whitelistedExceptions.filter(f => f).length > 0
}

const isNonError = (event) => {
  const excludedList = [
    'Non-Error promise rejection captured',
    'Non-Error exception captured',
    "InvalidCharacterError: Failed to execute 'createElement' on 'Document'",
    'NetworkError: NetworkError',
    'NetworkError: A network error occurred',
    'Error: Request aborted',
    'Network Error',
    'Request aborted',
    'Error: Network Error',
    'header.loginForm.errors.unauthorized',
    'header.loginForm.errors.loginFailed',
    'header.loginForm.errors.blacklisted',
    'NotSupportedError: The operation is not supported',
    'The operation is not supported'
  ]

  const list = excludedList.find((value) => {
    let exception = event.exception.values[0].value.trim()
    exception = exception.replace(/ +(?= )/g, '')

    return exception.startsWith(value)
  })

  return !isUndefined(list)
}

class SentryInit {
  static init() {
    if (process.env.NODE_ENV === 'production') {
      let integration = []
      let options = {
        Vue,
        attachProps: true,
        logErrors: true
      }

      if (conf.sentryBeta) {
        integration.push(
          new Integrations.BrowserTracing({
            tracingOrigins: ['localhost', 'airbaltic.com', /^\//]
          })
        )

        options.tracesSampleRate = conf.sentrySampleRate
        options.tracingOptions = { trackComponents: true }
        // Sentry replay
        // options.replaysSessionSampleRate = conf.sentryReplaysSessionSampleRate
        // options.replaysOnErrorSampleRate = conf.sentryReplaysOnErrorSampleRate
        // integration.push(new Replay())
      }

      options = Object.assign(options, {
        dsn: 'https://4cb7f2ac066f1b60738022d219a5cdb4@o4507300404920320.ingest.de.sentry.io/4507384873943120',
        integrations: integration,
        environment: process.env.NODE_ENV,
        normalizeDepth: 5,
        allowUrls: [/airbaltic\.com/],
        beforeSend: function(event, hint) {
          try {
            if (
              !hint.originalException ||
              isUnhandledException(event) ||
              isBrowserUnsupported() ||
              isNonError(event) ||
              hasAxiosStacktrace(event) ||
              isValidFailedToFetch(event)
            ) {
              return null
            }
          } catch (error) {
            console.warn('[sentry] Failed to identify originalException', error)
          }

          return event
        },
        ignoreErrors: [
          'top.GLOBALS',
          'originalCreateNotification',
          'canvas.contentDocument',
          'MyApp_RemoveAllHighlights',
          'http://tt.epicplay.com',
          "Can't find variable: ZiteReader",
          'jigsaw is not defined',
          'ComboSearch is not defined',
          'http://loading.retry.widdit.com/',
          'atomicFindClose',
          'fb_xd_fragment',
          'bmi_SafeAddOnload',
          'EBCallBackMessageReceived',
          'conduitPage',
          'Error: Request failed with status code 403',
          'doLogout',
          'failedLogout',
          'sharedStorage is disabled'
        ],
        denyUrls: [
          /graph\.facebook\.com/i,
          /connect\.facebook\.net\/en_US\/all\.js/i,
          /eatdifferent\.com\.woopra-ns\.com/i,
          /static\.woopra\.com\/js\/woopra\.js/i,
          /extensions\//i,
          /^chrome:\/\//i,
          /127\.0\.0\.1:4001\/isrunning/i,
          /webappstoolbarba\.texthelp\.com\//i,
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
        ]
      })

      Sentry.init(options)
    }
  }
}

export default SentryInit
