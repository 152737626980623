import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import Step3Modal from 'models/Pax/CharterForm/Step3'

const step3Modal = new Step3Modal()
const formState = Object.assign({}, { formErrors: step3Modal.errorMsg }, step3Modal)

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return formState
    }
  },
  validations () {
    return {
      state: step3Modal.validations
    }
  }
})

const Step3 = {
  namespaced: true,
  state: formState,
  mutations: {
    setFirstName (state, value) {
      state.form.firstName = value
    },
    setLastName (state, value) {
      state.form.lastName = value
    },
    setEmail (state, value) {
      state.form.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setIntCode (state, value) {
      state.form.intCode = value
    },
    setPhoneNumber (state, value) {
      state.form.phoneNumber = value
    }
  },
  actions: {
    clearStep ({ commit }) {
      validator.$v.state.form.$reset()

      commit('setFirstName', '')
      commit('setLastName', '')
      commit('setEmail', '')
      commit('setIntCode', '')
      commit('setPhoneNumber', '')
    },
    validateForm ({ dispatch }) {
      const actions = [
        dispatch(`validateStep`),
        dispatch(`charterForm/company/validateCompany`, null, { root: true })
      ]

      return Promise.all(actions)
        .then(() => {
          return dispatch('charterForm/getDataAndSubmitForm', null, { root: true })
            .then((response) => {
              dispatch('clearStep')
              return Promise.resolve(response)
            })
            .catch((e) => Promise.reject(e))
        })
        .catch(() => Promise.reject({ type: 'form' }))
    },
    validateStep () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state
        vState.$touch()

        return !vState.$invalid ? resolve() : reject()
      })
    },
    getData ({ state }) {
      const code = state.form.intCode.split("|")
      const data = {
        email: state.form.email,
        firstName: state.form.firstName,
        lastName: state.form.lastName,
        intCode: code[0],
        phoneNumber: state.form.phoneNumber
      }

      return Promise.resolve(data)
    }
  },
  getters: {
    $v () {
      return Object.assign({}, validator.$v.state.form)
    },
    step3FormErrors (state) {
      return state.formErrors
    }
  }
}

export default Step3
