import SidebarStore from 'store/modules/Sidebar/Sidebar'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, SidebarStore)

      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    if (typeof sidebar !== "undefined") {
      store.commit(`${name}/setLeftMenu`, sidebar.leftMenu)
      store.commit(`${name}/setPleaseNoteTitle`, sidebar.pleaseNoteTitle)
      store.commit(`${name}/setPleaseNoteText`, sidebar.pleaseNoteText)
    }
  }
}

export default Init
