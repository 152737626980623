var ContactSection = /** @class */ (function () {
    function ContactSection(obj) {
        if (obj === void 0) { obj = {}; }
        this.contactInformationTitle = '';
        this.contactInformationDescription = '';
        Object.assign(this, obj);
    }
    return ContactSection;
}());
export default ContactSection;
