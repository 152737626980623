import AbstractView from 'abstract';
import Vue from 'vue'
import IndexPage from 'containers/Pages/IndexPage'
import store from 'definitions/store'
import indexPageInit from 'initializations/pages/IndexPage'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        indexPageInit.init()

        new Vue({
            store,
            ...IndexPage
        }).$mount("#" + this.elemId)
    }
}
