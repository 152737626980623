import EvePaymentsStore from 'store/modules/Pages/EvePayments'
import SidebarStore from 'store/modules/Sidebar/Sidebar'
import store from 'definitions/store'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule('sidebar', SidebarStore)
            store.registerModule(name, EvePaymentsStore)

            Init.setPageData(name)
        }
    },
    setPageData (name) {
        if (typeof evePaymentPage !== 'undefined') {
            store.dispatch(`${name}/setEvePaymentsPage`, evePaymentPage)
        }

        if (typeof eveSuccessPage !== 'undefined') {
            store.dispatch(`${name}/setEveSuccessPage`, eveSuccessPage)
        }

        if (typeof eveFailedPage !== 'undefined') {
            store.dispatch(`${name}/setEveFailedPage`, eveFailedPage)
        }

        if (typeof eveCanceledPage !== 'undefined') {
            store.dispatch(`${name}/setEveCanceledPage`, eveCanceledPage)
        }

        if (typeof eveCancelPage !== 'undefined') {
            store.dispatch(`${name}/setEveCancelPage`, eveCancelPage)
        }

        if (typeof noteSnippet !== 'undefined') {
            store.commit(`sidebar/setPleaseNoteTitle`, noteSnippet.title)
            store.commit(`sidebar/setPleaseNoteText`, noteSnippet.text)
        }
    }
}

export default Init
