import Pimcore from 'services/AirBaltic/Pimcore'
import ErrorHandler from 'utils/ErrorHandler'

const ERRORS = {}

class PressReleases {
  getReleases (year, selectedLang) {
    return new Pimcore().get(`/press-releases/${selectedLang}/${year}`)
      .then((response) => Promise.resolve(response.data))
      .catch(error => Promise.reject(this.getErrors(error.response)))
  }

  getSingleRelease (releaseId, lang) {
    return new Pimcore().get(`/single-release/${releaseId}?language=${lang}`)
      .then((response) => Promise.resolve(response.data))
      .catch(error => Promise.reject(this.getErrors(error.response)))
  }

  getReleaseYears (selectedLang) {
    return new Pimcore().get(`/release-years/${selectedLang}`)
      .then((response) => Promise.resolve(response.data))
      .catch(error => Promise.reject(this.getErrors(error.response)))
  }

  getErrors (response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default PressReleases


