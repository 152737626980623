<template>
  <div class="bbb-item">
    <div class="bbb-content">
      <a
        v-for="(item, itemIndex) in data"
        :key="itemIndex"
        :href="getBannerUrl(item)"
        class="bbb-subitem"
        @click="showLoader(item)"
      >
        <div class="bbb-blocks">
          <div class="bbb-subitem-left-block">
            <span class="origin">
              {{ getFlightLabel(item.orig) }} -
            </span>
            <span class="destination">
              {{ getFlightLabel(item.dest) }}
            </span>
          </div>
          <div
            v-if="item.price"
            class="bbb-subitem-right-block"
          >
            <span class="month">{{ getMonthName(item.monthId) }}</span>
            <div class="price-block">
              <span class="from">{{ $t('bestBuyBanners.from') }}</span>
              <span class="price">{{ getWholeNumber(item.price) }}</span>
              <sup class="decimal-price">99</sup>
              <span class="currency">€</span>
            </div>
          </div>
        </div>
        <template v-if="typeof item.labels !== 'undefined'">
          <div class="labels">
            <span
              v-for="(label, key) in item.labels"
              :key="`label_${label}_${key}`"
              :class="['group', typeof label !== 'undefined' ? getItemLabelKey(label) : '']"
            >
              {{ getItemLabelTranslated(label) }}
            </span>
          </div>
        </template>
      </a>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import gtmBestBuyBanners from 'services/AirBaltic/GTM/BestBuyBanners'
  import moment from 'moment'
  import head from 'lodash-es/head'
  import get from 'lodash-es/get'
  import conf from 'conf'

  export default {
    props: {
      data: {
        type: Array,
        required: true
      },
      index: {
        type: Number,
        required: true
      },
      page: {
        type: Number,
        required: true
      }
    },

    computed: {
      ...mapState('main', [
        'selectedLang',
        'selectedCountry'
      ]),
      ...mapState('bestBuyBanners', {
        origDestLabels: 'labels'
      }),
      ...mapState('eHorizonSearchForm', [
        'origDestData',
      ])
    },
    methods: {
      getMonthName(month) {
        const dateInst = new moment().add(month - 2, 'months').date(1)
        return moment.months(parseInt(dateInst.format('M')))
      },
      getFlightLabel (code) {
        if (this.origDestData && typeof this.origDestData[`${code}A`] !== 'undefined') {
          return this.origDestData[`${code}A`].city
        }
        return code
      },
      getItemLabel (labels) {
        return head(labels).toLowerCase()
      },
      getItemLabelKey (labels) {
        return labels.toLowerCase().replace('label_', '')
      },
      getItemLabelTranslated(labels) {
        const key = labels.toLowerCase()
        return get(this.origDestLabels, key, '')
      },
      showLoader (item) {
        gtmBestBuyBanners.click(item, this.page)
        this.$store.commit('main/showPlaneLoader', true)
      },
      getWholeNumber (price) {
        return Math.trunc(price)
      },
      getBannerUrl (item) {
        const url = `${conf.newIbeUrl}/${this.selectedLang}/fb/calendar`
        const params = {
          p: 'BTI',
          sref: 'BBX',
          pos: this.selectedCountry,
          l: this.selectedLang,
          originCode: item.orig,
          destinCode: item.dest,
          originType: 'A',
          destinType: 'A',
          selectedMonth: Number(item.monthId - 1),
          numAdt: 1,
          tripType: 'oneway'
        }

        return url + '?' + Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[`${key}`])
        }).join('&')
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  .bbb-subitem {
    display: block;
    padding: 12px 0 11px;
    border-bottom: 1px solid $gray-darker-border;
    color: $grey-default;
    text-decoration: none;
    min-height: 93px;

    &:hover {

      .bbb-subitem-left-block .destination {
        color: $blue-light;
      }
    }

    .bbb-blocks {
      display: flex;
      flex-direction: row;
    }

    .bbb-subitem-left-block,
    .bbb-subitem-right-block {

      > span {
        display: block;
      }
    }

    .bbb-subitem-left-block {
      width: 60%;

      .origin {
        font-size: em(11);
        font-weight: 500;
        line-height: 14px;
      }

      .destination {
        margin-top: 8px;
        font-size: em(16);
        line-height: 19px;
        font-weight: 500;
        color: $blue-medium;
      }
    }

    .bbb-subitem-right-block {
      width: 40%;
      text-align: right;

      .month {
        font-size: em(12);
        line-height: 14px;
        color: $grey;
        text-transform: capitalize;
      }

      .price-block {
        margin-top: 4px;
        white-space: nowrap;

        .from {
          margin-right: 5px;
          font-size: em(12);
          line-height: 14px;
        }

        .price {
          font-size: em(20);
          font-weight: 500;
          line-height: 24px;
        }

        .decimal-price {
          color: $grey-default;
          font-size: 11px;
          font-weight: 500;
          top: -8px;
        }

        .currency {
          font-size: em(16);
          line-height: 19px;
          font-weight: 500;
        }
      }
    }

    .labels {
      display: block;

      .group {
        padding: 3px 6px;
        border-radius: 3px;
        background-color: rgba(172, 185, 12, 0.15);
        color: $green-dark;
        display: inline-block;
        font-size: em(11);
        font-weight: 500;
        line-height: 14px;
        margin-right: 8px;
        margin-top: 8px;

        &:last-child {
          margin-right: 0;
        }

        &.newdestinations, &.new {
          background-color: rgba(172, 185, 12, 0.15);
          color: $green-dark;
        }

        &.direct, &.direct_flights {
          background-color: rgba(172, 185, 12, 0.15);
          color: $green-dark;
        }

        &.blank {
          background-color: transparent;
          color: transparent;
        }
      }
    }
  }
</style>
