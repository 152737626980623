import AirbusStore from 'store/modules/Pages/Airbus'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, AirbusStore)

      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {

    if (typeof pageData !== "undefined") {
      store.commit(`${name}/setPageData`, pageData)
    }

    if (pageData.pressContacts) {
      store.commit('sidebar/setPressContacts', pageWithSidebar.pressContacts)
    }
  }
}

export default Init
