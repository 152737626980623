import AbstractView from 'abstract'
import Vue from 'vue'
import store from 'definitions/store'
import selfServiceInit from 'initializations/pages/SelfService'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    selfServiceInit.init("selfService")

    const CustomerSelfService = () => import(/* webpackChunkName: "customer-self-service-page" */ 'containers/Pages/CustomerSelfService/Index')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(CustomerSelfService)
    })
  }
}
