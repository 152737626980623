import LandingPageStore from 'store/modules/Pages/CampaignLandingPage'
import store from 'definitions/store'
import moment from 'moment'
import getNearestAirport from 'utils/GetNearestAirport'
import isNull from 'lodash-es/isNull'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, LandingPageStore)
      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    if (typeof clpOrigDestData !== "undefined") {
      store.commit(`${name}/setOrigins`, clpOrigDestData)

      let hasPrice = false
      let hasDates = []
      Object.keys(clpOrigDestData).forEach(key => {
        for (let d of clpOrigDestData[key].destinations) {
          if (d.price) {
            hasPrice = true
            break;
          }

          for (let d of clpOrigDestData[key].destinations) {
            const isDateInPast = moment().isAfter(moment(d.startDate, 'DD.MM.YYYY'))

            if (!isDateInPast) {
              if (!hasDates.includes(key)) {
                hasDates.push(key)
              }
              break;
            }
          }
        }
      })

      store.commit(`${name}/setHasPrices`, hasPrice)
      store.commit(`${name}/setHasDates`, hasDates)
    }

    if (typeof landingPageInit !== "undefined") {
      store.commit(`${name}/setBannerDesktop`, landingPageInit.desktopImg)
      store.commit(`${name}/setBannerTablet`, landingPageInit.tabletImg)
      store.commit(`${name}/setBannerMobile`, landingPageInit.mobileImg)

      store.dispatch(`${name}/setSelectedOrigin`, landingPageInit.defaultOriginAirport)
      store.commit(`${name}/setIntroText`, landingPageInit.introText)
      store.commit(`${name}/setPageTitle`, landingPageInit.pageTitle)
      store.commit(`${name}/setSaleDescriptionItems`, landingPageInit.saleContent)

      if (landingPageInit.timerEnabled) {
        store.commit(`${name}/setTimerTitle`, landingPageInit.timerTitle)
        store.dispatch(`${name}/setDeadline`, {
          date: landingPageInit.timerDate ? landingPageInit.timerDate.date : '',
          time: landingPageInit.timerTime
        })
      }

      if (landingPageInit.geoLocationEnabled) {
        const airport = getNearestAirport(true)
        if (!isNull(airport)) {
          if (typeof clpOrigDestData !== "undefined") {
            if (Object.keys(clpOrigDestData).includes(airport)) {
              store.dispatch(`${name}/setSelectedOrigin`, airport)
            } else if (typeof landingPageInit.defaultOriginAirport !== 'undefined' && landingPageInit.defaultOriginAirport !== '') {
              store.dispatch(`${name}/setSelectedOrigin`, landingPageInit.defaultOriginAirport)
            }
          }
        }
      } else {
        store.dispatch(`${name}/setSelectedOrigin`, landingPageInit.defaultOriginAirport)
      }

      store.commit(`${name}/setFlightDetails`, landingPageInit.flightDetails)
      store.dispatch(`${name}/setSelectedSorter`, landingPageInit.defaultSortingOption)
      store.commit(`${name}/setDefaultFlightType`, landingPageInit.defaultFlightType)
      store.commit(`${name}/setTermsAndConditions`, landingPageInit.termsAndConditions)
      store.commit(`${name}/setNoteText`, landingPageInit.noteText)
      store.commit(`${name}/setIconType`, landingPageInit.iconType)
    }
  }
}

export default Init
