import CookieUtils from 'utils/CookieUtils'
import difference from 'lodash-es/difference'
import flatMap from 'lodash-es/flatMap'
import cloneDeep from 'lodash-es/cloneDeep'

const Header = {
  state: {
    open: false,
    logo: {
      background: 'green-base',
      image: {}
    },
    profileSection: {},
    topNavLinks: [],
    headerLinks: [],
    whiteHeaderLinks: [],
    profileMenuLinks: [],
    profileMenuCleanLinks: [],
    alert: {
      type: '',
      content: '',
      closable: false,
      isBold: false,
      showDefaultIcon: true,
      icon: null
    },
    preHeaderOpenItems: {
      location: false,
      myBt: false,
      profile: false,
      search: false,
      notification: false,
      hasOpenItems: false
    },
    activeNavItem: '',
    breadcrumbs: [],
    notificationCount: 0,
    offers: [],
    tabs: [],
    searchForm: {},
    flightStatus: {},
    backdropVisible: false,
    campaignCounter: {
      active: false
    }
  },
  mutations: {
    setBackdropVisible(state, value) {
      state.backdropVisible = value
    },
    setLogo(state, value) {
      state.logo = value
    },
    setTabs(state, value) {
      state.tabs = value
    },
    setTopNavLinks(state, value) {
      state.topNavLinks = value
    },
    setProfileSection(state, value) {
      state.profileSection = value
    },
    setOpen(state, value) {
      state.open = value
    },
    setNotificationCount(state, value) {
      state.notificationCount = value
    },
    setHeaderLinks(state, value) {
      state.headerLinks = value
    },
    setWhiteHeaderLinks(state, value) {
      state.whiteHeaderLinks = value
    },
    setProfileMenuLinks(state, value) {
      state.profileMenuLinks = value
    },
    setProfileMenuCleanLinks(state, value) {
      state.profileMenuCleanLinks = value
    },
    setWebsiteData(state, value) {
      state.languages = value
    },
    setCountries(state, value) {
      state.countries = value
    },
    setGlobalAlert(state, value) {
      state.alert = value
    },
    closeAllPreHeaderItems(state) {
      state.preHeaderOpenItems.location = false
      state.preHeaderOpenItems.myBt = false
      state.preHeaderOpenItems.profile = false
      state.preHeaderOpenItems.search = false
      state.preHeaderOpenItems.notification = false
      state.preHeaderOpenItems.hasOpenItems = false
      state.backdropVisible = false
    },
    openPreHeaderItem(state, value) {
      state.preHeaderOpenItems[value] = true
      state.preHeaderOpenItems.hasOpenItems = true
    },
    setActiveNavItem(state, value) {
      state.activeNavItem = value
    },
    clearActiveNavItem(state) {
      state.activeNavItem = ''
    },
    setBreadcrumbs(state, value) {
      state.breadcrumbs = value
    },
    setOffers(state, value) {
      state.offers = value
    },
    setSearchForm(state, value) {
      state.searchForm = value
    },
    setFlightStatus(state, value) {
      state.flightStatus = value
    },
    setCampaignCounter(state, value) {
      state.campaignCounter = value
    }
  },
  actions: {
    openPreHeaderItem({ commit }, value) {
      commit('closeAllPreHeaderItems')
      commit('openPreHeaderItem', value)
    },
    setNotificationCount({ commit, state }) {
      const notificationCookie = CookieUtils.getCookie('notificationsAlerts').split(',')
      const notifications = flatMap(state.offers, 'key')

      const count = difference(notifications, notificationCookie).length

      commit('setNotificationCount', count)
    },
    setOffers({ commit, state }, data) {
      const topNavLinks = cloneDeep(state.topNavLinks)

      const offersBlock = topNavLinks.find((block) => block.component === 'header-offers')

      offersBlock.offers = data.offers
      offersBlock.total = data.total

      commit('setTopNavLinks', topNavLinks)
    },
    setProfileSection({ commit }, data) {
      commit('setProfileSection', data)
      commit('setProfileMenuLinks', data.oldProfileLinks)
      commit('setProfileMenuCleanLinks', data.newProfileLinks)
    }
  },
  namespaced: true
}

export default Header
