import BusinessTravelStore from 'store/modules/Pages/ProfilePages/BusinessTravel'
import store from 'definitions/store'
import PaxProfile from 'services/AirBaltic/Pax/Profile'
import * as Sentry from '@sentry/vue'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, BusinessTravelStore)

      Init.initPageData(name)
      Init.initPaxDetails(name)
      Init.initSidebarData()
    }
  },

  initPageData(name) {
    store.commit('main/setPageType', 'profile')
    store.commit(`${name}/setPageTitle`, pageData.title)
    store.commit(`${name}/setDescription`, pageData.description)
    store.commit(`${name}/setSectionTitle`, pageData.sectionTitle)
    store.commit(`${name}/setTooltipModalTitle`, pageData.tooltipModalTitle)
    store.commit(`${name}/setTooltipModalUrl`, pageData.tooltipModalUrl)

    if (typeof countries !== 'undefined') {
      store.commit(`${name}/setCountries`, countries)
    }
  },

  initSidebarData() {
    if (typeof pressContacts !== 'undefined') {
      store.commit('sidebar/setPressContacts', pressContacts)
    }
  },

  initPaxDetails(name) {
    new PaxProfile()
      .getPaxProfileData()
      .then(({ companyDetails }) => {
        if (!companyDetails) return

        store.commit(`${name}/setBusinessCountry`, companyDetails.countryCode)
        store.commit(`${name}/setBusinessCompanyName`, companyDetails.name)
        store.commit(`${name}/setBusinessStreet`, companyDetails.street)
        store.commit(`${name}/setBusinessRegNo`, companyDetails.regNo)
        store.commit(`${name}/setBusinessVatNumber`, companyDetails.vat)
        store.commit(`${name}/setBusinessPostcode`, companyDetails.postcode)
        store.commit(`${name}/setBusinessCity`, companyDetails.city)
      })
      .catch((e) => Sentry.captureException(e))
  }
}

export default Init
