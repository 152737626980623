import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import divolteCompliment from 'services/AirBaltic/Divolte/Compliment'
import ComplimentService from 'services/AirBaltic/Forms/Compliment'
import ComplimentModel from 'models/Compliment'
import moment from 'moment'
import get from 'lodash-es/get'

const compliment = new ComplimentModel()
const formData = {
  form: compliment.form,
  formErrors: compliment.errorMsg,
}

const defaultState = Object.assign({}, formData)

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations () {
    return {
      state: compliment.validation
    }
  }
})

export default {
  state: {
    pageTitle: '',
    intro: '',
    description: '',
    form: defaultState.form,
    formErrors: defaultState.formErrors
  },
  mutations: {
    setPageTitle (state, value) {
      state.pageTitle = value
    },
    setIntro (state, value) {
      state.intro = value
    },
    setDescription (state, value) {
      state.description = value
    },
    setItem(state, { data }) {
      state.form[data.key] = data.value
    },
    setPaxTitle (state, value) {
      state.form.title = value
    },
    setFirstName (state, value) {
      state.form.firstName = value
    },
    setLastName (state, value) {
      state.form.lastName = value
    },
    setEmail (state, value) {
      state.form.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setFlightNumber (state, value) {
      state.form.flightNumber = value.toUpperCase()
    },
    setFlightDate (state, value) {
      state.form.flightDate = value
    },
    setFlightMonth (state, value) {
      state.form.flightMonth = value
    },
    setFlightYear (state, value) {
      state.form.flightYear = value
    },
    setDate (state, value) {
      state.form.date = value
    },
    setBookingReference (state, value) {
      state.form.bookingReference = value
    },
    setMessage (state, value) {
      state.form.message = value
    },
  },
  actions: {
    clearForm ({ commit }) {
      commit('setPaxTitle', '')
      commit('setFirstName', '')
      commit('setLastName', '')
      commit('setEmail', '')
      commit('setFlightNumber', '')
      commit('setFlightDate', '')
      commit('setFlightMonth', '')
      commit('setFlightYear', '')
      commit('setDate', '')
      commit('setBookingReference', '')
      commit('setMessage', '')

      validator.$v.state.form.$reset()
    },
    setFlightDate ({ commit, dispatch }, data) {
      commit('setFlightDate', data)
      dispatch('validateDate')
    },
    setFlightMonth ({ commit, dispatch }, data) {
      commit('setFlightMonth', data)
      dispatch('validateDate')
    },
    setFlightYear ({ commit, dispatch }, data) {
      commit('setFlightYear', data)
      dispatch('validateDate')
    },
    validateDate ({ state, commit }) {
      const flightDate = state.form.flightDate
      const flightMonth = state.form.flightMonth
      const flightYear = state.form.flightYear

      const date = moment(`${flightYear}-${flightMonth}-${flightDate}`, 'YYYY-M-D', true)

      if (date.isValid()) {
        commit('setDate', date.format('YYYY-MM-DD'))
      }
    },
    validateForm ({ dispatch }) {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        if (!vState.$invalid) {
          dispatch('submitForm')
            .then(() => {
              dispatch('divoltePush')
              resolve()
            })
            .catch(e => reject({ type: 'submit', msg: e}))
        } else {
          reject({ type: 'validation', msg: 'compliment form validation failed'})
        }
      })
    },
    divoltePush ({ state }) {
      divolteCompliment.divoltePush(state.form)
    },
    submitForm ({ state, getters }) {
      return new Promise((resolve, reject) => {
        const obj = {
          bookingRef: state.form.bookingReference,
          contact: getters['contact'],
          message: state.form.message
        }

        const flightNumber = get(state, 'form.flightNumber', '');
        const flightDate = get(state, 'form.date', '');

        if (flightNumber !== '' && flightDate !== '') {
          Object.assign(obj, {
            flightList: [{
              flightNumber: flightNumber.trim().toUpperCase(),
              flightDate
            }]
          })
        }

        const title = get(state, 'form.title', '');
        const firstName = get(state, 'form.firstName', '');
        const lastName = get(state, 'form.lastName', '');

        if (title !== '' && firstName !== '' && lastName !== '') {
          Object.assign(obj, { passengers: [{ title, firstName, lastName}] })
        }

        return new ComplimentService().submitForm(obj)
          .then(() => resolve(true))
          .catch((error) => reject(error))
      })
    }
  },
  getters: {
    contact (state, getters, rootState) {
      return {
        language: rootState.main.selectedLang.toUpperCase(),
        title: state.form.title,
        firstName: state.form.firstName,
        lastName: state.form.lastName,
        email: state.form.email,
        behalfOfOtherPerson: false
      }
    },
    $v () {
      return Object.assign({}, validator.$v.state)
    },
    complimentFormErrors (state) {
      return state.formErrors
    }
  },
  namespaced: true
}
