import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import bestBuyBannersWrapper from 'components/BestBuyBanners/BestBuyBannersWrapper'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        new Vue({
          store,
          ...bestBuyBannersWrapper
        }).$mount("#" + this.elemId)
    }
}
