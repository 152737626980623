import AbstractView from 'abstract';
import Vue from 'vue'
import eHorizonTabsWrapper from 'templates/TabsWrapper'
import store from 'definitions/store'
import eHorizonSearchFormInit from 'initializations/eHorizon/SearchForm'
import eHorizonCheckinFormInit from 'initializations/eHorizon/CheckinForm'
import eHorizonIndexTabsInit from 'initializations/eHorizon/IndexTabs'
import eHorizonMmbForm from 'initializations/eHorizon/MmbForm'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    eHorizonIndexTabsInit.init("indexTabs")
    eHorizonSearchFormInit.init("eHorizonSearchForm")
    eHorizonMmbForm.init("mmbForm")
    eHorizonCheckinFormInit.init("checkinForm")

    new Vue({
      store,
      ...eHorizonTabsWrapper
    }).$mount("#" + this.elemId)
  }
}
