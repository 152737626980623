import ClaimsStore from 'store/modules/Pages/Claims/ClaimsPage'
import SearchFormStore from 'store/modules/eHorizon/SearchForm/SearchForm'
import store from 'definitions/store'
import isEmpty from 'lodash-es/isEmpty'
import OrigDest from 'utils/OrigDestData'

const Init = {
  init(name) {
    if (!store.hasModule(name)) {
      store.registerModule(name, ClaimsStore)
      if (!store.hasModule('eHorizonSearchForm')) {
        store.registerModule('eHorizonSearchForm', SearchFormStore)
      }

      Init.initDefaultValues(name)
    }
  },
  initDefaultValues(name) {
    if (typeof pageData !== "undefined") {
      store.commit(`${name}/setPageData`, pageData)
    }

    if (typeof pnrData !== "undefined") {
      store.commit(`${name}/pnr/setBookingTitle`, pnrData.pnrTitle)
      store.commit(`${name}/pnr/setBookingDescription`, pnrData.pnrDescription)
      store.commit(`${name}/pnr/setInfoBoxDescription`, pnrData.pnrTitle)
      store.commit(`${name}/pnr/setInfoBoxCTA`, pnrData.infoBoxCTA)
      store.commit(`${name}/pnr/setInfoBoxDescription`, pnrData.infoBoxDescription)
    }

    if (typeof formLayoutData !== "undefined") {
      store.commit(`${name}/setFormLayoutData`, formLayoutData)
    }

    if (typeof origDestData !== 'undefined') {
      store.commit(`eHorizonSearchForm/setOrigDestData`,  OrigDest.parseData(origDestData))
    }

    if (typeof activeItem !== "undefined") {
      let category = isEmpty(activeItem) ? null : activeItem
      let types = ['DAMAGED', 'LOST', 'PILFERED']

      if (!category) {
        return
      }

      if (types.includes(category.key)) {
        store.commit('claims/form/baggage/setReceivedBaggage', 'yes')
      }

      if (category.key === 'DAMAGED') {
        store.commit('claims/form/baggage/setReceiptSection', true)
      }

      store.commit('claims/form/setCategoryType', category.category)
      store.commit('claims/form/flights/setCategory', category.category)
      store.commit('claims/setActiveStep', category)
    }

    if (typeof token !== "undefined") {
      store.commit('claims/files/setToken', token)
    }
  }
}

export default Init
