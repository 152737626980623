import PaxPins from 'services/AirBaltic/Pax/Pins'
import PaxProfile from 'services/AirBaltic/Pax/Profile'

import get from 'lodash-es/get'

const defaultState = {
  pageData: {
    formInfo: '',
    tooltipModalTitle: '',
    tooltipModalUrl: '',
    description: '',
    pinsSectionText: '',
    pinsNumberLabel: '',
    pinsCollectedLabel: '',
    pinsLevelLabel: '',
    cards: {
      BASIC: '',
      EXEC: '',
      VIP: ''
    },
    unlock: '',
    own: '',
    member: ''
  },
  description: '',
  pinsNumber: '',
  pinsLevels: {},
  pinsCollected: 0,
  pinsData: {
    firstName: '',
    lastName: ''
  },
  pinsError: null,
  firstName: '',
  familyName: ''
}

const PinsStore = {
  state: defaultState,
  mutations: {
    setDescription(state, value) {
      state.description = value
    },
    setPinsNumber(state, value) {
      state.pinsNumber = value
    },
    setPinsCollected(state, value) {
      state.pinsCollected = value
    },
    setPinsLevels(state, value) {
      state.pinsLevels = value
    },
    setPinsData(state, value) {
      state.pinsData = value
    },
    setPageData(state, value) {
      state.pageData = value
    },
    setPinsError(state, data) {
      state.pinsError = data
    }
  },
  actions: {
    updatePaxAccount({ state }) {
      const PaxProfileService = new PaxProfile()
      const fieldsToUpdate = {
        firstName: state.pinsData.firstName,
        lastName: state.pinsData.lastName
      }

      return PaxProfileService.updatePaxProfile(fieldsToUpdate)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error))
    },
    setPaxPinsData({ commit, dispatch }, paxPinsData) {
      const existingMember = get(paxPinsData, 'existingPinsMember', false)
      const pinsNumber = get(paxPinsData, 'pinsNumber', 0)
      commit('main/user/setPinsConnected', existingMember, { root: true })
      commit('setPinsNumber', pinsNumber)
      dispatch('getUserDataFromPins')
    },
    getUserDataFromPins({ commit, state }) {
      if (state.pinsNumber === 0) {
        return
      }

      const PaxPinsService = new PaxPins()

      PaxPinsService.getPinsData(state.pinsNumber)
        .then((response) => {
          commit('setPinsData', response)
          commit('setPinsCollected', get(response, 'balance', 0))
          commit('setPinsError', null)
        })
        .catch((error) => {
          commit('setPinsData', {
            firstName: '',
            lastName: ''
          })
          commit('setPinsError', error)
        })
    }
  },
  namespaced: true
}

export default PinsStore
