import SiloStore from 'store/modules/Pages/SiloPage'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, SiloStore)
      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    if (typeof siloInit !== "undefined") {
      store.commit(`${name}/setPageTitle`, siloInit.title)
      store.commit(`${name}/setDesktopImg`, siloInit.desktopImg)
      store.commit(`${name}/setTabletImg`, siloInit.tabletImg)
      store.commit(`${name}/setMobileImg`, siloInit.mobileImg)
      store.commit(`${name}/setItems`, siloInit.siloItems)
    }
  }
}

export default Init
