const CharterPage = {
  state: {
    pageData: {
      charter: {},
      wetLease: {},
      cargo: {},
      features: [],
      fleet: [],
      customers: [],
    },
    selectedForm: ''
  },
  mutations: {
    setPageData (state, value) {
      state.pageData = value
    },

    setSelectedForm (state, value) {
      state.selectedForm = value
    }
  },
  namespaced: true
}

export default CharterPage
