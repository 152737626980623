import Default from 'store/modules/Pages/Claims/Form/Default'
import Pnr from 'store/modules/Pages/Claims/Form/Pnr'
import Files from 'store/modules/Pages/Claims/Form/Files'
import Vue from 'vue'

const Claims = {
  modules: {
    form: Default,
    pnr: Pnr,
    files: Files
  },
  state: {
    pageData: {
      pageTitle: '',
      description: '',
      formList: [],
      formListTitle: '',
      infoBlock: {}
    },
    activeStep: '',
    formLayoutData: {},
    pnrFilled: false,
    origDestData: {}
  },
  mutations: {
    setPageData(state, value) {
      state.pageData = value
    },
    setActiveStep(state, value) {
      state.activeStep = value
    },
    setFormLayoutData(state, value) {
      state.formLayoutData = value
    },
    setPnrFilled(state, value) {
      state.pnrFilled = value
    },
    setOrigDestData(state, value) {
      state.origDestData = value
    }
  },

  actions: {
    clearAllData({ commit, dispatch }) {
      commit('setActiveStep', '')
      commit('setPnrFilled', false)
      dispatch('claims/pnr/clearForm', null, { root: true })
      dispatch('claims/form/clearForm', null, { root: true })
      dispatch('claims/files/clearForm', null, { root: true })
    },
    showSuccessModal({ commit, dispatch }) {
      let props = {
        status: 'success',
        successTitle: Vue.i18n.translate('claims.popupMessages.success.successTitle'),
        successMessage: Vue.i18n.translate('claims.popupMessages.success.successMessage'),
        showCta: true
      }
      const DefaultFormNotification = import(
        /* webpackChunkName: "error-notification" */ 'components/Notifications/DefaultFormNotification'
      )

      DefaultFormNotification.then((result) => {
        commit('main/setModalContent', result.default, { root: true })
      })

      this.status = 'success'
      dispatch('main/openModal', null, { root: true })

      commit('main/setModalType', 'centered', { root: true })
      commit('main/setModalClass', 'modal-white-centered', { root: true })
      commit('main/setShowModalHeaderWhite', true, { root: true })
      commit('main/setModalTitle', '', { root: true })
      commit('main/setShowCloseIcon', true, { root: true })
      commit('main/setModalProps', props, { root: true })
      commit(
        'main/setModalCloseFn',
        () => {
          dispatch('closeModal')
        },
        { root: true }
      )
    },
    closeModal({ dispatch }) {
      if (this.status === 'success') {
        window.location.reload()
      }
      dispatch('main/closeModal', null, { root: true })
    }
  },

  namespaced: true
}

export default Claims
