const FareConditions = {
  state: {
    pageData: {
      pageTitle: '',
      description: '',
      tables: [],
      tableHeader: [],
      bottomContent: ''
    }
  },

  mutations: {
    setPageTitle (state, value) {
      state.pageData.pageTitle = value
    },
    setDescription (state, value) {
      state.pageData.description = value
    },
    setTables (state, value) {
      state.pageData.tables = value
    },
    setTableHeader (state, value) {
      state.pageData.tableHeader = value
    },
    setBottomContent (state, value) {
      state.pageData.bottomContent = value
    }
  },

  actions: {
    setPageData ({ commit }, data) {
      commit('setPageTitle', data.pageTitle)
      commit('setDescription', data.description)
      commit('setTables', data.tables)
      commit('setTableHeader', data.tableHeader)
      commit('setBottomContent', data.bottomContent)
    }
  },

  namespaced: true
}

export default FareConditions
