var Booking = /** @class */ (function () {
    function Booking() {
        this.pnrRl = '';
        this.carrier = '';
        this.flightNumber = 0;
        this.origin = { code: '', description: '' };
        this.destin = { code: '', description: '' };
        this.departureDate = '';
        this.arrivalDate = '';
        this.daysTillDeparture = 0;
        this.scheduleChange = false;
        this.pnrRetrieveToken = '';
        this.passengers = [];
    }
    return Booking;
}());
export default Booking;
