import AxiosUtils from 'utils/AxiosUtils'

const UnsubscribePage = {
  state: {
    pageData: {
      title: '',
      subtitle: '',
      description: '',
      buttonText: '',
      checkboxes: []
    }
  },
  mutations: {
    setPageData (state, value) {
      state.pageData = value
    },

    setChecked (state, index) {
      state.pageData.checkboxes[`${index}`].checked = !state.pageData.checkboxes[`${index}`].checked
    }
  },

  actions: {
    submitForm (context, json) {
      return new Promise((resolve, reject) => {
        const obj = {
          controller: 'Ajax_Vue_Unsubscribe',
          action: 'unsubscribe',
          checked: JSON.stringify(json.checked),
          unchecked: JSON.stringify(json.unchecked),
          hash: window.location.search.replace('?data=', '')
        }

        AxiosUtils.post(obj, '/ajax', true)
          .then((response) => {
            if (response.data.status) {
              resolve(response.data.status)
            } else {
              reject(false)
            }
          })
          .catch(() => {
            reject(false)
          })
      })
    }
  },

  namespaced: true
}

export default UnsubscribePage
