import Vue from 'vue'
import Calendar from 'store/modules/Pages/CharterCargo/Form/Calendar'
import Step1Model from 'models/Pax/CharterForm/Step1'
import { validationMixin } from 'vuelidate'
import moment from 'moment'

const step1 = new Step1Model()
const defaultState = Object.assign({}, step1, {
  loadingStatus: false,
  validationErrors: step1.errorMsg
})

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations () {
    return {
      state: step1.validations
    }
  }
})

const Step1 = {
  namespaced: true,
  modules: {
    calendar: Calendar
  },
  state: defaultState,
  mutations: {
    setOrigin (state, value) {
      state.origin = value
    },
    setDestin (state, value) {
      state.destin = value
    },
    setTripType (state, newType) {
      state.tripType = newType
    },
    setLoadingStatus (state, value) {
      state.loadingStatus = value
    },
  },
  actions: {
    validateOriginDestin () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state
        vState.$touch()

        return !vState.$invalid ? resolve() : reject()
      })
    },
    getLegs ({ state }) {
      return new Promise((resolve) => {
        resolve([{
          origin: state.origin.value,
          destin: state.destin.value
        }])
      })
    },
    clearStep ({ commit }) {
      commit('setTripType', 'oneway')
      commit('setOrigin', '')
      commit('setDestin', '')

      commit('calendar/updateCalendarReturnDate', '')
      commit('calendar/updateCalendarDepartureDate', new Date())

      validator.$v.state.$reset()
    },
    validateStep ({ dispatch, state }) {
      const rules = [
        dispatch('validateOriginDestin'),
        dispatch('calendar/validateDeparturePromise'),
      ]

      if (['return', 'multi'].includes(state.tripType)) {
        rules.push(dispatch('calendar/validateReturnPromise'))
      }

      return Promise.all(rules)
        .then(() => Promise.resolve(true))
        .catch((e) => Promise.reject(e))
    },
    getData ({ state, dispatch }) {
      return dispatch('getLegs')
        .then(legs => {
          legs.map((leg) => {
            leg.dateOfFlight = moment(state.calendar.departureDate).format('YYYY-MM-DD')
          })

          return Promise.resolve(legs)
        })
    }
  },
  getters: {
    $v () {
      return Object.assign ({}, validator.$v.state)
    },
    validationErrors (state) {
      return state.validationErrors
    }
  },
}

export default Step1
