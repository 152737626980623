<template>
  <div class="wrap-container storyblok">
    <div
      v-if="backdropVisible"
      class="backdrop"
      @click="closeBackdrop"
      @keyup.esc="closeBackdrop"
    />

    <div class="left-container">
      <button
        v-if="!isDesktop"
        class="button burger"
        :aria-label="$t('indexTabs.aria.menu')"
        @click="toggleMenu"
      >
        <img
          src="~assets/svg/burger.svg"
          alt="Menu"
        />
      </button>
      <logo />
    </div>
    <nav class="button-container">
      <div class="buttons">
        <template v-for="(section, index) in topNavLinks">
          <template v-if="showSection(section.component)">
            <component
              :is="section.component"
              :key="section.component + '_' + index"
              :section="section"
              @close-backdrop="closeBackdrop"
              @show-backdrop="openBackdrop"
            />
          </template>
        </template>
      </div>
      <HeaderProfile
        v-if="Object.values(profileSection).length"
        :section="profileSection"
        @close-backdrop="closeBackdrop"
        @show-backdrop="openBackdrop"
      />
    </nav>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import gtmHeader from 'services/AirBaltic/GTM/HeaderMenu'
  import ScrollUtils from 'utils/scrollUtils'

  import Logo from 'components/Storyblok/Header/Logo.vue'

  const HeaderSearch = () =>
    import(
      /* webpackChunkName: "storyblok-header-search" */ 'components/Storyblok/Header/Search'
    )
  const HeaderLink = () =>
    import(
      /* webpackChunkName: "storyblok-header-link" */ 'components/Storyblok/Header/Link'
    )

  const HeaderOffers = () =>
    import(
      /* webpackChunkName: "storyblok-header-offers" */ 'components/Storyblok/Header/Offers'
    )
  const HeaderI18n = () =>
    import(
      /* webpackChunkName: "storyblok-header-i18n" */ 'components/Storyblok/Header/I18n'
    )

  const HeaderProfile = () =>
    import(
      /* webpackChunkName: "storyblok-header-profile" */ 'components/Storyblok/Header/Profile'
    )

  export default {
    components: {
      Logo,
      HeaderSearch,
      HeaderLink,
      HeaderOffers,
      HeaderI18n,
      HeaderProfile
    },
    computed: {
      ...mapGetters('device', ['isDesktop']),
      ...mapState('header', [
        'offers',
        'topNavLinks',
        'profileSection',
        'backdropVisible'
      ])
    },
    methods: {
      showSection(component) {
        if (component === 'header-search') {
          return this.isDesktop
        }

        return true
      },
      closeBackdrop() {
        ScrollUtils.enableScroll()
        this.$store.commit('header/closeAllPreHeaderItems')
      },
      openBackdrop() {
        this.disableScroll()
        this.$store.commit('header/setBackdropVisible', true)
      },
      toggleMenu() {
        gtmHeader.headerCategoryClick('Burger icon', null, 'Burger icon')
        this.$emit('toggle-menu')
      },
      disableScroll() {
        if (!this.isDesktop) {
          ScrollUtils.disableScroll()
        }
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.64);
    z-index: 4;
  }

  .wrap-container {
    box-shadow: inset 0 -1px 0 0 $gray-divider;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: $desktop-lg;
    margin: 0 auto;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    gap: rem(4, 16);
    margin-right: 8px;

    @include bp-mf-mobile-large {
      gap: rem(8, 16);
    }

    @include bp-mf-tablet {
      gap: rem(16, 16);
    }

    @include bp-mf-desktop {
      gap: rem(20, 16);
      margin: 0;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    gap: rem(4, 16);

    @include bp-mf-mobile-large {
      gap: rem(8, 16);
    }

    @include bp-mf-tablet {
      gap: rem(16, 16);
    }
  }

  button {
    padding: 0;
  }

  .burger {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
  }

  .left-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(8, 16);
    margin-left: 8px;

    @include bp-mf-desktop {
      margin: 0;
    }
  }
</style>
