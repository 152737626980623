import Vue from 'vue'
import store from 'definitions/store'
import headerInit from 'initializations/HeaderWhite'
import loginFormInit from 'initializations/LoginForm'
import AbstractView from 'abstract'
import HeaderWhite from 'templates/HeaderWhite'
import DeviceMixin from 'containers/DeviceMixin'
import mainInit from 'initializations/Main'
import searchInit from 'initializations/pages/Search'
import CookieUtils from 'utils/CookieUtils'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId)
  }

  init() {
    mainInit.init('main')
    headerInit.init('header')
    searchInit.init('search')
    loginFormInit.init('loginForm')

    if (CookieUtils.getCookie('btMobileApp')) {
      return
    }

    new Vue({
      el: '#header',
      mixins: [DeviceMixin],
      store,
      render: (h) => h(HeaderWhite)
    })

    const HeaderGlobalAlert = () =>
      import(/* webpackChunkName: "header-global-alert" */ 'templates/GlobalAlert')
    new Vue({
      el: '#headerGlobalAlert',
      store,
      render: (h) => h(HeaderGlobalAlert)
    })

    const SubHeader = () =>
      import(/* webpackChunkName: "header-subheader" */ 'templates/SubHeader')
    new Vue({
      el: '#subHeader',
      store,
      render: (h) => h(SubHeader)
    })
  }
}
