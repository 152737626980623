<template>
  <section
    ref="eHorizonTabsWrapper"
    :class="['e-horizon-tabs-wrap']"
  >
    <index-tabs
      v-if="isMobile && !isABTestNewVersion"
      class="top"
      :type="['internal']"
    />

    <template v-for="tab in tabs">
      <alert
        v-if="isDesktop && showActiveErrorBlock(tab)"
        :key="`${tab}_error`"
        class="error-block"
        type="danger"
        :content="$t(activeTabErrors[0])"
      />

      <background
        v-if="activeTab === tab"
        :key="tab"
        :banners="activeTabBackground"
        :device-type="deviceType"
        :active-tab="activeTab"
        :class="{'new-version' : isABTestNewVersion}"
      >
        <template v-if="isDesktop || isABTestNewVersion">
          <component
            :is="activeTab"
            :rip="isRip"
            :rip-texts="activeTabTexts"
            :banners="getBanners(activeTab)"
          />
        </template>
      </background>

      <info-banner
        v-if="!isMobile && showInfoBanner(tab)"
        :key="`${tab}_banner`"
      />
    </template>

    <alert
      v-if="!isDesktop && showActiveErrorBlock(activeTab)"
      class="error-block"
      type="danger"
      :content="$t(activeTabErrors[0])"
    />

    <component
      :is="activeTab"
      v-if="!isDesktop && !isABTestNewVersion"
      :rip="isRip"
      :rip-texts="activeTabTexts"
      :banners="getBanners(activeTab)"
    />
    <info-banner
      v-if="isMobile && showInfoBanner(activeTab)"
      :key="`${activeTab}_banner`"
    />
  </section>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import get from 'lodash-es/get'
  import isUndefined from 'lodash-es/isUndefined'
  import IndexTabs from 'containers/eHorizon/IndexTabs/NewTabs'
  import Background from 'components/eHorizon/Background'
  import ABTestUtils from 'utils/ABTestUtils'

  export default {
    components: {
      IndexTabs,
      Background,
      Alert: () => import(/* webpackChunkName: "customer-self-service-alert" */ 'containers/Pages/CustomerSelfService/Alert'),
      FLT: () => import(/* webpackChunkName: "FLT" */ 'components/Forms/Index/FlightSearch'),
      MMB: () => import(/* webpackChunkName: "MMB" */ 'components/Forms/Index/MyBooking'),
      CKI: () => import(/* webpackChunkName: 'CKI' */ 'components/Forms/Index/Checkin'),
      HTL: () => import(/* webpackChunkName: 'HTL' */ 'components/Forms/Index/Hotel'),
      InfoBanner: () => import(/* webpackChunkName: 'info-banner' */ 'components/eHorizon/InfoBanner')
    },
    data () {
      return {
        tabs: ['FLT', 'HTL', 'MMB', 'CKI']
      }
    },
    computed: {
      ...mapState('device', [
        'deviceType'
      ]),
      ...mapGetters('device', [
        'isMobile',
        'isDesktop'
      ]),
      ...mapState('indexTabs', [
        'showOverlay',
        'wideBanners',
        'activeTab',
        'ripTabs',
        'ripTexts',
        'infoBanner'
      ]),
      ...mapGetters('mmbForm', {
        'MMBFormErrors': 'formErrors'
      }),
      ...mapGetters('checkinForm', {
        'CKIFormErrors': 'formErrors'
      }),
      ...mapGetters('eHorizonSearchForm', {
        'FLTFormErrors': 'formErrors'
      }),
      HTLFormErrors () {
        return {}
      },
      isABTestNewVersion() {
        return ABTestUtils.isNewVersionEnabled()
      },
      activeTabErrors () {
        return this[`${this.activeTab}FormErrors`]
      },
      showErrorBlock () {
        return this.activeTabErrors.length > 0
      },
      isRip () {
        return this.ripTabs.includes(this.activeTab)
      },
      activeTabTexts () {
        return get(this.ripTexts, this.activeTab, {})
      },
      activeTabBackground () {
        if (isUndefined(this.wideBanners[this.activeTab])) {
          return {
            images: {
              desktopImg: "",
              tabletImg: "",
              mobileImg: "",
            },
            title: ""
          }
        }

        return this.wideBanners[this.activeTab]
      }
    },
    watch: {
      activeTab: {
        handler: function () {
          if (this.isMobile) {
            this.scrollToElement()
          }
        }
      }
    },
    methods: {
      hideOverlay () {
        this.$store.commit('indexTabs/closeOverlay')
      },
      showActiveErrorBlock (tab) {
        return this.showErrorBlock && this.activeTab === tab
      },
      scrollToElement () {
        const element = this.$refs.eHorizonTabsWrapper.getBoundingClientRect()
        const currentScroll = document.body.scrollTop || document.documentElement.scrollTop || 0
        let offset = element.top
        if (this.isMobile) {
          offset = element.top - 105
        }

        window.scrollTo(0, currentScroll + offset)
      },
      showInfoBanner (tab) {
        return this.activeTab === 'FLT' && tab === 'FLT' && this.infoBanner
      },
      getBanners (tab) {
        if (isUndefined(this.wideBanners[tab])) {
          return {
             images: {
              desktopImg: "",
              tabletImg: "",
              mobileImg: "",
            },
            title: ""
          }
        }

        return this.wideBanners[tab]
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  .e-horizon-tabs-wrap {
    background-color: $gray-light-background;
    position: relative;
  }

  .tiles-wrap {
    margin: 0 -24px;

    @include bp-mf-tablet {
      margin: 40px 0 0;
      display: flex;
    }

    @include bp-mf-desktop {
      max-width: $desktop-wide-width;
      margin: 25px auto 0;
      justify-content: space-between;
    }

    @include bp-desktop-small {
      margin: 25px 26px 0;
    }
  }

  ::v-deep .new-version {
    @include bp-mf-desktop {
      z-index: 3;
    }

    .content-wrap,
    .glass-wrapper.active {
      background: transparent;
    }
  }
</style>
