import Api from 'services/AirBaltic/Api'
import ErrorHandler from 'utils/ErrorHandler'
import conf from 'conf'
import Destin from 'store/modules/eHorizon/SearchForm/Destin'
import Origin from 'store/modules/eHorizon/SearchForm/Origin'
import Main from 'store/modules/Main'
import AnyDest from 'store/modules/Pages/AnyDestination/AnyDestination'
import moment from 'moment'

const ERRORS = {
  GENERIC: 'generic.errors.serverError',
  VALIDATION_FAILURE: 'generic.errors.serverError',
  PINS_VALIDATION_FAILURE: 'pinsReward.pinsNumber.genericError'
}

class AnyDestination {
  anyDestSearch(data) {
    let dataVariables = data.origin + '/' + data.departureDate + '/' + data.returnDate
    return new Api()
      .get('/schedule/any/simple/' + dataVariables + conf.btUrlCors, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        return Promise.reject(this.returnError(error))
      })
  }

  anyDestPrice(data) {
    let dataVariables =
      data.origin +
      '/' +
      data.destination +
      '/' +
      data.departureDate +
      '/' +
      data.returnDate
    return new Api()
      .get('/schedule/any/prices/' + dataVariables + conf.btUrlCors)
      .then((response) => {
        if (!Object.keys(response.data).length) {
          return Promise.reject(this.returnError([]))
        }
        return Promise.resolve(this.modifyCard(response.data))
      })
      .catch((error) => {
        return Promise.reject(this.returnError(error))
      })
  }

  modifyCard(card) {
    let key = Object.keys(card)[0]
    let value = Object.values(card)[0]
    let destinData = this.getDestinData(key)
    if(!destinData) return {}
    return {
      code: key,
      price: value,
      image: this.getCardImage(key),
      country: destinData.country,
      city: destinData.city,
      labels: destinData.labels,
      bookingHref: destinData.bookingHref
    }
  }

  getCardImage(code) {
    if (!code) return ''
    return `/destinations-single-2019/bottom-block-images/desktop/${code.toLowerCase()}.jpg`
  }

  getDestinData(code) {
    const destination = Destin.state.destinations.find((dest) => dest.code === code)

    if (destination) {
      return {
        country: destination.country,
        city: destination.city,
        labels: destination.labels ?? [],
        bookingHref: this.getCardDeepLink(code)
      }
    }
    return null
  }

  getCardDeepLink(code) {
    return (
      conf.newIbeUrl +
      '/' +
      Main.state.selectedLang +
      '/fb/availability' +
      '?p=bti' +
      '&originCode=' +
      Origin.state.value.code +
      '&originType=A' +
      '&destinCode=' +
      code +
      '&destinType=A' +
      '&departure=' +
      this.formatDate(AnyDest.modules.calendar.state.departureDate) +
      '&return=' +
      this.formatDate(AnyDest.modules.calendar.state.returnDate)
    )
  }

  formatDate(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  returnError(error) {
    if (error.response) {
      return this.getFieldErrors(error.response)
    } else {
      return {
        msg: this.getErrors(ERRORS.GENERIC),
        type: error.type ?? 'anyDestSearch'
      }
    }
  }

  getFieldErrors(error) {
    return {
      msg: this.getErrors(error),
      message: error.data.message,
      type: 'anyDestSearch'
    }
  }

  getErrors(response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default AnyDestination
