import ResetPasswordStore from 'store/modules/Pages/ProfilePages/ResetPassword'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, ResetPasswordStore)

      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    if (typeof pageData !== "undefined") {
      store.commit(`${name}/setPageTitle`, pageData.title)
      store.commit(`${name}/setSectionTitle`, pageData.sectionTitle)
      store.commit(`${name}/setSectionText`, pageData.sectionText)
      store.commit(`${name}/setErrorExpiredFirst`, pageData.errorExpiredFirst)
      store.commit(`${name}/setErrorExpiredSecond`, pageData.errorExpiredSecond)
      store.commit(`${name}/setResendLinkText`, pageData.resendLinkText)
      store.commit(`${name}/setErrorInvalid`, pageData.errorInvalid)
    }
    store.commit(`${name}/setLoginUrl`, loginUrl)
  }
}

export default Init
