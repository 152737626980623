import Api from 'services/AirBaltic/Api'
import Leg from 'models/GroupBooking/Leg'
import TimetableLeg from 'models/Timetable/Flight'
import moment from 'moment'
import cloneDeep from 'lodash-es/cloneDeep'
import ErrorHandler from 'utils/ErrorHandler'

const ERRORS = {}

export default class FlightsData {
  getFlights(data) {
    return new Api().post('/schedule/withConnections', data)
      .then((res) => Promise.resolve(this.parseFlights(res.data, true)))
      .catch(() => Promise.resolve([]))
  }

  getTimetableFlights(data) {
    return new Api().post('/schedule/timetable/simple', data)
      .then((res) => Promise.resolve(this.parseTimetableFlights(res.data, data.periodStart, data.periodEnd)))
      .catch(() => Promise.resolve([]))
  }

  searchByAirport (airport, direction) {
    const path = `/schedule/live/${direction}/${airport}`
    return new Api().get(path)
      .then((res) => Promise.resolve(this.parseFlights(res.data)))
      .catch(error => Promise.reject(this.getLiveErrors(error.response)))
  }

  searchByNumber (number) {
    const path = `/schedule/live/flightNumber/${number}`
    return new Api().get(path)
      .then((res) => Promise.resolve(this.parseFlights(res.data)))
      .catch(error => Promise.reject(this.getLiveErrors(error.response)))
  }

  getErrors (response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }

  getLiveErrors (response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response, true)
  }

  parseFlights (response, first = false) {
    const arr = []
    let key = null
    if (Object.values(response).length) {
      const dates = Object.keys(response);
      key = dates[0];

      dates.map((date) => {
        let flight = {}
        if (typeof response[`${date}`] === 'undefined') {
          arr[`${date}`] = []
          return
        }

        response[`${date}`].map((item) => {
          flight = new Leg(item.legs[0])

          if (item.legs.length > 1) {
            flight = this.addConnection(flight, item.legs[1])
          }

          if (typeof arr[`${date}`] === 'undefined') {
            arr[`${date}`] = []
          }
          arr[`${date}`].push(flight)
        })
      })
    }

    return first && key !== null ? arr[`${key}`] : arr
  }

  parseTimetableFlights (response, start, end) {
    const arr = []
    if (Object.values(response).length) {
      response.map((item) => {
        item.periodStart = start
        item.periodEnd = end
        const flight = new TimetableLeg(item)

        arr.push(flight)
      })
    }

    return arr
  }

  addConnection (flight, leg) {
    const second = new Leg(leg)
    const depart = moment.parseZone(second.depart)
    const arrival = moment.parseZone(flight.arrival)
    const connectionArrival = moment(second.arrival)
    const firstDeparture = moment(cloneDeep(flight.depart))
    const diff = depart.diff(arrival, 'minutes')
    const nextDay = !connectionArrival.isSame(firstDeparture, 'day')
    const changes = []

    flight.arrival = second.arrival
    flight.arrivalDate = second.arrivalDate
    flight.arrivalTime = second.arrivalTime
    flight.durationMinutes += Number(second.durationMinutes) + Number(diff)
    flight.dest = second.dest
    flight.flightNumber = [flight.flightNumber, second.flightNumber]
    flight.connectionAirport = [second.orig]
    flight.connectionTime = Number(diff)
    flight.duration = this.getTimeFromMins(flight.durationMinutes)
    flight.changes = changes

    flight.nextDay = nextDay

    return flight
  }

  getTimeFromMins (mins) {
    const h = Math.floor(mins / 60)
    const m = mins % 60
    const hC = h < 10 ? '0' + h : h
    const mC = m < 10 ? '0' + m : m

    return `${hC}:${mC}`
  }
}
