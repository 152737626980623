import { email, numeric, minLength, requiredIf } from 'vuelidate/lib/validators';
var Irregularity = /** @class */ (function () {
    function Irregularity() {
        var _this = this;
        this.id = 0;
        this.email = '';
        this.intCode = '';
        this.phone = '';
        this.phoneNumber = '';
        this.errorMsg = {
            email: {
                email: 'reusable.email.validation.invalid',
                required: 'reusable.email.validation.missing',
            },
            intCode: {
                required: 'reusable.intCodeWithPhone.validation.intCode.required'
            },
            phoneNumber: {
                numeric: 'reusable.intCodeWithPhone.validation.phoneNumber.notNumeric',
                required: 'reusable.intCodeWithPhone.validation.phoneNumber.required',
                minLength: 'reusable.intCodeWithPhone.validation.phoneNumber.minlength'
            }
        };
        this.validation = {
            contacts: {
                $each: {
                    email: {
                        required: requiredIf(function (value) { return _this.hasBeenStarted(value); }),
                        email: email
                    },
                    intCode: {
                        required: requiredIf(function (value) { return _this.hasBeenStarted(value) && _this.phoneNotFromApi(value); })
                    },
                    phoneNumber: {
                        required: requiredIf(function (value) { return _this.hasBeenStarted(value); }),
                        numeric: numeric,
                        minLength: minLength(5)
                    }
                }
            }
        };
    }
    Irregularity.prototype.phoneNotFromApi = function (value) {
        return value.phoneChanged || (value.phoneNumber && !value.phone);
    };
    Irregularity.prototype.hasBeenStarted = function (value) {
        return value.email || value.intCode || value.phoneNumber;
    };
    return Irregularity;
}());
export default Irregularity;
