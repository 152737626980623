import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import landingPageInit from 'initializations/pages/CampaignLandingDestinations'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
      landingPageInit.init("campaignLandingDestinations")

      const LandingPage = () => import(/* webpackChunkName: "campaign-landing-page" */ 'containers/Pages/CampaignLandingDestinations')
      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(LandingPage)
      })
    }
}
