import { maxLength, required } from 'vuelidate/lib/validators';
var OtherRequest = /** @class */ (function () {
    function OtherRequest() {
        this.form = {
            requestMessage: ''
        };
        this.messageMaxLength = 1500;
        this.errorMsg = {
            requestMessage: {
                allowedCharacters: 'forms.wheelchair.requestMessage.validation.allowedCharacters',
                maxLength: 'forms.wheelchair.requestMessage.validation.maxLength',
                required: 'reusable.validation.required'
            }
        };
        this.validation = {
            form: {
                requestMessage: {
                    maxLength: maxLength(this.messageMaxLength),
                    required: required
                }
            }
        };
    }
    return OtherRequest;
}());
export default OtherRequest;
