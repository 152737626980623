<template>
  <div class="footer-links">
    <div
      v-for="link in footerLinks"
      :key="link.key"
      class="footer-submenu-block"
    >
      <button
        v-if="!isDesktop"
        class="footer-submenu-title"
        :class="{ active: link.active }"
        :aria-label="`Open ${link.label} submenu`"
        @click="openMenu(link.key)"
      >
        {{ link.label }}
      </button>
      <a
        v-else
        class="footer-submenu-title"
        :href="link.href"
      >
        {{ link.label }}
      </a>

      <ul class="footer-submenu-list">
        <li
          v-for="item in link.childs"
          :key="item.key"
        >
          <a
            :class="{ external: item.linkTarget === '_blank' }"
            :href="item.href"
            :target="item.linkTarget"
          >
            {{ item.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import cloneDeep from 'lodash-es/cloneDeep'

  export default {
    name: 'FooterLinks',
    props: {
      footerLinks: {
        type: Array,
        required: true
      }
    },
    computed: {
      ...mapGetters('device', ['isDesktop'])
    },
    methods: {
      openMenu(key) {
        let newLinks = cloneDeep(this.footerLinks)

        newLinks = newLinks.map((link) => ({
          ...link,
          active: this.setActive(link, key)
        }))

        this.$store.commit('footer/setFooterItems', newLinks, { root: true })
      },
      setActive(link, key) {
        if (link.key === key) {
          return !link.active
        }

        return false
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .footer-links {
    display: flex;
    flex-direction: column;

    @include bp-mf-desktop {
      flex-direction: row;
      justify-content: flex-start;
      gap: 88px;
    }

    @include bp-mf-desktop-large {
      width: $desktop-lg;
    }
  }

  .footer-submenu-block {
    position: relative;
    border-bottom: 1px solid $blue-border;

    @include bp-mf-desktop {
      width: 25%;
      border: none;
    }

    @include bp-mf-desktop-large {
      width: 200px;
    }
  }

  .footer-submenu-title {
    display: block;
    line-height: rem(56, 16);
    font-size: rem(16, 16);
    background: none;
    font-weight: 400;
    text-align: left;
    padding: 0;
    color: $blue-dark;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      @include icon-font-thin;
      content: $icon-arrow-right-thin-medium;
      transform: rotate(90deg);
      position: absolute;
      top: 8px;
      right: 6px;
      font-size: rem(11, 16);
      line-height: rem(40, 16);
      text-align: center;
      opacity: 0.5;
    }

    @include bp-mf-desktop {
      color: $blue-dark;
      font-size: rem(16, 16);
      font-weight: 500;
      line-height: rem(24, 16);
      margin-bottom: 16px;

      &:after {
        content: '';
      }
    }

    &.active {
      &:after {
        transform: rotate(270deg);
        opacity: 1;
      }

      + .footer-submenu-list {
        display: flex;
      }
    }
  }

  .footer-submenu-list {
    display: none;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;

    li {
      list-style: none;
    }

    @include bp-mf-desktop {
      display: flex;
    }
  }

  ::v-deep a {
    color: $blue-dark;
    font-size: rem(16, 16);
    line-height: rem(24, 16);
    text-decoration: none;
    display: block;
    padding-left: 24px;

    @include bp-mf-desktop {
      padding-left: 0;
    }

    &:hover {
      text-decoration: underline;
    }

    &.external {
      &:after {
        content: $icon-external-link-facelift;
        @include icon-font-thin();
        vertical-align: top;
        margin-left: -4px;
        margin-top: 2px;
        font-size: rem(8, 16);
        line-height: normal;
        color: $grey;
      }
    }
  }
</style>
