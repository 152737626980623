import BestBuyBannersStore from 'store/modules/Pages/BestBuyBanners'
import store from 'definitions/store'
import BBBService from 'services/AirBaltic/BBB'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, BestBuyBannersStore)
    }

    if (typeof enableBBB !== 'undefined') {
      if (enableBBB) {
        Init.initBestBuy(name)
      }
    }
  },

  initBestBuy(name) {
    if (typeof origDestLabels !== 'undefined') {
      store.commit(`${name}/setLabels`, origDestLabels)
    }

    const bbbService = new BBBService()
    bbbService.setPosList(store.state.bestBuyBanners.listName)
      .then((data) => {
        store.dispatch(`${name}/setBestBuyCountries`, data)
        let country = typeof pos !== 'undefined' && pos.length > 0 ? pos : store.state.main.selectedCountry
        if (country && !data.includes(country)) { country = 'ZZ'}

        bbbService.setSinglePos(country, store.state.bestBuyBanners.listName)
          .then((response) => store.commit(`${name}/setBestBuyBanners`, response))
          .catch(() => store.commit(`${name}/setBestBuyBanners`, []))
      })
      .catch(() => store.dispatch(`${name}/setBestBuyCountries`, []))
  }
}

export default Init
