import cloneDeep from 'lodash-es/cloneDeep'
import get from 'lodash-es/get'

class OrigDestData {
  static parseData(origDestData) {
    Object.values(origDestData)
      .map((origin) => {
        let isOrigin = false

        if (typeof origin.destinations === 'undefined' || Object.keys(origin.destinations).length === 0) {
          origin.isBtOrig = isOrigin

          return origin
        }

        Object.values(origin.destinations)
          .map((destin) => {
            const destination = cloneDeep(destin)
            const marks = get(destination, 'marks', [])

            destin.fastSearch = marks.includes('FAST_SEARCH')

            if (get(destin, 'labels', []).length > 0) {
              const isBTDestin = destin.labels.includes("label_bt_destination")
              const hasBTDirect = destin.labels.includes("label_direct_flights")

              if (!isOrigin) {
                isOrigin = isBTDestin
              }

              destin.isBTDest = isBTDestin
              destin.hasBTDirect = hasBTDirect
            }
          })

          origin.isBtOrig = isOrigin
      })

    return origDestData
  }
}

export default OrigDestData
