const TAB_TYPES = {
  depart: 'depart',
  return: 'return'
}

const defaultState = {
  activeTab: TAB_TYPES.depart,
  yearPrices: [],
  availableDates: [],
  departApiError: false,
  returnApiError: false,
  selectMonthFromDeeplink: false,
  showDepartPriceChangedPopover: false,
}

const Fsf = {
  state: defaultState,
  getters: {
    isReturnTab(state) {
      return state.activeTab === TAB_TYPES.return
    },
    isDepartTab(state) {
      return state.activeTab === TAB_TYPES.depart
    }
  },
  mutations: {
    setActiveTab(state, value) {
      state.activeTab = value
    },
    setYearPrices(state, value) {
      state.yearPrices = value
    },
    setAvailableDates(state, value) {
      state.availableDates = value
    },
    setDepartApiError(state, value) {
      state.departApiError = value
    },
    setReturnApiError(state, value) {
      state.returnApiError = value
    },
    setSelectMonthFromDeeplink(state, value) {
      state.selectMonthFromDeeplink = value
    },
    setShowDepartPriceChangedPopover(state, value) {
      state.showDepartPriceChangedPopover = value
    }
  },

  namespaced: true
}

export default Fsf
