<template>
  <div>
    <a
      v-for="(item, index) in socialItems"
      :key="item.name"
      :ref="item.ref"
      class="item"
      :class="[item.class, { middle: index !== 0 && index !== socialItems.length - 1 }]"
      href="#"
      :aria-label="item.aria"
      @click.prevent="item.action"
    >
      <span>{{ item.name }}</span>
    </a>
  </div>
</template>

<script>
  import PinsLoginForm from 'components/LoginForm/PinsLoginForm'

  export default {
    props: {
      openPins: {
        type: Boolean,
        required: false,
        default: false
      },
      page: {
        type: String,
        default: 'login_page'
      }
    },
    data() {
      return {
        socialItems: [
          {
            name: 'airBaltic Club',
            ref: '',
            class: 'pins',
            action: this.openPinsLoginModal,
            aria: `${this.$t('header.white.login.loginWithText')} airBaltic Club`
          }
        ]
      }
    },
    mounted() {

      if (this.openPins) {
        this.openPinsLoginModal()
      }
    },
    methods: {
      closeModal() {
        this.$store.dispatch('main/closeModal')
        this.$store.dispatch('loginForm/forgotPassword/resetForm')
      },
      pinsSuccess() {
        this.$emit('success')
      },
      openPinsLoginModal() {
        this.$store.dispatch('main/openModal')
        this.$store.commit('main/setModalContent', PinsLoginForm)
        this.$store.commit('main/setModalCloseFn', this.closeModal)
        this.$store.commit('main/setModalType', 'sidebar')
        this.$store.commit('main/setModalClass', 'modal-white-sidebar')
        this.$store.commit('main/setShowModalHeaderWhiteNav', true)
        this.$store.commit('main/setShowModalHeader', false)
        this.$store.commit('main/setShowCloseIcon', true)
        this.$store.commit('main/setModalProps', { type: 'login', page: this.page })
        this.$store.commit('main/setModalEvents', { success: this.pinsSuccess })
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';
  @import '~assets/styles/bootstrap-grid.scss';

  .item {
    align-items: center;
    background: white;
    border-radius: 4px;
    display: flex;
    height: 92px;
    justify-content: center;
    margin-bottom: 16px;
    text-decoration: none;
    width: 100%;

    @include bp-mf-desktop {
      color: $blue-dark;
      flex-direction: row-reverse;
      height: 56px;
      justify-content: space-between;
      margin-bottom: 12px;
      padding: 0 16px;
      width: 265px;
    }

    &.middle {
      @include bp-mf-tablet {
        margin-left: 24px;
        margin-right: 24px;
      }

      @include bp-mf-desktop {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &:visited {
      span {
        color: $blue-dark;
      }
    }

    &:hover,
    &:focus {
      border: 1px solid $grey-border;
    }

    &:focus {
      span {
        outline: 1px dotted $blue-hover;
      }
    }

    &::before {
      border-radius: 50%;
      display: block;
      height: 32px;
      width: 32px;
    }

    span {
      display: none;

      @include bp-mf-desktop {
        color: $blue-dark;
        display: block;
        font-size: rem(12, 16);
        letter-spacing: 0;
        line-height: 14px;
        text-align: left;
        text-decoration: none;
      }
    }

    &.gmail {
      &::before {
        @include icon-font-thin();

        background-color: $google-color;
        color: white;
        content: $icon-gmail;
        font-size: rem(16, 16);
        line-height: 32px;
        text-align: center;
      }
    }

    &.pins {
      &::before {
        background: url('~assets/images/logos/airBaltic-club-logo.svg') no-repeat;
        background-position: center !important;
        background-size: 76px 25px !important;
        border-radius: 0;
        content: '';
        width: 76px !important;
      }
    }
  }
</style>
