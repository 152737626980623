import GiftPageStore from 'store/modules/Pages/GiftPage'
import store from 'definitions/store'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule(name, GiftPageStore)

            Init.initDefaultValues(name)
        }
    },
    initDefaultValues(name) {
        if (typeof gclpInit !== "undefined") {
            store.commit(`${name}/setPageTitle`, gclpInit.title)
            store.commit(`${name}/setDescription`, gclpInit.description)
            store.commit(`${name}/setLeftButtonTitle`, gclpInit.leftButtonTitle)
            store.commit(`${name}/setLeftButtonSubTitle`, gclpInit.leftButtonSubTitle)
            store.commit(`${name}/setLeftButtonLink`, gclpInit.leftButtonLink)
            store.commit(`${name}/setLeftButtonTarget`, gclpInit.leftButtonTarget)
            store.commit(`${name}/setLeftButtonCaption`, gclpInit.leftButtonCaption)
            store.commit(`${name}/setRightButtonTitle`, gclpInit.rightButtonTitle)
            store.commit(`${name}/setRightButtonSubTitle`, gclpInit.rightButtonSubTitle)
            store.commit(`${name}/setRightButtonLink`, gclpInit.rightButtonLink)
            store.commit(`${name}/setRightButtonTarget`, gclpInit.rightButtonTarget)
            store.commit(`${name}/setRightButtonCaption`, gclpInit.rightButtonCaption)
            store.commit(`${name}/setDetailsTitle`, gclpInit.detailsTitle)
            store.commit(`${name}/setDetailsText`, gclpInit.detailsText)

        }
    }
}

export default Init
