import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, maxValue } from 'vuelidate/lib/validators'
import { decimal } from 'utils/validators'
import BankModel from 'models/Claims/Bank'

const bankModel = new BankModel()
const defaultState = {
  reimbursement: null,
  reimbursementTypes: {
    standardReimbursement: false,
    expensesReimbursement: false,
    skipValidation: true
  },
  totalAmount: '',
  paymentMethod: null,
  fields: bankModel.fields,
  formErrors: bankModel.errorMsg
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    let validations = {}

    validations = {
      reimbursement: {
        required
      },
      reimbursementTypes: {
        isTrueAtLeastOne: () => !this.state.reimbursement ? true :
          this.state.reimbursementTypes.expensesReimbursement ||
          this.state.reimbursementTypes.standardReimbursement
      },
      totalAmount: {
        required: requiredIf(() => this.state.reimbursement),
        decimal,
        maxValue: maxValue(100000)
      },
      paymentMethod: {
        required: requiredIf(() => this.state.reimbursement)
      },
      fields: {
        ...new BankModel(this.state.fields.currency).getValidations(
          this.state.paymentMethod
        )
      }
    }

    if (this.state.reimbursementTypes.skipValidation) {
      delete validations.reimbursementTypes
    }

    return {
      state: validations
    }
  }
})

const BankInfo = {
  state: defaultState,
  mutations: {
    setReimbursement(state, value) {
      state.reimbursement = value
    },

    setStandardReimbursement(state, value) {
      state.reimbursementTypes.standardReimbursement = value
    },

    setExpensesReimbursement(state, value) {
      state.reimbursementTypes.expensesReimbursement = value
    },

    setSkipReimbursementTypes(state, value) {
      state.reimbursementTypes.skipValidation = value !== 'flight'
    },

    setTotalAmount(state, value) {
      state.totalAmount = value
    },

    setPaymentMethod(state, value) {
      state.paymentMethod = value
      state.fields = new BankModel().getFields(value)
      validator.$v.state.fields.$reset()
    },

    setBankName(state, value) {
      state.fields.bankName = value
    },

    setAccHolderName(state, value) {
      state.fields.accHolderName = value
    },

    setAccNumber(state, value) {
      state.fields.accNumber = value
    },

    setSwift(state, value) {
      state.fields.swift = value
    },

    setBicSwift(state, value) {
      state.fields.bicSwift = value
    },

    setBikCode(state, value) {
      state.fields.bik = value
    },

    setInnCode(state, value) {
      state.fields.innCode = value
    },

    setKppCode(state, value) {
      state.fields.kppCode = value
    },

    setBankNameCorresponded(state, value) {
      state.fields.bankNameCorresponded = value
    },

    setBankSwiftCorresponded(state, value) {
      state.fields.bankSwiftCorresponded = value
    },

    setAccNumberCorresponded(state, value) {
      state.fields.accNumberCorresponded = value
    },

    setAddress(state, value) {
      state.fields.address = value
    },

    setRoutingNumber(state, value) {
      state.fields.routingNumber = value
    },

    setCurrency(state, value) {
      state.fields.currency = value
    },

    reset(state) {
      state.reimbursement = null
      state.reimbursementTypes.expensesReimbursement = false
      state.reimbursementTypes.standardReimbursement = false
      state.totalAmount = ''
      state.paymentMethod = null
      state.fields = bankModel.fields
    },
    resetReimbursement(state) {
      state.totalAmount = ''
      state.paymentMethod = null
      state.fields = bankModel.fields
      state.reimbursementTypes.expensesReimbursement = false
      state.reimbursementTypes.standardReimbursement = false
    }
  },

  actions: {
    touch() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('bank-info')
      })
    },

    clearForm({ commit }) {
      validator.$v.state.$reset()
      commit('reset')
    },

    clearReimbursement({ commit }) {
      validator.$v.state.$reset()
      commit('resetReimbursement')
    }
  },

  getters: {
    $bankInfo() {
      return Object.assign({}, validator.$v.state)
    },

    formErrorsBank(state) {
      return state.formErrors
    },

    bankInfoData(state) {
      const bankObj = {
        totalAmount: state.totalAmount.trim().replace(',', '.'),
        payWithVoucher: state.paymentMethod === 'voucher'
      }

      if (state.paymentMethod === 'bankTransferEU') {
        bankObj[state.paymentMethod] = {
          accountHolderName: state.fields.accHolderName,
          accountNumber: state.fields.accNumber,
        }
      }

      if (!['voucher', 'bankTransferEU'].includes(state.paymentMethod)) {
        const fields = {
          bank: state.fields.bankName,
          accountHolderName: state.fields.accHolderName,
          accountNumber: state.fields.accNumber,
          currency: state.fields.currency,
          correspondentBank: state.fields.bankNameCorresponded,
          correspondentSwift: state.fields.bankSwiftCorresponded,
          accountHolderFullAddress: state.fields.address
        }

        if (state.paymentMethod === 'bankTransfer') {
          fields.bicSwift = state.fields.bicSwift
        } else if (state.paymentMethod === 'bankTransferRussia') {
          fields.swift = state.fields.swift
          fields.bik = state.fields.bik
          fields.inn = state.fields.innCode
          fields.kpp = state.fields.kppCode
          fields.correspondentBank = state.fields.bankNameCorresponded
          fields.correspondentAccountNumber = state.fields.accNumberCorresponded
          fields.accountHolderFullAddress = state.fields.address
        } else if (state.paymentMethod === 'bankTransferUkraine') {
          fields.swift = state.fields.swift
        } else {
          fields.swift = state.fields.swift
          fields.routingNumber = state.fields.routingNumber
        }

        bankObj[state.paymentMethod] = fields
      }

      return bankObj
    }
  },

  namespaced: true
}

export default BankInfo
