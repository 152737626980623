const BusinessLounge = {
  state: {
    pageTitle: '',
    description: '',
    placeholderDesktop: '',
    placeholderMobile: '',
    termsAndConditions: {},
    data: [],
    tableRows: [],
    tableHeader: {},
    visibleItems: 4
  },
  mutations: {
    setPageTitle (state, value) {
      state.pageTitle = value
    },

    setDescription (state, value) {
      state.description = value
    },

    setPlaceholder (state, value) {
      state.placeholderDesktop = value
    },

    setPlaceholderMobile (state, value) {
      state.placeholderMobile = value
    },

    setTermsAndConditions (state, value) {
      state.termsAndConditions = value
    },

    setData (state, value) {
      state.data = value
    },

    setTableRows (state, value) {
      state.tableRows = value
    },

    setTableHeader (state, value) {
      state.tableHeader = value
    },

    setVisibleItems (state, value) {
      state.visibleItems = value
    }
  },

  actions: {
    setData ({ commit }, data) {
      commit('setData', data)
      commit('setTableRows', data)
    },

    filterData ({ commit, state }, word) {
      if (word === '') {
        commit('setTableRows', state.data)
      } else {
        word = word.toLowerCase()
        const array = state.data.filter((el) => {
          const cityContains = el.city && el.city.toLowerCase().includes(word)
          const nameContains = el.place && el.name.toLowerCase().includes(word)
          return cityContains || nameContains
        })
        commit('setTableRows', array)
      }
    }
  },
  namespaced: true
}

export default BusinessLounge
