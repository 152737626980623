import OriginStore from 'components/eHorizon/Destination/OriginStore'
import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { textInCurrentLang } from 'utils/validators'
import get from 'lodash-es/get'

const formErrors = {
  value: {
    city: {
      maxLength: 'pinsReward.errors.originDestin.maxLength',
      textInCurrentLang: 'pinsReward.errors.originDestin.notInCurrentLang',
      required: 'searchForm.originDestin.origin.errors.missingValue'
    }
  }
}

const defaultState = {
  value: {},
  destinations: [],
  selectedLabel: 'popular',
  selectedCategory: '',
  selectedSubCategory: '',
  homeCountry: null,
  typeaheadIgnoreFilter: true,
  hasErrors: false,
  filter: '',
  customInput: false,
  labels: {
    nearby: 'searchForm.originDestin.origin.labels.nearbyOrigins',
    popular: 'searchForm.originDestin.origin.labels.popularOrigins',
    all: 'searchForm.originDestin.origin.labels.allOrigins'
  },
  formErrors: formErrors
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations: {
    state: {
      value: {
        required,
        city: {
          maxLength: maxLength(50),
          textInCurrentLang,
          required
        }
      },
      filter: {
        textInCurrentLang
      }
    }
  }
})

const Origin = {
  state: defaultState,
  getters: {
    $v() {
      return Object.assign({}, validator.$v)
    },
    originLabels: (state) => {
      return state.homeCountry
        ? {
            nearby: Vue.i18n.translate(state.labels.nearby),
            all: Vue.i18n.translate(state.labels.all)
          }
        : {
            popular: Vue.i18n.translate(state.labels.popular),
            all: Vue.i18n.translate(state.labels.all)
          }
    }
  },
  modules: {
    typeahead: OriginStore()
  },
  mutations: {
    setTypeaheadIgnoreFilter(state, value) {
      state.typeaheadIgnoreFilter = value
    },
    setValue(state, value) {
      state.value = value
    },
    setDestinations(state, value) {
      state.destinations = value
    },
    setInvalid(state, status) {
      state.invalid = status
    },
    setSelectedLabel(state, label) {
      state.selectedLabel = label
    },
    setSelectedCategory (state, value) {
      state.selectedCategory = value
      state.selectedSubCategory = ''
      state.selectedLabel = 'all'
      if (value === 'nearby') {
        state.selectedLabel = 'nearby'
      }
    },
    setSelectedSubCategory (state, value) {
      state.selectedSubCategory = value
    },
    setHomeCountry(state, countryCode) {
      state.homeCountry = countryCode
    },
    setLabels(state, labels) {
      state.labels = labels
    },
    clearSelectedLabel(state) {
      state.selectedLabel = state.homeCountry ? 'nearby' : 'popular'
    },
    setFilter(state, value) {
      state.filter = value
    },
    setCustomInput(state, value) {
      state.customInput = value
    }
  },
  actions: {
    setSelectedLabel({ commit, state }, label) {
      if (state.selectedLabel === label) {
        commit('setSelectedLabel', 'all')
      } else {
        commit('setSelectedLabel', label)
      }
    },
    doFilter({ commit }, filter) {
      commit('typeahead/setFilter', filter)
      commit('setFilter', filter)
      commit('setTypeaheadIgnoreFilter', filter === '')
    },
    closeAll({ dispatch }) {
      dispatch('closeTypeahead')
    },
    closeTypeahead({ commit, dispatch }) {
      commit('typeahead/close')
      commit('clearSelectedLabel')
      dispatch('setFilterToCurrentValue')
    },
    onValueSelect({ commit, dispatch }, value) {
      commit('setValue', value)
      commit('typeahead/setValue', value)

      dispatch('setFilterToCurrentValue')
      dispatch('closeTypeahead')
      dispatch('validate')
    },
    setFilterToCurrentValue({ commit, state }) {
      const code = get(state, 'value.aptWithCode', '')

      commit('typeahead/setFilter', code)
      commit('setFilter', code)
      commit('setTypeaheadIgnoreFilter', true)
    },
    validate({ state, commit, dispatch }) {
      validator.$v.state.$touch()
      commit('setInvalid', !validator.$v.state.value.required)

      if (state.customInput) {
        dispatch('validateCustom')
      }
    },
    validatePromise({ dispatch }) {
      dispatch('validate')

      return new Promise((resolve, reject) => {
        !validator.$v.state.value.$invalid ? resolve(true) : reject('origin-value')
      })
    },
    validateCustom({ commit }) {
      let isInvalid =
        !validator.$v.state.value.city.required ||
        !validator.$v.state.value.city.maxLength ||
        !validator.$v.state.value.city.textInCurrentLang

      commit('setInvalid', isInvalid)
    },
    setHomeCountry({ commit }, countryCode) {
      commit('setHomeCountry', countryCode)
      commit('clearSelectedLabel')
    },
    reset({ commit }) {
      commit('setValue', {})
      commit('setInvalid', false)

      validator.$v.state.$reset()
    }
  },
  namespaced: true
}

export default Origin
