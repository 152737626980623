import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import GVO from 'models/Payments/GVO'
import GVOSection from 'models/Payments/Sections/Gvo'

const gvo = new GVO()
const formData = {
  formErrors: gvo.errorMsg,
  form: gvo
}
const defaultState = Object.assign({}, new GVOSection(), formData)
const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations () {
    return {
      state: gvo.validation
    }
  }
})

export default {
  namespaced: true,
  state: defaultState,
  actions: {
    validateGvo ({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('touchGvo')
          .then(() => resolve(true))
          .catch(() => reject('validateGvo'))
      })
    },
    touchGvo () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        !vState.$invalid ? resolve(true) :reject('touchGvo')
      })
    },
    clearGvo ({ commit }) {
      commit('clearData', new GVO())
      validator.$v.state.form.$reset()
    }
  },
  mutations: {
    clearData (state, value) {
      state.form = value
    },
    setGvo (state, value) {
      state.form.gvo = value
    },
    setTitle (state, value) {
      state.gvoTitle = value
    },
    setDescription (state, value) {
      state.gvoDescription = value
    }
  },
  getters: {
    $gvo () {
      return Object.assign({}, validator.$v.state.form)
    },
    gvoValidationFormErrors (state) {
      return state.formErrors
    }
  }
}
