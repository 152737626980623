<template>
  <header-white
    v-if="!storyblokEnabled"
    :class="{ 'modal-opened': modal.isModalOpened }"
  />
  <storyblok-header v-else :class="{ 'modal-opened': modal.isModalOpened }" />
</template>

<script>
  import { mapState } from 'vuex'
  import HeaderWhite from 'containers/Header/Header'
  import StoryblokHeader from 'containers/Header/Storyblok/Header'
  import conf from 'conf'

  export default {
    components: {
      HeaderWhite,
      StoryblokHeader
    },
    computed: {
      ...mapState('main', ['modal']),
      storyblokEnabled() {
        return conf.storyblokEnabled
      }
    }
  }
</script>
