import { storyblokInit, apiPlugin, renderRichText } from '@storyblok/js'
import conf from 'conf'
import moment from 'moment'

let StoryblokSingleton = null
const version = conf.storyblokVersion

const Storyblok = {
  init() {
    if (!conf.storyblokEnabled) return

    if (!StoryblokSingleton) {
      StoryblokSingleton = storyblokInit({
        accessToken: conf.storyblokToken,
        use: [apiPlugin],
        cache: {
          clear: 'auto',
          type: 'memory'
        }
      }).storyblokApi
    }

    return StoryblokSingleton
  },
  getLinkRel(target) {
    return target === '_blank' ? 'nofollow noopener noreferrer' : null
  },
  parseImage(src) {
    return src.replace('https://a.storyblok.com', 'https://assets.airbaltic.com')
  },
  getAppContent(content) {
    return {
      component: 'app',
      content: {
        preText: content.preText,
        title: content.title,
        description: renderRichText(content.description),
        showAppleStore: content.showAppleStore,
        appleStore: {
          alt: content.appleStore.alt,
          src: content.appleStore.filename
        },
        appleStoreHref: {
          href: content.appleStoreHref.url,
          target: content.appleStoreHref?.target,
          rel: this.getLinkRel(content.appleStoreHref?.target)
        },
        image: {
          alt: content.image.alt,
          src: content.image.filename
        },
        logo: {
          alt: content.logo.alt,
          src: content.logo.filename
        },
        showPlayStore: content.showPlayStore,
        playStore: {
          alt: content.playStore.alt,
          src: content.playStore.filename
        },
        playStoreHref: {
          href: content.playStoreHref.url,
          target: content.playStoreHref?.target,
          rel: this.getLinkRel(content?.playStoreHref?.target)
        },
        qrCode: {
          alt: content.qrCode.alt,
          src: content.qrCode.filename
        }
      }
    }
  },

  async mainNavigation(language) {
    const mainLinks = {
      tabs: [],
      sections: []
    }

    const { data, error } = await StoryblokSingleton.get(
      'cdn/stories/main-navigation/main-navigation',
      {
        version,
        language,
        resolve_links: 'story',
        resolve_assets: true,
        resolve_relations: [
          'main-navigation.tabs',
          'navigation-section.title',
          'navigation-section.blocks',
          'navigation-items.children',
          'navigation-items.link',
          'app.playStoreHref',
          'app.appleStoreHref'
        ]
      }
    )

    if (typeof error !== 'undefined') {
      return mainLinks
    }

    mainLinks.tabs = data.story.content.tabs.map((tab) => ({
      label: tab.content.text,
      key: tab.name
    }))

    mainLinks.sections = {
      items: this.getStoryContent(data.story.content.body, language)
    }

    return { mainLinks }
  },
  getStoryContent(body, language) {
    return body.map((section) => {
      let label = section?.title?.content?.text

      if (section?.customTitle) {
        label = section.customTitle
      }

      return {
        label,
        subHeader: this.getSubHeader(section, language)
      }
    })
  },
  getSubHeader(section) {
    let block = ''

    if (section?.blocks?.content?.component === 'blog') {
      block = {
        component: 'blog',
        content: []
      }
    }

    if (section?.blocks?.content?.component === 'app') {
      const content = section?.blocks?.content
      block = this.getAppContent(content)
    }

    const items = section.items.map((item) => {
      const content = item?.link?.content

      if (content?.isCard) {
        return {
          label: content.text,
          href: content.href.url,
          isCard: content.isCard,
          image: content.image,
          description: content.description
        }
      }

      return {
        heading: {
          label: content?.text,
          href: content?.href?.url
        },
        children: item?.children.map((child) => {
          const childContent = child?.content

          return {
            label: childContent?.text,
            href: childContent?.href?.url
          }
        })
      }
    })

    return {
      block,
      items
    }
  },
  async footerNavigation(language) {
    const footerLinks = []

    const { data: navLinks, error } = await StoryblokSingleton.get(
      'cdn/stories/main-navigation/footer-navigation',
      {
        version,
        language,
        resolve_links: 'story',
        resolve_assets: true,
        resolve_relations: ['navigation-items.link', 'navigation-section.title']
      }
    )

    if (typeof error !== 'undefined') {
      return footerLinks
    }

    const navLinksContent = navLinks?.story?.content

    navLinksContent?.items.forEach((section) => {
      let sectionTitle = section?.title?.content?.text
      let sectionHref = section?.title?.content?.href?.url

      if (section?.customTitle) {
        sectionTitle = section.customTitle
        sectionHref = null
      }

      footerLinks.push({
        label: sectionTitle,
        href: sectionHref,
        key: section._uid,
        childs: section.items.map((item) => {
          const content = item.link.content
          return {
            label: content.text,
            href: content.href.url,
            linkTarget: content.href.target,
            rel: this.getLinkRel(content.href.target),
            key: item.link.slug
          }
        })
      })
    })

    return footerLinks
  },
  async mainFooter(language, country) {
    const relations = [
      'payment.link',
      'footer.socials',
      'footer.payments',
      'footer.searchPageUrl',
      'footer.awards',
      'footer.contact',
      'footer.legal',
      'footer.copyright'
    ]

    const { data } = await StoryblokSingleton.get(
      'cdn/stories/main-navigation/main-footer',
      {
        version,
        language: language,
        resolve_links: 'story',
        resolve_assets: true,
        resolve_relations: relations.join(',')
      }
    )

    const story = data.story.content

    const social = story?.socials.map((item) => ({
      link: item.content.link.url,
      target: item.content.link.target,
      rel: this.getLinkRel(item.content.link.target),
      icon: item.content.icon.filename,
      name: item.name
    }))

    const footerSemantics = {
      searchPageUrl: story?.searchPageUrl?.content?.href?.url,
      logo: story.logo.filename,
      email: story.email,
      address: story.address,
      phone: story.phone
    }

    const footerAwards =
      story?.awards?.map((award) => {
        return {
          title: award.title,
          src: this.parseImage(award.image.filename),
          alt: award.image.alt
        }
      }) || []

    const contactUs = {
      href: story.contact.content.href.url,
      target: '_self',
      text: story.contact.content.text
    }

    const legalLinks = []

    story.legal.forEach((link) => {
      if (link?.content?.whitelistCountries.length > 0) {
        const found = link?.content?.whitelistCountries.filter((item) => item === country)

        if (found.length <= 0) {
          return
        }
      }

      legalLinks.push({
        _uid: link._uid,
        href: link.content.href.url,
        text: link.content.text
      })
    })

    const copyright = {
      href: story.copyright.content.href.url,
      target: '_self',
      text: story.copyright.content.text
    }

    const googlePrivacyPolicy = renderRichText(story.googlePrivacyPolicy)

    const paymentLogos = story.payments.map((item) => ({
      title: item.content.link.content.text,
      href: item.content.link.content.href.url,
      alt: item.content.icon.alt,
      src: item.content.icon.filename
    }))

    return {
      social,
      footerSemantics,
      footerAwards,
      contactUs,
      legalLinks,
      copyright,
      googlePrivacyPolicy,
      paymentLogos
    }
  },
  showDisclaimer(startDate, endDate) {
    return moment().isAfter(startDate) && moment().isBefore(endDate)
  },
  async globalSettings(language) {
    const relations = [
      'global-settings.createProfileLink',
      'global-settings.registerProfileLink',
      'global-settings.disclaimer',
      'global-settings.flightStatusLink',
      'global-settings.searchPageLink',
      'header-search.searchLink',
      'header-flight-status.link'
    ]
    const { data } = await StoryblokSingleton.get('cdn/stories/data/global-settings', {
      version,
      language: language,
      resolve_links: 'story',
      resolve_relations: relations.join(',')
    })

    const domain = conf.btTranslationsUrl.slice(0, -1)

    const registerPageUrl =
      domain + data.story.content?.registerProfileLink?.content?.href?.url || null
    const createProfileLink =
      domain + data.story.content?.createProfileLink?.content?.href?.url || null
    const formattedIsLoginDisabled = data.story.content.loginDisabled

    const ripTabs = data.story.content.rip

    const disclaimer = data.story.content?.disclaimer?.content
    let globalAlert = {}

    if (
      disclaimer &&
      this.showDisclaimer(data.story.content.startDate, data.story.content.endDate)
    ) {
      globalAlert = {
        isBold: disclaimer.bold,
        closable: disclaimer.closable,
        showDefaultIcon: disclaimer.showDefaultIcon,
        type: disclaimer.type,
        icon: null,
        content: renderRichText(disclaimer.content)
      }

      if (!disclaimer.showDefaultIcon) {
        globalAlert.icon = {
          alt: disclaimer?.icon?.alt,
          source: disclaimer?.icon.filename
        }
      }
    }

    const mainLinks = {
      searchForm: {},
      flightStatus: {}
    }

    const searchForm = data.story.content.searchPageLink

    mainLinks.searchForm = {
      title: searchForm.content.title,
      icon: {
        alt: searchForm.content.icon.alt,
        source: searchForm.content.icon.filename
      },
      link: searchForm.content?.searchLink?.content?.href?.url,
      placeholder: searchForm?.content?.placeholder
    }

    const flightStatus = data.story.content.flightStatusLink

    mainLinks.flightStatus = {
      label: flightStatus.content?.title,
      href: flightStatus.content?.link?.content?.href?.url,
      icon: {
        alt: flightStatus.content?.icon?.alt,
        source: flightStatus.content?.icon?.filename
      }
    }

    return {
      globalAlert,
      registerPageUrl,
      createProfileLink,
      formattedIsLoginDisabled,
      ripTabs,
      mainLinks
    }
  },
  async topHeader(language, store) {
    const relations = [
      'top-navigation-item.link',
      'top-navigation.buttons',
      'top-navigation.profile',
      'header-link.link',
      'header-search.searchLink',
      'header-offers.offersBlock',
      'header-profile.newProfileLinks',
      'header-profile.oldProfileLinks'
    ]
    const { data } = await StoryblokSingleton.get(
      'cdn/stories/main-navigation/navigation',
      {
        version,
        language: language,
        resolve_links: 'story',
        resolve_relations: relations.join(',')
      }
    )

    const content = data.story.content

    const logo = {
      background: content.logoBg,
      image: {
        alt: content.logo.alt,
        source: content.logo.filename
      }
    }

    const buttonCalls = []
    const navigation = content.buttons.map((buttonSection) => {
      const buttonContent = buttonSection.content

      switch (buttonContent.component) {
        case 'header-search':
          return {
            component: buttonContent.component,
            title: buttonContent.title,
            icon: {
              alt: buttonContent.icon.alt,
              source: buttonContent.icon.filename
            },
            link: buttonContent.searchLink.content.href.url,
            placeholder: buttonContent.placeholder
          }
        case 'header-link':
          return {
            component: buttonContent.component,
            title: buttonContent.title,
            icon: {
              alt: buttonContent.icon.alt,
              source: buttonContent.icon.filename
            },
            link: buttonContent.link.content.href.url
          }
        case 'header-i18n':
          return {
            component: buttonContent.component,
            title: buttonContent.title,
            icon: {
              alt: buttonContent.icon.alt,
              source: buttonContent.icon.filename
            }
          }
        case 'header-offers':
          let offers = []
          let path = buttonContent.offersBlock?.full_slug

          if (path) {
            if (!path.startsWith('main-navigation')) {
              path = path.slice(3, path.length)
            }

            buttonCalls.push(
              StoryblokSingleton.get(`cdn/stories/${path}`, {
                version,
                language,
                resolve_links: 'story',
                resolve_assets: true,
                resolve_relations: ['offers-block.offers', 'offers.link']
              })
            )
          }

          return {
            component: buttonContent.component,
            title: buttonContent.title,
            icon: {
              alt: buttonContent.icon.alt,
              source: buttonContent.icon.filename
            },
            total: buttonContent.offers?.length ?? 0,
            offers
          }
      }
    })

    Promise.all(buttonCalls).then((values) => {
      const offers = values[0]

      if (!offers) return

      const data = offers.data.story.content.offers.map((offer) => {
        const offerContent = offer.content

        if (offerContent) {
          return {
            key: offer.slug,
            label: offerContent.title,
            description: offerContent.description,
            href: offerContent.link?.content?.href?.url,
            linkTarget: offerContent.link?.content?.href?.target,
            rel: this.getLinkRel(offerContent.link?.content?.href?.target)
          }
        }
      })

      if (data.length === 0) return

      store.dispatch(`header/setOffers`, {
        offers: data,
        total: data.length
      })
    })

    const profileContent = content.profile.content
    const profileSection = {
      component: profileContent.component,
      title: profileContent.title,
      greeting: profileContent.greeting,
      icon: {
        alt: profileContent.icon.alt,
        source: profileContent.icon.filename
      },
      newProfileLinks: profileContent.newProfileLinks.map((link) => ({
        current: false,
        label: link.content.text,
        href: link.content.href.url
      })),
      oldProfileLinks: profileContent.oldProfileLinks.map((link) => ({
        current: false,
        label: link.content.text,
        href: link.content.href.url
      }))
    }

    return { logo, navigation, profileSection }
  },
  async headerCounter(language) {
    const { data } = await StoryblokSingleton.get(
      'cdn/stories/main-navigation/campaign-timer',
      {
        version,
        language,
        resolve_links: 'story',
        resolve_relations: ['campaign-counter.excludedFrom']
      }
    )

    if (!data) {
      return
    }

    const story = data.story.content

    if (!story) {
      const counter = {
        active: false
      }
      return { counter }
    }

    const excludedList = story.excludedFrom.map((link) => link.content.href.url)
    const isExcluded = excludedList.includes(window.location.pathname)
    const isClosed = moment().isAfter(story.expirationDateTime)

    if (isExcluded || isClosed) {
      const counter = {
        active: false
      }
      return { counter }
    }

    const counter = {
      active: true,
      type: story.type,
      title: story.title,
      date: story.expirationDateTime,
      excludedList: [],
      showAction: story.showAction,
      closable: story.closable,
      showIcon: story.showIcon,
      action: {
        href: story.action.url,
        text: story.actionText
      }
    }

    return { counter }
  }
}

export default Storyblok
