const AirbusPage = {
  state: {
    pageData: {
      pageTitle: '',
      pageSubtitle: '',
      mobileTopImg: '',
      desktopTopVideo: '',
      topLinkUrl: '',
      topLinkText: '',
      features: [],
      explore: {
        title: '',
        url: '',
        linkTitle: '',
        img: ''
      },
      videoTitle: '',
      videos: [],
      blog: {
        title: '',
        subtitle: '',
        linkTitle: '',
        linkUrl: '',
        articles: []
      }
    }
  },

  mutations: {
    setPageData (state, value) {
      state.pageData = value
    }
  },

  namespaced: true
}

export default AirbusPage
