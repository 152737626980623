import conf from 'conf'
import DateUtils from 'utils/DateUtils'
import Vue from 'vue'
import gtmFSF from 'services/AirBaltic/GTM/FSF'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Calendar from 'store/modules/eHorizon/SearchForm/Calendar'
import { isValidDate, isDateTodayOrInFuture } from 'utils/validators'

const formErrors = {
  selectedOrigin: {
    required: 'flightHotel.errors.emptyOrigin'
  },
  selectedDestination: {
    required: 'flightHotel.errors.emptyDestination'
  },
  calendar: {
    returnInputText: {
      required: 'flightHotel.errors.emptyReturnDate',
      isValidDate: 'searchForm.dateSelection.errors.invalid',
      isAfterFrom: 'searchForm.dateSelection.errors.wrongDateOrder'
    },
    departureInputText: {
      required: 'flightHotel.errors.emptyDepartureDate',
      isValidDate: 'InvalidDate dep',
      isDateTodayOrInFuture: 'date today or future'
    }
  },
  notEmptyChildAge: {
    isEmpty: 'flightHotel.errors.emptyChildAge'
  }
}

const defaultState = {
  originAirports: '',
  selectedOrigin: {},
  destinationAirports: '',
  availableDestinations: [],
  selectedDestination: '',
  selectedDestinationCountry: '',
  hotel: 2,
  totalAdults: 2,
  totalChildren: 0,
  notEmptyChildAge: true,
  returnInputText: '',
  departureInputText: '',
  activeGlass: false,
  formErrors: formErrors
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    return {
      state: {
        selectedOrigin: {
          required
        },
        selectedDestination: {
          required
        },
        calendar: {
          departureInputText: {
            isValidDate,
            isDateTodayOrInFuture,
            required
          },
          returnInputText: {
            isValidDate,
            isAfterFrom() {
              const date = DateUtils.getDateFromString(TripX.state.returnInputText)
              const date2 = DateUtils.getDateFromString(TripX.state.departureInputText)

              return date >= date2
            },
            required
          }
        },
        notEmptyChildAge: {
          isEmpty() {
            return TripX.state.notEmptyChildAge
          }
        }
      }
    }
  }
})

const TripX = {
  modules: {
    calendar: Calendar
  },
  state: defaultState,
  mutations: {
    setOriginAirports(state, value) {
      state.originAirports = value
    },
    setSelectedOrigin(state, value) {
      state.selectedOrigin = value
    },
    setDestinationAirports(state, value) {
      state.destinationAirports = value
    },
    setAvailableDestinations(state, value) {
      state.availableDestinations = value
    },
    setSelectedDestination(state, value) {
      state.selectedDestination = value
    },
    setSelectedDestinCountryName(state, value) {
      state.selectedDestinationCountry = value
    },
    setHotel(state, value) {
      state.hotel = value
    },
    setTotalAdults(state, value) {
      state.totalAdults = value
    },
    setTotalChildren(state, value) {
      state.totalChildren = value
    },
    setNotEmptyChildAge(state, value) {
      state.notEmptyChildAge = value
    },
    setReturnInputText(state, value) {
      state.returnInputText = value === '' ? '' : value
    },
    setDepartureInputText(state, value) {
      state.departureInputText = value === '' ? '' : value
    },
    setActiveGlass(state, value) {
      state.activeGlass = value
    }
  },

  actions: {
    touch() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject(vState)
      })
    },
    validate({ dispatch }) {
      const rules = [dispatch('touch')]

      return Promise.all(rules)
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((e) => {
          return Promise.reject(e)
        })
    },
    gtmTripX({ state }) {
      const formData = {
        country: state.selectedDestinationCountry,
        city: state.selectedDestination.name,
        adultCount: state.totalAdults,
        childCount: state.totalChildren,
        passengerCount: state.totalAdults + state.totalChildren,
        origin: state.selectedOrigin.code,
        destination: state.selectedDestination.code,
        departure: state.calendar.departureInputText,
        return: state.calendar.returnInputText
      }
      gtmFSF.flightHotelSearch(formData)
    }
  },

  getters: {
    getSearchUrl(state, getters, rootState) {
      const utm = `&utm_source=${
        window.location.hostname
      }/${rootState.main.selectedLang.toUpperCase()}&utm_medium=search-bar&utm_campaign=topmenu-hotel-flights`

      let selectedLangIsFi = rootState.main.selectedLang === 'fi' ? 'fi' : 'eu'

      return (
        conf.tripXSearch +
        `/${selectedLangIsFi}` +
        `/search` +
        `?origins=${state.selectedOrigin.code}` +
        `&destinations=${state.selectedDestination.code}` +
        `&from=${DateUtils.formatDate(state.departureInputText)}` +
        `&to=${DateUtils.formatDate(state.returnInputText)}` +
        `&flexibleDates=false&rooms=${state.hotel}` +
        utm
      )
    },
    $v() {
      return Object.assign({}, validator.$v.state)
    }
  },

  namespaced: true
}

export default TripX
