import SelfServiceStore from 'store/modules/Pages/SelfService'
import store from 'definitions/store'
import OrigDest from 'utils/OrigDestData'
import Generic from 'models/SelfService/Generic'
import LocationService from 'services/AirBaltic/Location/Location'
import gtmMMB from 'services/AirBaltic/GTM/MMB'
import * as Sentry from '@sentry/vue'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, SelfServiceStore)

      Init.initOrigDestData(name)
      Init.initIntCountryCodes(name)
      Init.initDefaultValues(name)
      Init.initDefaultData(name)
    }
  },

  initDefaultValues(name) {
    if (typeof medicalInfo !== 'undefined') {
      store.commit(`${name}/setMedicalInfo`, medicalInfo)
    }

    if (typeof additionalInfo !== 'undefined') {
      store.commit(`${name}/setAdditionalInfo`, additionalInfo)
    }
  },

  initOrigDestData(name) {
    if (typeof origDestData !== 'undefined') {
      const data = OrigDest.parseData(origDestData)

      store.commit(`${name}/setOrigDestData`, data)
    }
  },

  initDefaultData(name) {
    if (typeof pageData !== 'undefined') {
      store.commit(`${name}/setPageData`, pageData)
    }

    if (typeof pnr !== 'undefined') {
      store.commit(`${name}/setPnr`, pnr)
    }

    if (typeof surname !== 'undefined') {
      store.commit(`${name}/setSurname`, surname)
    }

    if (typeof code !== 'undefined') {
      store.commit(`${name}/setCode`, code)
    }

    if (typeof ripTabs !== 'undefined') {
      store.commit(`${name}/setRipTabs`, ripTabs)
    }

    if (typeof indexUrl !== 'undefined') {
      store.commit(`${name}/setIndexUrl`, indexUrl)
    }

    if (typeof basket !== 'undefined') {
      try {
        const parsed = JSON.parse(basket)

        if (parsed) {
          gtmMMB.push(parsed)
        }
      } catch (error) {
        console.error('Error - ', error)
        console.error('Data - ', basket)
        Sentry.captureException(error, { extra: { data: basket } })
      }
    }

    store.commit(`${name}/addServiceMapping`, new Generic().services)
  },

  initIntCountryCodes(name) {
    if (typeof contentData_data_countries !== 'undefined') {
      store.dispatch(
        `${name}/irregularities/setIntCodeList`,
        contentData_data_countries._children,
        { root: true }
      )
    }
  }
}

export default Init
