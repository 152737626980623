<template>
  <section
    :class="['tabSection', {
      internal: showBlock('internal')
    }]"
  >
    <div class="tabs-wrap">
      <tab-pill
        v-for="tab in internal"
        :key="tab"
        :name="tab"
        :active="isActiveTab(tab)"
        @tab-click="onTabClicked"
      />
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import TabPill from 'components/eHorizon/Tabs/TabPill'
  import UrlParams from 'utils/UrlParams'

  export default {
    components: {
      TabPill
    },
    props: {
      type: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        internal: ['FLT', 'HTL', 'MMB', 'CKI'],
      }
    },
    computed: {
      ...mapState('indexTabs', [
        'activeTab',
        'bookingUrl',
        'carsUrl',
        'bookingUrlTarget',
        'carsUrlTarget',
        'ripTabs'
      ])
    },
    mounted() {
      this.getTabFromHash()
    },
    methods: {
      isActiveTab (name) {
        return name === this.activeTab
      },
      onTabClicked (tab) {
        this.$store.dispatch('indexTabs/switchTabs', tab)
      },
      getTabFromHash () {
        const tabs = ['FLT', 'MMB', 'CKI', 'HTL']
        let tab = UrlParams.getUrlParam(window.location.href, 'opentab')

        if (tab !== null) {
          tab = tab.toUpperCase()
        }

        if (tabs.includes(tab)) {
          setTimeout(() => {
            this.$store.commit('indexTabs/setActiveTab', tab)
          }, 1000)
        }
      },
      showBlock (type) {
        return this.type.includes(type)
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";
  // @import '~assets/styles/components/eHorizon/tabs.scss';

  .tabSection {
    position: absolute;
    top: 152px;
    z-index: 3;
    width: 100%;
    overflow-x: auto;
  }

  .tabs-wrap {
    display: flex;
    flex-direction: row;
    min-width: 600px;
    padding: 12px;
  }
</style>
