import moment from 'moment'

class DateUtils {
  static resetTime (date) {
    // this is to eliminate timezone effect and get the right day.
    // TODO: check if conflicts with other dates
    if (typeof (date) === 'number') {
      date = new Date(date)
    } else if (moment.isMoment(date)) {
      return new Date(date.startOf('day'))
    }
    return new Date(date.setHours(0, 0, 0, 0) + Math.abs(date.getTimezoneOffset() * 60000))
  }

  static getDateFromString (dateString) {
    const parts = dateString.split('.')
    return this.resetTime(new Date(parts[2], parts[1] - 1, parts[0]))
  }

  static formatDate = (date, expected='YYYY-MM-DD', receivedFormat='DD-MM-YYYY') => {
    return moment(date, receivedFormat).format(expected)
  }
}

export default DateUtils
