import Search from 'store/modules/Pages/LAD/Search'
import moment from 'moment'

const defaultState = {
  pageTitle: '',
  heroImages: [],
  errors: {
    departureTitle: '',
    arrivalTitle: '',
    flightNumberTitle: '',
    description: '',
    link: {
      href: '',
      title: '',
      target: ''
    }
  },
  currentTab: 'airport',
  services: {},
  statuses: {},
  statusMappings: {
    "ARR": "landed",
    "ON": "landed",
    "NEW": "scheduled",
    "SKD": "scheduled",
    "TIM": "scheduled",
    "EQT": "scheduled",
    "ETD": "scheduled",
    "RTR": "scheduled",
    "OUT": "onTheWay",
    "DEP": "onTheWay",
    "DEL": "canceled",
    "CNL": "canceled",
    "DIV": "diverted"
  },
  viewTimetable: {},
  airport: null,
  activeDateItem: 'today',
  activeDate: moment().format('YYYY-MM-DD'),
  flightData: []
}

const LAD = {
  modules: {
    search: Search
  },
  namespaced: true,
  state: defaultState,
  actions: {
    setPageData ({ commit, dispatch }, data) {
      commit('setPageTitle', data.pageTitle)
      commit('setHeroImages', data.heroImages)
      commit('setStatuses', data.statuses)
      commit('setViewTimetable', data.viewTimetable)
      dispatch('setErrorFields', data.errors)
      commit('setServices', data.services)
      commit('setServicesTitle', data.servicesTitle)
    },
    setErrorFields ({ commit }, data) {
      commit('setDepartureTitle', data.departureTitle)
      commit('setArrivalTitle', data.arrivalTitle)
      commit('setFlightNumberTitle', data.flightNumberTitle)
      commit('setErrorDescription', data.description)
      commit('setErrorLink', data.link)
    }
  },
  mutations: {
    setCurrentTab (state, value) {
      state.currentTab = value
    },
    setPageTitle (state, value) {
      state.pageTitle = value
    },
    setHeroImages (state, value) {
      state.heroImages = value
    },
    setDepartureTitle (state, value) {
      state.errors.departureTitle = value
    },
    setArrivalTitle (state, value) {
      state.errors.arrivalTitle = value
    },
    setFlightNumberTitle (state, value) {
      state.errors.flightNumberTitle = value
    },
    setErrorDescription (state, value) {
      state.errors.description = value
    },
    setServices (state, value) {
      state.services = value
    },
    setServicesTitle (state, value) {
      state.servicesTitle = value
    },
    setErrorLink (state, value) {
      state.errors.link = {
        href: value.href,
        title: value.text,
        target: value.target
      }
    },
    setFlightData (state, value) {
      state.flightData = value
    },
    setStatuses (state, value) {
      state.statuses = value
    },
    setViewTimetable (state, value) {
      state.viewTimetable = value
    },
    setAirport (state, value) {
      state.airport = value
    },
    activeDate (state, value) {
      state.activeDate = value
    },
    activeDateItem (state, value) {
      state.activeDateItem = value
    }
  }
}

export default LAD
