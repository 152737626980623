import DeviceStore from 'store/modules/Device/Device'

const Init = {
  init(store, name) {
    if (!store[name]) {
      store.registerModule(name, DeviceStore)
    }
  }
}

export default Init