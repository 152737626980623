import OwnWheelChairRequest from 'models/Forms/Assistance/Sections/OwnWheelChairRequest'
import FormValidationUtils from 'utils/FormValidation'

const requestOwnWheelChair = new OwnWheelChairRequest()
const formData = {
  formErrors: requestOwnWheelChair.errorMsg,
  form: requestOwnWheelChair.form
}
const defaultState = Object.assign({}, formData)
const validator = FormValidationUtils.initValidation(
  defaultState,
  requestOwnWheelChair.validation
)

const module = {
  namespaced: true,
  state: defaultState,
  actions: {
    validateRequest({ dispatch }) {
      const rules = [
        dispatch('touch')
      ]

      return Promise.all(rules)
        .then(() => Promise.resolve(true))
        .catch((e) => Promise.reject(e))
    },
    touch() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('validate wheelchair own request')
      })
    },
    clearRequest({ commit }) {
      validator.$v.state.form.$reset()
      commit('resetForm')
    }
  },
  mutations: {
    setOwnWheelChairRequest(state, value) {
      state.form.requestMessage = value
    },
    setGuidanceAtAirport(state, value) {
      state.form.guidanceAtAirport = value
    },
    setUseOwnWheelchair(state, value) {
      state.form.useOwnWheelchair = value
    },
    setWheelchairType(state, value) {
      state.form.wheelchairType = value
    },
    setCarryToPlane(state, value) {
      state.form.carryToPlane = value
      if (value === 'Yes') {
        state.form.carryToSeat = 'No'
      }
    },
    setCarryToSeat(state, value) {
      state.form.carryToSeat = value
      if (value === 'Yes') {
        state.form.carryToPlane = 'No'
      }
    },
    setWeight(state, value) {
      state.form.weight = value
    },
    setDimensions(state, value) {
      state.form.dimensions = value
    },
    setWidth(state, value) {
      state.form.width = value
    },
    setLength(state, value) {
      state.form.length = value
    },
    setHeight(state, value) {
      state.form.height = value
    },
    resetForm(state) {
      state.form = new OwnWheelChairRequest().form
    }
  },
  getters: {
    $request() {
      return Object.assign({}, validator.$v.state.form)
    },
    requestValidationFormErrors(state) {
      return state.formErrors
    }
  }
}

export default module
