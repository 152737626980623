import flattenDeep from 'lodash-es/flattenDeep'
import PaymentFormService from 'services/AirBaltic/Forms/Payment'
import PnrValidation from 'models/Payments/PnrValidation'
import PnrValidationSection from 'models/Payments/Sections/PnrValidationSection'
import FormValidationUtils from 'utils/FormValidation'
import FlightUtils from 'utils/FlightUtils'
const pnrValidation = new PnrValidation()

const formData = {
  form: pnrValidation,
  formErrors: pnrValidation.errorMsg,
  errors: pnrValidation.errors,
  bookingRef: null
}
const defaultState = Object.assign({}, new PnrValidationSection(), formData)
const validator = FormValidationUtils.initValidation(
  defaultState,
  pnrValidation.validation
)

export default {
  namespaced: true,
  state: defaultState,
  actions: {
    clearForm({ commit }) {
      commit('pnr', null)
      commit('surname', null)
      commit('claims/form/setPaxLastNameDisabled', false, { root: true })
    },

    validatePnrForm({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form

        dispatch('validateFields', 'all')
        commit('removeError', {
          name: 'generic',
          msg: 'claims.pnr.form.errors.generic.invalid'
        })

        if (!vState.$invalid) {
          dispatch('submitForm')
            .then((res) => {
              commit('removeErrors')
              return resolve(res)
            })
            .catch(() => reject('claims.pnr.form.errors.generic.invalid'))
        }
      })
    },
    submitForm({ state, dispatch, commit }) {
      return new Promise((resolve, reject) => {
        new PaymentFormService()
          .validatePnr(state.form.pnr, state.form.surname)
          .then((response) => {
            commit('setBookingRef', state.form.pnr)
            const isCharter = FlightUtils.isCharterFlight(response.data.segments)
            commit('setIsGroup', response.data.header.group || isCharter)
            dispatch('claims/form/getPaxData', response.data.passengers, { root: true })
            dispatch('claims/form/getFlightData', response.data.segments, { root: true })

            resolve(response)
          })
          .catch((e) => reject(e))
      })
    },

    validateFields({ dispatch }, field) {
      const vState = validator.$v.state.form

      if (field === 'pnr' || field === 'all') {
        vState.pnr.$touch()
        dispatch('checkField', {
          name: 'pnr',
          validator: 'required',
          key: 'missingValue'
        })
        dispatch('checkField', {
          name: 'pnr',
          validator: 'pnrOrTicketNumber',
          key: 'invalid'
        })
      }

      if (field === 'surname' || field === 'all') {
        vState.surname.$touch()
        dispatch('checkField', {
          name: 'surname',
          validator: 'required',
          key: 'missingValue'
        })
        dispatch('checkField', {
          name: 'surname',
          validator: 'alphaWithSpaces',
          key: 'onlyLatin'
        })
      }
    },

    checkField({ commit, state }, data) {
      if (data.name !== 'generic') {
        const obj = { name: data.name, msg: state.formErrors[data.name][data.validator] }

        if (!validator.$v.state.form[data.name][data.validator]) {
          commit('addError', obj)
        } else {
          commit('removeError', obj)
          commit('removeError', { name: 'generic' })
        }
      }
    }
  },
  mutations: {
    surname(state, value) {
      state.form.surname = value
    },
    removeErrors(state) {
      state.errors = new PnrValidation().errors
    },
    pnr(state, value) {
      state.form.pnr = value
    },
    setBookingRef(state, value) {
      state.bookingRef = value
    },
    setIsGroup(state, value) {
      state.isGroup = value
    },
    addError(state, error) {
      if (typeof state.errors[error.name] === 'undefined') {
        state.errors[error.name] = []
      }
      const found = state.errors[error.name].includes(error.msg)
      if (!found) {
        state.errors[error.name].push(error.msg)
      }
    },
    removeError(state, error) {
      if (error.name === 'generic') {
        state.errors.generic = []
      } else {
        const index = state.errors[error.name].indexOf(error.msg)
        if (index !== -1) {
          state.errors[error.name].splice(index, 1)
        }
      }
    },
    setBookingTitle(state, value) {
      state.bookingTitle = value
    },
    setBookingDescription(state, value) {
      state.bookingDescription = value
    },
    setInfoBoxDescription(state, value) {
      state.infoBoxDescription = value
    },
    setInfoBoxCTA(state, value) {
      state.infoBoxCTA = value
    }
  },
  getters: {
    $v() {
      return Object.assign({}, validator.$v.state.form)
    },
    validationFormErrors(state) {
      let filtered = state.errors
      if (typeof filtered !== 'undefined') {
        const values = Object.values(filtered)
        filtered = values.filter((n) => n)
      }
      return flattenDeep(filtered)
    }
  }
}
