import { required } from 'vuelidate/lib/validators';
var GVO = /** @class */ (function () {
    function GVO() {
        this.gvo = '';
        this.translationVariables = {};
        this.errorMsg = {
            gvo: {
                required: 'payments.sections.gvoSection.gvo.validation.required',
            },
        };
        this.validation = {
            form: {
                gvo: {
                    required: required,
                },
            },
        };
    }
    return GVO;
}());
export default GVO;
