import Request from 'services/Request'
import conf from 'conf'
import CookieUtils from 'utils/CookieUtils'
import axios from 'axios'
import isUndefined from 'lodash-es/isUndefined'
import isNull from 'lodash-es/isNull'
import get from 'lodash-es/get'
import * as Sentry from '@sentry/vue'

export default class Api extends Request {
  constructor(isLive = false) {
    super()
    this.isLive = isLive
  }

  getBaseUrl() {
    if (this.isLive) {
      return conf.btApiLiveUrl
    }

    return conf.btApiUrl
  }

  createRequest(customHeaders) {
    const REQUEST_TIMEOUT = customHeaders?.timeout ?? 60000
    const cookie = CookieUtils.getCookie(conf.jwtCookieName)

    const headers = {
      ...customHeaders,
      Accept: 'application/json'
    }

    if (cookie !== '') {
      headers.Authorization = `Bearer ${cookie}`
    }

    const request = axios.create({
      timeout: REQUEST_TIMEOUT,
      withCredentials: true,
      responseType: 'json',
      responseEncoding: 'utf8',
      baseURL: this.getBaseUrl(),
      headers: headers
    })

    request.interceptors.response.use(
      (response) => response,
      (error) => {
        if (get(error, 'code') === 'ECONNABORTED') {
          return Promise.reject({
            code: error.code,
            msg: 'generic.errors.requestTimedOut'
          })
        } else if (!isNull(error) && !isUndefined(error.response)) {
          return Promise.reject(error)
        } else {
          Sentry.captureException(error)
        }
      }
    )

    return request
  }
}
