import AbstractView from 'abstract'
import Vue from 'vue'
import store from 'definitions/store'
import FooterWide from 'containers/Footer/FooterWideFacelift.vue'
import StoryblokFooterWide from 'containers/Footer/StoryblokFooterWide.vue'
import DeviceMixin from 'containers/DeviceMixin'
import FooterInit from 'initializations/FooterWide'
import conf from 'conf'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId)
  }

  init() {
    let component = FooterWide

    if (conf.storyblokEnabled) {
      component = StoryblokFooterWide
    }
    FooterInit.init('footer')

    new Vue({
      mixins: [DeviceMixin],
      store,
      ...component
    }).$mount(`#${this.elemId}`)
  }
}
