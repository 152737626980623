var FlightDetailsSection = /** @class */ (function () {
    function FlightDetailsSection(obj) {
        if (obj === void 0) { obj = {}; }
        this.date = new Date();
        this.flightDetailsTitle = '';
        this.flightDetailsDescription = '';
        this.flightDay = this.date.getDay();
        this.flightMonth = this.date.getMonth();
        this.flightYear = this.date.getFullYear();
        Object.assign(this, obj);
    }
    return FlightDetailsSection;
}());
export default FlightDetailsSection;
