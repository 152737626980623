import * as Sentry from '@sentry/browser'

export default (error) => {
  const codes = [400, 413, 500]

  if (!error.response || !codes.includes(error.response.status)) {
    return
  }

  Sentry.withScope((scope) => {
    const response = error.response.data

    scope.setExtra('url', error.response.config.url)

    if (response !== null && typeof response !== 'undefined') {
      Object.keys(response).forEach(key => {
        scope.setExtra(key, response[`${key}`])
      })
    }

    Sentry.captureException(new Error(`${error.response.status} Error`))
  })
}
