var defaultRegexes = {
    alphaNumberSpaceDash: /^[A-Za-z 0-9-]*$/,
    alphaNumberSpaceDashCommaDot: /^[A-Za-z 0-9-,.]*$/,
    alphaNumberSpaceDashCommaDotSlash: /^[A-Za-z 0-9-\/,.]*$/,
    flightNumber: /^[A-Z]{1}[A-Z0-9]{1}[0-9]{1,4}$/,
    btFlightNumber: /(?!^BT0$)(^[BT]{2}[0-9]{1,4}$)/,
    baggageNumber: /^[A-Za-z]{3}[Bb]{1}[Tt]{1}[0-9]{5}$/,
    dimensions: /^[0-9]{1,5}x[0-9]{1,5}x[0-9]{1,5}$/,
    validPassword: /^(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[0-9]{1,})(?=.*[~!@#$%^&*()\-_=+{};:,<.>]{1,}).{8,}$/,
    anyBaggageNumber: /^[A-Za-z]{3}[A-Z]{1}[A-Z0-9]{1}[0-9]{5}$/
};
var regex = function (name) { return function (value) {
    var fieldRegex = defaultRegexes[name];
    return fieldRegex && value ? fieldRegex.test(value) : true;
}; };
export default regex;
