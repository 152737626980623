import UnsubscribeStore from 'store/modules/Pages/Unsubscribe'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, UnsubscribeStore)

      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    if (typeof pageData !== "undefined") {
      store.commit(`${name}/setPageData`, pageData)
    }
  }
}

export default Init
