import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import assistanceInit from 'initializations/pages/Assistance'
import SidebarInit from 'initializations/Sidebar'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    SidebarInit.init("sidebar")
    assistanceInit.init("assistance")

    const Assistance = () => import(/* webpackChunkName: "assistance" */ 'containers/Pages/Assistance/Assistance')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(Assistance)
    })
  }
}
