<template>
  <div class="bbb-block">
    <div class="bbb-heading">
      <span class="bbb-heading-title">{{ $t('bestBuyBanners.title') }}</span>
      <dropdown
        v-if="!isMobile && bestBuyCountries"
        class="countries"
        :translation-prefix="'countries.'"
        :items="bestBuyCountries"
        :selected-item="getSelectedCountry"
        :item-class="'flag-'"
        @select="selectCountry"
      />

      <div
        v-else
        class="dropdown-field"
        @click="openModal"
      >
        {{ $t('countries.' + getSelectedCountry) }}
      </div>
    </div>

    <div class="bbb-wrap">
      <div class="bbb-slider">
        <carousel
          ref="slider"
          class="bbb-slider-carousel"
          :navigation-enabled="getChunkedItems().length > 1"
          :navigation-click-target-size="0"
          :navigation-next-label="''"
          :navigation-prev-label="''"
          :loop="true"
          :per-page="1"
          @pageChange="onSlideChange"
        >
          <slide
            v-for="(banner, index) in getChunkedItems()"
            :key="banner.key"
          >
            <best-buy-banner-item
              :data="banner"
              :index="index"
              :page="currentPage"
            />
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
  import { Carousel, Slide } from 'vue-carousel'
  import { mapState, mapGetters } from 'vuex'
  import Dropdown from 'components/Dropdown/DropdownWhite'
  import BestBuyBannerItem from 'components/BestBuyBanners/BestBuyBannerItem'
  import gtmBestBuyBanners from 'services/AirBaltic/GTM/BestBuyBanners'
  import chunk from 'lodash-es/chunk'
  import BBBService from 'services/AirBaltic/BBB'

  export default {
    components: {
      Carousel,
      Slide,
      BestBuyBannerItem,
      Dropdown
    },
    props: {
      bestBuyBanners: {
        type: Array,
        default: () => []
      },
      bestBuyCountries: {
        type: Array,
        default: () => []
      }
    },

    data () {
      return {
        country: '',
        currentPage: 0,
        cachedPrices: []
      }
    },

    computed: {
      getSelectedCountry () {
        return this.bestBuyCountries.find(element => element.code === this.country) ? this.country : 'ZZ'
      },
      ...mapState('bestBuyBanners', [
        'listName'
      ]),
      ...mapState('main', [
        'selectedCountry',
        'selectedLang'
      ]),
      ...mapGetters('device', [
        'isMobile'
      ])
    },

    created () {
      this.country = this.selectedCountry
    },

    methods: {
      getChunkedItems () {
        return chunk(this.bestBuyBanners, 3)
      },

      selectCountry (selected) {
        this.country = selected
        this.closeModal()

        new BBBService().setSinglePos(selected, this.listName)
          .then((response) => {
            this.$store.commit(`index/bestBuyBanners/setBestBuyBanners`, response)

            this.$nextTick(() => {
              this.onSlideChange(0)
            })
          })
          .catch(() => this.$store.commit(`index/bestBuyBanners/setBestBuyBanners`, []))
          .finally(() => this.$refs.slider.goToPage(0))
      },

      closeModal () {
        this.$store.dispatch('main/closeModal')
      },

      onSlideChange (page) {
        this.currentPage = page
        gtmBestBuyBanners.pageChange(page, this.getSelectedCountry)
        this.goThroughCurrentFlightsAndCallPriceCheck()
      },

      goThroughCurrentFlightsAndCallPriceCheck () {
        const flights = this.getChunkedItems()

        if (flights.length > 0 && typeof flights[this.currentPage] !== 'undefined') {
          flights[this.currentPage].forEach((flight) => {
            this.checkAndUpdateOrigDestPriceCache(flight.orig, flight.dest)
          })
        }
      },

      checkAndUpdateOrigDestPriceCache (orig, dest) {
        const id = `${orig}${dest}`

        if (!this.cachedPrices[`${id}`]) {
          BBBService.getPrices(orig, dest)
          this.cachedPrices[`${id}`] = true
        }
      },

      openModal () {
        const props = {
          translationPrefix: 'countries.',
          items: this.bestBuyCountries,
          selectedItem: this.getSelectedCountry,
          forceOpen: true,
          forceSelectFn: this.selectCountry
        }

        this.$store.dispatch('main/openModal')
        this.$store.commit('main/setModalTitle', this.$t('bestBuyBanners.modalTitle'))
        this.$store.commit('main/setModalProps', props)
        this.$store.commit('main/setModalContent', Dropdown)
        this.$store.commit('main/setModalClass', 'without-body-margin')
        this.$store.commit('main/setModalCloseFn', this.closeModal)
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";
  @import "~assets/styles/components/carousel-navigation-common.scss";

  .bbb-block {

    @include bp-mf-tablet {
      position: relative;
      padding-bottom: 40px;
      height: 100%;
    }

    .bbb-slider {
      overflow: hidden;
    }

    /deep/ {

      .VueCarousel {

        @include bp-mf-tablet {
          position: inherit;
        }
      }

      .VueCarousel-pagination {
        margin: 22px 0 0;

        @include bp-mf-tablet {
          position: absolute;
          left: 0;
          bottom: 7px;
          margin: 0;
        }
      }

      .VueCarousel-navigation {
        z-index: 1;


        .VueCarousel-navigation-button {

          @include bp-mf-tablet {
            bottom: -1px;
          }
        }
      }
    }
  }

  .bbb-heading {
    margin-bottom: 5px;

    @include bp-mf-tablet {
      margin-bottom: 16px;
    }

    .bbb-heading-title {
      font-size: em(20);
      font-weight: 500;
      line-height: 24px;
    }

    /deep/ .dropdown-wrap {
      position: relative;
      display: inline-block;

      .dropdown-list li a {
        display: flex;
        align-items: center;
      }
    }

    /deep/ .dropdown-field {
      margin-top: 10px;
      border: 1px solid $formInputBorder;
      border-radius: 4px;
      font-weight: 500;
      text-align: center;
      line-height: 48px;
      color: $blue-medium;
      cursor: pointer;

      @include bp-mf-tablet {
        display: inline-block;
        margin: 0 0 0 3px;
        padding-right: 18px;
        border: none;
        border-radius: 0;
        font-size: em(20);
        line-height: 24px;
        text-align: left;
      }

      &:hover {
        color: $blue-light;

        &:after {
          color: $blue-light;
        }
      }

      &:before {
        content: none !important;
      }

      &:after {
        content: $icon-arrow-right-thin-medium;
        @include icon-font-thin();
        margin-left: 8px;
        transform: rotate(90deg);
        font-size: 12px;
        line-height: 1;
        font-weight: 700;
        color: $blue-medium;

        @include bp-mf-tablet {
          line-height: inherit;
          font-size: 14px;
        }
      }
    }

    /deep/ .dropdown-list {
      z-index: 2;
      position: absolute;
      top: 35px;
      width: 256px;
      max-height: 370px;
      background: #ffffff;
      box-shadow: 0 0 10px hexToRGBA(#000000, 0.26);

      li {

        &:hover,
        &.selected {
          background: #f5f7cf;
        }

        &.selected {
          position: relative;

          a {
            padding-right: 50px;
          }

          &:after {
            content: $icon-checkmark;
            @include icon-font();
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            font-size: em(12);
            line-height: 45px;
            text-align: center;
            color: $green-base;
          }
        }
      }

      a {
        padding-left: 57px;
        height: 45px;
        border-bottom: 1px solid #ececec;
        font-size: em(14);
        line-height: 16px;
        color: $grey-light;

        &:before {
          position: absolute;
          left: 16px;
          height: 18px;
        }
      }
    }
  }
</style>
