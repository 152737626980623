export default class AbstractView {
    constructor(domElementId) {
        this.elemId = domElementId;
    }

    init() {
    }

    destroy() {
    }
}