import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import SingleDestinationInit from 'initializations/pages/SingleDestination'
import eHorizonSearchFormInit from 'initializations/eHorizon/SearchForm'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
      eHorizonSearchFormInit.init("eHorizonSearchForm")
      SingleDestinationInit.init("singleDestination")

      const SingleDestination = () => import(/* webpackChunkName: "single-destination-page" */ 'containers/Pages/SingleDestination')

      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(SingleDestination)
      })
    }
}
