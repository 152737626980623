import FormValidationUtils from 'utils/FormValidation'
import { required } from 'vuelidate/lib/validators'
import { isTrue } from 'utils/validators'

const defaultState = {
  pageTitle: '',
  pageDescription: '',
  serviceTitle: '',
  serviceDescription: '',
  list: [],
  errors: '',
  oID: '',
  data: '',
  code: '',
  note: '',
  paymentCurrency: 'EUR',
  lookupId: '',
  expiredLink: {
    href: '',
    target: '',
    text: ''
  },
  success: {
    title: '',
    description: ''
  },
  canceled: {
    title: '',
    description: ''
  },
  cancel: {
    title: '',
    description: '',
    payLink: {
      href: '',
      target: '',
      text: ''
    },
    cancelledLink: {
      href: '',
      target: '',
      text: ''
    }
  },
  failedLink: {
    href: '',
    target: '',
    text: ''
  },
  failed: {
    title: '',
    description: ''
  },
  softCancelled: {
    title: '',
    description: '',
    link: {
      href: '',
      target: '',
      text: ''
    }
  },
  acceptTitle: '',
  acceptDescription: '',
  acceptError: '',
  terms: false
}

const validator = FormValidationUtils.initValidation(defaultState, {
  terms: { required, isTrue }
})

const EvePayments = {
  state: defaultState,
  actions: {
    setEvePaymentsPage({ commit }, data) {
      commit('setPageTitle', data.pageTitle)
      commit('setPageDescription', data.pageDescription)
      commit('setServiceTitle', data.serviceTitle)
      commit('setServiceDescription', data.serviceDescription)
      commit('setList', data.list)
      commit('setNote', data.note)
      commit('setErrors', data.errors)
      commit('setId', data.pid)
      commit('setData', data.data)
      commit('setCode', data.code)
      commit('setCancelTexts', data.cancel)
      commit('setExpiredLink', data.expiredLink)
      commit('setAcceptTitle', data.acceptTitle)
      commit('setAcceptDescription', data.acceptDescription)
      commit('setAcceptError', data.acceptError)
    },
    setEveSuccessPage({ commit }, data) {
      commit('setSuccessTexts', data)
    },
    setEveFailedPage({ commit }, data) {
      commit('setFailedTitle', data.title)
      commit('setFailedDescription', data.description)
      commit('setFailedLink', data.failedLink)
      commit('setId', data.pid)
      commit('setErrors', data.errors)
    },
    setEveCanceledPage({ commit }, data) {
      commit('setCanceledTexts', data)
      commit('setSoftCancel', data.softCancelled)
    },
    setEveCancelPage({ commit }, data) {
      commit('setCancelTexts', data)
      commit('setId', data.pid)
      commit('setErrors', data.errors)
      commit('setPayLink', data.payLink)
      commit('setCancelledLink', data.cancelledLink)
      commit('setCode', data.code)
      commit('setExpiredLink', data.expiredLink)
    }
  },
  mutations: {
    setSoftCancel(state, value) {
      state.softCancelled = value
    },
    setPayLink(state, value) {
      state.payLink = value
    },
    setCancelledLink(state, value) {
      state.cancelledLink = value
    },
    setExpiredLink(state, value) {
      state.expiredLink = value
    },
    setSuccessTexts(state, value) {
      state.success = value
    },
    setFailedTitle(state, value) {
      state.failed.title = value
    },
    setFailedDescription(state, value) {
      state.failed.description = value
    },
    setFailedLink(state, value) {
      state.failedLink = value
    },
    setCancelTexts(state, value) {
      state.cancel = value
    },
    setCanceledTexts(state, value) {
      state.canceled = value
    },
    setPageTitle(state, value) {
      state.pageTitle = value
    },
    setPageDescription(state, value) {
      state.pageDescription = value
    },
    setServiceTitle(state, value) {
      state.serviceTitle = value
    },
    setServiceDescription(state, value) {
      state.serviceDescription = value
    },
    setList(state, value) {
      state.list = value
    },
    setNote(state, value) {
      state.note = value
    },
    setErrors(state, value) {
      state.errors = value
    },
    setId(state, value) {
      state.oID = value
    },
    setData(state, value) {
      state.data = value
    },
    setCode(state, value) {
      state.code = value
    },
    setPaymentCurrency(state, value) {
      state.paymentCurrency = value
    },
    setLookupId(state, value) {
      state.lookupId = value
    },
    setAcceptTitle(state, value) {
      state.acceptTitle = value
    },
    setAcceptDescription(state, value) {
      state.acceptDescription = value
    },
    setTerms(state, value) {
      state.terms = value
    },
    setAcceptError(state, value) {
      state.acceptError = value
    }
  },
  getters: {
    $v() {
      return Object.assign({}, validator.$v.state)
    }
  },

  namespaced: true
}

export default EvePayments
