import AbstractView from 'abstract';
import Vue from 'vue'
import PressReleasesInit from 'initializations/pages/PressReleases/PressReleases'
import SidebarInit from 'initializations/Sidebar'
import store from 'definitions/store'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    SidebarInit.init("sidebar")
    PressReleasesInit.init("pressReleases")

    const PressReleases = () => import(/* webpackChunkName: "press-releases-page" */ 'containers/Pages/PressReleases/PressReleases')
    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(PressReleases)
    })
  }
}

