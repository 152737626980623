import SidebarPageStore from 'store/modules/Pages/SidebarPage'
import store from 'definitions/store'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule(name, SidebarPageStore)
            Init.initDefaultValues(name)
        }
    },

    initDefaultValues(name) {
        if (typeof pageWithSidebar !== "undefined") {
            store.commit(`${name}/setPageTitle`, pageWithSidebar.title)
            store.commit(`${name}/setIntro`, pageWithSidebar.intro)
            store.commit(`${name}/setSections`, pageWithSidebar.sections)
            store.commit(`${name}/setPageLeadImgDesktop`, pageWithSidebar.desktopImg)
            store.commit(`${name}/setPageLeadImgTablet`, pageWithSidebar.tabletImg)
            store.commit(`${name}/setPageLeadImgMobile`, pageWithSidebar.mobileImg)
            store.commit(`${name}/setPrintEnabled`, pageWithSidebar.print)


            if (pageWithSidebar.pressContacts) {
                store.commit('sidebar/setPressContacts', pageWithSidebar.pressContacts)
            }

            if (pageWithSidebar.cargoShipping) {
              store.dispatch('sidebar/cargoShipping/setCargoShipping', pageWithSidebar.cargoShipping)
            }

            if (pageWithSidebar.showTermsAndConditions && typeof termsAndConditions !== "undefined") {
                store.commit(`${name}/setTermsAndConditionsVisible`, true)
                store.commit(`${name}/setTermsAndConditions`, {
                    title: termsAndConditions.title.text,
                    content: termsAndConditions.content.text
                })
            }
        }
    }
}

export default Init
