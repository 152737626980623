import SimpleTextPageStore from 'store/modules/Pages/SimpleTextPage'
import store from 'definitions/store'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule(name, SimpleTextPageStore)

            Init.initDefaultValues(name)
        }
    },
    initDefaultValues(name) {
        if (typeof simpleTextPageInit !== "undefined") {
            store.commit(`${name}/setPageTitle`, simpleTextPageInit.title)
            store.commit(`${name}/setIntro`, simpleTextPageInit.intro)
            store.commit(`${name}/setPageLeadImg`, simpleTextPageInit.image)
            store.commit(`${name}/setBlueButtonLink`, simpleTextPageInit.blueButtonLink)
            store.commit(`${name}/setBlueButtonText`, simpleTextPageInit.blueButtonText)
            store.commit(`${name}/setSections`, simpleTextPageInit.sections)

            if (simpleTextPageInit.showTermsAndConditions && typeof termsAndConditions !== "undefined") {
                store.commit(`${name}/setTermsAndConditionsVisible`, true)
                store.commit(`${name}/setTermsAndConditions`, {
                    title: termsAndConditions.title.text,
                    content: termsAndConditions.content.text
                })
            }
        }
    }
}

export default Init
