const SingleDestinationContent = {
  state: {
    pageLeadImg: '',
    pageTitleFromPrice: '',
    pageTitle: '',
    histogramTitle: '',
    country: '',
    blueButtonLink: '',
    blueButtonText: '',
    intro: '',
    secondIntro: '',
    secondFull: '',
    airportInfo: [],
    sections: [],
    termsAndConditionsVisible: false,
    termsAndConditions: {},
    originApt: '',
    useEhorizonStoreData: false,
    metaDescription: ''
  },
  mutations: {
    setPageLeadImg(state, value) {
      state.pageLeadImg = value
    },
    setPageTitle(state, value) {
      state.pageTitle = value
    },
    setPageTitleFromPrice(state, value) {
      state.pageTitleFromPrice = value
    },
    setHistogramTitle(state, value) {
      state.histogramTitle = value
    },
    setCountry(state, value) {
      state.country = value
    },
    setBlueButtonLink(state, value) {
      state.blueButtonLink = value
    },
    setBlueButtonText(state, value) {
      state.blueButtonText = value
    },
    setIntro(state, value) {
      state.intro = value
    },
    setSecondIntro(state, value) {
      state.secondIntro = value
    },
    setSecondFull(state, value) {
      state.secondFull = value
    },
    setAirportInfo(state, value) {
      state.airportInfo = value
    },
    setSections(state, value) {
      state.sections = value
    },
    setOrigin(state, value) {
      state.originApt = value
    },
    setTermsAndConditionsVisible(state, value) {
      state.termsAndConditionsVisible = value
    },
    setTermsAndConditions(state, value) {
      state.termsAndConditions = value
    },
    setUseEhorizonStoreData(state, value) {
      state.useEhorizonStoreData = value
    },
    setMetaDescription(state, value) {
      state.metaDescription = value
     }
  },
  namespaced: true
}

export default SingleDestinationContent
