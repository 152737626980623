import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import loginInit from 'initializations/pages/Pax/Login'
import loginForm from 'components/LoginForm/IframeWrapper'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        loginInit.init("login")

          new Vue({
            store,
            ...loginForm
          }).$mount("#" + this.elemId)
    }
}
