import moment from "moment"

class UUID_Utils {

  static create_UUID () {
    let dt = moment().unix()
    const uuid = 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (dt + Math.random()*16)%16 | 0
      dt = Math.floor(dt/16)
      return ( c === 'x' ? r :(r&0x3|0x8)).toString(16)
    })

    return uuid
  }
}

export default UUID_Utils
