import DestinStore from 'components/eHorizon/Destination/DestinStore'
import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { textInCurrentLang } from 'utils/validators'
import get from 'lodash-es/get'

const defaultLabel = 'label_all'

const formErrors = {
  value: {
    city: {
      maxLength: 'pinsReward.errors.originDestin.maxLength',
      textInCurrentLang: 'pinsReward.errors.originDestin.notInCurrentLang',
      required: 'searchForm.originDestin.destin.errors.missingValue'
    }
  }
}

const defaultState = {
  value: {},
  destinations: [],
  typeaheadIgnoreFilter: true,
  invalid: false,
  labels: {},
  filter: '',
  filteredLabels: {},
  selectedLabel: 'label_all',
  selectedCategory: '',
  selectedSubCategory: '',
  selectedSubCategoryLabel: '',
  initAirport: '',
  customInput: false,
  formErrors: formErrors
}

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations: {
    state: {
      value: {
        required,
        city: {
          maxLength: maxLength(50),
          textInCurrentLang,
          required
        }
      },
      filter: {
        textInCurrentLang
      }
    }
  }
})

const Destin = {
  state: defaultState,
  modules: {
    typeahead: DestinStore()
  },
  getters: {
    $v () {
      return Object.assign({}, validator.$v)
    },
    destinLabels: state => {
      return state.filteredLabels
    }
  },
  mutations: {
    setTypeaheadIgnoreFilter (state, value) {
      state.typeaheadIgnoreFilter = value
    },
    setValue (state, value) {
      state.value = value
    },
    setDestinations (state, value) {
      state.destinations = value
    },
    setInvalid (state, status) {
      state.invalid = status
    },
    setLabels (state, labels) {
      state.labels = labels
    },
    setFilteredLabels (state, labels) {
      state.filteredLabels = labels
    },
    setSelectedLabel (state, label) {
      if (state.selectedLabel === label) {
        state.selectedLabel = defaultLabel
      } else {
        state.selectedLabel = label
      }
    },
    setSelectedCategory (state, value) {
      state.selectedCategory = value
      state.selectedSubCategory = ''
      state.selectedSubCategoryLabel = ''
      state.selectedLabel = defaultLabel
    },
    setSelectedSubCategory (state, value) {
      state.selectedSubCategory = value
    },
    setSelectedSubCategoryLabel (state, value) {
      state.selectedSubCategoryLabel = value
    },
    setFilter (state, value) {
      state.filter = value
    },
    setInitAirport (state, value) {
      state.initAirport = value
    },
    setCustomInput (state,value) {
      state.customInput = value
    }
  },
  actions: {
    doFilter ({ commit }, filter) {
      commit('typeahead/setFilter', filter)
      commit('setFilter', filter)
      commit('setTypeaheadIgnoreFilter', filter === '')
    },
    closeAll ({ commit, dispatch }) {
      dispatch('closeTypeahead')
      commit('setSelectedLabel', defaultLabel)
    },

    closeTypeahead ({ commit, dispatch }) {
      commit('typeahead/close')
      dispatch('setFilterToCurrentValue')
    },

    onValueSelect ({ commit, dispatch }, value) {
      commit('setValue', value)
      commit('typeahead/setValue', value)

      dispatch('setFilterToCurrentValue')
      dispatch('closeTypeahead')
      if(value.customInput) {
        dispatch('validateCustom')
      } else {
        dispatch('validate')
      }
    },
    setFilterToCurrentValue ({ commit, state }) {
      const code = get(state, 'value.aptWithCode', '')

      commit('typeahead/setFilter', code)
      commit('setFilter', code)
      commit('setTypeaheadIgnoreFilter', true)
    },

    clear ({ dispatch }) {
      dispatch('onValueSelect', {})
    },

    updateDestinationLabels ({ commit, state }, labels) {
      const mappedLabels = {}
      labels.map((label) => {
        mappedLabels[`${label}`] = state.labels[`${label}`]
      })
      commit('setFilteredLabels', mappedLabels)
    },

    updateDestinations ({ commit, state, dispatch }, destinations) {
      commit('setDestinations', destinations)

      const findDestin = function (destin) {
        return destin.code === state.value.code && destin.type === state.value.type
      }

      if (state.value && state.value.code && state.value.type) {
        const destin = destinations.find(findDestin)
        if (destin) {
          dispatch('onValueSelect', destin)
        } else {
          dispatch('clear')
        }
      }
    },
    validate ({state, commit, dispatch }) {
      validator.$v.state.$touch()
      commit('setInvalid', !validator.$v.state.value.required)
      if(state.customInput) {
        dispatch('validateCustom')
      }
    },
    validateCustom ({ commit }) {
      let isInvalid =
        !validator.$v.state.value.city.required ||
        !validator.$v.state.value.city.maxLength ||
        !validator.$v.state.value.city.textInCurrentLang

      commit('setInvalid', isInvalid)
    },
    reset ({ commit }) {
      commit('setValue', {})
      commit('setInvalid', false)

      validator.$v.state.$reset()
    }
  },

  namespaced: true
}

export default Destin
