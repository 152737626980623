import AbstractView from 'abstract';
import Vue from 'vue'
import BusinessLoungeInit from 'initializations/pages/BusinessLounge'
import SidebarInit from 'initializations/Sidebar'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        SidebarInit.init("sidebar")
        BusinessLoungeInit.init("businessLounge")

        const BusinessLoungePage = () => import(/* webpackChunkName: "business-lounge-page" */ 'containers/Pages/BusinessLounge')

        new Vue({
            el: "#" + this.elemId,
            store,
            render: h => h(BusinessLoungePage)
        })
    }
}
