<template>
  <div :class="['form-updated', status]">
    <h5 v-if="showTitle">{{ getNotificationTitle }}</h5>
    <p v-if="hasSuccessMessage">{{ getSuccessMessage }}</p>
    <p v-if="hasErrorMessage">{{ errorMessage }}</p>
    <p v-if="hasNoErrorMessage">{{ $t('notification.fail') }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      status: {
        type: String,
        required: true
      },
      showTitle: {
        type: Boolean,
        required: false,
        default: true
      },
      successMessage: {
        type: String,
        required: false,
        default: ''
      },
      errorMessage: {
        type: String,
        required: false,
        default: ''
      }
    },

    computed: {
      isSuccess () {
        return this.status === 'success'
      },
      isFail () {
        return this.status === 'fail'
      },
      hasErrorMessage () {
        return this.isFail && this.errorMessage !== ''
      },
      hasNoErrorMessage () {
        return this.isFail && this.errorMessage === ''
      },
      hasSuccessMessage () {
        return this.isSuccess
      },
      getSuccessMessage () {
        if (this.successMessage !== '') {
          return this.successMessage
        }
        return this.$t('notification.success')
      },
      getNotificationTitle () {
        if (this.isSuccess) {
         return  this.$t('notification.successTitle')
        }

        return  this.$t('notification.failTitle')
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  .form-updated {
    position: relative;
    text-align: center;
    margin-top: 30px;

    &.success {

      &:before {
        content: $icon-checkmark-medium;
        color: $green-base;
      }
    }

    &.fail {

      &:before {
        @include filled-icon-font();
        content: $filled-icon-close;
        color: $red-dark;
      }
    }

    &:before {
      @include icon-font();
      margin: 30px 0 20px;
      width: 100px;
      height: 100px;
      background-color: $grey-white;
      border-radius: 50%;
      font-size: em(30);
      line-height: 105px;

      @include bp-mobile-landscape {
        margin: 0 0 10px;
      }
    }

    h5 {
      margin: 0;
      font-size: em(28);
      font-weight: 300;
    }

    p {
      margin: 12px 0 20px;
      font-size: em(15);
      line-height: 20px;
      color: $grey;

      @include bp-mobile-landscape {
        margin-bottom: 0;
      }
    }
  }
</style>
