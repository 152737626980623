import CreateProfileStore from 'store/modules/Pages/ProfilePages/Clean/CreateProfile'
import store from 'definitions/store'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule(name, CreateProfileStore)

            Init.initDefaultValues(name)
        }
    },

    initDefaultValues(name) {
        if (typeof benefitsList !== "undefined") {
            store.commit(`${name}/setPageTitle`, pageTitle)
            store.commit(`${name}/setBenefitsListTitle`, benefitsTitle)
            store.commit(`${name}/setBenefitsList`, benefitsList)
            store.commit(`${name}/setModalTitle`, modalTitle)
            store.commit(`${name}/setModalContent`, modalContent)
            store.commit(`${name}/setEditMyProfileUrl`, editProfileUrl)
        }

        if (typeof signUpConfirmationPage !== 'undefined') {
            store.commit(`${name}/setSignUpConfirmationUrl`, signUpConfirmationPage)
        }

        if (typeof registerPage !== 'undefined') {
            store.commit(`${name}/setRegisterPageUrl`, registerPage)
        }

        if (typeof profilePageUrl !== 'undefined') {
            store.commit(`${name}/setProfilePageUrl`, profilePageUrl)
        }

        if (typeof loginUrl !== 'undefined') {
            store.commit(`${name}/setLoginUrl`, loginUrl)
        }

        if (typeof loaderBackground !== 'undefined') {
            store.commit(`${name}/setLoaderBackground`, loaderBackground)
        }
    }
}

export default Init
