import AccordionPageStore from 'store/modules/Pages/AccordionPage'
import store from 'definitions/store'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule(name, AccordionPageStore)

            Init.initDefaultValues(name)
        }
    },
    initDefaultValues(name) {
        if (typeof accordionWithLeftMenu !== "undefined") {
            store.commit(`${name}/setPageTitle`, accordionWithLeftMenu.title)
            store.commit(`${name}/setPageLeadImgDesktop`, accordionWithLeftMenu.desktopImg)
            store.commit(`${name}/setPageLeadImgTablet`, accordionWithLeftMenu.tabletImg)
            store.commit(`${name}/setPageLeadImgMobile`, accordionWithLeftMenu.mobileImg)
            store.commit(`${name}/setMainIntro`, accordionWithLeftMenu.intro)
            store.commit(`${name}/setSecondIntro`, accordionWithLeftMenu.content)
            store.commit(`${name}/setAccordionItems`, accordionWithLeftMenu.accordions)
            store.commit(`${name}/setPreAccordionImages`, accordionWithLeftMenu.preAccordionImages)
            store.commit(`${name}/setPrintEnabled`, accordionWithLeftMenu.print)
            store.commit(`${name}/setAdditionalContent`, accordionWithLeftMenu.additionalContent)
            store.commit('sidebar/setPressContacts', accordionWithLeftMenu.pressContacts)
            store.commit('main/setPageType', accordionWithLeftMenu.intName)

            store.commit(`${name}/setShowJsonLd`, accordionWithLeftMenu.showJsonLD)

            if (accordionWithLeftMenu.showSixtWidget) {
                store.commit(`${name}/setShowSixtWidget`, true)
                store.commit(`${name}/setSixtWidgetTitle`, accordionWithLeftMenu.sixtWidgetTitle)
                store.commit(`${name}/setSearchOnlySixt`, accordionWithLeftMenu.searchOnlySixt ? true : false)
            }

            if (accordionWithLeftMenu.cargoShipping) {
                store.dispatch('sidebar/cargoShipping/setCargoShipping', accordionWithLeftMenu.cargoShipping)
            }

            if (accordionWithLeftMenu.showTermsAndConditions && typeof termsAndConditions !== "undefined") {
                store.commit(`${name}/setTermsAndConditionsVisible`, true)
                store.commit(`${name}/setTermsAndConditions`, {
                    title: termsAndConditions.title.text,
                    content: termsAndConditions.content.text
                })
            }
        }
    }
}

export default Init
