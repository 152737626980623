import Vue from 'vue'
import cloneDeep from 'lodash-es/cloneDeep'
import { validationMixin } from 'vuelidate'
import PassengerSegment from 'models/Payments/PassengerSegment'

const SegmentStore = function () {
  const segment = new PassengerSegment()

  const validator = new Vue({
    mixins: [
      validationMixin
    ],
    computed: {
      state () {
        return segment
      }
    },
    validations () {
      const validation = cloneDeep(segment.validation)

      let excluded = []

      if (typeof module.state.excluded !== 'undefined') {
        excluded = Object.values(module.state.excluded)

        excluded.forEach((exclude) => {
          delete validation[`${exclude}`]
        })
      }

      return { state: validation }
    }
  })

  const module =  {
    namespaced: true,
    state: segment,
    mutations: {
      toggleCheckedBaggage (state, value) {
        state.checkedBaggage.checked = value
      },
      setBaggageCount (state, value) {
        state.checkedBaggage.count = value
      },
      toggleHeavyBaggage (state, value) {
        state.heavyBaggage.checked = value
      },
      toggleExtras (state, value) {
        state.extras.checked = value
      },
      toggleSpeq (state, value) {
        state.speq.checked = value
      },
      setExcludedFields (state, value) {
        state.excluded = value
      },
      clearSegment (state) {
        state.speq.checked = false
        state.checkedBaggage.checked = false
        state.heavyBaggage.checked = false
        state.extras.checked = false

        state.speq.type = ''
        state.checkedBaggage.count = 0
        validator.$v.state.$reset()
      },
    },
    getters: {
      $validator () {
        return validator.$v.state
      },
      $checkedBaggage () {
        return Object.assign({}, validator.$v.state.checkedBaggage)
      },
      checkedBaggageValidationFormErrors (state) {
        return state.formErrors.checkedBaggage
      },
      $heavyBaggage () {
        return Object.assign({}, validator.$v.state.heavyBaggage)
      },
      heavyBaggageValidationFormErrors (state) {
        return state.formErrors.heavyBaggage
      },
      $extras () {
        return Object.assign({}, validator.$v.state.extras)
      },
      extrasValidationFormErrors (state) {
        return state.formErrors.extras
      }
    }
  }

  return module
}

export default SegmentStore
