import MmbFormStore from 'store/modules/eHorizon/MmbForm'
import gtmMMB from 'services/AirBaltic/GTM/MMB'
import store from 'definitions/store'
import conf from 'conf'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, MmbFormStore)

      Init.initDefaultValues(name)
      Init.setFieldValues(name)
    }
  },
  initDefaultValues (name) {
    store.commit(`${name}/setFormUrl`, `${conf.newEeUrl}/en/mmb/retrieve`)
  },
  setFieldValues(name) {
    if (typeof mmbInit !== 'undefined') {
      let pnr = ''
      if (mmbInit.pnr !== null) {
        pnr = mmbInit.pnr
        store.commit(`${name}/bookingRef`, mmbInit.pnr)
      }
      if (mmbInit.lastname !== null) {
        store.commit(`${name}/surname`, mmbInit.lastname)
      }

      if (mmbInit.refundError) {
        store.commit(`${name}/setRefundError`, true)
      }

      if (mmbInit.errorRef) {
        store.commit(`${name}/setErrorRef`, mmbInit.errorRef)
      }

      if (mmbInit.errors.length > 0) {
        gtmMMB.mmbError("2E errors", `${mmbInit.errors.join()}; ${pnr}`)
        mmbInit.errors.map(function (error) {
          store.commit(`${name}/addError`, error)
        });
      }
    }
  }
}

export default Init
