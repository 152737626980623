import EditProfile from 'models/Forms/Profile/EditProfile'
import cloneDeep from 'lodash-es/cloneDeep'

const Generic = {
  namespaced: true,
  state: Object.assign({}, new EditProfile()),
  mutations: {
    setPageData (state, value) {
      state.pageData = value
    },
    setDeleteData (state, value) {
      state.deleteData = value
    },
    setLanguages(state, value) {
      state.languages = value
    },
    setCountries(state, value) {
      state.countries = value
    },
    setTerms (state, value) {
      state.termsAndConditions = value
    },
    setPlatforms (state, value) {
      state.platforms = value
    }
  },
  actions: {
    removeNewsletterNotification ({ state, commit }) {
      const platforms = cloneDeep(state.platforms)
      delete platforms.newsletter

      commit('setPlatforms', platforms)
    },
    setCountries ({ commit }, data) {
      let countries = [{ value: '', title: '---' }]

      Object.values(data).map((country) => {
        countries.push({ value: country.code, title: country.name })
      })

      countries = countries.sort((a, b) => a.title.localeCompare(b.title))

      commit('setCountries', countries)
    },
    setLanguages ({ commit }, data) {
      const languages = [{ value: '', title: '---' }]

      data.map((lang) => {
        languages.push({ value: lang, title: `languages.${lang.toLowerCase()}` })
      })

      commit('setLanguages', languages)
    }
  }
}

export default Generic
