import FormRequest from 'models/Forms/Assistance/Sections/Other'
import FormValidationUtils from 'utils/FormValidation'

const formRequest = new FormRequest()
const formData = {
  formErrors: formRequest.errorMsg,
  form: formRequest.form,
}
const defaultState = Object.assign({}, formData)
const validator = FormValidationUtils.initValidation(defaultState, formRequest.validation)

const module = {
  namespaced: true,
  state: defaultState,
  actions: {
    validateRequest () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('validate other assistance form request')
      })
    },
    clearRequest ({ commit }) {
      validator.$v.state.form.$reset()
      commit('resetForm')
    }
  },
  mutations: {
    setOtherRequest (state, value) {
      state.form.requestMessage = value
    },
    resetForm (state) {
      state.form = new FormRequest().form
    }
  },
  getters: {
    $request () {
      return Object.assign({}, validator.$v.state.form)
    },
    requestValidationFormErrors (state) {
      return state.formErrors
    }
  }
}

export default module
