import Passenger from 'models/CheckIn/Passenger'
import Flight from 'models/CheckIn/Flight'
import CheckInService from 'services/AirBaltic/CheckIn'
import find from 'lodash-es/find'
import conf from 'conf'

const CheckIn = {
  state: {
    pageData: {},
    generic: {},
    segments: [],
    paxList: [],
    rules: [],
    directions: [],
    origDestData: {},
    indexUrl: '',
    documentsToSubmit: [],
    submitError: '',
    isOnlyPax: false,
    uuid: '',
    showCaptcha: false
  },
  mutations: {
    setOrigDestData (state, value) {
      state.origDestData = value
    },
    addGeneric (state, value) {
      state.generic = value
    },
    setPnr (state, value) {
      state.generic.pnr = value
    },
    setTicketNumber (state, value) {
      state.generic.ticketNumber = value
    },
    setSurname (state, value) {
      state.generic.surname = value
    },
    addSegments (state, value) {
      state.segments = value
    },
    addDirections (state, value) {
      state.directions = value
    },
    addRules (state, value) {
      state.rules = value
    },
    setIndexUrl (state, value) {
      state.indexUrl = value
    },
    setPageData (state, value) {
      state.pageData = value
    },
    clearDocument (state, props) {
      const pax = find(state.paxList, { paxId: props.pax.paxId, directionId: props.pax.directionId })
      const document = find(pax.documents, { type: props.document.type })
      document.status = 'NOT_YET_UPLOADED'
    },
    addDocumentToSubmit (state, { postObj, uuid }) {
      const doc = state.documentsToSubmit.find((d) => {
        return d.directionId === postObj.directionId && d.paxId === postObj.paxId && d.documentType === postObj.documentType
      })
      if (doc) {
        doc.uuid = uuid
      } else {
        postObj.uuid = uuid
        state.documentsToSubmit.push(postObj)
      }
    },
    setPaxInList (state, value) {
      let pax = state.paxList.find((p) => {
        return p.directionId === value.directionId && d.paxId === value.paxId
      })
      if (pax) {
        pax = value
      }
    },
    setDocumentStatuses (state, directionsWithRules) {
      state.paxList.map((pax) => {
        directionsWithRules.map(dir => {
          if (dir.directionId === pax.directionId) {
            dir.documentStatus.map((doc) => {
              if (doc.specification.paxId === pax.paxId) {
                pax.setDocument(doc)
              }
            })
          }
        })
      })
    },
    setPaxList (state, value) {
      state.paxList = value
    },
    setSubmitError (state, value) {
      state.submitError = value
    },
    setIsOnlyPax (state, value) {
      state.isOnlyPax = value
    },
    checkboxChange(state, { value, pax }) {
      state.paxList.map((passenger) => {
        if (passenger.paxId === pax.paxId && passenger.directionId === pax.directionId) {
          passenger.isChecked = value
        }
      })
    },
    setUuid (state, value) {
      state.uuid = value
    },
    setShowCaptcha (state, value) {
      state.showCaptcha = value
    }
  },
  actions: {
    setPaxData ({ state, commit, dispatch }, { pnr, directionsWithRules }) {
      const segmentList = []
      const directionList = []
      const passengerList = []
      const pnrSurname = state.generic.surname
      if (pnr.pnrRl) {
        commit('setPnr', pnr.pnrRl)
      }
      const isOnlyPax = pnr.passengers && pnr.passengers.length === 1

      directionsWithRules.map(dir => {
        if (dir.documentStatus && dir.documentStatus.length) {
          const direction = find(pnr.directions, { id: dir.directionId })
          dir.segments = direction.segments
          pnr.passengers.map((passenger) => {
            if (passenger.surname && passenger.surname !== pnrSurname && passenger.surname.indexOf(pnrSurname) > -1) {
              commit('setSurname', passenger.surname)
            }
            passenger.isChecked = isOnlyPax
            const pax = new Passenger(passenger, dir.directionId)
            dir.documentStatus.map((doc) => {
              if (doc.specification.paxId === pax.paxId) {
                if (dir.rules && dir.rules.length) {
                  const docSpec = dir.rules[0].documents.find(rule => rule.documentType === doc.specification.documentType)
                  doc.isCovidTestDoc = docSpec ? docSpec.ruleName === 'LV_TEST' : false
                  doc.hideQRscan = docSpec ? !docSpec.qr : false
                }

                pax.setDocument(doc)
              }
            })
            passengerList.push(pax)
          })
          directionList.push(dir)
        }
      })
      let paxWithRequiredDocs = false
      passengerList.map((pax) => {
        if (pax.hasToUploadDocuments) {
          paxWithRequiredDocs = true
        }
      })
      if (!paxWithRequiredDocs) {
        dispatch('validateAndSubmit').then(() => {
          dispatch('redirectToEE')
        })
      } else if (!directionList.length) {
        dispatch('redirectToEE')
      } else {
        pnr.segments.map(segment => {
          segmentList.push(new Flight(segment))
        })
        commit('addSegments', segmentList)
        commit('addDirections', directionList)
        commit('setPaxList', passengerList)
        commit('setIsOnlyPax', isOnlyPax)
        commit("main/showPlaneLoader", false, { root: true })
      }
    },

    redirectToEE ({ getters }) {
      window.location.href = getters.eeUrl
    },

    sendDocument ({ state, rootState, commit, dispatch }, postObj) {
      const service = new CheckInService()
      postObj.languageCode = rootState.main.selectedLang

      return new Promise((resolve, reject) => {
        service
          .sendDocument(postObj, state.uuid)
          .then(response => {
            if (response)  {
              const uuid = response.data.uuid

              commit('addDocumentToSubmit', { postObj, uuid })
              dispatch('validateDocument')
                .then((res) => {
                  resolve(res)
                })
                .catch((e) => { reject(e) })
            } else {
              reject({ code: 'OTHER_VALIDATION_ERROR' })
            }
          })
          .catch((e) => {
            dispatch('checkCaptcha', e)
            reject(e)
          })
      })
    },

    checkCaptcha ({ commit }, value) {
      if (value.response && value.response.status === 403) {
        commit('setShowCaptcha', true)
      } else {
        commit('setShowCaptcha', false)
      }
    },

    validateDocument ({ state, rootState, dispatch }) {
      const service = new CheckInService()
      const docList = []
      state.documentsToSubmit.forEach(d => {
        docList.push(d.uuid)
      })
      const postObj = {
        surname: state.generic.surname,
        documents: docList
      }

      if (state.generic.pnr && state.generic.pnr.length === 6) {
        postObj.pnrRl = state.generic.pnr
      } else {
        postObj.ticketNumber = state.generic.pnr
      }

      postObj.languageCode = rootState.main.selectedLang

      return new Promise((resolve, reject) => {
        service.validate(postObj, state.uuid)
          .then(response => {
            resolve(response.data)
          })
          .catch((e) => {
            dispatch('checkCaptcha', e)
            reject(e)
          })
      })
    },

    validateAndSubmit ({ state, rootState, commit, dispatch }) {
      const service = new CheckInService()
      const docList = []
      state.documentsToSubmit.forEach(d => {
        docList.push(d.uuid)
      })
      const postObj = {
        surname: state.generic.surname,
        documents: docList,
        languageCode: rootState.main.selectedLang
      }

      if (state.generic.pnr && state.generic.pnr.length === 6) {
        postObj.pnrRl = state.generic.pnr
      } else {
        postObj.ticketNumber = state.generic.pnr
      }

      return new Promise((resolve, reject) => {
        service
          .validateAndSubmit(postObj, state.uuid)
          .then(({ data }) => {
            commit('setDocumentStatuses', data.directionsWithRules)
            resolve(data)
          })
          .catch((e) => {
            dispatch('checkCaptcha', e)
            reject(e)
          })
      })
    },

    skipDocumentVerification ({ state, rootState, dispatch }) {
      const languageCode = rootState.main.selectedLang
      new CheckInService().skip(state.generic.pnr, languageCode, state.uuid)
        .then(() => {
          dispatch('redirectToEE')
        })
        .catch(() => {
          dispatch('redirectToEE')
        })
    },
  },

  getters: {
    eeUrl (state, getters, rootState) {
      let params = window.location.search
      if (params) {
        params = params.replace('?', '&')
      }
      const urlEncodedSurname = state.generic.surname.replace(' ', '%20')
      return `${conf.newEeUrl}/${rootState.main.selectedLang}/checkin/upsell?action2=upsell&p=bti&` +
        `ck_pnr=${state.generic.pnr}&ck_lastname=${urlEncodedSurname}&l=${rootState.main.selectedLang}${params}`
    }
  },

  namespaced: true
}

export default CheckIn
