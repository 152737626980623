import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import storeInit from 'initializations/pages/GroupBooking'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    storeInit.init("groupBooking")

    const BookingForm = () => import(/* webpackChunkName: "booking-form" */ 'containers/Pages/GroupBooking/Form')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(BookingForm)
    })
  }
}
