import { maxLength, required } from 'vuelidate/lib/validators';
var GuidanceRequest = /** @class */ (function () {
    function GuidanceRequest() {
        this.form = {
            impairmentType: '',
            accompaniedByGuideDog: '',
            requestMessage: ''
        };
        this.messageMaxLength = 1500;
        this.errorMsg = {
            accompaniedByGuideDog: {
                required: 'forms.guidance.accompaniedByGuideDog.validation.required'
            },
            impairmentType: {
                required: 'forms.guidance.impairmentType.validation.required'
            },
            requestMessage: {
                allowedCharacters: 'forms.guidance.requestMessage.validation.allowedCharacters',
                maxLength: 'forms.guidance.requestMessage.validation.maxLength'
            }
        };
        this.validation = {
            form: {
                accompaniedByGuideDog: {
                    required: required
                },
                impairmentType: {
                    required: required
                },
                requestMessage: {
                    maxLength: maxLength(this.messageMaxLength)
                }
            }
        };
    }
    return GuidanceRequest;
}());
export default GuidanceRequest;
