import AbstractView from 'abstract';
import Vue from 'vue'
import pageInit from 'initializations/pages/SearchTable'
import SidebarInit from 'initializations/Sidebar'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init () {
      pageInit.init('searchTable')
      SidebarInit.init("sidebar")

      const SearchTable = () => import(/* webpackChunkName: "search-table-page" */ 'containers/Pages/SearchTable')
      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(SearchTable)
      })
    }
}
