import ComplimentPageStore from 'store/modules/Pages/ComplimentPage'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, ComplimentPageStore)

      Init.initDefaultValues(name)
    }
  },
  initDefaultValues(name) {
    if (typeof complimentInit !== "undefined") {
      store.commit(`${name}/setPageTitle`, complimentInit.title)
      store.commit(`${name}/setIntro`, complimentInit.intro)
      store.commit(`${name}/setDescription`, complimentInit.description)

      store.commit('sidebar/setPressContacts', complimentInit.pressContacts)
    }
  }
}

export default Init
