<template>
  <div class="wrap-container">
    <div class="container">
      <div class="buttons tabs">
        <template v-if="isIndex">
          <button
            v-for="tab in tabs.filter((key) => key !== 'HTL')"
            :key="tab"
            :ref="tab"
            class="button-link tab"
            :class="{ active: isActiveItem(tab) }"
            @click="switchToTab(tab)"
          >
            <span>{{ $t(`indexTabs.${tab.toLowerCase()}TabLabel`) }}</span>
          </button>
        </template>
        <template v-if="!isIndex && isDesktop">
          <a
            v-for="tab in tabs.filter((key) => key !== 'HTL')"
            :key="tab"
            class="button-link tab"
            :href="`/${selectedLang}/index?opentab=${tab}`"
            @click="clickAction(tab)"
          >
            {{ $t(`indexTabs.${tab.toLowerCase()}TabLabel`) }}
          </a>
        </template>
      </div>
      <div
        v-if="isDesktop && mainMenu.length"
        class="buttons"
      >
        <button
          v-for="item in mainMenu"
          :key="item.label"
          class="button-link tab"
          :class="{ active: item.key === activeItem }"
          @click="openMenuItem(item)"
        >
          <span>{{ item.label }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import UrlParams from 'utils/UrlParams'
  import gtmHeader from 'services/AirBaltic/GTM/HeaderMenu'

  export default {
    props: {
      mainMenu: {
        type: Array,
        required: true
      },
      activeItem: {
        type: String,
        default: '',
        required: false
      },
      menuOpen: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        tabs: ['FLT', 'HTL', 'MMB', 'CKI']
      }
    },
    computed: {
      ...mapGetters('device', ['isDesktop']),
      ...mapState('main', ['pageType', 'selectedLang']),
      ...mapState('indexTabs', ['activeTab', 'showOverlay']),
      isIndex() {
        return this.pageType === 'index'
      }
    },
    created() {
      this.getTabFromHash()
    },
    methods: {
      openMenuItem(item) {
        if (item.key === this.activeItem || item === '') {
          this.$emit('open-menu-item', '')
          return
        }

        gtmHeader.headerCategoryClick(item.key, item.href, item.label)
        this.$emit('open-menu-item', item.key)
      },
      getTabFromHash() {
        let tab = UrlParams.getUrlParam(window.location.href, 'opentab')

        if (tab) {
          tab = tab.toUpperCase()
        }

        if (this.tabs.includes(tab)) {
          this.switchToTab(tab, true)
        }
      },
      switchToTab(tab, initial = false) {
        this.openMenuItem('')

        if (tab !== this.activeTab) {
          this.$store.dispatch('indexTabs/switchTabs', tab, { root: true })

          if (!initial) {
            gtmHeader.headerCategoryClick(
              tab,
              null,
              this.$t(`indexTabs.${tab.toLowerCase()}TabLabel`)
            )
          }

          window.scrollTo(0, 0)
        }

        this.$emit('open-tab', tab)
      },
      clickAction(tab) {
        gtmHeader.headerCategoryClick(
          tab,
          `/${this.selectedLang}/index?opentab=${tab}`,
          this.$t(`indexTabs.${tab.toLowerCase()}TabLabel`)
        )
      },
      isActiveItem(key) {
        if (this.menuOpen || this.activeItem) {
          return false
        }

        if (key === 'FLT' && this.activeTab === 'HTL') {
          return true
        }

        return this.activeTab === key
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .wrap-container {
    box-shadow: inset 0 -1px 0 0 $gray-divider;

    @include bp-mf-tablet {
      padding: 0 8px;
    }

    @include bp-desktop-small {
      padding-right: 26px !important;
      padding-left: 26px !important;
    }

    @include bp-mf-desktop {
      padding: 0;
    }

    &.active {
      z-index: 2;
    }

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: $desktop-wide-width;

      @include bp-mf-desktop {
        margin: 0 auto;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    padding: 0 4px;

    @include bp-mf-desktop {
      padding: 0;
    }

    .button-link {
      padding: 0 12px;
      width: auto;

      @include bp-mf-desktop {
        padding: 0 20px;
      }

      span {
        min-height: 48px;
        display: flex;
        align-items: center;
      }

      &:first-child {
        @include bp-mf-desktop {
          padding-left: 0;
        }
      }

      &:last-child {
        @include bp-mf-desktop {
          padding-right: 0;
        }
      }

      &.tab {
        display: inline-block;

        @include bp-mf-desktop {
          overflow: initial;
          white-space: initial;
          display: inline-flex;
          justify-content: center;
          flex-direction: row;
        }

        @include bp-mf-desktop {
          font-weight: 500;
        }
      }
    }

    a.button-link {
      min-height: 48px;
      display: flex;
      align-items: center;
    }

    &.tabs {
      padding: 0;
      display: block;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;

      @include bp-mf-desktop {
        overflow: initial;
        white-space: initial;
        display: initial;
      }

      span {
        font-size: rem(14, 16);
        font-weight: 500;

        @include bp-mf-tablet {
          font-size: rem(16, 16);
        }
      }
    }
  }

  .button-link {
    font-size: rem(16, 16);
    font-weight: 400;
    line-height: 24px;
    color: $blue-dark;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: space-between;

    @include outline-focus;

    &.active {
      position: relative;

      span {
        border-bottom: 3px solid $green-base;
        font-weight: 500;
      }
    }
  }

  button {
    text-overflow: initial;
    white-space: initial;
  }
</style>
