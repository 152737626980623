import Api from 'services/AirBaltic/Api'
import ErrorHandler from 'utils/ErrorHandler'
import conf from 'conf'

const ERRORS = {
  GENERIC: 'generic.errors.serverError',
  VALIDATION_FAILURE: 'generic.errors.serverError',
  PINS_VALIDATION_FAILURE: 'pinsReward.pinsNumber.genericError'
}

class RewardUpgradeRequest {

  getHeaders () {
    return {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  validatePins (data) {
    const headers = this.getHeaders()
    let formData = `number=${data.number}&name=${data.name}`
    return new Api().post(`/pins/validate${conf.btUrlCors}`, formData, headers)
      .then((response) => Promise.resolve(response))
      .catch(error => {
        if (error.response) {
          return Promise.reject(this.getFieldErrors(error.response))
        } else {
          return Promise.reject({
            msg: this.getErrors(ERRORS.GENERIC),
            type: "submit"
          })
        }
      })
  }

  submitForm (data) {
    return new Api().post('/pins/flightUpgradeReward' + conf.btUrlCors, data)
      .then((response) => Promise.resolve(response))
      .catch(error => {
        if (error.response) {
          return Promise.reject(this.getFieldErrors(error.response))
        } else {
          return Promise.reject({
            msg: this.getErrors(ERRORS.GENERIC),
            type: "submit"
          })
        }
      })
  }

  getFieldErrors(error) {
    if (error.data.code === 'VALIDATION_FAILURE' && error.data.fieldErrors) {
      if (this.isPinsError(error.data.fieldErrors)){
        return {
          msg: 'pinsReward.pinsNumber.genericError',
          fieldErrors: error.data.fieldErrors,
          type: "submit"
        }
      }
    }

    return {
      msg: this.getErrors(error),
      message: error.data.message,
      fieldErrors: error.data.fieldErrors,
      type: "submit"
    }
  }

  isPinsError (invalidFields) {
    return !!(invalidFields['cardHolder.cardNumber'] && invalidFields['cardHolder.firstName'] && invalidFields['cardHolder.lastName'])
  }

  getErrors (response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default RewardUpgradeRequest
