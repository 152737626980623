import Generic from 'store/modules/Forms/Generic'
import Assistance from 'models/Forms/Assistance/Assistance'
import PnrValidation from 'store/modules/Forms/PnrValidation'
import Guidance from 'store/modules/Forms/Assistance/Guidance'
import OwnWheelChair from 'store/modules/Forms/Assistance/OwnWheelChair'
import AirportWheelChair from 'store/modules/Forms/Assistance/AirportWheelChair'
import Other from 'store/modules/Forms/Assistance/Other'
import Contact from 'store/modules/Forms/Sections/Contact'
import Flights from 'store/modules/Forms/Sections/Flights'
import Company from 'store/modules/Forms/Sections/Company'
import Files from 'store/modules/Pages/Claims/Form/Files'
import Consent from 'store/modules/Forms/Assistance/Sections/Consent'

export default {
  namespaced: true,
  modules: {
    generic: Generic,
    validation: PnrValidation,
    guidance: Guidance,
    'wheelchair-own': OwnWheelChair,
    'wheelchair-airport': AirportWheelChair,
    other: Other,
    contact: Contact,
    company: Company,
    flights: Flights,
    files: Files,
    consent: Consent
  },
  state: () => new Assistance(),
  actions: {
    setTexts({ commit }, texts) {
      commit('setTexts', texts)
    },
    setActiveStep({ commit }, value) {
      commit('generic/setActiveStep', value)
    },
    setActiveItem({ commit }, value) {
      commit('generic/setActiveItem', value)
    },
    setBackFunction({ commit }, value) {
      commit('generic/setBackFn', value)
    },
    setBookingRef({ commit }, value) {
      commit('validation/pnr', value)
    },
    setSurname({ commit }, value) {
      commit('validation/surname', value)
    },
    setError({ commit }, value) {
      commit('validation/addError', value)
    },
    removeError({ commit }, value) {
      commit('validation/removeError', value)
    },
    setFromUrl({ commit }, value) {
      commit('validation/setFromUrl', value)
    },
    validate({ dispatch }, value) {
      return dispatch('validation/validatePnrForm', value)
    },
    clearPnrValidation({ dispatch }) {
      dispatch('validation/clearForm')
    },
    clearGuidanceForm({ dispatch }) {
      return dispatch('generic/clearForms')
    },
    clearOwnWheelChairForm({ dispatch }) {
      return dispatch('generic/clearForms')
    },
    clearForm({ dispatch }) {
      return dispatch('generic/clearForms')
    },
    setPnrValidationData({ dispatch }, value) {
      dispatch('validation/setPnrValidationData', value)
    },
    setGenericData({ dispatch }, value) {
      dispatch('generic/setGenericData', value)
    },
    validateGuidanceAndSubmit({ dispatch, getters }) {
      return dispatch(`${getters.activeItem.key}/validateAndSubmit`)
    },
    validateOwnWheelChairAndSubmit({ dispatch, getters }) {
      return dispatch(`${getters.activeItem.key}/validateAndSubmit`)
    },
    validateOtherAndSubmit({ dispatch, getters }) {
      return dispatch(`${getters.activeItem.key}/validateAndSubmit`)
    },
    setImpairmentType({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setImpairmentType`, value)
    },
    setAccompaniedByGuideDog({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setAccompaniedByGuideDog`, value)
    },
    setGuidanceRequest({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setGuidanceRequest`, value)
    },
    setContactExcludedFields({ commit }, value) {
      commit('contact/setExcludedFields', value)
    },
    setContactTitle({ commit }, value) {
      commit('contact/setTitle', value)
    },
    setContactFirstName({ commit }, value) {
      commit('contact/setFirstName', value)
    },
    setContactLastName({ commit }, value) {
      commit('contact/setLastName', value)
    },
    setContactEmail({ commit }, value) {
      commit('contact/setEmail', value)
    },
    setCompanyBillingDetails({ commit }, value) {
      commit('company/setBillingDetails', value)
    },
    setCompanyName({ commit }, value) {
      commit('company/setCompany', value)
    },
    setCompanyRegistrationNumber({ commit }, value) {
      commit('company/setRegistrationNumber', value)
    },
    setCompanyVatNumber({ commit }, value) {
      commit('company/setVatNumber', value)
    },
    setCompanyAddress({ commit }, value) {
      commit('company/setAddress', value)
    },
    setCompanyCity({ commit }, value) {
      commit('company/setCity', value)
    },
    setCompanyPostalCode({ commit }, value) {
      commit('company/setPostalCode', value)
    },
    setCompanyCountryCode({ commit }, value) {
      commit('company/setCountryCode', value)
    },
    setContactIntCode({ commit }, value) {
      commit('contact/setIntCode', value)
    },
    setContactPhoneNumber({ commit }, value) {
      commit('contact/setPhoneNumber', value)
    },
    setFlightNumber({ commit }, value) {
      commit('flights/setFlightNumber', value)
    },
    addFlight({ commit }) {
      commit('flights/addFlight')
    },
    removeFlight({ commit }, value) {
      commit('flights/removeFlight', value)
    },
    setFlightDay({ commit, dispatch }, data) {
      commit('flights/setFlightDay', data)
      dispatch('flights/setFlightDate', data.index)
    },
    setFlightMonth({ commit, dispatch }, data) {
      commit('flights/setFlightMonth', data)
      dispatch('flights/setFlightDate', data.index)
    },
    setFlightYear({ commit, dispatch }, data) {
      commit('flights/setFlightYear', data)
      dispatch('flights/setFlightDate', data.index)
    },
    updateSelectedFlights({ commit }, value) {
      commit('flights/updateSelectedFlights', value)
    },
    setGuidanceAtAirport({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setGuidanceAtAirport`, value)
    },
    setUseOwnWheelchair({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setUseOwnWheelchair`, value)
    },
    setUseAirportWheelchair({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setUseAirportWheelchair`, value)
    },
    setWheelchairType({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setWheelchairType`, value)
    },
    setCarryToPlane({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setCarryToPlane`, value)
    },
    setCarryToSeat({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setCarryToSeat`, value)
    },
    setWeight({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setWeight`, value)
    },
    setWidth({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setWidth`, value)
    },
    setHeight({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setHeight`, value)
    },
    setLength({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setLength`, value)
    },
    setOwnWheelChairRequest({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setOwnWheelChairRequest`, value)
    },
    setAirportWheelChairRequest({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setAirportWheelChairRequest`, value)
    },
    setOtherRequest({ commit, getters }, value) {
      commit(`${getters.activeItem.key}/request/setOtherRequest`, value)
    },
    setConsent({ commit }, value) {
      commit(`consent/setConsent`, value)
    }
  },
  mutations: {
    setTexts(state, value) {
      state.texts = value
    },
    setFormName(state, value) {
      state.formName = value
    }
  },
  getters: {
    activeItem(state) {
      return state.generic.activeItem
    },
    activeStep(state) {
      return state.generic.activeStep
    },
    bookingTitle(state) {
      return state.validation.bookingTitle
    },
    bookingDescription(state) {
      return state.validation.bookingDescription
    },
    form(state) {
      return state.validation.form
    },
    validationFormErrors(state, getters) {
      return getters['validation/validationFormErrors']
    },
    pnrRules(state) {
      return state.pnrRules
    },
    additionalInfo(state) {
      return state.generic.additionalInfo
    },
    type(state) {
      return state.generic.type
    },
    typeTitle(state) {
      return state.generic.typeTitle
    },
    contactForm(state) {
      return state.contact.form
    },
    contactInformationTitle(state) {
      return state.contact.contactInformationTitle
    },
    contactInformationDescription(state) {
      return state.contact.contactInformationDescription
    },
    showBillingDetails(state) {
      return state.company.showBillingDetails
    },
    $contact(state, getters) {
      return getters['contact/$contact']
    },
    contactValidationFormErrors(state, getters) {
      return getters['contact/contactValidationFormErrors']
    },
    companyForm(state) {
      return state.company.form
    },
    $company(state, getters) {
      return getters['company/$company']
    },
    companyValidationFormErrors(state, getters) {
      return getters['company/validationFormErrors']
    },
    countries(state) {
      return state.generic.countries
    },
    flights(state) {
      return state.flights
    },
    flightDay(state) {
      return state.flights.flightDay
    },
    flightMonth(state) {
      return state.flights.flightMonth
    },
    flightYear(state) {
      return state.flights.flightYear
    },
    texts(state) {
      return state.texts
    },
    flightDetailsDescription(state) {
      return state.texts.flightDetailsDescription
    },
    $flights(state, getters) {
      return getters['flights/$flights']
    },
    checkedValidation(state, getters) {
      return getters['flights/checkedValidation']
    },
    flightCount(state, getters) {
      return getters['flights/flightCount']
    },
    hiddenContactsFields(state, getters) {
      return state[getters.activeItem.key].hiddenContactsFields
    },
    requestForm(state, getters) {
      return state[getters.activeItem.key].request.form
    },
    $request(state, getters) {
      return getters[`${getters.activeItem.key}/request/$request`]
    },
    requestValidationFormErrors(state, getters) {
      return getters[`${getters.activeItem.key}/request/requestValidationFormErrors`]
    },
    $v(_state, getters) {
      return getters['consent/$v']
    },
    consent(state) {
      return state.consent.form.consent
    },
    consentErrors(_state, getters) {
      return getters['consent/consentErrors']
    }
  }
}
