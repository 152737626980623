import FareConditionsStore from 'store/modules/Pages/FareConditions'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, FareConditionsStore)

      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {

    if (typeof pageData !== "undefined") {
      store.dispatch(`${name}/setPageData`, pageData)
    }
  }
}

export default Init
