import AbstractView from 'abstract'
import Vue from 'vue'
import EditMyProfilePageInit from 'initializations/pages/Pax/EditMyProfilePage'
import SidebarInit from 'initializations/Sidebar'
import store from 'definitions/store'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId)
  }

  init() {
    SidebarInit.init('sidebar')
    EditMyProfilePageInit.init('editMyProfilePage')

    const EditMyProfile = () =>
      import(
        /* webpackChunkName: "edit-my-profile-page" */ 'containers/Pages/Profile/EditMyProfile'
      )
    new Vue({
      el: '#' + this.elemId,
      store,
      render: (h) => h(EditMyProfile)
    })
  }
}
