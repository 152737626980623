import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import PassengerModel from 'models/Claims/Passenger'

const passenger = new PassengerModel()

const defaultState = {
  passengers: [passenger],
  selectedPassengers: [],
  formErrors: passenger.errorMsg
}

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations () {
    return {
      state: Object.assign({}, passenger.validation)
    }
  }
})

const Passengers = {
  state: defaultState,
  mutations: {
    setPassengers (state, value) {
      state.passengers = value
    },

    addPassenger (state) {
      const passenger = new PassengerModel()
      passenger.selected = true

      state.passengers.push(passenger)
    },

    removePassenger (state, index) {
      state.passengers.splice(index, 1)
    },

    setFirstName (state, value) {
      if (state.passengers[value.index]) {
        state.passengers[value.index].firstName = value.value
      }
    },

    setLastName (state, value) {
      if (state.passengers[value.index]) {
        state.passengers[value.index].lastName = value.value
      }
    },

    setSelectedPax (state, value) {
      if (state.passengers[value.index]) {
        state.passengers[value.index].selected = value.value
      }
    },

    reset (state) {
      state.passengers = [new PassengerModel()]
    },

    updateSelectedPassengers (state, value) {
      state.selectedPassengers = value
    }
  },

  actions: {
    touch ({ state, rootState }) {
      return new Promise((resolve, reject) => {
        let vState = validator.$v.state.passengers

        if (state.passengers.length > 1 && !rootState.claims.pnr.isGroup) {
          vState = validator.$v.state.selectedPassengers
        }

        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('passengers')
      })
    },

    clearForm ({ commit }) {
      validator.$v.state.$reset()
      commit('reset')
    }
  },

  getters: {
    $passengers () {
      return validator.$v.state.passengers
    },

    checkedPassengerValidation () {
      return Object.assign({}, validator.$v.state.selectedPassengers)
    },
  },

  namespaced: true
}

export default Passengers
