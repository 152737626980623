import CorporateRegister from "store/modules/Pages/CorporateRegister/CorporateRegister";
import store from "definitions/store";

const Init = {
  init() {
    if (!store["corporateRegister"]) {
      store.registerModule("corporateRegister", CorporateRegister);
      Init.initDefaultValues(store);
    }
  },

  initDefaultValues(store) {
    if (typeof pageData !== "undefined") {
      store.commit("corporateRegister/setPageData", pageData);
    }

    if (typeof contentData_data_countries !== "undefined") {
      store.commit(
        "corporateRegister/setIntCodeList",
        contentData_data_countries._children,
        { root: true }
      );
    }
  },
};

export default Init;
