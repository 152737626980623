import PartnershipStore from 'store/modules/Pages/Partnership/Partnership'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store.hasModule(name)) {
      store.registerModule(name, PartnershipStore)
      Init.initDefaultValues(name)
    }
  },
  initDefaultValues(name) {
    if (typeof pageData !== 'undefined') {
      store.commit(`${name}/setPageData`, pageData)
    }

    if (typeof intCodeCountries !== 'undefined') {
      store.commit(`${name}/setCountries`, intCodeCountries)
    }

    if (typeof formData !== 'undefined') {
      store.commit(`${name}/setFormData`, formData)
    }
    if (typeof isFormOpen !== 'undefined') {
      store.commit(`${name}/setIsFormOpen`, isFormOpen)
    }
  }
}

export default Init
