var EditProfile = /** @class */ (function () {
    function EditProfile() {
        this.pageTitle = '';
        this.pageDescription = '';
        this.sections = {};
        this.actions = [];
        this.deleteData = {};
        this.countries = [];
        this.languages = [];
        this.termsAndConditions = '';
        this.newsletterNotificationVisible = true;
        this.platforms = {
            newsletter: {
                name: 'newsletter',
                title: 'forms.registerForm.notifications.newsletter.title',
                description: 'forms.registerForm.notifications.newsletter.description',
                connected: false
            },
            smsDeals: {
                name: 'smsDeals',
                title: 'forms.registerForm.notifications.smsDeals.title',
                description: 'forms.registerForm.notifications.smsDeals.description',
                connected: false
            },
            whatsapp: {
                name: "whatsapp",
                title: 'forms.registerForm.notifications.whatsapp.title',
                description: 'forms.registerForm.notifications.whatsapp.description',
                connected: false
            }
        };
    }
    return EditProfile;
}());
export default EditProfile;
