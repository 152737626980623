import AbstractView from 'abstract';
import Vue from 'vue'
import ConfirmationPageInit from 'initializations/pages/Confirmation'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
      ConfirmationPageInit.init("confirmationPage")

      const ConfirmationPage = () => import(/* webpackChunkName: "confirmation-page" */ 'containers/Pages/Confirmation')
      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(ConfirmationPage)
      })
    }
}
