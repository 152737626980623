import AbstractView from 'abstract';
import Vue from 'vue'
import UnsubscribeInit from 'initializations/pages/Unsubscribe'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
      UnsubscribeInit.init("unsubscribe")
      const UnsubscribePage = () => import(/* webpackChunkName: "unsubscribe-page" */ 'containers/Pages/Unsubscribe')

      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(UnsubscribePage)
      })
    }

}
