const defaultState = {
  pageTitle: '',
  description: '',
  activeStep: 'step1',
  countries: [],
  bookingUrl: '',
  airports: []
}

const Generic = {
  namespaced: true,
  state: defaultState,
  actions: {
    setGenericData ({ commit }, data) {
      commit('setPageTitle', data.title)
      commit('setDescription', data.description)
      commit('setBookingUrl', data.bookingUrl)
    },
    setAirports ({ commit }, airports) {
      const data = []
      airports.map((airport) => {
        const airportName = airport.airportName === null ? airport.airportCode + ' - Unknown' : airport.airportCode + ' - ' + airport.airportName
        const cityName = airport.cityName === null ? '' : ', ' +airport.cityName

        data.push({
          value: airport.airportCode,
          label: airportName + cityName,
          cityName: airport.cityName,
          countryCode: airport.countryCode,
          countryName: airport.countryName,
          airportName: airport.airportName
        })
      })

      commit('setAirports', data)
    },
    setCountries ({ commit }, countries) {
      const data = []
      countries.map((country) => {
        data.push({
          value: country.value,
          title: country.title,
          dialingCode: country.dialingCode
        })
      })

      commit('setCountries', data)
    }
  },
  mutations: {
    setAirports (state, value) {
      state.airports = value
    },
    setCountries (state, value) {
      state.countries = value
    },
    setPageTitle (state, value) {
      state.pageTitle = value
    },
    setDescription (state, value) {
      state.description = value
    },
    setActiveStep(state, value) {
      state.activeStep = value
    },
    setBookingUrl (state, value) {
      state.bookingUrl = value
    }
  }
}

export default Generic
