var DestinationSiloItem = /** @class */ (function () {
    function DestinationSiloItem() {
        this.code = '';
        this.city = '';
        this.href = '';
        this.image = '';
        this.country = '';
        this.countryCode = '';
        this.labels = [];
        this.latitude = 0;
        this.longitude = 0;
        this.viaPoint = false;
    }
    return DestinationSiloItem;
}());
export default DestinationSiloItem;
