<template>
  <div class="header">
    <div
      v-if="showBackdrop && isDesktop"
      class="backdrop"
      @click="openMenu('')"
    />
    <div
      class="header-wrap-white"
      :class="{ focused: inputFocused }"
    >
      <top-bar
        :search-link="searchLink"
        @toggle-menu="toggleMenu"
      />
      <main-tabs
        :class="{ active: showBackdrop }"
        :main-menu="mainMenu"
        :active-item="activeItemKey"
        :menu-open="menuOpen"
        @open-menu-item="openMenu"
        @open-tab="openTab"
      />

      <template v-if="menuOpen">
        <navigation
          v-if="!isDesktop"
          :active-item="activeItem"
          :show-backdrop="showBackdrop"
          @toggle-menu="toggleMenu"
          @open-item="openItem"
          @focus-input="focusInput"
          @close-backdrop="closeBackdrop"
        />
        <sub-menu
          v-if="isDesktop"
          :sub-menu="subMenu"
          :active-item="activeItem"
          :flight-status="flightStatusLink"
          :search-link="searchLink"
          :blog="blog"
          :ad="ad"
          @close-menu="openMenu"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import ScrollUtils from 'utils/scrollUtils'
  import { mapState, mapGetters } from 'vuex'
  import Pimcore from 'services/AirBaltic/Pimcore'
  import isObject from 'lodash-es/isObject'

  import MainTabs from 'containers/Header/Items/MainTabs'
  import TopBar from 'containers/Header/Items/TopBar'

  const Navigation = () =>
    import(
      /* webpackChunkName: "header-navigation" */ 'containers/Header/Items/Navigation'
    )
  const SubMenu = () =>
    import(/* webpackChunkName: "header-sub-menu" */ 'containers/Header/Items/SubMenu')

  export default {
    name: 'HeaderContainer',
    components: {
      MainTabs,
      TopBar,
      Navigation,
      SubMenu
    },
    data() {
      return {
        menuOpen: false,
        activeItem: '',
        inputFocused: false
      }
    },
    computed: {
      ...mapGetters('device', ['isDesktop']),
      ...mapState('device', ['deviceType']),
      ...mapState('main', ['selectedLang']),
      ...mapState('header', ['whiteHeaderLinks']),
      showBackdrop() {
        return this.menuOpen
      },
      mainMenu() {
        return this.whiteHeaderLinks?.items || []
      },
      subMenu() {
        return this.mainMenu.filter((item) => item.key === this.activeItem) || []
      },
      flightStatusLink() {
        return this.whiteHeaderLinks?.flightStatus
      },
      searchLink() {
        return this.whiteHeaderLinks?.searchPage
      },
      blog() {
        return this.whiteHeaderLinks?.blog || []
      },
      ad() {
        return this.whiteHeaderLinks?.ad || {}
      },
      activeItemKey() {
        return isObject(this.activeItem) ? this.activeItem.key : this.activeItem
      }
    },
    watch: {
      deviceType() {
        this.openMenu('')
      }
    },
    created() {
      new Pimcore()
        .get(`/get-white-header-links/${this.selectedLang}`)
        .then(({ data }) => this.$store.commit('header/setWhiteHeaderLinks', data))
    },
    methods: {
      openTab() {
        this.menuOpen = false
      },
      openMenu(label) {
        this.openItem(label)

        if (!this.menuOpen) {
          this.menuOpen = true
        }
        if (label === '') {
          this.menuOpen = false
        }
      },
      openItem(key) {
        this.activeItem = key
      },
      focusInput() {
        ScrollUtils.disableScroll()
        this.inputFocused = true
      },
      focusoutInput() {
        ScrollUtils.enableScroll()
        this.inputFocused = false
      },
      disableUserScroll() {
        if (!this.isDesktop) {
          ScrollUtils.disableScroll()
        }
      },
      closeBackdrop() {
        ScrollUtils.enableScroll()

        if (this.inputFocused) {
          this.focusoutInput()
          return
        }

        this.showOffers = false
        this.showLangSwitcher = false
        this.showProfile = false
        this.menuOpen = false
      },
      toggleMenu() {
        if (!this.menuOpen) {
          this.disableUserScroll()
        } else {
          ScrollUtils.enableScroll()
        }

        if (this.inputFocused) {
          this.focusoutInput()
          return
        }

        this.menuOpen = !this.menuOpen
        this.openItem('')
      }
    }
  }
</script>
<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .header {
    position: sticky;
    top: 0;
    z-index: 4;
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.64);
  }

  .header-wrap-white {
    background: white;

    @include bp-mf-desktop {
      position: relative;
    }

    &.focused {
      .backdrop {
        z-index: 5;
      }

      ::v-deep .search-wrapper {
        background: white;
        width: 100%;
        max-width: unset;
        z-index: 6;
      }

      ::v-deep .close-wrapper {
        width: 56px;
      }

      ::v-deep .nav {
        z-index: 3 !important;
      }
    }
  }
</style>
