import AbstractView from 'abstract'
import Vue from 'vue'
import RegisterProfileInit from 'initializations/pages/Pax/RegisterProfile'

import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init () {
      RegisterProfileInit.init("registerProfile")

      const RegisterProfile = () => import(/* webpackChunkName: "register-profile-page" */ 'containers/Pages/Profile/Register')

      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(RegisterProfile)
      })
    }
}
