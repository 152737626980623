<template>
  <header class="header">
    <a
      href="#mainAnchor"
      class="skip-to-main-content-link"
    >
      {{ $t('reusable.skip_to_main') }}
    </a>
    <div
      v-if="showBackdrop && isDesktop"
      class="backdrop"
      @click="closeMenu"
    />
    <div
      class="header-wrap-white storyblok"
      :class="{ focused: inputFocused }"
    >
      <top-bar @toggle-menu="toggleMenu" />
      <main-tabs
        :class="{ active: showBackdrop }"
        :main-menu="mainMenu"
        :active-menu-item="activeMenuItem"
        :menu-open="menuOpen"
        @open-menu-item="openMenu"
        @open-tab="closeMenu"
      />

      <template v-if="menuOpen">
        <navigation
          v-if="!isDesktop"
          :active-item="activeMenuItem"
          :show-backdrop="showBackdrop"
          @toggle-menu="toggleMenu"
          @open-item="openItem"
          @focus-input="focusInput"
          @focusout-input="focusoutInput"
          @close-backdrop="closeBackdrop"
        />
        <sub-menu
          v-else
          :active-menu-item="activeMenuItem"
          @close-menu="closeMenu"
        />
      </template>
    </div>
    <campaign-counter v-if="!hideCounter && campaignCounter.active && !menuOpen" />
  </header>
</template>

<script>
  import ScrollUtils from 'utils/scrollUtils'
  import { mapState, mapGetters } from 'vuex'

  import MainTabs from 'containers/Header/Storyblok/MainTabs'
  import TopBar from 'containers/Header/Storyblok/TopBar'

  const Navigation = () =>
    import(
      /* webpackChunkName: "header-storyblok-navigation" */ 'containers/Header/Storyblok/Navigation'
    )
  const SubMenu = () =>
    import(
      /* webpackChunkName: "header-storyblok-sub-menu" */ 'containers/Header/Storyblok/SubMenu'
    )

  const CampaignCounter = () =>
    import(
      /* webpackChunkName: "header-storyblok-campaign-counter" */ 'containers/Header/Storyblok/CampaignCounter'
    )

  import CookieUtils from 'utils/CookieUtils'

  export default {
    name: 'HeaderContainer',
    components: {
      MainTabs,
      TopBar,
      Navigation,
      SubMenu,
      CampaignCounter
    },
    data() {
      return {
        menuOpen: false,
        activeMenuItem: '',
        inputFocused: false
      }
    },
    computed: {
      ...mapGetters('device', ['isDesktop']),
      ...mapState('device', ['deviceType']),
      ...mapState('header', ['whiteHeaderLinks', 'campaignCounter']),
      showBackdrop() {
        return this.menuOpen
      },
      mainMenu() {
        return this.whiteHeaderLinks?.items || []
      },
      hideCounter() {
        return CookieUtils.getCookie('campaignCounter') === 'true'
      }
    },
    watch: {
      deviceType() {
        this.closeMenu()
      }
    },
    methods: {
      closeMenu() {
        this.menuOpen = false
        this.activeMenuItem = ''
      },
      openMenu(item) {
        this.openItem(item)

        if (!this.menuOpen) {
          this.menuOpen = true
        }
        if (item === '') {
          this.menuOpen = false
        }
      },
      openItem(key) {
        this.activeMenuItem = key
      },
      focusInput() {
        ScrollUtils.disableScroll()
        this.inputFocused = true
      },
      focusoutInput() {
        ScrollUtils.enableScroll()
        this.inputFocused = false
      },
      disableUserScroll() {
        if (!this.isDesktop) {
          ScrollUtils.disableScroll()
        }
      },
      closeBackdrop() {
        ScrollUtils.enableScroll()

        if (this.inputFocused) {
          this.focusoutInput()
          return
        }

        this.showOffers = false
        this.showLangSwitcher = false
        this.showProfile = false
        this.menuOpen = false
      },
      toggleMenu() {
        if (!this.menuOpen) {
          this.disableUserScroll()
        } else {
          ScrollUtils.enableScroll()
        }

        if (this.inputFocused) {
          this.focusoutInput()
          return
        }

        this.menuOpen = !this.menuOpen
        this.openItem('')
      }
    }
  }
</script>
<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .header {
    position: sticky;
    top: 0;
    z-index: 4;
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.64);
  }

  .header-wrap-white {
    background: white;

    @include bp-mf-desktop {
      position: relative;
    }

    &.focused {
      .backdrop {
        z-index: 5;
      }

      ::v-deep .search-wrapper {
        background: white;
        width: 100%;
        max-width: unset;
        z-index: 6;
      }

      ::v-deep .close-wrapper {
        width: 56px;
      }

      ::v-deep .nav {
        z-index: 3 !important;
      }
    }
  }

  .skip-to-main-content-link {
    position: absolute;
    left: -9999px;
    z-index: 999;
    opacity: 0;
    padding: 8px 16px;
    background: $green-base;
    color: $blue-dark;
    border-radius: 4px;
    min-height: 40px;
    width: auto;
    text-decoration: none;
    font-weight: 500;
    line-height: rem(26, 16);
  }
  .skip-to-main-content-link:focus {
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }

  .skip-to-main-content-link:hover {
    background: $green-hover-light;
  }
</style>
