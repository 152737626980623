import * as Sentry from "@sentry/browser"
import PaxProfile from "services/AirBaltic/Pax/Profile"
import PaxLogin from "services/AirBaltic/Pax/Login"
import PaxLogout from "services/AirBaltic/Pax/Logout"
import JwtUtils from "utils/JwtUtils"
import moment from "moment"
import conf from "conf"
import get from "lodash-es/get"

const loginUser = () => {
  return new PaxLogin()
    .doPimcoreLoginPing()
    .then(() => Promise.resolve())
    .catch(e => {
      Sentry.captureException(e)
      return Promise.reject()
    })
}

export function getExpirationTime() {
  const jwt = JwtUtils.getJwtContentFromCookie(conf.jwtCookieName)

  if (jwt) {
    let comparedTime = moment()
    const refreshTimer = get(conf, 'jwtRefreshTime', 0)

    if (refreshTimer > 0) {
      comparedTime = moment().add(55, 'minutes')
    }

    return Math.round(moment.unix(jwt.exp).diff(comparedTime))
  }

  return 0
}

export function renewUserToken() {
  return new PaxProfile()
    .renewUserToken()
    .then(() => loginUser())
    .catch(e => {
      Sentry.captureException(e)
      return Promise.reject(e)
    })
}

export function getRefreshToken() {
  return window.localStorage.getItem('refreshToken')
}

export function setRefreshToken(token) {
  return window.localStorage.setItem('refreshToken', token)
}

export function logoutUser () {
  return new PaxLogout().logoutUser()
}
