import Vue from 'vue'
import store from 'definitions/store'
import AbstractView from 'abstract'
import DeviceMixin from 'containers/DeviceMixin'
import mainInit from 'initializations/Main'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        mainInit.init("main")

        new Vue({
            mixins: [DeviceMixin],
            store
        }).$mount('#header')
    }
}
