import { betweenLength, isDate12HoursBefore, isDateAfter, isValidDate, regex } from 'utils/validators';
import { required } from 'vuelidate/lib/validators';
var minLength = 3;
var maxLength = 6;
var FlightDetails = /** @class */ (function () {
    function FlightDetails(obj) {
        if (obj === void 0) { obj = {}; }
        this.flightDate = '';
        this.flightNumber = '';
        this.errorMsg = {
            flightDate: {
                date12HoursBefore: 'reusable.flightDate.validation.date12HoursBefore',
                dateAfterToday: 'reusable.flightDate.validation.dateAfterToday',
                isValidDate: 'reusable.flightDate.validation.invalid',
                required: 'reusable.flightDate.validation.required',
            },
            flightNumber: {
                between: 'reusable.flightNumber.validation.between',
                flightNumberInvalid: 'reusable.flightNumber.validation.invalid',
                required: 'reusable.flightNumber.validation.required',
            },
        };
        this.validation = {
            form: {
                flightDate: {
                    date12HoursBefore: isDate12HoursBefore,
                    dateAfterToday: isDateAfter,
                    isValidDate: isValidDate,
                    required: required,
                },
                flightNumber: {
                    between: betweenLength(minLength, maxLength),
                    flightNumberInvalid: regex('flightNumber'),
                    required: required,
                },
            },
        };
        if (Object.keys(obj).length > 0) {
            Object.assign(this, obj);
        }
    }
    return FlightDetails;
}());
export default FlightDetails;
