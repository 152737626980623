export default {
  namespaced: true,
  state: {
    pageData: null,
    intCodeList: []
  },
  mutations: {
    setPageData(state, value) {
      state.pageData = value
    },
    setIntCodeList(state, value) {
      state.intCodeList = value
    }
  }
}
