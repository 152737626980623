import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import storeInit from 'initializations/pages/RewardRequest'
import SidebarInit from 'initializations/Sidebar'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    SidebarInit.init("sidebar")
    storeInit.init("rewardRequest")

    const RewardRequest = () => import(/* webpackChunkName: "repat-form-page" */ 'containers/Pages/RewardRequest/RewardRequest')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(RewardRequest)
    })
  }
}
