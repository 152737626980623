import Generic from 'store/modules/Pages/GroupBooking/Generic'
import Step1 from 'store/modules/Pages/GroupBooking/Steps/Step1'
import Step2 from 'store/modules/Pages/GroupBooking/Steps/Step2'
import Step3 from 'store/modules/Pages/GroupBooking/Steps/Step3'
import Step4 from 'store/modules/Pages/GroupBooking/Steps/Step4'
import GroupBookingService from 'services/AirBaltic/Forms/GroupBooking'

const GroupBooking = {
  modules: {
    generic: Generic,
    step1: Step1,
    step2: Step2,
    step3: Step3,
    step4: Step4
  },
  state: {
    pageData: {
      title: '',
      intro: '',
      description: '',
      mobileImg: '',
      tabletImg: '',
      desktopImg: '',
      bookDates: '',
      travelDates: '',
      openFormLink: {
        href: '',
        text: ''
      },
      features: {},
      steps: {},
      offers: []
    },
    contacts: {},
    termsAndConditions: {}
  },
  mutations: {
    setPageData (state, value) {
      state.pageData = value
    },

    setTermsAndConditions (state, value) {
      state.termsAndConditions = value
    },

    setContacts (state, value) {
      state.contacts = value
    }
  },
  actions: {
    getDataAndSubmitForm ({ dispatch }) {
      const steps = [
        dispatch('step1/getData'),
        dispatch('step2/getData'),
        dispatch('step3/getData'),
        dispatch('step4/getData'),
        dispatch('step4/getOtherData')
      ]

      return Promise.all(steps)
        .then((data) => {
          const req = {
            flightType: data[0].flightType,
            origin: data[0].origin,
            destin: data[0].destin,
            departureFlights: data[1].departureFlights,
            returnFlights: data[1].returnFlights,
            passengerCount: data[0].passengerCount,
            flexible: data[0].flexible,
            departureDate: data[0].departureDate,
            returnDate: data[0].returnDate,
            preferredPrice: data[2].preferredPrice,
            travelExtras: data[2].travelExtras,
            message: data[2].message,
            contact: data[3]
          }

          if (data[4].contactType === 'agency') {
            req.handledByBt = data[4].handling
            req.amadeusId =  data[4].id
            req.country = data[4].country
            req.agencyName = data[4].agencyName

            return new GroupBookingService().submitAgencyForm(req)
              .then(() => Promise.resolve())
              .catch((e) => Promise.reject(e))
          } else {
            return new GroupBookingService().submitForm(req)
              .then(() => Promise.resolve())
              .catch((e) => Promise.reject(e))
          }
        })
        .catch((e) => Promise.reject({ type: 'service', data: e }))
    }
  },
  namespaced: true
}

export default GroupBooking
