import CookieUtils from 'utils/CookieUtils'

const LocationForm = {
  state: {
    countries: [],
    languageLinks: [],
    countrySwitcherFn: null,
    languageSwitcherFn: null
  },
  mutations: {
    setCountryList (state, value) {
      state.countries = value
    },
    setLanguageLinks (state, value) {
      state.languageLinks = value
    },
    setCountrySwitcherFn (state, value) {
      state.countrySwitcherFn = value
    },
    setLanguageSwitcherFn (state, value) {
      state.languageSwitcherFn = value
    }
  },
  actions: {
    formatCountries ({ commit }, data) {
      const formatted = data.map(item => ({
        name: 'countries.' + item,
        code: item
      }))
      commit('setCountryList', formatted)
    },
    setSelectedCountry ({ commit }, value) {
      commit('main/selectCountry', value, { root: true })
      CookieUtils.setCookie('country', value.toUpperCase())
    },
    setSelectedLang ({ commit }, value) {
      commit('main/selectLang', value, { root: true })
      CookieUtils.setCookie('language', value)
    }
  },
  namespaced: true
}

export default LocationForm
