import AuthorizedApi from 'services/AirBaltic/AuthorizedApi'
import ErrorHandler from 'utils/ErrorHandler'

const ERRORS = {
  WRONG_USERNAME_OR_PASSWORD: 'forms.modals.editProfile.errors.oldPasswordIncorrect'
}

class Password {
  change (data) {
    return new AuthorizedApi().post('/profile/edit/changePassword', data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(this.getErrors(error.response)))
  }

  getErrors (response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default Password
