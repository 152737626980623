import { email, required, minLength } from 'vuelidate/lib/validators';
var Login = /** @class */ (function () {
    function Login() {
        this.form = {
            email: '',
            password: ''
        };
        this.errorMsg = {
            email: {
                required: 'header.loginForm.email.errors.missingEmail',
                email: 'header.loginForm.email.errors.invalidEmail',
            },
            password: {
                required: 'header.loginForm.password.errors.missingPassword',
                minLength: 'header.loginForm.password.errors.invalidPassword'
            },
        };
        this.validations = {
            form: {
                email: {
                    required: required,
                    email: email
                },
                password: {
                    required: required,
                    minLength: minLength(5)
                }
            }
        };
    }
    return Login;
}());
export default Login;
