import get from 'lodash-es/get'
import CookieUtils from 'utils/CookieUtils'
import FormMixin from 'components/FormMixin'
import PaxPins from 'services/AirBaltic/Pax/Pins'
import PaxLogin from 'services/AirBaltic/Pax/Login'
import FormNotification from 'components/Notifications/FormNotification'
import divolteProfile from 'services/AirBaltic/Divolte/Profile'

const PinsMixin = {
  data() {
    return {
      loginService: null,
      pinsService: null
    }
  },
  mixins: [FormMixin],
  methods: {
    openPinsNotification(modalStatus, successMsg = null, errorMsg = null) {
      this.$store.dispatch('main/openModal', { root: true })
      this.$store.commit('main/setModalContent', FormNotification, { root: true })
      this.$store.commit('main/setModalCloseFn', this.closeModal, { root: true })
      this.$store.commit('main/setModalType', 'small')
      this.$store.commit('main/setModalClass', 'modal-white-small')
      this.$store.commit('main/setShowModalHeader', false)
      this.$store.commit('main/setShowModalHeaderWhite', false)
      this.$store.commit('main/setShowCloseIcon', true)
      this.$store.commit('main/setModalProps', {
        status: modalStatus,
        successMessage: successMsg,
        errorMessage: errorMsg,
        showTitle: false
      })
    },
    getPaxPinsService() {
      if (!this.pinsService) {
        this.pinsService = new PaxPins()
      }

      return this.pinsService
    },
    getPaxLoginService() {
      if (!this.loginService) {
        this.loginService = new PaxLogin()
      }

      return this.loginService
    },
    pinsLogin(postObject) {
      const loginData = {
        ...postObject,
        lang: this.$store.state.main.selectedLang.toUpperCase()
      }
      return new Promise((resolve, reject) => {
        this.getPaxLoginService()
          .doPinsLogin(loginData)
          .then((userData) =>
            this.pimcoreLogin(userData)
              .then((res) => resolve(res))
              .catch((error) => reject(error))
          )
          .catch((error) => {
            this.$emit('fail')
            reject(error)
          })
      })
    },
    pinsData(postObject) {
      const loginData = {
        ...postObject,
        lang: this.$store.state.main.selectedLang.toUpperCase()
      }
      return new Promise((resolve, reject) => {
        this.getPaxPinsService()
          .getDataFromLogin(loginData)
          .then((userData) => resolve(userData))
          .catch((error) => {
            this.$emit('fail')
            reject(error)
          })
      })
    },
    pimcoreLogin(userData) {
      return new Promise((resolve, reject) => {
        this.getPaxLoginService()
          .doPimcoreLoginPing()
          .then(() => {
            this.doPinsLoginDataLayerPush()
            this.setStoreData(userData.userData)
            this.$emit('success')
            resolve(true)
          })
          .catch(() => {
            this.$emit('fail')
            reject('generic.errors.serverError')
          })
      })
    },
    setStoreData(user) {
      this.$store.dispatch('main/user/init', user)
      divolteProfile.login(user)
      this.$store.dispatch('main/closeModal')
    },
    doPinsLoginDataLayerPush() {
      CookieUtils.setCookie('loginMethod', 'pins', 1)

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'loginEvent',
        eventCategory: 'All pages',
        eventAction: 'Login',
        eventLabel: 'Method: pins; Page: ' + window.location.href,
        nonInteraction: true,
        authMethod: 'pins'
      })
    },
    setPinsData(
      connected = false,
      pinsData = {},
      pinsCollected = null,
      pinsNumber = null
    ) {
      this.$store.commit('main/user/setPinsConnected', connected)
      this.$store.commit('pins/setPinsData', pinsData)
      if (pinsNumber) {
        this.$store.commit(`pins/setPinsNumber`, pinsNumber)
      }
      if (pinsCollected) {
        this.$store.commit('pins/setPinsCollected', pinsCollected)
      }
    },
    connectPinsCard(postObject) {
      return new Promise((resolve, reject) => {
        this.getPaxPinsService()
          .connectPins(postObject)
          .then((user) => {
            const pinPoints = get(user, 'data.balance', 0)
            const pinsNumber = get(user, 'data.number', null)
            this.setPinsData(true, user.data, pinPoints, pinsNumber)
            resolve(user)
          })
          .catch((error) => reject(error))
      })
    },
    disconnectPinsCard() {
      return new Promise((resolve, reject) => {
        this.getPaxPinsService()
          .disconnectPins()
          .then((response) => {
            this.setPinsData()
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    }
  }
}

export default PinsMixin
