import Vue from 'vue'
import SignUp from 'services/AirBaltic/Pax/SignUp'
import { validationMixin } from 'vuelidate'
import { required, email, minLength, sameAs, not } from 'vuelidate/lib/validators'
import {
  containsSpecialChar,
  containsUpperChar,
  containsLowerChar,
  containsNumber,
  containsForbidden,
  containsSpaces
} from 'utils/validators'
import divolteProfile from 'services/AirBaltic/Divolte/Profile'

const defaultState = {
  pageTitle: '',
  benefitsListTitle: '',
  benefitsList: '',
  modalTitle: '',
  modalContent: '',
  email: '',
  password: '',
  showPassword: false,
  editMyProfileUrl: '#',
  modalType: '',
  modalFailTitle: '',
  modalFailContent: '',
  modalExistsTitle: '',
  modalExistsContent: '',
  signUpConfirmationUrl: '',
  loginUrl: '',
  loaderBackground: ''
}

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations () {
    return {
      state: {
        email: {
          required,
          email
        },
        password: {
          required,
          minLength: minLength(8),
          notSameAsEmail: not(sameAs('email')),
          containsSpecialChar,
          containsUpperChar,
          containsLowerChar,
          containsNumber,
          containsForbidden: not(containsForbidden),
          containsSpaces: not(containsSpaces)
        }
      }
    }
  }
})

const CreateProfile = {
  state: defaultState,
  getters: {
    $v () {
      return Object.assign({}, validator.$v)
    }
  },
  mutations: {
    setPageTitle (state, value) {
      state.pageTitle = value
    },
    setBenefitsListTitle (state, value) {
      state.benefitsListTitle = value
    },
    setBenefitsList (state, value) {
      state.benefitsList = value
    },
    setModalTitle (state, value) {
      state.modalTitle = value
    },
    setModalContent (state, value) {
      state.modalContent = value
    },
    setPassword (state, value) {
      state.password = value
    },
    setEmail (state, value) {
      state.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setShowPassword (state, value) {
      state.showPassword = value
    },
    setEditMyProfileUrl (state, value) {
      state.editMyProfileUrl = value
    },
    setModalType (state, value) {
      state.modalType = value
    },
    setModalFailTitle (state, value) {
      state.modalFailTitle = value
    },
    setModalFailContent (state, value) {
      state.modalFailContent = value
    },
    setModalExistsTitle (state, value) {
      state.modalExistsTitle = value
    },
    setModalExistsContent (state, value) {
      state.modalExistsContent = value
    },
    setSignUpConfirmationUrl (state, value) {
      state.signUpConfirmationUrl = value
    },
    setLoginUrl (state, value) {
      state.loginUrl = value
    },
    setLoaderBackground (state, value) {
      state.loaderBackground = value
    }
  },

  actions: {
    setPassword ({ commit }, data) {
      commit('setPassword', data)
      validator.$v.state.password.$touch()
    },
    setEmail ({ commit }, data) {
      commit('setEmail', data)
      validator.$v.state.email.$touch()
    },

    clearForm ({ commit }) {
      validator.$v.state.$reset()

      commit('setPassword', '')
      commit('setEmail', '')
    },

    validate () {
      return new Promise((resolve, reject) => {
        validator.$v.state.email.$touch()
        validator.$v.state.password.$touch()
        if (!validator.$v.state.$invalid) {
          resolve(true)
        } else {
          reject(false)
        }
      })
    },

    submitForm ({ state, rootState }) {
      return new Promise((resolve, reject) => {
        SignUp.sendEmail({
          email: state.email,
          password: state.password,
          language: rootState.main.selectedLang.toUpperCase(),
          profileCreateLink: state.signUpConfirmationUrl,
          forgotPasswordResetLink: rootState.loginForm.forgotPassword.url
        })
        .then(() => {
          divolteProfile.register(state.email)
          return resolve(true)
        })
        .catch(() => reject(false))
      })
    },

    redirectToEditProfile ({ state }) {
      window.location = state.editMyProfileUrl
    }
  },
  namespaced: true
}

export default CreateProfile
