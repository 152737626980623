import UrlParams from 'utils/UrlParams'
import GTM from 'services/AirBaltic/GTM'
import Vue from 'vue'

class BestBuyBanners {
  static push (data) {
    GTM.doDataLayerPush(data)
  }

  static click (banner, pageNumber) {
    const lang = Vue.i18n.locale()
    const pos = UrlParams.getUrlParam(banner.url, 'pos')
    const od = UrlParams.getUrlParam(banner.url, 'originCode') + '-' + UrlParams.getUrlParam(banner.url, 'destinCode')
    const price = banner.price
    const oDate = UrlParams.getUrlParam(banner.url, 'startDate')
    const page = pageNumber + 1

    this.push({
      event: 'GAevent',
      eventCategory: 'Frontpage',
      eventAction: 'Cheap flights block (BB)',
      eventLabel: 'POS: ' + pos + '; OD: ' + od + '; Price: ' + price + '; Outb date: ' + oDate + '; Page: ' + page + '; Lang: ' + lang,
      nonInteraction: 0
    })
  }

  static pageChange (page, pos) {
    const lang = Vue.i18n.locale()

    this.push({
      event: 'GAevent',
      eventCategory: 'Frontpage',
      eventAction: 'Cheap flights block (BB)',
      eventLabel: 'POS: ' + pos + '; A:Page change; Page: ' + (page + 1) + '; Lang: ' + lang,
      nonInteraction: 0

    })
  }

}

export default BestBuyBanners
