import FlightModel from 'models/Forms/Sections/Flights';
import moment from 'moment';
var PnrGetFlightData = /** @class */ (function () {
    function PnrGetFlightData() {
    }
    PnrGetFlightData.getFlightData = function (response) {
        var flightData = [];
        var data = {
            flightData: {
                flightNumber: '',
                flightDate: '',
                origin: '',
                destin: '',
                carrier: '',
                number: 0
            },
            flightDate: {
                flightDay: '',
                flightMonth: '',
                flightYear: ''
            }
        };
        response.map(function (flight) {
            var obj = Object.assign({}, data);
            obj.flightData = {
                flightNumber: flight.carrier + flight.number,
                flightDate: moment().format(flight.departureDate),
                origin: flight.origin,
                destin: flight.destin,
                carrier: flight.carrier,
                number: flight.number
            };
            var flightModel = new FlightModel(obj.flightData);
            obj.flightDate = {
                flightDay: moment(flightModel.flightDate).format('DD'),
                flightMonth: moment(flightModel.flightDate).format('MM'),
                flightYear: moment(flightModel.flightDate).format('YYYY')
            };
            flightData.push(Object.assign({}, flightModel, obj.flightDate));
        });
        if (!response.length) {
            var flightModel = new FlightModel(data.flightData);
            flightData.push(Object.assign({}, flightModel, data.flightDate));
        }
        return flightData;
    };
    return PnrGetFlightData;
}());
export default PnrGetFlightData;
