import HelpAndContactStore from 'store/modules/Pages/HelpAndContact'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, HelpAndContactStore)

      Init.initDefaultValues(name)
      Init.initIntCountryCodes(name)
    }
  },

  initDefaultValues(name) {
    if (typeof pageData !== "undefined") {
      store.dispatch(`${name}/setPageData`, pageData)
    }
  },

  initIntCountryCodes(name) {
    if (typeof contentData_data_countries !== "undefined") {
      store.dispatch(`${name}/setCountries`, contentData_data_countries._children, { root: true })
    }
  }
}

export default Init
