import AxiosUtils from 'utils/AxiosUtils'
import ScrollUtils from 'utils/scrollUtils'

const ESC_KEYCODE = 27

const ModalMixin = {
  methods: {
    closeOnEscKey (e) {
      if (e.keyCode === ESC_KEYCODE) {
        this.onModalCloseClicked()
      }
    },

    openModal (isFullUrl) {
      this.getModalContent(this.modalContentUrl, isFullUrl)
        .then(() => {
          window.addEventListener('keyup', this.closeOnEscKey)
          this.isModalVisible = true
          this.isVisible = false
          this.$store.dispatch('main/openModal')
          this.$store.commit('main/setModalCloseFn', this.onModalCloseClicked)
        })
    },

    closeModal () {
      this.$store.dispatch('main/closeModal')
      ScrollUtils.enableScroll()
    },

    onModalCloseClicked () {
      window.removeEventListener('keyup', this.closeOnEscKey)
      this.isModalVisible = false
      this.$store.dispatch('main/closeModal')
    },

    getModalContent (url, isFullUrl) {
      return new Promise((resolve) => {
        if (this.modalContent === '') {
          if (url !== '' && url.indexOf('tooltip.modal.url') === -1) {
            const postObject = {
              responseAction: 'JSON'
            }

            AxiosUtils.post(postObject, url, isFullUrl)
              .then((response) => {
                if (response.data.content) {
                  this.$store.commit('main/setShowModalHeader', true)
                  this.$store.commit('main/setModalTitle', response.data.title)
                  this.$store.commit('main/setModalContent', response.data.content)
                } else {
                  this.$store.commit('main/setModalContent', response.data)
                }

                resolve(response)
              })
              .catch(() => {})
          }
        } else {
          resolve()
        }
      })
    },

    getModalContentAndReturn (url, isFullUrl) {
      return new Promise((resolve) => {
        if (url !== '' && url.indexOf('tooltip.modal.url') === -1) {
          const postObject = {
            responseAction: 'JSON'
          }

          AxiosUtils.post(postObject, url, isFullUrl).then((response) => {
            resolve(response)
          })
            .catch(() => {})
        }
      })
    }
  }
}

export default ModalMixin
