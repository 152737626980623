import GTM from 'services/AirBaltic/GTM'
import Vue from 'vue'

class MMB {
  static push (data) {
    GTM.doDataLayerPush(data)
  }

  static tabClick (tabName) {
    let event = ''

    switch (tabName) {
      case 'FLT':
        event = 'Search Flights'
        break
      case 'MMB':
        event = 'MMB'
        break
      case 'CKI':
        event = 'CKIN'
        break
      case 'car':
        event = 'Car rental'
        break
      case 'hotel':
        event = 'Hotel'
        break
      default:
        event = tabName
    }

    this.push({
      event: 'GAevent',
      eventCategory: 'Frontpage',
      eventAction: 'Top form tab click',
      eventLabel: event + '; Lang: ' + Vue.i18n.locale(),
      nonInteraction: 0
    })
  }
}

export default MMB
