import Vue from 'vue'
import Vuex from 'vuex'
import 'moment/locale/et'
import 'moment/locale/fi'
import 'moment/locale/lv'
import 'moment/locale/ru'
import 'moment/locale/lt'
import 'moment/locale/de'
import deviceInit from 'initializations/Device'
import IndexTabsStore from 'initializations/eHorizon/IndexTabs'

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production'
})

deviceInit.init(store, "device")
IndexTabsStore.init(store, "indexTabs")

export default store
