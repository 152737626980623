import Request from 'services/Request'
import CookieUtils from 'utils/CookieUtils'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'
import conf from 'conf'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import store from 'definitions/store'

const REQUEST_TIMEOUT = 60000

export default class AuthorizedApi extends Request {
  getBaseUrl() {
    return conf.btApiUrl
  }

  createRequest() {
    const request = axios.create({
      timeout: REQUEST_TIMEOUT,
      withCredentials: true,
      responseType: 'json',
      responseEncoding: 'utf8',
      baseURL: this.getBaseUrl(),
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + CookieUtils.getCookie(conf.jwtCookieName)
      }
    })

    const refreshAuthLogic = (failedRequest) => {
      if (failedRequest.response.config.url === '/profile/logout') {
        return Promise.reject('failedLogout')
      }

      if (store && failedRequest.response.config.url !== '/profile/v3/login/refresh') {
        return store
          .dispatch('main/user/renewToken')
          .then(() => {
            return Promise.resolve()
          })
          .catch((e) => {
            return store.dispatch('main/user/logout').then(() => {
              return Promise.reject('doLogout')
            })
          })
      } else {
        return store.dispatch('main/user/logout').then(() => {
          return Promise.reject('doLogout')
        })
      }
    }

    createAuthRefreshInterceptor(request, refreshAuthLogic)

    request.interceptors.request.use(
      (config) => {
        const authHeader = get(config, 'headers.common.Authorization')
          || get(config, 'headers.Authorization')
          || null
        if (isEmpty(authHeader) || authHeader === 'Bearer ') {
          return Promise.reject(config)
        }

        return config
      },
      (error) => Promise.reject(error)
    )

    return request
  }
}
