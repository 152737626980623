const AccordionPage = {
  state: {
    pageLeadImgDesktop: '',
    pageLeadImgTablet: '',
    pageLeadImgMobile: '',
    pageTitle: '',
    mainIntro: '',
    secondIntro: '',
    accordionItems: [],
    preAccordionImages: [],
    printEnabled: false,
    termsAndConditionsVisible: false,
    termsAndConditions: {},
    showJsonLD: false,
    showSixtWidget: false,
    sixtWidgetTitle: '',
    searchOnlySixt: false,
    additionalContent: ''
  },
  mutations: {
    setPageLeadImgDesktop(state, value) {
      state.pageLeadImgDesktop = value
    },
    setPageLeadImgTablet(state, value) {
      state.pageLeadImgTablet = value
    },
    setPageLeadImgMobile(state, value) {
      state.pageLeadImgMobile = value
    },
    setPageTitle(state, value) {
      state.pageTitle = value
    },
    setMainIntro(state, value) {
      state.mainIntro = value
    },
    setSecondIntro(state, value) {
      state.secondIntro = value
    },
    setAccordionItems(state, value) {
      state.accordionItems = value
    },
    setPrintEnabled(state, value) {
      state.printEnabled = value
    },
    setTermsAndConditionsVisible(state, value) {
      state.termsAndConditionsVisible = value
    },
    setTermsAndConditions(state, value) {
      state.termsAndConditions = value
    },
    setPreAccordionImages(state, value) {
      state.preAccordionImages = value
    },
    setShowJsonLd(state, value) {
      state.showJsonLD = value
    },
    setShowSixtWidget(state, value) {
      state.showSixtWidget = value
    },
    setSixtWidgetTitle(state, value) {
      state.sixtWidgetTitle = value
    },
    setAdditionalContent(state, value) {
      state.additionalContent = value
    },
    setSearchOnlySixt(state, value) {
      state.searchOnlySixt = value
    }
  },
  namespaced: true
}

export default AccordionPage
