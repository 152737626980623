import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import SidebarPageInit from 'initializations/pages/SidebarPage'
import SidebarInit from 'initializations/Sidebar'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        SidebarInit.init("sidebar")
        SidebarPageInit.init("sidebarPage")

        const SidebarPage = () => import(/* webpackChunkName: "container-sidebar-page" */ 'containers/Pages/Sidebar')
        new Vue({
            el: "#" + this.elemId,
            store,
            render: h => h(SidebarPage)
        })
    }
}
