import AbstractView from 'abstract';
import Vue from 'vue'
import pageInit from 'initializations/pages/404'
import store from 'definitions/store'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    pageInit.init('404')
    const page404 = () => import(/* webpackChunkName: "page404" */ 'containers/Pages/404')
    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(page404)
    })
  }
}
