import SelfCheckInStore from 'store/modules/Pages/CheckIn'
import store from 'definitions/store'
import OrigDest from 'utils/OrigDestData'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, SelfCheckInStore)

      Init.initOrigDestData(name)
      Init.initDefaultData(name)
    }
  },

  initOrigDestData(name) {
    if (typeof origDestData !== 'undefined') {
      const data = OrigDest.parseData(origDestData)

      store.commit(`${name}/setOrigDestData`, data)
    }
  },

  initDefaultData (name) {
    if (typeof pageData !== 'undefined') {
      store.commit(`${name}/setPageData`, pageData)
    }

    if (typeof pnr !== 'undefined') {
      store.commit(`${name}/setPnr`, pnr.toUpperCase())
    }

    if (typeof surname !== 'undefined') {
      store.commit(`${name}/setSurname`, surname.toUpperCase())
    }

    if (typeof indexUrl !== 'undefined') {
      store.commit(`${name}/setIndexUrl`, indexUrl)
    }
  }
}

export default Init
