import CargoShipping from 'store/modules/CargoShipping/CargoShipping'

const Sidebar = {
  modules: {
    cargoShipping: CargoShipping
  },
  state: {
    leftMenu: [],
    pleaseNoteTitle: '',
    pleaseNoteText: '',
    pressContacts: {}
  },
  mutations: {
    setLeftMenu (state, value) {
      state.leftMenu = value
    },
    setPleaseNoteTitle (state, value) {
      state.pleaseNoteTitle = value
    },
    setPleaseNoteText (state, value) {
      state.pleaseNoteText = value
    },
    setPressContacts (state, value) {
      state.pressContacts = value
    }
  },
  namespaced: true
}

export default Sidebar
