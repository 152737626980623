var Notifications = /** @class */ (function () {
    function Notifications() {
        this.newsletter = {
            connected: false
        };
        this.smsDeals = {
            connected: false
        };
        this.whatsapp = {
            connected: false
        };
    }
    return Notifications;
}());
export default Notifications;
