import { regex } from 'utils/validators';
import { maxLength, minValue, numeric, required } from 'vuelidate/lib/validators';
var Step2 = /** @class */ (function () {
    function Step2() {
        this.form = {
            citizenship: '',
            information: '',
            passengers: '',
        };
        this.errorMsg = {
            citizenship: {
                allowedCharacters: 'reusable.citizenship.validation.allowedCharacters',
                required: 'reusable.citizenship.validation.required',
            },
            information: {
                allowedCharacters: 'charterForm.step2.fields.additionalInfo.validation.allowedCharacters',
                maxLength: 'charterForm.step2.fields.additionalInfo.validation.maxLength',
            },
            passengers: {
                minValue: 'charterForm.step2.fields.passengers.validation.minValue',
                numeric: 'charterForm.step2.fields.passengers.validation.numeric',
                required: 'charterForm.step2.fields.passengers.validation.required',
            },
        };
        this.validations = {
            form: {
                citizenship: {
                    allowedCharacters: regex('alphaNumberSpaceDashCommaDot'),
                    required: required,
                },
                information: {
                    allowedCharacters: regex('alphaNumberSpaceDashCommaDot'),
                    maxLength: maxLength(1300),
                },
                passengers: {
                    minValue: minValue(20),
                    numeric: numeric,
                    required: required,
                },
            },
        };
    }
    return Step2;
}());
export default Step2;
