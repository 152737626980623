import filter from 'lodash-es/filter'
import uniq from 'lodash-es/uniq'
import uniqWith from 'lodash-es/uniqWith'
import isEqual from 'lodash-es/isEqual'
import Vue from 'vue'
import isEmpty from 'lodash-es/isEmpty'
import OrigDestUtils from 'store/utils/eHorizonOrigDestUtils'
import DropdownUtils from 'store/utils/Dropdown'
import DestinationsService from 'services/AirBaltic/Destinations/Destinations'
import { validationMixin } from 'vuelidate'
import { textInCurrentLang } from 'utils/validators'
import UrlParams from 'utils/UrlParams'
import getNearestAirport from 'utils/GetNearestAirport'
import isUndefined from 'lodash-es/isUndefined'
import cloneDeep from 'lodash-es/cloneDeep'
import findIndex from 'lodash-es/findIndex'
import find from 'lodash-es/find'
import DestinationSiloItem from 'models/Destinations/DestinationSiloItem'

const DEFAULT_AIRPORT = 'RIX'

const defaultState = {
  originInitFromUlrHappened: false,
  initFromUlrHappened: false,
  pageData: {},
  filter: '',
  countryFilter: '',
  allDestinations: [],
  selectedLabel: '',
  airport: {},
  btOrigins: [],
  allOrigins: {},
  termsAndConditionsVisible: false,
  termsAndConditions: {},
  selectedCountry: {},
  allLabels: [],
  countryFromUrl: '',
  labelOrder: '',
  withOrigin: false,
  selectedDestination: null
}

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations: {
    state: {
      filter: {
        textInCurrentLang
      },
      countryFilter: {
        textInCurrentLang
      }
    }
  }
})

const DestinationsSilo = {
  state: defaultState,
  mutations: {
    setInitFromUrlHappened (state, value) {
      state.initFromUlrHappened = value
    },
    setOriginInitFromUrlHappened (state, value) {
      state.originInitFromUlrHappened = value
    },
    setPageData (state, value) {
      state.pageData = value
    },
    setDestinations (state, value) {
      state.allDestinations = value
    },
    changeAirport (state, value) {
      state.airport = value
    },
    setSelectedLabel (state, value) {
      state.selectedLabel = value
    },
    setFilter (state, value) {
      state.filter = value
    },
    setTermsAndConditionsVisible (state, status) {
      state.termsAndConditionsVisible = status
    },
    setTermsAndConditions (state, value) {
      state.termsAndConditions = value
    },
    setAllAirportData (state, value) {
      state.allOrigins = value
    },
    setBtOrigins (state, value) {
      state.btOrigins = value
    },
    setSelectedCountry (state, value) {
      state.selectedCountry = value
    },
    setLabels (state, value) {
      state.allLabels = value
    },
    setCountryFilter (state, value) {
      state.countryFilter = value
    },
    setCountryFromUrl (state, value) {
      state.countryFromUrl = value
    },
    setLabelOrder (state, value) {
      state.labelOrder = value
    },
    withOrigin (state, value) {
      state.withOrigin = value
    },
    selectDestination (state, value) {
      state.selectedDestination = value
    }
  },

  getters: {
    $v () {
      return Object.assign({}, validator.$v.state)
    },
    labels (state) {
      let mappedLabels = []
      let filteredDestinations = state.allDestinations

      const selectedCountry = state.selectedCountry?.value || null

      if (selectedCountry !== null ) {
        filteredDestinations = filteredDestinations.filter(o => o.countryCode === state.selectedCountry.value)
      }

      let availableLabels = filteredDestinations.map(({ labels }) => labels)

      availableLabels = uniq([].concat(...availableLabels))
      availableLabels.forEach((label) => {
        const translationKey = `destinationLabels.${label.toLowerCase()}`
        const hasLocalTranslation = Vue.i18n.translate(translationKey) !== '' && Vue.i18n.translate(translationKey) !== translationKey
        mappedLabels.push({
          key: label,
          title: hasLocalTranslation ? Vue.i18n.translate(translationKey) : state.allLabels[`${label}`]
        })
      })

      mappedLabels = OrigDestUtils.sortLabelsBySpecificOrder(mappedLabels,  state.labelOrder)

      // Removed as requested BTDEV-1670
      return mappedLabels.filter(o => o.key !== 'label_all' && o.key !== 'label_bt_destination')
    },
    isHomeAirportSelected (state) {
      const currentAirportCode = state.airport?.code || null

      return isEmpty(state.airport) || currentAirportCode === DEFAULT_AIRPORT
    },
    countries (state) {
      let countries = []

      state.allDestinations.forEach((o) => {
        countries.push({ value: o.countryCode, title: o.country })
      })

      countries = DropdownUtils.filterData(state.countryFilter, uniqWith(countries, isEqual))
      if (countries.length > 0) {
        countries.unshift({ value: null, title: Vue.i18n.translate('destinationsSilo.allCountries') })
      }

      return countries
    },

    destinations (state) {
      let filteredDestinations = state.allDestinations

      const selectedCountry = state.selectedCountry?.value || null

      if (selectedCountry !== null) {
        filteredDestinations = OrigDestUtils.sortByCity(filteredDestinations.filter(
          o => o.countryCode === state.selectedCountry.value
        ))
      }

      if (!isEmpty(state.selectedLabel)) {
        filteredDestinations = OrigDestUtils.sortByCity(filteredDestinations.filter((o) => {
          return o.labels.filter(value => -1 !== state.selectedLabel.indexOf(value)).length > 0
        }))
      }

      const data = cloneDeep(filteredDestinations)
      if (state.withOrigin) {
        const item = new DestinationSiloItem()
        const code = state.airport?.code || null

        if (code !== null) {
          item.code = state.airport.code.length === 3 ? (`${state.airport.code}${state.airport.type}`).toLowerCase() : state.airport.code
          item.city = state.airport.city
          item.country = state.airport.country
          item.lng = state.airport.longitude
          item.lat = state.airport.latitude
          item.type = state.airport.type

          if (findIndex(data, (o) => o.code === item.code) < 0) {
            data.push(item)
          }
        }
        filteredDestinations = data
      }

      if (findIndex(data, (o) => o.code === (`RIXA`).toLowerCase()) < 0) {
        const allDestinations = state.allDestinations || []
        const rixObj = find(allDestinations, (o) => o.code === (`RIXA`).toLowerCase())

        if (!isUndefined(rixObj)) {
          const rix = new DestinationSiloItem()
          rix.code = rixObj.code
          rix.city = rixObj.city
          rix.country = rixObj.country
          rix.lng = rixObj.lng
          rix.lat = rixObj.lat
          rix.type = rixObj.type
          rix.viaPoint = true

          data.push(rix)

          filteredDestinations = data
        }
      }

      return filteredDestinations
    },
    airports (state) {
      const filterValue = state.filter

      if (!isEmpty(filterValue)) {
        const origins = state.btOrigins
        const typeaheadIgnoreFilter = state.filter.length <= 0
        const matchedTranslation = Vue.i18n.translate('searchForm.originDestin.origin.matchedOrigins')

        return OrigDestUtils.addHeaderToAirportList(matchedTranslation,
          OrigDestUtils.filterData(filterValue, origins, typeaheadIgnoreFilter)
        )
      }

      return state.btOrigins
    }
  },

  actions: {
    setSelectedCountry ({ commit }, filterCountry) {
      commit('setSelectedCountry', filterCountry)
      commit('setSelectedLabel', '')
    },
    setOrigDestData ({ commit, state }, origDestData) {
      const btOrigins = OrigDestUtils.prepareData(filter(origDestData, o => o.isBtOrig))
      commit('setAllAirportData', origDestData)
      commit('setBtOrigins', OrigDestUtils.sortByCity(btOrigins))
      commit('changeAirport', btOrigins.find(o => o.code === DEFAULT_AIRPORT))

      const forcedOrigin = UrlParams.getUrlParam(window.location.href, 'flying_from')

      const defaultAirport = btOrigins.find(o => o.code === DEFAULT_AIRPORT)
      const forcedAirport = btOrigins.find(o => o.code === forcedOrigin)

      if (!isEmpty(forcedOrigin) && !isEmpty(forcedAirport) && !state.originInitFromUlrHappened) {
        commit('changeAirport', forcedAirport)
        commit('setOriginInitFromUrlHappened', true)
      } else {
        const nearestCode = getNearestAirport(true)
        const nearestAirport = btOrigins.find(o => o.code === nearestCode)

        if (isUndefined(nearestAirport)) {
          commit('changeAirport', defaultAirport)
        } else {
          commit('changeAirport', nearestAirport)
        }
      }
    },
    fetchDestinations ({ commit, dispatch, state }) {
      dispatch('clearFilters')

      const allDestinations = state.airport?.destinations || null

      if (isEmpty(allDestinations)) {
        commit('setDestinations', [])
      } else {
        const codes = Object.keys(allDestinations)

        new DestinationsService().getDestinations({ codes: codes.join() })
          .then((destinations) => {

            const destinationList = []
            destinations.forEach((item) => {
              const labels = allDestinations[item.code.toUpperCase()]?.labels || []
              if (!labels.includes("label_bt_destination")) return

              const airport = state.allOrigins[item.code.toUpperCase()]
              const isDirect = labels.includes("label_direct_flights") && labels.includes("label_bt_destination")

              item.fastSearch = allDestinations[item.code.toUpperCase()]?.fastSearch || 0
              item.isDirect = isDirect
              item.city = airport.city
              item.apt = airport.apt
              item.country = airport.country
              item.countryCode = airport.countryCode
              item.labels = labels
              item.origin = state.airport.code
              item.lat = airport.latitude
              item.lng = airport.longitude

              destinationList.push(item)
            })

            dispatch('setDestinations', destinationList)
          })
          .catch(() => {
            commit('setDestinations', [])
          })
      }
    },
    setDestinations ({ commit, state }, destinations) {
      const forcedLabels = UrlParams.getUrlParam(window.location.href, 'labels')
      const forcedCountry = UrlParams.getUrlParam(window.location.href, 'destinCountry')

      if ((!isEmpty(forcedLabels) || !isEmpty(forcedCountry)) && !state.initFromUlrHappened) {
        if (!isEmpty(forcedCountry)) {
          const result = destinations.find(o => o.countryCode === forcedCountry)
          if (!isEmpty(result)) {
            commit('setSelectedCountry', { value: forcedCountry, title: result.country })
          }
        }

        if (!isEmpty(forcedLabels)) {
          commit('setSelectedLabel', forcedLabels)
        }

        commit('setInitFromUrlHappened', true)
      }

      commit('setDestinations', OrigDestUtils.sortByCity(destinations))
    },
    clearFilters ({ commit }) {
      commit('setSelectedLabel', '')
      commit('setSelectedCountry', { value: null, title: Vue.i18n.translate('destinationsSilo.allCountries') })
      commit('selectDestination', null)
    },
    resetOrigins ({ dispatch, state }) {
      dispatch('setOrigDestData', state.allOrigins)
    }
  },

  namespaced: true
}

export default DestinationsSilo
