import AbstractView from 'abstract';
import Vue from 'vue'
import createProfileInit from 'initializations/pages/Pax/CreateProfile'
import SettingsInit from 'initializations/pages/Pax/ProfileSettings'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        createProfileInit.init("createProfile")
        SettingsInit.init("profileSettings")

        const CreateProfile = () => import(/* webpackChunkName: "create-profile-page" */ 'containers/Pages/Profile/Create')

        this.getEmail()
        this.initDefaultValues('createProfile')

        new Vue({
            el: "#" + this.elemId,
            store,
            render: h => h(CreateProfile, { props: { hideSocial: true}})
        })
    }

    getEmail () {
      let searchParams = new URLSearchParams(window.location.search)
      let email = searchParams.get('email')

      if (email) {
        store.dispatch('createProfile/setEmail', atob(email), { root: true })
      }
    }

  initDefaultValues(name) {
    if (typeof benefitsList !== "undefined") {
      store.commit(`${name}/setPageTitle`, pageTitle)
      store.commit(`${name}/setBenefitsListTitle`, benefitsTitle)
      store.commit(`${name}/setBenefitsList`, benefitsList)
      store.commit(`${name}/setModalTitle`, modalTitle)
      store.commit(`${name}/setModalContent`, modalContent)
      store.commit(`${name}/setEditMyProfileUrl`, editProfileUrl)
      store.commit(`${name}/setModalFailTitle`, modalFailTitle)
      store.commit(`${name}/setModalFailContent`, modalFailContent)
      store.commit(`${name}/setModalExistsTitle`, modalExistsTitle)
      store.commit(`${name}/setModalExistsContent`, modalExistsContent)
      store.commit(`${name}/setLoginUrl`, loginUrl)
    }

    if (typeof signUpConfirmationPage !== 'undefined') {
      store.commit(`${name}/setSignUpConfirmationUrl`, signUpConfirmationPage)
    }

    if (typeof loginUrl !== 'undefined') {
      store.commit(`${name}/setLoginUrl`, loginUrl)
    }

    if (typeof loaderBackground !== 'undefined') {
      store.commit(`${name}/setLoaderBackground`, loaderBackground)
    }
  }
}
