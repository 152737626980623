import LADStore from 'store/modules/Pages/LAD/LAD'
import SearchFormStore from 'store/modules/eHorizon/SearchForm/SearchForm'
import store from 'definitions/store'
import getNearestAirport from 'utils/GetNearestAirport'
import OrigDest from 'utils/OrigDestData'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, LADStore)
      store.registerModule('eHorizonSearchForm', SearchFormStore)

      Init.initDefaultValues(name)
      Init.initOrigDestData(name)
    }
  },

  initDefaultValues(name) {
    if (typeof pageData !== "undefined") {
      store.dispatch(`${name}/setPageData`, pageData)
    }
  },

  initOrigDestData(name) {
    const nearestAirport = getNearestAirport()

    if (typeof origDestData !== 'undefined') {
      const data = OrigDest.parseData(origDestData)
      store.dispatch(`${name}/search/setOrigDestData`, data)

      if (nearestAirport && typeof data[nearestAirport] !== 'undefined') {
        store.commit(`${name}/search/changeAirport`, data[nearestAirport])
      }
    }
  }
}

export default Init
