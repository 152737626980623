import PaxBookings from 'services/AirBaltic/Pax/Bookings'
import axios from 'axios'
import OrigDest from 'utils/OrigDestData'

const dateCompare = (a, b) => {
  return new Date(a.departureDate).getTime() - new Date(b.departureDate).getTime()
}

const timeIsInPast = (flight) => {
  return new Date().getTime() > new Date(flight.departureDate).getTime()
}

const flightInFuture = (flight) => {
  return (
    flight.daysTillDeparture > 0 ||
    (flight.daysTillDeparture === 0 && !timeIsInPast(flight))
  )
}

const isInActiveBookings = (booking, activeBookings) => {
  if (!activeBookings) return false

  return activeBookings.some((active) => active.pnrRl === booking.pnrRl)
}

const MyBookings = {
  state: {
    pageTitle: '',
    selectedTab: 'active',
    addExtrasLinkText: '',
    viewDetailsLinkText: '',
    changeNameLabel: '',
    changeFlightDateLabel: '',
    cancelBookingLabel: '',
    noActiveBookingsText: '',
    noPastBookingsText: '',
    noAccountBookingsText: '',
    paymentPageUrl: '',
    bookingCancellationPage: '',
    selfServiceUrl: '',
    destinations: {},
    activeBookings: [],
    pastBookings: [],
    accountBookings: []
  },

  mutations: {
    setPageTitle(state, value) {
      state.pageTitle = value
    },

    setSelectedTab(state, value) {
      state.selectedTab = value
    },

    setActiveBookings(state, value) {
      state.activeBookings = value
    },

    setPastBookings(state, value) {
      state.pastBookings = value
    },

    setBookingVisibility(state, data) {
      if (typeof state.activeBookings[data.index] !== 'undefined') {
        state.activeBookings[data.index].visible = data.value
      }
    },

    setNoActiveBookingsText(state, value) {
      state.noActiveBookingsText = value
    },

    setNoPastBookingsText(state, value) {
      state.noPastBookingsText = value
    },

    setNoAccountBookingsText(state, value) {
      state.noAccountBookingsText = value
    },

    setDestinations(state, value) {
      state.destinations = value
    },

    setAddExtrasLinkText(state, value) {
      state.addExtrasLinkText = value
    },

    setChangeNameLabel(state, value) {
      state.changeNameLabel = value
    },

    setChangeFlightDateLabel(state, value) {
      state.changeFlightDateLabel = value
    },

    setCancelBookingLabel(state, value) {
      state.cancelBookingLabel = value
    },

    setPaymentPageUrl(state, value) {
      state.paymentPageUrl = value
    },

    setBookingCancellationPageUrl(state, value) {
      state.bookingCancellationPage = value
    },

    setViewDetailsLinkText(state, value) {
      state.viewDetailsLinkText = value
    },

    setSelfServiceUrl(state, value) {
      state.selfServiceUrl = value
    },

    setAccountBookings(state, value) {
      state.accountBookings = value
    }
  },

  getters: {
    isActiveTab(state) {
      return state.selectedTab === 'active'
    },
    isPastTab(state) {
      return state.selectedTab === 'past'
    },
    isAccountTab(state) {
      return state.selectedTab === 'account'
    }
  },

  actions: {
    initBookingsStore({ commit, dispatch }) {
      if (typeof pageData !== 'undefined') {
        dispatch(`setPageData`, pageData)

        if (typeof origDestData !== 'undefined') {
          commit(`setDestinations`, OrigDest.parseData(origDestData))
        }

        new PaxBookings()
          .getPaxBookingsData()
          .then((response) => {
            const activeBookings = response
              .filter((booking) => flightInFuture(booking))
              .sort(dateCompare)

            const pastBookings = response
              .filter((booking) => !flightInFuture(booking))
              .sort(dateCompare)
              .reverse()

            commit('setActiveBookings', activeBookings)
            commit('setPastBookings', pastBookings)
          })
          .catch(() => {
            commit('setActiveBookings', [])
          })
          .finally(() => {
            dispatch('getAccountBookings')
          })
      } else {
        axios.get('/resources/bookings/pageData.json').then((response) => {
          dispatch(`setPageData`, response.data)
        })

        axios.get('/resources/orig-dest/lv.json').then((response) => {
          axios.get('/resources/bookings/bookings.json').then((bookingsResponse) => {
            const bookings = new PaxBookings().convertToBookings(bookingsResponse.data)
            const activeBookings = bookings
              .filter((booking) => flightInFuture(booking))
              .sort(dateCompare)

            const pastBookings = bookings
              .filter((booking) => !flightInFuture(booking))
              .sort(dateCompare)
              .reverse()

            commit('setActiveBookings', activeBookings)
            commit('setPastBookings', pastBookings)
            commit(`setDestinations`, response.data)
          })
        })
      }
    },

    getAccountBookings({ state, commit }) {
      new PaxBookings()
        .getPaxAccountBoookingsData()
        .then((response) => {
          const accountBookings = response
            .filter((booking) => flightInFuture(booking))
            .filter((booking) => !isInActiveBookings(booking, state.activeBookings))
            .sort(dateCompare)

          commit('setAccountBookings', accountBookings)
        })
        .catch(() => {
          commit('setAccountBookings', [])
        })
    },

    setPageData({ commit }, pageData) {
      commit(`setPageTitle`, pageData.title)
      commit(`setAddExtrasLinkText`, pageData.addExtrasLinkText)
      commit(`setViewDetailsLinkText`, pageData.viewDetailsLinkText)
      commit(`setChangeNameLabel`, pageData.changeNameLabel)
      commit(`setChangeFlightDateLabel`, pageData.changeFlightDateLabel)
      commit(`setCancelBookingLabel`, pageData.cancelBookingLabel)
      commit(`setNoActiveBookingsText`, pageData.noActiveBookingsText)
      commit(`setNoPastBookingsText`, pageData.noPastBookingsText)
      commit(`setNoAccountBookingsText`, pageData.noAccountBookingsText)
      commit(`setPaymentPageUrl`, pageData.paymentPageUrl)
      commit(`setBookingCancellationPageUrl`, pageData.bookingCancellationPage)
      commit(`setSelfServiceUrl`, pageData.selfServiceUrl)
    }
  },

  namespaced: true
}

export default MyBookings
