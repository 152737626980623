import CheckedBaggage from './Sections/CheckedBaggage';
import Extras from './Sections/Extras';
import HeavyBaggage from './Sections/HeavyBaggage';
var PassengerSegment = /** @class */ (function () {
    function PassengerSegment(obj) {
        if (obj === void 0) { obj = {}; }
        this.checkedBaggage = new CheckedBaggage();
        this.heavyBaggage = new HeavyBaggage();
        this.extras = new Extras();
        this.speq = {};
        this.formErrors = {
            checkedBaggage: this.checkedBaggage.errorMsg,
            extras: this.extras.errorMsg,
            heavyBaggage: this.heavyBaggage.errorMsg,
        };
        this.validation = {
            checkedBaggage: this.checkedBaggage.validation,
            extras: this.extras.validation,
            heavyBaggage: this.heavyBaggage.validation,
        };
        Object.assign(this, obj);
    }
    return PassengerSegment;
}());
export default PassengerSegment;
