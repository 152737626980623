<template>
  <div class="index-page-content">
    <template v-for="(section, key) in sections">
      <inspirational-banner
        v-if="section.name === 'inspirational' && inspirationalBanner.length"
        :key="key"
        :title="section.title"
        :banners="inspirationalBanner"
      />

      <best-buy-banners
        v-if="section.name === 'best-buy-banners' && !isRip && bestBuyBanners.length"
        :key="key"
        :title="section.title"
        :banners="bestBuyBanners"
        :best-buy-countries="bestBuyCountries"
        :destination-page-link="destinationPageLink"
        :every-mundo-link="everyMundoLink"
      />

      <card-section
        v-if="section.name === 'cards' && cardSection"
        :key="key"
        :section="cardSection"
      />

      <service-section
        v-if="section.name === 'services' && serviceSection.services"
        :key="key"
        :section="serviceSection"
      />

      <ad-section
        v-if="section.name === 'ad' && adSection.colors"
        :key="key"
        :section="adSection"
      />

      <blog-section
        v-if="section.name === 'blog' && showBlogSection"
        :key="key"
        :section="blogSection"
      />

      <why-us-section
        v-if="section.name === 'why-us' && whyUsSection"
        :key="key"
        :section="whyUsSection"
      />

      <subscribe-section
        v-if="section.name === 'subscribe'"
        :key="key"
        :section="subscribeSection"
      />

      <label-banner-section
        v-if="section.name === 'label-banners'"
        :key="key"
        :section="labelBanners"
      />

      <every-mundo-cards-section
        v-if="section.name === 'every-mundo-cards' && showEveryMundoCards"
        :key="key"
        :section="everyMundoCards"
      />
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    components: {
      'inspirational-banner': () =>
        import(
          /* webpackChunkName: "inspirational-banner" */ 'containers/Pages/IndexPage/InspirationalBanner'
        ),
      'best-buy-banners': () =>
        import(
          /* webpackChunkName: "best-buy-banners" */ 'containers/Pages/IndexPage/BestBuyBanners'
        ),
      'card-section': () =>
        import(
          /* webpackChunkName: "card-section" */ 'containers/Pages/IndexPage/CardSection'
        ),
      'blog-section': () =>
        import(
          /* webpackChunkName: "blog-section" */ 'containers/Pages/IndexPage/BlogSection'
        ),
      'why-us-section': () =>
        import(
          /* webpackChunkName: "why-us-section" */ 'containers/Pages/IndexPage/WhyUsSection'
        ),
      'service-section': () =>
        import(
          /* webpackChunkName: "service-section" */ 'containers/Pages/IndexPage/ServiceSection'
        ),
      'ad-section': () =>
        import(
          /* webpackChunkName: "ad-section" */ 'containers/Pages/IndexPage/AdSection'
        ),
      'subscribe-section': () =>
        import(
          /* webpackChunkName: "subscribe-section" */ 'containers/Pages/IndexPage/SubscribeSection'
        ),
      'label-banner-section': () =>
        import(
          /* webpackChunkName: "label-banner-section" */ 'containers/Pages/IndexPage/LabelBannerSection'
        ),
      'every-mundo-cards-section': () =>
        import(
          /* webpackChunkName: "every-mundo-cards-section" */ 'containers/Pages/IndexPage/EveryMundoCards.vue'
          )
    },
    computed: {
      ...mapState('indexPage', [
        'inspirationalBanner',
        'sections',
        'cardSection',
        'blogSection',
        'serviceSection',
        'adSection',
        'everyMundoCards',
        'subscribeSection',
        'whyUsSection',
        'labelBanners'
      ]),
      ...mapState('indexPage/bestBuyBanners', [
        'bestBuyBanners',
        'bestBuyCountries',
        'destinationPageLink',
        'everyMundoLink'
      ]),
      ...mapState('indexTabs', ['ripTabs']),
      isRip() {
        return this.ripTabs.includes('FLT')
      },
      showBlogSection() {
        return Object.keys(this.blogSection).length > 0
      },
      showEveryMundoCards() {
        return !!this.everyMundoCards?.data?.length
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';

  .index-page-content {
    background: $blue-bg-css;

    section {
      min-height: 100px;
      max-width: $desktop-wide-width;
      margin: 0 12px;
      padding: 28px 0;

      @include bp-mf-tablet {
        margin: 0 26px;
        padding: 32px 0;
      }

      @include bp-mf-desktop {
        margin: 0 auto;
        padding: 40px 0;
      }

      @include bp-desktop-small {
        margin: 0 26px;
      }

      &:not(:first-child) {
        border-top: 1px solid #dbdde2;
      }
    }

    .app-section-wrap + section {
      @include bp-mf-desktop {
        border-top: none;
      }
    }
  }

  ::v-deep .section-link {
    @include defaultFontValues($blue-medium, 16, 24, 500);
  }
</style>
