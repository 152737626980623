const Tabs = {
  state: {
    activeTab: 'FLT',
    openFsfOvl: false,
    bookingUrl: '',
    carsUrl: '',
    ripTabs: [],
    showOverlay: false,
    ripUrl: '',
    bookingUrlTarget: '_self',
    carsUrlTarget: '_self',
    wideBanners: {},
    ripTexts: [],
    selfServiceUrl: '',
    selfCKIUrl: '',
    infoBanner: {},
    anyDestinationButtonData: {},
    tabAutoFocus: false
  },
  mutations: {
    setActiveTab(state, value) {
      state.activeTab = value
    },
    setOpenFsfOvl(state, value) {
      state.openFsfOvl = value
    },
    setBookingUrl(state, value) {
      state.bookingUrl = value
    },
    setCarsUrl(state, value) {
      state.carsUrl = value
    },
    setBookingUrlTarget(state, value) {
      state.bookingUrlTarget = value
    },
    setCarsUrlTarget(state, value) {
      state.carsUrlTarget = value
    },
    setRipTabs(state, value) {
      state.ripTabs = value
    },
    setRipTexts(state, value) {
      state.ripTexts = value
    },
    openOverlay(state) {
      state.showOverlay = true
    },
    toggleOverlay(state, value) {
      state.showOverlay = value
    },
    closeOverlay(state) {
      state.showOverlay = false
    },
    setRipUrl(state, value) {
      state.ripUrl = value
    },
    setBannersData(state, value) {
      state.wideBanners = value
    },
    setInfoBanner(state, value) {
      state.infoBanner = value
    },
    setSelfServiceUrl(state, value) {
      state.selfServiceUrl = value
    },
    setSelfCKIUrl(state, value) {
      state.selfCKIUrl = value
    },
    setAnyDestinationButtonData(state, value) {
      state.anyDestinationButtonData = value
    },
    enableTabAutoFocus(state) {
      state.tabAutoFocus = true
    }
  },
  actions: {
    switchTabs({ dispatch, commit }, tab) {
      dispatch('mmbForm/removeAllErrors', null, { root: true })
      dispatch('checkinForm/removeAllErrors', null, { root: true })
      commit('setActiveTab', tab)
    }
  },
  namespaced: true
}

export default Tabs
