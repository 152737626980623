const SingleRelease = {
  state: {
    release: {}
  },
  mutations: {
    setRelease (state, value) {
      state.release = value
    }
  },

  namespaced: true
}

export default SingleRelease
