import { email, required } from 'vuelidate/lib/validators';
var SignUpPage = /** @class */ (function () {
    function SignUpPage() {
        this.pageTitle = '';
        this.benefitsListTitle = '';
        this.benefitsList = '';
        this.modalTitle = '';
        this.modalContent = '';
        this.editMyProfileUrl = '#';
        this.modalType = '';
        this.signUpConfirmationUrl = '';
        this.registerPage = '';
        this.loginUrl = '';
        this.loaderBackground = '';
        this.modalErrors = {
            title: '',
            content: ''
        };
        this.form = {
            email: ''
        };
        this.errorMsg = {
            email: {
                email: 'reusable.email.validation.invalid',
                required: 'reusable.email.validation.missing'
            },
        };
        this.validations = {
            form: {
                email: {
                    email: email,
                    required: required
                }
            }
        };
    }
    return SignUpPage;
}());
export default SignUpPage;
