<template>
  <i
    class="close-icon"
    aria-label="Close button"
    @click="onClicked($event)"
  />
</template>

<script>

  export default {
    methods: {
      onClicked (event) {
        this.$emit('click', event)
      }
    }
  }

</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  .close-icon {
    position: absolute;
    color: $grey;
    cursor: pointer;

    &:after {
      content: $icon-close-thin;
      @include icon-font-thin();
    }

    &:hover {
      color: $blue-medium;
    }

    &.circle {

      &:before {
        content: '';
        display: block;
        border-radius: 50%;
      }

      &:after {
        content: $icon-close-circle;
        @include icon-font();
        position: absolute;
        top: 0;
      }
    }

    &.small:after {
      display: block;
      content: $icon-close-medium;
      @include icon-font();
      font-size: em(12);

      @include bp-mf-tablet {
        font-size: em(11);
      }
    }
  }
</style>
