import GTM from 'services/AirBaltic/GTM'

class Offers {
  static push(data) {
    GTM.doDataLayerPush(data)
  }

  static offersClicked(count) {
    this.push({
      event: 'ga4_generic_event',
      generic_event_name: 'notification_icon',
      eventCategory: 'click',
      eventAction: count
    })
  }

  static offersVisible(count) {
    this.push({
      event: 'ga4_generic_event',
      generic_event_name: 'notification_icon',
      eventCategory: 'see',
      eventAction: count
    })
  }

  static offersOpen(title, int_name) {
    this.push({
      event: 'ga4_generic_event',
      eneric_event_name: 'notification_icon',
      eventCategory: 'read_more',
      eventAction: title,
      eventLabel: int_name
    })
  }

  static offersRead(title, int_name) {
    this.push({
      event: 'ga4_generic_event',
      eneric_event_name: 'notification_icon',
      eventCategory: 'marked_as_read',
      eventAction: title,
      eventLabel: int_name
    })
  }
}

export default Offers
