import moment from 'moment'
import CookieUtils from 'utils/CookieUtils'

export default class Divolte {
  static doDataPush(name, data) {
    const gdprSettings = CookieUtils.getCookie('gdpr_settings').split(',')
    const hasItems = gdprSettings.filter((cookie) => cookie === 'web')

    if (hasItems.length <= 0) {
      return false
    }

    window.divolte = window.divolte || { loaded: false, queue: [], signal: () => {} }

    window.divolte.signal(name, data)
  }

  static getTimestampMs(date) {
    return moment(date).valueOf()
  }

  static daysToEpoch(date) {
    return parseInt(Math.floor(date / 8.64e7), 10)
  }

  static parseDate(date, format) {
    return this.getTimestampMs(moment(date, format))
  }
}
