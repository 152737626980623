<template>
  <input
    v-if="maskValue !== ''"
    :id="name"
    v-mask="directiveMask"
    :name="name"
    :type="type"
    :value="value"
    :inputmode="keyboardType"
    :placeholder="placeholder"
    :maxlength="maxLength"
    :disabled="!!disabled"
    :aria-placeholder="label"
    :autocomplete="autocompleteValue"
    labelledby="label"
    @input="onChange($event.target.value)"
  />

  <input
    v-else
    :id="name"
    :name="name"
    :type="type"
    :value="value"
    :inputmode="keyboardType"
    :placeholder="placeholder"
    :maxlength="maxLength"
    :disabled="!!disabled"
    :aria-placeholder="label"
    :autocomplete="autocompleteValue"
    labelledby="label"
    @input="onChange($event.target.value)"
  />
</template>

<script>
  import { mask } from 'vue-the-mask'

  export default {
    name: 'InputBase',
    directives: {
      mask
    },
    props: {
      type: {
        type: String,
        required: false,
        default: 'text'
      },
      placeholder: {
        type: String,
        default: '',
        required: false
      },
      label: {
        type: String,
        default: '',
        required: false
      },
      name: {
        type: String,
        required: true
      },
      field: {
        type: String,
        required: true
      },
      value: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      maxLength: {
        type: Number,
        default: 1500,
        required: false
      },
      maskValue: {
        type: String,
        default: '',
        required: false
      },
      autocomplete: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        directiveMask: {
          mask: this.maskValue,
          tokens: {
            '@': { pattern: /[1-9]/ },
            '#': { pattern: /\d/ }
          }
        }
      }
    },
    computed: {
      keyboardType() {
        return this.type === 'number' || this.type === 'tel' ? 'tel' : 'text'
      },
      autocompleteValue() {
        if (this.name === 'password') {
          return 'off'
        }

        return this.autocomplete ? 'on' : 'off'
      }
    },
    methods: {
      onChange(value) {
        this.$emit('input', value)
      }
    }
  }
</script>
