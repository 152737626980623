import IrregularityModel from 'models/SelfService/Irregularities'
import SelfServiceService from 'services/AirBaltic/SelfService'
import FormValidationUtils from 'utils/FormValidation'
import find from 'lodash-es/find'

const contact = Object.assign({}, new IrregularityModel())

const isAdult = (pax) => {
  return !pax.isChild && !pax.isInfant
}

const hasMissingInfo = (pax) => {
  return !pax.email || !pax.phoneNumber || pax.hasErrored
}

const defaultState = {
  contacts: [],
  intCodeList: [],
  formErrors: contact.errorMsg
}

const validator = FormValidationUtils.initValidation(defaultState, contact.validation)

const Irregularities = {
  state: defaultState,
  mutations: {
    addContact (state, value) {
      const contact = new IrregularityModel()
      const data = {
        id: value,
        email: '',
        intCode: '',
        phone: '',
        phoneNumber: ''
      }
      state.contacts.push(Object.assign({}, contact, data))
    },
    setIntCodeList (state, value) {
      state.intCodeList = value
    },
    setContacts (state, value) {
      state.contacts = value
    },
    reset (state) {
      state.contacts = [Object.assign({}, new IrregularityModel(), [])]
    }
  },
  actions: {
    setContacts ({ commit }, passengers) {
      const contacts = passengers
      .filter((passenger) => passenger && isAdult(passenger) && hasMissingInfo(passenger))
      .map((passenger) => Object.assign({}, new IrregularityModel(), passenger))

      commit('setContacts', contacts)
    },
    setIntCodeList ({ commit }, data) {
      let phoneCodes = [{ key: '', value: '', title: '---' }]

      Object.values(data).map(country => {
        phoneCodes.push ({key: country.code, value: country.dialingCode, title: country.name})
      })

      phoneCodes = phoneCodes.sort((a, b) => a.title.localeCompare(b.title))

      commit('setIntCodeList', phoneCodes)
    },
    validateForm ({ state }) {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.contacts

        const isNotEmpty = find(state.contacts, (contact) => contact.email) ? true : false

        vState.$touch()

        !vState.$invalid ? resolve(isNotEmpty) : reject('irregularity-form')
      })
    },
    clearForm ({ commit }) {
      validator.$v.state.contacts.$reset()
      commit('reset')
    },
    saveContacts ({ state, rootState, dispatch }) {
      const selfServiceState = rootState.selfService
      const service = new SelfServiceService(selfServiceState, rootState.main.selectedLang)
      const pnrRl = selfServiceState.generic.pnr
      const contacts = []
      state.contacts.map(contact => {
        if (contact.phoneNumber && contact.email) {
          contacts.push({
            pax: {
              title: contact.title,
              firstName: contact.firstName,
              lastName: contact.lastName
            },
            phone: {
              number: contact.intCode ? contact.intCode + ' ' + contact.phoneNumber : contact.phoneNumber,
              language: rootState.main.selectedLang.toUpperCase()
            },
            email: {
              address: contact.email,
              language: rootState.main.selectedLang.toUpperCase()
            }
          })
        }
      })

      return new Promise((resolve, reject) => {
        service.saveIrregularityContacts({
          pnrRl,
          contacts
        })
        .then(() => {
          dispatch('selfService/updatePassengers', contacts, { root: true })
          resolve()
        })
        .catch(e => reject(e))
      })
    }
  },
  getters: {
    $contacts () {
      return Object.assign({}, validator.$v.state.contacts)
    },
    formErrors (state) {
      return state.formErrors
    }
  },

  namespaced: true
}

export default Irregularities
