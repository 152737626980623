import PaymentStore from 'store/modules/Pages/Payment/Payment'
import store from 'definitions/store'
import PassengerStore from 'store/modules/Pages/Payment/Passenger'
import SegmentStore from 'store/modules/Pages/Payment/Segment'
import SidebarStore from 'store/modules/Sidebar/Sidebar'
import SearchFormStore from 'store/modules/eHorizon/SearchForm/SearchForm'
import OrigDest from 'utils/OrigDestData'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, PaymentStore)
    }

    store.registerModule('sidebar', SidebarStore)
    store.registerModule(['payment', 'passengers', 'passenger_1'], PassengerStore())
    store.registerModule(['payment', 'passengers', 'passenger_1', 'segment_0'], SegmentStore())
    store.registerModule('eHorizonSearchForm', SearchFormStore)

    Init.initDefaultValues(name)
  },
  initDefaultValues(name) {
    if (typeof origDestData !== "undefined") {
      store.commit(`${name}/generic/setOrigDestData`,  OrigDest.parseData(origDestData))
      store.commit(`eHorizonSearchForm/setOrigDestData`,  OrigDest.parseData(origDestData))
    }

    if (typeof paymentActiveItem !== "undefined") {
      if (paymentActiveItem.dataFromUrl) {
        store.commit(`${name}/validation/pnr`, paymentActiveItem.dataFromUrl.pnr)
        store.commit(`${name}/validation/surname`, paymentActiveItem.dataFromUrl.lastName.toUpperCase())
        store.commit(`${name}/validation/setFromUrl`, true)

        store.commit(`${name}/css/setPnr`, paymentActiveItem.dataFromUrl.pnr)
        store.commit(`${name}/css/setSurname`, paymentActiveItem.dataFromUrl.lastName.toUpperCase())
        store.commit(`${name}/css/setRedirect`, paymentActiveItem.dataFromUrl.redirectToCss)
        store.commit(`${name}/css/setPathToCss`, paymentActiveItem.dataFromUrl.pathToCss)
      }
      store.commit(`${name}/generic/setActiveItem`, paymentActiveItem)
      store.commit(`${name}/generic/setActiveStep`, 'validation')
    }

    if (typeof PaymentPageData !== "undefined") {
      store.dispatch(`${name}/generic/setGenericData`, PaymentPageData)
    }

    if (typeof countries !== 'undefined') {
      store.commit(`${name}/company/setCountries`, countries)
    }

    if (typeof PnrValidationPageData !== "undefined") {
      store.dispatch(`${name}/validation/setPnrValidationData`, PnrValidationPageData)
    }

    if (typeof flightDetailsFormPage !== "undefined") {
      store.commit(`${name}/flightDetails/setFlightDetailsTitle`, flightDetailsFormPage.flightDetailsTitle)
      store.commit(`${name}/flightDetails/setFlightDetailsDescription`, flightDetailsFormPage.flightDetailsDescription)
    }

    if (typeof requestFormPage !== "undefined") {
      store.commit(`${name}/request/setRequestTitle`, requestFormPage['requestTitle'])
    }

    if (typeof gvoFormPage !== "undefined") {
      store.commit(`${name}/gvo/setTitle`, gvoFormPage['gvoTitle'])
      store.commit(`${name}/gvo/setDescription`, gvoFormPage['gvoDescription'])
    }

    if (typeof contactFormPage !== "undefined") {
      store.dispatch(`${name}/contact/setContactFormData`, contactFormPage)
    }

    if (typeof passengerFormData !== "undefined") {
      store.dispatch(`${name}/passengers/setPassengersFormData`, passengerFormData)
    }

    if (typeof noteSnippet !== 'undefined') {
      store.commit(`sidebar/setPleaseNoteTitle`, noteSnippet.title)
      store.commit(`sidebar/setPleaseNoteText`, noteSnippet.text)
    }
  }
}

export default Init
