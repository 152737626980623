import ContactUsStore from 'store/modules/Pages/ContactUsPage'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, ContactUsStore)

      Init.initDefaultValues(name)
    }
  },
  initDefaultValues(name) {
    if (typeof contactUs !== "undefined") {
      store.commit(`${name}/setPageTitle`, contactUs.title)
      store.commit(`${name}/setDescription`, contactUs.description)
      store.commit(`${name}/setAccordionItems`, contactUs.accordion)

      store.commit('sidebar/setPressContacts', contactUs.pressContacts)
    }
  }
}

export default Init
