import ScrollUtils from 'utils/scrollUtils'
import * as Sentry from '@sentry/vue'

const FormMixin = {
  data() {
    return {
      responseStatus: ''
    }
  },

  methods: {
    getErrorMsg(name, form) {
      if (!this.$v || !this.formErrors[`${form}`]) return ''

      const params = Object.keys(this.$v[`${form}`][`${name}`].$params)
      let error = ''
      params.forEach((param) => {
        if (this.$v[`${form}`][`${name}`][`${param}`] === false) {
          error = this.$t(this.formErrors[`${form}`][`${name}`][`${param}`])
        }
      })

      return error
    },

    openFormNotification(responseStatus, e) {
      let props
      let modalType
      let modalClass
      let showCloseIcon
      if (e?.type === 'informal') {
        props = {
          errors: {
            title: this.$t(e.title),
            content: this.$t(e.content),
            btnText: this.$t(e.btnText)
          },
          close: e.close ?? this.$_formMixin_closeModalFunction,
          errorType: e.type,
          disableCloseOnClickOutside: true,
          showHeader: e.showHeader ?? false
        }

        const InformationNotification = import(
          /* webpackChunkName: "information-notification" */ 'components/Notifications/InformationNotification'
        )
        InformationNotification.then((result) => {
          this.$store.commit('main/setModalContent', result.default)
        }).catch((e) => Sentry.captureException(e))
        modalType = 'centered'
        modalClass = 'modal-white-centered'
        showCloseIcon = false
      } else {
        props = {
          status: responseStatus,
          errorMessage: e
        }
        const FormNotification = import(
          /* webpackChunkName: "form-notification" */ 'components/Notifications/FormNotification'
        )
        FormNotification.then((result) => {
          this.$store.commit('main/setModalContent', result.default)
        })
        modalType = 'small'
        modalClass = 'modal-white-small'
        showCloseIcon = true
      }

      this.$store.dispatch('main/openModal')
      this.$store.commit('main/setModalCloseFn', this.$_formMixin_closeModalFunction)
      this.$store.commit('main/setModalType', modalType)
      this.$store.commit('main/setModalProps', props)

      this.responseStatus = responseStatus
      this.$store.commit('main/setModalClass', modalClass)
      this.$store.commit('main/setShowCloseIcon', showCloseIcon)
      this.$store.commit('main/setShowModalHeader', false)

      if (this.deviceType === 'tablet') {
        ScrollUtils.enableScroll()
      }
    },

    $_formMixin_closeModalFunction() {
      this.$store.dispatch('main/closeModal')
      ScrollUtils.enableScroll()
    }
  }
}

export default FormMixin
