<template>
  <form
    class="login-form"
    @keyup.enter="submitForm"
  >
    <element-input
      :label="$t('reusable.email.label')"
      name="email"
      field="email"
      :value="form.email"
      :error="fieldHasError('email')"
      :error-msg="getFieldError('email')"
      @input="onEmailInput"
    />

    <element-password
      :label="$t('reusable.password.label')"
      name="password"
      field="password"
      :value="form.password"
      :error="fieldHasError('password')"
      :error-msg="getFieldError('password')"
      :show-strength-meter="false"
      :password-validator="$v.password"
      @input="onPasswordInput"
    />

    <a
      v-if="showForgotPassword"
      class="main-link forgot"
      href="#"
      @click.prevent="openForgotPassword"
    >
      {{ $t('header.loginForm.forgotPasswordLink') }}
    </a>

    <span
      v-dompurify-html="$t('privacyPolicyAgreement.loginOrSignup')"
      class="privacy-policy-agreement white"
    />

    <element-button
      :text="$t('buttons.logIn')"
      @click.prevent="submitForm"
    />
  </form>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import ElementInput from 'components/Elements/Input'
  import ElementPassword from 'components/Elements/Password'
  import ElementButton from 'components/Elements/Button'
  import FormMixin from 'components/Forms/FormMixin'
  import gtmProfile from 'services/AirBaltic/GTM/Profile'
  import * as Sentry from '@sentry/vue'

  export default {
    components: {
      ElementInput,
      ElementPassword,
      ElementButton
    },
    mixins: [FormMixin],
    props: {
      showForgotPassword: {
        type: Boolean,
        required: false,
        default: false
      },
      internal: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters('login/loginForm', ['$v', 'loginFormErrors']),
      ...mapState('login/loginForm', ['form', 'genericErrors'])
    },
    methods: {
      fieldHasError(field) {
        return this.$_formMixin_hasError(this.$v, field)
      },
      getFieldError(field) {
        return this.$_formMixin_errorMsg(this.$v, this.loginFormErrors, field)
      },
      submitForm() {
        this.$store.commit('main/setShowSmallLoader', true)

        this.$store
          .dispatch('login/loginForm/validateForm')
          .then(() => {
            this.$store
              .dispatch('login/loginForm/submitForm', this.internal)
              .then(({ redirectUrl }) => {
                gtmProfile.loginSuccessAction('email', 'login_page')
                if (redirectUrl !== '') {
                  window.location.replace(redirectUrl)
                }
              })
              .catch((e) => {
                this.$emit('fail', e)
                Sentry.captureException(e)
              })
              .finally(() => this.$store.commit('main/setShowSmallLoader', false))
          })
          .catch((e) => Sentry.captureException(e))
          .finally(() => this.$store.commit('main/setShowSmallLoader', false))
      },
      openForgotPassword() {
        return this.$emit('openForgotPassword')
      },
      onEmailInput(value) {
        this.$store.commit('login/loginForm/setEmail', value)
      },
      onPasswordInput(value) {
        this.$store.commit('login/loginForm/setPass', value)
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
>
  @import '~assets/styles/components/tooltip.scss';

  .js-tooltip.js-single-login-tooltip {
    @include tooltip();

    width: 100%;
    max-width: 200px;
    text-align: center;
  }
</style>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .forgot {
    display: block;
    margin-bottom: 20px;
    text-align: right;

    @include bp-mf-tablet {
      margin-top: 15px;
    }

    @include bp-mf-desktop {
      margin-bottom: 25px;
    }
  }

  .privacy-policy-agreement {
    font-size: em(14);
    line-height: 18px;
    color: $blue-light;

    ::v-deep {
      a {
        color: $blue-light;
        text-decoration: underline;

        &:focus {
          text-decoration: none;
        }

        &:focus,
        &:hover {
          color: $blue-hover;
        }
      }
    }

    @include bp-mf-tablet {
      text-align: center;
    }

    @include bp-mf-desktop {
      text-align: left;
    }
  }
</style>
