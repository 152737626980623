import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import paymentInit from 'initializations/pages/Payment/Payment'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    paymentInit.init("payment")

    const Payment = () => import(/* webpackChunkName: "payment" */ 'containers/Pages/Payment')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(Payment)
    })
  }
}
