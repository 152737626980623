<template>
  <div class="forgot-password-form">
    <p v-if="!success">{{ $t('header.loginForm.forgotPasswordText') }}</p>
    <form
      v-if="!success"
      ref="forgotPassword"
      novalidate
      @submit.prevent="submitRecoverForm()"
      @keyup.esc="closeModal()"
    >
      <form-errors v-if="genericErrors.length > 0">
        <li
          v-for="(errorMessage, index) in genericErrors"
          :key="index"
          v-dompurify-html="$t(errorMessage)"
        />
      </form-errors>

      <element-input
        :label="$t('header.loginForm.forgotPasswordTextLabel')"
        name="email"
        field="email"
        :value="form.email"
        :error="fieldHasError('email')"
        :error-msg="getFieldError('email')"
        @input="onInput"
      />

      <blue-button
        class="button"
        :prevent="true"
        :label="$t('header.preHeader.button.send')"
        @clicked="submitRecoverForm()"
      />
    </form>

    <success v-if="success" />
    <small-loader />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import ElementInput from 'components/Elements/Input'
  import SmallLoader from 'components/SmallLoader'
  import FormMixin from 'components/Forms/FormMixin'
  import gtmProfile from 'services/AirBaltic/GTM/Profile'

  export default {
    components: {
      FormErrors: () =>
        import(/* webpackChunkName: "form-errors" */ 'components/FormErrors/FormErrors'),
      Success: () =>
        import(/* webpackChunkName: "modal-success" */ 'components/Modal/Success'),
      BlueButton: () =>
        import(/* webpackChunkName: "btn-blue" */ 'components/Buttons/Blue'),
      ElementInput,
      SmallLoader
    },
    mixins: [FormMixin],
    props: {
      internalLogin: {
        type: Boolean,
        default: false,
        required: false
      },
      loginType: {
        type: String,
        default: 'email'
      },
      loginPage: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        success: false,
        isModalVisible: false,
        pageGTM: this.getLoginPage()
      }
    },
    computed: {
      ...mapGetters('loginForm/forgotPassword', ['$v', 'formErrors']),
      ...mapState('loginForm/forgotPassword', ['genericErrors', 'form', 'url']),
      ...mapState('loginForm', ['isLoginPage']),
      ...mapState('main', ['selectedLang'])
    },
    methods: {
      getLoginPage() {
        if (this.loginPage) {
          return this.loginPage
        }
        return this.isLoginPage ? 'login_page' : 'bt_pages'
      },
      fieldHasError(field) {
        return this.$_formMixin_hasError(this.$v, field)
      },
      getFieldError(field) {
        return this.$_formMixin_errorMsg(this.$v, this.formErrors, field)
      },
      onInput(value) {
        this.$store.dispatch('loginForm/forgotPassword/validateEmail').catch(() => {})
        this.$store.commit('loginForm/forgotPassword/setEmail', value)
      },

      submitRecoverForm() {
        this.$store.commit('main/setShowSmallLoader', true)
        this.$store
          .dispatch('loginForm/forgotPassword/submitForm', this.internalLogin)
          .then(() => {
            this.$store.commit('loginForm/forgotPassword/setEmailIsSentFromLoginType', this.loginType)
            gtmProfile.forgotPasswordAction(this.loginType, this.loginPage)
            this.success = true
            this.$emit('success')
          })
          .catch(() => {})
          .finally(() => {
            setTimeout(() => {
              this.$store.commit('main/setShowSmallLoader', false)
            }, 300)
          })
      },

      closeModal() {
        this.$store.dispatch('main/closeModal')
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';

  p {
    margin: 23px 0 28px;
    font-size: em(14);
    line-height: 20px;
    color: $blue-secondary;

    @include bp-mf-tablet {
      margin-bottom: 20px;
    }
  }

  @include bp-tablet-only {
    .form-errors {
      padding: 10px 20px;
      font-size: em(12);

      &:before {
        display: none;
      }
    }
  }

  form {
    @include clearfix();
  }

  .field-wrap {
    margin-bottom: 26px;
  }

  .btn-blue {
    float: right;

    button {
      padding: 0 16px;
    }
  }
</style>
