import AirportWheelChairRequest from 'models/Forms/Assistance/Sections/AirportWheelChairRequest'
import FormValidationUtils from 'utils/FormValidation'

const requestAirportWheelChair = new AirportWheelChairRequest()
const formData = {
  formErrors: requestAirportWheelChair.errorMsg,
  form: requestAirportWheelChair.form,
}
const defaultState = Object.assign({}, formData)
const validator = FormValidationUtils.initValidation(defaultState, requestAirportWheelChair.validation)

const module = {
  namespaced: true,
  state: defaultState,
  actions: {
    validateRequest () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('validate wheelchair airport request')
      })
    },
    clearRequest ({ commit }) {
      commit('resetForm')
      validator.$v.state.form.$reset()
    }
  },
  mutations: {
    setAirportWheelChairRequest (state, value) {
      state.form.requestMessage = value
    },
    setUseAirportWheelchair (state, value) {
      state.form.useAirportWheelchair = value
    },
    setCarryToPlane (state, value) {
      state.form.carryToPlane = value
      if (value === 'Yes') {
        state.form.carryToSeat = 'No'
      }
    },
    setCarryToSeat (state, value) {
      state.form.carryToSeat = value
      if (value === 'Yes') {
        state.form.carryToPlane = 'No'
      }
    },
    resetForm (state) {
      state.form = new AirportWheelChairRequest().form
    }
  },
  getters: {
    $request () {
      return Object.assign({}, validator.$v.state.form)
    },
    requestValidationFormErrors (state) {
      return state.formErrors
    }
  }
}

export default module
