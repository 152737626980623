<template>
  <div
    v-if="showBanner"
    class="banner-wrap"
  >
    <a
      :href="url"
      class="banner-bar"
      :style="styleObj.img"
      :aria-label="styleObj.alt"
    />
    <div class="banner-bar-wrap">
      <close-icon @click="closePromoBar()" />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import CloseIcon from 'components/CloseIcon'
  import CookieUtils from 'utils/CookieUtils'
  import isUndefined from 'lodash-es/isUndefined'

  export default {
    components: {
      CloseIcon
    },
    props: {
      promoBanner: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        styleObj: {
          img: '',
          alt: ''
        }
      }
    },
    computed: {
      ...mapState('main', [
        'selectedLang'
      ]),
      ...mapState('device', [
        'deviceType',
        'isRetina'
      ]),
      showBanner () {
        return Object.keys(this.promoBanner).length > 0
      },
      url () {
        return this.promoBanner.url === '' ? null : this.promoBanner.url
      }
    },
    watch: {
      deviceType: {
        handler: function () {
          this.getBackgroundImage()
        }
      },
      promoBanner: {
        handler: function () {
          this.getBackgroundImage()
        }
      }
    },
    mounted () {
      this.getBackgroundImage()
    },
    methods: {
      getBackgroundImage () {
        if (Object.keys(this.promoBanner).length === 0) {
          this.styleObj.img = { 'background-image': `` }
          return false
        }

        const imageType = this.isRetina ? 'retina' : 'default'
        const source = this.promoBanner[`${this.deviceType}Img`][`${imageType}`]

        this.styleObj.alt = isUndefined(source.meta.title[this.selectedLang]) ? source.meta.title['en'] : source.meta.title[this.selectedLang]
        this.loadImage(source.img)
          .then(() => this.styleObj.img = { 'background-image': `url(${source.img})` })
          .catch(() => this.styleObj.img = { 'background-image': `` })
      },
      loadImage (source) {
        return new Promise((resolve, reject) => {
          const myImage = new Image()

          myImage.onload = () => resolve(source)
          myImage.onerror = () => reject(new Error())

          myImage.src = source
        })
      },
      closePromoBar () {
        CookieUtils.setCookie('banner-bar', false)
        this.$emit('reloadBanner')
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  .banner-wrap {
    height: 68px;
  }

  .banner-bar {
    height: 68px;
    position: relative;
    min-height: 68px;
    max-height: 68px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: block;
  }

  .banner-bar-wrap {
    display: flex;
    flex-direction: row-reverse;
    align-content: center;

    @include bp-mf-tablet {
      max-width: $tablet-wide-width;
      margin: 0 auto;
    }

    @include bp-mf-desktop {
      max-width: $desktop-wide-width;
    }

    .close-icon {
      position: relative;
      color: #ffffff;
      font-size: em(15);
      transition: opacity ease 0.2s;
      padding: 10px 13px;
      top: -55px;
      right: 0;

      &:hover {
        opacity: 0.5;
      }

      @include bp-mobile {
        right: 10px;
      }

      @include bp-tablet-only {
        right: 13px;
      }

      @media only screen and (max-width: 1160px) {
        right: 13px;
      }

      @include bp-desktop-small {
        padding: 0;
        top: -44px;
        right: 26px;
      }

      @include bp-desktop {
        right: -13px;
      }
    }
  }
</style>
