import axios from 'axios'
import conf from 'conf'
import handleError from 'utils/SentryErrorHandler'

class AxiosUtils {
  static post (postObject, url, isUrlFull) {
    let query = ''
    Object.keys(postObject).forEach((key) => {
      query += encodeURIComponent(key) + '=' + encodeURIComponent(postObject[`${key}`]) + '&'
    })

    const fullUrl = isUrlFull ? url : `${conf.btUrl}${url}${conf.btUrlCors}`

    return axios.post(
      fullUrl,
      query,
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
    )
      .then(response => Promise.resolve(response))
      .catch(error => {
        handleError(error)
        return Promise.reject(error)
      })
  }
}

export default AxiosUtils
