import store from 'definitions/store'
import OutlookStore from 'store/modules/Pages/Outlook'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, OutlookStore)
      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    if (typeof pageData !== "undefined") {
      store.commit(`${name}/setPageData`, pageData)
    }

    if (typeof activeYear !== "undefined") {
      store.commit(`${name}/setActiveYear`, activeYear)
    }

    if (typeof activeMonth !== "undefined") {
      store.commit(`${name}/setActiveMonth`, activeMonth)
    }
  }
}

export default Init
