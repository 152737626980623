<template>
  <div
    class="form-updated"
    :class="[customClass, status]"
  >
    <h5>{{ getNotificationTitle() }}</h5>
    <p
      v-if="status === 'success'"
      v-dompurify-html="successMessage"
    />

    <p
      v-if="status === 'info'"
      v-dompurify-html="infoMessage"
      class="info"
    />
    <form-errors
      v-if="hasErrorMessage"
      :show-icon="false"
    >
      <form-error-item
        v-for="(errorMessage, index) in failMessage"
        :key="index"
        :content="errorMessage"
      />
    </form-errors>
    <p v-if="hasNoErrorMessage">{{ $t('notification.fail') }}</p>

    <div
      v-if="showCta"
      class="btn btn-blue btn-back"
    >
      <button
        type="button"
        @click="cta.action()"
      >
        {{ $t(cta.title) }}
      </button>
    </div>
  </div>
</template>

<script>
  import FormErrors from 'components/FormErrors/FormErrors'
  import FormErrorItem from 'components/FormErrors/FormErrorItem'

  export default {
    components: {
      FormErrorItem,
      FormErrors
    },
    props: {
      showCta: {
        type: Boolean,
        default: false
      },
      status: {
        type: String,
        required: true
      },
      successTitle: {
        type: String,
        default: ''
      },
      successMessage: {
        type: String,
        default: ''
      },
      infoMessage: {
        type: String,
        required: false,
        default: ''
      },
      failTitle: {
        type: String,
        default: ''
      },
      failMessage: {
        type: Array,
        default: () => []
      },
      customClass: {
        type: String,
        default: ''
      },
      cta: {
        type: Object,
        default: () => ({
          title: 'buttons.toHomePage',
          action: () => (window.location.href = '/')
        }),
        required: false
      }
    },
    computed: {
      hasErrorMessage() {
        return this.status === 'fail' && this.failMessage
      },

      hasNoErrorMessage() {
        return this.status === 'fail' && !this.failMessage
      }
    },
    methods: {
      getNotificationTitle() {
        if (this.status === 'success') {
          return this.successTitle
        } else {
          return this.failTitle
        }
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';

  .form-updated {
    position: relative;
    text-align: center;

    @include bp-mf-tablet {
      padding: 70px 40px 110px 40px;
    }

    &.success {
      &:before {
        width: 120px;
        content: url('~assets/images/email-sent.svg');
        background: none;
        color: $green-base;
        margin-bottom: 50px;
      }
    }

    &.done {
      &:before {
        @include icon-font();
        margin: 30px 0 20px;
        width: 100px;
        height: 100px;
        background-color: $grey-white;
        border-radius: 50%;
        font-size: em(30);
        line-height: 105px;
        content: $icon-checkmark-medium;
        color: $green-base;

        @include bp-mobile-landscape {
          margin: 0 0 10px;
        }
      }
    }

    &.fail {
      &:before {
        @include filled-icon-font();
        content: $filled-icon-close;
        color: $red-dark;
      }
    }

    &:before {
      @include icon-font();
      margin: 30px 0 20px;
      width: 100px;
      height: 100px;
      background-color: $grey-white;
      border-radius: 50%;
      font-size: em(30);
      line-height: 105px;

      @include bp-mobile-landscape {
        margin: 0 0 10px;
      }
    }

    h5 {
      margin: 0;
      font-size: em(20);
      font-weight: 500;
      line-height: 26px;
      text-align: center;
    }

    p {
      margin: 12px 0 20px;
      font-size: em(16);
      color: $grey;
      line-height: 22px;
      text-align: center;

      @include bp-mobile-landscape {
        margin-bottom: 0;
      }

      &.info {
        ::v-deep a {
          color: $grey;
        }
      }
    }

    .form-errors {
      margin-top: 15px;
      list-style: none;
      background-color: #ffffff;

      &.no-icon {
        border: none;
      }

      li {
        margin: 12px 0 20px;
        font-size: em(16);
        line-height: 22px;
        text-align: center;

        @include bp-mobile-landscape {
          margin-bottom: 0;
        }
      }
    }

    .btn-back {
      margin-top: 30px;

      button {
        padding: 0 25px;
      }
    }
  }
</style>
