import Calendar from 'store/modules/Pages/GroupBooking/Calendar'
import PaxSelector from 'store/modules/eHorizon/SearchForm/PaxSelector'
import Origin from 'store/modules/eHorizon/SearchForm/Origin'
import Destin from 'store/modules/eHorizon/SearchForm/Destin'
import AnyDestSearch from 'services/AirBaltic/AnyDestination/AnyDestination'
import DestinationsSilo from 'store/modules/Pages/DestinationsSilo'
import gtmAnyDestination from 'services/AirBaltic/GTM/AnyDestination'
import moment from 'moment'
import uniq from 'lodash-es/uniq'
import Vue from 'vue'

const filters = () => {
  return {
    priceMin: 20,
    priceMax: 1500,
    sortOrder: {
      value: '',
      title: ''
    },
    sortLabel: {
      key: 'label_all',
      title: 'All destinations'
    },
    sortCountry: {}
  }
}

const defaultState = {
  pageData: {
    title: '',
    description: '',
    mobileImg: '',
    tabletImg: '',
    desktopImg: '',
    conditionsTitle: '',
    conditionsContent: ''
  },
  destinations: [],
  labels: [],
  activeView: 'search',
  filter: filters()
}

const anyDest = {
  modules: {
    calendar: Calendar,
    paxSelector: PaxSelector,
    origin: Origin,
    destin: Destin,
    destinationsSilo: DestinationsSilo
  },
  state: defaultState,
  mutations: {
    setPageData(state, value) {
      state.pageData = value
    },
    setDestinations(state, value) {
      state.destinations = value
    },
    setLabels(state, value) {
      state.labels = value
    },
    setActiveView(state, value) {
      state.activeView = value
    },
    setSortOrder(state, value) {
      state.filter.sortOrder = value
    },
    setPriceRange(state, value) {
      const [priceMin, priceMax] = value
      state.filter.priceMin = priceMin
      state.filter.priceMax = priceMax
    },
    setPriceMin(state, value) {
      state.filter.priceMin = value
    },
    setPriceMax(state, value) {
      state.filter.priceMax = value
    },
    setSortLabel(state, value) {
      state.filter.sortLabel = value
    },
    setSortCountry(state, value) {
      state.filter.sortCountry = value
    },
    resetFilters(state) {
      Object.assign(state.filter, filters())
    }
  },

  actions: {
    getRules({ dispatch }) {
      return [
        dispatch('origin/validatePromise'),
        dispatch('calendar/validateDeparturePromise'),
        dispatch('calendar/validateReturnPromise')
      ]
    },
    async getDestinations({ dispatch }) {
      return dispatch('getRules').then((response) => {
        return dispatch('validateRules', response)
      })
    },
    validateRules({ dispatch }, rules) {
      return Promise.all(rules)
        .then(() => {
          return dispatch('getAnyDestSearch')
        })
        .catch((e) => {
          return Promise.reject(e)
        })
    },
    getAnyDestSearch({ state, dispatch, rootState }) {
      const postObj = {
        origin: rootState.eHorizonSearchForm.origin.value.code,
        departureDate: moment(state.calendar.departureDate).format('YYYY-MM-DD'),
        returnDate: moment(state.calendar.returnDate).format('YYYY-MM-DD')
      }

      return new AnyDestSearch()
        .anyDestSearch(postObj)
        .then((response) => {
          dispatch('setData', response.data)
          return Promise.resolve(response)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },

    setData({ commit, dispatch }, data) {
      commit('setDestinations', data)
      dispatch('labels')
    },

    resetFilters({ commit }) {
      commit('resetFilters')
    },
    setPriceRange({ commit }, price) {
      commit('setPriceMin', price[0])
      commit('setPriceMax', price[1])
    },
    labels({ state, commit, rootState }) {
      if (!state.destinations.length) return
      let siloLabels = []
      let mappedLabels = []

      state.destinations.forEach((destin) => {
        let destination = Destin.state.destinations.find((dest) => dest.code === destin)
        if (!destination) return
        siloLabels = siloLabels.concat(...destination.labels)
      })

      uniq(siloLabels).map((label) => {
        const translationKey = `destinationLabels.${label.toLowerCase()}`
        const hasLocalTranslation =
          Vue.i18n.translate(translationKey) !== '' &&
          Vue.i18n.translate(translationKey) !== translationKey
        mappedLabels.push({
          key: label,
          title: hasLocalTranslation
            ? Vue.i18n.translate(translationKey)
            : rootState.destinationsSilo.allLabels[`${label}`]
        })
      })

      commit(
        'setLabels',
        mappedLabels.filter((o) => o.key !== 'label_bt_destination')
      )
    },
    gtmDataPush({ state, getters }, payload) {
      let filterData = {}
      let stateData = getters.gtmState

      if (payload.filters) {
        filterData = {
          sort_by: state.filter.sortOrder.title,
          min_price_range: state.filter.priceMin,
          max_price_range: state.filter.priceMax,
          type_of_destination: state.filter.sortLabel.title,
          chosen_country: state.filter.sortCountry.title ?? null
        }
      }

      payload.state = {
        stateData,
        filterData
      }

      gtmAnyDestination.gtmData(payload)
    },

    gtmDataBookingPush({ getters }, payload) {
      payload.stateData = getters.gtmState

      gtmAnyDestination.gtmDataBooking(payload)
    }
  },

  getters: {
    gtmState(state) {
      return {
        adults: state.paxSelector.adults,
        youngAdults: state.paxSelector.youngAdults,
        children: state.paxSelector.children,
        infants: state.paxSelector.infants,
        passengers:
          state.paxSelector.adults +
          state.paxSelector.children +
          state.paxSelector.infants,
        origin_airport_code: state.origin.value.code,
        departure_date: state.calendar.departureInputText,
        return_date: state.calendar.returnInputText
      }
    }
  },

  namespaced: true
}

export default anyDest
