import Api from 'services/AirBaltic/Api'

class BBBService {
  setPosList(posList = 'main') {
    return new Api()
      .get(`/banners/${posList.toLowerCase()}/posList`)
      .then((response) => Promise.resolve(response.data))
      .catch((e) => Promise.reject(e))
  }

  setSinglePos(country, posList = 'main') {
    return new Api()
      .get(`/banners/${posList.toLowerCase()}/${country}`)
      .then((response) => Promise.resolve(response.data))
      .catch((e) => Promise.reject(e))
  }

  getAllBanners(bannerName) {
    return new Api()
      .get(`/banners/${bannerName}`)
      .then((response) => Promise.resolve(response.data))
      .catch((e) => Promise.reject(e))
  }

  static getPrices(origin, destination) {
    return new Api().get(`/2e/offers/${origin}/${destination}`)
  }
}

export default BBBService
