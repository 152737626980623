class CookieUtils {
  static getCookie(cookieName) {
    cookieName += '='
    let cookie = ''
    const decodedCookie = decodeURIComponent(document.cookie)
    const cookies = decodedCookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[parseInt(i)]

      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }

      if (c.indexOf(cookieName) === 0) {
        cookie = c.substring(cookieName.length, c.length)
      }
    }
    return cookie
  }

  static findCookieByName(cookieName) {
    let cookie = ''
    const decodedCookie = decodeURIComponent(document.cookie)
    const cookies = decodedCookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[parseInt(i)]

      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }

      if (c.indexOf(cookieName) === 0) {
        const foundCookie = c.split('=')
        cookie = foundCookie[0]
      }
    }

    return cookie
  }

  static getDomain() {
    let domain = document.location.hostname
    const parts = domain.split('.')
    if (parts.length > 2) {
      parts.shift()
      domain = parts.join('.')
    }

    return domain
  }

  static setCookie(cookieName, value, exDays, secure = true, sameSite = '') {
    const exdate = new Date()
    exdate.setDate(exdate.getDate() + exDays)
    const cValue =
      encodeURIComponent(value) + (!exDays ? '' : '; expires=' + exdate.toUTCString())
    const secureFlag = secure ? 'Secure;' : ''
    const sameSiteFlag = sameSite !== '' ? 'SameSite=Lax; ' : `SameSite=${sameSite}`

    document.cookie = `${cookieName}=${cValue};path=/;domain=${this.getDomain()};${secureFlag}${sameSiteFlag}`
  }

  static setCookieIfNotExists(cookieName, value, exDays) {
    if (!CookieUtils.getCookie(cookieName)) {
      this.setCookie(cookieName, value, exDays)
    }
  }

  static sessionStorageIsAvailable() {
    try {
      const uid = new Date()
      window.sessionStorage.setItem(uid, uid)
      window.sessionStorage.removeItem(uid)
      return true
    } catch (exception) {
      return false
    }
  }

  static getSessionKey(key) {
    return window.sessionStorage.getItem(key)
  }
}

export default CookieUtils
