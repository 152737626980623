<template>
  <div :class="['input-block', { 'error': error, 'disabled': disabled }]">
    <div :class="['input-field', field]">
      <input-base
        :type="type"
        :name="name"
        :field="field"
        :value="value"
        :disabled="disabled"
        :max-length="maxLength"
        :class="{ 'not-empty': value !== '' }"
        :label="label"
        :mask-value="maskValue"
        placeholder=" "
        :autocomplete="autocomplete"
        @input="onChange($event)"
      />
      <label
        v-if="label.length"
        v-dompurify-html="fieldLabel"
        :for="name"
      />
    </div>
    <errors
      v-if="error"
      :errors="[errorMsg]"
    />
  </div>
</template>

<script>
  import InputBase from 'components/Elements/Base/Input'

  export default {
    name: 'Input',
    components: {
      'input-base': InputBase,
      Errors: () => import(/* webpackChunkName: "element-errors" */ 'components/Elements/Errors')
    },
    props: {
      optional: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        required: false,
        default: 'text'
      },
      label: {
        type: String,
        default: '',
        required: false
      },
      name: {
        type: String,
        required: true
      },
      field: {
        type: String,
        required: true
      },
      error: {
        type: Boolean,
        default: false,
        required: false
      },
      errorMsg: {
        type: String,
        default: '',
        required: false,
      },
      value: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      maxLength: {
        type: Number,
        default: 1500,
        required: false
      },
      append: {
        type: Boolean,
        default: false,
        required: false
      },
      maskValue: {
        type: String,
        default: '',
        requried: false
      },
      autocomplete: {
        type: Boolean,
        default: true,
        required: false
      },
      formatUpperCase: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      fieldLabel() {
        let label = this.label

        if (this.optional) {
          label += `<span> (${this.$t('optional')})</span>`
        }

        return label
      },
    },
    methods: {
      onChange(value) {
        const formattedValue = this.formatUpperCase ? value.toUpperCase() : value

        this.$emit('input', formattedValue)
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  .input-block {
    display: block;

    .input-field {
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid $blue-border;
      height: 58px;
      margin-bottom: 16px;
      position: relative;

      @include bp-mf-tablet {
        margin-bottom: 12px;
      }

      label {
        position: absolute;
        top: calc(50% - 10px);
        left: 0;
        padding: 0 16px;
        width: 100%;
        transition: 0.2s ease all;
        @include text-overflow();
        font-size: em(16);
        line-height: 20px;
        pointer-events: none;
        color: $blue-light;
      }

      &:focus-within {
        border-color: $blue-medium;
      }

      input {
        width: 100%;
        height: 58px;
        border-radius: 4px;
        padding: 0 16px;
        font-size: em(16);
        letter-spacing: 0;
        line-height: 20px;
        color: $blue-dark;
        transition: 0.2s ease all;

        &.not-empty,
        &:focus {
          padding-top: 10px;

          + label {
            top: 7px;
            font-size: em(12);
            letter-spacing: 0;
            line-height: 14px;
          }
        }

        &:not(:placeholder-shown) {
          padding-top: 10px;

          + label {
            top: 7px;
            font-size: em(12);
            letter-spacing: 0;
            line-height: 14px;
          }
        }

        &:focus {
          border-color: #b7b8be;
        }
      }
    }

    &.error {
      .input-field {
        border: 1px solid $red-error;
        margin-bottom: 6px;
      }
    }

    &.disabled {
      .input-field {
        border: 1px solid $blue-bg-darker;
        background: $blue-bg-darker;

        /deep/ input {
          color: $blue-dark;
          background: $blue-bg-darker;

          + label {
            color: $blue-light;
          }
        }
      }
    }
  }
</style>
