import AbstractView from 'abstract';
import Vue from 'vue'
import SingleReleaseInit from 'initializations/pages/PressReleases/SingleRelease'
import SidebarInit from 'initializations/Sidebar'
import store from 'definitions/store'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init () {
    SidebarInit.init("sidebar")
    SingleReleaseInit.init("singleRelease")

    const SingleRelease = () => import(/* webpackChunkName: "single-release-page" */ 'containers/Pages/PressReleases/PressReleasesSingle')
    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(SingleRelease)
    })
  }
}

