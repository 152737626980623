import CheckinFormStore from 'store/modules/eHorizon/CheckInForm'
import gtmCKI from 'services/AirBaltic/GTM/CKI'
import store from 'definitions/store'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule(name, CheckinFormStore)

            Init.setFieldValues(name)
        }
    },
    setFieldValues(name) {
        if (typeof ckiInit !== "undefined") {
            let pnr = ''
            if (ckiInit.pnr !== null) {
                pnr = ckiInit.pnr
                store.commit(`${name}/bookingRef`, ckiInit.pnr)
            }
            if (ckiInit.lastname !== null) {
                store.commit(`${name}/surname`, ckiInit.lastname)
            }

            if (ckiInit.asrError) {
              store.commit(`${name}/setAsrError`, true)
            }

            if (ckiInit.errorRef) {
              store.commit(`${name}/setErrorRef`, ckiInit.errorRef)
            }

            if (ckiInit.errors.length > 0) {
                gtmCKI.ckiError("2E errors", `${ckiInit.errors.join()}; ${pnr}`)
                ckiInit.errors.map(function (error) {
                    store.commit(`${name}/addError`, error)
                });
            }
        }
    }
}

export default Init
