import Files from 'store/modules/Pages/Claims/Form/Files.js';
import PnrRetrievalHttpService from 'services/AirBaltic/Forms/PnrRetrievalHttp';
var state = {
    data: {},
    features: [],
    origDestData: {}
};
var mutations = {
    setPnr: function (state, value) {
        if (!value) {
            return;
        }
        state.data = value;
    },
    setFeatures: function (state, value) {
        state.features = value;
    },
    setOrigDestData: function (state, value) {
        state.origDestData = value;
    }
};
var actions = {
    validatePnr: function (context, _a) {
        var pnrRl = _a.pnrRl, surname = _a.surname;
        return PnrRetrievalHttpService.validatePnr({ pnrRl: pnrRl, surname: surname })
            .then(function (data) {
            context.commit('setPnr', data);
        })
            .catch(function (error) {
            return Promise.reject(error);
        });
    },
    validateBookingCancelation: function () {
        return null;
    }
};
export default {
    modules: {
        files: Files
    },
    state: state,
    mutations: mutations,
    actions: actions,
    namespaced: true
};
