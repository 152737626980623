import PressReleasesService from 'services/AirBaltic/PressReleases'

const PressReleases = {
  state: {
    pageTitle: '',
    items: [],
    selectedYear: '',
    visibleItems: 5,
    perPage: 5,
    years: [],
    showYearSelect: true
  },
  mutations: {
    setItems(state, value) {
      state.items = value
    },
    setSelectedYear(state, value) {
      state.selectedYear = value
    },
    setYearsValues(state, value) {
      state.years = value
    },
    setVisibleItems(state, value) {
      state.visibleItems = value
    },
    setPerPage(state, value) {
      state.perPage = value
    },
    setShowYearSelect(state, value) {
      state.showYearSelect = value
    }
  },
  actions: {
    setSelectedYear({ commit, state }, data) {
      commit('setVisibleItems', state.perPage)
      commit('setSelectedYear', data)
    },
    setVisibleItems({ commit, state }) {
      commit('setVisibleItems', state.visibleItems + state.perPage)
    },
    getReleases({ commit, state, rootState }) {
      commit('main/setShowSmallLoader', true, { root: true })
      new PressReleasesService().getReleases(state.selectedYear, rootState.main.selectedLang)
        .then((data) => commit('setItems', data.releases))
        .catch(() => commit('setItems', []))
        .finally(() => commit('main/setShowSmallLoader', false, { root: true }))
    }
  },

  namespaced: true
}

export default PressReleases
