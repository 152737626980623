const PaxSelector = {
  state: {
    adults: 1,
    children: 0,
    infants: 0,
    youngAdults: 0,
    dropdown: {
      open: false
    },
    config: {
      minAdults: 1,
      maxAdults: 99,
      minChildren: 0,
      maxChildren: 8,
      minInfants: 0,
      maxInfants: 9,
      minYoungAdults: 0,
      maxYoungAdults: 9
    },
    groupBooking: false,
    infantCountExceeded: false,
    errors: []
  },
  getters: {
    errors (state) {
      return state.errors
    }
  },
  mutations: {
    showPaxDropdown (state) {
      state.dropdown.open = true
    },
    hidePaxDropdown (state) {
      state.dropdown.open = false
    },
    setAdults (state, value) {
      state.adults = value
    },
    setChildren (state, value) {
      state.children = value
    },
    setInfants (state, value) {
      state.infants = value
    },
    setYoungAdults (state, value) {
      state.youngAdults = value

      const hasYoungAdults = value > 0

      state.config.minAdults = hasYoungAdults ? 0 : 1
      state.adults = !hasYoungAdults && state.adults === 0 ? 1 : state.adults
    },
    setGroupBooking (state, value) {
      state.groupBooking = value
    },
    setInfantCountExceeded (state, value) {
      state.infantCountExceeded = value
    },
    setConfig (state, value) {
      state.config = value
    },
    addPaxError (state, errorKey) {
      if (state.errors.indexOf(errorKey) === -1) {
        state.errors.push(errorKey)
      }
    },
    removePaxError (state, errorKey) {
      const index = state.errors.indexOf(errorKey)
      if (index !== -1) {
        state.errors.splice(index, 1)
      }
    }
  },
  namespaced: true
}

export default PaxSelector
