import { alphaWithSpaces } from 'utils/validators';
import { email, required } from 'vuelidate/lib/validators';
var Contact = /** @class */ (function () {
    function Contact() {
        this.email = '';
        this.firstName = '';
        this.lastName = '';
        this.errorMsg = {
            email: {
                email: 'reusable.email.validation.invalid',
                missing: 'reusable.email.validation.missing',
            },
            firstName: {
                alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
                required: 'reusable.firstName.validation.required',
            },
            lastName: {
                alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
                required: 'reusable.lastName.validation.required',
            },
            title: {
                required: 'reusable.paxTitle.validation.required',
            },
        };
        this.validation = {
            form: {
                email: {
                    email: email,
                    missing: required,
                },
                firstName: {
                    alphaWithSpaces: alphaWithSpaces,
                    required: required,
                },
                lastName: {
                    alphaWithSpaces: alphaWithSpaces,
                    required: required,
                },
                title: {
                    missing: required,
                },
            },
        };
        this._title = 'Mr';
    }
    Object.defineProperty(Contact.prototype, "title", {
        get: function () {
            return this._title;
        },
        set: function (value) {
            var allowedTitles = ['Mr', 'Ms'];
            var newString = value;
            if (value !== null) {
                newString = value.charAt(0).toUpperCase() + value.slice(1).toLocaleLowerCase();
            }
            if (allowedTitles.indexOf(newString) !== -1) {
                this._title = value;
            }
        },
        enumerable: false,
        configurable: true
    });
    return Contact;
}());
export default Contact;
