<template>
  <div
    v-if="bestBuyBannersSet"
    class="best-buy-banner-wrapper"
  >
    <best-buy-banners-block
      :best-buy-banners="bestBuyBanners"
      :best-buy-countries="bestBuyCountries"
    />
  </div>
</template>
<script>
  import BestBuyBannersBlock from 'components/BestBuyBanners/BestBuyBannersBlock'
  import { mapState } from 'vuex'
  import DeviceMixin from 'containers/DeviceMixin'

  export default {
    components: {
      BestBuyBannersBlock
    },
    mixins: [
      DeviceMixin
    ],
    computed: {
      ...mapState('bestBuyBanners', [
        'bestBuyBanners',
        'bestBuyCountries',
      ]),
      bestBuyBannersSet () {
        return this.bestBuyBanners && Object.keys(this.bestBuyBanners).length
      }
    },
    mounted () {
      this.handleResize()
    },
    created () {
      window.addEventListener('resize', this.handleResize)
    },
    destroyed () {
      window.removeEventListener('resize', this.handleResize)
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "~assets/styles/common.scss";

  .best-buy-banner-wrapper {
    padding: 20px;
    background: white;
  }

  @include bp-mobile {

    /deep/ .dropdown-field {
      display: inline-block;
      right: 20px;
      position: absolute;
      top: 8px;
      margin-top: 0 !important;
      border: none !important;
    }
  }
</style>
