import PaxSelector from 'store/modules/Pages/GroupBooking/PaxSelector'
import Vue from 'vue'
import conf from 'conf'
import Calendar from 'store/modules/Pages/GroupBooking/Calendar'
import Step1Model from 'models/Pax/GroupBooking/Step1'
import { validationMixin } from 'vuelidate'
import moment from 'moment'
import Origin from 'store/modules/eHorizon/SearchForm/Origin'
import Destin from 'store/modules/eHorizon/SearchForm/Destin'
import FlightsDataService from 'services/AirBaltic/FlightsData'
import divolteGroupBooking from 'services/AirBaltic/Divolte/GroupBooking'

const step1 = new Step1Model()
const defaultState = Object.assign ({}, step1, {
  formValid: false,
  errors: [],
  loadingStatus: false,
  validationErrors: step1.errorMsg,
  returnData: {},
  departureData: {}
})

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations () {
    return { state: step1.validations }
  }
})

const Step1 = {
  namespaced: true,
  modules: {
    origin: Origin,
    destin: Destin,
    paxSelector: PaxSelector,
    calendar: Calendar
  },
  state: defaultState,
  mutations: {
    setDepartureData (state, value) {
      state.departureData = value
    },
    setReturnData (state, value) {
      state.returnData = value
    },
    toggleFlexible (state, value) {
      state.flexible = value
    },
    setTripType (state, newType) {
      state.tripType = newType
    },
    setFromValid (state, value) {
      state.formValid = value
    },
    addError (state, value) {
      state.errors.push(value)
    },
    setLoadingStatus (state, value) {
      state.loadingStatus = value
    },
    removeErrors (state) {
      state.errors = []
    }
  },
  getters: {
    $v () {
      return Object.assign ({}, validator.$v)
    },
    validationErrors (state) {
      return state.validationErrors
    },
    formErrors (state) {
      const errors = []
      const paxSelectorErrors = state.paxSelector.errors

      return errors.concat(state.errors, paxSelectorErrors)
    },
    formAction () {
      return conf.newIbeUrl + `/${Vue.i18n.locale()}/fb/histogram`
    }
  },
  actions: {
    clearStep ({ commit, dispatch, getters }) {
      commit('destin/setValue', {})
      dispatch('destin/onValueSelect', {})
      commit('destin/setInvalid', false)

      commit('paxSelector/setAdults', null)
      commit('paxSelector/setChildren', null)
      commit('paxSelector/setInfants', null)
      commit('paxSelector/showChildBlock', false)

      commit('toggleFlexible', false)
      commit('setTripType', 'return')

      commit('calendar/setDepartureErrors', [])
      commit('calendar/setReturnErrors', [])
      commit('calendar/updateCalendarReturnDate', '')
      commit('calendar/updateCalendarDepartureDate', new Date())
      commit('setFromValid', false)

      validator.$v.state.$reset()

      getters['destin/$v'].state.$reset()
      getters['paxSelector/$paxSelector'].state.$reset()
      getters['calendar/$v'].state.$reset()
    },
    validateOriginDestin ({ dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        dispatch('eHorizonSearchForm/validateComponents', null, { root: true })

        if (rootState.eHorizonSearchForm.formValid) {
          return resolve()
        } else {
          return reject('validateOriginDestin')
        }
      })
    },
    validateStep ({ dispatch, state }) {
      const rules = [
        dispatch('validateOriginDestin'),
        dispatch('paxSelector/validatePax'),
        dispatch('calendar/validateDeparturePromise'),
      ]

      if (['return', 'multi'].includes(state.tripType)) {
        rules.push(dispatch('calendar/validateReturnPromise'))
      }

      return Promise.all(rules)
        .then(() => Promise.resolve(true))
        .catch((e) => Promise.reject(e))
    },
    getTimetableDeparture ({ state }) {
      const date = moment(state.calendar.departureDate).format('YYYY-MM-DD')
      const form = {
        orig: state.origin.value.code,
        dest: state.destin.value.code,
        periodStart: date,
        periodEnd: date
      }

      return new FlightsDataService().getFlights(form)
    },

    getTimetableReturn ({ state }) {
      const date = moment(state.calendar.returnDate).format('YYYY-MM-DD')
      const form = {
        orig: state.destin.value.code,
        dest: state.origin.value.code,
        periodStart: date,
        periodEnd: date
      }

      return new FlightsDataService().getFlights(form)
    },
    getData ({ state }) {
      const paxSelector = state.paxSelector
      const data = {
        flightType: state.tripType === 'oneway' ? 'ONE_WAY' : 'ROUNDTRIP',
        origin: state.origin.value.code,
        destin: state.destin.value.code,
        flexible: state.flexible,
        departureDate: moment(state.calendar.departureDate).format('YYYY-MM-DD'),
        returnDate: null,
        passengerCount: {
          adults: paxSelector.adults !== null ? paxSelector.adults : "0",
          children: paxSelector.children !== null ? paxSelector.children : "0",
          infants: paxSelector.infants !== null ? paxSelector.infants : "0"
        }
      }

      if (state.tripType !== 'oneway') {
        data.returnDate =  moment(state.calendar.returnDate).format('YYYY-MM-DD')
      }

      return Promise.resolve(data)
    },
    searchDateSelection ({ rootState }) {
      divolteGroupBooking.searchDateSelection(rootState.groupBooking.step1)
    }
  }
}

export default Step1
