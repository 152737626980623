import Request from 'services/Request'
import isEmpty from 'lodash-es/isEmpty'

export default class Pimcore extends Request {

  ajax (data = {}) {
    let params = ''
    if (!isEmpty(data)) {
      Object.keys(data).forEach((key) => {
        params += encodeURIComponent(key) + '=' + encodeURIComponent(data[`${key}`]) + '&'
      })
    }

    return this.doRequest('post', '/ajax', params)
  }
}

