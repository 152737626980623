import get from 'lodash-es/get';
import { capitalize } from 'utils/TextTransformUtils';
var Passenger = /** @class */ (function () {
    function Passenger(passenger) {
        var _this = this;
        this.id = 0;
        this.name = '';
        this.firstName = '';
        this.lastName = '';
        this.title = '';
        this.initials = '';
        this.type = '';
        this.dob = '';
        this.email = '';
        this.phone = '';
        this.phoneNumber = '';
        this.intCode = '';
        this.hasInfants = false;
        this.isChild = false;
        this.isInfant = false;
        this.infants = {};
        this.services = {};
        this.abClubNumber = '';
        this.abClubLevel = '';
        this.hasError = false;
        this.phoneChanged = false;
        this.emailChanged = false;
        this.inProgress = false;
        if (typeof passenger !== 'undefined') {
            var firstName = capitalize(passenger.firstName);
            var lastName = capitalize(passenger.surname);
            var infantList = get(passenger, 'infants', []);
            var paxType = get(passenger, 'paxType', '');
            var contacts = get(passenger, 'contacts', []);
            this.id = passenger.id;
            this.name = capitalize(passenger.name);
            this.firstName = firstName;
            this.lastName = lastName;
            this.title = get(passenger, 'title', '');
            this.initials = this.getInitials(firstName, lastName);
            this.type = paxType;
            this.isChild = paxType === 'CHD';
            this.isInfant = paxType === 'INS';
            this.dob = get(passenger, 'dateOfBirth', '');
            this.hasInfants = infantList.length > 0;
            this.abClubNumber = get(passenger, 'frequentFlyerNumber', '');
            this.abClubLevel = get(passenger, 'frequentFlyerLevel', '');
            contacts.forEach(function (el) {
                if (el.email) {
                    _this.email = el.email.toLowerCase();
                }
                if (el.intCode) {
                    _this.intCode = el.intCode.startsWith('+') ? el.intCode : "+".concat(el.intCode);
                }
                if (el.phoneNumber) {
                    _this.phone = el.phoneNumber;
                    if (el.phoneNumber.includes(' ')) {
                        var phone = el.phoneNumber.split(' ');
                        _this.intCode = phone[0];
                        _this.phoneNumber = phone[1];
                    }
                    else {
                        _this.phoneNumber = el.phoneNumber;
                    }
                }
                if (typeof el.failed !== 'undefined') {
                    _this.hasError = el.failed;
                    _this.inProgress = true;
                }
            });
            if (this.hasInfants) {
                var infant = infantList[0];
                this.infants = {
                    name: capitalize(infant.name),
                    dob: infant.dateOfBirth
                };
            }
        }
    }
    Passenger.prototype.getInitials = function (firstName, lastName) {
        return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    };
    return Passenger;
}());
export default Passenger;
