import Api from 'services/AirBaltic/Api'
import ErrorHandler from 'utils/ErrorHandler'

const ERRORS = {}

class CharterForm {
  submitForm (data) {
    return new Api().post('/charters/pax/submit', data)
      .then(() => Promise.resolve())
      .catch(error => Promise.reject(this.getErrors(error.response)))
  }

  getErrors (response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default CharterForm
