import PinsStore from 'store/modules/Pages/ProfilePages/Pins'
import EditProfileStore from 'store/modules/Pages/ProfilePages/EditProfilePage'
import store from 'definitions/store'
import PaxProfile from 'services/AirBaltic/Pax/Profile'
import * as Sentry from '@sentry/vue'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, PinsStore)
      store.registerModule('editProfilePage', EditProfileStore)

      Init.initDefaultValues(name)
      Init.initPinsData(name)
    }
  },

  initDefaultValues(name) {
    store.commit('main/setPageType', 'profile')

    if (typeof pageData !== 'undefined') {
      store.commit(`${name}/setPageData`, pageData)

      if (typeof pageData.cards !== 'undefined') {
        const pinsLevels = Object.keys(pageData.cards)
        store.commit(`${name}/setPinsLevels`, pinsLevels)
      }
    }
  },

  initPinsData(name) {
    new PaxProfile()
      .getPaxProfileData()
      .then(({ pinsNumber }) => {
        const hasPinsNumber = pinsNumber?.length > 0 ?? false
        store.dispatch(`${name}/setPaxPinsData`, {
          existingPinsMember: hasPinsNumber,
          pinsNumber: hasPinsNumber ? pinsNumber : 0
        })
      })
      .catch((e) => Sentry.captureException(e))
  }
}

export default Init
