import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'intersection-observer'
import axios from 'axios'
import Vue from 'vue'
import vuexI18n from 'vuex-i18n'
import store from 'definitions/store'
import conf from 'conf'
import * as VueGoogleMaps from 'gmap-vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import SentryInit from './sentry'
import filters from './filters'
import VueCompositionAPI from '@vue/composition-api'

SentryInit.init()

const requireAndInitAll = function (require, queue) {
  for (const index in queue) {
    const moduleData = queue[index]
    if (moduleData.requirePath !== undefined) {
      const viewModule = require(moduleData.requirePath)
      const view = new viewModule.Main(moduleData.elemId)

      view.init()
    }
  }
}

const contextLayouts = require.context('../website/views', true, /^.*\/layouts\/.*\.(js|css|scss)$/)
const contextScripts = require.context('../website/views', true, /^.*\/scripts\/.*\.(js|css|scss)$/)

if (jsQueue) {
  const url = `${staticPath}${LANGUAGE}.js?v=${staticVersion}`
  axios.get(url)
    .then((response) => {
      Vue.use(vuexI18n.plugin, store)

      Vue.i18n.add(LANGUAGE, response.data)
      Vue.i18n.set(LANGUAGE)

      requireAndInitAll(contextLayouts, jsQueue.queue.layouts)
      requireAndInitAll(contextScripts, jsQueue.queue.scripts)

      Vue.use(VueGoogleMaps, {
        load: {
          key: conf.mapsApiKey,
          libraries: ['geometry']
        },
        installComponents: true
      })
      Vue.use(VueCompositionAPI)
    })
    .catch((e) => {
      console.log('e', e)
    })


    const TEMPORARY_ATTRIBUTE = "data-temp-href-target";

    Vue.use(VueDOMPurifyHTML, {
      hooks: {
        beforeSanitizeAttributes: (node) => {
          if (node.tagName === "A") {
            if (!node.hasAttribute("target")) {
              node.setAttribute("target", "_self");
            }

            if (node.hasAttribute("target")) {
              node.setAttribute(
                TEMPORARY_ATTRIBUTE,
                node.getAttribute("target")
              );
            }
          }
        },
        afterSanitizeAttributes: (node) => {
          if (node.tagName === "A" && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
            node.setAttribute("target", node.getAttribute(TEMPORARY_ATTRIBUTE));
            node.removeAttribute(TEMPORARY_ATTRIBUTE);
            if (node.getAttribute("target") === "_blank") {
              node.setAttribute("rel", "noopener");
            }
          }
        },
      },
    });

    Vue.use(filters)
}
