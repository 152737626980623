import * as moment from 'moment';
var Segment = /** @class */ (function () {
    function Segment(segment) {
        this.origin = '';
        this.destin = '';
        this.carrier = '';
        this.number = 0;
        this.departureDate = moment().add(1, 'year');
        this.excluded = [];
        this._flightNumber = '';
        if (typeof segment !== 'undefined') {
            this.carrier = segment.carrier;
            this.number = segment.number;
            this.origin = segment.origin;
            this.destin = segment.destin;
            this.departureDate = moment(segment.departureDate);
            this.flightNumber = this.carrier + this.number;
        }
    }
    Object.defineProperty(Segment.prototype, "flightNumber", {
        get: function () {
            return this._flightNumber;
        },
        set: function (value) {
            this._flightNumber = value;
        },
        enumerable: false,
        configurable: true
    });
    return Segment;
}());
export default Segment;
