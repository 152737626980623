import AnyDestination from 'store/modules/Pages/AnyDestination/AnyDestination'
import SearchFormStore from 'store/modules/eHorizon/SearchForm/SearchForm'
import OrigDestUtils from 'store/utils/eHorizonOrigDestUtils'
import OrigDest from 'utils/OrigDestData'
import SiloStore from 'store/modules/Pages/DestinationsSilo'
import store from 'definitions/store'
import getNearestAirport from 'utils/GetNearestAirport'

import CookieUtils from 'utils/CookieUtils'

const Init = {
    init(name) {

        if (!store.hasModule(name)) {
            store.registerModule(name, AnyDestination)
            Init.initDefaultValues(name)
        }

        if (!store.hasModule('eHorizonSearchForm')) {
            store.registerModule('eHorizonSearchForm', SearchFormStore)
        }

        if (!store.hasModule('destinationsSilo')) {
            store.registerModule('destinationsSilo', SiloStore)
        }

        Init.initOrigDestData()
        Init.initSearchFormConfig()
        Init.initSiloDefaultValues()
    },
    initDefaultValues(name) {
        if (typeof pageData !== "undefined") {
            store.commit(`${name}/setPageData`, pageData)
        }
    },

    initSearchFormConfig() {
        store.commit(`eHorizonSearchForm/setGroupBookingUrl`, GROUP_BOOKING_URL)
    },

    initOrigDestData() {
        if (typeof origDestLabels !== 'undefined') {
            store.commit(`eHorizonSearchForm/destin/setLabels`, origDestLabels)
        }

        if (typeof origDestData !== 'undefined') {
            const data = OrigDest.parseData(origDestData)
            store.commit(`eHorizonSearchForm/setOrigDestData`, data)
            store.commit(`eHorizonSearchForm/origin/setDestinations`, OrigDestUtils.prepareData(data).filter(o => o.isOrig))
            const nearestAirport = getNearestAirport()
            if (nearestAirport && typeof data[nearestAirport] !== 'undefined') {
                store.dispatch(`eHorizonSearchForm/selectOrigin`, data[nearestAirport])
            }
        }

        const country = CookieUtils.getCookie('country') || null
        if (country) {
            store.dispatch(`eHorizonSearchForm/origin/setHomeCountry`, country)
        }
    },

    initSiloDefaultValues() {
        if (typeof destinationsSiloInit !== "undefined") {

            if (typeof destinationsSiloInit.country !== "undefined" && destinationsSiloInit.country !== null) {
                store.commit('destinationsSilo/setCountryFromUrl', destinationsSiloInit.country)
            }

            store.commit(`destinationsSilo/setLabelOrder`, destinationsSiloInit.labelOrder || '')
        }

        if (typeof origDestData !== 'undefined') {
            store.dispatch(`destinationsSilo/setOrigDestData`, OrigDest.parseData(origDestData))
        }

        if (typeof origDestLabels !== 'undefined') {
            store.commit('destinationsSilo/setLabels', origDestLabels)
        }
    }
}

export default Init
