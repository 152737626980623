import GroupBookingStore from 'store/modules/Pages/GroupBooking'
import SearchFormStore from 'store/modules/eHorizon/SearchForm/SearchForm'
import OrigDestUtils from 'store/utils/eHorizonOrigDestUtils'
import CookieUtils from 'utils/CookieUtils'
import store from 'definitions/store'
import OrigDest from 'utils/OrigDestData'
import getNearestAirport from 'utils/GetNearestAirport'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, GroupBookingStore)
      store.registerModule('eHorizonSearchForm', SearchFormStore)

      Init.initDefaultValues(name)
      Init.initOrigDestData(name)
    }
  },

  initDefaultValues(name) {
    if (typeof pageData !== "undefined") {
      store.commit(`${name}/setPageData`, pageData)
    }

    if (typeof contacts !== "undefined") {
      store.commit(`${name}/setContacts`, contacts)
    }

    if (typeof termsAndConditions !== "undefined") {
      store.commit(`${name}/setTermsAndConditions`, {
        title: termsAndConditions.title ? termsAndConditions.title.text : '',
        content: termsAndConditions.content ? termsAndConditions.content.text : ''
      })
    }

    if (typeof groupBookingFormData !== 'undefined') {
      store.dispatch(`${name}/generic/setGenericData`, groupBookingFormData)
      store.commit(`${name}/generic/setActiveStep`, 'step1')
      store.commit(`${name}/generic/setTermsAndConditions`,  {
        title: groupBookingFormData.terms ? groupBookingFormData.terms.title : '',
        content: groupBookingFormData.terms ? groupBookingFormData.terms.content : ''
      })
      store.commit(`${name}/generic/setTermsAndConditionsVisible`,  groupBookingFormData.terms ? groupBookingFormData.terms.visible : false)

      store.commit(`${name}/step3/setServices`,  groupBookingFormData.services)
    }
  },

  initOrigDestData(name) {
    const nearestAirport = getNearestAirport()

    if (typeof origDestData !== 'undefined') {
      const data = OrigDest.parseData(origDestData)
      store.commit(`eHorizonSearchForm/setOrigDestData`, data)

      store.commit(`${name}/step1/origin/setDestinations`, OrigDestUtils.prepareData(data).filter(o => o.isOrig))

      if (nearestAirport && typeof data[nearestAirport] !== 'undefined') {
        store.dispatch(`eHorizonSearchForm/selectOrigin`, data[nearestAirport])
      }
    }

    if (typeof groupBookingCountries !== 'undefined') {
      store.commit(`${name}/generic/setCountries`, groupBookingCountries)
    }

    const country = CookieUtils.getCookie('country') || null
    if (country) {
      store.dispatch(`${name}/step1/origin/setHomeCountry`, country)
    }
  }
}

export default Init
