export default function () {
  return {
    namespaced: true,
    state: {
      filter: '',
      value: {},
      open: false,
      options: []
    },
    mutations: {
      setFilter (state, value) {
        state.filter = value
      },
      setValue (state, value) {
        state.value = value
      },
      setOpen (state, value) {
        state.open = value
      },
      open (state) {
        state.open = true
      },
      close (state) {
        state.open = false
      },
      setOptions (state, value) {
        state.options = value
      }
    }
  }
}
