import AuthorizedApi from 'services/AirBaltic/AuthorizedApi'
import Booking from 'models/Pax/Bookings/Booking'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'
import moment from 'moment'

const privateMethods = {
  calculateDaysTillDeparture(departureDate) {
    if (isEmpty(departureDate)) {
      return 0
    }

    const today = moment().startOf('day')
    const departure = moment(departureDate).startOf('day')

    return moment(today).diff(moment(departure), 'days') * -1
  }
}

class PaxBookings {
  convertToBookings(bookingsJson) {
    const allBookings = []

    bookingsJson.forEach((booking) => {
      booking.directions.forEach((direction) => {
        direction.segments.forEach((segment) => {
          const singleBooking = new Booking()

          singleBooking.pnrRl = get(booking, 'pnrRl', '')
          singleBooking.pnrRetrieveToken = get(booking, 'pnrRetrieveToken', '')

          singleBooking.scheduleChange = get(direction, 'scheduleChange', '')

          singleBooking.carrier = get(segment, 'carrier', '')
          singleBooking.flightNumber = get(segment, 'flightNumber', '')
          singleBooking.arrivalDate = get(segment, 'arrival', '')
          singleBooking.departureDate = get(segment, 'depart', '')
          singleBooking.origin.code = get(segment, 'orig', '')
          singleBooking.destin.code = get(segment, 'dest', '')
          singleBooking.origin.description = get(segment, 'origDescription', '')
          singleBooking.destin.description = get(segment, 'destDescription', '')
          singleBooking.daysTillDeparture = privateMethods.calculateDaysTillDeparture(
            singleBooking.departureDate
          )

          allBookings.push(singleBooking)
        })
      })
    })

    return allBookings
  }

  convertAccountBookings(bookingsJson) {
    const segments = get(bookingsJson, 'bookingSegments', [])
    const allBookings = []

    segments.forEach((item) => {
      const singleBooking = new Booking()

      singleBooking.pnrRl = get(item, 'pnrRl', '')
      singleBooking.carrier = get(item, 'carrier', '')
      singleBooking.flightNumber = get(item, 'flightNumber', 0)
      singleBooking.origin.code = get(item, 'origin', '')
      singleBooking.destin.code = get(item, 'destin', '')
      singleBooking.departureDate = get(item, 'departureDate', '')
      singleBooking.arrivalDate = get(item, 'arrivalDate', '')
      singleBooking.passengers = get(item, 'passengers', [])
      singleBooking.daysTillDeparture = privateMethods.calculateDaysTillDeparture(
        singleBooking.departureDate
      )
      singleBooking.pnrCreationDatetime = moment(
        get(item, 'pnrCreationDatetime', [])
      ).format('YYMMDD')
      allBookings.push(singleBooking)
    })

    return allBookings
  }

  getPaxBookingsData() {
    return new AuthorizedApi()
      .get('/mmb/pnr/retrieve/list')
      .then((response) => {
        return Promise.resolve(this.convertToBookings(response.data))
      })
      .catch(() => Promise.reject([]))
  }

  getPaxAccountBoookingsData() {
    return new AuthorizedApi()
      .get('/profile/bookings/list')
      .then((response) => {
        return Promise.resolve(this.convertAccountBookings(response.data))
      })
      .catch(() => Promise.reject([]))
  }
}

export default PaxBookings
