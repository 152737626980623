var Request = /** @class */ (function () {
    function Request(obj) {
        if (obj === void 0) { obj = {}; }
        this.requestTitle = '';
        this.sportEquipmentRequestTitle = '';
        this.sportEquipmentRequestDescription = '';
        this.heavyCabinBaggageRequestTitle = '';
        this.heavyCabinBaggageRequestDescription = '';
        this.checkedBaggageRequestTitle = '';
        this.checkedBaggageRequestDescription = '';
        Object.assign(this, obj);
    }
    return Request;
}());
export default Request;
