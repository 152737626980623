import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import evePaymentsInit from 'initializations/EvePayments'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    evePaymentsInit.init("evePayments")

    const EveSuccess = () => import(/* webpackChunkName: "eve-success" */ 'containers/Pages/EvePayments/Success')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(EveSuccess)
    })
  }
}
