import PaymentGeneric from 'store/modules/Pages/Payment/Generic'
import PnrValidation from 'store/modules/Pages/Payment/PnrValidation'
import Default from 'store/modules/Pages/Payment/Forms/Default'
import Company from 'store/modules/Pages/Payment/Sections/Company'
import FlightDetails from 'store/modules/Pages/Payment/Sections/FlightDetails'
import Passengers from 'store/modules/Pages/Payment/Sections/Passengers'
import Contact from 'store/modules/Pages/Payment/Sections/Contact'
import Other from 'store/modules/Pages/Payment/Forms/Other'
import Extras from 'store/modules/Pages/Payment/Forms/Extras'
import Request from 'store/modules/Pages/Payment/Sections/Request'
import Segments from 'store/modules/Pages/Payment/Sections/Segments'
import GVO from 'store/modules/Pages/Payment/Sections/GVO'
import FlightList from 'store/modules/Pages/Claims/Sections/Flights'
import CSS from 'store/modules/Pages/Payment/CSS'
import Payment from 'models/Payments/Payment'

export default {
  namespaced: true,
  modules: {
    generic: PaymentGeneric,
    validation: PnrValidation,
    defaultForm: Default,
    other: Other,
    company: Company,
    flightDetails: FlightDetails,
    passengers: Passengers,
    contact: Contact,
    request: Request,
    segments: Segments,
    extras: Extras,
    gvo: GVO,
    flightList: FlightList,
    css: CSS
  },
  state: new Payment(),
  actions: {
    clearData ({ commit }) {
      commit('setPnr', null)
      commit('setFlightNumber', null)
      return Promise.resolve(true)
    }
  },
  mutations: {
    setFormName (state, value) {
      state.formName = value
    },
    setFormLanguage (state, value) {
      state.language = value
    },
    setFlightNumber (state, value) {
      state.flightNumber = value
    },
    setPnrData (state, value) {
      state.pnrData = value
    },
    setPnr (state, value) {
      let bookingRef = value
      if (bookingRef !== null) {
        bookingRef = bookingRef.trim().toUpperCase()
      }
      state.bookingRef = bookingRef
    },
    setIsCharter(state, value) {
      state.isCharter = value
    }
  }
}
