import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import moment from 'moment'
import FlightDetailsSection from 'models/Payments/Sections/FlightDetailsSection'
import FlightDetails from 'models/Payments/FlightDetails'
import PaymentGeneric from 'store/modules/Pages/Payment/Generic'

const flightDetails = new FlightDetails()
const formData = {
  form: flightDetails,
  flightDay: '',
  flightMonth: '',
  flightYear: '',
  formErrors: flightDetails.errorMsg
}
const defaultState = Object.assign({}, new FlightDetailsSection(), formData)

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    const data = new FlightDetails().validation
    if (
      ['invoices', 'booking_cancellation'].includes(PaymentGeneric.state.activeItem.key)
    ) {
      delete data.form.flightDate.dateAfterToday
      delete data.form.flightDate.date12HoursBefore
    }

    if (PaymentGeneric.state.activeStep === 'defaultForm') {
      delete data.form.flightDate.date12HoursBefore
    } else {
      delete data.form.flightDate.dateAfterToday
    }

    return {
      state: data
    }
  }
})

export default {
  namespaced: true,
  state: defaultState,
  actions: {
    setFlightDetailsData({ commit }, data) {
      commit('setFlightDetailsTitle', data.flightDetailsTitle)
      commit('setFlightDetailsDescription', data.flightDetailsDescription)
    },
    setFlightInfo({ commit }, value) {
      commit('setFlightNumber', value.flightNumber)
      commit('setFlightDate', value.departureDate)
      commit('setFlightDay', value.departureDate.format('DD'))
      commit('setFlightMonth', value.departureDate.format('MM'))
      commit('setFlightYear', value.departureDate.format('YYYY'))
    },
    setFlightDate({ state, commit }) {
      const flightDate = `${state.flightYear}-${state.flightMonth}-${state.flightDay}`
      const date = moment(flightDate, 'YYYY-M-D', true)
      if (date.isValid()) {
        commit('setFlightDate', date)
      } else {
        commit('setFlightDate', null)
      }
    },
    validateFlightDetails() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('flightDeatails')
      })
    },
    clearFlightDetails({ commit }) {
      const data = new FlightDetails()

      let day = ''
      let month = ''
      let year = ''

      if (data.flightDate !== '') {
        day = data.flightDate.format('DD')
        month = data.flightDate.format('MM')
        year = data.flightDate.format('YYYY')
      }

      commit('setFlightNumber', data.flightNumber)
      commit('setFlightDate', data.flightDate)
      commit('setFlightDay', day)
      commit('setFlightMonth', month)
      commit('setFlightYear', year)
      validator.$v.state.form.$reset()
    }
  },
  mutations: {
    setFlightNumber(state, value) {
      state.form.flightNumber = value.toUpperCase()
    },
    setFlightDay(state, value) {
      state.flightDay = value
    },
    setFlightMonth(state, value) {
      state.flightMonth = value
    },
    setFlightYear(state, value) {
      state.flightYear = value
    },
    setFlightDate(state, value) {
      let date = value
      if (value === null) {
        date = {}
      }
      state.form.flightDate = date
    },
    setFlightDetailsTitle(state, value) {
      state.flightDetailsTitle = value
    },
    setFlightDetailsDescription(state, value) {
      state.flightDetailsDescription = value
    }
  },
  getters: {
    $flightDetails() {
      return Object.assign({}, validator.$v.state.form)
    },
    flightDetailsValidationFormErrors(state) {
      return state.formErrors
    }
  }
}
