<template>
  <div class="login-content wide main-content">
    <div class="wrap">
      <h1>{{ pageTitle }}</h1>
      <p
        v-if="description !== ''"
        class="login-hint"
      >
        {{ description }}
        <a
          href="javascript:void(0)"
          @click="openForgotPassword()"
        >
          {{ resetPasswordLinkText }} </a
        >.
      </p>
      <div class="login-main-block">
        <login-form />
      </div>
    </div>

    <small-loader />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import LoginForm from 'components/ProfilePages/LoginForm'
  import ScrollUtils from 'utils/scrollUtils'
  import UrlParams from 'utils/UrlParams'
  import SmallLoader from 'components/SmallLoader'

  export default {
    components: {
      LoginForm,
      SmallLoader
    },
    computed: {
      ...mapState('login', [
        'pageTitle',
        'description',
        'resetPasswordLinkText',
        'indexUrl'
      ])
    },
    watch: {
      '$store.state.main.user.isUserLogged': function (newVal) {
        if (newVal && typeof this.indexUrl !== 'undefined') {
          window.location = this.indexUrl
        }
      }
    },
    mounted() {
      this.getEmailFromUrl()
    },
    methods: {
      openForgotPassword() {
        const ForgotPasswordForm = import(
          /* webpackChunkName: "white-forgot-password-form" */ 'components/LoginForm/White/ForgotPasswordForm.vue'
        )
        ForgotPasswordForm.then((result) => {
          this.$store.commit('main/setModalContent', result.default)
        })

        this.$store.dispatch('main/openModal', { root: true })
        this.$store.commit('main/setShowModalHeader', false)
        this.$store.commit('main/setShowModalHeaderWhite', false)
        this.$store.commit('main/setShowModalHeaderWhiteNav', false)
        this.$store.commit('main/setModalType', 'small')
        this.$store.commit('main/setModalClass', 'modal-white-small')
        this.$store.commit('main/setShowCloseIcon', true)
        this.$store.commit('main/setModalCloseFn', this.closeModal, { root: true })
      },

      closeModal() {
        this.$store.dispatch('main/closeModal')
        ScrollUtils.enableScroll()
        this.$store.dispatch('loginForm/forgotPassword/resetForm')
      },

      getEmailFromUrl() {
        const email = UrlParams.getUrlParam(window.location.href, 'email')
        if (email) {
          this.$store.commit('loginForm/setLogin', email)
        }
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';

  .login-content {
    padding: 26px 23px 40px;

    @include bp-tablet-only {
      padding: 42px 26px 80px;
    }

    @include bp-mf-tablet {
      background-color: $formBlock;
    }

    @include bp-mf-desktop {
      padding-top: 48px;
      padding-bottom: 88px;
    }

    h1,
    .login-hint,
    .login-main-block {
      max-width: 400px;
    }

    h1 {
      margin: 0 auto 13px;
      font-size: em(32);
      font-weight: 300;
      line-height: 38px;
      text-align: center;

      @include bp-mf-tablet {
        margin-bottom: 16px;
        font-size: em(40);
        line-height: 47px;
      }
    }
  }

  .login-hint {
    margin: 0 auto 27px;
    text-align: center;
    line-height: 20px;

    @include bp-mf-tablet {
      margin-bottom: 29px;
    }

    a {
      color: $blue-medium;

      &:hover {
        color: $blue-light;
      }
    }
  }

  .login-main-block {
    margin: 0 auto;

    @include bp-mf-tablet {
      padding: 50px 56px;
      background-color: #ffffff;
      border: 1px solid $gray-light-border;
      border-radius: 6px;
    }
  }
</style>
