import CookieUtils from 'utils/CookieUtils'

class JwtUtils {
  static getJwtContentFromCookie (cookieName) {
    const cookie = CookieUtils.getCookie(cookieName)

    if (cookie === '') {
      return false
    }

    return this.decodeJwtToken(cookie)
  }

  static decodeJwtToken (token) {
    if (!token) {
      return false
    }

    const base64Url = token.split('.')[1]

    if (!base64Url) {
      return false
    }

    const base64 = base64Url.replace('-', '+').replace('_', '/')

    return JSON.parse(window.atob(base64))
  }
}

export default JwtUtils
