import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { email, minLength, or, required } from 'vuelidate/lib/validators'
import { alphaWithSpaces, pnr, ticketNumber } from 'utils/validators'
import RewardRequestUpgradeService from 'services/AirBaltic/Forms/RewardUpgradeRequest'
import PnrValidation from 'store/modules/Forms/PnrValidation'
import PnrValidationModel from 'models/Forms/PnrValidation'
import Pnr from 'store/modules/Pages/Claims/Form/Pnr'
import PnrRetrievalService from 'services/AirBaltic/Forms/PnrRetrievalHttp'
import PnrRetrieve from 'services/AirBaltic/Forms/PnrRetrieve'
import GetFlights from 'services/AirBaltic/Forms/PnrGetFlightData'
import Flights from 'store/modules/Forms/Flights'

const pnrValidationModel = new PnrValidationModel()

const formErrors = {
  pinsData: {
    firstName: {
      alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
      required: 'reusable.firstName.validation.required'
    },
    lastName: {
      alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
      required: 'reusable.lastName.validation.required'
    },
    email: {
      required: 'reusable.email.validation.missing',
      email: 'reusable.email.validation.invalid'
    },
    pinsNumber: {
      required: 'generic.errors.fieldEmpty',
      minLength: 'pinsReward.pinsNumber.validation.invalid'
    }
  },
  pnrData: pnrValidationModel.errorMsg,
  passengers: {
    flights: {
      required: 'generic.errors.fieldEmpty'
    }
  }
}

const defaultState = {
  pageData: {
    pageTitle: '',
    description: '',
    warning: '',
    pinsCardTitle: '',
    pinsCardDescription: '',
    bookingDataTitle: '',
    bookingDataDescription: '',
    flightAndPaxTitle: '',
    flightAndPaxDescription: '',
    termsAndConditionLinkText: '',
    termsAndConditionTitle: '',
    termsAndConditionContent: ''
  },
  pinsData: {
    firstName: '',
    lastName: '',
    email: '',
    pinsNumber: '',
    backendError: false,
    isSetFromPins: false
  },
  pnrData: {
    pnr: '',
    surname: ''
  },
  pnrRules: [
    'NO_ACTIVE_FLIGHTS_CLASS_UPGRADE',
    'NO_ACTIVE_FLIGHTS_AFTER_24H_OR_NO_BT_FLIGHTS'
  ],
  flightsData: {
    passengers: [],
    flights: [],
    upgradePassengers: [],
    isGroupBooking: false
  },
  activeStep: 1,
  formErrors: formErrors
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    return {
      state: {
        pinsData: {
          firstName: {
            alphaWithSpaces,
            required
          },
          lastName: {
            alphaWithSpaces,
            required
          },
          email: {
            required,
            email
          },
          pinsNumber: {
            required,
            minLength: minLength(10)
          }
        },
        pnrData: {
          pnr: {
            pnrOrTicketNumber: or(pnr, ticketNumber),
            required
          },
          surname: {
            alphaWithSpaces,
            minLength: minLength(1),
            required
          }
        },
        passengers: {
          flights: {
            required
          }
        }
      }
    }
  }
})

const Reward = {
  modules: {
    pnrValidation: PnrValidation,
    pnr: Pnr,
    flights: Flights
  },
  state: defaultState,
  mutations: {
    setPageData(state, value) {
      state.pageData = value
    },
    setActiveStep(state, value) {
      state.activeStep = value
    },
    setPinsFirstName(state, value) {
      state.pinsData.firstName = value
    },
    setPinsLastName(state, value) {
      state.pinsData.lastName = value
    },
    setPinsEmail(state, value) {
      state.pinsData.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setPinsNumber(state, value) {
      state.pinsData.pinsNumber = value
    },
    setIsSetFromPins(state, value) {
      state.pinsData.isSetFromPins = value
    },
    setBackendPinsError(state, value) {
      state.pinsData.backendError = value
    },
    setPnr(state, value) {
      state.pnrData.pnr = value
    },
    setSurname(state, value) {
      state.pnrData.surname = value
    },
    setIsGroupBooking(state, value){
      state.flightsData.isGroupBooking = value
    },
    setPassengers(state, value) {
      state.flightsData.passengers = value
    },
    setFlights(state, value) {
      state.flightsData.flights = value
    },
    setUpgradePassengers(state, value) {
      state.flightsData.upgradePassengers = value
    },
    setDefaultForm(state) {
      state.pinsData = {
        firstName: '',
        lastName: '',
        email: '',
        pinsNumber: '',
        backendError: false,
        isSetFromPins: false
      }
      state.pnrData = {
        pnr: '',
        surname: ''
      }
      state.flightsData = {
        passengers: [],
        flights: []
      }
    }
  },

  actions: {
    touch(context, validateData) {
      return new Promise((resolve) => {
        const vState = validator.$v.state[validateData.type]

        vState.$touch()
        if (!vState.$invalid) resolve(true)
      })
    },
    clearForm({ commit }) {
      validator.$v.state.$reset()

      commit('setBackendPinsError', false)
      commit('setDefaultForm')
    },

    validatePins({ state, dispatch }) {
      const rules = [dispatch('touch', { type: 'pinsData' })]

      return Promise.all(rules)
        .then(() => {
          const postObj = {
            number: state.pinsData.pinsNumber.toString(),
            name: `${state.pinsData.firstName} ` + `${state.pinsData.lastName}`
          }

          return new RewardRequestUpgradeService()
            .validatePins(postObj)
            .then((response) => Promise.resolve(response))
            .catch((error) => {
              if (error.fieldErrors) {
                dispatch('setFieldErrors', error.fieldErrors)
              }
              return Promise.reject(error)
            })
        })
        .catch((e) => Promise.reject(e))
    },

    validatePnrForm({ dispatch, commit }) {
      const rules = [dispatch('touch', { type: 'pnrData' })]

      return Promise.all(rules)
        .then(() => {
          commit('pnr/removeError', {
            name: 'generic',
            msg: 'claims.pnr.form.errors.generic.invalid'
          })

          return new Promise((resolve, reject) => {
            dispatch('pnrSubmitForm')
              .then((res) => dispatch('pnrValidationSuccessful', { data: res }))
              .then((data) => resolve(data))
              .catch((e) => reject(e))
          })
        })
        .catch((e) => Promise.reject(e))
    },

    pnrSubmitForm({ state }) {
      let pnrRl = state.pnrData.pnr
      let surname = state.pnrData.surname
      return new Promise((resolve, reject) => {
        return PnrRetrievalService.validatePnr({ pnrRl, surname })
          .then((data) => resolve(data))
          .catch((error) => reject(error))
      })
    },

    pnrValidationSuccessful({ state, commit, dispatch }, res) {
      const data = {
        response: res,
        rules: state.pnrRules
      }

      return PnrRetrieve.validateBooking(data)
        .then(() => {
          commit('pnr/removeErrors')
          dispatch('setPaxData', { pax: res.data })
          return Promise.resolve(res.data)
        })
        .catch((e) => Promise.reject(e))
    },

    setPaxData({ commit }, data) {
      const flightData = GetFlights.getFlightData(data.pax.segments)

      commit('flights/setHasFlights', !!flightData.length)
      commit('flights/setFlights', flightData)
      commit('setIsGroupBooking', data.pax.header.group)
    },

    submitRequestUpgradeForm({ state, dispatch }) {
      const languageFilter = ['EN', 'RU', 'LV']
      const localeLang = Vue.i18n.locale().toUpperCase()

      return new Promise((resolve, reject) => {
        const postObj = {
          cardHolder: {
            cardNumber: state.pinsData.pinsNumber.toString(),
            language: languageFilter.includes(localeLang) ? localeLang : 'EN',
            firstName: state.pinsData.firstName,
            lastName: state.pinsData.lastName,
            email: state.pinsData.email
          },
          bookingRef: state.pnrData.pnr,
          passengers: state.flightsData.upgradePassengers
        }

        return new RewardRequestUpgradeService()
          .submitForm(postObj)
          .then((response) => resolve(response))
          .catch((error) => {
            if (error.fieldErrors) {
              dispatch('setFieldErrors', error.fieldErrors)
            }
            return reject(error)
          })
      })
    },

    setFieldErrors({ commit }, errors) {
      if (!errors) {
        return
      }

      if (
        errors['cardHolder.cardNumber'] ||
        errors['cardHolder.firstName'] ||
        errors['cardHolder.lastName']
      ) {
        commit('setBackendPinsError', true)
      }
    }
  },

  getters: {
    $v() {
      return Object.assign({}, validator.$v.state)
    }
  },

  namespaced: true
}

export default Reward
