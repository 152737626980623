<template>
  <a
    :href="clickable ? '/' : null"
    title="airBaltic homepage"
    tabindex="0"
    class="logo-wrapper"
    :class="logo.background"
    @click="resetTab"
  >
    <img
      v-if="Object.keys(logo.image).length"
      class="test-logo"
      :src="logo.image.source"
      :alt="logo.image.alt"
      :width="isDesktop ? 112 : 70"
      :height="isDesktop ? 50 : 30"
    />
  </a>
</template>

<script>
  import CookieUtils from 'utils/CookieUtils'
  import { mapState, mapGetters } from 'vuex'

  export default {
    props: {
      clickable: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters('device', ['isDesktop']),
      ...mapState('header', ['logo'])
    },
    methods: {
      resetTab() {
        CookieUtils.setCookie('activeTab', 'FLT', 0)
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';

  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 92px;
    height: 56px;

    &.green-base {
      background: $green-base;
    }

    &.white {
      background: white;
      border-bottom: 1px solid $gray-divider;
    }

    &.blue-dark {
      background: $blue-dark;
    }

    @include bp-mf-desktop {
      width: 160px;
      height: 80px;
    }

    img {
      top: 0;
      position: relative;
      left: 0;
    }
  }
</style>
