import sortBy from 'lodash-es/sortBy'
import Segment from 'models/Payments/Segment'

export default {
  namespaced: true,
  state: {
    segments: []
  },
  actions: {
    convertAndSetSegments ({ commit }, flights) {
      const convertedSegments = []

      sortBy(flights, ['departureDate']).map((segment, key) => {
        convertedSegments[`segment_${key}`] = new Segment(segment)
      })

      commit('setSegments', convertedSegments)
    },
    clearSegments ({ state, commit }, passengers) {
      if (passengers) {
        passengers.map((passenger) => {
          Object.keys(state.segments).map((segment) => {
            commit(`payment/passengers/${passenger.namespace}/${segment}/clearSegment`, null, {root: true})
          })
        })
      }

      commit('setSegments', [])
    }
  },
  mutations: {
    setSegments (state, value) {
      state.segments = Object.assign({}, value)
    }
  }
}
