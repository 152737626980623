import get from 'lodash-es/get'
import isObject from 'lodash-es/isObject'
import has from 'lodash-es/has'

const GENERIC_SERVER_ERROR = 'generic.errors.serverError'
const ERROR_VALIDATION_FAILED = 'generic.errors.validationFailure'
const VALIDATION_FAILURE = 'VALIDATION_FAILURE'
const NOT_FOUND = 'generic.errors.notFound'
const EMAIL_FAKE_OR_INVALID = 'generic.errors.emailFakeOrInvalid'
const TOO_MANY_SUBSCRIPTION_ATTEMPTS = 'generic.errors.tooManySubscriptionAttempts'

class ErrorHandler {
  static getErrorTranslationKey (serviceErrors, response, check404 = false) {

    if (check404 && this.is404Error(response)) {
      return NOT_FOUND
    }

    if (!this.isResponseValid(response)) {
      return GENERIC_SERVER_ERROR
    }

    return this.getServiceErrors(serviceErrors, response)
  }

  static is404Error (response) {
    return get(response, 'status') === 404
  }

  static isResponseValid (response) {
    return has(response, 'status') || has(response, 'data.code')
  }

  static getServiceErrors (serviceErrors, response) {
    const errorCode = get(response, 'data.code', null)

    if (errorCode === VALIDATION_FAILURE) {
      const validationErrors = this.getInvalidFields(serviceErrors, response.data)

      if (validationErrors.length !== 0) {
        return validationErrors
      }

      return ERROR_VALIDATION_FAILED
    }

    if (errorCode === 'EMAIL_FAKE_OR_INVALID') {
      return EMAIL_FAKE_OR_INVALID
    }

    if (errorCode === 'TOO_MANY_SUBSCRIPTION_ATTEMPTS') {
      return TOO_MANY_SUBSCRIPTION_ATTEMPTS
    }

    return has(serviceErrors, errorCode) ? serviceErrors[`${errorCode}`] : GENERIC_SERVER_ERROR
  }

  static getInvalidFields (serviceErrors, response) {
    const fieldErrors = get(response, 'fieldErrors', {})

    const errors = []

    Object.keys(fieldErrors)
      .forEach((field) => {
        const errorField = get(fieldErrors, field, null)
        let fieldErrorCode = null

        if (isObject(errorField)) {
          fieldErrorCode = errorField.code.toUpperCase()
        } else {
          fieldErrorCode = errorField.toUpperCase()
        }

        const errorCodeName = `${field.toUpperCase()}_${fieldErrorCode}`
        if (has(serviceErrors, errorCodeName)) {
          errors.push(serviceErrors[`${errorCodeName}`])
        }
      })

    return errors
  }
}

export default ErrorHandler
