import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import siloPageInit from 'initializations/pages/SiloPage'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    siloPageInit.init("siloPage")

    const SiloPage = () => import(/* webpackChunkName: "silo-page" */ 'containers/Pages/Silo')
    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(SiloPage)
    })
  }
}
