const MealsPage = {
  state: {
    pageTitle: '',
    pleaseNote: '',
    economyClassText: '',
    businessClassText: '',
    economyData: {},
    businessData: {},
    menuItems: [],
    menuData: {},
    termsAndConditionsVisible: false,
    termsAndConditions: {}
  },
  mutations: {
    setPageTitle (state, value) {
      state.pageTitle = value
    },

    setPleaseNote (state, value) {
      state.pleaseNote = value
    },

    setEconomyClassText (state, value) {
      state.economyClassText = value
    },

    setBusinessClassText (state, value) {
      state.businessClassText = value
    },

    setEconomyData (state, value) {
      state.economyData = value
    },

    setBusinessData (state, value) {
      state.businessData = value
    },

    setMenuItems (state, value) {
      state.menuItems = value
    },

    setMenuData (state, value) {
      state.menuData = value
    },

    setTermsAndConditionsVisible (state, value) {
      state.termsAndConditionsVisible = value
    },

    setTermsAndConditions (state, value) {
      state.termsAndConditions = value
    }
  },

  namespaced: true
}

export default MealsPage
