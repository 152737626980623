import Vue from 'vue'
import store from 'definitions/store'
import bbbInit from 'initializations/BestBuyBanners'
import eHorizonSearchFormInit from 'initializations/eHorizon/SearchForm'
import AbstractView from 'abstract'
import mainInit from 'initializations/Main'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    mainInit.init("main")
    eHorizonSearchFormInit.init("eHorizonSearchForm")
    bbbInit.init("bestBuyBanners")

    new Vue({
      mixins: [],
      store
    }).$mount('#bestBuyBannersWrapper')
  }
}
