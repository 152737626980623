import AbstractView from 'abstract'
import Vue from 'vue'
import store from 'definitions/store'
import storeInit from 'initializations/pages/Gallery'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    storeInit.init("galleryPage")

    const GalleryPage = () => import(/* webpackChunkName: "gallery-page-silo" */ 'containers/Pages/Gallery/Silo')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(GalleryPage)
    })
  }
}
