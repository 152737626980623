import Calendar from 'store/modules/Pages/GroupBooking/Calendar'
import PaxSelector from 'store/modules/eHorizon/SearchForm/PaxSelector'
import Vue from 'vue'
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { email, required, minLength, maxLength, not } from 'vuelidate/lib/validators'
import { alphaWithSpaces, regex, isPaxOlderThan, isDateAfter, isDateValidOrEmpty } from 'utils/validators'
import RewardRequestService from 'services/AirBaltic/Forms/RewardRequest'

const formErrors = {
  pinsData: {
    firstName: {
      alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
      required: 'reusable.firstName.validation.required'
    },
    lastName: {
      alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
      required: 'reusable.lastName.validation.required'
    },
    email: {
      required: 'reusable.email.validation.missing',
      email: 'reusable.email.validation.invalid'
    },
    pinsNumber: {
      required: 'generic.errors.fieldEmpty',
      minLength: 'pinsReward.pinsNumber.validation.invalid'
    }
  },
  flight: {
    departFlightNumber: {
      maxLength: 'reusable.flightNumber.validation.between',
      flightNumberInvalid: 'reusable.flightNumber.validation.invalid'
    },
    returnFlightNumber: {
      maxLength: 'reusable.flightNumber.validation.between',
      flightNumberInvalid: 'reusable.flightNumber.validation.invalid'
    }
  },
  paxList: {
    adults: {
      firstName: {
        alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
        required: 'reusable.firstName.validation.required'
      },
      lastName: {
        alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
        required: 'reusable.lastName.validation.required'
      }
    },
    children: {
      firstName: {
        alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
        required: 'reusable.firstName.validation.required'
      },
      lastName: {
        alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
        required: 'reusable.lastName.validation.required'
      },
      dateOfBirth: {
        maxAge: 'reusable.dateOfBirth.validation.child.maxAge',
        minAge: 'reusable.dateOfBirth.validation.child.minAge',
        invalidDate: 'reusable.dateOfBirth.validation.valid',
        required: 'reusable.dateOfBirth.validation.required'
      }
    },
    infants: {
      firstName: {
        alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
        required: 'reusable.firstName.validation.required'
      },
      lastName: {
        alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
        required: 'reusable.lastName.validation.required'
      },
      dateOfBirth: {
        maxAge: 'reusable.dateOfBirth.validation.infant.maxAge',
        dobInFuture: 'reusable.dateOfBirth.validation.after',
        invalidDate: 'reusable.dateOfBirth.validation.valid',
        required: 'reusable.dateOfBirth.validation.required'
      }
    }
  }
}

const defaultState = {
  pageData: {
    pageTitle: '',
    description: '',
    warning: '',
    pinsCardTitle: '',
    pinsCardDescription: '',
    flightDataTitle: '',
    flightDataDescription: '',
    flightDateTitle: '',
    klafDestinationsUrl: '',
    flightDateDescription: '',
    paxTitle: '',
    paxDescription: '',
    termsAndConditionLinkText: '',
    termsAndConditionTitle: '',
    termsAndConditionContent: '',
    showTCModal: false,
  },
  pinsData: {
    firstName: '',
    lastName: '',
    email: '',
    pinsNumber: '',
    backendError: false,
    isSetFromPins: false
  },
  flight: {
    tripType: 'oneway',
    travelClass: 'economy',
    departFlightNumber: '',
    returnFlightNumber: '',
    comment: ''
  },
  paxList: {
    adults: [
      {
        firstName: '',
        lastName: ''
      }
    ],
    children: [],
    infants: []
  },
  formErrors: formErrors
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    const validations = {
      state: {
        calendar: {},
        pinsData: {
          firstName: {
            alphaWithSpaces,
            required
          },
          lastName: {
            alphaWithSpaces,
            required
          },
          email: {
            required,
            email
          },
          pinsNumber: {
            required,
            minLength: minLength(10)
          }
        },
        flight: {
          departFlightNumber: {
            maxLength: maxLength(6),
            flightNumberInvalid: regex('flightNumber')
          },
          returnFlightNumber: {
            maxLength: maxLength(6),
            flightNumberInvalid: regex('flightNumber')
          }
        },
        paxList: {
          adults: {
            $each: {
              firstName: {
                alphaWithSpaces,
                required
              },
              lastName: {
                alphaWithSpaces,
                required
              }
            }
          },
          children: {
            $each: {
              firstName: {
                alphaWithSpaces,
                required
              },
              lastName: {
                alphaWithSpaces,
                required
              },
              dateOfBirth: {
                maxAge: isPaxOlderThan(
                  12,
                  'DD / MM / YYYY',
                  Calendar.state.returnDate
                    ? Calendar.state.returnDate
                    : Calendar.state.departureDate
                ),
                minAge: not(
                  isPaxOlderThan(
                    2,
                    'DD / MM / YYYY',
                    Calendar.state.returnDate
                      ? Calendar.state.returnDate
                      : Calendar.state.departureDate
                  )
                ),
                invalidDate: isDateValidOrEmpty('DD / MM / YYYY'),
                required
              }
            }
          },
          infants: {
            $each: {
              firstName: {
                alphaWithSpaces,
                required
              },
              lastName: {
                alphaWithSpaces,
                required
              },
              dateOfBirth: {
                maxAge: isPaxOlderThan(
                  2,
                  'DD / MM / YYYY',
                  Calendar.state.returnDate
                    ? Calendar.state.returnDate
                    : Calendar.state.departureDate
                ),
                dobInFuture: not(isDateAfter('DD / MM / YYYY')),
                invalidDate: isDateValidOrEmpty('DD / MM / YYYY'),
                required
              }
            }
          }
        }
      }
    }

    if (this.state.flight.tripType !== 'return') {
      delete validations.state.flight.returnFlightNumber
    }

    return validations
  }
})

const Reward = {
  modules: {
    calendar: Calendar,
    paxSelector: PaxSelector
  },
  state: defaultState,
  mutations: {
    setPageData(state, value) {
      state.pageData = value
    },
    setPinsFirstName(state, value) {
      state.pinsData.firstName = value
    },
    setPinsLastName(state, value) {
      state.pinsData.lastName = value
    },
    setPinsEmail(state, value) {
      state.pinsData.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setPinsNumber(state, value) {
      state.pinsData.pinsNumber = value
    },
    setIsSetFromPins(state, value) {
      state.pinsData.isSetFromPins = value
    },
    setTripType(state, value) {
      state.flight.tripType = value
    },
    setTravelClass(state, value) {
      state.flight.travelClass = value
    },
    setDepartFlightNumber(state, value) {
      state.flight.departFlightNumber = value
    },
    setReturnFlightNumber(state, value) {
      state.flight.returnFlightNumber = value
    },
    setComment(state, value) {
      state.flight.comment = value
    },
    addAdultPax(state) {
      state.paxList.adults.push({
        firstName: '',
        lastName: ''
      })
    },
    removeAdultPax(state) {
      state.paxList.adults.pop()
    },
    addChildPax(state) {
      state.paxList.children.push({
        firstName: '',
        lastName: '',
        dateOfBirth: ''
      })
    },
    removeChildPax(state) {
      state.paxList.children.pop()
    },
    addInfantPax(state) {
      state.paxList.infants.push({
        firstName: '',
        lastName: '',
        dateOfBirth: ''
      })
    },
    removeInfantPax(state) {
      state.paxList.infants.pop()
    },
    setPaxFirstName(state, value) {
      state.paxList[value.paxType][value.index].firstName = value.value
    },
    setPaxLastName(state, value) {
      state.paxList[value.paxType][value.index].lastName = value.value
    },
    setPaxDob(state, value) {
      state.paxList[value.paxType][value.index].dateOfBirth = value.value
    },
    setOrigDestData(state, value) {
      state.origDestData = value
    },
    setBackendPinsError(state, value) {
      state.pinsData.backendError = value
    },
    setDefaultForm(state) {
      state.pinsData = {
        firstName: '',
        lastName: '',
        email: '',
        pinsNumber: '',
        backendError: false,
        isSetFromPins: false
      }
      state.paxList = {
        adults: [
          {
            firstName: '',
            lastName: ''
          }
        ],
        children: [],
        infants: []
      }
      state.flight = {
        tripType: 'oneway',
        travelClass: 'economy',
        departFlightNumber: '',
        returnFlightNumber: '',
        comment: ''
      }
    }
  },

  actions: {
    touch() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('reward form')
      })
    },
    clearForm({ commit, dispatch }) {
      validator.$v.state.$reset()

      dispatch('eHorizonSearchForm/destin/reset', null, { root: true })
      dispatch('eHorizonSearchForm/origin/reset', null, { root: true })

      commit('setBackendPinsError', false)
      commit('setDefaultForm')
      commit('calendar/updateCalendarDepartureText', '')
      commit('calendar/updateCalendarReturnText', '')
    },
    validateOriginDestin({ dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        dispatch('eHorizonSearchForm/validateComponents', null, { root: true })

        if (rootState.eHorizonSearchForm.formValid) {
          return resolve()
        } else {
          return reject('validateOriginDestin')
        }
      })
    },
    validate({ state, dispatch, rootState }) {
      const rules = [
        dispatch('touch'),
        dispatch('validateOriginDestin'),
        dispatch('calendar/validateDeparturePromise')
      ]
      if (state.flight.tripType !== 'oneway') {
        rules.push(dispatch('calendar/touchReturnDate'))
        rules.push(dispatch('calendar/validateReturnPromise'))
      }

      return Promise.all(rules)
        .then(() => {
          const returnDate = state.calendar.returnDate
            ? moment(state.calendar.returnDate).format('YYYY-MM-DD')
            : ''

          let childrenState = []
          let infantState = []

          state.paxList.children.forEach((child) => {
            childrenState.push({
              firstName: child.firstName,
              lastName:child.lastName,
              dateOfBirth:moment(child.dateOfBirth, 'DD / MM / YYYY').format('YYYY-MM-DD')
            })
          })

          state.paxList.infants.forEach((infant) => {
            infantState.push({
              firstName: infant.firstName,
              lastName:infant.lastName,
              dateOfBirth:moment(infant.dateOfBirth, 'DD / MM / YYYY').format('YYYY-MM-DD')
            })
          })

          const postObj = {
            cardHolder: {
              cardNumber: state.pinsData.pinsNumber.toString(),
              language: Vue.i18n.locale().toUpperCase(),
              firstName: state.pinsData.firstName,
              lastName: state.pinsData.lastName,
              email: state.pinsData.email
            },
            flightType: state.flight.tripType === 'oneway' ? 'ONE_WAY' : 'ROUNDTRIP',
            travelClass: state.flight.travelClass.toUpperCase(),
            origin: rootState.eHorizonSearchForm.origin.value.code,
            destin: rootState.eHorizonSearchForm.destin.value.code,
            departureFlight: state.flight.departFlightNumber,
            returnFlight: state.flight.returnFlightNumber,
            departureDate: moment(state.calendar.departureDate).format('YYYY-MM-DD'),
            returnDate: returnDate,
            message: state.flight.comment,
            adults: state.paxList.adults,
            children: childrenState,
            infants: infantState
          }

          return new RewardRequestService()
            .submitForm(postObj)
            .then((response) => {
              return Promise.resolve(response)
            })
            .catch((error) => {
              if (error.fieldErrors) {
                dispatch('setFieldErrors', error.fieldErrors)
              }
              return Promise.reject(error)
            })
        })
        .catch((e) => {
          return Promise.reject(e)
        })
    },

    setFieldErrors({ commit }, errors) {
      if (!errors) {
        return
      }

      if (
        errors['cardHolder.cardNumber'] ||
        errors['cardHolder.firstName'] ||
        errors['cardHolder.lastName']
      ) {
        commit('setBackendPinsError', true)
      }
    }
  },

  getters: {
    $v() {
      return Object.assign({}, validator.$v.state)
    }
  },

  namespaced: true
}

export default Reward
