import Api from 'services/AirBaltic/Api'

class Charter {
  getAirportList () {
    return new Api().get('/charters/airports')
      .then((response) => Promise.resolve(response.data))
      .catch((e) => Promise.reject(e))
  }
}

export default Charter
