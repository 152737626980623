<template>
  <div
    class="wrap-container"
    @keyup.esc="closeBackdrop()"
  >
    <div class="container">
      <div
        v-if="showBackdrop"
        class="backdrop"
        @click="closeBackdrop()"
      />
      <div class="buttons">
        <button
          v-if="!isDesktop"
          class="button burger"
          :aria-label="$t('indexTabs.aria.menu')"
          @click="toggleMenu()"
        />
        <logo />
      </div>
      <div class="buttons">
        <template v-if="isDesktop">
          <div
            v-if="showSearch"
            class="search-container"
          >
            <input
              ref="searchInput"
              v-model="searchString"
              name="search"
              class="search-input"
              :placeholder="$t('searchPage.searchPlaceholder')"
              @keydown.enter="performSearch"
            />
          </div>
          <button
            class="button search"
            :class="{ active: showSearch }"
            :aria-label="$t('indexTabs.aria.search')"
            @click="toggleSearch()"
          />
        </template>
        <a
          v-if="accessibilityPageUrl"
          class="button accessibility"
          :href="accessibilityPageUrl"
        />
        <button
          class="button offers"
          :aria-label="$t('indexTabs.aria.offers')"
          @click="toggleOffers()"
        >
          <counter
            :count="notificationCount"
            :show-number="false"
            @created="offersVisible(notificationCount)"
          />
        </button>
        <button
          class="button lang"
          :aria-label="$t('indexTabs.aria.lang')"
          @click="toggleLangSwitcher()"
        />
        <template v-if="!isUserLogged">
          <button
            class="button login"
            :aria-label="$t('indexTabs.aria.login')"
            @click="toggleProfile()"
          >
            <span v-if="isDesktop">{{ $t('indexTabs.aria.login') }}</span>
          </button>
        </template>
        <user-greeting
          v-else
          :user-data="userData"
          @toggleProfile="toggleProfile"
        />

        <pre-header-flyout
          v-if="showFlyout"
          :class="getClass"
          @closeFlyout="closeFlyout"
        >
          <header-offers
            v-if="showOffers"
            :offers="offers"
            @updateCount="setNotificationCount"
          />

          <location-form v-if="showLangSwitcher" />

          <template v-if="!isLoginDisabled && showProfile">
            <login-form
              v-if="!isUserLogged"
              @close="closeFlyout"
            />

            <user-profile
              v-if="isUserLogged"
              :is-clean="userData.clean"
              class="manage-profile-white"
              @closeFlyout="closeFlyout"
              @logout="closeFlyout"
            />
          </template>
          <div v-else-if="isLoginDisabled && showProfile">
            <close-icon @click="closeFlyout" />
            <v-alert>
              {{ $t('header.loginForm.unavailableLogin') }}
            </v-alert>
          </div>
        </pre-header-flyout>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import querystring from 'querystring'
  import ScrollUtils from 'utils/scrollUtils'
  import Pimcore from 'services/AirBaltic/Pimcore'
  import Logo from 'components/Header/White/Logo'
  const Counter = () => import(/* webpackChunkName: "counter" */ 'components/Counter')
  const PreHeaderFlyout = () =>
    import(
      /* webpackChunkName: "pre-header-flyout" */ 'components/Header/White/PreHeader/PreHeaderFlyout'
    )
  const HeaderOffers = () =>
    import(/* webpackChunkName: "header-offers" */ 'components/HeaderOffers')

  const LocationForm = () =>
    import(/* webpackChunkName: "location-form" */ 'components/Header/White/LocationForm')

  const UserProfile = () => import('components/LoginForm/UserProfile')
  const UserGreeting = () => import('containers/Header/Items/UserGreeting')
  const LoginForm = () => import('components/LoginForm/White/LoginForm')
  const VAlert = () => import(/* webpackChunkName: "v-alert" */ 'components/VAlert')
  const CloseIcon = () =>
    import(/* webpackChunkName: "close-icon" */ 'components/CloseIcon')

  import * as Sentry from '@sentry/vue'

  import gtmOffers from 'services/AirBaltic/GTM/Offers'
  import gtmHeader from 'services/AirBaltic/GTM/HeaderMenu'
  import gtmProfile from 'services/AirBaltic/GTM/Profile'

  export default {
    components: {
      Logo,
      Counter,
      PreHeaderFlyout,
      HeaderOffers,
      LocationForm,
      UserProfile,
      UserGreeting,
      LoginForm,
      VAlert,
      CloseIcon
    },
    props: {
      searchLink: {
        type: Object,
        required: false,
        default: () => {}
      }
    },
    data() {
      return {
        searchString: '',
        showBackdrop: false,
        showSearch: false,
        showOffers: false,
        showLangSwitcher: false,
        showProfile: false
      }
    },
    computed: {
      ...mapGetters('device', ['isMobile', 'isDesktop']),
      ...mapState('header', ['offers', 'notificationCount']),
      ...mapState('main/user', ['userData', 'isUserLogged']),
      ...mapState('main', [
        'selectedLang',
        'createProfileLink',
        'accessibilityPageUrl',
        'isLoginDisabled'
      ]),
      showFlyout() {
        return this.showOffers || this.showLangSwitcher || this.showProfile
      },
      getClass() {
        let className = ''

        if (this.isUserLogged) {
          className = 'logged-in '
        }

        if (this.showOffers) {
          className += 'offers'
        }

        if (this.showLangSwitcher) {
          className += 'lang'
        }

        if (this.showProfile && this.isUserLogged) {
          className += 'userProfile'
        }

        if (this.showProfile && !this.isUserLogged) {
          className += 'login'
        }

        if (this.isLoginDisabled) {
          className += ' disabled'
        }

        return className
      }
    },
    watch: {
      isUserLogged() {
        this.closeBackdrop()
      }
    },
    created() {
      new Pimcore()
        .get(`/get-offers/${this.selectedLang}`)
        .then(({ data }) => {
          this.$store.commit('header/setOffers', data)
          this.setNotificationCount()
        })
        .catch((e) => Sentry.captureException(e))
    },
    methods: {
      offersVisible(count) {
        gtmOffers.offersVisible(count)
      },
      offersClicked() {
        gtmOffers.offersClicked(this.notificationCount)
      },
      performSearch() {
        const string = this.searchString.replace(/\s\s+/g, ' ').trim()
        const query = querystring.stringify({ search: string })

        location.href = `${this.searchLink?.href}#${query}`

        if (window.location.pathname === this.searchLink?.href) {
          location.reload()
        }
      },
      closeBackdrop() {
        this.showBackdrop = false
        this.showLangSwitcher = false
        this.showOffers = false
        this.showProfile = false
      },
      openBackdrop() {
        this.showBackdrop = true
      },
      toggleLangSwitcher() {
        this.openBackdrop()
        this.disableUserScroll()

        this.showLangSwitcher = !this.showLangSwitcher
      },
      toggleSearch() {
        this.showSearch = !this.showSearch

        if (this.showSearch) {
          this.$nextTick(() => {
            this.$refs.searchInput?.focus()
          })
        }
      },
      toggleProfile() {
        if (!this.isUserLogged) {
          let pageName = 'bt_pages'
          if (window.location.href.indexOf(createProfileLink) > -1) {
            pageName = 'create_profile_page'
          }
          gtmProfile.loginHeaderClickAction(pageName)
        }

        this.openBackdrop()
        this.disableUserScroll()

        this.showProfile = !this.showProfile
      },
      toggleOffers() {
        this.openBackdrop()
        this.disableUserScroll()

        this.showOffers = !this.showOffers

        this.offersClicked()
      },
      toggleMenu() {
        gtmHeader.headerCategoryClick('Burger icon', null, 'Burger icon')
        this.$emit('toggle-menu')
      },
      setNotificationCount() {
        this.$store.dispatch('header/setNotificationCount')
      },
      closeFlyout() {
        if (!this.isDesktop) {
          ScrollUtils.enableScroll()
        }
        this.closeBackdrop()
      },
      disableUserScroll() {
        if (this.isMobile) {
          ScrollUtils.disableScroll()
        }
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.64);
    z-index: 4;
  }

  .search-container {
    .search-input {
      background: $search-bg-gray;
      border-radius: 4px 0 0 4px;
      padding: 12px 0 12px 14px;
      font-size: rem(16, 16);
      line-height: 24px;
      width: 265px;
      height: 45px;

      @include input-placeholder {
        color: $gray-text-muted;
      }
    }
  }

  .wrap-container {
    box-shadow: inset 0 -1px 0 0 $gray-divider;

    @include bp-mf-tablet {
      padding: 0 8px;
    }

    @include bp-desktop-small {
      padding-right: 26px !important;
      padding-left: 26px !important;
    }

    @include bp-mf-desktop {
      padding: 0;
    }

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: $desktop-wide-width;

      @include bp-mf-desktop {
        margin: 0 auto;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }

  .button {
    display: flex;
    height: 56px;
    width: 42px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;

    @include outline-focus;

    @include bp-mf-tablet {
      width: 48px;
    }

    @include bp-mf-desktop {
      margin-right: 8px;
    }

    &:before {
      @include icon-font-thin();
      font-size: 24px;
      color: $blue-dark;
    }

    &.offers {
      position: relative;

      .counter {
        top: calc(50% - 14px);
        right: calc(50% - 14px);
      }

      &:before {
        content: $icon-header-notification;
      }
    }

    &.accessibility {
      text-decoration: none;

      &:before {
        @include filled-icon-font();
        content: $filled-icon-accessible;
      }
    }

    &.lang {
      &:before {
        content: $icon-header-globe;
      }

      @include bp-mf-desktop {
        margin-right: 10px;
      }
    }

    &.login {
      margin-right: 4px;

      &:before {
        content: $icon-header-profile;

        @include bp-mf-desktop {
          margin-right: 8px;
        }
      }

      @include bp-mf-desktop {
        padding: 8px 24px 8px 16px;
        background: $green-base;
        color: $blue-dark;
        border-radius: 20px;
        width: auto;
        height: 40px;
        margin-right: 0;
      }
    }

    &.search {
      &.active {
        background: $search-bg-gray;
        border-radius: 0 4px 4px 0;
        height: 45px;
      }

      &:before {
        content: $icon-header-search;
      }
    }

    &.burger {
      width: 56px;

      &:before {
        content: $icon-header-hamburger;
      }
    }
  }

  ::v-deep .pre-header-flyout {
    @include bp-mf-tablet {
      left: auto !important;
      right: 0 !important;
      top: 54px !important;
      overflow: initial !important;
    }

    &.offers {
      @include bp-mf-tablet {
        min-height: auto !important;

        &::before {
          right: 114px;
        }
      }

      @include bp-mf-desktop {
        &::before {
          right: calc(50% + 2px);
        }
      }

      &.logged-in {
        @include bp-mf-tablet {
          &::before {
            right: 130px;
          }
        }

        @include bp-mf-tablet {
          &::before {
            right: calc(50% + 26px);
          }
        }
      }
    }

    &.lang {
      @include bp-mf-tablet {
        &::before {
          right: 66px;
        }
      }

      @include bp-mf-desktop {
        &::before {
          right: calc(50% - 54px);
        }
      }

      &.logged-in {
        @include bp-mf-tablet {
          &::before {
            right: 82px;
          }
        }

        @include bp-mf-desktop {
          &::before {
            right: calc(50% - 30px);
          }
        }
      }
    }

    &.userProfile {
      @include bp-mf-tablet {
        z-index: 5 !important;
        left: auto !important;
        right: 0;

        &::before {
          right: 24px;
        }
      }
    }

    &.login {
      @include bp-mf-tablet {
        &::before {
          right: 18px;
        }
      }

      @include bp-mf-desktop {
        &::before {
          right: 42px;
        }
      }
    }
  }
</style>
