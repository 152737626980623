import AuthorizedApi from 'services/AirBaltic/AuthorizedApi'
import Pimcore from 'services/AirBaltic/Pimcore'
import conf from 'conf'
import has from 'lodash-es/has'
import CookieUtils from "utils/CookieUtils"
import * as Sentry from '@sentry/vue'

class Logout {
  doPimcoreLogout() {
    return new Pimcore().post(`/jwt-logout${conf.btUrlCors}`)
      .then(response => Promise.resolve(has(response, 'status')))
      .catch(error => Promise.reject(error))
  }

  doApiLogout() {
    const token = window.localStorage.getItem('refreshToken')
    return new AuthorizedApi().post(`/profile/logout`, {
      refreshToken: token
    })
      .then(response => {
        window.localStorage.removeItem('refreshToken')
        return Promise.resolve(response)
      })
      .catch(error => Promise.reject(error))
  }

  logoutUser(callback = null) {
    return Promise.all([
      this.doApiLogout(),
      this.doPimcoreLogout()
    ])
      .catch((e) => {
        Sentry.captureException(e)
      })
      .finally(() => {
        this.doLogoutDataLayerPush()

        if (callback !== null) {
          callback()
        }
      })
  }

  doLogoutDataLayerPush() {
    const method = CookieUtils.getCookie('loginMethod')
    if (method === 'email') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'logoutEvent',
        eventCategory: 'All pages',
        eventAction: 'Logout',
        eventLabel: 'Method: ' + method + '; Page: ' + window.location.href,
        nonInteraction: true,
        authMethod: method
      })
    }

    CookieUtils.setCookie('loginMethod', '', -1)
  }
}

export default Logout
