import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { required, minLength, sameAs, not, maxLength } from 'vuelidate/lib/validators'
import {
  containsSpecialChar,
  containsUpperChar,
  containsLowerChar,
  containsNumber,
  containsForbidden,
  containsSpaces
} from 'utils/validators'
import PaxLogin from 'services/AirBaltic/Pax/Login'

const validationErrors = {
  WRONG_OR_EXPIRED_PASSWORD_RESET_TOKEN: 'generic.errors.wrongOrExpiredPasswordToken',
  USED_PASSWORD_RESET_TOKEN: 'generic.errors.usedPasswordResetToken'
}

const defaultState = {
  pageTitle: '',
  sectionTitle: '',
  sectionText: '',
  loginUrl: '',
  password: {
    new: '',
    confirm: ''
  },
  token: '',
  errorExpiredFirst: '',
  errorExpiredSecond: '',
  resendLinkText: '',
}

const validator = new Vue({
  mixins: [
    validationMixin
  ],

  computed: {
    state () {
      return defaultState
    }
  },

  validations () {
    return {
      state: {
        password: {
          new: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(20),
            notSameAsEmail: not(sameAs('email')),
            containsSpecialChar,
            containsUpperChar,
            containsLowerChar,
            containsNumber,
            containsForbidden: not(containsForbidden),
            containsSpaces: not(containsSpaces)
          },
          confirm: {
            required,
            sameAsPassword: sameAs('new')
          }
        }
      }
    }
  }
})

const ResetPassword = {
  state: defaultState,

  getters: {
    $v () {
      return Object.assign({}, validator.$v.state)
    }
  },

  mutations: {
    setPageTitle (state, value) {
      state.pageTitle = value
    },

    setSectionTitle (state, value) {
      state.sectionTitle = value
    },

    setSectionText (state, value) {
      state.sectionText = value
    },

    setLoginUrl (state, value) {
      state.loginUrl = value
    },

    setToken (state, value) {
      state.token = value
    },

    setNewPassword (state, value) {
      state.password.new = value
    },

    setConfirmPassword (state, value) {
      state.password.confirm = value
    },

    setErrorExpiredFirst (state, value) {
      state.errorExpiredFirst = value
    },

    setErrorExpiredSecond (state, value) {
      state.errorExpiredSecond = value
    },

    setResendLinkText (state, value) {
      state.resendLinkText = value
    },

    setErrorInvalid (state, value) {
      state.errorInvalid = value
    }
  },

  actions: {
    setNewPassword ({ commit }, data) {
      validator.$v.state.password.new.$touch()
      commit('setNewPassword', data)
    },

    setConfirmPassword ({ commit }, data) {
      validator.$v.state.password.confirm.$touch()
      commit('setConfirmPassword', data)
    },

    validateForm () {
      return new Promise((resolve) => {
        validator.$v.state.password.new.$touch()
        validator.$v.state.password.confirm.$touch()

        if (!validator.$v.state.password.$invalid) {
          resolve()
        }
      })
    },

    submitForm ({ state, commit }) {
      const PaxLoginService = new PaxLogin()

      return new Promise((resolve, reject) => {
        commit('main/setShowSmallLoader', true, { root: true })

        PaxLoginService.resetPassword({
          token: state.token,
          password: state.password.new
        })
        .then(() => resolve())
        .catch((error) => {
          let message = ''

          if (error && error.response) {
            const code = error.response.data.code

            if (Object.prototype.hasOwnProperty.call(validationErrors, code)) {
              message = validationErrors[`${code}`]
            }
          }

          message = message ? Vue.i18n.translate(message) : ''

          reject(message)
        })
        .finally(() => {
          commit('main/setShowSmallLoader', false, { root: true })
        })
      })
    }
  },

  namespaced: true
}

export default ResetPassword
