<template>
  <div class="search-results-content main-content wide">
    <div class="search-field-block">
      <div class="wrap">
        <div class="search-field-wrap">
          <input
            ref="searchField"
            type="text"
            :placeholder="$t('searchPage.searchPlaceholder')"
            :value="searchString"
            @keyup.enter="doSearch"
          />
          <div class="btn btn-blue">
            <button @click="doSearch">
              {{ $t('searchPage.startSearch') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="search-results-block">
      <div class="wrap">
        <div class="search-results-wrap">
          <search-results
            :search-string="searchString"
            :search-results="searchResults"
            :current-page="currentPage"
            :total-result-count="totalResultCount"
            :total-pages="totalPages"
            :loader-is-shown="showSmallLoader"
          />
        </div>
      </div>
    </div>
    <small-loader />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import SearchResults from 'components/Search/Results'
  import SmallLoader from 'components/SmallLoader'
  import querystring from 'querystring'

  export default {
    components: {
      SearchResults,
      SmallLoader
    },
    computed: {
      ...mapState('main', {
        showSmallLoader: (state) => state.showSmallLoader
      }),
      ...mapState('search', [
        'searchString',
        'searchResults',
        'currentPage',
        'totalPages',
        'totalResultCount'
      ])
    },
    mounted() {
      let urlParams = new URLSearchParams(window.location.search)

      if (urlParams.has('search')) {
        this.$store.dispatch('search/setSearchString', urlParams.get('search'))
      }
    },
    methods: {
      doSearch() {
        const string = this.$refs.searchField.value.replace(/\s\s+/g, ' ').trim()
        const query = querystring.stringify({ search: string })

        if (query !== 'search=') {
          this.$store.dispatch('search/setSearchString', string)
        }
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';

  .search-results-content {
    padding: 0 0 52px;
    min-height: 497px;
  }

  .search-field-block {
    padding: 44px 0;
    background-color: $gray-light-background;

    @include bp-tablet-only {
      padding: 42px 0;
    }

    @include bp-mobile {
      padding: 24px 0;
    }
  }

  .search-field-wrap,
  .search-results-wrap {
    margin: 0 auto;
    max-width: 740px;

    @include bp-tablet-only {
      padding: 0 26px;
    }

    @include bp-tablet {
      max-width: 100%;
    }

    @include bp-mobile {
      padding: 0 23px;
    }
  }

  .search-field-wrap {
    position: relative;
    padding-right: 140px;

    @include bp-tablet-only {
      padding-right: 169px;
    }

    @include bp-mobile {
      padding-right: 103px;
    }

    input {
      display: inline-block;
      vertical-align: top;
      padding: 0 18px;
      width: 100%;
      height: 46px;
      border: 1px solid $grey-border;

      @include bp-mobile {
        height: 42px;
      }
    }

    .btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 120px;
      min-width: auto;
      height: 46px;

      @include bp-tablet-only {
        right: 26px;
        width: 127px;
      }

      @include bp-mobile {
        right: 23px;
        width: 72px;
        height: 42px;
        font-size: em(12);
      }

      button {
        line-height: 44px;

        @include bp-mobile {
          line-height: 40px;
        }
      }
    }
  }

  #search-results-container {
    .search-suggestion {
      display: block;
      width: 100%;
    }
  }
</style>
