import { alphaWithSpaces, betweenLength, isTrue } from 'utils/validators';
import { required } from 'vuelidate/lib/validators';
var Passenger = /** @class */ (function () {
    function Passenger(passenger) {
        this.extras = false;
        this.firstName = '';
        this.lastName = '';
        this.checkedBaggage = false;
        this.heavyBaggage = false;
        this.protectedBaggage = false;
        this.textBetweenLength = {
            max: 50,
            min: 1,
        };
        this.translationVariables = {
            firstName: {
                textBetween: this.textBetweenLength,
            },
            lastName: {
                textBetween: this.textBetweenLength,
            },
        };
        this.errorMsg = {
            checkedBaggage: {
                isTrue: 'payments.sections.passengersDetails.checkedBaggage.validation.oneOrMore',
            },
            extras: {
                isTrue: 'payments.sections.passengersDetails.extras.validation.oneOrMore',
            },
            firstName: {
                alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
                required: 'reusable.firstName.validation.required',
                textBetween: 'reusable.firstName.validation.between',
            },
            heavyBaggage: {
                isTrue: 'payments.sections.passengersDetails.heavyCabinBaggage.validation.oneOrMore',
            },
            lastName: {
                alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
                required: 'reusable.lastName.validation.required',
                textBetween: 'reusable.lastName.validation.between',
            },
            title: {
                required: 'reusable.paxTitle.validation.required',
            },
        };
        this.validation = {
            passenger: {
                checkedBaggage: {
                    isTrue: isTrue,
                },
                extras: {
                    isTrue: isTrue,
                },
                firstName: {
                    alphaWithSpaces: alphaWithSpaces,
                    required: required,
                    textBetween: betweenLength(this.textBetweenLength.min, this.textBetweenLength.max),
                },
                heavyBaggage: {
                    isTrue: isTrue,
                },
                lastName: {
                    alphaWithSpaces: alphaWithSpaces,
                    required: required,
                    textBetween: betweenLength(this.textBetweenLength.min, this.textBetweenLength.max),
                },
                title: {
                    required: required,
                },
            },
        };
        this._title = '';
        if (typeof passenger !== 'undefined') {
            this.parseName(passenger);
        }
    }
    Object.defineProperty(Passenger.prototype, "title", {
        get: function () {
            return this._title;
        },
        set: function (value) {
            var allowedTitles = ['MR', 'MS'];
            if (allowedTitles.includes(value.toUpperCase())) {
                this._title = value.charAt(0).toUpperCase() + value.slice(1).toLocaleLowerCase();
            }
        },
        enumerable: false,
        configurable: true
    });
    Passenger.prototype.parseName = function (data) {
        var name = data.givenName;
        var surname = data.surname;
        var arr = {
            name: name,
            surname: surname,
            title: '',
        };
        var spaceCount = (name.trim().match(/ /g) || []).length;
        if (spaceCount >= 1) {
            var lastSpaceLocation = name.lastIndexOf(' ');
            var title = name.slice((lastSpaceLocation + 1), name.length);
            var givenName = name.slice(0, lastSpaceLocation);
            arr.title = title;
            arr.name = givenName;
        }
        this.title = arr.title;
        this.firstName = arr.name;
        this.lastName = arr.surname;
    };
    return Passenger;
}());
export default Passenger;
