import { email, numeric, required } from 'vuelidate/lib/validators';
import { alphaNumWithSpaces, alphaWithSpaces, isTrue, regex } from 'utils/validators';
import { maxLength, minLength } from 'vuelidate/lib/validators';
var Step4 = /** @class */ (function () {
    function Step4() {
        this.contactType = 'individual';
        this.handling = false;
        this.id = '';
        this.agencyName = '';
        this.country = '';
        this.title = 'Mr';
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.intCode = '';
        this.phoneNumber = '';
        this.groupName = '';
        this.groupType = 'standard';
        this.language = '';
        this.groupCharLimit = 100;
        this.terms = false;
        this.errorMsg = {
            country: {
                required: 'reusable.country.validation.required',
            },
            email: {
                email: 'reusable.email.validation.invalid',
                required: 'reusable.email.validation.missing',
            },
            firstName: {
                alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
                required: 'reusable.firstName.validation.required',
            },
            groupName: {
                allowedCharacters: 'groupBooking.step4.groupName.validation.allowedCharacters',
                maxLength: 'groupBooking.step4.groupName.validation.maxLength',
            },
            agencyName: {
                alphaNumberSpaceDash: 'groupBooking.step4.agencyName.alphaNumberSpaceDash',
                required: 'groupBooking.step4.agencyName.required',
            },
            id: {
                alphaNumWithSpaces: 'groupBooking.step4.id.alphaNumWithSpaces',
            },
            intCode: {
                required: 'groupBooking.step4.intCode.required',
            },
            language: {
                required: 'reusable.contactLang.validation.required',
            },
            lastName: {
                alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
                required: 'reusable.lastName.validation.required',
            },
            phoneNumber: {
                maxLength: 'reusable.phone.validation.maxLength',
                minLength: 'reusable.phone.validation.minLength',
                numeric: 'reusable.phone.validation.notNumeric',
                required: 'reusable.phone.validation.invalid',
            },
            terms: {
                isTrue: 'groupBooking.step4.terms.isTrue',
                required: 'groupBooking.step4.terms.required',
            },
            title: {
                required: 'reusable.paxTitle.validation.required',
            },
        };
        this.validations = {
            country: {
                required: required,
            },
            email: {
                email: email,
                required: required,
            },
            firstName: {
                alphaWithSpaces: alphaWithSpaces,
                required: required,
            },
            groupName: {
                allowedCharacters: regex('alphaNumberSpaceDash'),
                maxLength: maxLength(this.groupCharLimit),
            },
            agencyName: {
                alphaNumberSpaceDash: regex('alphaNumberSpaceDash'),
                required: required,
            },
            id: {
                alphaNumWithSpaces: alphaNumWithSpaces,
            },
            intCode: {
                required: required,
            },
            language: {
                required: required
            },
            lastName: {
                alphaWithSpaces: alphaWithSpaces,
                required: required,
            },
            phoneNumber: {
                maxLength: maxLength(18),
                minLength: minLength(5),
                numeric: numeric,
                required: required,
            },
            terms: {
                isTrue: isTrue,
                required: required,
            },
            title: {
                required: required,
            },
        };
    }
    return Step4;
}());
export default Step4;
