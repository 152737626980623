import EditProfileStore from 'store/modules/Pages/ProfilePages/EditProfilePage'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, EditProfileStore)
      Init.initPageData(name)
      Init.initPaxDetails(name)
      Init.initSidebarData()
    }
  },

  initPageData(name) {
    if (typeof pageTitle !== 'undefined') {
      store.commit(`${name}/setPageTitle`, pageTitle)
    }
    store.commit('main/setPageType', 'profile')
  },

  initSidebarData() {
    if (typeof pressContacts !== 'undefined') {
      store.commit('sidebar/setPressContacts', pressContacts)
    }
  },

  initPaxDetails(name) {
    if (typeof userData !== 'undefined') {
      store.commit(`${name}/setFirstName`, userData.firstName)
      store.commit(`${name}/setFamilyname`, userData.familyName)
      store.commit(`${name}/setPaxTitle`, userData.title)
      store.dispatch(`${name}/setBirthDay`, userData.dateOfBirth.day)
      store.dispatch(`${name}/setBirthMonth`, userData.dateOfBirth.month)
      store.dispatch(`${name}/setBirthYear`, userData.dateOfBirth.year)
    }
  }
}

export default Init
