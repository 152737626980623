import PressReleasesStore from 'store/modules/Pages/PressReleases/PressReleases'
import PressReleasesService from 'services/AirBaltic/PressReleases'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, PressReleasesStore)
      Init.initDefaultValues(name)
    }
  },
  initDefaultValues(name) {
    if (typeof pressReleasesInit !== "undefined") {
      let currentYear = '2018'
      if (pressReleasesInit) {

        if (pressReleasesInit.year != currentYear) {
          currentYear = pressReleasesInit.year;
        }
        const perPage = 5
        store.dispatch(`${name}/setSelectedYear`, currentYear)
        store.commit(`${name}/setPerPage`, perPage)

        const service = new PressReleasesService()

        service.getReleaseYears(store.state.main.selectedLang)
          .then((data) => store.commit(`${name}/setYearsValues`, data.years))
          .catch(() => { })

        service.getReleases(currentYear, store.state.main.selectedLang)
          .then((data) => store.commit(`${name}/setItems`, data.releases))
          .catch(() => { })

        store.commit(`${name}/setShowYearSelect`, pressReleasesInit.showYearSelect)
      }
    }

    if (typeof pressContacts !== 'undefined') {
      store.commit('sidebar/setPressContacts', pressContacts)
    }

    if (typeof noteSnippet !== 'undefined') {
      store.commit(`sidebar/setPleaseNoteTitle`, noteSnippet.title)
      store.commit(`sidebar/setPleaseNoteText`, noteSnippet.text)
    }
  }
}

export default Init
