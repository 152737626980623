import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    const Modal = () => import(/* webpackChunkName: "modal" */ 'templates/Modal')
    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(Modal)
    })
  }
}
