import GuidanceRequest from 'models/Forms/Assistance/Sections/GuidanceRequest'
import FormValidationUtils from 'utils/FormValidation'

const requestGuidance = new GuidanceRequest()
const formData = {
  formErrors: requestGuidance.errorMsg,
  form: requestGuidance.form,
}
const defaultState = Object.assign({}, formData)
const validator = FormValidationUtils.initValidation(defaultState, requestGuidance.validation)

const module = {
  namespaced: true,
  state: defaultState,
  actions: {
    validateRequest () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('validate guidance request')
      })
    },
    clearRequest ({ commit }) {
      commit('resetForm')
      validator.$v.state.form.$reset()
    }
  },
  mutations: {
    setImpairmentType (state, value) {
      state.form.impairmentType = value
    },
    setAccompaniedByGuideDog (state, value) {
      state.form.accompaniedByGuideDog = value
    },
    setGuidanceRequest (state, value) {
      state.form.requestMessage = value
    },
    resetForm (state) {
      state.form = new GuidanceRequest().form
    }
  },
  getters: {
    $request () {
      return Object.assign({}, validator.$v.state.form)
    },
    requestValidationFormErrors (state) {
      return state.formErrors
    }
  }
}

export default module
