var Payment = /** @class */ (function () {
    function Payment() {
        this.formName = '';
        this.bookingRef = '';
        this.language = '';
        this.requestCharsTotal = 4500;
        this.showCharLimit = 500;
        this.pnrData = {};
        this.isCharter = false;
    }
    return Payment;
}());
export default Payment;
