import { render, staticRenderFns } from "./BestBuyBannerItem.vue?vue&type=template&id=4c45d698&scoped=true&"
import script from "./BestBuyBannerItem.vue?vue&type=script&lang=js&"
export * from "./BestBuyBannerItem.vue?vue&type=script&lang=js&"
import style0 from "./BestBuyBannerItem.vue?vue&type=style&index=0&id=4c45d698&lang=scss&scoped=true&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c45d698",
  null
  
)

export default component.exports