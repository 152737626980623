var Gvo = /** @class */ (function () {
    function Gvo(obj) {
        if (obj === void 0) { obj = {}; }
        this.gvoTitle = '';
        this.gvoDescription = '';
        Object.assign(this, obj);
    }
    return Gvo;
}());
export default Gvo;
