import moment from 'moment'
import Vue from 'vue'
import OrigDestUtils from 'store/utils/eHorizonOrigDestUtils'
import Origin from 'store/modules/eHorizon/SearchForm/Origin'
import isEmpty from 'lodash-es/isEmpty'

const defaultState = {
  pageData: {
    desktopImg: '',
    tabletImg: '',
    mobileImg: '',
    title: '',
    introText: '',
    subTitle: '',
    flightDetails: {
      book: {
        title: '',
        from: '',
        to: ''
      },
      travel: {
        title: '',
        from: '',
        to: ''
      }
    }
  },
  selectedOrigin: '',
  selectedDestin: '',
  originList: [],
  destinList: [],
  selectedSorter: '',
  filter: '',
  deadline: '',
  timerTitle: '',
  timerEnabled: false,
  clpOrigDestData: [],
  flightType: '',
  selectedSorting: '',
  defaultFlightType: '',
  termsAndConditions: {},
  iconType: '',
  hideImages: false,
  isOndCampaign: false,
  openAllHistograms: false
}

const cityCompare = (a, b) => a.city.localeCompare(b.city)
const priceCompare = (a, b) => {
  const aPrice = a.priceOW ? parseInt(a.priceOW) : parseInt(a.priceRT)
  const bPrice = b.priceOW ? parseInt(b.priceOW) : parseInt(b.priceRT)
  return aPrice - bPrice
}

const CampaignLandingPage = {
  modules: {
    origin: Origin
  },
  state: defaultState,
  mutations: {
    setPageData(state, value) {
      state.pageData = value
    },

    setSelectedDestin(state, value) {
      state.selectedDestin = value
    },

    setOriginList(state, value) {
      const firstAptList = ['RIX', 'VNO', 'TLL', 'TMP', 'OSL', 'TRF', 'BLL', 'CPH']
      const btOrigins = value.filter(
        (origin) => firstAptList.indexOf(origin.value) !== -1
      )
      const nonBtOrigins = value.filter(
        (origin) => firstAptList.indexOf(origin.value) === -1
      )
      btOrigins.sort((a, b) => {
        let indexA = firstAptList.indexOf(a.value)
        let indexB = firstAptList.indexOf(b.value)
        if (indexA < indexB) {
          return -1
        }
        if (indexB < indexA) {
          return 1
        }
        return 0
      })

      nonBtOrigins.sort((a, b) => {
        if (a.title < b.title) {
          return -1
        }
        if (a.title > b.title) {
          return 1
        }
        return 0
      })
      value = btOrigins.concat(nonBtOrigins)
      state.originList = value
    },

    setDestinList(state, value) {
      value.unshift({
        title: Vue.i18n.translate('reusable.showAll'),
        value: ''
      })
      state.destinList = value
    },

    setSelectedSorter(state, value) {
      state.selectedSorter = value
    },

    setDefaultFlightType(state, value) {
      state.defaultFlightType = value
    },

    setClpOrigDestData(state, value) {
      state.clpOrigDestData = value
    },

    setFlightDetails(state, value) {
      state.flightDetails = value
    },

    setSelectedFilter(state, value) {
      state.filter = value
    },

    setDeadline(state, value) {
      state.deadline = value
    },

    setTimerTitle(state, value) {
      state.timerTitle = value
    },

    setTimerEnabled(state, value) {
      state.timerEnabled = value
    },

    setIntroText(state, value) {
      state.introText = value
    },

    setTitle(state, value) {
      state.title = value
    },

    setTermsAndConditions(state, value) {
      state.termsAndConditions = value
    },

    setIconType(state, value) {
      state.iconType = value
    },

    hideFilterBlock(state, value) {
      state.hideFilterBlock = value
    },

    updateSelectedTripType(state, value) {
      const flight = state.clpOrigDestData.find(
        (item) => item.originCode === value.origin && item.code === value.destin
      )
      const index = state.clpOrigDestData.indexOf(flight)
      flight.tripType = value.tripType
      Vue.set(state.clpOrigDestData, index, flight)
    },

    setHideImages(state, value) {
      state.hideImages = value
    },

    setIsOndCampaign(state, value) {
      state.isOndCampaign = value
    },

    setOpenAllHistograms(state, value) {
      state.openAllHistograms = value
    }
  },

  actions: {
    setDeadline({ commit }, data) {
      const date = moment(data.date)
      if (data.time) {
        date.set({
          hours: data.time.split(':')[0],
          minutes: data.time.split(':')[1]
        })
      }

      commit('setDeadline', date)
    },

    setSelectedSorter({ commit }, data) {
      commit('setSelectedSorter', data)
    },

    updateSelectedTripType({ commit }, value) {
      commit('updateSelectedTripType', value)
    }
  },

  getters: {
    airports(state) {
      const filterValue = state.origin?.typeahead?.filter

      if (!isEmpty(filterValue)) {
        const origins = state.originList
        const typeaheadIgnoreFilter = filterValue.length <= 0
        const matchedTranslation = Vue.i18n.translate(
          'searchForm.originDestin.origin.matchedOrigins'
        )

        return OrigDestUtils.addHeaderToAirportList(
          matchedTranslation,
          OrigDestUtils.filterData(filterValue, origins, typeaheadIgnoreFilter)
        )
      }

      return state.originList
    },
    destinations(state) {
      let origData = Object.assign([], state.clpOrigDestData)
      const selectedOrigin = state.origin?.value?.code
      if (selectedOrigin) {
        origData = origData.filter((item) => item.originCode === selectedOrigin)
      }

      return origData
    },
    pricesDestinations(state) {
      let origData = Object.assign([], state.clpOrigDestData)
      let priceData = []
      const selectedOrigin = state.origin?.value?.code
      if (selectedOrigin) {
        origData = origData.filter((item) => item.originCode === selectedOrigin)
      }
      origData.forEach((orig) => {
        let price = orig.priceOW ? orig.priceOW : orig.priceRT
        let foundInArray = priceData.find((item) => item.price === price)
        if (!foundInArray) {
          priceData.push({
            price: price,
            flights: [orig]
          })
        } else if (foundInArray) {
          foundInArray.flights.push(orig)
        }
      })

      priceData.sort((a, b) => {
        if (a.price > b.price) {
          return 1
        }
        if (b.price > a.price) {
          return -1
        }
        return 0
      })

      return priceData
    },
    filteredFlights(state, getters) {
      let destinations = Object.assign([], getters.pricesDestinations)

      if (state.filter) {
        let filtered = []
        destinations.map((category) => {
          let flights = category.flights.filter((item) => {
            const filter = state.filter.toLowerCase()
            return item.code.toLowerCase().indexOf(filter) !== -1
          })

          filtered.push({
            price: category.price,
            flights: flights
          })
        })
        destinations = filtered
      }

      switch (state.selectedSorter) {
        case 'cityAsc':
          destinations.map((category) => {
            category.flights.sort(cityCompare)
          })
          break
        case 'cityDesc':
          destinations.map((category) => {
            category.flights.sort(cityCompare).reverse()
          })
          break
        case 'priceAsc':
        case 'default':
          destinations.map((category) => {
            category.flights.sort(priceCompare)
          })
          break
      }

      return destinations
    }
  },
  namespaced: true
}

export default CampaignLandingPage
