const DeviceMixin = {
  data () {
    return {
      lastDeviceType: undefined
    }
  },
  created () {
    this.handleResize()
    this.$store.commit('device/setIsRetina', this.isRetina())
    this.$store.commit('device/setIsIE', this.isIE())
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    isRetina () {
      const mediaQuery = "(-webkit-min-device-pixel-ratio: 1.5),\
          (min--moz-device-pixel-ratio: 1.5),\
          (-o-min-device-pixel-ratio: 3/2),\
          (min-resolution: 1.5dppx)";
      if (window.devicePixelRatio > 1)
        return true
      if (window.matchMedia && window.matchMedia(mediaQuery).matches)
        return true
      return false
    },

    isIE () {
      const ua = window.navigator.userAgent;
      return (ua.indexOf("MSIE ") > -1 || (ua.indexOf("Trident/") > -1))
    },

    handleResize () {
      const fullWidth = Math.max(window.innerWidth, document.documentElement.clientWidth)
      const fullHeight = document.documentElement.clientHeight

      const desktopWidth = 991
      const tabletWidth = 767

      let deviceType = 'mobile'
      if (fullWidth > desktopWidth) {
        deviceType = 'desktop'
      } else if (fullWidth > tabletWidth) {
        deviceType = 'tablet'
      }

      if (deviceType !== this.lastDeviceType) {
        this.$store.commit('device/setDeviceType', deviceType)
        this.lastDeviceType = deviceType
      }

      this.$store.commit('device/setClientWidth', fullWidth)
      this.$store.commit('device/setClientHeight', fullHeight)
    }
  }
}

export default DeviceMixin
