<template>
  <a
    :href="clickable ? '/' : null"
    title="airBaltic homepage"
    tabindex="0"
    class="logo-wrapper"
    @click="resetTab"
  >
    <img
      src="~assets/images/airbaltic.svg"
      alt="airBaltic logo"
      :width="getWidth"
      :height="getHeight"
    />
  </a>
</template>

<script>
  import CookieUtils from 'utils/CookieUtils'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      clickable: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters('device', ['isDesktop']),
      getWidth() {
        if (this.isDesktop) {
          return 112
        }
        return 70
      },
      getHeight() {
        if (this.isDesktop) {
          return 50
        }
        return 30
      }
    },
    methods: {
      resetTab() {
        CookieUtils.setCookie('activeTab', 'FLT', 0)
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';

  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 92px;
    height: 56px;
    background: $green-base;

    @include outline-focus;

    @include bp-mf-desktop {
      width: 160px;
      height: 80px;
    }

    img {
      top: 0;
      position: relative;
      left: 0;
    }
  }
</style>
