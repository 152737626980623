import ABClub from './ABClub';
var Guardian = /** @class */ (function () {
    function Guardian() {
        this.email = '';
        this.card = new ABClub().card;
        this.consent = false;
    }
    return Guardian;
}());
export default Guardian;
