import some from 'lodash-es/some';
import { capitalize } from 'utils/TextTransformUtils';
var Passenger = /** @class */ (function () {
    function Passenger(passenger, dirId) {
        this.paxId = 0;
        this.directionId = 0;
        this.name = '';
        this.initials = '';
        this.hasToUploadDocuments = false;
        this.isChecked = false;
        this.documents = {
            eu: {
                status: '',
                required: false
            },
            lv: {
                status: '',
                required: false
            },
            de: {
                status: '',
                required: false
            },
            lt: {
                status: '',
                required: false
            }
        };
        if (typeof passenger !== 'undefined') {
            var parsedName = capitalize(passenger.name);
            var firstName = passenger.firstName;
            var lastName = passenger.surname;
            this.paxId = passenger.id;
            this.isChecked = !!passenger.isChecked;
            this.directionId = dirId || 0;
            this.name = parsedName;
            this.initials = this.getInitials(firstName, lastName);
        }
    }
    Passenger.prototype.getInitials = function (firstName, lastName) {
        return firstName.charAt(0) + lastName.charAt(0);
    };
    Passenger.prototype.setDocument = function (document) {
        var doc = {
            isCovidTestDoc: document.isCovidTestDoc,
            status: document.validationStatus,
            type: document.specification.documentType,
            required: document.validationStatus !== 'DOCUMENT_NOT_NEEDED',
            hideQRscan: document.hideQRscan
        };
        if (document.specification.documentType === 'EU_DCC' && document.isCovidTestDoc) {
            this.documents.eu = doc;
        }
        switch (document.specification.documentType) {
            case 'EU_DCC':
                this.documents.eu = doc;
                break;
            case 'LV_COVIDPASS':
                this.documents.lv = doc;
                break;
            case 'DE_EINREISEANMELDUNG':
                this.documents.de = doc;
                break;
            case 'LT_EPLF':
                this.documents.de = doc;
                break;
        }
        this.hasToUploadDocuments = some(this.documents, { required: true });
    };
    return Passenger;
}());
export default Passenger;
