import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import cloneDeep from 'lodash-es/cloneDeep'

class FormValidation {
  static initValidation(defaultState, validation, excluded = []) {
    return new Vue({
      mixins: [validationMixin],
      computed: {
        state() {
          return defaultState
        }
      },
      validations() {
        const validationRules = cloneDeep(validation)
        const excludedFields = Object.values(excluded)

        if (Object.values(validationRules).length > 0) {
          excludedFields.forEach((exclude) => {
            delete validationRules.form[`${exclude}`]
          })
        }

        return { state: validationRules }
      }
    })
  }
}

export default FormValidation
