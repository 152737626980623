import get from 'lodash-es/get'

const SearchTable = {
  state: {
    pageData: {
      pageTitle: '',
      description: '',
      placeholderDesktop: '',
      placeholderMobile: '',
      visibleItems: 5,
    },
    tableData: {},
    initTableData: {},
    termsAndConditions: {},
    data: []
  },
  mutations: {
    setPageData (state, value) {
      state.pageData = value
    },

    setTableData (state, value) {
      state.tableData = value
    },

    setInitTableData (state, value) {
      state.initTableData = value
    },

    setData (state, value) {
      state.data = value
    },

    setTableRows (state, value) {
      state.tableRows = value
    },

    setTableHeader (state, value) {
      state.tableHeader = value
    }
  },

  actions: {
    setInitTableData ({ commit }, data) {
      let parsed = {}
      try {
        parsed = JSON.parse(data)
      } catch (e) {
        // Do nothing
      }

      commit('setInitTableData', parsed)
      commit('setTableData', parsed)
    },

    filterData ({ commit, state }, word) {
      const header  = get(state, 'initTableData.header', '')
      if (word === '') {
        commit('setTableData', state.initTableData)
      } else {
        word = word.toLowerCase()
        const array = get(state, 'initTableData.rows', [])
          .filter((row) => {
            let matches = false
            row.forEach((cell) => {
              if (cell.toString().toLowerCase().includes(word)) {
                matches = true
              }
            })
            return matches
        })

        commit('setTableData', {
          header: header,
          rows: array
        })
      }
    }
  },
  namespaced: true
}

export default SearchTable
