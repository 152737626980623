import Vue from 'vue'
import store from 'definitions/store'
import loginFormInit from 'initializations/LoginForm'
import AbstractView from 'abstract'
import mainInit from 'initializations/Main'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    mainInit.init("main")
    loginFormInit.init("loginForm")

    new Vue({
      mixins: [],
      store
    }).$mount('#header')
  }
}