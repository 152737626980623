<template>
  <div
    v-show="showSmallLoader"
    class="loader-wrap"
  >
    <div class="loader-img" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState('main', [
        'showSmallLoader'
      ])
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";
  @import '~assets/styles/components/loader.scss';

  .loader-wrap {
    z-index: 101;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background: hexToRGBA($grey-black-border, 0.4);
    text-align: center;

    .loader-img {
      @include loader();
    }
  }
</style>
