import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import CompanyModal from 'models/Pax/CharterForm/Company'

const companyModal = new CompanyModal()
const formState = Object.assign({}, { formErrors: companyModal.errorMsg }, companyModal)

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return formState
    }
  },
  validations () {
    return {
      state: companyModal.validations
    }
  }
})

const Company = {
  namespaced: true,
  state: formState,
  mutations: {
    toggleBillingDetails (state, value) {
      state.showBillingDetails = value
    },
    setCompanyName (state, value) {
      state.form.name = value
    },
    setRegNo (state, value) {
      state.form.regNo = value
    },
    setVatNo (state, value) {
      state.form.vatNo = value
    },
    setAddress (state, value) {
      state.form.address = value
    },
    setCity (state, value) {
      state.form.city = value
    },
    setPostalCode (state, value) {
      state.form.postalCode = value
    },
    setCountry (state, value) {
      state.form.country = value
    }
  },
  actions: {
    clearStep ({ commit }) {
      validator.$v.state.form.$reset()

      commit('setCompanyName', '')
      commit('setRegNo', '')
      commit('setVatNo', '')
      commit('setAddress', '')
      commit('setCity', '')
      commit('setPostalCode', '')
      commit('setCountry', '')
    },
    validateCompany ({ state, dispatch }) {
      if (!state.showBillingDetails) {
        return Promise.resolve(true)
      }

      return dispatch('touchCompany')
    },
    touchCompany () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('touchCompany')
      })
    },
    getData ({ state }) {
      const data = {
        address: state.form.address,
        city: state.form.city,
        country: state.form.country,
        name: state.form.name,
        postalCode: state.form.postalCode,
        regNo: state.form.regNo,
        vatNo: state.form.vatNo
      }

      return Promise.resolve(data)
    }
  },
  getters: {
    $v () {
      return Object.assign({}, validator.$v.state.form)
    },
    companyFormErrors (state) {
      return state.formErrors
    }
  }
}

export default Company
