import isObject from 'lodash-es/isObject'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'
import ScrollUtils from 'utils/scrollUtils'
import DefaultFormNotification from 'components/Notifications/DefaultFormNotification'

const Form = {
  data() {
    return {
      responseStatus: '',
      filledForm: ''
    }
  },
  methods: {
    $_formMixin_getHasError(field) {
      return this.$_formMixin_hasError(this.validation, field)
    },

    $_formMixin_getFormErrorMsg(name, formErrors, form) {
      if (!this.$v || !formErrors[`${form}`]) return ''

      const params = Object.keys(this.$v[`${form}`][`${name}`].$params)
      let error = ''
      params.forEach((param) => {
        if (this.$v[`${form}`][`${name}`][`${param}`] === false) {
          error = this.$t(formErrors[`${form}`][`${name}`][`${param}`])
        }
      })

      return error
    },

    $_formMixin_getErrorMsg(field, variables = {}) {
      if (!this.validation) {
        return
      }
      return this.$_formMixin_errorMsg(this.validation, this.formErrors, field, variables)
    },

    $_formMixin_hasError(store, field, form) {
      let validation = null

      if (!form) {
        validation = get(store, field, null)
      } else {
        const formStore = get(store, form, null)

        if (formStore !== null) {
          validation = get(formStore, field, null)
        }
      }

      if (typeof validation !== 'undefined' && !isEmpty(validation)) {
        return validation.$error
      }
      return false
    },

    $_formMixin_errorMsg(store, validator, name, variables = {}) {
      const deepStore = get(store, name, {})
      let error = ''
      if (isEmpty(deepStore)) {
        return error
      }

      const params = Object.keys(deepStore.$params)

      params.forEach((p) => {
        if (deepStore[`${p}`] === false && validator[`${name}`]) {
          if (
            Object.prototype.hasOwnProperty.call(variables, name) &&
            Object.prototype.hasOwnProperty.call(variables[`${name}`], p)
          ) {
            error = this.$t(validator[`${name}`][`${p}`], variables[`${name}`][`${p}`])
          } else {
            error = this.$t(validator[`${name}`][`${p}`])
          }
        }
      })

      return error
    },

    $_formMixin_successModalWithRedirectToCss(
      title = 'payments.popupMessages.success.successTitle',
      msg = 'payments.popupMessages.success.successMessage'
    ) {
      const successTitle = this.$t(title)
      const successMessage = this.$t(msg)

      this.$_formMixin_openModal({
        status: 'success',
        cta: {
          title: this.css.redirectBack ? 'buttons.backToCSS' : 'buttons.toHomePage',
          action: this.redirectToCSS
        },
        successTitle,
        successMessage,
        showCta: true
      })
    },

    redirectToCSS() {
      if (this.css.redirectBack) {
        const data = {
          pnr: this.css.pnr,
          lastname: this.css.surname
        }

        this.$_LocationHelpers_submitFormWithData(this.css.url, data)
      } else {
        window.location.href = '/'
      }
      this.$store.dispatch('payment/css/clearData')
    },

    $_formMixin_successModal(
      title = 'payments.popupMessages.success.successTitle',
      message = 'payments.popupMessages.success.successMessage',
      showCta = true,
      form = '',
      customCloseFn = null
    ) {
      this.$_formMixin_openModal({
        status: 'success',
        successTitle: this.$t(title),
        successMessage: this.$t(message),
        showCta,
        form,
        customCloseFn
      })
    },
    $_formMixin_openFailModal(err, title = null, showCta = false, customCloseFn = null) {
      this.$_formMixin_openModal({
        status: 'fail',
        failTitle:
          title === null ? this.$t('payments.popupMessages.fail.failTitle') : title,
        failMessage: !isObject(err) ? [err] : err,
        showCta,
        customCloseFn
      })
    },

    $_formMixin_openModal(props) {
      let closeFn = this.$_formMixin_closeModal
      if (props.customCloseFn) {
        closeFn = props.customCloseFn
      }
      ScrollUtils.smoothScroll(0, 300)
      this.responseStatus = props.status
      this.filledForm = props.form
      this.$store.dispatch('main/openModal')
      this.$store.commit('main/setShowModalHeader', false)
      this.$store.commit('main/setShowModalHeaderWhite', false)
      this.$store.commit('main/setModalContent', DefaultFormNotification)
      this.$store.commit('main/setModalType', 'centered')
      this.$store.commit('main/setModalClass', 'modal-white-centered')
      this.$store.commit('main/setShowCloseIcon', true)
      this.$store.commit('main/setShowModalHeader', false)
      this.$store.commit('main/setModalCloseFn', closeFn)
      this.$store.commit('main/setModalProps', props)
    },

    $_formMixin_closeModal(namespace = 'payment') {
      if (this.$store.state.main.formNamespace !== '') {
        namespace = this.$store.state.main.formNamespace
      }
      this.$store.dispatch('main/closeModal')
      this.$_formMixin_enableScroll(this.deviceType)

      if (this.responseStatus === 'success') {
        if (['deleteProfile'].includes(this.filledForm)) {
          window.location.href = '/'
        }
        ScrollUtils.smoothScroll(0, 300)

        if (
          namespace &&
          !['emailEdit', 'deleteProfile', 'EditEmailSubscribe'].includes(this.filledForm)
        ) {
          this.$store.commit(`${namespace}/generic/setActiveStep`, 'intro')
        }
      }
    },

    $_formMixin_enableScroll(device) {
      if (device === 'mobile') {
        ScrollUtils.enableScroll()
      }
    }
  }
}

export default Form
