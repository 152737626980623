const SnippetTemplate = {
  state: {
    sections: {},
    pageTitle: '',
    pageTitlePosition: ''
  },
  mutations: {
    setSections (state, sections) {
      state.sections = sections
    },
    setPageTitle (state, title) {
      state.pageTitle = title
    },
    setPageDescription (state, title) {
      state.pageDescription = title
    },
    setPageTitlePosition (state, position) {
      state.pageTitlePosition = position
    }
  },
  namespaced: true
}

export default SnippetTemplate
