import Api from 'services/AirBaltic/Api'
import ErrorHandler from 'utils/ErrorHandler'
import conf from 'conf'

const ERRORS = {}

class Compliment {

  submitForm (data) {
    const path = '/contact/compliment'
    return new Api().post(path + conf.btUrlCors, data)
      .then((response) => Promise.resolve(response))
      .catch(error => Promise.reject(this.getErrors(error.response)))
  }

  getErrors (response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default Compliment



