const deviceTypes = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile'
}

const Device = {
  state: {
    deviceType: deviceTypes.DESKTOP,
    clientWidth: 0,
    clientHeight: 0,
    isRetina: false,
    isIE: false
  },
  mutations: {
    setDeviceType (state, newType) {
      state.deviceType = newType
    },
    setIsRetina (state, isRetina) {
      state.isRetina = isRetina
    },
    setIsIE (state, isIE) {
      state.isIE = isIE
    },
    setClientWidth (state, newClientWidth) {
      state.clientWidth = newClientWidth
    },
    setClientHeight (state, newClientHeight) {
      state.clientHeight = newClientHeight
    }
  },
  getters : {
    isMobile (state) {
      return state.deviceType === 'mobile'
    },
    isTablet (state) {
      return state.deviceType === 'tablet'
    },
    isDesktop (state) {
      return state.deviceType === 'desktop'
    },
    isIE (state) {
      return state.isIE
    },
    notMobile (state) {
      return state.deviceType === 'desktop' || state.deviceType === 'tablet'
    },
  },
  namespaced: true
}

export default Device
