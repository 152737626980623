import RegisterProfile from 'models/Forms/Profile/RegisterProfile'
import cloneDeep from 'lodash-es/cloneDeep'

const Generic = {
  namespaced: true,
  state: Object.assign({}, new RegisterProfile()),
  mutations: {
    setPageTitle (state, value) {
      state.pageTitle = value
    },
    setSections (state, value) {
      state.sections = value
    },
    setLanguages(state, value) {
      state.languages = value
    },
    setCountries(state, value) {
      state.countries = value
    },
    setPhoneCodes (state, value) {
      state.phoneCodes = value
    },
    setTerms (state, value) {
      state.termsAndConditions = value
    },
    setSignUpPage (state, value) {
      state.signUpPage = value
    },
    setLoginPage (state, value) {
      state.loginPage = value
    },
    setProfilePage (state, value) {
      state.profilePageUrl = value
    },
    setPlatforms (state, value) {
      state.platforms = value
    }
  },
  actions: {
    removeNewsletterNotification ({ state, commit }) {
      const platforms = cloneDeep(state.platforms)
      delete platforms.newsletter

      commit('setPlatforms', platforms)
    },
    setCountries ({ commit }, data) {
      let countries = [{ value: '', title: '---' }]
      let phoneCodes = [{ key: '', value: '', title: '---' }]

      Object.values(data).map((country) => {
        countries.push({ value: country.code, title: country.name })
        phoneCodes.push({ key: country.code, value: country.dialingCode, title: country.name })
      })

      countries = countries.sort((a, b) => a.title.localeCompare(b.title))
      phoneCodes = phoneCodes.sort((a, b) => a.title.localeCompare(b.title))

      commit('setCountries', countries)
      commit('setPhoneCodes', phoneCodes)
    },
    setLanguages ({ commit }, data) {
      const languages = [{ value: '', title: '---' }]

      data.map((lang) => {
        languages.push({ value: lang, title: `languages.${lang.toLowerCase()}` })
      })

      commit('setLanguages', languages)
    }
  }
}

export default Generic
