const SimpleTextPageContent = {
  state: {
    pageLeadImg: '',
    pageTitle: '',
    blueButtonLink: '',
    blueButtonText: '',
    intro: '',
    sections: [],
    termsAndConditionsVisible: false,
    termsAndConditions: {}
  },
  mutations: {
    setPageLeadImg (state, value) {
      state.pageLeadImg = value
    },
    setPageTitle (state, value) {
      state.pageTitle = value
    },
    setBlueButtonLink (state, value) {
      state.blueButtonLink = value
    },
    setBlueButtonText (state, value) {
      state.blueButtonText = value
    },
    setIntro (state, value) {
      state.intro = value
    },
    setSections (state, value) {
      state.sections = value
    },
    setTermsAndConditionsVisible (state, value) {
      state.termsAndConditionsVisible = value
    },
    setTermsAndConditions (state, value) {
      state.termsAndConditions = value
    }
  },
  namespaced: true
}

export default SimpleTextPageContent
