import EmailChangeService from 'services/AirBaltic/Pax/EmailChange'

const ConfirmationPage = {
  state: {
    pageSuccessTitle: '',
    pageFailTitle: '',
    pageSuccessText: '',
    pageFailText: '',
    status: '',
    token: ''
  },

  mutations: {
    setPageSuccessTitle (state, value) {
      state.pageSuccessTitle = value
    },

    setPageFailTitle (state, value) {
      state.pageFailTitle = value
    },

    setPageSuccessText (state, value) {
      state.pageSuccessText = value
    },

    setPageFailText (state, value) {
      state.pageFailText = value
    },

    setStatus (state, value) {
      state.status = value
    },

    setToken (state, value) {
      state.token = value
    }
  },
  actions: {
    confirmEmail (context, token) {
      const data = {
        token
      }

      return new EmailChangeService().confirm(data)
    }
  },

  namespaced: true
}

export default ConfirmationPage
