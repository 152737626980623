import CompareTableStore from 'store/modules/Pages/CompareTable'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, CompareTableStore)
      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    if (typeof pageData !== "undefined") {
      store.commit(`${name}/setPageData`, pageData)

      if (typeof termsAndConditions !== "undefined") {
        store.commit(`${name}/setTermsAndConditions`, {
          title: termsAndConditions.title.text,
          content: termsAndConditions.content.text
        })
      }
    }
  }
}

export default Init
