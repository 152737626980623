import PaxProfile from 'services/AirBaltic/Pax/Profile'
import * as Sentry from '@sentry/vue'
import FormValidationUtils from 'utils/FormValidation'
import CompanyDetails from 'models/Pax/CompanyDetails'

const companyDetailsModel = new CompanyDetails()

const defaultState = {
  pageTitle: '',
  description: '',
  sectionTitle: '',
  tooltipModalTitle: '',
  tooltipModalUrl: '',
  countries: [],
  form: companyDetailsModel.form,
  formErrors: { form: companyDetailsModel.errorMsg }
}

const validator = FormValidationUtils.initValidation(
  defaultState,
  companyDetailsModel.validation
)

const BusinessTravelStore = {
  state: defaultState,
  mutations: {
    setPageTitle(state, value) {
      state.pageTitle = value
    },
    setDescription(state, value) {
      state.description = value
    },
    setSectionTitle(state, value) {
      state.sectionTitle = value
    },
    setTooltipModalTitle(state, value) {
      state.tooltipModalTitle = value
    },
    setTooltipModalUrl(state, value) {
      state.tooltipModalUrl = value
    },
    setBusinessCountry(state, value) {
      state.form.countryCode = value
    },
    setBusinessCity(state, value) {
      state.form.city = value
    },
    setBusinessPostcode(state, value) {
      state.form.postcode = value
    },
    setBusinessStreet(state, value) {
      state.form.street = value
    },
    setBusinessCompanyName(state, value) {
      state.form.name = value
    },
    setBusinessRegNo(state, value) {
      state.form.regNo = value
    },
    setBusinessVatNumber(state, value) {
      state.form.vat = value
    },
    setCountries(state, value) {
      state.countries = value
    }
  },

  actions: {
    validateForm({ dispatch }) {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form

        if (!vState.$invalid) {
          dispatch('submitForm')
            .then((response) => resolve(response))
            .catch(() => reject(false))
        } else {
          reject(false)
        }
      })
    },

    submitForm({ dispatch, commit, state }) {
      const formData = state.form
      const paxData = {
        companyDetails: {
          city: formData.city,
          postcode: formData.postcode,
          countryCode: formData.countryCode,
          street: formData.street,
          name: formData.name,
          regNo: formData.regNo,
          vat: formData.vat
        }
      }

      return new PaxProfile()
        .updatePaxProfile(paxData)
        .then(() => {
          dispatch('main/showSuccessNotification', null, { root: true })
          return Promise.resolve('success')
        })
        .catch((e) => {
          commit('main/showErrorNotification', null, { root: true })
          Sentry.captureException(e)
          return Promise.reject(false)
        })
    }
  },

  getters: {
    $v() {
      return Object.assign({}, validator.$v.state)
    }
  },

  namespaced: true
}

export default BusinessTravelStore
