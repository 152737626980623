import AbstractView from 'abstract';
import Vue from 'vue'
import editProfileInit from 'initializations/pages/Pax/EditProfilePage'
import SidebarInit from 'initializations/Sidebar'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
      return super(domElementId);
    }

    init () {
      SidebarInit.init("sidebar")
      editProfileInit.init("editProfilePage")

      const editProfilePage = () => import(/* webpackChunkName: "edit-profile-page" */ 'containers/Pages/Profile/Edit')
      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(editProfilePage)
      })
    }
}
