import axios from 'axios'

const GalleryPageContent = {
  state: {
    pageTitle: '',
    activePage: '',
    galleryPages: [],
    sections: {},
    sectionValues: {},
    visibleItems: 6,
    perPage: 6
  },

  mutations: {
    setPageTitle(state, value) {
      state.pageTitle = value
    },

    setActivePage(state, value) {
      state.activePage = value
    },

    setGalleryPages(state, value) {
      state.galleryPages = value
    },

    setCurrentImage(state, value) {
      state.currentImage = value
    },

    setSections(state, value) {
      state.sections = value
    },

    setSectionValues(state, value) {
      state.sectionValues = value
    },

    setVisibleItems(state, value) {
      state.visibleItems = value
    },

    setPerPage(state, value) {
      state.perPage = value
    }
  },

  actions: {
    setCurrentVisibleItems({ commit, state }) {
      commit('setVisibleItems', state.visibleItems + state.perPage)
    },

    fetchSectionValues({ commit, state }, value) {
      if (typeof state.sections[`${value}`] !== "undefined") {
        commit('setSectionValues', state.sections[`${value}`])
      }
    },

    downloadImage({ commit }, value) {
      commit('main/setShowSmallLoader', true, { root: true })

      const fileName = value.img.source.split('/').pop()

      axios({
        url: value.img.source,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)

        fileLink.click()
      })
      .catch((e) => Sentry.captureException(e))
      .finally(() => commit('main/setShowSmallLoader', false, { root: true }))
    }
  },

  namespaced: true
}

export default GalleryPageContent
