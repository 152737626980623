import Api from 'services/AirBaltic/Api'
import ErrorHandler from 'utils/ErrorHandler'
import moment from 'moment'
import get from 'lodash-es/get'
import PassengerModel from 'models/Forms/Sections/Passenger'
import FlightModel from 'models/Forms/Sections/Flights'

const ERRORS = {
  GENERIC: 'generic.errors.serverError',
  FLIGHTDATE_DATEAFTERTODAYTYPE: 'reusable.flightDate.validation.dateAfterToday',
  NOT_FOUND: 'forms.errors.paxNotFound',
  NO_ACTIVE_FLIGHTS_12H: 'forms.errors.noActiveFlights12Hours',
  NO_ACTIVE_FLIGHTS_48H: 'forms.errors.noActiveFlights48Hours',
  NO_ACTIVE_FLIGHTS: 'forms.errors.noActiveFlights',
  NO_BT_FLIGHTS: 'forms.errors.noBTFlights',
  NO_ACTIVE_FLIGHTS_72H: 'forms.errors.noActiveFlights72Hours'
}

class PnrRetrieval {

  validatePnr (pnr, surname) {
    const data = { pnrRl: pnr, surname }

    return new Api().get(`/pnr-retrieval/retrieve`, data)
      .then(response => Promise.resolve(response))
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          return Promise.reject(ERRORS.NOT_FOUND)
        } else {
          return Promise.reject(ERRORS.GENERIC)
        }
      })
  }

  parseResponse (data) {
    const flights = get(data.response, 'data.segments', [])

    if (!data.rules.length) {
      return Promise.resolve()
    }

    let result = Promise.resolve()

    for (let i = 0; i < data.rules.length; i++) {
      if (data.rules[parseInt(i)] === 'NO_ACTIVE_FLIGHTS_72H' && this.noActiveFlights72h(flights)) {
        result = Promise.reject(ERRORS.NO_ACTIVE_FLIGHTS_72H)
        break
      }

      if (data.rules[parseInt(i)] === 'NO_ACTIVE_FLIGHTS_48H' && this.noActiveFlights48h(flights)) {
        result = Promise.reject(ERRORS.NO_ACTIVE_FLIGHTS_48H)
        break
      }

      if (data.rules[parseInt(i)] === 'NO_ACTIVE_FLIGHTS_12H' && this.noActiveFlights12h(flights)) {
        result = Promise.reject(ERRORS.NO_ACTIVE_FLIGHTS_12H)
        break
      }

      if (data.rules[parseInt(i)] === 'NO_ACTIVE_FLIGHTS' && this.noActiveFlights(flights)) {
        result = Promise.reject(ERRORS.NO_ACTIVE_FLIGHTS)
        break
      }

      if (data.rules[parseInt(i)] === 'NO_BT_FLIGHTS') {
        result = Promise.reject(ERRORS.NO_BT_FLIGHTS)
        break
      }
    }

    return result
  }

  noActiveFlights72h (flights) {
    const prev72HourFlights = this.flightsNext72Hours(flights)

    return flights.length === prev72HourFlights.length
  }

  noActiveFlights48h (flights) {
    const prev48HourFlights = this.flightsPrev48Hours(flights)

    return flights.length === prev48HourFlights.length
  }

  noActiveFlights12h (flights) {
    const prev12HourFlights = this.flightsPrev12Hours(flights)

    return flights.length === prev12HourFlights.length
  }

  noActiveFlights (flights) {
    const departedFlights = this.filterDepartedFlights(flights)

    return flights.length < 0 || flights.length === departedFlights.length
  }

  diff (flight, rules = null) {
    const defaultRule = moment(flight.departureDate)

    if (rules) {
      if (rules.action === 'subtract') {
        defaultRule.subtract(rules.rule.time, rules.rule.unit)
      }
    }

    return defaultRule.diff(moment(), 'minutes')
  }

  filterDepartedFlights (flights) {
    return flights.filter(flight => this.diff(flight) < 0)
  }

  flightsPrev12Hours (flights) {
    const rule = {
      action: 'subtract',
      rule: {
        time: 12,
        unit: 'hours'
      }
    }

    return flights.filter(flight => this.diff(flight, rule) <= 0)
  }

  flightsNext72Hours (flights) {
    const filtered = flights.filter(flight => {
      const diff = moment(flight.departureDate).diff(moment(), "minutes")
      return diff <= 0 || diff >= 3 * 24 * 60
    })

    return filtered
  }

  flightsPrev48Hours (flights) {
    const rule = {
      action: 'subtract',
      rule: {
        time: 48,
        unit: 'hours'
      }
    }

    return flights.filter(flight => this.diff(flight, rule) <= 0 )
  }

  getErrors (response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }

  getPaxData (passengers) {
    const paxData = []

    passengers.forEach((pax, index) => {
      const passenger = Object.assign({id: index}, pax)
      if (passenger.givenName !== null) {
        const modelData = new PassengerModel(passenger)

        paxData.push(modelData)
      }
    })

    return paxData
  }

  getFlightData (response) {
    const flightData = []

    const data = {
      flightData: {
        flightNumber: '',
        flightDate: '',
        origin: '',
        destin: '',
        carrier: '',
        number: 0
      },
      flightDate: {
        flightDay: '',
        flightMonth: '',
        flightYear: ''
      }
    }

    response.forEach((flight) => {
      const obj = Object.assign({}, data)

      obj.flightData = {
        flightNumber: (flight.carrier+flight.number),
        flightDate: moment(flight.departureDate),
        origin: flight.origin,
        destin: flight.destin,
        carrier: flight.carrier,
        number: flight.number
      }

      const flightModel = new FlightModel(obj.flightData)

      obj.flightDate = {
        flightDay: flightModel.flightDate.format('DD'),
        flightMonth: flightModel.flightDate.format('MM'),
        flightYear: flightModel.flightDate.format('YYYY'),
      }

      flightData.push(Object.assign({}, flightModel, obj.flightDate))
    })

    if (!response.length) {
      const flightModel = new FlightModel(data.flightData)
      flightData.push(Object.assign({}, flightModel, data.flightDate))
    }

    return flightData
  }
}

export default PnrRetrieval
