import moment from 'moment';
import get from 'lodash-es/get';
export var ERRORS = {
    GENERIC: 'generic.errors.serverError',
    FLIGHTDATE_DATEAFTERTODAYTYPE: 'reusable.flightDate.validation.dateAfterToday',
    NOT_FOUND: 'forms.errors.paxNotFound',
    NO_ACTIVE_FLIGHTS_12H: 'forms.errors.noActiveFlights12Hours',
    NO_ACTIVE_FLIGHTS_48H: 'forms.errors.noActiveFlights48Hours',
    NO_ACTIVE_FLIGHTS: 'forms.errors.noActiveFlights',
    NO_BT_FLIGHTS: 'forms.errors.noBTFlights',
    NO_ACTIVE_FLIGHTS_CLASS_UPGRADE: 'forms.errors.noActiveFlightsClassUpgrade',
    EXCLUDE_GROUP_FLIGHTS: 'forms.errors.excludeGroupFlights',
    NO_ACTIVE_FLIGHTS_AFTER_24H_OR_NO_BT_FLIGHTS: 'forms.errors.noActiveFlights24HoursOrNoBTFlights',
    NO_ACTIVE_FLIGHTS_72H: 'forms.errors.noActiveFlights72Hours'
};
var PnrRetrieve = /** @class */ (function () {
    function PnrRetrieve() {
    }
    PnrRetrieve.validateBooking = function (_a) {
        var response = _a.response, rules = _a.rules;
        var flights = get(response, 'data.segments', []);
        var header = get(response, 'data.header', []);
        if (!rules.length) {
            return Promise.resolve();
        }
        var error = rules.find(function (rule) {
            if (!Object.keys(PnrRetrieve.bookingRules).includes(rule)) {
                return false;
            }
            if (rule === 'NO_BT_FLIGHTS') {
                return true;
            }
            if (rule === 'EXCLUDE_GROUP_FLIGHTS') {
                return !!PnrRetrieve.bookingValidations[rule](header);
            }
            return !!PnrRetrieve.bookingValidations[rule](flights);
        });
        return (error === null || error === void 0 ? void 0 : error.length)
            ? Promise.reject(PnrRetrieve.bookingRules[error])
            : Promise.resolve();
    };
    PnrRetrieve.noActiveFlights72h = function (flights) {
        var next72hFlights = PnrRetrieve.flightsNext72Hours(flights);
        return flights.length === next72hFlights.length;
    };
    PnrRetrieve.flightsNext72Hours = function (flights) {
        return flights.filter(function (flight) {
            var diff = moment(flight.departureDate).diff(moment(), 'minutes');
            var seventyTwoHoursInMinutes = 4320;
            return diff <= 0 || diff >= seventyTwoHoursInMinutes;
        });
    };
    PnrRetrieve.noActiveFlights48h = function (flights) {
        var prev48HourFlights = PnrRetrieve.flightsPrev48Hours(flights);
        return flights.length === prev48HourFlights.length;
    };
    PnrRetrieve.noActiveFlights12h = function (flights) {
        var prev12HourFlights = PnrRetrieve.flightsPrev12Hours(flights);
        return flights.length === prev12HourFlights.length;
    };
    PnrRetrieve.noActiveFlights = function (flights) {
        var departedFlights = PnrRetrieve.filterDepartedFlights(flights);
        return flights.length < 0 || flights.length === departedFlights.length;
    };
    PnrRetrieve.noActiveFlightsAfter24HOrBTFLight = function (flights) {
        var prev24HourFlights = this.flightsAfter24Hours(flights);
        var BTFlights = this.flightsWithBT(prev24HourFlights);
        return BTFlights.length <= 0;
    };
    PnrRetrieve.excludeGroupFlights = function (header) {
        return header.group;
    };
    PnrRetrieve.diff = function (flight, rules) {
        if (rules === void 0) { rules = {}; }
        var defaultRule = moment(flight.departureDate);
        if ((rules === null || rules === void 0 ? void 0 : rules.action) === 'subtract') {
            defaultRule.subtract(rules.rule.time, rules.rule.unit);
        }
        return defaultRule.diff(moment(), 'minutes');
    };
    PnrRetrieve.filterDepartedFlights = function (flights) {
        var _this = this;
        return flights.filter(function (flight) { return _this.diff(flight) < 0; });
    };
    PnrRetrieve.flightsAfter24Hours = function (flights) {
        return flights.filter(function (flight) {
            var diff = moment(flight.departureDate).diff(moment(), 'minutes');
            return diff >= 24 * 60;
        });
    };
    PnrRetrieve.flightsWithBT = function (flights) {
        return flights.filter(function (flight) {
            return flight.carrier === 'BT';
        });
    };
    PnrRetrieve.flightsPrev12Hours = function (flights) {
        var _this = this;
        var rule = {
            action: 'subtract',
            rule: {
                time: 12,
                unit: 'hours'
            }
        };
        return flights.filter(function (flight) { return _this.diff(flight, rule) <= 0; });
    };
    PnrRetrieve.flightsPrev48Hours = function (flights) {
        var _this = this;
        var rule = {
            action: 'subtract',
            rule: {
                time: 48,
                unit: 'hours'
            }
        };
        return flights.filter(function (flight) { return _this.diff(flight, rule) <= 0; });
    };
    PnrRetrieve.bookingRules = {
        NO_ACTIVE_FLIGHTS_72H: ERRORS.NO_ACTIVE_FLIGHTS_72H,
        NO_ACTIVE_FLIGHTS_48H: ERRORS.NO_ACTIVE_FLIGHTS_48H,
        NO_ACTIVE_FLIGHTS_12H: ERRORS.NO_ACTIVE_FLIGHTS_12H,
        NO_ACTIVE_FLIGHTS: ERRORS.NO_ACTIVE_FLIGHTS,
        NO_BT_FLIGHTS: ERRORS.NO_BT_FLIGHTS,
        NO_ACTIVE_FLIGHTS_CLASS_UPGRADE: ERRORS.NO_ACTIVE_FLIGHTS_CLASS_UPGRADE,
        EXCLUDE_GROUP_FLIGHTS: ERRORS.EXCLUDE_GROUP_FLIGHTS,
        NO_ACTIVE_FLIGHTS_AFTER_24H_OR_NO_BT_FLIGHTS: ERRORS.NO_ACTIVE_FLIGHTS_AFTER_24H_OR_NO_BT_FLIGHTS
    };
    PnrRetrieve.bookingValidations = {
        NO_ACTIVE_FLIGHTS_72H: function (flights) {
            return PnrRetrieve.noActiveFlights72h(flights);
        },
        NO_ACTIVE_FLIGHTS_48H: function (flights) {
            return PnrRetrieve.noActiveFlights48h(flights);
        },
        NO_ACTIVE_FLIGHTS_12H: function (flights) {
            return PnrRetrieve.noActiveFlights12h(flights);
        },
        NO_ACTIVE_FLIGHTS: function (flights) { return PnrRetrieve.noActiveFlights(flights); },
        NO_ACTIVE_FLIGHTS_CLASS_UPGRADE: function (flights) {
            return PnrRetrieve.noActiveFlights(flights);
        },
        EXCLUDE_GROUP_FLIGHTS: function (header) {
            return PnrRetrieve.excludeGroupFlights(header);
        },
        NO_ACTIVE_FLIGHTS_AFTER_24H_OR_NO_BT_FLIGHTS: function (flights) {
            return PnrRetrieve.noActiveFlightsAfter24HOrBTFLight(flights);
        }
    };
    return PnrRetrieve;
}());
export default PnrRetrieve;
