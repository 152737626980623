<template>
  <div class="press-contacts-sidebar-block">
    <div class="press-contacts-sidebar-wrap">
      <div class="contacts-info">
        <h4>{{ pressContacts.title }}</h4>
        <div class="contacts-company">
          {{ pressContacts.companyName }}
          <span class="department">
            {{ pressContacts.department }}
          </span>
        </div>
        <div class="contacts-list-col">
          <div
            v-dompurify-html="pressContacts.address"
            class="contacts-list-item address"
          />
        </div>
        <div class="contacts-list-col">
          <div class="contacts-list-item mail">
            <a
              :href="`mailto:${ pressContacts.email }`"
              target="_top"
            >
              {{ pressContacts.email }}
            </a>
          </div>
          <div
            v-dompurify-html="pressContacts.phone"
            class="contacts-list-item phone"
          />
        </div>
      </div>

      <div
        v-dompurify-html="pressContacts.additionalInfo"
        class="contacts-hint"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      pressContacts: {
        type: Object,
        default: () => {}
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  .press-contacts-sidebar-block {
    margin-top: 32px;

    @include bp-tablet {
      padding: 32px 26px;
      background-color: $grey-the-whitest;
    }

    /deep/ a {
      color: $blue-medium;

      &:hover {

        @include bp-mf-tablet {
          color: $blue-light;
        }
      }
    }
  }

  .press-contacts-sidebar-wrap {
    border: 1px solid $grey-border;
    background-color: #ffffff;
  }

  .contacts-info {
    padding: 28px 24px 33px;

    @include bp-tablet-only {
      padding: 28px 30px 25px;
    }

    h4 {
      margin: 0;
      font-weight: 500;

      @include bp-tablet-only {
        margin-bottom: 21px;
      }
    }
  }

  .contacts-company {
    margin-top: 13px;
    padding-bottom: 3px;
    font-size: em(13);
    line-height: 17px;

    @include bp-tablet-only {
      margin-top: 0;
      padding-right: 60px;
    }

    .department {
      display: block;
      margin-top: 5px;
      font-size: 12px;
      color: $grey;

      @include bp-tablet-only {
        margin-top: 6px;
      }
    }
  }

  .contacts-company,
  .contacts-list-col {

    @include bp-tablet-only {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 3 - 3px);
    }
  }

  .contacts-list-col {

    @include bp-tablet-only {
      margin-top: -4px;
    }
  }

  .contacts-list-item {
    position: relative;
    margin-top: 18px;
    padding-left: 49px;
    font-size: em(13);
    line-height: 22px;

    @include bp-tablet-only {
      margin: 0;
    }

    &:before {
      content: '';
      @include icon-font-thin();
      position: absolute;
      top: -4px;
      left: 0;
      width: 32px;
      height: 32px;
      background-color: $grey-white-2;
      border-radius: 50%;
      line-height: 34px;
      text-align: center;
      color: $blue-medium;
    }

    &.address {

      /deep/ p {
        margin: 0;
      }

      &:before {
        content: $icon-map-pin-thin;
        font-size: em(16);
      }
    }

    &.mail {

      @include bp-tablet-only {
        margin: 0 0 30px;
      }

      &:before {
        content: $icon-envelope-thin;
        font-size: em(11);
      }
    }

    &.phone:before {
      content: $icon-phone-thin;
      font-size: em(16);
    }
  }

  .contacts-hint {
    position: relative;
    padding: 17px 24px;
    border-top: 1px solid $grey-border;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 24px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    &:before {
      top: -11px;
      border-bottom: 10px solid $grey-border;
    }

    &:after {
      top: -10px;
      border-bottom: 10px solid #ffffff;
    }

    /deep/ p {
      margin: 0;
      font-size: em(12);
      line-height: 16px;
      color: #7f7f7f;
    }
  }
</style>
