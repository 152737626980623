import Vue from 'vue';
export default function textInCurrentLang(text) {
    switch (Vue.i18n.locale()) {
        case 'lv':
            return /^[a-zA-ZĀāČčĒēĢģĪīĶķĻļŅņŠšŪūŽž()\- \s]*$/.test(text);
        case 'en':
            return /^[a-zA-Z()\- \s]*$/.test(text);
        case 'et':
            return /^[a-zA-ZŠšŽžÕõÄäÖöÜü()\- \s]*$/.test(text);
        case 'ru':
            return /^[A-Zа-яА-ЯЁё()\- \s]*$/.test(text);
        case 'de':
            return /^[a-zA-ZÄäÖöÜüẞß()\- \s]*$/.test(text);
        case 'lt':
            return /^[a-zA-ZĄąČčĘęĖėĮįŠšŲŪūŽž()\- \s]*$/.test(text);
        case 'fi':
            return /^[a-zA-ZÅåÄäÖöŠšŽž()\- \s]*$/.test(text);
        default:
            return false;
    }
}
