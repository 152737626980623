var CheckedBaggage = /** @class */ (function () {
    function CheckedBaggage() {
        var _this = this;
        this.checked = false;
        this.count = 0;
        this.valid = false;
        this.type = 'checked_baggage';
        this.errorMsg = {
            count: {
                minValue: 'payments.sections.passengersDetails.checkedBaggage.validation.baggageCountValid.oneOrMore',
            },
        };
        this.validation = {
            count: {
                minValue: function (value) {
                    var valid = true;
                    if (_this.checked) {
                        valid = value > 0;
                    }
                    return valid;
                },
            },
        };
    }
    return CheckedBaggage;
}());
export default CheckedBaggage;
