import * as moment from 'moment';
import get from 'lodash-es/get';
var Leg = /** @class */ (function () {
    function Leg(obj) {
        if (obj === void 0) { obj = {}; }
        this.arrTime = '';
        this.arrivalTime = '';
        this.depTime = '';
        this.departTime = '';
        this.dest = '';
        this.durationMinutes = 0;
        this.flightNumber = [];
        this.orig = '';
        this.conn = '';
        this.connTime = 0;
        this.nextDay = false;
        this.duration = '';
        this.periodStart = '';
        this.periodEnd = '';
        this.dates = [];
        this.selected = false;
        var arrival = moment.parseZone(obj.arrTime, 'HH:mmZ');
        var departure = moment.parseZone(obj.depTime, 'HH:mmZ');
        var durationMinutes = get(obj, 'durationMinutes', 0);
        var periodStart = get(obj, 'periodStart', '');
        var periodEnd = get(obj, 'periodEnd', '');
        var dates = get(obj, 'dates', []);
        var data = {
            arrivalTime: arrival.format('HH:mm'),
            departTime: departure.format('HH:mm'),
            durationMinutes: durationMinutes,
            duration: this.getTimeFromMins(durationMinutes),
            nextDay: arrival.isBefore(departure),
            dates: this.enumerateDaysBetweenDates(periodStart, periodEnd, dates)
        };
        Object.assign(this, obj, data);
    }
    Leg.prototype.enumerateDaysBetweenDates = function (startDate, endDate, obj) {
        var dates = [];
        var currDate = moment(startDate).startOf('day').subtract(1, 'days');
        var lastDate = moment(endDate).startOf('day');
        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            if (obj.includes(currDate.format('YYYY-MM-DD'))) {
                dates.push({ hasFlight: true, selected: false });
            }
            else {
                dates.push({ hasFlight: false, selected: false });
            }
        }
        return dates;
    };
    Leg.prototype.getTimeFromMins = function (mins) {
        var h = Math.floor(mins / 60);
        var m = mins % 60;
        var hC = h < 10 ? '0' + h : h;
        var mC = m < 10 ? '0' + m : m;
        return "".concat(hC, ":").concat(mC);
    };
    return Leg;
}());
export default Leg;
