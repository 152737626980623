<template>
  <div class="login-wrapper">
    <template v-if="!isLoginDisabled">
      <div class="login-header">
        <div class="header-title">
          {{ $t('header.white.login.loginTitle') }}
        </div>
        <close-icon @click="closeIframe" />
      </div>
      <login-form
        :show-tooltip="false"
        :internal="false"
        @openForgotPassword="openForgotPassword"
        @success="onSuccess"
        @fail="onFail"
      />
    </template>
    <div
      v-else
      class="login-wrapper__error"
    >
      <close-icon @click="closeIframe" />
      <v-alert>
        {{ $t('header.loginForm.unavailableLogin') }}
      </v-alert>
    </div>
  </div>
</template>
<script>
  import LoginForm from 'components/LoginForm/LoginForm'
  import ForgotPasswordForm from 'components/LoginForm/ForgotPasswordForm'
  import CloseIcon from 'components/CloseIcon'
  import CookieUtils from 'utils/CookieUtils'
  import conf from 'conf'
  import Vue from 'vue'
  import { mapState } from 'vuex'
  const VAlert = () => import(/* webpackChunkName: "v-alert" */ 'components/VAlert')

  export default {
    components: {
      LoginForm,
      CloseIcon,
      VAlert
    },

    data() {
      return {
        pageOrigin: '',
        safeOrigins: [
          'https://www.airbaltic.com',
          'https://btrixnginxtest01.airbaltic.com',
          'https://btrixcmsaptest01.airbaltic.com',
          'https://local.airbaltic.com',
          'https://fly.airbaltic.com',
          'https://btife-int.airbaltic.com',
          'https://btife-test.airbaltic.com',
          'https://btife-uat.airbaltic.com',
          'https://btife-qc.airbaltic.com',
          'https://btifedev-test.2e-systems.com',
          'https://btifedev-test.airbaltic.com',
          'https://livery.airbaltic.com',
          'https://l50.peepdeep.ee',
          'https://planiespotlight.airbaltic.com',
          'https://planiespotlighttest.airbaltic.com'
        ]
      }
    },

    computed: {
      ...mapState('loginForm/forgotPassword', ['emailIsSentFromLoginType']),
      ...mapState('main', ['isLoginDisabled']),

      iframeHeight() {
        const body = document.body
        const html = document.documentElement
        return Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        )
      },

      iframeWidth() {
        const body = document.body
        const html = document.documentElement
        return Math.max(
          body.scrollWidth,
          body.offsetWidth,
          html.clientWidth,
          html.scrollWidth,
          html.offsetWidth
        )
      }
    },

    watch: {
      emailIsSentFromLoginType: {
        handler: function () {
          if (this.emailIsSentFromLoginType) {
            this.passwordRecoveryGtmPush()
          }
        }
      }
    },

    mounted() {
      window.addEventListener('message', this.receiveMessage, false)
      this.sendLoadedEvent()
    },

    methods: {
      openForgotPassword() {
        this.$store.dispatch('main/openModal')
        this.$store.commit(
          'main/setModalTitle',
          this.$t('header.loginForm.forgotPasswordTitle')
        )
        this.$store.commit('main/setModalContent', ForgotPasswordForm)
        this.$store.commit('main/setModalCloseFn', this.closeModal)
        this.$store.commit('main/setModalProps', {
          loginType: 'email',
          loginPage: 'ibe_pages'
        })

        this.postMessage({
          event: 'resize',
          width: this.iframeWidth + 'px',
          height: '380px'
        })
      },

      closeModal() {
        this.$store.dispatch('main/closeModal')
        this.$store.dispatch('loginForm/forgotPassword/resetForm')
        this.resizeIframe()
      },

      passwordRecoveryGtmPush() {
        this.postMessage({
          event: 'login_event',
          eventCategory: 'forget_password',
          eventAction: this.emailIsSentFromLoginType,
          site_language: Vue.i18n.locale()
        })
        this.$store.commit('loginForm/forgotPassword/setEmailIsSentFromLoginType', null)
      },

      resizeIframe() {
        this.postMessage({
          event: 'resize',
          width: this.iframeWidth + 'px',
          height: this.iframeHeight + 30 + 'px'
        })
      },

      onSuccess() {
        const token = CookieUtils.getCookie(conf.jwtCookieName)

        this.postMessage({
          event: 'login-success',
          token,
          close: true
        })
        this.closeIframe()
      },

      onFail({ action }) {
        this.postMessage({
          event: 'login-error',
          close: false,
          eventAction: action
        })
        this.resizeIframe()
      },

      postMessage(message) {
        if (this.pageOrigin) {
          message = JSON.stringify(message)
          window.parent.postMessage(message, this.pageOrigin)
        }
      },

      closeIframe() {
        this.postMessage({
          event: 'close',
          close: true
        })
      },

      receiveMessage(event) {
        if (this.isRequestUrlSafe(event.origin)) {
          if (event.data === 'setOrigin') {
            this.pageOrigin = event.origin
          }
        }
      },

      isRequestUrlSafe(origin) {
        let isSafe = false
        this.safeOrigins.forEach((url) => {
          const lastChar = url.slice(-1)
          if (lastChar === '/') {
            url = url.slice(0, -1)
          }

          if (origin === url) {
            isSafe = true
          }
        })

        return isSafe
      },

      sendLoadedEvent() {
        let message = {
          event: 'loaded',
          close: false
        }
        message = JSON.stringify(message)
        window.parent.postMessage(message, '*')
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .login-wrapper {
    background: #ffffff;
    font-family: Roboto, Helvetica Neue, Helvetica, Trebuchet MS, Arial, sans-serif;
    width: 320px;
    margin: 0 auto;

    &__error {
      padding: 3rem 2rem;

      ::v-deep .close-icon {
        top: 1rem;
        right: 1rem;
      }
    }
  }

  .login-form {
    padding: 0 20px 44px;
    margin-top: 24px;
  }

  /deep/ .social-login {
    &.is-ie {
      .social-block {
        width: calc(50% - 5px);
      }
    }
  }

  /deep/ .social-block {
    margin: 0 5px 10px 0;

    &:last-child {
      margin: 0 0 10px 0;
    }
  }

  /deep/ .form-errors {
    border: 1px solid $formInputBorder;
    border-radius: 3px;
    background-color: $danger-bg;

    &:before {
      display: none;
    }
  }

  .login-header {
    padding: 0 55px 0 24px;
    height: 53px;
    border-bottom: 1px solid $grey-border;

    .header-title {
      @include text-overflow();
      line-height: 55px;
      font-weight: 500;

      @include bp-mf-tablet {
        position: relative;
        line-height: 57px;
      }
    }

    .close-icon {
      top: 0;
      right: 20px;
      font-size: em(18);
      line-height: 55px;
      color: $blue-medium;
    }
  }
</style>
