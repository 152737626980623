class Validation {

  static clearErrors (store, namespace) {
    store.dispatch(`${namespace}/removeError`, { name: 'generic', msg: `generic.errors.serverError`})
    store.dispatch(`${namespace}/removeError`, { name: 'generic', msg: `forms.errors.noActiveFlights`})
    store.dispatch(`${namespace}/removeError`, { name: 'generic', msg: `forms.errors.noActiveFlights12Hours`})
    store.dispatch(`${namespace}/removeError`, { name: 'generic', msg: `forms.errors.noActiveFlights24Hours`})
  }

  static validateFields (store, namespace, validator, field) {
    const vState = validator.$v.state.form

    if (field === 'pnr' || field === 'all') {
      vState.pnr.$touch()
      this.checkField(store, namespace, validator,{name: 'pnr', validator: 'required', key: 'missingValue'})
      this.checkField(store, namespace, validator,{name: 'pnr', validator: 'pnrOrTicketNumber', key: 'invalid'})
    }

    if (field === 'surname' || field === 'all') {
      vState.surname.$touch()
      this.checkField(store, namespace, validator,{name: 'surname', validator: 'required', key: 'missingValue'})
      this.checkField(store, namespace, validator,{name: 'surname', validator: 'alphaWithSpaces', key: 'onlyLatin'})
    }
  }

  static checkField (store, namespace, validator, data) {
    if (data.name !== 'generic') {
      const obj = {
        name: data.name,
        msg: store.state[`${namespace}`].validation.formErrors[data.name][data.validator]
      }


      if (!validator.$v.state.form[data.name][data.validator]) {
        store.dispatch(`${namespace}/setError`, obj)
      } else {
        store.dispatch(`${namespace}/removeError`, obj)
        store.dispatch(`${namespace}/removeError`, { name: 'generic' })
      }
    }
  }
}

export default Validation
