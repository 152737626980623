<template>
  <div class="payment-options">
    <ul class="payment-logos">
      <li
        v-for="payment in paymentLogos"
        :key="payment.alt"
      >
        <a :href="payment.href">
          <v-lazy-image
            :alt="payment.alt"
            :height="payment.height"
            :src="payment.src"
            :width="payment.width"
            class=""
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import VLazyImage from 'v-lazy-image'

  export default {
    name: 'FooterPayments',
    components: {
      VLazyImage
    },
    data() {
      return {
        paymentLogos: [
          {
            href: this.$t('footerWide.paymentTypes.masterCard.link'),
            src: this.getLogo('master-card.svg'),
            alt: this.$t('footerWide.paymentTypes.masterCard.altText'),
            width: 50,
            height: 40
          },
          {
            href: this.$t('footerWide.paymentTypes.amex.link'),
            src: this.getLogo('american-express.svg'),
            alt: this.$t('footerWide.paymentTypes.amex.altText'),
            width: 40,
            height: 35
          },
          {
            href: this.$t('footerWide.paymentTypes.visa.link'),
            src: this.getLogo('visa.svg'),
            alt: this.$t('footerWide.paymentTypes.visa.altText'),
            width: 57,
            height: 18
          },
          {
            href: this.$t('footerWide.paymentTypes.payPal.link'),
            src: this.getLogo('pay-pal.svg'),
            alt: this.$t('footerWide.paymentTypes.payPal.altText'),
            width: 80,
            height: 19
          },
          {
            href: this.$t('footerWide.paymentTypes.klix.link'),
            src: this.getLogo('klix-logo.svg'),
            alt: this.$t('footerWide.paymentTypes.klix.altText'),
            width: 48,
            height: 18
          },
          {
            href: this.$t('footerWide.paymentTypes.trustly.link'),
            src: this.getLogo('trustly.png'),
            alt: this.$t('footerWide.paymentTypes.trustly.altText'),
            width: 79,
            height: 26
          },
          {
            href: this.$t('footerWide.paymentTypes.bitpay.link'),
            src: this.getLogo('bitpay.png'),
            alt: this.$t('footerWide.paymentTypes.bitpay.altText'),
            width: 71,
            height: 25
          },
          {
            href: this.$t('footerWide.paymentTypes.googlePay.link'),
            src: this.getLogo('googlepay.svg'),
            alt: this.$t('footerWide.paymentTypes.googlePay.altText'),
            width: 57,
            height: 30
          },
          {
            href: this.$t('footerWide.paymentTypes.applePay.link'),
            src: this.getLogo('applepay.svg'),
            alt: this.$t('footerWide.paymentTypes.applePay.altText'),
            width: 47,
            height: 30
          },
          {
            href: this.$t('footerWide.paymentTypes.uatp.link'),
            src: this.getLogo('uatp.png'),
            alt: this.$t('footerWide.paymentTypes.uatp.altText'),
            width: 68,
            height: 20
          }
        ]
      }
    },
    methods: {
      getLogo(filename) {
        return require(`assets/images/logos/${filename}`)
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .payment-options {
    margin-bottom: 24px;
    padding-top: 24px;
    border-top: 1px solid $blue-border;

    @include bp-mf-tablet {
      margin-bottom: 27px;
    }

    @include bp-mf-desktop {
      border: none;
      margin: 0;
      padding: 0;
    }

    .text {
      @include defaultFontValues($blue-dark, 12, 20);
      display: inline-block;
      padding: 20px 0 10px;
      text-align: left;

      @include bp-mf-tablet {
        padding: 25px 0 10px;
      }

      a {
        color: $blue-medium;

        &:hover {
          color: $blue-medium-hover;
        }

        &:visited {
          color: $blue-medium;
        }
      }
    }
  }

  .payment-logos {
    li {
      list-style: none;
      margin: 12px 20px 0 0;
      display: inline-block;
      vertical-align: middle;

      @include bp-mf-tablet {
        margin-right: 40px;
      }
    }
  }
</style>
