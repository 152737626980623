<template>
  <div class="cargo-shipping-sidebar-block">
    <div class="cargo-shipping-sidebar-wrap">
      <h4>{{ cargoShipping.title }}</h4>
      <div class="description">{{ cargoShipping.description }}</div>
      <form
        ref="cargoShippingForm"
        class="cargo-shipping-form"
      >
        <div class="field-group">
          <div
            class="field-wrap prefix"
            :class="{ error: $v.form.prefix.$error }"
          >
            <input
              name="prefix"
              maxlength="3"
              :value="form.prefix"
              @change="setPrefix($event.target.value)"
            />
          </div>
          <span class="separator">-</span>
          <div
            class="field-wrap number"
            :class="{ error: $v.form.number.$error }"
          >
            <input
              name="number"
              maxlength="8"
              :value="form.number"
              @change="setNumber($event.target.value)"
            />
          </div>
        </div>
        <div v-if="genericErrors.length > 0">
          <div
            v-for="(errorMessage, index) in genericErrors"
            :key="index"
            class="error-msg"
          >
            {{ $t(errorMessage) }}
          </div>
        </div>
        <div class="btn btn-blue">
          <button
            type="button"
            @click="submitForm()"
          >
            {{ $t('buttons.continue') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import FormMixin from 'components/FormMixin'
  import ModalMixin from 'components/Modal/mixins/Modal'

  export default {
    mixins: [FormMixin, ModalMixin],
    props: {
      cargoShipping: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      ...mapState('sidebar/cargoShipping', ['title', 'description', 'form', 'formValid']),
      ...mapGetters('sidebar/cargoShipping', ['$v', 'genericErrors'])
    },
    methods: {
      setPrefix(value) {
        this.$store.commit('sidebar/cargoShipping/setPrefix', value)
      },

      setNumber(value) {
        this.$store.commit('sidebar/cargoShipping/setNumber', value)
      },
      submitForm() {
        this.$store.commit('main/setShowSmallLoader', true)
        this.$store
          .dispatch('sidebar/cargoShipping/validate')
          .then(() => {
            this.$store.dispatch('sidebar/cargoShipping/submitForm').then((res) => {
              if (res) {
                this.$store.dispatch('sidebar/cargoShipping/resetForm')
              }
            })
          })
          .finally(() => this.$store.commit('main/setShowSmallLoader', false))
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';

  .cargo-shipping-sidebar-block {
    margin-top: 32px;
    padding: 32px 26px;

    @include bp-tablet {
      background-color: $grey-the-whitest;
    }

    @include bp-mf-desktop {
      padding: 0;
    }
  }

  .cargo-shipping-sidebar-wrap {
    border: 1px solid $grey-border;
    background-color: #ffffff;
    padding: 28px 24px 33px;
  }

  h4 {
    margin: 0;
    font-weight: 500;

    @include bp-tablet-only {
      margin-bottom: 21px;
    }
  }

  .description {
    margin: 13px 0;
    padding-bottom: 3px;
    font-size: em(13);
    line-height: 17px;

    @include bp-tablet-only {
      margin-top: 0;
      padding-right: 60px;
    }
  }

  .cargo-shipping-form {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include bp-tablet-only {
      flex-direction: row;
      justify-content: space-between;
    }

    .btn {
      height: 48px;

      & button {
        line-height: 48px;
      }
    }

    .error-msg {
      display: block;
      line-height: 14px;
      color: $red-dark;
      margin: 0 0 10px;
    }
  }

  .field-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    .field-wrap {
      width: calc((100% / 3) * 2 - 20px);

      &.prefix {
        width: 30%;
      }

      input {
        padding: 0 13px;
        width: 100%;
        height: 48px;
        border: 1px solid $gray-border;
        font-size: em(16);
        color: $grey-black-lighter;

        @include bp-mf-tablet {
          padding: 0 19px;
        }

        &:disabled {
          background-color: rgb(235, 235, 228);
        }
      }

      &.error {
        input {
          border: 1px solid $red-dark;
        }
      }
    }

    .separator {
      width: 30px;
      text-align: center;
      display: inline-block;
      font-size: em(20);
      color: black;
    }
  }
</style>
