<template>
  <div class="payment-options">
    <ul class="payment-logos">
      <li
        v-for="(payment, index) in paymentLogos"
        :key="payment.alt + index"
      >
        <a
          :href="payment.href"
          :alt="`${payment.title}: ${payment.alt}`"
        >
          <v-lazy-image
            :alt="payment.alt"
            :src="payment.src"
            height="40"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import VLazyImage from 'v-lazy-image'

  export default {
    name: 'FooterPayments',
    components: {
      VLazyImage
    },
    props: {
      paymentLogos: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .payment-logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px 24px;

    li {
      list-style: none;

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus {
          img {
            filter: brightness(1.2);
          }
        }
      }

      img {
        max-width: 60px;
      }
    }

    @include bp-mf-tablet {
      gap: 24px 40px;
    }
  }
</style>
