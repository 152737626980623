import { numeric, required } from 'vuelidate/lib/validators';
var Step3 = /** @class */ (function () {
    function Step3() {
        this.loadingStatus = false;
        this.services = [];
        this.price = 0;
        this.selectedServices = [];
        this.additionalInfo = null;
        this.charLimit = 1300;
        this.errorMsg = {
            price: {
                number: 'groupBooking.step3.price.number',
                required: 'groupBooking.step3.price.required',
            },
        };
        this.validations = {
            price: {
                number: numeric,
                required: required,
            },
        };
    }
    return Step3;
}());
export default Step3;
