var DefaultForm = /** @class */ (function () {
    function DefaultForm() {
        this.serviceType = '';
        this.language = '';
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.bookingRef = '';
        this.flightNumber = '';
        this.message = '';
        this.flightDate = '';
        this.company = {};
    }
    return DefaultForm;
}());
export default DefaultForm;
