import GalleryPageStore from 'store/modules/Pages/GalleryPage'
// import SidebarStore from 'store/modules/Sidebar/Sidebar'
import store from 'definitions/store'

const Init = {
    init(name) {
        // if (!store['sidebar']) {
        //     store.registerModule('sidebar', SidebarStore)
        // }

        if (!store[name]) {
            store.registerModule(name, GalleryPageStore)

            Init.initDefaultValues(name)
        }
    },
    initDefaultValues(name) {
        if (typeof pageTitle !== "undefined") {
            store.commit(`${name}/setPageTitle`, pageTitle)
        }

        if (typeof galleryAssets !== "undefined") {
            store.commit(`${name}/setGalleryPages`, galleryAssets)
        }

        if (typeof sections !== "undefined" && typeof activeItem !== "undefined") {
            store.commit(`${name}/setActivePage`, activeItem)
            store.commit(`${name}/setSections`, sections)
        }

        if (typeof noteSnippet !== 'undefined') {
            store.commit(`sidebar/setPleaseNoteTitle`, noteSnippet.title)
            store.commit(`sidebar/setPleaseNoteText`, noteSnippet.text)
        }
    }
}

export default Init
