import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import init from 'initializations/pages/ComplimentPage'
import SidebarInit from 'initializations/Sidebar'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
      SidebarInit.init("sidebar")
      init.init("complimentPage")

      const ComplimentPage = () => import(/* webpackChunkName: "compliment-page" */ 'containers/Pages/Compliment')
      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(ComplimentPage)
      })
    }
}
