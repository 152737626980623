import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import storeInit from 'initializations/pages/RewardClassUpgrade'
import SidebarInit from 'initializations/Sidebar'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        SidebarInit.init("sidebar")
        storeInit.init("rewardClassUpgrade")

        const RewardClassUpgrade = () => import(/* webpackChunkName: "reward-class-upgrade-page" */ 'containers/Pages/RewardClassUpgrade/RewardClassUpgrade')

        new Vue({
            el: "#" + this.elemId,
            store,
            render: h => h(RewardClassUpgrade)
        })
    }
}
