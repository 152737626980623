import RewardClassUpgrade from 'store/modules/Pages/RewardClassUpgrade'
import SearchFormStore from 'store/modules/eHorizon/SearchForm/SearchForm'
import OrigDestUtils from 'store/utils/eHorizonOrigDestUtils'
import OrigDest from 'utils/OrigDestData'
import store from 'definitions/store'
import getNearestAirport from 'utils/GetNearestAirport'

import CookieUtils from 'utils/CookieUtils'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule(name, RewardClassUpgrade)
            store.registerModule('eHorizonSearchForm', SearchFormStore)

            Init.initDefaultValues(name)
            Init.initOrigDestData(name)
        }
    },
    initDefaultValues(name) {
        store.commit('main/setFormNamespace', name, { root: true })
        if (typeof pageData !== "undefined") {
            store.commit(`${name}/setPageData`, pageData)
        }
    },

    initOrigDestData(name) {
        if (typeof origDestLabels !== 'undefined') {
            store.commit(`eHorizonSearchForm/destin/setLabels`, origDestLabels)
        }

        if (typeof origDestData !== 'undefined') {
            const data = OrigDest.parseData(origDestData)
            store.commit(`eHorizonSearchForm/setOrigDestData`, data)
            store.commit(`eHorizonSearchForm/origin/setDestinations`, OrigDestUtils.prepareData(data).filter(o => o.isOrig))
            const nearestAirport = getNearestAirport()
            if (nearestAirport && typeof data[nearestAirport] !== 'undefined') {
                store.dispatch(`eHorizonSearchForm/selectOrigin`, data[nearestAirport])
            }
        }

        const country = CookieUtils.getCookie('country') || null
        if (country) {
            store.dispatch(`eHorizonSearchForm/origin/setHomeCountry`, country)
        }
    },
}

export default Init
