import moment from 'moment'
import Payment from 'services/AirBaltic/Forms/Payment'

const Other = {
  namespaced: true,
  state: {
    checkedBaggageForm: {
      title: '',
      description: '',
      config: {
        minBaggageCount: 0,
        maxBaggageCount: 5,
        bagWeight: 23
      }
    },
    heavyBaggageForm: {
      title: '',
      description: ''
    },
    speqForm: {
      title: '',
      description: ''
    }
  },
  mutations: {
    setCheckedBaggageTitle(state, value) {
      state.checkedBaggageForm.title = value
    },
    setCheckedBaggageDescription(state, value) {
      state.checkedBaggageForm.description = value
    },
    setProtectedBaggageTitle(state, value) {
      state.checkedBaggageForm.protectedBaggageTitle = value
    },
    setProtectedBaggageDescription(state, value) {
      state.checkedBaggageForm.protectedBaggageDescription = value
    },
    setHeavyBaggageTitle(state, value) {
      state.heavyBaggageForm.title = value
    },
    setHeavyBaggageDescription(state, value) {
      state.heavyBaggageForm.description = value
    },
    setSpeqTitle(state, value) {
      state.speqForm.title = value
    },
    setSpeqDescription(state, value) {
      state.speqForm.description = value
    }
  },
  actions: {
    submitForm({ dispatch }, data) {
      return new Payment()
        .submitBaggageForm(data)
        .then((response) => {
          return dispatch('payment/generic/clearForms', data.form, { root: true })
            .then(() => Promise.resolve(response))
            .catch((err) => Promise.reject(err))
        })
        .catch((err) => Promise.reject(err))
    },
    getPassengerData({ rootState }, data) {
      const passengerData = {
        title: data.passenger.title,
        firstName: data.passenger.firstName,
        lastName: data.passenger.lastName
      }

      const bags = []

      if (data.form === 'checkedBaggageForm') {
        passengerData.protectedBaggage = data.passenger.protectedBaggage
      }

      if (data.segments.length > 0) {
        data.segments.map((segment) => {
          let formName = data.form.replace('Form', '')

          if (formName === 'heavyCabinBaggage') {
            formName = formName.replace('Cabin', '')
          }

          const $segment =
            rootState.payment.passengers[`${data.passengerNamespace}`][
              `${segment.namespace}`
            ][`${formName}`]

          if (typeof $segment !== 'undefined') {
            if ($segment.checked) {
              const obj = {
                flightNumber: `${segment.value.carrier}${segment.value.number}`,
                flightDate: moment(segment.value.departureDate).format('YYYY-MM-DD')
              }

              if (typeof $segment.type !== 'undefined' && $segment.type.length > 0) {
                obj.type = $segment.type
              }

              if (typeof $segment.count !== 'undefined' && $segment.count > 0) {
                obj.quantity = $segment.count
              }

              bags.push(obj)
            }
          }
        })
      }

      passengerData.baggagePieces = bags
      return passengerData
    }
  }
}

export default Other
