import AbstractView from 'abstract';
import Vue from 'vue'
import SnippetTemplateInit from 'initializations/pages/SnippetTemplate'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
      return super(domElementId);
    }

    init() {
      SnippetTemplateInit.init("snippetTemplate")

      const SnippetTemplate = () => import(/* webpackChunkName: "snippet-template" */ 'containers/Pages/SnippetTemplate')
      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(SnippetTemplate)
      })
    }
}
