<template>
  <div class="left-menu">
    <ul>
      <li
        v-for="(menuItem, index) in filteredMenu"
        :key="index"
        :class="{'current': menuItem.current}"
      >
        <a
          :href="menuItem.url"
          :target="menuItem.target ? menuItem.target : '_self'"
        >
          {{ menuItem.title }}
        </a>
      </li>
      <li v-if="isUserLogged && userData.clean && pageType === 'profile'">
        <a
          href="#"
          @click="logOut"
        >
          {{ $t('header.preHeader.userProfile.logout') }}
        </a>
      </li>
    </ul>
    <div
      v-if="pleaseNoteTitle && pleaseNoteTitle !== ''"
      class="block-important"
    >
      <span class="title">{{ pleaseNoteTitle }}</span>
      <p>{{ pleaseNoteText }}</p>
    </div>

    <press-contacts
      v-if="pressContacts.address"
      :press-contacts="pressContacts"
    />

    <cargo-shipping
      v-if="cargoShipping.title"
      :cargo-shipping="cargoShipping"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import PressContacts from 'components/Sidebar/PressContacts'
  import CargoShipping from 'components/Sidebar/CargoShipping'
  import PaxLogout from "services/AirBaltic/Pax/Logout"

  export default {
    components: {
      CargoShipping,
      PressContacts
    },
    computed: {
      ...mapState('sidebar', [
        'leftMenu',
        'pleaseNoteTitle',
        'pleaseNoteText',
        'pressContacts',
        'cargoShipping'
      ]),
      ...mapState('main', [
        'pageType',
      ]),
      ...mapState('main/user', [
        'userData',
        'isUserLogged'
      ]),
      filteredMenu () {
        if (this.isUserLogged) {
          const key = this.userData.clean ? ['profile-settings', 'pax-account-edit'] :  ['edit-profile']
          return this.leftMenu.filter((item) => !key.includes(item.key))
        } else {
          return this.leftMenu
        }
      }
    },
    methods: {
      callback () {
        this.$store.dispatch('main/user/clearUserData')
      },
      logOut () {
        new PaxLogout().logoutUser(this.callback)
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  .left-menu {
    float: left;
    vertical-align: top;
    padding-right: 60px;
    width: 34%;

    @include bp-desktop-small {
      padding-right: 35px;
    }

    @include bp-tablet {
      order: 2;
      padding-right: 0;
      width: 100%;
    }

    ul {
      margin-bottom: 24px;
      background-color: $gray-light-background;
      border-radius: 8px;
      list-style: none;

      @include bp-tablet {
        display: none;
      }
    }

    li {
      position: relative;

      &:not(:last-child) {

        &:after {
          content: '';
          position: relative;
          display: block;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $grey-border;
        }
      }

      &:first-child {
        clip-path: inset(0 round 8px 0 0 0);
      }

      &:last-child {
        clip-path: inset(0 round 0 0 0 8px);
      }

      &.current {
        border-left: 2px solid $green-base;

        a {
          color: $green-dark;
        }
      }
    }

    a {
      display: block;
      padding: 11px 25px;
      font-weight: 500;
      text-decoration: none;
      line-height: 16px;
      color: $grey-default;

      &:hover {
        color: $green-dark;
      }
    }
  }

  .block-important {
    padding: 23px 24px 28px;
    background-color: rgba(205, 218, 50, 0.05);

    @include bp-tablet-only {
      position: relative;
      margin-top: 0;
      padding: 35px 26px 35px 81px;
    }

    @include bp-tablet {
      position: relative;
    }

    @include bp-mobile {
      margin-top: 8px;
      padding: 25px 23px 28px 23px;
    }

    &:before {
      content: $icon-alert-thin;
      @include icon-font-thin();
      display: block;
      transform: rotate(180deg);
      font-size: em(32);
      font-weight: 500;
      text-align: center;
      color: $green-base;

      @include bp-tablet-only {
        position: absolute;
        top: 35px;
        left: 26px;
      }

      @include bp-mobile {
        display: none;
      }
    }

    .title {
      display: block;
      margin: 7px 0 10px;
      font-weight: 500;
      text-align: center;

      @include bp-tablet-only {
        margin: 0 0 9px;
      }

      @include bp-tablet {
        text-align: left;
      }

      @include bp-mobile {
        margin: 0 0 8px;
      }
    }

    p {
      margin: 0;
      font-size: em(12);
      line-height: 18px;
    }
  }
</style>
