import EmailChangeModel from 'models/Forms/EmailChange'
import FormValidationUtils from 'utils/FormValidation'
import EmailChangeService from 'services/AirBaltic/Pax/EmailChange'

const emailChange = Object.assign({}, new EmailChangeModel())

const defaultState = {
  form: {
    ...emailChange
  },
  formErrors: emailChange.errorMsg
}

const validator = FormValidationUtils.initValidation(defaultState, emailChange.validation)

const EmailChangeStore = {
  state: defaultState,
  mutations: {
    setEmail(state, value) {
      state.form.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setConfirmUrl(state, value) {
      state.form.confirmUrl = value
    },
    setForgotPasswordResetLink(state, value) {
      state.form.forgotPasswordResetLink = value
    },
    setEmailNotActivated(state, value) {
      state.form.emailNotActivated = value
    },
    setDefaultEmail(state, value) {
      state.form.defaultEmail = value
    },
    setNewEmail(state, value) {
      state.form.newEmail = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setEmailInUse(state, value) {
      state.form.emailInUse = value
    }
  },
  getters: {
    $emailChange() {
      return Object.assign({}, validator.$v.state.form)
    },
    formErrors(state) {
      return state.formErrors
    }
  },
  actions: {
    sendEmail({ state, rootState, commit }) {
      const data = {
        newEmail: state.form.email,
        language: rootState.main.selectedLang.toUpperCase(),
        confirmLink: state.form.confirmUrl,
        forgotPasswordResetLink: state.form.forgotPasswordResetLink
      }

      return new EmailChangeService()
        .sendEmail(data)
        .then(() => commit('setNewEmail', state.form.email))
    }
  },
  namespaced: true
}

export default EmailChangeStore
