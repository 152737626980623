import Api from 'services/AirBaltic/Api'

class SignUp {
  static sendEmail (data) {
    return new Api().post('/profile/new/sendEmail', data)
  }

  static confirmUser (token) {
    return new Api().post('/profile/new/confirm', token)
  }

  static sendCleanEmail (data) {
    return new Api().post('/profile/new/v2/sendEmail', data)
  }

  static confirmCleanUser (token) {
    return new Api().post('/profile/new/v2/confirm', token)
  }

  static validateToken (token) {
    return new Api().post('/profile/new/v2/validate', token)
  }
}

export default SignUp
