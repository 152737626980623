import CookieUtils from 'utils/CookieUtils'
import ScrollUtils from 'utils/scrollUtils'
import User from 'store/modules/User/User'
import Location from 'store/modules/Header/LocationForm'

const modalComponent = {
  content: {},
  isChanged: 0
}

const MainStore = {
  modules: {
    user: User,
    location: Location
  },
  state: {
    selectedLang: '',
    selectedCountry: '',
    apiResponded: false,
    userData: '',
    createProfileLink: '',
    hideCookieAlert: false,
    cookieAlertContent: '',
    gdprCookie: [],
    tempCookies: [],
    formNamespace: '',
    modal: {
      isModalOpened: false,
      modalType: '',
      modalContent: 0,
      modalTitle: '',
      modalSubTitle: '',
      modalClass: '',
      showCloseIcon: false,
      showModalHeader: true,
      showModalHeaderWhite: false,
      showModalHeaderWhiteNav: false,
      hideModalBackdropOnMobile: true,
      modalProps: {},
      modalEvents: {},
      modalCloseFn: () => {}
    },
    pageType: '',
    showPlaneLoader: false,
    showSmallLoader: false,
    notification: {
      isOpened: false,
      status: ''
    },
    apiUrl: '',
    registerPageUrl: '',
    accessibilityPageUrl: '',
    isLoginDisabled: false
  },
  mutations: {
    setGdprCookieValues(state, value) {
      state.gdprCookie = value
    },
    setTempCookies(state, value) {
      state.tempCookies = value
    },
    setFormNamespace(state, value) {
      state.formNamespace = value
    },
    selectCountry(state, value) {
      state.selectedCountry = value
    },
    selectLang(state, value) {
      state.selectedLang = value
    },
    setApiResponded(state, value) {
      state.apiResponded = value
    },
    setModalOpen(state, value) {
      state.modal.isModalOpened = value
    },
    setModalType(state, value) {
      state.modal.modalType = value
    },
    setModalTitle(state, value) {
      state.modal.modalTitle = value
    },
    setModalSubTitle(state, value) {
      state.modal.modalSubTitle = value
    },
    setModalProps(state, value) {
      state.modal.modalProps = value
    },
    setModalEvents(state, value) {
      state.modal.modalEvents = value
    },
    setModalContent(state, value) {
      modalComponent.component = value
      state.modal.modalContent++
    },
    setModalCloseFn(state, value) {
      state.modal.modalCloseFn = value
    },
    setCreateProfileLink(state, value) {
      state.createProfileLink = value
    },
    setHideCookieAlert(state, value) {
      state.hideCookieAlert = value
    },
    setCookieAlertContent(state, value) {
      state.cookieAlertContent = value
    },
    setPageType(state, value) {
      state.pageType = value
    },
    setShowCloseIcon(state, value) {
      state.modal.showCloseIcon = value
    },
    setModalClass(state, value) {
      state.modal.modalClass = value
    },
    setShowModalHeader(state, value) {
      state.modal.showModalHeader = value
    },
    setShowModalHeaderWhite(state, value) {
      state.modal.showModalHeaderWhite = value
    },
    setShowModalHeaderWhiteNav(state, value) {
      state.modal.showModalHeaderWhiteNav = value
    },
    setHideModalBackdropOnMobile(state, value) {
      state.modal.hideModalBackdropOnMobile = value
    },
    showPlaneLoader(state, value) {
      state.showPlaneLoader = value
    },
    setShowSmallLoader(state, value) {
      state.showSmallLoader = value
    },
    hideNotification(state) {
      state.notification.isOpened = false
    },
    showSuccessNotification(state) {
      state.notification.isOpened = true
      state.notification.status = 'success'
    },
    showErrorNotification(state) {
      state.notification.isOpened = true
      state.notification.status = 'error'
    },
    setAccessibilityPageUrl(state, value) {
      state.accessibilityPageUrl = value
    },
    setRegisterPageUrl(state, value) {
      state.registerPageUrl = value
    },
    setIsLoginDisabled(state, value) {
      state.isLoginDisabled = value
    }
  },
  getters: {
    modalContent(state) {
      modalComponent.isComponentChanged = state.modal.modalContent
      return modalComponent.component
    }
  },
  actions: {
    setGdprCookie({ commit, state }, value) {
      commit('setGdprCookieValues', value)

      const exDays = value.every((item) => item === 'fnc') ? 0 : 365
      CookieUtils.setCookie('gdpr_settings', state.gdprCookie, exDays, true, 'Lax')
      CookieUtils.setCookie('2e_send_GA', state.gdprCookie.includes('sts'), 365)
    },
    selectCountry({ commit }, value) {
      commit('selectCountry', value)
    },
    selectLang({ commit }, value) {
      commit('selectLang', value)
    },
    openFsfModal({ commit }) {
      ScrollUtils.disableScroll()
      commit('setModalOpen', true)
    },
    openModal({ commit, rootState }) {
      ScrollUtils.disableScroll()
      if (rootState.device.deviceType === 'desktop') {
        ScrollUtils.enableScroll()
      }
      commit('setModalOpen', true)
    },
    closeModal({ commit }) {
      commit('setModalTitle', '')
      commit('setModalContent', 0)
      commit('setShowModalHeader', true)
      commit('setShowModalHeaderWhite', false)
      commit('setShowModalHeaderWhiteNav', false)
      commit('setHideModalBackdropOnMobile', true)
      commit('setModalType', '')
      commit('setModalClass', '')
      commit('setShowCloseIcon', false)
      commit('setModalProps', {})
      commit('setModalOpen', false)
    },
    showSuccessNotification({ commit }) {
      commit('showSuccessNotification')
      setTimeout(() => {
        commit('hideNotification')
      }, 5000)
    }
  },

  namespaced: true
}

export default MainStore
