<template>
  <div class="disclaimers">
    <div class="block external-link-mark">
      <div class="link-icon" />
      <div v-dompurify-html="$t('footerWide.externalLinkText')" />
    </div>

    <div
      v-if="googlePrivacyPolicyText !== ''"
      class="block googlePrivacy"
    >
      <v-lazy-image
        :src="googleCapthaLogo"
        alt="Google Privacy Policy Logo"
        width="24"
        height="24"
      />
      <div v-dompurify-html="googlePrivacyPolicyText" />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import VLazyImage from 'v-lazy-image'

  export default {
    name: 'FooterDisclaimers',
    components: {
      VLazyImage
    },
    computed: {
      ...mapState('footer', ['googlePrivacyPolicyText']),
      googleCapthaLogo() {
        return require('assets/svg/captcha.svg')
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .disclaimers {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: $blue-dark;
    font-size: rem(12, 16);
    line-height: rem(20, 16);

    @include bp-mf-tablet {
      flex-direction: row;
      justify-content: space-between;
      gap: 66px;
    }

    @include bp-mf-desktop {
      max-width: 720px;
      gap: 88px;
    }
  }

  ::v-deep a {
    color: $blue-dark;

    &:focus {
      text-decoration: none;
      color: $blue-menu-hover;
    }
  }

  ::v-deep p {
    margin: 0;
  }

  .block {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .link-icon {
    width: 24px;
    height: 24px;

    &:before {
      content: $icon-external-link-facelift;
      @include icon-font-thin();
      font-size: rem(24, 16);
      display: flex;
      align-items: center;
    }
  }
</style>
