import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import mealsPageInit from 'initializations/pages/MealsPage'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
      mealsPageInit.init("mealsPage")

      const MealsPage = () => import(/* webpackChunkName: "meals-page" */ 'containers/Pages/Meals')
      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(MealsPage)
      })
    }
}
