import find from 'lodash-es/find'
import findIndex from 'lodash-es/findIndex'
import cloneDeep from 'lodash-es/cloneDeep'

const defaultState = {
  requestTitle: '',
  types: [],
  passengers: [
    {
      index: 1,
      namespace: 'passenger_1'
    }
  ],
  segments: [
    {
      index: 0,
      namespace: 'segment_0'
    }
  ]
}

const Passengers = {
  namespaced: true,
  state: defaultState,
  actions: {
    setPassengersFormData({ commit }, data) {
      commit('setTypes', data.types)
      commit('payment/other/setProtectedBaggageTitle', data.protectedBaggageTitle, {
        root: true
      })
      commit(
        'payment/other/setProtectedBaggageDescription',
        data.protectedBaggageDescription,
        { root: true }
      )
    },
    validatePassengers({ dispatch, state }) {
      const actions = []

      state.passengers.forEach(({ namespace }) => {
        actions.push(dispatch(`${namespace}/validatePassenger`))
        if (state[namespace] && state[namespace].speqList){
          state[namespace].speqList.forEach(speq => {
            actions.push(dispatch(`${namespace}/${speq.namespace}/validateSpeq`))
          })
        }

        state.segments.forEach((segment) => {
          actions.push(dispatch(`${namespace}/validatePassengerSegment`, segment))
        })
      })

      return Promise.all(actions)
        .then(() => Promise.resolve(true))
        .catch(() => Promise.reject('validatePassengers'))
    },
    clearPassengers({ state, commit, dispatch }) {
      dispatch('payment/segments/clearSegments', state.passengers, { root: true })
      cloneDeep(state.passengers).forEach((passenger) => {
        if (passenger.index !== 1) {
          commit('removePassenger', passenger.index)
        } else {
          dispatch('passenger_1/clearPassenger')
        }
      })

      commit('clearPassengers')
    }
  },
  mutations: {
    addPassenger(state) {
      const lastPassenger = state.passengers.slice(-1)[0]
      const newIndex = lastPassenger.index + 1
      state.passengers.push({
        index: newIndex,
        namespace: `passenger_${newIndex}`
      })
    },
    removePassenger(state, index) {
      const found = find(state.passengers, ['index', index])
      const passengerIndex = findIndex(state.passengers, ['index', index])
      state.passengers.splice(passengerIndex, 1)

      if (typeof found !== 'undefined' && state[found.namespace]) {
        this.unregisterModule(['payment', 'passengers', found.namespace])
      }
    },
    clearPassengers(state) {
      state.passengers = [
        {
          index: 1,
          namespace: 'passenger_1'
        }
      ]
    },
    setTypes(state, value) {
      state.types = value
    },
    setSegments(state, value) {
      state.segments = value
    }
  }
}

export default Passengers
