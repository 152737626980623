<template>
  <div class="footer-social-wrapper">
    <ul class="footer-social">
      <li
        v-for="(item, index) in social"
        :key="index"
        :class="item"
      >
        <a
          :aria-label="`airBaltic ${item} page`"
          :href="$t(`footerWide.social.${item}`)"
          target="_blank"
        />
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'FooterSocial',
    props: {
      social: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .footer-social-wrapper {
    width: 100%;
    margin: auto auto 24px;
    border-bottom: 1px solid $gray-border;

    @include bp-mf-desktop {
      border: none;
      width: auto;
      min-width: unset;
      margin: auto 0 auto 24px;
    }
  }

  .footer-social {
    display: flex;
    margin: auto;
    justify-content: space-around;
    max-width: 355px;
    padding: 16px 0;

    @include bp-mf-desktop {
      margin: 0;
    }

    li {
      display: inline-block;
      vertical-align: top;
      margin-right: 5%;

      @include bp-mf-tablet {
        margin-right: 24px;
      }

      &:last-child {
        margin: 0;
      }

      &.facebook a:before {
        content: $icon-facebook-facelift;
        color: white;
      }

      &.twitter a:before {
        content: $icon-twitter-facelift;
        color: white;
      }

      &.instagram a:before {
        content: $icon-instagram-facelift;
        color: white;
      }

      &.youtube a:before {
        content: $icon-youtube-facelift;
        color: white;
      }

      &.tiktok a:before {
        content: $icon-tiktok-facelift;
        color: white;
      }

      &.linkedin a:before {
        content: $icon-linkedin-facelift;
        color: white;
      }
    }

    a {
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      text-decoration: none;

      &:before {
        @include icon-font();
        font-size: em(24);
      }
    }
  }
</style>
