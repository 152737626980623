import { alphaWithSpaces } from 'utils/validators';
import { required } from 'vuelidate/lib/validators';
var id = 0;
var Passenger = /** @class */ (function () {
    function Passenger(passenger) {
        this.firstName = '';
        this.lastName = '';
        this.id = id;
        this.selected = false;
        this.errorMsg = {
            firstName: {
                alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
                required: 'reusable.firstName.validation.required',
            },
            lastName: {
                alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
                required: 'reusable.lastName.validation.required',
            },
            selectedPassengers: {
                checked: 'claims.pax.validation.isTrue'
            }
        };
        this.validation = {
            passengers: {
                $each: {
                    firstName: {
                        alphaWithSpaces: alphaWithSpaces,
                        required: required,
                    },
                    lastName: {
                        alphaWithSpaces: alphaWithSpaces,
                        required: required,
                    },
                },
            },
            selectedPassengers: {
                checked: function (value) { return value.length; }
            }
        };
        if (typeof passenger !== 'undefined') {
            this.parseName(passenger);
        }
        id++;
    }
    Passenger.prototype.parseName = function (data) {
        if (typeof data.givenName === 'undefined' ||
            typeof data.surname === 'undefined') {
            return;
        }
        if (data.selected) {
            this.selected = true;
        }
        var firstName = data.givenName;
        var spaceCount = (firstName.trim().match(/ /g) || []).length;
        if (spaceCount >= 1) {
            var titles = ['MR', 'MS', 'MRS', 'DR'];
            var lastSpaceLocation = firstName.lastIndexOf(' ');
            var title = firstName.slice((lastSpaceLocation + 1), firstName.length);
            if (titles.includes(title.toUpperCase())) {
                firstName = firstName.slice(0, lastSpaceLocation);
            }
        }
        this.firstName = firstName;
        this.lastName = data.surname;
    };
    return Passenger;
}());
export default Passenger;
