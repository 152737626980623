import Api from 'services/AirBaltic/Api'

class Location {
  getCountryList() {
    return new Api()
      .get(`/data/storyblok/countries/${lang}/list`)
      .then(({ data }) => Promise.resolve(data))
      .catch((e) => Promise.reject(e))
  }

  getCountriesMap() {
    return new Api()
      .get(`/data/storyblok/countries/${lang}/map`)
      .then(({ data }) => Promise.resolve(data))
      .catch((e) => Promise.reject(e))
  }

  getLanguageList() {
    return new Api()
      .get('/data/languages')
      .then(({ data }) => Promise.resolve(data))
      .catch((e) => Promise.reject(e))
  }

  getContentCountryList(lang) {
    return new Api()
      .get(`/data/storyblok/countries/${lang}/data.json`)
      .then(({ data }) => Promise.resolve(data))
      .catch((e) => Promise.reject(e))
  }
}

export default Location
