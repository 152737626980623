import get from 'lodash-es/get'
import ErrorHandler from 'utils/ErrorHandler'
import AuthorizedApi from 'services/AirBaltic/AuthorizedApi'
import Api from 'services/AirBaltic/Api'

const ERRORS = {
  PINS_UNKNOWN: 'pins.errors.pinsGeneric',
  WRONG_USERNAME_OR_PASSWORD: 'pins.errors.wrongUsernameOrPassword',
  USER_NAME_IN_BLACK_LIST: 'pins.errors.usernameInBlacklist',
  PASSWORD_SIZE: 'pins.errors.passwordSize',
  PASSWORD_NOTNULL: 'generic.errors.fieldEmpty',
  USERNAME_SIZE: 'pins.errors.usernameSize',
  USERNAME_NOTNULL: 'generic.errors.fieldEmpty',
  PINS_EMPTY: 'pins.errors.pinsNumberNotSet',
  PINS_NUMBER_EXIST: 'pins.errors.pinsNumberExists',
  PINS_NOT_FOUND: 'pins.errors.notFound'
}

class Pins {
  getPinsData(number) {
    if (!number) {
      return Promise.reject(this.getErrors({ status: 400, data: { code: 'PINS_EMPTY' } }))
    }

    return new AuthorizedApi()
      .get('/pins/' + number)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(this.getErrors(error.response)))
  }

  connectPins(data) {
    return new AuthorizedApi()
      .post('/profile/pins/login', data)
      .then(({ data }) => {
        const refreshToken = data.refreshToken
        window.localStorage.setItem('refreshToken', refreshToken)
        const number = get(data, 'userData.pinsNumber', null)

        return this.getPinsData(number)
          .then((resData) => {
            const res = data
            res.data = resData
            return Promise.resolve(res)
          })
          .catch((error) => Promise.reject(error))
      })
      .catch((error) => Promise.reject(this.getErrors(error.response)))
  }

  getDataFromLogin(data) {
    return new Api()
      .post('/pins/data/login', data)
      .then((response) => Promise.resolve(response.data))
      .catch((error) =>
        Promise.reject(ErrorHandler.getErrorTranslationKey(ERRORS, error.response))
      )
  }

  disconnectPins() {
    return new AuthorizedApi()
      .delete('/pins/disconnect')
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(this.getErrors(error.response)))
  }

  getErrors(response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default Pins
