import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import storeInit from 'initializations/pages/Outlook'
import SidebarInit from 'initializations/Sidebar'

export class Main extends AbstractView {
  constructor(domElementId) {
    return super(domElementId);
  }

  init() {
    storeInit.init("outlook")
    SidebarInit.init("sidebar")

    const Outlook = () => import(/* webpackChunkName: "outlook-page" */ 'containers/Pages/Outlook/Outlook')

    new Vue({
      el: "#" + this.elemId,
      store,
      render: h => h(Outlook)
    })
  }
}
