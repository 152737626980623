import cloneDeep from 'lodash-es/cloneDeep';
import { alphaWithSpaces, alphaNumWithSpaces, isValidIban, isValidBicSwift, betweenLength, isValidAccountNumber } from 'utils/validators';
import { required, numeric, or } from 'vuelidate/lib/validators';
var Bank = /** @class */ (function () {
    function Bank(currency) {
        this.fields = {};
        this.validations = {};
        this.bankTransferValidations = {
            accHolderName: {
                required: required,
                alphaWithSpaces: alphaWithSpaces
            },
            accNumber: {
                required: required,
                isValidNumberOrIban: or(isValidIban, isValidAccountNumber)
            },
            bankName: {
                required: required,
                alphaWithSpaces: alphaWithSpaces
            },
            address: {
                required: required,
                alphaNumWithSpaces: alphaNumWithSpaces
            },
            bicSwift: {
                required: required,
                isValidBicSwift: isValidBicSwift
            },
            currency: {
                required: required
            },
            bankNameCorresponded: {
                required: required,
                alphaWithSpaces: alphaWithSpaces
            },
            bankSwiftCorresponded: {
                required: required,
                isValidBicSwift: isValidBicSwift
            }
        };
        this.bankTransferEUValidations = {
            accHolderName: {
                required: required,
                alphaWithSpaces: alphaWithSpaces
            },
            accNumber: {
                isValidIban: isValidIban,
                requiredIban: required
            }
        };
        this.bankTransferUkraineValidations = {
            accHolderName: {
                required: required,
                alphaWithSpaces: alphaWithSpaces
            },
            accNumber: {
                required: required,
                isValidNumberOrIban: or(isValidIban, isValidAccountNumber)
            },
            address: {
                required: required,
                alphaWithSpaces: alphaWithSpaces
            },
            bankName: {
                required: required,
                alphaWithSpaces: alphaWithSpaces
            },
            currency: {
                required: required
            },
            swift: {
                required: required,
                isValidBicSwift: isValidBicSwift
            },
            bankNameCorresponded: {
                required: required,
                alphaWithSpaces: alphaWithSpaces
            },
            bankSwiftCorresponded: {
                required: required,
                isValidBicSwift: isValidBicSwift
            }
        };
        this.bankTransferUSAValidations = {
            accHolderName: {
                required: required,
                alphaWithSpaces: alphaWithSpaces
            },
            accNumber: {
                requiredAccNumber: required,
                between: betweenLength(5, 17),
                numeric: numeric
            },
            address: {
                required: required,
                alphaNumWithSpaces: alphaNumWithSpaces
            },
            bankName: {
                required: required,
                alphaWithSpaces: alphaWithSpaces
            },
            currency: {
                required: required
            },
            routingNumber: {
                required: required
            },
            swift: {
                required: required,
                isValidBicSwift: isValidBicSwift
            },
            bankNameCorresponded: {
                required: required,
                alphaWithSpaces: alphaWithSpaces
            },
            bankSwiftCorresponded: {
                required: required,
                isValidBicSwift: isValidBicSwift
            }
        };
        this.bankTransferRussiaValidations = {
            accHolderName: {
                required: required,
                alphaWithSpaces: alphaWithSpaces
            },
            accNumber: {
                required: required
            },
            accNumberCorresponded: {
                required: required
            },
            address: {
                required: required
            },
            bankName: {
                required: required
            },
            bankNameCorresponded: {
                required: required
            },
            bik: {
                required: required
            },
            currency: {
                required: required
            },
            innCode: {
                required: required
            },
            kppCode: {
                required: required
            },
            swift: {
                required: required
            }
        };
        this.bankTransfer = {
            accHolderName: '',
            accNumber: '',
            bankName: '',
            address: '',
            bicSwift: '',
            currency: '',
            bankNameCorresponded: '',
            bankSwiftCorresponded: ''
        };
        this.bankTransferEU = {
            accHolderName: '',
            accNumber: '',
            currency: 'EUR'
        };
        this.bankTransferUkraine = {
            accHolderName: '',
            accNumber: '',
            address: '',
            bankName: '',
            currency: '',
            swift: '',
            bankNameCorresponded: '',
            bankSwiftCorresponded: ''
        };
        this.bankTransferUSA = {
            accHolderName: '',
            accNumber: '',
            address: '',
            bankName: '',
            currency: '',
            routingNumber: '',
            swift: '',
            bankNameCorresponded: '',
            bankSwiftCorresponded: ''
        };
        this.bankTransferRussia = {
            accHolderName: '',
            accNumber: '',
            accNumberCorresponded: '',
            address: '',
            bankName: '',
            bankNameCorresponded: '',
            bik: '',
            currency: '',
            innCode: '',
            kppCode: '',
            swift: ''
        };
        this.errorMsg = {
            accHolderName: {
                required: 'reusable.bankInfo.accHolderName.validation.missing',
                alphaWithSpaces: 'reusable.bankInfo.generic.validation.alphaWithSpaces'
            },
            accNumber: {
                between: 'reusable.bankInfo.accNumber.validation.between',
                numeric: 'reusable.bankInfo.accNumber.validation.onlyNumbers',
                isValidIban: 'reusable.bankInfo.accIban.validation.invalid',
                required: 'reusable.bankInfo.accNumberOrIban.validation.missing',
                requiredIban: 'reusable.bankInfo.accIban.validation.missing',
                requiredAccNumber: 'reusable.bankInfo.accNumber.validation.missing',
                isValidNumberOrIban: 'reusable.bankInfo.accNumberOrIban.validation.invalid'
            },
            accNumberCorresponded: {
                required: 'reusable.bankInfo.accNumberCorresponded.validation.missing'
            },
            address: {
                required: 'reusable.bankInfo.address.validation.missing',
                alphaWithSpaces: 'reusable.bankInfo.generic.validation.alphaWithSpaces',
                alphaNumWithSpaces: 'reusable.bankInfo.generic.validation.alphaNumWithSpaces'
            },
            bankName: {
                required: 'reusable.bankInfo.bankName.validation.missing',
                alphaWithSpaces: 'reusable.bankInfo.generic.validation.alphaWithSpaces'
            },
            bankNameCorresponded: {
                required: 'reusable.bankInfo.bankNameCorresponded.validation.missing',
                alphaWithSpaces: 'reusable.bankInfo.generic.validation.alphaWithSpaces'
            },
            bankSwiftCorresponded: {
                required: 'reusable.bankInfo.bankSwiftCorresponded.validation.missing',
                isValidBicSwift: 'reusable.bankInfo.bankSwiftCorresponded.validation.incorrect'
            },
            bicSwift: {
                required: 'reusable.bankInfo.bicSwift.validation.missing',
                isValidBicSwift: 'reusable.bankInfo.bicSwift.validation.incorrect'
            },
            bik: {
                required: 'reusable.bankInfo.bik.validation.missing'
            },
            currency: {
                required: 'reusable.bankInfo.currency.validation.missing'
            },
            innCode: {
                required: 'reusable.bankInfo.innCode.validation.missing'
            },
            kppCode: {
                required: 'reusable.bankInfo.kppCode.validation.missing'
            },
            paymentMethod: {
                required: 'reusable.bankInfo.paymentMethod.validation.missing'
            },
            reimbursement: {
                required: 'reusable.bankInfo.reimbursement.validation.missing'
            },
            routingNumber: {
                alphaNumWithSpaces: 'reusable.bankInfo.routingNumber.validation.alphaNumWithSpaces',
                required: 'reusable.bankInfo.routingNumber.validation.missing'
            },
            swift: {
                required: 'reusable.bankInfo.swift.validation.missing',
                isValidBicSwift: 'reusable.bankInfo.swift.validation.incorrect'
            },
            totalAmount: {
                decimal: 'reusable.bankInfo.totalAmount.validation.numeric',
                required: 'reusable.bankInfo.totalAmount.validation.missing',
                maxValue: 'reusable.bankInfo.totalAmount.validation.maxValue'
            },
            reimbursementTypes: {
                isTrueAtLeastOne: 'reusable.bankInfo.reimbursement.checkbox.validation.missing'
            }
        };
        this._globalCurrency = '';
        if (typeof currency !== 'undefined') {
            this.globalCurrency = currency;
        }
    }
    Bank.prototype.getFields = function (type) {
        var filtered = cloneDeep(this.fields);
        switch (type) {
            case 'bankTransfer': {
                filtered = this.bankTransfer;
                break;
            }
            case 'bankTransferEU': {
                filtered = this.bankTransferEU;
                break;
            }
            case 'bankTransferRussia': {
                filtered = this.bankTransferRussia;
                break;
            }
            case 'bankTransferUkraine': {
                filtered = this.bankTransferUkraine;
                break;
            }
            case 'bankTransferUSA': {
                filtered = this.bankTransferUSA;
                break;
            }
        }
        return filtered;
    };
    Bank.prototype.getValidations = function (type) {
        var validations = cloneDeep(this.validations);
        switch (type) {
            case 'bankTransfer': {
                validations = this.bankTransferValidations;
                break;
            }
            case 'bankTransferEU': {
                validations = this.bankTransferEUValidations;
                break;
            }
            case 'bankTransferRussia': {
                validations = this.bankTransferRussiaValidations;
                break;
            }
            case 'bankTransferUkraine': {
                validations = this.bankTransferUkraineValidations;
                break;
            }
            case 'bankTransferUSA': {
                validations = this.bankTransferUSAValidations;
                break;
            }
        }
        return validations;
    };
    Object.defineProperty(Bank.prototype, "globalCurrency", {
        get: function () {
            return this._globalCurrency;
        },
        set: function (value) {
            this._globalCurrency = value;
        },
        enumerable: false,
        configurable: true
    });
    return Bank;
}());
export default Bank;
