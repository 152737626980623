const AirbusPage = {
  state: {
    pageData: {
      pageTitle: '',
      subtitle: '',
      description: '',
      searchDescription: '',
      backButton: '',
      homeButton: '',
      discoverDescription: ''
    },
    discoverNews: []
  },

  mutations: {
    setPageData (state, value) {
      state.pageData = value
    },
    setDiscoverNews (state, value) {
      state.discoverNews = value
    }
  },

  namespaced: true
}

export default AirbusPage
