import OrigDestUtils from 'store/utils/OrigDestUtils'
import values from 'lodash-es/values'
import isEmpty from 'lodash-es/isEmpty'
import flatten from 'lodash-es/flatten'

class eHorizonOrigDestUtils extends OrigDestUtils {

  static toLabel (data) {
    let city = ''

    if (data.city !== null && typeof data.city !== "undefined") {
      city = data.city + ' '
    }

    return (data && data.code) ? city + '(' + data.code + ')' : ''
  }

  static toFSFLabel (data) {
    let city = ''

    if (data.city !== null && typeof data.city !== "undefined") {
      city = data.city + ' '
    }

    return (data && data.code) ? '<b>' + city + '</b> ' + data.code : ''
  }

  static toCity (data) {
    let airport = ''

    if (data.apt !== null && typeof data.apt !== "undefined") {
      airport = data.apt + ', '
    }
    return (data && data.code) ? airport + data.country : ''
  }

  static prepareData (data) {
    return values(data)
      .map((o) => {
        o.aptWithCode = eHorizonOrigDestUtils.toLabel(o)
        return o
      })
  }

  static sortByCity (airports) {
    function cityCompare (a, b) {
      if (typeof a.city !== 'undefined') {
        return a.city.localeCompare(b.city)
      }
      return 0
    }

    return airports.sort(cityCompare)
  }

  static getAvailableLabels (airports) {
    const found = airports.filter((elem) => {
      return !isEmpty(elem.labels)
    })

    const labels = flatten(found.map(item => item.labels))

    return labels.filter((item, index) => {
      return labels.indexOf(item) === index
    })
  }

  static addHeaderToAirportList (header, airports) {
    if (airports.length > 0) {
      airports.unshift(header)

      return airports
    }

    return []
  }

  static filterData (constraint, data, ignoreConstraint) {
    function aptCompare (a, b) {
      if (typeof a.apt !== 'undefined' && (a.apt !== null && b.apt !== null)) {
        return a.apt.localeCompare(b.apt)
      }
      return 0
    }

    if (!constraint || ignoreConstraint) {
      return data.slice().sort(aptCompare)
    }

    function escapeRegExp (str) {
      return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
    }

    const matchesCity = []
    const matchesAirport = []
    const matchesCountry = []
    const matchesCode = []
    const matchesCityCode = []
    const matchesAptWithCode = []
    const dividers = "^| |[(]|/|-|'|`"
    const substrRegex = new RegExp(`(${dividers})${escapeRegExp(constraint)}`, 'i')

    data.forEach((dest) => {
      if (substrRegex.test(dest.code)) {
        matchesCode.push(dest)
      } else if (substrRegex.test(dest.city)) {
        matchesCity.push(dest)
      } else if (substrRegex.test(dest.cityCode)) {
        matchesCityCode.push(dest)
      } else if (substrRegex.test(dest.apt)) {
        matchesAirport.push(dest)
      } else if (substrRegex.test(dest.aptWithCode)) {
        matchesAptWithCode.push(dest)
      } else if (substrRegex.test(dest.country)) {
        matchesCountry.push(dest)
      }
    })

    matchesCode.sort(aptCompare)
    matchesCity.sort(aptCompare)
    matchesCityCode.sort(aptCompare)
    matchesAirport.sort(aptCompare)
    matchesAptWithCode.sort(aptCompare)
    matchesCountry.sort(aptCompare)

    return matchesCode.concat(
      matchesCity,
      matchesCityCode,
      matchesAirport,
      matchesAptWithCode,
      matchesCountry
    )
  }
}

export default eHorizonOrigDestUtils
