import Api from 'services/AirBaltic/Api'
import ErrorHandler from 'utils/ErrorHandler'

const ERRORS = {}

class GroupBooking {
  submitForm (data) {
    return new Api().post('/contact/service/v2/groupBooking', data)
      .then(() => Promise.resolve())
      .catch(error => Promise.reject(this.getErrors(error.response)))
  }

  submitAgencyForm (data) {
    return new Api().post('/contact/service/v2/groupBooking/travelAgency', data)
      .then(() => Promise.resolve())
      .catch(error => Promise.reject(this.getErrors(error.response)))
  }

  getErrors (response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default GroupBooking
