import Api from 'services/AirBaltic/Api'
import ErrorHandler from 'utils/ErrorHandler'
import axios from 'axios'
import conf from 'conf'

const ERRORS = {
  GENERIC: 'generic.errors.serverError',
  FLIGHTDATE_DATEAFTERTODAYTYPE: 'generic.errors.dateAfterToday',
  BAD_FILE_TYPE: 'reusable.fileUpload.wrongFileType',
  REQUEST_ENTITY_TOO_LARGE: 'reusable.fileUpload.requestTooLarge'
}

class Assistance {
  submitGuidanceForm(data, files) {
    const api = '/contact/service/multipart/v2/assistance/guidance'
    return this.submitWithFiles(data, files, api)
  }

  submitOwnWheelChairForm(data, files) {
    const api = '/contact/service/multipart/v2/assistance/wheelchairOwn'
    return this.submitWithFiles(data, files, api)
  }

  submitAirportWheelChairForm(data, files) {
    const api = '/contact/service/multipart/v2/assistance/wheelchairAirport'
    return this.submitWithFiles(data, files, api)
  }

  submitOtherForm(data, files) {
    const api = '/contact/service/multipart/v2/assistance/other'
    return this.submitWithFiles(data, files, api)
  }

  submitWithFiles(data, files, api) {
    const formData = new FormData()

    files.forEach((file) => {
      formData.append('files', file, file.name)
    })

    data = JSON.stringify(data)

    const form = new Blob([data], {
      type: 'application/json'
    })

    formData.append('request', form)

    return axios
      .post(
        conf.btApiUrl + api,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(this.getErrors(error.response)))
  }

  getErrors(response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default Assistance
