<template>
  <div>
    <e-horizon-index-tabs/>
    <promo-bar
      v-if="showBanner"
      :promoBanner="promoBanner"
      @reloadBanner="showPromoBanner()"
    />
  </div>
</template>

<script>
  import CookieUtils from 'utils/CookieUtils'
  import eHorizonIndexTabs from 'containers/eHorizon/IndexTabs/SearchWrap'
  import PromoBar from 'components/eHorizon/PromoBar'

  export default {
    components: {
      eHorizonIndexTabs,
      PromoBar
    },
    data () {
      return {
        promoBanner: {},
        showBanner: false
      }
    },
    methods: {
      getPromoBanner () {
        if (typeof eHorizonPromoBar !== "undefined") {
          this.promoBanner = eHorizonPromoBar
          this.showBanner = this.showPromoBanner() && Object.keys(eHorizonPromoBar).length > 0
        }
      },
      showPromoBanner () {
        this.showBanner = CookieUtils.getCookie('banner-bar') !== 'false'
        return this.showBanner
      }
    },
    created () {
      this.getPromoBanner()
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss">
  @import "~assets/styles/main.scss";
</style>
