import findIndex from 'lodash-es/findIndex'
import some from 'lodash-es/some'
import cloneDeep from 'lodash-es/cloneDeep'
import Vue from 'vue'
import { validationMixin } from 'vuelidate';
import { alphaNum } from 'utils/validators'

const errorMsg = {
  code: {
    alphaNum: 'searchForm.promoCode.validation.code.alphaNumEHorizon'
  }
}

const defaultState = {
  showPromoField: false,
  dropdown: {
    open: false
  },
  promoCodes: [],
  validationErrors: [],
  formErrors: errorMsg,
  invalid: false
}

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations () {
    const rules = {
      promoCodes: {
        $each: {
          code: {
            alphaNum
          }
        }
      }
    }

    return { state: rules }
  }
})

const PromoCodes = {
  state: defaultState,
  getters: {
    $v () {
      return Object.assign({}, validator.$v)
    },
    validationErrors (state) {
      return state.validationErrors
    }
  },
  actions: {
    toggleBlock ({ commit, state }, value) {
      commit('showPromoField', value)
      commit('showDropdown', value)

      if (state.promoCodes.length === 0 && value) {
        commit('add')
      }
    },
    validateForm ({ commit, state }) {
      validator.$v.state.$touch()
      if (state.showPromoField) {
        const hasError = some(state.promoCodes, (o) => o.error.length > 0)
        commit('setInvalid', validator.$v.state.promoCodes.$invalid || hasError)
      } else {
        commit('setInvalid', false)
      }
    },
    removeInvalidPromoCodes ({ commit, state }) {
      if (!state.invalid) return

      commit('setPromoCodes', state.promoCodes.filter(code => !(code.error && code.error.length > 0)))
    }
  },
  mutations: {
    showPromoField (state, value) {
      state.showPromoField = value
    },
    showDropdown (state, value) {
      state.dropdown.open = value
    },
    add (state) {
      let lastIndex = null
      if (state.promoCodes.length !== 0) {
        lastIndex = state.promoCodes.slice(-1)[0]
      } else {
        lastIndex = { index: -1 }
      }

      const newIndex = lastIndex.index + 1
      state.promoCodes.push({ index: newIndex, code: '', error: '' })
    },
    update (state, payload) {
      const foundIndex = findIndex(state.promoCodes, ['index', payload.index])

      if (foundIndex > -1) {
        const updateValue = cloneDeep(state.promoCodes[`${foundIndex}`])

        updateValue.code = payload.code.replace(/\s+/g, '')
        updateValue.error = payload.error

        state.promoCodes.splice(foundIndex, 1, updateValue)
      }
    },
    remove (state, index) {
      const foundIndex = findIndex(state.promoCodes, ['index', index]);
      if (foundIndex !== -1) {
        state.promoCodes.splice(foundIndex, 1)
      }
    },
    setPromoCodes (state, value) {
      state.promoCodes = value
    },
    setInvalid (state, value) {
      state.invalid = value
    }
  },
  namespaced: true
}

export default PromoCodes
