const Footer = {
  state: {
    footerLinks: [],
    storyblokFooterLinks: {
      items: [],
      contactUs: {},
      legalLinks: []
    },
    footerOld: {},
    showScrollButton: true,
    googlePrivacyPolicyText: '',
    subscribePopupData: {},
    subscribePopupDisabled: false,
    footerSemantics: [],
    awards: [],
    copyright: '',
    social: [],
    paymentLogos: []
  },
  mutations: {
    setFooterLinks(state, value) {
      state.footerLinks = value
    },
    setFooterItems(state, value) {
      state.storyblokFooterLinks.items = value
    },
    setFooterContactUs(state, value) {
      state.storyblokFooterLinks.contactUs = value
    },
    setFooterLegalLinks(state, value) {
      state.storyblokFooterLinks.legalLinks = value
    },
    setGooglePrivacyPolicyText(state, value) {
      state.googlePrivacyPolicyText = value
    },
    setSubscribePopupData(state, value) {
      state.subscribePopupData = value
    },
    setFooterOldData(state, value) {
      state.footerOld = value
    },
    setShowScrollButton(state, value) {
      state.showScrollButton = value
    },
    setDisableSubscribePopup(state, value) {
      state.subscribePopupDisabled = value
    },
    setSemantics(state, value) {
      state.footerSemantics = value
    },
    setAwards(state, value) {
      state.awards = value
    },
    setCopyright(state, value) {
      state.copyright = value
    },
    setSocial(state, value) {
      state.social = value
    },
    setPaymentLogos(state, value) {
      state.paymentLogos = value
    }
  },
  namespaced: true
}

export default Footer
