import ProfileSettingsStore from 'store/modules/Pages/ProfilePages/ProfileSettings'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, ProfileSettingsStore)

      Init.initPageData(name)
      Init.initPaxDetails(name)
      Init.initSidebarData()
      Init.initPageType()
    }
  },

  initPageData(name) {
    if (typeof pageData !== 'undefined') {
      if (typeof pageData.delete !== 'undefined') {
        store.commit(
          `${name}/setModalDeleteProfileTitle`,
          pageData.delete.deleteModalTitle
        )
        store.commit(`${name}/setModalDeleteProfileText`, pageData.delete.deleteModalText)
        store.commit(
          `${name}/setModalDeleteProfileDisclaimer`,
          pageData.delete.deleteModalDisclaimer
        )
      }

      store.commit(`${name}/setPageData`, pageData)
    }

    if (typeof countries !== 'undefined') {
      store.commit(`${name}/setCountries`, countries)
    }

    if (typeof validLanguages !== 'undefined') {
      store.commit(`${name}/setLanguages`, validLanguages)
    }
  },

  initSidebarData() {
    if (typeof pressContacts !== 'undefined') {
      store.commit('sidebar/setPressContacts', pressContacts)
    }
  },

  initPaxDetails(name) {
    if (typeof userData !== 'undefined') {
      store.commit(`${name}/setPreferredLanguage`, userData.preferredLanguage)
      store.commit(`${name}/emailChange/setEmail`, userData.email)
      store.commit(`${name}/emailChange/setDefaultEmail`, userData.email)
      store.commit(`${name}/emailChange/setNewEmail`, userData.newEmail)
      store.commit(`${name}/emailChange/setEmailNotActivated`, userData.emailNotActivated)
      store.commit(`${name}/setSubscribeEmail`, userData.specialDealsEmail)
      store.commit(`${name}/setSubscribeSms`, userData.specialDealsSms)
      store.commit(`${name}/setHomeCountry`, userData.homeCountry)
      store.dispatch(`${name}/setPhone`, userData.phoneNumber.number)
      store.commit(`${name}/setHasPassword`, userData.hasPassword)

      if (userData.phoneNumber.countryCode !== null) {
        store.dispatch(`${name}/setIntCode`, userData.phoneNumber.countryCode)
      }
    }

    if (typeof confirmUrl !== 'undefined') {
      store.commit(`${name}/emailChange/setConfirmUrl`, confirmUrl)
    }

    if (typeof forgotPasswordResetLink !== 'undefined') {
      store.commit(
        `${name}/emailChange/setForgotPasswordResetLink`,
        forgotPasswordResetLink
      )
    }
  },

  initPageType() {
    store.commit('main/setPageType', 'profile')
  }
}

export default Init
