<template>
  <div>
    <form
      ref="pinsLoginForm"
      class="pins-login-form"
      @keyup.enter="submitLoginForm"
    >
      <img
        src="~assets/images/logos/airBaltic-club-logo.svg"
        alt="airBaltic Club logo"
      />
      <form-errors
        v-if="genericErrors.length > 0"
        :show-icon="false"
      >
        <li
          v-for="(errorMessage, index) in genericErrors"
          :key="index"
          v-dompurify-html="$t(errorMessage)"
        />
      </form-errors>

      <div
        class="field-wrap dynamic email"
        :class="{ error: fieldHasError('email') }"
      >
        <input
          type="email"
          autocomplete="off"
          :class="{ 'not-empty': form.email !== '' }"
          @input="onEmailInput($event)"
        />
        <label>{{ $t('header.white.login.placeholder.email') }}</label>

        <div
          v-if="getErrors('email')"
          class="error-msg"
        >
          {{ getErrors('email') }}
        </div>
      </div>

      <div
        class="field-wrap dynamic password"
        :class="{ error: fieldHasError('password') }"
      >
        <input
          :type="showPassword ? 'text' : 'password'"
          autocomplete="off"
          :class="{ 'not-empty': form.password !== '' }"
          @input="onPasswordInput($event)"
        />
        <label>{{ $t('header.white.login.placeholder.password') }}</label>
        <div
          v-tooltip="passwordTooltip"
          :class="['show-password', { active: showPassword }]"
          @click="toggleShowPassword()"
        >
          <i class="icon" />
        </div>

        <div
          v-if="getErrors('password')"
          class="error-msg"
        >
          {{ getErrors('password') }}
        </div>
      </div>

      <div class="manage-login">
        <div class="forgot-password">
          <a
            v-if="pinsForgotUrl && internal"
            :href="pinsForgotUrl"
          >
            {{ $t('header.loginForm.forgotPasswordLink') }}
          </a>
          <span
            v-if="!internal"
            class="link"
            @click="openForgotPassword"
          >
            {{ $t('header.loginForm.forgotPasswordLink') }}
          </span>
        </div>
      </div>

      <p class="sub-text">{{ $t('header.white.pinsLogin.confirmation') }}</p>

      <div
        class="btn btn-blue btn-login"
        @click="submitLoginForm"
      >
        <button type="button">
          {{ $t('header.white.login.submitButtonText') }}
        </button>
      </div>
    </form>
    <small-loader />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import FormErrors from 'components/FormErrors/FormErrors'
  import ModalMixin from 'components/Modal/mixins/Modal'
  import PinsMixin from 'components/LoginForm/mixins/Pins'
  import SmallLoader from 'components/SmallLoader'
  import Tooltip from 'utils/directives/Tooltip'
  import gtmProfile from 'services/AirBaltic/GTM/Profile'

  import FormMixin from 'components/Forms/FormMixin'

  export default {
    directives: {
      Tooltip
    },
    components: {
      FormErrors,
      SmallLoader
    },
    mixins: [ModalMixin, PinsMixin, FormMixin],
    props: {
      type: {
        type: String,
        default: ''
      },
      internal: {
        type: Boolean,
        default: true
      },
      page: {
        type: String,
        default: 'bt_pages'
      }
    },
    data() {
      return {
        showPassword: false
      }
    },

    computed: {
      ...mapState('main', ['showSmallLoader', 'selectedLang']),
      ...mapGetters('loginForm', ['$v', 'loginFormErrors']),
      ...mapState('loginForm', ['form', 'genericErrors']),
      ...mapState('pins', ['pageData']),
      pinsForgotUrl() {
        return this.$t('header.white.pinsLogin.pinsForgotUrl')
      },
      passwordTooltip() {
        return {
          content: this.$t('header.white.login.tooltip'),
          class: 'js-pins-tooltip'
        }
      }
    },
    destroyed() {
      this.$store.dispatch('loginForm/resetForm')
    },
    methods: {
      submitLoginForm() {
        this.$store
          .dispatch('loginForm/validateForm')
          .then(() => {
            if (this.type === 'login') {
              this.loginWithPins()
            } else if (this.type === 'connect') {
              this.connectPins()
            } else if (this.type === 'getData') {
              this.getPinsData()
            }
          })
          .catch(() => {})
      },
      loginWithPins() {
        this.$store.commit('main/setShowSmallLoader', true)

        this.pinsLogin({
          userName: this.form.email,
          password: this.form.password
        })
          .then(() => gtmProfile.loginSuccessAction('pins', this.page))
          .catch((e) => {
            gtmProfile.failedLoginAction('pins', this.page, this.$t(e))
            return this.$store.dispatch('loginForm/setGenericErrors', e)
          })
          .finally(() => this.$store.commit('main/setShowSmallLoader', false))
      },
      openForgotPassword() {
        const ForgotPasswordForm = import(
          /* webpackChunkName: "white-forgot-password-form" */ 'components/LoginForm/ForgotPasswordForm.vue'
        )
        ForgotPasswordForm.then((result) => {
          this.$store.commit('main/setModalContent', result.default)
        })

        this.$store.dispatch('main/openModal', { root: true })
        this.$store.commit(
          'main/setModalTitle',
          this.$t('header.loginForm.forgotPasswordTitle')
        )
        this.$store.commit('main/setModalType', 'sidebar')
        this.$store.commit('main/setModalClass', 'modal-white-sidebar')
        this.$store.commit('main/setShowModalHeader', false)
        this.$store.commit('main/setShowModalHeaderWhite', true)
        this.$store.commit('main/setShowModalHeaderWhiteNav', true)
        this.$store.commit('main/setShowCloseIcon', true)
        this.$store.commit('main/setModalCloseFn', this.closeModal)
        this.$store.commit('main/setModalProps', { loginType: 'pins' })
      },
      closeModal() {
        this.$store.dispatch('main/closeModal')
      },
      getPinsData() {
        this.$store.commit('main/setShowSmallLoader', true)

        this.pinsData({
          userName: this.form.email,
          password: this.form.password
        })
          .then((userData) => this.$emit('success', userData))
          .catch((e) => this.$store.dispatch('loginForm/setGenericErrors', e))
          .finally(() => this.$store.commit('main/setShowSmallLoader', false))
      },
      connectPins() {
        this.$store.commit('main/setShowSmallLoader', true)

        this.connectPinsCard({
          userName: this.form.email,
          password: this.form.password,
          lang: this.selectedLang.toUpperCase()
        })
          .then(() =>
            this.openPinsNotification('success', this.$t('pins.notifications.connected'))
          )
          .catch((e) => this.$store.dispatch('loginForm/setGenericErrors', e))
          .finally(() => this.$store.commit('main/setShowSmallLoader', false))
      },
      toggleShowPassword() {
        this.showPassword = !this.showPassword
      },
      onEmailInput(event) {
        this.$store.commit('loginForm/setEmail', event.target.value)
      },
      onPasswordInput(event) {
        this.$store.commit('loginForm/setPass', event.target.value)
      },
      fieldHasError(field) {
        return this.$_formMixin_hasError(this.$v, field)
      },
      getErrors(field) {
        return this.$_formMixin_errorMsg(this.$v, this.loginFormErrors, field)
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
>
  @import '~assets/styles/components/tooltip.scss';

  .js-tooltip.js-pins-tooltip {
    @include tooltip();
    max-width: 200px;
    width: 100%;
    text-align: center;
  }
</style>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .pins-login-form {
    margin-bottom: 24px;

    .manage-login {
      margin-top: 12px;
      @include clearfix();

      .forgot-password {
        float: right;
        margin-left: -4px;
        padding-left: 10px;
        @include text-overflow();
        text-align: right;
        cursor: pointer;

        ::v-deep {
          .link,
          a {
            font-weight: 500;
            line-height: 26px;
            color: $blue-medium;
            text-decoration: none;

            &:hover {
              color: $blue-light;
            }
          }
        }
      }
    }

    img {
      height: 70px;
      display: block;
      margin: 55px auto 20px auto;

      @include bp-mf-tablet {
        display: block;
        margin: 0 auto 20px auto;
      }
    }

    .sub-text {
      font-size: em(12);
      line-height: 18px;
      color: $grey-default;
      margin: 19px 0 20px 0 !important;
      font-weight: 300;
      text-align: center !important;
    }

    .form-errors {
      @include bp-tablet-only {
        position: inherit;
        padding: 10px 20px;
        font-size: em(12);
      }

      &:before {
        @include bp-tablet-only {
          display: none;
        }
      }
    }

    .field-wrap {
      &.email {
        margin-bottom: 16px;
      }

      &.password {
        input,
        label {
          padding-right: 66px;
        }
      }

      input {
        border: 1px solid $formInputBorder;

        &:focus {
          border: 1px solid $gray-focus-border;
        }
      }

      .show-password {
        position: absolute;
        top: 1px;
        right: 1px;
        width: 46px;
        height: 46px;
        background-color: #ffffff;
        border-left: 1px solid $formInputBorder;
        text-align: center;
        cursor: pointer;

        .icon:after {
          content: $line-icon-eye;
          @include line-icon-font();
          font-size: em(20);
          line-height: 48px;
          color: #bac0c7;
        }

        &.active .icon:after {
          color: $blue-medium;
        }

        &:hover {
          .icon:after {
            @include bp-mf-desktop {
              color: $blue-active;
            }
          }
        }
      }
    }

    .btn-login {
      width: 100%;
      height: 48px;
      font-weight: 400;

      button {
        padding: 0 16px;
        font-size: em(14);
        line-height: 48px;
      }
    }
  }
</style>
