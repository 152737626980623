import Divolte from 'services/AirBaltic/Divolte'
import UUID_Utils from 'utils/UUID_Utils'
import conf from 'conf'

class Compliment {
  static push(name, data) {
    if (conf.divolte) {
      Divolte.doDataPush(name, data)
    }
  }

  static divoltePush(state) {
    this.push('passengerDetails', {
      identity: {
        userUUID: UUID_Utils.create_UUID(),
        firstname: state.firstName,
        surname: state.lastName,
        email: state.email,
        title: state.title,
      }
    })
  }
}

export default Compliment
