import { alphaWithSpaces, postalCode, regex } from 'utils/validators';
import { maxLength, required } from 'vuelidate/lib/validators';
var Company = /** @class */ (function () {
    function Company() {
        this.showBillingDetails = false;
        this.name = '';
        this.registrationNumber = '';
        this.vatNumber = '';
        this.address = '';
        this.city = '';
        this.postalCode = '';
        this.countryCode = '';
        this.addressMaxLength = 200;
        this.cityMaxLength = 50;
        this.nameMaxLength = 100;
        this.regNumberMaxLength = 30;
        this.vatNumberMaxLength = 30;
        this.errorMsg = {
            address: {
                allowedCharacters: 'reusable.street.validation.allowedCharacters',
                maxLength: 'reusable.street.validation.maxLength',
                required: 'reusable.street.validation.required',
            },
            city: {
                alphaWithSpaces: 'reusable.city.validation.alphaWithSpaces',
                maxLength: 'reusable.city.validation.maxLength',
                required: 'reusable.city.validation.required',
            },
            countryCode: {
                required: 'reusable.country.validation.required',
            },
            name: {
                maxLength: 'reusable.company.validation.maxLength',
                required: 'reusable.company.validation.required',
            },
            postalCode: {
                postalCode: 'reusable.postalCode.validation.invalid',
                required: 'reusable.postalCode.validation.required',
            },
            registrationNumber: {
                allowedCharacters: 'reusable.regNumber.validation.allowedCharacters',
                maxLength: 'reusable.regNumber.validation.maxLength',
                required: 'reusable.regNumber.validation.required',
            },
            vatNumber: {
                allowedCharacters: 'reusable.vatNumber.validation.allowedCharacters',
                maxLength: 'reusable.vatNumber.validation.maxLength',
            },
        };
        this.translationVariables = {
            address: {
                maxLength: {
                    limit: this.addressMaxLength,
                },
            },
            city: {
                maxLength: {
                    limit: this.cityMaxLength,
                },
            },
            name: {
                maxLength: {
                    limit: this.nameMaxLength,
                },
            },
            registrationNumber: {
                maxLength: {
                    limit: this.regNumberMaxLength,
                },
            },
            vatNumber: {
                maxLength: {
                    limit: this.vatNumberMaxLength,
                },
            },
        };
        this.validation = {
            form: {
                address: {
                    allowedCharacters: regex('alphaNumberSpaceDashCommaDot'),
                    maxLength: maxLength(this.addressMaxLength),
                    required: required,
                },
                city: {
                    alphaWithSpaces: alphaWithSpaces,
                    maxLength: maxLength(this.cityMaxLength),
                    required: required,
                },
                countryCode: {
                    required: required,
                },
                name: {
                    maxLength: maxLength(this.nameMaxLength),
                    required: required,
                },
                postalCode: {
                    postalCode: postalCode,
                    required: required,
                },
                registrationNumber: {
                    allowedCharacters: regex('alphaNumberSpaceDash'),
                    maxLength: maxLength(this.regNumberMaxLength),
                    required: required,
                },
                vatNumber: {
                    allowedCharacters: regex('alphaNumberSpaceDash'),
                    maxLength: maxLength(this.vatNumberMaxLength),
                },
            },
        };
    }
    Company.prototype.getDataForSending = function () {
        return {
            address: this.address,
            city: this.city,
            countryCode: this.countryCode,
            name: this.name,
            postalCode: this.postalCode,
            registrationNumber: this.registrationNumber,
            vatNumber: this.vatNumber,
        };
    };
    return Company;
}());
export default Company;
