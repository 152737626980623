var Generic = /** @class */ (function () {
    function Generic(obj) {
        if (obj === void 0) { obj = {}; }
        this.list = [];
        this.note = '';
        this.forms = [];
        this.additionalInfo = [];
        this.oldUrl = '';
        this.steps = '';
        this.stepsTitle = '';
        this.typeTitle = '';
        this.type = '';
        this.pageTitle = '';
        this.description = '';
        this.activeStep = '';
        this.activeItem = {};
        this.origDestData = [];
        this.namespace = '';
        this.countries = [];
        this.backFn = function () { return undefined; };
        if (Object.keys(obj).length > 0) {
            Object.assign(this, obj);
        }
    }
    return Generic;
}());
export default Generic;
