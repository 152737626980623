import Generic from 'models/Forms/Generic'
import moment from 'moment'

export default {
  namespaced: true,
  state: () => new Generic(),
  actions: {
    setGenericData ({ commit }, data) {
      commit('setSteps', data.steps)
      commit('setStepsTitle', data.stepsTitle)
      commit('setTypeTitle', data.typeTitle)
      commit('setAdditionalInfo', data.additionalInfo)
      commit('setList', data.list)
      commit('setNote', data.note)
      commit('setOldUrl', data.paymentUrl)
      commit('setForms', data.forms)
      commit('setType', data.type)
      commit('setPageTitle', data.title)
      commit('setDescription', data.description)
    },
    validateForm (context, rules) {
      return Promise.all(rules)
        .then(() => Promise.resolve(true))
        .catch(err => Promise.reject(err))
    },
    setCountries ({ commit }, countries) {
      const data = []
      countries.map(country => {
        data.push({
          value: country.value,
          title: country.title,
          dialingCode: country.dialingCode
        })
      })

      commit('setCountries', data)
    },
    clearForms ({ dispatch, rootGetters, rootState }) {
      const namespace = rootState.main.formNamespace
      const activeItem = rootGetters[`${namespace}/activeItem`].key

      const clearDataRules = [
        dispatch(`${namespace}/flights/clearForm`, null, { root: true }),
        dispatch(`${namespace}/${activeItem}/request/clearRequest`, null, { root: true }),
        dispatch(`${namespace}/contact/clearContacts`, null, { root: true }),
        dispatch(`${namespace}/company/clearCompany`, null, { root: true })
      ]

      return Promise.all(clearDataRules)
        .then(() => Promise.resolve(true))
        .catch(() => Promise.reject('clearForms failed'))
    },
    getFlightList (context, flights) {
      const flightsArray = []

      if (flights.hasFlights) {
        let data = flights.selectedFlights
        if (!flights.selectedFlights.length && flights.flights.length === 1) {
          data = flights.flights
        }

        data.forEach(flight => {
          flightsArray.push({
            flightNumber: flight.flightNumber.trim().toUpperCase(),
            flightDate: moment(
              `${flight.flightYear}-${flight.flightMonth}-${flight.flightDay}`,
              'YYYY-MM-DD'
            ).format('YYYY-MM-DD')
          })
        })
      } else {
        flights.flights.forEach(flight => {
          let date = flight.flightDate
          if (flight.flightDate !== '') {
            date = flight.flightDate.format('YYYY-MM-DD')
          }
          flightsArray.push({
            flightNumber: flight.flightNumber,
            flightDate: date
          })
        })
      }

      return flightsArray
    },
    getPassengersData (context, passengers) {
      const data = []

      passengers.map(passenger => {
        if (passenger.checked) {
          data.push({
            name: `${passenger.firstName} ${passenger.lastName}`,
            residence: passenger.residence,
            citizenship: passenger.citizenship
          })
        }
      })

      return data
    },
    getContactData (context, contact) {
      const code = contact.intCode.split('|')
      return {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phoneNumber: contact.phoneNumber,
        phoneCountry: code[1],
        phoneDialingCode: code[0]
      }
    },
  },
  mutations: {
    setCountries (state, value) {
      state.countries = value
    },
    setOrigDestData (state, value) {
      state.origDestData = value
    },
    setPageTitle (state, value) {
      state.pageTitle = value
    },
    setDescription (state, value) {
      state.description = value
    },
    setSteps (state, value) {
      state.steps = value
    },
    setAdditionalInfo (state, value) {
      state.additionalInfo = value
    },
    setBackFn (state, value) {
      state.backFn = value
    },
    setStepsTitle (state, value) {
      state.stepsTitle = value
    },
    setTypeTitle (state, value) {
      state.typeTitle = value
    },
    setList (state, value) {
      state.list = value
    },
    setNote (state, value) {
      state.note = value
    },
    setOldUrl (state, value) {
      state.oldUrl = value
    },
    setActiveItem (state, value) {
      state.activeItem = value
    },
    setActiveStep (state, value) {
      state.activeStep = value
    },
    setForms (state, value) {
      state.forms = value
    },
    setType (state, value) {
      state.type = value
    },
    setFormName (state, value) {
      state.formName = value
    },
    setProtectedBaggage (state, value) {
      state.protectedBaggage = value
    }
  }
}
