import AssistanceStore from 'store/modules/Pages/Assistance'
import SearchFormStore from 'store/modules/eHorizon/SearchForm/SearchForm'
import store from 'definitions/store'
import OrigDest from 'utils/OrigDestData'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, AssistanceStore)
      store.registerModule('eHorizonSearchForm', SearchFormStore)

      Init.initDefaultValues(name)
    }
  },
  initDefaultValues(name) {
    store.commit('main/setFormNamespace', name, { root: true })

    if (assistanceActiveItem !== null) {
      if (assistanceActiveItem.dataFromUrl) {
        store.commit(`${name}/validation/pnr`, assistanceActiveItem.dataFromUrl.pnr)
        store.commit(`${name}/validation/surname`, assistanceActiveItem.dataFromUrl.lastName.toUpperCase())
        store.commit(`${name}/validation/setFromUrl`, true)
      }
      store.dispatch(`${name}/setActiveItem`, assistanceActiveItem)
      store.dispatch(`${name}/setActiveStep`, 'validation')
    } else {
      store.dispatch(`${name}/setActiveStep`, 'intro')
    }

    if (typeof assistancePageData !== "undefined") {
      store.dispatch(`${name}/setGenericData`, assistancePageData)
    }

    if (typeof noteSnippet !== 'undefined') {
      store.commit(`sidebar/setPleaseNoteTitle`, noteSnippet.title)
      store.commit(`sidebar/setPleaseNoteText`, noteSnippet.text)
    }

    if (typeof pnrValidationPageData !== "undefined") {
      store.dispatch(`${name}/setPnrValidationData`, pnrValidationPageData)
    }

    if (typeof assistanceCountries !== "undefined") {
      store.dispatch(`${name}/generic/setCountries`, assistanceCountries)
    }

    if (typeof origDestData !== 'undefined') {
      store.commit(`eHorizonSearchForm/setOrigDestData`,  OrigDest.parseData(origDestData))
    }

    if (typeof assistanceTexts !== 'undefined') {
      store.dispatch(`${name}/setTexts`, assistanceTexts)
    }
  }
}

export default Init
