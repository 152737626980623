import GTM from 'services/AirBaltic/GTM'
import Vue from 'vue'

class MMB {
  static push (data) {
    GTM.doDataLayerPush(data)
  }

  static mmbPost (bookingRef) {
    let pnr = ''
    let ticketNumber = ''

    if (bookingRef.length === 6) {
      pnr = bookingRef
    } else {
      ticketNumber = bookingRef
    }

    this.push({
      'event': 'GAevent',
      'eventCategory': 'Frontpage - CKIN/MMB login',
      'eventAction': 'MMB login',
      'eventLabel': 'PNR - ' + pnr + '; Language - ' + Vue.i18n.locale(),
      'eventValue': 0,
      'nonInteraction': 0,
      'pnr': pnr,
      'ticketNumber': ticketNumber
    })
  }

  static mmbError (group, text) {
    this.push({
      event: 'GAevent',
      eventCategory: 'Error',
      eventAction: 'P:index; Cat:MMB; G: ' + group + '; Lang: ' + Vue.i18n.locale(),
      eventLabel: text,
      nonInteraction: 1
    })
  }

  static headerMMBClick () {
    this.push({
      event: 'GAevent',
      eventCategory: 'All pages',
      eventAction: 'Header',
      eventLabel: 'C:MMB button; A:click; Lang: ' + Vue.i18n.locale(),
      nonInteraction: 0
    })
  }

}

export default MMB
