import Divolte from 'services/AirBaltic/Divolte'
import UUID_Utils from 'utils/UUID_Utils'
import find from 'lodash-es/find'
import conf from 'conf'

class GroupBooking {
  static push (name, data) {
    if (conf.divolte) {
      Divolte.doDataPush(name, data)
    }
  }

  static searchDateSelection (state) {
    const paxSelector = state.paxSelector
    const departureDate = Divolte.getTimestampMs(state.calendar.departureDate)
    const arrivalDate = Divolte.getTimestampMs(state.calendar.returnDate)

    this.push('searchDateSelection', {
      search: {
        origin: state.origin.value.code,
        destination: state.destin.value.code,
        tripType: this.getTripType(state.tripType),
        outboundDate: Divolte.daysToEpoch(departureDate),
        inboundDate: Divolte.daysToEpoch(arrivalDate),
        adults: this.getPaxCount(paxSelector.adults),
        children: this.getPaxCount(paxSelector.children),
        infants: this.getPaxCount(paxSelector.infants)
      }
    })
  }

  static getTripType (type) {
    return type === 'return' ? 'RT' : 'OW'
  }

  static getPaxCount(pax) {
    return pax !== null ? Number(pax) : 0
  }

  static getFlights (state) { // step 2 after submit
    const flights = []

    const departureFlight = find(state.step1.departureData, {'flightNumber': state.step2.departureFlight })

    if (departureFlight) {
      flights.push(this.parseFlight(departureFlight, 'out'))
    }

    if (state.step1.tripType === 'return') {
      const returnFlight = find(state.step1.returnData, {'flightNumber': state.step2.returnFlight })

      if (returnFlight) {
        flights.push(this.parseFlight(returnFlight, 'in'))
      }
    }

    return flights
  }

  static flightSelection (state) {
    const flights = this.getFlights(state)

    if (flights.length) {
      this.push('flightSelection', {
        products: {
          flights: flights
        }
      })
    }
  }

  static parseFlight (flight, direction) {
    const flightNumber = flight.flightNumber[0];
    const carrier = flightNumber.slice(0, 2)
    const number = Number(flightNumber.slice(2, flightNumber.length))

    return {
      origin: flight.departAirport,
      destination: flight.arrivalAirport,
      departureDateTime: Divolte.parseDate(flight.departDate + ' ' + flight.departTime, 'DD.MM.YYYY HH:ii'),
      arrivalDateTime: Divolte.parseDate(flight.arrivalDate + ' ' + flight.arrivalTime, 'DD.MM.YYYY HH:ii'),
      flightNumber: number,
      carrier: carrier,
      direction: this.getDirection(direction)
    }
  }

  static getDirection (direction) {
    return direction === 'out' ? 'OUTBOUND' : 'INBOUND'
  }

  static passengerDetails (state) { // form submit success
    const contact = state.step4

    const identity = {
      userUUID: UUID_Utils.create_UUID(),
      title: contact.title,
      firstname: contact.firstName,
      surname: contact.lastName,
      email: contact.email
    }

    const products = {
      flights: this.getFlights(state)
    }

    this.push('passengerDetails', {
      identity,
      products
    })
  }
}

export default GroupBooking
