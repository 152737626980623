import CharterPageStore from 'store/modules/Pages/CharterCargo/CharterPage'
import CargoFormStore from 'store/modules/Pages/CargoForm'
import Charters from 'services/AirBaltic/Charters'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, CharterPageStore)
      store.registerModule('cargoForm', CargoFormStore)
      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    if (typeof pageData !== "undefined") {
      store.commit(`${name}/setPageData`, pageData)
    }

    store.commit(`${name}/setSelectedForm`, 'cargo')

    if (typeof cargoFormData !== 'undefined') {
      store.dispatch(`cargoForm/generic/setGenericData`, cargoFormData)
      store.commit(`cargoForm/generic/setActiveStep`, 'step1')
    }

    if (typeof cargoFormCountries !== 'undefined') {
      cargoFormCountries.sort((a, b) => a.title.localeCompare(b.title))
      store.dispatch(`cargoForm/generic/setCountries`, cargoFormCountries)
    }

    new Charters().getAirportList()
      .then((res) => {
        store.dispatch(`cargoForm/generic/setAirports`, res)
      })
      .catch(() => {})
  }
}

export default Init
