import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import moment from 'moment'
import FlightModel from 'models/Claims/Flights'

const data = {
  flightDay: '',
  flightMonth: '',
  flightYear: '',
}

const flight = Object.assign({}, new FlightModel(), data)

const defaultState = {
  flights: [flight],
  selectedFlights: [],
  hasFlights: false,
  formErrors: flight.errorMsg,
  category: ''
}

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations () {
    return {
      state: Object.assign({}, flight.validation)
    }
  }
})

const Flights = {
  state: defaultState,
  mutations: {
    addFlight (state) {
      const flight = new FlightModel()
      const data = {
        flightDay: '',
        flightMonth: '',
        flightYear: '',
      }
      state.flights.push(Object.assign({}, flight, data))
    },

    removeFlight (state, index) {
      state.flights.splice(index, 1)
    },

    updateSelectedFlights (state, value) {
      state.selectedFlights = value
    },

    setHasFlights (state, value) {
      state.hasFlights = value
    },

    setFlights (state, value) {
      state.flights = value
    },

    setFlightNumber (state, value) {
      if (state.flights[value.index]) {
        state.flights[value.index].flightNumber = value.value
      }
    },

    setFlightDay (state, value) {
      if(state.flights[value.index]) {
        state.flights[value.index].flightDay = value.value
      }
    },

    setFlightMonth (state, value) {
      if(state.flights[value.index]) {
        state.flights[value.index].flightMonth = value.value
      }
    },

    setFlightYear (state, value) {
      if(state.flights[value.index]) {
        state.flights[value.index].flightYear = value.value
      }
    },

    setFlightDate (state, value) {
      if(state.flights[value.index]) {
        state.flights[value.index].flightDate = value.value
      }
    },

    reset (state) {
      state.flights = [Object.assign({}, new FlightModel(), data)]
      state.selectedFlights = []
    },

    setCategory (state, value) {
      state.category = value
    }
  },

  actions: {
    setFlightDate ({ state, commit }, index) {
      const flight = state.flights[`${index}`]
      const data = {
        value: null,
        index
      }

      if (typeof flight !== 'undefined') {
        const flightDate = `${flight.flightYear}-${flight.flightMonth}-${flight.flightDay}`

        if (flightDate.split('-').every(dateToken => dateToken !== '')) {
          data.value = moment(flightDate, 'YYYY-M-D', true)
        }
      }

      commit('setFlightDate', data)
    },

    clearForm ({ commit }) {
      validator.$v.state.flights.$reset()
      validator.$v.state.selectedFlights.$reset()
      commit('reset')
    },

    touch ({ state }) {
      return new Promise((resolve, reject) => {
        let vState = validator.$v.state.flights

        if (state.hasFlights) {
          vState = validator.$v.state.selectedFlights
        }

        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('flights-claims')
      })
    }
  },

  getters: {
    $flights () {
      return Object.assign({}, validator.$v.state.flights)
    },

    checkedValidation () {
      return Object.assign({}, validator.$v.state.selectedFlights)
    },

    flightCount (state) {
      return state.flights.length
    },
  },

  namespaced: true
}

export default Flights
