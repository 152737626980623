import TimetableStore from 'store/modules/Pages/Timetable'
import SearchFormStore from 'store/modules/eHorizon/SearchForm/SearchForm'
import OrigDestUtils from 'store/utils/eHorizonOrigDestUtils'
import CookieUtils from 'utils/CookieUtils'
import store from 'definitions/store'
import OrigDest from 'utils/OrigDestData'
import getNearestAirport from 'utils/GetNearestAirport'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, TimetableStore)
      store.registerModule('eHorizonSearchForm', SearchFormStore)

      Init.initDefaultValues(name)
      Init.initOrigDestData()
      Init.initSearchFormConfig()
    }
  },

  initDefaultValues(name) {
    if (typeof pageData !== 'undefined') {
      store.commit(`${name}/setPageData`, pageData)
    }
  },
  initOrigDestData() {
    const nearestAirport = getNearestAirport()

    if (typeof origDestLabels !== 'undefined') {
      store.commit(`eHorizonSearchForm/destin/setLabels`, origDestLabels)
    }

    if (typeof origDestData !== 'undefined') {
      const data = OrigDest.parseData(origDestData)
      store.commit(`eHorizonSearchForm/setOrigDestData`, data)
      store.commit(
        `eHorizonSearchForm/origin/setDestinations`,
        OrigDestUtils.prepareData(data).filter((o) => o.isOrig)
      )

      if (nearestAirport && typeof data[nearestAirport] !== 'undefined') {
        store.dispatch(`eHorizonSearchForm/selectOrigin`, data[nearestAirport])
      }
    }

    const country = CookieUtils.getCookie('country') || null
    if (country) {
      store.dispatch(`eHorizonSearchForm/origin/setHomeCountry`, country)
    }
  },
  initSearchFormConfig() {
    store.commit(`eHorizonSearchForm/setGroupBookingUrl`, GROUP_BOOKING_URL)
    store.commit(`eHorizonSearchForm/setGroupBookingFormUrl`, GROUP_BOOKING_FORM)
  }
}

export default Init
