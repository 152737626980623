import { required } from 'vuelidate/lib/validators';
import { regex } from 'utils/validators';
var LAD = /** @class */ (function () {
    function LAD() {
        this.form = {
            number: ''
        };
        this.errorMsg = {
            number: {
                flightNumberInvalid: 'reusable.flightNumber.validation.invalid',
                required: 'reusable.flightNumber.validation.required'
            }
        };
        this.validation = {
            form: {
                number: {
                    flightNumberInvalid: regex('btFlightNumber'),
                    required: required
                }
            }
        };
    }
    return LAD;
}());
export default LAD;
