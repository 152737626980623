<template>
  <div
    :class="[
      'index-page-lead-img',
      `${activeTab}`,
      { 'fallback-enabled': fallbackImageVisible, 'mood-img': hasMoodCityBlock }
    ]"
    :style="backgroundImage"
  >
    <div class="wrap">
      <slot />
      <div
        v-if="hasMoodCityBlock && !showHistogram"
        class="mood-item"
      >
        <div class="city">{{ title }}</div>
        <div
          v-dompurify-html="banners.description"
          class="description"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ImageHelpers from 'utils/mixins/ImageHelpers'
  import { mapState } from 'vuex'
  import * as Sentry from '@sentry/vue'

  export default {
    mixins: [ImageHelpers],
    props: {
      activeTab: {
        type: String,
        required: true
      },
      banners: {
        type: Object,
        required: true
      },
      deviceType: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        backgroundImage: '',
        fallbackImageVisible: false
      }
    },
    computed: {
      ...mapState('eHorizonSearchForm', ['origDestData', 'showHistogram']),
      isMoodImg() {
        return this.banners.type === 'moodBanner'
      },
      hasMoodCityBlock() {
        return this.isMoodImg && this.deviceType === 'desktop'
      },
      title() {
        return this.banners.moodTitle ? this.banners.moodTitle : this.cityName
      },
      cityName() {
        const dest =
          this.origDestData[`${this.banners.destination}A`] ||
          this.origDestData[`${this.banners.destination}C`]

        if (typeof dest === 'undefined') {
          Sentry.captureException('No destination found -' + this.banners.destination)
          return ''
        }
        return dest.city
      }
    },
    watch: {
      activeTab: {
        handler: function () {
          this.getBackgroundImage()
        }
      },
      banners: {
        handler: function () {
          this.getBackgroundImage()
        }
      },
      deviceType: {
        handler: function () {
          this.getBackgroundImage()
        }
      }
    },
    created() {
      this.getBackgroundImage()
    },
    methods: {
      getBackgroundImage() {
        const source = this.$_ImageHelpers_responsiveImage({
          desktop: this.banners.images.desktopImg.img,
          tablet: this.banners.images.tabletImg.img,
          mobile: this.banners.images.mobileImg.img
        })

        if (source) {
          this.fallbackImageVisible = false
          this.backgroundImage = this.$_ImageHelpers_getBackgroundImage(source)
        } else {
          this.backgroundImage = { 'background-image': `` }
          this.fallbackImageVisible = true
        }
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';

  .index-page-lead-img {
    font-family: $base-font-family;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: background-image 0.2s ease-in-out;
    background-image: linear-gradient(180deg, #0142b5 0%, #9bcee5 100%);

    @include bp-mf-desktop {
      z-index: 1;
    }

    &.fallback-enabled {
      @include bp-mf-desktop() {
        background-image: url('~assets/images/index-page/fallback-image.jpg');
      }
    }

    &.FLT,
    &.MMB,
    &.CKI,
    &.HTL {
      height: 100%;
      min-height: 220px;

      @include bp-mf-tablet {
        min-height: 320px;
      }

      @include bp-mf-desktop {
        min-height: 474px;
      }

      @include bp-mf-desktop-large {
        min-height: 474px;
        height: 474px;
        display: flex;
        align-items: center;
      }

      @include bp-mf-desktop-xlarge {
        min-height: 600px;
        display: flex;
        align-items: center;
      }
    }

    &.mood-img {
      position: relative;

      &::after {
        display: block;
        position: absolute;
        bottom: 0;
        height: 258px;
        width: 100%;
        content: '';
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.79) 100%
        );
      }
    }

    .wrap {
      width: 100%;
      max-width: $desktop-wide-width;
      position: relative;
    }

    .mood-item {
      position: absolute;
      right: 0;
      bottom: -15px;
      width: 255px;
      color: white;
      text-align: center;
      line-height: 25px;
      z-index: 1;

      @include bp-desktop-small {
        bottom: -10px;
      }

      @include bp-mf-desktop-large {
        bottom: 15px;
      }

      @include bp-mf-desktop-xlarge {
        bottom: -30px;
      }

      .city {
        font-size: em(20);
        font-weight: 500;
        line-height: 22px;

        @include bp-mf-desktop-xlarge {
          font-size: em(24);
          line-height: 18px;
        }

        &::after {
          margin: 10px 0;
          content: $icon-quotes;
          @include icon-font-thin();
          display: block;
          font-size: 20px;
          color: $green-base;

          @include bp-mf-desktop-xlarge {
            font-size: 16px;
          }
        }
      }

      .description {
        font-size: em(16);

        @include bp-mf-desktop-xlarge {
          font-size: em(16);
          line-height: 24px;
        }

        ::v-deep p {
          margin: 0;
        }
      }
    }
  }
</style>
