<template>
  <div id="search-results-container">
    <div
      v-if="searchResults.length !== 0"
      class="results-count"
    >
      {{ totalResultCount }} {{ $t('searchPage.searchedFor') }}:  "{{ searchString }}"
    </div>
    <div
      v-if="searchResults.length === 0 && searchString !== '' && !loaderIsShown"
      class="no-results"
    >
      {{ $t('searchPage.noResults') }} "{{ searchString }}"
    </div>

    <template v-if="searchResults.length > 0 && !loaderIsShown">
      <suggestion
        v-for="(result, index) in searchResults"
        :id="result.cacheId"
        :key="index"
        :title="result.title"
        :description="result.snippet"
        :url="result.link"
        @onClick="onClick"
      />
    </template>

    <div
      v-if="totalPages > 1"
      class="btn-group"
    >
      <div
        class="btn btn-ghost btn-small"
        :class="{'disabled': currentPage === 1}"
        @click="prevPage"
      >
        <span>{{ $t('searchPage.prevPage') }}</span>
      </div>
      <div
        class="btn btn-ghost btn-small"
        :class="{'disabled': currentPage === totalPages}"
        @click="nextPage"
      >
        <span>{{ $t('searchPage.nextPage') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import Suggestion from 'components/Search/Suggestion'
  import querystring from 'querystring'

  export default {
    components: {
      Suggestion
    },
    props: {
      loaderIsShown: {
        type: Boolean,
        required: false,
        default: false
      },
      searchString: {
        type: String,
        required: true
      },
      searchResults: {
        type: Array,
        required: true
      },
      currentPage: {
        type: Number,
        required: true
      },
      totalPages: {
        type: Number,
        required: true
      },
      totalResultCount: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        inSearchPage: false
      }
    },
    created () {
      this.$store.commit('search/setResultsPageVisible', true)

      // eslint-disable-next-line no-undef
      const parsedHash = querystring.parse(location.hash)
      if (typeof parsedHash['#search'] !== 'undefined') {
        this.$store.dispatch('search/setSearchString', parsedHash['#search'])
      }
    },
    methods: {
      prevPage () {
        if (this.currentPage - 1 > 0) {
          this.$store.commit('search/setCurrentPage', this.currentPage - 1)
          this.$store.dispatch('search/getResults')
        }
      },
      nextPage () {
        if (this.currentPage + 1 <= this.totalPages) {
          this.$store.commit('search/setCurrentPage', this.currentPage + 1)
          this.$store.dispatch('search/getResults')
        }
      },
      onClick (id, url) {
        window.location = url
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  .results-count,
  .no-results {
    padding: 36px 0 13px;
    font-size: em(16);

    @include bp-mobile {
      padding: 26px 0 2px;
      font-size: em(14);
    }
  }

  .btn-group {
    margin-top: 42px;

    @include bp-mobile {
      margin-top: 32px;
    }

    .btn-ghost {
      min-width: 126px;
      text-align: center;

      @include bp-mobile {
        max-width: 126px;
        height: 42px;
      }

      span {

        @include bp-mobile {
          font-size: em(13);
          line-height: 42px;
        }
      }

      &:nth-of-type(2) {
        float: right;
      }
    }
  }
</style>
