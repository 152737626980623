import AbstractView from 'abstract';
import Vue from 'vue'
import pinsInit from 'initializations/pages/Pax/Pins'
import SidebarInit from 'initializations/Sidebar'
import SettingsInit from 'initializations/pages/Pax/ProfileSettings'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
      SidebarInit.init("sidebar")
      SettingsInit.init("profileSettings")
      pinsInit.init("pins")

      const pinsPage = () => import(/* webpackChunkName: "pins-page" */ 'containers/Pages/Profile/Pins')
      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(pinsPage)
      })
    }
}
