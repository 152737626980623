import moment from 'moment';
var isPaxOlderThan = function (years, format, date) {
    if (format === void 0) { format = 'DD.MM.YYYY'; }
    if (date === void 0) { date = null; }
    return function (value) {
        /**
         * function is for dynamic values
         */
        var yearsValue = typeof years === 'function' ? years() : years;
        if (date) {
            return (value === '' ||
                moment(date, format).diff(moment(value, format), 'years') < yearsValue);
        }
        else {
            return value === '' || moment().diff(moment(value, format), 'years') < yearsValue;
        }
    };
};
export default isPaxOlderThan;
