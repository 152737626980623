import axios from 'axios'
import querystring from 'querystring'
import conf from 'conf'
import get from 'lodash-es/get'

const Store = {
  state: {
    searchString: '',
    searchPageUrl: '',
    searchResults: [],
    currentPage: 1,
    totalPages: 1,
    totalResultCount: 21,
    suggestionCount: 3,
    searchResultsCount: 6,
    resultsPageVisible: false
  },
  mutations: {
    setSearchString(state, value) {
      state.searchString = value
    },
    setSearchResults(state, value) {
      state.searchResults = value
    },
    setSearchPageUrl(state, value) {
      state.searchPageUrl = value
    },
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    setTotalPages(state, value) {
      state.totalPages = value
    },
    setTotalResultCount(state, value) {
      state.totalResultCount = value
    },
    setResultsPageVisible(state, value) {
      state.resultsPageVisible = value
    }
  },
  actions: {
    setSearchString({ commit, dispatch }, value) {
      commit('setSearchString', value)
      commit('setCurrentPage', 1)
      dispatch('getResults')
    },
    getResults({ state, dispatch }) {
      if (
        typeof conf.gcsApiKey !== 'undefined' &&
        typeof conf.gcsEngineKey !== 'undefined' &&
        state.resultsPageVisible
      ) {
        dispatch('getSearchResults')
      }
    },
    getSearchResults({ commit, state, rootState }) {
      if (state.searchString !== '') {
        const selectedLang = get(rootState, 'main.selectedLang', 'en').toLowerCase()
        const object = {
          lr: `lang_${selectedLang}`,
          hl: selectedLang,
          key: conf.gcsApiKey,
          cx: conf.gcsEngineKey,
          q: `${state.searchString}`,
          num: state.searchResultsCount,
          start:
            state.searchResultsCount * state.currentPage + 1 - state.searchResultsCount,
          fields: 'searchInformation/totalResults,items(cacheId,link,title,snippet)',
          prettyPrint: false,
          c2coff: 1
        }

        commit('main/setShowSmallLoader', true, { root: true })
        axios
          .get(`${conf.gcsApiUrl}?${querystring.stringify(object)}`)
          .then((response) => {
            const total = parseInt(response.data.searchInformation.totalResults, 10)
            const pages = total !== 0 ? Math.round(total / state.searchResultsCount) : 1
            const items =
              typeof response.data.items !== 'undefined' ? response.data.items : []

            commit('setSearchResults', items)
            commit('setTotalPages', pages)
            commit('setTotalResultCount', total)
          })
          .catch(() => commit('setSearchResults', []))
          .finally(() => commit('main/setShowSmallLoader', false, { root: true }))
      }
    }
  },
  namespaced: true
}

export default Store
