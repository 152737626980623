import flattenDeep from 'lodash-es/flattenDeep'
import PnrRetrievalService from 'services/AirBaltic/Forms/PnrRetrieval.js'
import PnrValidation from 'models/Forms/PnrValidation'
import PnrValidationSection from 'models/Forms/Sections/PnrValidationSection'
import validationUtils from 'utils/Validation'
import FormValidationUtils from 'utils/FormValidation'

const pnrValidation = new PnrValidation()
const formData = {
  form: pnrValidation,
  formErrors: pnrValidation.errorMsg,
  errors: pnrValidation.errors
}
const defaultState = Object.assign({}, new PnrValidationSection(), formData)
const validator = FormValidationUtils.initValidation(defaultState, pnrValidation.validation)

export default {
  namespaced: true,
  state: defaultState,
  actions: {
    clearForm ({ commit }) {
      commit('pnr', '')
      commit('surname', '')
      commit('setFromUrl', false)
    },
    setPnrValidationData ({ commit }, data) {
      commit('setBookingTitle', data.bookingTitle)
      commit('setBookingDescription', data.bookingDescription)
      commit('setInfoBoxDescription', data.infoBoxDescription)
      commit('setInfoBoxCTA', data.infoBoxCTA)
    },
    validatePnrForm ({ dispatch }, activeItem) {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        dispatch('validateFields', 'all')
        dispatch('clearErrors')

        if (!vState.$invalid) {
          dispatch('submitForm')
            .then((res) => dispatch('validationSuccessful', { response: res, activeItem }))
            .then((data) => resolve(data))
            .catch((e) => reject(e))
        }
      })
    },
    validationSuccessful ({ commit, dispatch, rootState, rootGetters}, res) {
      const namespace = rootState.main.formNamespace

      const data = {
        activeItem: res.activeItem,
        response: res.response,
        rules: rootGetters[`${namespace}/pnrRules`]
      }

      return new PnrRetrievalService().parseResponse(data)
        .then(() => {
          commit('removeErrors')
          dispatch('setPaxData', { pax: res.response.data, namespace })
          return Promise.resolve(res.response)
        })
        .catch((e) => {
          dispatch('clearPaxData', namespace)
          return Promise.reject(e)
        })
    },
    clearPaxData ({ dispatch }, namespace) {
      dispatch(`${namespace}/flights/clearForm`, null , { root: true })
    },
    setPaxData ({ commit }, data) {
      const flightData = new PnrRetrievalService().getFlightData(data.pax.segments)
      commit(`${data.namespace}/flights/setHasFlights`, !!flightData.length, { root: true })
      commit(`${data.namespace}/flights/setFlights`, flightData, { root: true })
    },
    submitForm ({ state }) {
      return new Promise((resolve, reject) => {
        new PnrRetrievalService().validatePnr(state.form.pnr, state.form.surname)
          .then((response) => resolve(response))
          .catch((e) => reject(e))
      })
    },
    clearErrors ({ rootState }) {
      validationUtils.clearErrors(this, rootState.main.formNamespace)
    },
    validateFields ({ rootState }, field) {
      validationUtils.validateFields(this, rootState.main.formNamespace, validator, field)
    }
  },
  mutations: {
    surname (state, value) {
      state.form.surname = value
    },
    removeErrors (state) {
      state.errors = new PnrValidation().errors
    },
    pnr (state, value) {
      state.form.pnr = value
    },
    setFromUrl (state, value) {
      state.form.setFromUrl = value
    },
    setBookingTitle (state, value) {
      state.bookingTitle = value
    },
    setBookingDescription (state, value) {
      state.bookingDescription = value
    },
    setInfoBoxDescription (state, value) {
      state.infoBoxDescription = value
    },
    setInfoBoxCTA (state, value) {
      state.infoBoxCTA = value
    },
    addError (state, error) {
      if (typeof state.errors[error.name] === 'undefined') {
        state.errors[error.name] = []
      }
      const found = state.errors[error.name].includes(error.msg)
      if (!found) {
        state.errors[error.name].push(error.msg)
      }
    },
    removeError (state, error) {
      if (error.name === 'generic') {
        state.errors.generic = []
      } else {
        const index = state.errors[error.name].indexOf(error.msg)
        if (index !== -1) {
          state.errors[error.name].splice(index, 1)
        }
      }
    },
  },
  getters: {
    $v () {
      return Object.assign({}, validator.$v.state.form)
    },
    validationFormErrors (state) {
      let filtered = state.errors

      if (typeof filtered !== 'undefined') {
        const values = Object.values(filtered)
        filtered = values.filter(n => n)
      }
      return flattenDeep(filtered)
    }
  }
}
