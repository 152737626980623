import Generic from 'store/modules/Pages/CharterCargo/Form/Generic'
import Step1 from 'store/modules/Pages/CharterCargo/Form/Steps/Step1'
import Step2 from 'store/modules/Pages/CargoForm/Steps/Step2'
import Step3 from 'store/modules/Pages/CargoForm/Steps/Step3'
import Company from 'store/modules/Pages/CharterCargo/Form/Company'
import CargoFormService from 'services/AirBaltic/Forms/CargoForm'

const CargoForm = {
  modules: {
    generic: Generic,
    step1: Step1,
    step2: Step2,
    step3: Step3,
    company: Company
  },
  state: {
    pageData: {
      title: '',
      intro: '',
      description: '',
      mobileImg: '',
      tabletImg: '',
      desktopImg: '',
      openFormLink: {
        href: '',
        text: ''
      },
    }
  },
  mutations: {
    setPageData (state, value) {
      state.pageData = value
    }
  },
  actions: {
    getDataAndSubmitForm ({ dispatch, rootState }) {
      const steps = [
        dispatch('step1/getData'),
        dispatch('step2/getData'),
        dispatch('step3/getData'),
        dispatch('company/getData'),
      ]

      return Promise.all(steps)
        .then((data) => {
          const req = {
            languageCode: rootState.main.selectedLang.toUpperCase(),
            legs: data[0],
            cargoInformation: {
              natureOfGoods: data[1].natureOfGoods,
              totalWeight: data[1].totalWeight,
              totalPieces: data[1].totalPieces,
              totalVolume: data[1].totalVolume,
              dangerousGoods: data[1].dangerousGoods,
              additionalRequests: data[1].additionalInfo,
              dimensions: {
                height: data[1].dimensions.height,
                width: data[1].dimensions.width,
                length: data[1].dimensions.length,
              }
            },
            contactInformation: {
              firstName: data[2].firstName,
              lastName: data[2].lastName,
              email: data[2].email,
              phone: data[2].intCode + ' ' + data[2].phoneNumber,
              company: {
                name: data[3].name,
                registrationNumber: data[3].regNo,
                vatNumber: data[3].vatNo,
                address: data[3].address,
                city: data[3].city,
                postalCode: data[3].postalCode,
                country: data[3].country,
              }
            }
          }

          return new CargoFormService().submitForm(req)
            .then(() => Promise.resolve())
            .catch((e) => Promise.reject({ type: 'service', data: e }))
        })
        .catch((e) => Promise.reject(e))
    }
  },
  namespaced: true
}

export default CargoForm
