import { not, required, sameAs, maxLength } from 'vuelidate/lib/validators';
var Step1 = /** @class */ (function () {
    function Step1() {
        this.destin = '';
        this.origin = '';
        this.tripType = 'oneway';
        this.errorMsg = {
            destin: {
                not: 'reusable.destin.validation.sameAs',
                required: 'reusable.destin.validation.required',
                value: {
                    required: 'reusable.origin.validation.required',
                    maxLength: 'pinsReward.errors.originDestin.maxLength',
                },
            },
            origin: {
                required: 'reusable.origin.validation.required',
                value: {
                    required: 'reusable.origin.validation.required',
                    maxLength: 'pinsReward.errors.originDestin.maxLength',
                },
            },
        };
        this.validations = {
            destin: {
                not: not(sameAs('origin')),
                required: required,
                value: {
                    required: required,
                    maxLength: maxLength(50)
                },
            },
            origin: {
                required: required,
                value: {
                    required: required,
                    maxLength: maxLength(50)
                },
            },
        };
    }
    return Step1;
}());
export default Step1;
