<template>
  <div class="wrap-container">
    <div class="container">
      <div class="buttons tabs">
        <template v-if="isIndex">
          <button
            v-for="tab in tabs"
            :key="tab.key"
            :ref="tab.key"
            class="button-link tab"
            :class="{ active: isActiveTab(tab.key) }"
            @click="switchToTab(tab.key)"
          >
            <span>{{ tab.label }}</span>
          </button>
        </template>
        <template v-if="!isIndex && isDesktop">
          <a
            v-for="tab in tabs"
            :key="tab.key"
            class="button-link tab"
            :href="`/${selectedLang}/index?opentab=${tab.key}`"
            @click="clickAction(tab.key)"
          >
            {{ tab.label }}
          </a>
        </template>
      </div>
      <div
        v-if="isDesktop && mainMenu.length"
        class="buttons"
      >
        <button
          v-for="item in mainMenu"
          :key="item.label"
          class="button-link tab"
          :class="{ active: item.label === activeMenuItem.label }"
          @click="openMenuItem(item)"
          @keyup.esc="openMenuItem(activeMenuItem)"
        >
          {{ item.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import UrlParams from 'utils/UrlParams'
  import ScrollUtils from 'utils/scrollUtils'
  import gtmHeader from 'services/AirBaltic/GTM/HeaderMenu'

  export default {
    props: {
      mainMenu: {
        type: Array,
        required: true
      },
      activeMenuItem: {
        type: [Object, String],
        default: '',
        required: false
      },
      menuOpen: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    computed: {
      ...mapGetters('device', ['isDesktop']),
      ...mapState('main', ['pageType', 'selectedLang']),
      ...mapState('indexTabs', ['activeTab']),
      ...mapState('header', ['tabs']),
      isIndex() {
        return this.pageType === 'index'
      }
    },
    created() {
      this.getTabFromHash()
    },
    methods: {
      openMenuItem(item) {
        if (item === this.activeMenuItem && this.activeMenuItem !== '') {
          this.$emit('open-menu-item', '')
          return
        }

        gtmHeader.headerCategoryClick(item.label, item.href, item.label)
        this.$emit('open-menu-item', item)
      },
      getTabFromHash() {
        let tab = UrlParams.getUrlParam(window.location.href, 'opentab')?.toUpperCase()

        if (this.tabs.includes(tab)) {
          this.switchToTab(tab, true)
        }
      },
      switchToTab(tab, initial = false) {
        if (tab === this.activeTab) {
          return
        }

        this.$store.dispatch('indexTabs/switchTabs', tab, { root: true })

        if (!initial) {
          gtmHeader.headerCategoryClick(
            tab,
            null,
            this.$t(`indexTabs.${tab.toLowerCase()}TabLabel`)
          )
        }

        ScrollUtils.smoothScroll(0, 200)
        this.$emit('open-tab', tab)
      },
      clickAction(tab) {
        gtmHeader.headerCategoryClick(
          tab,
          `/${this.selectedLang}/index?opentab=${tab}`,
          this.$t(`indexTabs.${tab.toLowerCase()}TabLabel`)
        )
      },
      isActiveTab(key) {
        if (this.menuOpen || this.activeMenuItem !== '') {
          return false
        }

        if (key === 'FLT' && this.activeTab === 'HTL') {
          return true
        }

        return this.activeTab === key
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .wrap-container {
    box-shadow: inset 0 -1px 0 0 $gray-divider;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    @include bp-mf-tablet {
      overflow: initial;
      white-space: initial;
    }

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: $desktop-lg;
      margin: 0;

      @include bp-mf-desktop {
        margin: 0 auto;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 0 8px !important;

    .button-link {
      width: auto;
      min-height: 48px;
      display: inline-block;
      align-items: center;
      padding: 0 4px;

      @include bp-mf-tablet {
        display: flex;
      }

      &.tab {
        @include bp-mf-desktop {
          font-weight: 500;
        }
      }
    }

    a.button-link {
      min-height: 48px;
      display: flex;
      align-items: center;
    }

    /* &.tabs {
      padding: 0;


      @include bp-mf-desktop {

      }
    } */
  }

  .button-link {
    font-size: rem(16, 16);
    font-weight: 400;
    line-height: rem(24, 16);
    color: $blue-dark;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: space-between;

    &.active {
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $green-base;
      }

      span {
        font-weight: 500;
      }
    }
  }

  button {
    padding: 0;
  }
</style>
