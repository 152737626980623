import loginForm from 'store/modules/Header/LoginForm'

const Login = {
  modules: {
    loginForm
  },

  state: {
    pageTitle: '',
    description: '',
    resetPasswordLinkText: '',
    indexUrl: '',
    profilePageUrl: '',
    profilePageUrlOld: ''
  },

  mutations: {
    setPageTitle (state, value) {
      state.pageTitle = value
    },

    setDescription (state, value) {
      state.description = value
    },

    setLinkText (state, value) {
      state.resetPasswordLinkText = value
    },

    setIndexUrl (state, value) {
      state.indexUrl = value
    },

    setProfilePageUrl (state, value) {
      state.profilePageUrl = value
    },

    setProfilePageUrlOld (state, value) {
      state.profilePageUrlOld = value
    }
  },
  namespaced: true
}

export default Login
