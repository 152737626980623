import * as moment from 'moment';
var Flight = /** @class */ (function () {
    function Flight(segment) {
        this.id = 0;
        this.origin = '';
        this.destin = '';
        this.origDescription = '';
        this.destDescription = '';
        this.departure = '';
        this.arrival = '';
        this.departureTime = '';
        this.arrivalTime = '';
        this.departureDate = '';
        this.nextDay = false;
        if (typeof segment !== 'undefined') {
            var arrival = moment.parseZone(segment.arrival);
            var departure = moment.parseZone(segment.depart);
            var nextDay = moment
                .parseZone(segment.arrival)
                .startOf('day')
                .diff(moment.parseZone(segment.depart).startOf('day'), 'days');
            this.id = segment.id;
            this.origin = segment.orig;
            this.destin = segment.dest;
            this.origDescription = segment.origDescription;
            this.destDescription = segment.destDescription;
            this.departure = departure.toISOString();
            this.arrival = arrival.toISOString();
            this.departureTime = departure.format('HH:mm');
            this.arrivalTime = arrival.format('HH:mm');
            this.departureDate = departure.format('dddd, DD.MM.YYYY');
            this.nextDay = nextDay > 0;
        }
    }
    return Flight;
}());
export default Flight;
