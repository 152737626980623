import { regex } from 'utils/validators';
import { maxLength, numeric, required } from 'vuelidate/lib/validators';
var validateDimensions = function (value) {
    return value.input.length > 0 ? regex('dimensions')(value.input) : true;
};
var Step2 = /** @class */ (function () {
    function Step2() {
        this.form = {
            additionalInfo: '',
            dangerous: false,
            dimensions: {
                height: '',
                input: '',
                length: '',
                width: '',
            },
            natureOfGoods: '',
            totalPieces: '',
            totalVolume: '',
            totalWeight: ''
        };
        this.errorMsg = {
            additionalInfo: {
                allowedCharacters: 'cargoForm.step2.fields.additionalInfo.validation.allowedCharacters',
                maxLength: 'cargoForm.step2.fields.additionalInfo.validation.maxLength',
            },
            dimensions: {
                dimensions: 'cargoForm.step2.fields.dimensions.validation.dimensions',
            },
            natureOfGoods: {
                allowedCharacters: 'cargoForm.step2.fields.natureOfGoods.validation.allowedCharacters',
                maxLength: 'cargoForm.step2.fields.natureOfGoods.validation.maxLength',
                required: 'cargoForm.step2.fields.natureOfGoods.validation.required',
            },
            totalPieces: {
                numeric: 'cargoForm.step2.fields.totalPieces.validation.numeric',
            },
            totalVolume: {
                numeric: 'cargoForm.step2.fields.totalVolume.validation.numeric',
            },
            totalWeight: {
                numeric: 'cargoForm.step2.fields.totalWeight.validation.numeric',
            },
        };
        this.validations = {
            form: {
                additionalInfo: {
                    allowedCharacters: regex('alphaNumberSpaceDashCommaDot'),
                    maxLength: maxLength(1300),
                },
                dimensions: {
                    dimensions: validateDimensions,
                },
                natureOfGoods: {
                    allowedCharacters: regex('alphaNumberSpaceDashCommaDot'),
                    maxLength: maxLength(1300),
                    required: required,
                },
                totalPieces: {
                    numeric: numeric,
                },
                totalVolume: {
                    numeric: numeric,
                },
                totalWeight: {
                    numeric: numeric,
                },
            },
        };
    }
    return Step2;
}());
export default Step2;
