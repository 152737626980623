import isArray from 'lodash-es/isArray'
import divolteGroupBooking from 'services/AirBaltic/Divolte/GroupBooking'

const defaultState = {
  loadingStatus: false,
  departureFlight: [],
  returnFlight: []
}

const Step2 = {
  namespaced: true,
  state: defaultState,
  mutations: {
    setDepartureFlight (state, value) {
      state.departureFlight = value
    },
    setReturnFlight (state, value) {
      state.returnFlight = value
    }
  },
  actions: {
    clearStep ({ commit }) {
      commit('setDepartureFlight', [])
      commit('setReturnFlight', [])
    },
    getData ({ state }) {
      const data = {
        returnFlights: isArray(state.returnFlight) ? state.returnFlight : [state.returnFlight],
        departureFlights: isArray(state.departureFlight) ? state.departureFlight : [state.departureFlight]
      }

      return Promise.resolve(data)
    },
    flightSelection ({ rootState }) {
      divolteGroupBooking.flightSelection(rootState.groupBooking)
    }
  }
}

export default Step2
