import axios from 'axios'
import isEmpty from 'lodash-es/isEmpty'
import handleError from 'utils/SentryErrorHandler'

export default class Request {
  getBaseUrl() {
    return ''
  }

  createRequest(customHeaders) {
    const REQUEST_TIMEOUT = 60000
    const headers = {
      ...customHeaders,
      Accept: 'application/json'
    }

    return axios.create({
      timeout: REQUEST_TIMEOUT,
      withCredentials: true,
      responseType: 'json',
      responseEncoding: 'utf8',
      baseURL: this.getBaseUrl(),
      headers
    })
  }

  post(path, data = {}, headers = {}) {
    let params = {}

    if (!isEmpty(data) || (data instanceof FormData && data.has('request'))) {
      params = data
    }

    return this.doRequest('post', path, params, headers)
  }

  put(path, data = {}, headers = {}) {
    let params = {}

    if (!isEmpty(data)) {
      params = data
    }

    return this.doRequest('put', path, params, headers)
  }

  get(path, data = {}, headers = {}) {
    let params = {}

    if (!isEmpty(data)) {
      params = {
        params: data
      }
    }

    return this.doRequest('get', path, params, headers)
  }

  delete(path, data = {}, headers = {}) {
    let params = {}

    if (!isEmpty(data)) {
      params = {
        params: data
      }
    }

    return this.doRequest('delete', path, params, headers)
  }

  doRequest(method, path, params, headers) {
    const request = this.createRequest(headers)

    return request[`${method}`](path, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        handleError(error)
        return Promise.reject(error)
      })
  }
}
