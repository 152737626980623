import MealsPageStore from 'store/modules/Pages/MealsPage'
import store from 'definitions/store'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule(name, MealsPageStore)

            Init.initDefaultValues(name)
        }
    },
    initDefaultValues(name) {
        if (typeof mealsInit !== "undefined") {
            store.commit(`${name}/setPageTitle`, mealsInit.title)
            store.commit(`${name}/setPleaseNote`, mealsInit.pleaseNote)
            store.commit(`${name}/setEconomyClassText`, mealsInit.economyClass)
            store.commit(`${name}/setBusinessClassText`, mealsInit.businessClass)
            store.commit(`${name}/setEconomyData`, mealsInit.economy)
            store.commit(`${name}/setBusinessData`, mealsInit.business)
            store.commit(`${name}/setMenuItems`, mealsInit.menuItems)
            store.commit(`${name}/setMenuData`, mealsInit.menuData)

            if (mealsInit.showTermsAndConditions && typeof termsAndConditions !== "undefined") {
                store.commit(`${name}/setTermsAndConditionsVisible`, true)
                store.commit(`${name}/setTermsAndConditions`, {
                    title: termsAndConditions.title.text,
                    content: termsAndConditions.content.text
                })
            }
        }
    }
}

export default Init
