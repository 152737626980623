import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import Step2Modal from 'models/Pax/CargoForm/Step2'

const step2Modal = new Step2Modal()
const formState = Object.assign({}, { formErrors: step2Modal.errorMsg }, step2Modal)

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return formState
    }
  },
  validations () {
    return {
      state: step2Modal.validations
    }
  }
})

const Step2 = {
  namespaced: true,
  state: formState,
  mutations: {
    setNatureOfGoods (state, value) {
      state.form.natureOfGoods = value
    },
    setTotalWeight (state, value) {
      state.form.totalWeight = value
    },
    setTotalPieces (state, value) {
      state.form.totalPieces = value
    },
    setTotalVolume (state, value) {
      state.form.totalVolume = value
    },
    setDimensions (state, value) {
      state.form.dimensions.input = value
    },
    setDangerous (state, value) {
      state.form.dangerous = value
    },
    setLength (state, value) {
      state.form.dimensions.length = value
    },
    setWidth (state, value) {
      state.form.dimensions.width = value
    },
    setHeight (state, value) {
      state.form.dimensions.height = value
    },
    setAdditionalInfo (state, value) {
      state.form.additionalInfo = value
    },
  },
  actions: {
    setDimensions ({ commit }, value) {
      commit('setLength', '')
      commit('setWidth', '')
      commit('setHeight', '')
      commit('setDimensions', value)

      const data = value.split('x')

      if (data.length === 3) {
        if (data[0] !== '' && data[3] !== '' && data[2] !== '') {
          commit('setLength', data[0])
          commit('setWidth', data[1])
          commit('setHeight', data[2])
        }
      }
    },
    clearStep ({ commit }) {
      validator.$v.state.form.$reset()

      commit('setNatureOfGoods', '')
      commit('setTotalWeight', 0)
      commit('setTotalPieces', 0)
      commit('setTotalVolume', 0)
      commit('setLength', '')
      commit('setWidth', '')
      commit('setHeight', '')
      commit('setAdditionalInfo', '')
    },
    validateStep () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        return !vState.$invalid ? resolve() : reject()
      })
    },
    getData ({ state }) {
      const data = {
        additionalInfo: state.form.additionalInfo,
        dimensions: {
          height: state.form.dimensions.height || 0,
          length: state.form.dimensions.length || 0,
          width: state.form.dimensions.width || 0
        },
        natureOfGoods: state.form.natureOfGoods || 0,
        totalPieces: state.form.totalPieces || 0,
        totalVolume: state.form.totalVolume || 0,
        totalWeight: state.form.totalWeight || 0,
        dangerousGoods: state.form.dangerous === 'Yes',
      }

      return Promise.resolve(data)
    }
  },
  getters: {
    $v () {
      return Object.assign({}, validator.$v.state.form)
    },
    step2FormErrors (state) {
      return state.formErrors
    }
  }
}

export default Step2
