export default {
  namespaced: true,
  state: {
    pnr: '',
    surname: '',
    redirectBack: false,
    url: ''
  },
  mutations: {
    setPnr (state, value) {
      state.pnr = value
    },
    setSurname (state, value) {
      state.surname = value
    },
    setRedirect (state, value) {
      state.redirectBack = value
    },
    setPathToCss (state, value) {
      state.url = value
    }
  },
  actions: {
    clearData ({ commit }) {
      commit('setPnr', '')
      commit('setSurname', '')
      commit('setRedirect', false)
      commit('setPathToCss', '')
    }
  }
}