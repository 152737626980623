<template>
  <ul :class="['form-errors', iconClass]">
    <slot />
  </ul>
</template>

<script>
  export default {
    props: {
      showIcon: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      iconClass () {
        return !this.showIcon ? 'no-icon' : ''
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import "~assets/styles/common.scss";

  @include bp-mf-tablet {

    .slideInOut-enter-active,
    .slideInOut-leave-active {
      animation: all 0.4s ease;
      max-height: 230px;
      overflow: hidden;
    }

    .slideInOut-enter,
    .slideInOut-leave-to {
      max-height: 0;
      overflow: hidden;
    }
  }

  .form-errors {
    margin: 0 0 16px;
    padding: 10px 20px;
    background-color: #f7e7e7;
    font-size: em(12);
    line-height: 16px;
    list-style: none;
    color: $red-dark;

    @include bp-mf-tablet {
      position: relative;
      padding: 15px 12px 15px 55px;
      font-size: em(14);
    }

    &:before {
      content: $icon-error-triangle;
      @include icon-font();
      display: none;
      position: absolute;
      top: calc(50% - 10px);
      left: 12px;
      font-size: em(20);
      color: #d72918;

      @include bp-mf-tablet {
        display: block;
      }
    }

    &.no-icon {
      border: 1px solid hexToRGBA(#000000, 0.08);
      border-radius: 3px;
      padding: 12px 16px;
      text-align: center;
      line-height: 18px;

      &:before {
        content: none;
      }
    }

    li:not(:last-child) {
      line-height: 18px;
      margin-bottom: 3px;
    }
  }
</style>
