import Vue from 'vue'
import cloneDeep from 'lodash-es/cloneDeep'
import { validationMixin } from 'vuelidate'
import Contact from 'models/Payments/Contact'
import ContactSection from 'models/Payments/Sections/Contact'

const contact = new Contact()
const formData = {
  formErrors: contact.errorMsg,
  form: contact,
  excluded: []
}
const defaultState = Object.assign({}, new ContactSection(), formData)
const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations () {
    const validation = cloneDeep(contact.validation)
    const excluded = Object.values(module.state.excluded)

    if (Object.values(validation).length > 0) {
      excluded.forEach((exclude) => { delete validation.form[`${exclude}`] })
    }

    return { state: validation }
  }
})

const module = {
  namespaced: true,
  state: defaultState,
  actions: {
    setContactFormData ({ commit }, data) {
      commit('setContactInformationTitle', data.contactInformationTitle)
      commit('setContactInformationDescription', data.contactInformationDescription)
    },
    setSurname ({ commit }, value) {
      commit('setLastName', value.trim().toUpperCase())
    },
    validateContacts () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('contact')
      })
    },
    clearContacts ({ commit }) {
      commit('resetForm')
      validator.$v.state.form.$reset()
    }
  },
  mutations: {
    setExcludedFields (state, value) {
      state.excluded = value
    },
    setContactInformationTitle (state, value) {
      state.contactInformationTitle = value
    },
    setContactInformationDescription (state, value) {
      state.contactInformationDescription = value
    },
    setFirstName (state, value) {
      state.form.firstName = value
    },
    setLastName (state, value) {
      state.form.lastName = value
    },
    setEmail (state, value) {
      state.form.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setTitle (state, value) {
      state.form.title = value
    },
    resetForm (state) {
      state.form = new Contact()
    }
  },
  getters: {
    $contact () {
      return Object.assign({}, validator.$v.state.form)
    },
    contactValidationFormErrors (state) {
      return state.formErrors
    }
  }
}

export default module
