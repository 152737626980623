import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { regex } from 'utils/validators'
import moment from 'moment'
import orderBy from 'lodash-es/orderBy'
import Flights from 'store/modules/Pages/Claims/Sections/Flights'

const formErrors = {
  pir: {
    required: 'reusable.baggage.pir.validation.missing',
    maxLength: 'reusable.baggage.pir.validation.maxLength',
    invalid: 'reusable.baggage.pir.validation.invalid'
  },
  hasReceived: {
    required: 'reusable.baggage.baggageReceived.validation.missing',
    selectedFlightToEarly:
      'reusable.baggage.baggageReceived.validation.selectedFlightToEarly'
  },
  receiptAvailable: {
    required: 'reusable.baggage.originalReceipt.validation.available'
  }
}

const defaultState = {
  hasPir: true,
  pir: '',
  hasReceived: null,
  receiptAvailable: null,
  receiptSectionNeeded: false,
  formErrors: formErrors
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    const data = {
      pir: {
        required,
        maxLength: maxLength(10),
        invalid: regex('anyBaggageNumber')
      },
      hasReceived: {
        required,
        selectedFlightToEarly: (value) => {
          if (value !== 'no') {
            return true
          }

          const selectedFlights = Flights.state.selectedFlights
          if (selectedFlights.length) {
            const orderedList = orderBy(
              selectedFlights,
              (o) => o.flightDate.format('YYYYMMDD'),
              ['desc']
            )
            const diff = moment().diff(orderedList[0].flightDate, 'minutes')

            if (diff >= 0 && diff <= 5 * 24 * 60) {
              return false
            }

            return true
          }

          return true
        }
      },
      receiptAvailable: {
        required: requiredIf(() => this.state.receiptSectionNeeded)
      }
    }

    if (!this.state.hasPir) {
      delete data.pir.required
    }

    return { state: data }
  }
})

const BaggageForm = {
  state: defaultState,
  mutations: {
    setHasPir(state, value) {
      state.hasPir = value
    },
    setPir(state, value) {
      state.pir = value
    },
    setReceivedBaggage(state, value) {
      state.hasReceived = value
    },

    setReceiptSection(state, value) {
      state.receiptSectionNeeded = value
    },

    setReceiptAvailable(state, value) {
      state.receiptAvailable = value
    },

    reset(state) {
      state.hasPir = true
      state.pir = ''
      state.hasReceived = null
      state.receiptAvailable = null
      state.receiptSectionNeeded = false
    }
  },

  actions: {
    touch() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('baggage')
      })
    },

    clearForm({ commit }) {
      validator.$v.state.$reset()
      commit('reset')
    },

    clearBaggageRef({ commit }) {
      validator.$v.state.pir.$reset()
      commit('setPir', '')
    }
  },

  getters: {
    $baggage() {
      return Object.assign({}, validator.$v.state)
    }
  },

  namespaced: true
}

export default BaggageForm
