import GTM from 'services/AirBaltic/GTM'
import Vue from 'vue'

class HeaderMenu {
  static push(data) {
    GTM.doDataLayerPush(data)
  }

  static categoryClick(title) {
    const lang = Vue.i18n.locale()
    const currentUrl = window.location.href

    this.push({
      event: 'GAevent',
      eventCategory: 'All pages',
      eventAction: 'Header links',
      eventLabel: 'T: Main; Link: ' + title + '; Page: ' + currentUrl + '; Lang: ' + lang,
      nonInteraction: 0
    })
  }

  static subCategoryClick(cat, link) {
    const lang = Vue.i18n.locale()
    const currentUrl = window.location.href

    this.push({
      event: 'GAevent',
      eventCategory: 'All pages',
      eventAction: 'Header links',
      eventLabel: `T: Sub-category; Cat: ${cat}; Link: ${link}'; Page: ${currentUrl}; Lang: ${lang}; nonInteraction: 0`
    })
  }

  static headerCategoryClick(int_name, url, menu_title) {
    const lang = Vue.i18n.locale()

    this.push({
      event: 'header_clicks',
      generic_event_name: 'header_tabs_clicks',
      eventCategory: menu_title,
      eventAction: int_name,
      site_language: lang,
      eventLabel: url
    })
  }

  static headerSubCategoryClick(int_name, url, menu_title) {
    const lang = Vue.i18n.locale()

    this.push({
      event: 'header_clicks',
      generic_event_name: 'header_subcategory_clicks',
      eventCategory: menu_title,
      eventAction: int_name,
      site_language: lang,
      eventLabel: url
    })
  }

  static headerMenuItemClick(int_name, url, menu_title) {
    const lang = Vue.i18n.locale()

    this.push({
      event: 'header_clicks',
      generic_event_name: 'header_items_clicks',
      eventCategory: menu_title,
      eventAction: int_name,
      site_language: lang,
      eventLabel: url
    })
  }

  static headerFlightStatusClick(int_name, url, menu_title) {
    const lang = Vue.i18n.locale()

    this.push({
      event: 'header_clicks',
      generic_event_name: 'flight_status',
      eventCategory: menu_title,
      eventAction: int_name,
      site_language: lang,
      eventLabel: url
    })
  }

  static headerSearchLinkClick(int_name, url, menu_title) {
    const lang = Vue.i18n.locale()

    this.push({
      event: 'header_clicks',
      generic_event_name: 'didnt_find_what_you_are_looking_for',
      eventCategory: menu_title,
      eventAction: int_name,
      site_language: lang,
      eventLabel: url
    })
  }

  static headerBlogCardClick(title, url) {
    const lang = Vue.i18n.locale()

    this.push({
      event: 'header_clicks',
      generic_event_name: 'header_subcategory_blog_cards',
      eventCategory: title,
      eventLabel: url,
      site_language: lang
    })
  }

  static headerAdStoreClick(url, type) {
    const lang = Vue.i18n.locale()

    this.push({
      event: 'header_clicks',
      generic_event_name: 'header_subcategory_bt_club_cards',
      eventCategory: 'airbaltic_app_ad',
      eventLabel: url,
      eventAction: type,
      site_language: lang
    })
  }
}

export default HeaderMenu
