import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'

const missingPrefix = 'cargoShipping.form.prefix.errors.missingPrefix'
const missingNumber = 'cargoShipping.form.number.errors.missingNumber'
const invalidPrefix = 'cargoShipping.form.prefix.errors.invalidPrefix'
const invalidNumber = 'cargoShipping.form.number.errors.invalidNumber'
const invalidPrefixLength = 'cargoShipping.form.prefix.errors.invalidPrefixLength'
const invalidNumberLength = 'cargoShipping.form.number.errors.invalidNumberLength'

const defaultState = {
  title: '',
  description: '',
  form: {
    prefix: '',
    number: ''
  },
  prefixErrors: [],
  numberErrors: [],
  formValid: false
}

const valueLength = function (size) {
  return function (value) {
    return value != null && value !== 'undefined' && value.length === size
  }
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    return {
      state: {
        form: {
          prefix: {
            required,
            length: valueLength(3),
            containsNumber: numeric
          },
          number: {
            required,
            length: valueLength(8),
            containsNumber: numeric
          }
        }
      }
    }
  }
})

const CargoShippingStore = {
  state: defaultState,
  mutations: {
    setTitle(state, value) {
      state.title = value
    },
    setDescription(state, value) {
      state.description = value
    },
    setPrefix(state, value) {
      state.form.prefix = value
    },
    setNumber(state, value) {
      state.form.number = value
    },
    setFormValid(state) {
      state.formValid = state.prefixErrors.length === 0 && state.numberErrors.length === 0
    },
    setPrefixErrors(state, value) {
      state.prefixErrors = value
    },
    setNumberErrors(state, value) {
      state.numberErrors = value
    }
  },

  actions: {
    setCargoShipping({ commit }, data) {
      commit('setTitle', data.title)
      commit('setDescription', data.description)
      commit('setPrefix', data.prefix)
      commit('setNumber', data.number)
    },
    validate({ dispatch }) {
      validator.$v.state.form.$touch()
      return new Promise((resolve) => {
        dispatch('validatePrefix')
        dispatch('validateNumber')
        resolve()
      })
    },
    resetForm({ commit }) {
      commit('setPrefixErrors', [])
      commit('setNumberErrors', [])
      commit('setFormValid')
      commit('setNumber', '')
      commit('setPrefix', '')
      validator.$v.state.$reset()
    },
    validatePrefix({ commit }) {
      validator.$v.state.form.prefix.$touch()
      const vState = validator.$v.state
      const errors = []

      if (!vState.form.prefix.required) {
        errors.push(missingPrefix)
      } else if (!vState.form.prefix.containsNumber) {
        errors.push(invalidPrefix)
      } else if (!vState.form.prefix.length) {
        errors.push(invalidPrefixLength)
      }

      commit('setPrefixErrors', errors)
      commit('setFormValid')
    },
    validateNumber({ commit }) {
      validator.$v.state.form.number.$touch()
      const vState = validator.$v.state
      const errors = []

      if (!vState.form.number.required) {
        errors.push(missingNumber)
      } else if (!vState.form.number.containsNumber) {
        errors.push(invalidNumber)
      } else if (!vState.form.number.length) {
        errors.push(invalidNumberLength)
      }

      commit('setNumberErrors', errors)
      commit('setFormValid')
    },

    submitForm({ state }) {
      return new Promise((resolve) => {
        if (!state.formValid) {
          return resolve(false)
        }

        window.open(
          `https://cargotrack.airbaltic.com/?p=${state.form.prefix}&s=${state.form.number}`,
          '',
          'height=500,width=1200,left=150,top=150,scrollbars=yes,location=no'
        )
        resolve(true)
      })
    }
  },

  getters: {
    $v() {
      return Object.assign({}, validator.$v.state)
    },
    genericErrors(state) {
      let errors = []
      const prefixErrors = state.prefixErrors
      const numberErrors = state.numberErrors
      errors = errors.concat(prefixErrors, numberErrors)

      return errors
    }
  },

  namespaced: true
}

export default CargoShippingStore
