import CookieUtils from 'utils/CookieUtils'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'
import {
  getExpirationTime,
  renewUserToken,
  logoutUser
} from 'services/AirBaltic/JwtRenew'
import conf from 'conf'
import Vue from 'vue'
import * as Sentry from '@sentry/vue'

const getRandomTime = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min)
}
const minuteInMilliseconds = 60 * 1000
const getTokenRefreshTime = getRandomTime(
  3 * minuteInMilliseconds,
  5 * minuteInMilliseconds
)

const userStore = {
  state: {
    isUserLogged: false,
    pinsConnected: false,
    userData: {
      title: '',
      firstName: '',
      lastName: '',
      pinsNumber: '',
      email: '',
      img: '',
      clean: false
    }
  },

  mutations: {
    setUserLoggedIn(state, value) {
      state.isUserLogged = value
    },
    setPinsConnected(state, value) {
      state.pinsConnected = value
    },
    setTitle(state, value) {
      state.userData.title = value
    },
    setFirstName(state, value) {
      state.userData.firstName = value
    },
    setLastName(state, value) {
      state.userData.lastName = value
    },
    setPinsNumber(state, value) {
      state.userData.pinsNumber = value
    },
    setEmail(state, value) {
      state.userData.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setClean(state, value) {
      state.userData.clean = value === true
    },
    setImage(state, image) {
      state.userData.img = image
    }
  },

  actions: {
    setUserData({ commit }, userData) {
      let image = ''
      const imageUserData = get(userData, 'img', null)
      const imageFromCookie = decodeURIComponent(CookieUtils.getCookie('user_img'))

      commit('setTitle', get(userData, 'title', ''))
      commit('setFirstName', get(userData, 'firstName', ''))
      commit('setLastName', get(userData, 'lastName', ''))
      commit('setPinsNumber', get(userData, 'pinsNumber', ''))
      commit('setEmail', get(userData, 'email', ''))
      commit('setClean', get(userData, 'clean', false))

      if (!isEmpty(imageUserData)) {
        image = imageUserData
      } else if (!isEmpty(imageFromCookie)) {
        image = imageFromCookie
      }
      commit('setImage', image)
    },
    init({ commit, dispatch }, userData) {
      dispatch('setUserData', userData)
      commit('setUserLoggedIn', true)

      dispatch('setJWTExpirationCheckTimer')
    },
    setJWTExpirationCheckTimer({ state, dispatch }) {
      const refreshTime = getExpirationTime() - Math.abs(getTokenRefreshTime)
      clearTimeout()

      if (!state.isUserLogged) {
        return
      }

      if (refreshTime < 0) {
        dispatch('renewToken')
      } else {
        setTimeout(() => dispatch('setJWTExpirationCheckTimer'), minuteInMilliseconds)
      }
    },
    renewToken({ dispatch }) {
      return renewUserToken()
        .then(() => dispatch('setJWTExpirationCheckTimer'))
        .catch((e) => {})
    },
    logout({ dispatch }) {
      return logoutUser()
        .catch((e) => Sentry.captureException(e))
        .finally(() => dispatch('showModal'))
    },
    showModal({ dispatch, commit }) {
      const props = {
        errors: {
          title: Vue.i18n.translate('loginPage.errorModalTitle'),
          content: Vue.i18n.translate('expiredModalText'),
          showIcon: false
        },
        close: () => {
          commit('setUserLoggedIn', false)
          dispatch('main/closeModal', null, { root: true })
        },
        showButton: false
      }

      const ErrorNotification = import(
        /* webpackChunkName: "error-notification" */ 'components/Notifications/ErrorNotification'
      )

      ErrorNotification.then((result) => {
        commit('main/setModalContent', result.default, { root: true })
        commit('main/setModalProps', props, { root: true })
      })

      dispatch('main/openModal', null, { root: true })
      commit('main/setShowModalHeaderWhite', false, { root: true })
      commit('main/setShowModalHeader', false, { root: true })
      commit('main/setShowCloseIcon', true, { root: true })
      commit('main/setModalTitle', ' ', { root: true })
      commit('main/setModalClass', 'modal-white-centered', { root: true })
      commit(
        'main/setModalCloseFn',
        () => {
          commit('setUserLoggedIn', false)
          dispatch('main/closeModal', null, { root: true })
        },
        { root: true }
      )
    },
    clearUserData({ commit, dispatch }) {
      commit('setUserLoggedIn', false)
      dispatch('setUserData', {})

      CookieUtils.setCookie('user_img', '', -1)
      CookieUtils.setCookie(conf.jwtCookieName, '', -1)
    }
  },

  namespaced: true
}

export default userStore
