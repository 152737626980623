const ContactUsPage = {
  state: {
    pageTitle: '',
    description: '',
    accordionItems: []
  },

  mutations: {
    setPageTitle (state, value) {
      state.pageTitle = value
    },

    setDescription (state, value) {
      state.description = value
    },

    setAccordionItems (state, value) {
      state.accordionItems = value
    }
  },

  namespaced: true
}

export default ContactUsPage
