const SidebarPage = {
  state: {
    pageLeadImgDesktop: '',
    pageLeadImgTablet: '',
    pageLeadImgMobile: '',
    pageTitle: '',
    intro: '',
    sections: [],
    printEnabled: false,
    termsAndConditionsVisible: false,
    termsAndConditions: {}
  },
  mutations: {
    setPageLeadImgDesktop (state, value) {
      state.pageLeadImgDesktop = value
    },
    setPageLeadImgTablet (state, value) {
      state.pageLeadImgTablet = value
    },
    setPageLeadImgMobile (state, value) {
      state.pageLeadImgMobile = value
    },
    setPageTitle (state, value) {
      state.pageTitle = value
    },
    setIntro (state, value) {
      state.intro = value
    },
    setSections (state, value) {
      state.sections = value
    },

    setPrintEnabled (state, value) {
      state.printEnabled = value
    },

    setTermsAndConditionsVisible (state, value) {
      state.termsAndConditionsVisible = value
    },

    setTermsAndConditions (state, value) {
      state.termsAndConditions = value
    }
  },
  namespaced: true
}

export default SidebarPage
