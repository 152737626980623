import FooterStore from 'store/modules/Footer/Footer'
import store from 'definitions/store'
import CookieUtils from 'utils/CookieUtils'
import axios from 'axios'
import Storyblok from '../storyblok'
import conf from 'conf'

Storyblok.init()

const Init = {
  init(name) {
    if (!store.hasModule(name)) {
      store.registerModule(name, FooterStore)
    }

    Init.initCookieAlert()
    Init.initBaseFooter()

    if (conf.storyblokEnabled) {
      Init.initStoryblokFooterItems(name)
      Init.initStoryblokFooterFaceliftLinks(name)
    } else {
      Init.initFooter()
      Init.initFooterFaceliftLinks(name)
    }
  },
  initBaseFooter() {
    if (store.state.main.pageType === 'index') {
      store.commit('footer/setShowScrollButton', false)
    }

    if (typeof subscribePopupData !== 'undefined' && subscribePopupData.length) {
      store.commit('footer/setSubscribePopupData', subscribePopupData[0])
    }

    if (typeof disableSubscribePopup !== 'undefined') {
      store.commit(`footer/setDisableSubscribePopup`, disableSubscribePopup)
    }
  },
  async initStoryblokFooterItems(name) {
    try {
      const language = store.state.main.selectedLang
      const footerLinks = await Storyblok.footerNavigation(language)

      store.commit(`${name}/setFooterItems`, footerLinks)
    } catch (error) {
      console.error('main-navigation/footer-navigation could not be loaded', error)
    }
  },
  async initStoryblokFooterFaceliftLinks(name) {
    const language = store.state.main.selectedLang
    const country = store.state.main.selectedCountry

    try {
      const {
        social,
        footerSemantics,
        footerAwards,
        contactUs,
        legalLinks,
        copyright,
        googlePrivacyPolicy,
        paymentLogos
      } = await Storyblok.mainFooter(language, country)

      store.commit(`${name}/setFooterContactUs`, contactUs)
      store.commit(`${name}/setFooterLegalLinks`, legalLinks)
      store.commit(`${name}/setSocial`, social)
      store.commit(`${name}/setCopyright`, copyright)
      store.commit(`${name}/setSemantics`, footerSemantics)
      store.commit(`${name}/setAwards`, footerAwards)
      store.commit(`${name}/setGooglePrivacyPolicyText`, googlePrivacyPolicy)
      store.commit(`${name}/setPaymentLogos`, paymentLogos)
    } catch (error) {
      console.error('main-navigation/main-footer could not be loaded', error)
    }
  },
  initCookieAlert() {
    if (typeof cookieContent !== 'undefined') {
      store.commit('main/setCookieAlertContent', cookieContent)

      const value = CookieUtils.getCookie('gdpr_settings')
      const split = value.split(',')
      const hasValue = ['fnc', 'sts', 'web', 'adv', '0', '1', '2', '3'].some((item) =>
        split.includes(item)
      )

      const hideAlert = value && hasValue
      store.commit('main/setHideCookieAlert', hideAlert)

      if (hasValue) {
        let cookieValue = split
        if (split.includes('0')) {
          cookieValue = ['fnc', 'web', 'adv']
        } else if (split.includes('1')) {
          cookieValue = ['fnc', 'web']
        } else if (split.includes('2')) {
          cookieValue = ['fnc', 'adv']
        } else if (split.includes('3')) {
          cookieValue = ['fnc']
        }

        store.dispatch('main/setGdprCookie', cookieValue)
        CookieUtils.setCookie('2e_send_GA', cookieValue.includes('sts'), 365)
      }
    }

    if (
      store.state.main.pageType === 'privacypolicy-white' ||
      store.state.main.pageType === 'cookie_policy'
    ) {
      store.commit('main/setHideCookieAlert', true)
    }
  },
  initFooter() {
    if (typeof googlePrivacyPolicyText !== 'undefined') {
      store.commit('footer/setGooglePrivacyPolicyText', googlePrivacyPolicyText)
    }

    if (typeof footerSemantics !== 'undefined') {
      store.commit('footer/setSemantics', footerSemantics)
    }
  },
  initFooterFaceliftLinks(name) {
    axios
      .get(`/get-wide-footer-facelift-links/${store.state.main.selectedLang}/`)
      .then((response) => {
        store.commit(`${name}/setFooterLinks`, response.data)
      })
      .catch((e) => console.error('Error fetching footer links', e))
  }
}

export default Init
