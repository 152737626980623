import { alphaNumWithSpaces, postalCode, regex } from 'utils/validators';
import { required, maxLength } from 'vuelidate/lib/validators';
var Step3 = /** @class */ (function () {
    function Step3() {
        this.showBillingDetails = false;
        this.form = {
            address: '',
            city: '',
            country: '',
            name: '',
            postalCode: '',
            regNo: '',
            vatNo: '',
        };
        this.errorMsg = {
            address: {
                allowedCharacters: 'reusable.street.validation.allowedCharacters',
                required: 'reusable.street.validation.required',
            },
            city: {
                alphaNumWithSpaces: 'reusable.city.validation.alphaWithSpaces',
                required: 'reusable.city.validation.required',
            },
            country: {
                required: 'reusable.country.validation.required',
            },
            name: {
                required: 'reusable.company.validation.required',
            },
            postalCode: {
                postalCode: 'reusable.postalCode.validation.invalid',
                required: 'reusable.postalCode.validation.required',
                maxLength: 'reusable.postalCode.validation.tooLong'
            },
            regNo: {
                alphaNumWithSpaces: 'reusable.regNumber.validation.allowedCharacters',
                required: 'reusable.regNumber.validation.required',
            },
            vatNo: {
                alphaNumWithSpaces: 'reusable.vatNumber.validation.allowedCharacters',
            },
        };
        this.validations = {
            form: {
                address: {
                    allowedCharacters: regex('alphaNumberSpaceDashCommaDotSlash'),
                    required: required,
                },
                city: {
                    alphaNumWithSpaces: alphaNumWithSpaces,
                    required: required,
                },
                country: {
                    required: required,
                },
                name: {
                    required: required,
                },
                postalCode: {
                    postalCode: postalCode,
                    required: required,
                    maxLength: maxLength(60)
                },
                regNo: {
                    alphaNumWithSpaces: alphaNumWithSpaces,
                    required: required,
                },
                vatNo: {
                    alphaNumWithSpaces: alphaNumWithSpaces,
                },
            },
        };
    }
    return Step3;
}());
export default Step3;
