import { alphaWithSpaces } from 'utils/validators';
import { email, numeric, required } from 'vuelidate/lib/validators';
var Step3 = /** @class */ (function () {
    function Step3() {
        this.showBillingDetails = false;
        this.form = {
            email: '',
            firstName: '',
            intCode: '',
            lastName: '',
            phoneNumber: ''
        };
        this.errorMsg = {
            email: {
                email: 'reusable.email.validation.invalid',
                required: 'reusable.email.validation.missing'
            },
            firstName: {
                alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
                required: 'reusable.firstName.validation.required'
            },
            intCode: {
                required: 'reusable.intCodeWithPhone.validation.intCode.required'
            },
            lastName: {
                alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
                required: 'reusable.lastName.validation.required'
            },
            phoneNumber: {
                numeric: 'reusable.intCodeWithPhone.validation.phoneNumber.notNumeric',
                required: 'reusable.intCodeWithPhone.validation.phoneNumber.required'
            }
        };
        this.validations = {
            form: {
                email: {
                    email: email,
                    required: required
                },
                firstName: {
                    alphaWithSpaces: alphaWithSpaces,
                    required: required
                },
                intCode: {
                    required: required
                },
                lastName: {
                    alphaWithSpaces: alphaWithSpaces,
                    required: required
                },
                phoneNumber: {
                    numeric: numeric,
                    required: required
                }
            }
        };
    }
    return Step3;
}());
export default Step3;
