<template>
  <div>
    <div class="promo-popup-wrapper">
      <div class="content">
        <div class="close">
          <close-icon @click="onClose" />
        </div>
        <div
          v-if="discount"
          class="discount-tag"
        >
          -{{ discount }}
        </div>
        <div class="header">
          <v-lazy-image
            v-if="image"
            class="image"
            :src="image.source"
            :alt="image.alt"
            :height="$_ImageHelpers_deviceType === 'mobile' ? 208 : 240"
          />
        </div>
        <div class="body">
          <h3 class="title">
            {{ title }}
          </h3>
          <div
            v-dompurify-html="description"
            class="text"
          />
          <div class="btn btn-blue">
            <a
              :href="cta.href"
              :target="cta.target"
              @click="onAction"
            >
              {{ cta.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CloseIcon from 'components/CloseIcon'
  import { mapActions } from 'vuex'
  import VLazyImage from 'v-lazy-image'
  import imageHelpers from 'utils/mixins/ImageHelpers'
  import GTM from 'services/AirBaltic/GTM'

  const EVENT_KEY = 'campaign_promo_popup_events'

  export default {
    name: 'PromoPopupContent',
    components: { CloseIcon, VLazyImage },
    mixins: [imageHelpers],
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    computed: {
      image() {
        const images = this.data.images

        return this.$_ImageHelpers_responsiveImage(images)
      },
      cta() {
        return this.data.cta
      },
      title() {
        return this.data.title
      },
      description() {
        return this.data.description
      },
      discount() {
        return this.data?.discount?.length ? this.data.discount : null
      },
      dataLayerBase() {
        return {
          event: EVENT_KEY,
          event_category: this.data.key,
          page_type: this.data.pageType,
          site_language: this.data.language,
        }
      }
    },
    created() {
      this.pushDataLayer('impression')
    },
    methods: {
      ...mapActions('main', ['closeModal']),
      onAction() {
        this.pushDataLayer('button_click')
        this.closePopup()
      },
      onClose() {
        this.pushDataLayer('closed')
        this.closePopup()
      },
      closePopup() {
        this.setSessionValue()
        this.closeModal()
      },
      pushDataLayer(action) {
        if (!action) {
          return
        }

        GTM.doDataLayerPush({
          ...this.dataLayerBase, event_action: action
        })
      },
      setSessionValue() {
        window.sessionStorage.setItem(`promo_popup_${this.data.id}`, 'closed')
      }
    }
  }
</script>

<style
  scoped
  lang="scss"
>
  @import '~assets/styles/common.scss';

  .promo-popup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .content {
      background: white;
      border-radius: rem(8, 16);
      margin: 0 rem(16, 16);
      position: relative;
      max-width: rem(480, 16);
      width: 100%;

      @include bp-mf-desktop {
        margin: 0;
      }

      .close {
        position: absolute;
        right: rem(16, 16);
        top: rem(16, 16);
        border-radius: 50%;
        background: $bg-light-grey;
      }

      ::v-deep .close-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(32, 16);
        height: rem(32, 16);
        color: $blue-dark;
        font-size: rem(12, 16);
      }
    }

    .discount-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: rem(32, 16);
      top: rem(32, 16);
      width: rem(76, 16);
      height: rem(32, 16);
      font-size: rem(24, 16);
      background: $red-placeholder;
      color: white;
      border-radius: rem(20, 16);
    }

    .header {
      max-height: rem(240, 16);

      .image {
        max-width: rem(480, 16);
        width: 100%;
        object-fit: cover;
        border-top-left-radius: rem(8, 16);
        border-top-right-radius: rem(8, 16);
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: rem(37, 16) rem(16, 16) rem(40, 16);
      color: $blue-dark;

      @include bp-mf-desktop {
        margin: rem(40, 16) rem(32, 16) rem(48, 16);
      }

      .title {
        font-size: rem(24, 16);
        line-height: rem(32, 16);
        text-align: center;
        font-weight: 500;
        margin: 0 0 rem(8, 16);
      }

      .text {
        font-size: rem(16, 16);
        line-height: rem(24, 16);
        text-align: center;
        max-width: rem(340, 16);
        margin: 0 0 rem(32, 16);
      }

      .btn {
        padding: 0 rem(10, 16);
      }
    }
  }
</style>
