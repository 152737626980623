import { email, required } from 'vuelidate/lib/validators';
var EmailChange = /** @class */ (function () {
    function EmailChange() {
        this.email = '';
        this.emailNotActivated = false;
        this.emailInUse = '';
        this.newEmail = '';
        this.defaultEmail = '';
        this.confirmUrl = '';
        this.forgotPasswordResetLink = '';
        this.errorMsg = {
            email: {
                required: 'reusable.email.validation.missing',
                email: 'reusable.email.validation.invalid',
                notInUse: 'reusable.email.validation.emailInUse'
            }
        };
        this.validation = {
            form: {
                email: {
                    required: required,
                    email: email,
                    notInUse: function (value, emailObj) {
                        return emailObj.emailInUse === '' || value !== emailObj.emailInUse;
                    }
                },
                emailInUse: {}
            },
        };
    }
    return EmailChange;
}());
export default EmailChange;
