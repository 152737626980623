import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import Company from 'models/Payments/Company'

const company = new Company()
const defaultState = {
  countries: [],
  showBillingDetails: false,
  form: company,
  formErrors: company.errorMsg
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    return {
      state: company.validation
    }
  }
})

export default {
  namespaced: true,
  state: defaultState,
  actions: {
    validateCompany({ state, dispatch }) {
      if (!state.showBillingDetails) {
        return Promise.resolve(true)
      }

      return dispatch('touchCompany')
    },
    touchCompany() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('company')
      })
    },
    clearCompany({ commit }) {
      commit('setBillingDetails', false)
      commit('clearCompany')
      validator.$v.state.form.$reset()
    }
  },
  mutations: {
    setBillingDetails(state, value) {
      state.showBillingDetails = value
    },
    setCountries(state, value) {
      state.countries = value
    },
    setCompany(state, value) {
      state.form.name = value
    },
    setRegistrationNumber(state, value) {
      state.form.registrationNumber = value
    },
    setVatNumber(state, value) {
      state.form.vatNumber = value
    },
    setAddress(state, value) {
      state.form.address = value
    },
    setCity(state, value) {
      state.form.city = value
    },
    setPostalCode(state, value) {
      state.form.postalCode = value
    },
    setCountryCode(state, value) {
      state.form.countryCode = value
    },
    clearCompany(state) {
      state.form = new Company()
    }
  },
  getters: {
    $company() {
      return Object.assign({}, validator.$v.state.form)
    },
    validationFormErrors(state) {
      return state.formErrors
    }
  }
}
