import store from 'definitions/store'
import EditMyProfilePageInit from 'store/modules/Pages/ProfilePages/EditMyProfilePage'
import LocationService from 'services/AirBaltic/Location/Location'
import * as Sentry from '@sentry/vue'
import PaxProfile from 'services/AirBaltic/Pax/Profile'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, EditMyProfilePageInit)

      Init.initDefaultValues(name)
      Init.initSidebarData()
      Init.initPageType()
      Init.initProfileData(name)
    }
  },

  initProfileData(name) {
    new PaxProfile()
      .getPaxProfileData()
      .then((data) => {
        Init.initPaxDetails(name, data)
        Init.initNotfications(name, data)
      })
      .catch((e) => Sentry.captureException(e))
  },

  initDefaultValues(name) {
    store.commit('main/setFormNamespace', name, { root: true })

    if (typeof pageData !== 'undefined') {
      store.commit(`${name}/generic/setPageData`, pageData)

      if (typeof pageData.deleteSection !== 'undefined') {
        store.commit(`${name}/generic/setDeleteData`, pageData.deleteSection)
      }
    }

    if (typeof confirmUrl !== 'undefined') {
      store.commit(`${name}/form/setConfirmLink`, confirmUrl)
    }

    if (typeof forgotPasswordResetLink !== 'undefined') {
      store.commit(`${name}/form/setForgotPasswordResetLink`, forgotPasswordResetLink)
    }

    if (typeof contentData_data_countries !== 'undefined') {
      store.dispatch(
        `${name}/generic/setCountries`,
        contentData_data_countries._children,
        { root: true }
      )
    }

    const service = new LocationService()

    service
      .getLanguageList()
      .then((data) =>
        store.dispatch(`${name}/generic/setLanguages`, data, { root: true })
      )
      .catch((e) => Sentry.captureException(e))
  },

  initSidebarData() {
    if (typeof pressContacts !== 'undefined') {
      store.commit('sidebar/setPressContacts', pressContacts)
    }
  },

  initPaxDetails(name, userData) {
    store.commit(`${name}/form/setUserData`, userData)
    store.commit(`${name}/form/setLanguage`, userData.language)
    store.commit(`${name}/form/setEmail`, userData.email)
    store.commit(`${name}/form/setResidenceCountry`, userData.countryCode)
  },

  initNotfications(name, userData) {
    store.dispatch(`${name}/setNotification`, {
      platform: 'newsletter',
      connected: userData.subscribeTypes.includes('EMAIL')
    })

    store.dispatch(`${name}/setNotification`, {
      platform: 'smsDeals',
      connected: userData.subscribeTypes.includes('SMS')
    })

    store.dispatch(`${name}/setNotification`, {
      platform: 'whatsapp',
      connected: userData.subscribeTypes.includes('WHATS_APP')
    })
  },

  initPageType() {
    store.commit('main/setPageType', 'profile')
  }
}

export default Init
