import cloneDeep from 'lodash-es/cloneDeep'
import findIndex from 'lodash-es/findIndex'
import find from 'lodash-es/find'
import Irregularities from 'store/modules/Forms/Irregularities'
import SelfServiceService from 'services/AirBaltic/SelfService'
import moment from 'moment'

const SelfService = {
  modules: {
    irregularities: Irregularities
  },
  state: {
    pageData: {},
    generic: {},
    passengers: [],
    segments: [],
    services: [],
    changes: [],
    mapping: [],
    origDestData: {},
    ripTabs: [],
    alerts: [],
    additionalInfo: {},
    medicalInfo: {},
    insurance: [],
    indexUrl: '',
    code: ''
  },
  mutations: {
    addServiceMapping(state, value) {
      state.mapping = value
    },
    setOrigDestData(state, value) {
      state.origDestData = value
    },
    addGeneric(state, value) {
      state.generic = value
    },
    setPnr(state, value) {
      state.generic.pnr = value
    },
    setSurname(state, value) {
      state.generic.surname = value
    },
    setCode(state, value) {
      state.code = value
    },
    addPassengers(state, value) {
      state.passengers = value
    },
    addSegments(state, value) {
      state.segments = value
    },
    addChanges(state, value) {
      state.changes = value
    },
    setIndexUrl(state, value) {
      state.indexUrl = value
    },
    toggleVisibility(state, id) {
      const segments = cloneDeep(state.segments)

      const key = findIndex(segments, ['id', id])
      const item = find(segments, ['id', id])

      item.open = !item.open
      segments[`${key}`] = item

      state.segments = segments
    },
    openTab(state, data) {
      const segments = cloneDeep(state.segments)

      const key = findIndex(segments, ['id', data.id])
      const item = find(segments, ['id', data.id])

      item.tab = data.tab
      segments[`${key}`] = item

      state.segments = segments
    },
    addServices(state, value) {
      state.services = value
    },
    setPageData(state, value) {
      state.pageData = value
    },
    setRipTabs(state, value) {
      state.ripTabs = value
    },
    setAlerts(state, value) {
      state.alerts = value
    },
    setAdditionalInfo(state, value) {
      state.additionalInfo = value
    },
    setMedicalInfo(state, value) {
      state.medicalInfo = value
    },
    setInsurance(state, value) {
      state.insurance = value
    }
  },
  actions: {
    setData({ commit }, data) {
      commit('setInsurance', data.insurance)
      commit('addGeneric', data.generic)
      commit('addPassengers', data.passengers)
      commit('addSegments', data.segments)
      commit('addChanges', data.changes)
      commit('addServices', data.services)
    },
    clearData({ commit }) {
      commit('setInsurance', [])
      commit('addPassengers', [])
      commit('addSegments', [])
      commit('addServices', [])
    },
    getAlerts({ commit, state, rootState }) {
      const service = new SelfServiceService(state, rootState.main.selectedLang)
      const origins = []
      const destins = []
      const departures = []
      const returns = []
      state.segments.forEach((flight) => {
        origins.push(flight.orig)
        destins.push(flight.dest)
        departures.push(moment(flight.departure).format('YYYY-MM-DD'))
        const returnDate = flight.return ? moment(flight.return).format('YYYY-MM-DD') : ''
        if (returnDate) {
          returns.push(returnDate)
        }
      })

      service
        .getAlertsFromPimcore({
          lang: rootState.main.selectedLang,
          country: rootState.main.selectedCountry,
          origin: origins,
          destin: destins,
          departDate: departures,
          returnDate: returns
        })
        .then((data) => {
          commit('setAlerts', data)
        })
        .catch(() => {
          commit('setAlerts', [])
        })
    },

    updatePassengers({ commit, state }, value) {
      const updatePassengers = state.passengers.map((p) => {
        const found =
          value.find(
            (v) => v.pax.firstName === p.firstName && v.pax.lastName === p.lastName
          ) || undefined

        if (found) {
          return {
            ...p,
            phoneNumber: found.phone.number,
            email: found.email.address,
            hasError: false,
            phoneChanged: found.phone.number !== p.phoneNumber,
            emailChanged: found.email.address !== p.email
          }
        }

        return p
      })

      commit('addPassengers', updatePassengers)
    }
  },
  namespaced: true
}

export default SelfService
