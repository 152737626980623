import Contact from 'models/Forms/Contact'
import FormValidationUtils from 'utils/FormValidation'

const contact = new Contact()
const formData = {
  formErrors: contact.errorMsg,
  form: contact,
  excluded: []
}
const defaultState = Object.assign({}, formData)

const module = {
  namespaced: true,
  state: defaultState,
  actions: {
    setSurname ({ commit }, value) {
      commit('setLastName', value.trim().toUpperCase())
    },
    validateContacts () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('validateContacts')
      })
    },
    clearContacts ({ commit }) {
      commit('resetForm')
      validator.$v.state.form.$reset()
    }
  },
  mutations: {
    setExcludedFields (state, value) {
      state.excluded = value
    },
    setContactInformationTitle (state, value) {
      state.contactInformationTitle = value
    },
    setContactInformationDescription (state, value) {
      state.contactInformationDescription = value
    },
    setFirstName (state, value) {
      state.form.firstName = value
    },
    setLastName (state, value) {
      state.form.lastName = value
    },
    setEmail (state, value) {
      state.form.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setTitle (state, value) {
      state.form.title = value
    },
    resetForm (state) {
      state.form = new Contact()
    },
    setIntCode (state, value) {
      state.form.intCode = value
    },
    setPhoneNumber (state, value) {
      state.form.phoneNumber = value
    }
  },
  getters: {
    $contact () {
      return Object.assign({}, validator.$v.state.form)
    },
    contactValidationFormErrors (state) {
      return state.formErrors
    }
  }
}

const validator = FormValidationUtils.initValidation(defaultState, contact.validation, module.state.excluded)

export default module
