import AbstractView from 'abstract';
import Vue from 'vue'
import CreateProfileInit from 'initializations/pages/Pax/Clean/CreateProfile'
import SettingsInit from 'initializations/pages/Pax/ProfileSettings'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init () {
      CreateProfileInit.init("createProfile")
      SettingsInit.init('profileSettings')
      const CreateProfile = () => import(/* webpackChunkName: "create-clean-profile-page" */ 'containers/Pages/Profile/Clean/Create')

      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(CreateProfile)
      })
    }
}
