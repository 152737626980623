import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import SimpleTextPageInit from 'initializations/pages/SimpleTextPage'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        SimpleTextPageInit.init("simpleTextPage")

        const SimpleTextPage = () => import(/* webpackChunkName: "container-simple-text-page" */ 'containers/Pages/SimpleText')
        new Vue({
            el: "#" + this.elemId,
            store,
            render: h => h(SimpleTextPage)
        })
    }
}
