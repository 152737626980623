var Assistance = /** @class */ (function () {
    function Assistance() {
        this.formName = '';
        this.bookingRef = '';
        this.language = '';
        this.pnrRules = [
            'NO_ACTIVE_FLIGHTS',
            'NO_ACTIVE_FLIGHTS_48H'
        ];
        this.texts = {};
    }
    return Assistance;
}());
export default Assistance;
