var RegisterProfile = /** @class */ (function () {
    function RegisterProfile() {
        this.pageTitle = '';
        this.sections = {};
        this.countries = [];
        this.languages = [];
        this.phoneCodes = [];
        this.termsAndConditions = '';
        this.signUpPage = '';
        this.profilePageUrl = '';
        this.newsletterNotificationVisible = true;
        this.loginPage = '';
        this.platforms = {
            newsletter: {
                name: 'newsletter',
                title: 'forms.registerForm.notifications.newsletter.title',
                description: 'forms.registerForm.notifications.newsletter.description',
            },
            smsDeals: {
                name: 'smsDeals',
                title: 'forms.registerForm.notifications.smsDeals.title',
                description: 'forms.registerForm.notifications.smsDeals.description',
                connected: false
            },
            whatsapp: {
                name: "whatsapp",
                title: 'forms.registerForm.notifications.whatsapp.title',
                description: 'forms.registerForm.notifications.whatsapp.description',
                connected: false
            }
        };
    }
    return RegisterProfile;
}());
export default RegisterProfile;
