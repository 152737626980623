import moment from 'moment'
import Generic from 'models/Payments/Generic'
import isObject from 'lodash-es/isObject'

export default {
  namespaced: true,
  state: new Generic(),
  actions: {
    setGenericData({ commit }, data) {
      commit('setSteps', data.steps)
      commit('setList', data.list)
      commit('setNote', data.note)
      commit('setOldUrl', data.paymentUrl)
      commit('setForms', data.forms)
      commit('setType', data.type)
      commit('setPageTitle', data.title)
      commit('setDescription', data.description)
      commit('setBookingCancellationUrl', data.bookingCancellationUrl)
    },
    validateForm(context, rules) {
      return Promise.all(rules)
        .then(() => Promise.resolve(true))
        .catch((e) => Promise.reject(e))
    },
    clearForms({ dispatch }, form) {
      const clearDataRules = [
        dispatch('payment/clearData', null, { root: true }),
        dispatch('payment/flightDetails/clearFlightDetails', null, { root: true }),
        dispatch('payment/request/clearRequests', null, { root: true }),
        dispatch('payment/contact/clearContacts', null, { root: true }),
        dispatch('payment/company/clearCompany', null, { root: true }),
        dispatch('payment/gvo/clearGvo', null, { root: true }),
        dispatch('payment/flightList/clearForm', null, { root: true })
      ]

      if (form !== 'default') {
        clearDataRules.push(
          dispatch('payment/passengers/clearPassengers', null, { root: true })
        )
        clearDataRules.push(
          dispatch('payment/segments/clearSegments', null, { root: true })
        )
      }

      return Promise.all(clearDataRules)
        .then(() => Promise.resolve(true))
        .catch(() => Promise.reject('clearForms'))
    },
    validateAndSubmit({ dispatch }, data) {
      return dispatch('validateForm', data.rules)
        .then(() => dispatch(`getDataAndSubmitForm`, data.form))
        .catch((err) => {
          const error = !isObject(err) ? { type: 'validation', msg: err } : err
          return Promise.reject(error)
        })
    },
    validateAndSubmitSpeq({ dispatch }, data) {
      return dispatch('validateForm', data.rules)
        .then(() => dispatch(`getDataAndSubmitSpeq`, data))
        .catch((err) => {
          const error = !isObject(err) ? { type: 'validation', msg: err } : err
          return Promise.reject(error)
        })
    },
    getDataAndSubmitSpeq({ dispatch, rootState }, formData) {
      const generic = rootState.payment
      const company = generic.company
      const contact = generic.contact.form
      const flightDetails = generic.flightDetails.form
      const request = generic.request.form

      const data = {
        language: generic.language,
        protectedBaggage: generic.protectedBaggage,
        title: contact.title,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        bookingRef: generic.bookingRef,
        flightNumber: flightDetails.flightNumber,
        flightDate: flightDetails.flightDate,
        message: request.message,
      }
      if (company.showBillingDetails) {
        dispatch('getCompanyData', company).then((companyData) =>
          Object.assign(data, { company: companyData })
        )
      }

      let flightDate = moment()
      if (flightDetails.flightDate !== null) {
        flightDate = moment(flightDetails.flightDate, 'YYYY-MM-DD')
      }
      data.flightDate = flightDate.format('YYYY-MM-DD')

      let passengersList = []
      formData.passengers.forEach(pax => {
        let baggage = []
        pax.flights.forEach(flight => {
          pax.speqList.forEach(speq => {
            baggage.push({
              type: speq.type,
              quantity: 1,
              flightNumber: flight.flightNumber,
              flightDate: moment(flight.flightDate, 'DD / MM / YYYY').format('YYYY-MM-DD'),
              weight: speq.weight,
              dimensions: {
                length: speq.length,
                width: speq.width,
                height: speq.height
              }
            })
            if (!data.flightNumber) {
              data.flightNumber = flight.flightNumber
              data.flightDate = moment(flight.flightDate, 'DD / MM / YYYY').format('YYYY-MM-DD')
            }
          })
        })
        passengersList.push({
          title: pax.title,
          firstName: pax.firstName,
          lastName: pax.lastName,
          protectedBaggage: formData.baggageProtection,
          baggagePieces: baggage
        })
      })
      data.passengers = passengersList

      return dispatch(
        `payment/other/submitForm`,
        { form: 'sportEquipmentForm', data },
        { root: true }
      ).catch((err) => Promise.reject({ type: 'submit', msg: err }))
    },
    getDataAndSubmitForm({ dispatch }, formName) {
      let path = 'other'

      if (['defaultForm'].includes(formName)) {
        path = 'defaultForm'
      }

      if (formName === 'extras') {
        path = 'extras'
      }

      return dispatch('getData', formName)
        .then((data) => {
          if (formName !== 'defaultForm') {
            delete data.serviceType
          }
          return dispatch(
            `payment/${path}/submitForm`,
            { form: formName, data },
            { root: true }
          )
        })
        .catch((err) => Promise.reject({ type: 'submit', msg: err }))
    },
    getData({ rootState, dispatch }, formName) {
      const generic = rootState.payment
      const company = generic.company
      const passengers = generic.passengers

      return dispatch('getPassengerList', { passengers, formName })
        .then((passengerList) => {
          const data = {}

          dispatch('getPaymentData', passengerList).then((paymentData) =>
            Object.assign(data, paymentData)
          )

          if (company.showBillingDetails) {
            dispatch('getCompanyData', company).then((companyData) =>
              Object.assign(data, { company: companyData })
            )
          }

          return data
        })
        .catch((err) => Promise.reject({ type: 'submit', msg: err }))
    },
    getCompanyData(context, company) {
      return {
        name: company.form.name,
        registrationNumber: company.form.registrationNumber,
        vatNumber: company.form.vatNumber,
        address: company.form.address,
        city: company.form.city,
        postalCode: company.form.postalCode,
        countryCode: company.form.countryCode
      }
    },
    getPaymentData({ rootState }, passengerList) {
      const generic = rootState.payment
      const flightDetails = generic.flightDetails.form
      const contact = generic.contact.form
      const request = generic.request.form

      const data = {
        serviceType: generic.formName,
        language: generic.language,
        protectedBaggage: generic.protectedBaggage,
        title: contact.title,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        bookingRef: generic.bookingRef,
        flightNumber: flightDetails.flightNumber,
        message: request.message,
        passengers: passengerList
      }

      let flightDate = moment()
      if (flightDetails.flightDate !== null) {
        flightDate = moment(flightDetails.flightDate, 'DD.MM.YYYY')
      }
      data.flightDate = flightDate.format('YYYY-MM-DD')

      return data
    },
    getPassengerList({ rootState, dispatch }, arr) {
      return new Promise((resolve) => {
        const data = []

        arr.passengers.passengers.forEach((passenger) => {
          const passengerStore = rootState.payment.passengers[passenger.namespace]

          if (typeof passengerStore !== 'undefined') {
            let segments = []

            if (typeof arr.passengers.segments !== 'undefined') {
              segments = arr.passengers.segments
            }

            const request = {
              passenger: passengerStore.passenger,
              form: arr.formName,
              passengerNamespace: passenger.namespace,
              segments
            }

            if (arr.formName === 'sportEquipmentForm') {
              request.passengerStore = passengerStore
              request.flightList = rootState.payment.flightList
            }

            let path = 'other'

            if (arr.formName === 'extras') {
              path = 'extras'
            }

            dispatch(`payment/${path}/getPassengerData`, request, { root: true }).then(
              (newPassenger) => data.push(newPassenger)
            )
          }
        })

        resolve(data)
      })
    }
  },
  mutations: {
    setBookingCancellationUrl(state, value) {
      state.bookingCancellationUrl = value
    },
    setOrigDestData(state, value) {
      state.origDestData = value
    },
    setPageTitle(state, value) {
      state.pageTitle = value
    },
    setDescription(state, value) {
      state.description = value
    },
    setSteps(state, value) {
      state.steps = value
    },
    setList(state, value) {
      state.list = value
    },
    setNote(state, value) {
      state.note = value
    },
    setOldUrl(state, value) {
      state.oldUrl = value
    },
    setActiveItem(state, value) {
      state.activeItem = value
    },
    setActiveStep(state, value) {
      state.activeStep = value
    },
    setForms(state, value) {
      state.forms = value
    },
    setType(state, value) {
      state.type = value
    },
    setFormName(state, value) {
      state.formName = value
    },
    setProtectedBaggage(state, value) {
      state.protectedBaggage = value
    }
  }
}
