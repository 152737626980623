import AbstractView from 'abstract';
import Vue from 'vue'
import airbusInit from 'initializations/pages/Airbus'
import SidebarInit from 'initializations/Sidebar'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
      SidebarInit.init("sidebar")
      airbusInit.init("airbus")

      const airbusPage = () => import(/* webpackChunkName: "airbus-page" */ 'containers/Pages/Airbus')
      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(airbusPage)
      })
    }
}
