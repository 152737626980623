import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import AccordionPageInit from 'initializations/pages/AccordionPage'
import SidebarInit from 'initializations/Sidebar'
import AccordionPage from 'containers/Pages/Accordion'
export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        SidebarInit.init("sidebar")
        AccordionPageInit.init("accordionPage")

        new Vue({
            el: "#" + this.elemId,
            store,
            render: h => h(AccordionPage)
        })
    }
}
