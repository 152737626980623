import Origin from 'store/modules/eHorizon/SearchForm/Origin'
import Destin from 'store/modules/eHorizon/SearchForm/Destin'
import PaxSelector from 'store/modules/eHorizon/SearchForm/PaxSelector'
import PromoCodes from 'store/modules/eHorizon/SearchForm/PromoCodes'
import Fsf from 'store/modules/eHorizon/SearchForm/Fsf'
import OrigDestUtils from 'store/utils/eHorizonOrigDestUtils'
import RecentSearch from 'store/modules/eHorizon/SearchForm/RecentSearch'
import Calendar from 'store/modules/eHorizon/SearchForm/Calendar'
import FlightService from 'services/AirBaltic/Flights'
import Vue from 'vue'
import conf from 'conf'

const hardCodedlabels = {
  ALL: 'all'
}

const SearchForm = {
  modules: {
    origin: Origin,
    destin: Destin,
    paxSelector: PaxSelector,
    promoCodes: PromoCodes,
    recentSearch: RecentSearch,
    calendar: Calendar,
    fsf: Fsf
  },
  state: {
    tripType: 'return',
    origDestData: {},
    directFlights: {},
    directFlightsRoundTrip: {},
    formValid: false,
    errors: [],
    groupBookingUrl: '',
    groupBookingFormUrl: '',
    sref: '',
    selectedMonth: '',
    loadingStatus: false,
    returnData: {},
    departureData: {},
    showHistogram: false,
    selectedFlightPrice: {},
    selectedDepartPrice: {},
    selectedReturnPrice: {},
    upsltab: '',
    originalDepartPrice: {}
  },
  mutations: {
    setDepartureData(state, value) {
      state.departureData = value
    },
    setReturnData(state, value) {
      state.returnData = value
    },
    setLoadingStatus(state, value) {
      state.loadingStatus = value
    },
    setTripType(state, newType) {
      state.tripType = newType
    },
    setOrigDestData(state, value) {
      state.origDestData = value
    },
    setFromValid(state, value) {
      state.formValid = value
    },
    addError(state, value) {
      state.errors.push(value)
    },
    setGroupBookingUrl(state, value) {
      state.groupBookingUrl = value
    },
    setGroupBookingFormUrl(state, value) {
      state.groupBookingFormUrl = value
    },
    setSref(state, value) {
      state.sref = value
    },
    setSelectedMonth(state, value) {
      state.selectedMonth = value
    },
    setUpsltab(state, value) {
      state.upsltab = value
    },
    removeErrors(state) {
      state.errors = []
    },
    setDirectFlightsData(state, value) {
      state.directFlights = value
    },
    setDirectFlightsRoundTripData(state, value) {
      state.directFlightsRoundTrip = value
    },
    setShowHistogram(state, value) {
      state.showHistogram = value
    },
    setSelectedFlightPrice(state, value) {
      state.selectedFlightPrice = value
    },
    setSelectedDepartPrice(state, value) {
      state.selectedDepartPrice = value
    },
    setSelectedReturnPrice(state, value) {
      state.selectedReturnPrice = value
    },
    setOriginalDepartPrice(state, value) {
      state.originalDepartPrice = value
    }
  },
  getters: {
    filteredOrigins:
      (state) =>
      (hide = false, excludeCities = false, btOnly = false) => {
        const priorityOrigins = ['RIX', 'TLL', 'VNO']
        const filter = state.origin.typeahead.filter
        const selectedLabel = state.origin.selectedLabel
        let origins = state.origin.destinations
        const category = state.origin.selectedCategory
        const subCategory = state.origin.selectedSubCategory
        const typeaheadIgnoreFilter = state.origin.typeaheadIgnoreFilter
        const directTranslation = Vue.i18n.translate(
          'searchForm.originDestin.origin.directFlightsOriginEHorizon'
        )
        const connectedTranslation = Vue.i18n.translate(
          'searchForm.originDestin.origin.connectedFlights'
        )
        const matchedTranslation = Vue.i18n.translate(
          'searchForm.originDestin.origin.matchedOrigins'
        )
        const popularTranslation = Vue.i18n.translate(
          'searchForm.originDestin.origin.popular'
        )

        if (!typeaheadIgnoreFilter) {
          if (excludeCities) {
            origins = origins.filter((origin) => origin.type !== 'C')
          }

          if (btOnly) {
            origins = OrigDestUtils.filterData(
              filter,
              origins.filter((o) => o.isBtOrig && o.type === 'A'),
              typeaheadIgnoreFilter
            )
          }

          return OrigDestUtils.addHeaderToAirportList(
            matchedTranslation,
            OrigDestUtils.filterData(filter, origins, typeaheadIgnoreFilter)
          )
        }

        if (category === 'countries' && subCategory) {
          let btOrigins = OrigDestUtils.filterData(
            filter,
            origins.filter((o) => {
              return o.isBtOrig && o.country === subCategory
            }),
            typeaheadIgnoreFilter
          )
          let partnerOrigins = OrigDestUtils.filterData(
            filter,
            origins.filter((o) => {
              return !o.isBtOrig && o.country === subCategory
            }),
            typeaheadIgnoreFilter
          )

          return [...btOrigins, ...partnerOrigins]
        }

        if (selectedLabel === hardCodedlabels.ALL || category === hardCodedlabels.ALL) {
          let btOrigins = OrigDestUtils.filterData(
            filter,
            origins.filter((o) => o.isBtOrig),
            typeaheadIgnoreFilter
          )
          let partnerOrigins = OrigDestUtils.filterData(
            filter,
            origins.filter((o) => !o.isBtOrig),
            typeaheadIgnoreFilter
          )

          if (excludeCities) {
            btOrigins = btOrigins.filter((origin) => origin.type !== 'C')
            partnerOrigins = partnerOrigins.filter((origin) => origin.type !== 'C')
          }

          btOrigins = OrigDestUtils.addHeaderToAirportList(directTranslation, btOrigins)

          if (!hide) {
            partnerOrigins = OrigDestUtils.addHeaderToAirportList(
              connectedTranslation,
              partnerOrigins
            )
            return [...btOrigins, ...partnerOrigins]
          }
          return [...btOrigins]
        } else {
          let countryOrigins = OrigDestUtils.filterData(
            filter,
            origins.filter((o) => o.countryCode === state.origin.homeCountry),
            typeaheadIgnoreFilter
          )

          let popularOrigins = OrigDestUtils.filterData(
            filter,
            origins.filter((o) => priorityOrigins.indexOf(o.code) !== -1),
            typeaheadIgnoreFilter
          )

          if (excludeCities) {
            countryOrigins = countryOrigins.filter((origin) => origin.type !== 'C')
            popularOrigins = popularOrigins.filter((origin) => origin.type !== 'C')
          }

          if (category !== 'interests') {
            countryOrigins = OrigDestUtils.addHeaderToAirportList(
              Vue.i18n.translate(state.origin.labels[`${selectedLabel}`]),
              countryOrigins
            )
          }

          popularOrigins = OrigDestUtils.addHeaderToAirportList(
            popularTranslation,
            OrigDestUtils.filterData(
              filter,
              popularOrigins.filter(
                (o) => !countryOrigins.find((item) => item.code === o.code)
              ),
              typeaheadIgnoreFilter
            )
          )

          return [...countryOrigins, ...popularOrigins]
        }
      },
    filteredDestins:
      (state) =>
      (hide = false, excludeCities = false) => {
        const filter = state.destin.typeahead.filter
        let destins = state.destin.destinations
        const selectedLabel = state.destin.selectedLabel
        const category = state.destin.selectedCategory
        const subCategory = state.destin.selectedSubCategory
        const typeaheadIgnoreFilter = state.destin.typeaheadIgnoreFilter
        const directTranslation = Vue.i18n.translate(
          'searchForm.originDestin.destin.directFlightsDestinEHorizon'
        )
        const connectedTranslation = Vue.i18n.translate(
          'searchForm.originDestin.destin.connectedFlightsDestin'
        )
        const matchedTranslation = Vue.i18n.translate(
          'searchForm.originDestin.destin.matchedDestins'
        )

        if (!typeaheadIgnoreFilter) {
          if (excludeCities) {
            destins = destins.filter((origin) => origin.type !== 'C')
          }

          return OrigDestUtils.addHeaderToAirportList(
            matchedTranslation,
            OrigDestUtils.filterData(filter, destins, typeaheadIgnoreFilter)
          )
        }

        let directs = OrigDestUtils.filterData(
          filter,
          destins.filter((o) => o.isBTDest),
          typeaheadIgnoreFilter
        )
        let connected = OrigDestUtils.filterData(
          filter,
          destins.filter((o) => !o.isBTDest),
          typeaheadIgnoreFilter
        )

        if (excludeCities) {
          directs = directs.filter((origin) => origin.type !== 'C')
          connected = connected.filter((origin) => origin.type !== 'C')
        }

        if (category === 'countries' && subCategory) {
          directs = directs.filter((origin) => origin.country === subCategory)
          connected = connected.filter((origin) => origin.country === subCategory)
          return [...directs, ...connected]
        }

        if (selectedLabel === 'label_all') {
          directs = OrigDestUtils.addHeaderToAirportList(directTranslation, directs)
          if (!hide) {
            connected = OrigDestUtils.addHeaderToAirportList(
              connectedTranslation,
              connected
            )
            return [...directs, ...connected]
          }

          return [...directs]
        }

        let destinList = OrigDestUtils.filterData(
          filter,
          destins.filter((o) => o.labels && o.labels.indexOf(selectedLabel) !== -1),
          typeaheadIgnoreFilter
        )
        if (category !== 'interests') {
          return OrigDestUtils.addHeaderToAirportList(
            state.destin.labels[`${selectedLabel}`],
            destinList
          )
        }
        return destinList
      },

    origDestCountries: (state) => (type) => {
      let origDest =
        type === 'origin' ? state.origin.destinations : state.destin.destinations
      let countries = []

      const isInCountryList = (country) =>
        countries.some((listItem) => listItem.country === country)

      Object.values(origDest).forEach((item) => {
        if (item.country && !isInCountryList(item.country)) {
          countries.push({
            code: item.countryCode,
            country: item.country
          })
        }
      })
      return countries.sort((a, b) => a.country.localeCompare(b.country))
    },

    formErrors(state) {
      return [...state.errors, ...state.paxSelector.errors]
    },
    formAction() {
      return `${conf.newIbeUrl}/${Vue.i18n.locale()}/fb/histogram`
    },
    formActionAvailability() {
      return `${conf.newIbeUrl}/${Vue.i18n.locale()}/fb/availability`
    }
  },
  actions: {
    updateSelectedFlightPrice: async function ({ commit, dispatch, state }, flightPrice) {
      const { price, outboundPrice, outboundDecimalPrice } = flightPrice
      const isReturnTab = state.fsf.activeTab === 'return'

      const priceObject = {
        price: outboundPrice,
        decimalPrice: outboundDecimalPrice
      }

      dispatch('showDepartPriceChangedPopover', false)
      if (price) {
        if (isReturnTab && outboundPrice) {
          const priceMatches = await dispatch('departPriceMatches', outboundPrice)
          if (!priceMatches) {
            commit('setSelectedDepartPrice', priceObject)
            dispatch('showDepartPriceChangedPopover', true)
          }
        }
        commit('setSelectedFlightPrice', flightPrice)
      }
    },
    showDepartPriceChangedPopover({ commit }, show) {
      commit('fsf/setShowDepartPriceChangedPopover', show, { root: true })
    },
    departPriceMatches({ state }, price) {
      if (!state.originalDepartPrice.price) {
        return true
      }

      return price === state.originalDepartPrice.price
    },
    setDepartPrice({ commit, dispatch, state }, price) {
      commit('setSelectedDepartPrice', price)
      commit('setOriginalDepartPrice', price)

      if (state.selectedFlightPrice?.price) {
        dispatch('updateSelectedFlightPrice', state.selectedFlightPrice)
      }
    },
    getDirectFlights({ commit, dispatch, state }) {
      setTimeout(() => {
        if (
          state.origin.value.code !== undefined &&
          state.destin.value.code !== undefined
        ) {
          if (state.destin.value.hasBTDirect === true) {
            dispatch('makeDirectFlightsRequest', false)
          } else {
            commit('setDirectFlightsData', {})
          }
        }
      }, 200)
    },
    getDirectFlightsRoundTrip({ commit, dispatch, state }) {
      setTimeout(() => {
        if (
          state.tripType === 'return' &&
          state.origin.value.code !== undefined &&
          state.destin.value.code !== undefined
        ) {
          if (state.destin.value.hasBTDirect === true) {
            dispatch('makeDirectFlightsRequest', true)
          } else {
            commit('setDirectFlightsRoundTripData', {})
          }
        }
      }, 200)
    },
    makeDirectFlightsRequest({ commit, state }, isRt) {
      const origin = state.origin.value
      const destin = state.destin.value

      let data = {
        origCode: origin.code,
        origType: origin.type,
        destCode: destin.code,
        destType: destin.type
      }
      let mutation = 'setDirectFlightsData'

      if (isRt) {
        data = {
          origCode: destin.code,
          origType: destin.type,
          destCode: origin.code,
          destType: origin.type
        }
        mutation = 'setDirectFlightsRoundTripData'
      }

      new FlightService()
        .getDirect(data)
        .then((response) => {
          if (response.length !== 0) {
            commit(mutation, response)
          } else {
            commit(mutation, {})
          }
        })
        .catch(() => commit(mutation, {}))
    },
    selectOrigin({ commit, dispatch, state }, originValue) {
      const destinations = OrigDestUtils.extractDestinations(
        originValue,
        state.origDestData
      )
      dispatch('origin/onValueSelect', originValue)
      dispatch('destin/updateDestinations', destinations)
      commit('destin/setSelectedLabel', 'label_all')
      dispatch(
        'destin/updateDestinationLabels',
        OrigDestUtils.getAvailableLabels(destinations)
      )
    },

    swapOrigWithDest({ dispatch, state }) {
      const newOrigin =
        state.origDestData[state.destin.value.code + state.destin.value.type]
      const newDestin =
        state.origDestData[state.origin.value.code + state.origin.value.type]

      if (typeof newOrigin !== 'undefined') {
        const destinData =
          newOrigin.destinations[state.origin.value.code + state.origin.value.type]

        if (typeof newDestin !== 'undefined') {
          if (typeof destinData !== 'undefined') {
            newDestin.fastSearch = destinData.fastSearch
          }

          dispatch('selectOrigin', newOrigin)
          dispatch('destin/onValueSelect', newDestin)
        }
      }
    },
    validateComponents({ commit, dispatch, state }) {
      commit('removeErrors')

      return new Promise((resolve) => {
        dispatch('destin/validate')
        dispatch('origin/validate')
        dispatch('promoCodes/validateForm')

        if (
          !state.destin.invalid &&
          !state.origin.invalid &&
          state.paxSelector.errors.length === 0 &&
          !state.promoCodes.invalid
        ) {
          commit('setFromValid', true)
        } else {
          commit('setFromValid', false)
        }

        resolve()
      })
    }
  },

  namespaced: true
}

export default SearchForm
