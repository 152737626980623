const RecentSearch = {
  state: {
    recentSearches: [],
    popupTitle: '',
    segments: [],
  },
  mutations: {
    setSearchData (state, value) {
      state.recentSearches = value
    },
    removeSearch (state, index) {
      state.recentSearches.splice(index, 1)
    },
    setPopupTitle (state, value) {
      state.popupTitle = value
    },
    setSegments (state, value) {
      state.segments = value
    }
  },
  namespaced: true
}

export default RecentSearch
