let topPosition = 0

class ScrollUtils {
  static disableScroll () {
    if (!document.body.classList.contains('scrollDisabled')) {
      const scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scroll || topPosition !== 0) {
        topPosition = scroll
      }

      document.body.classList.add('scrollDisabled')
      document.documentElement.style.overflow = 'auto'
      document.body.style.overflow = 'hidden'
    }
  }

  static enableScroll () {
    if (document.body.classList.contains('scrollDisabled')) {
      document.body.classList.remove('scrollDisabled')
      document.documentElement.style.overflow = ''
      document.body.style.overflow = ''
      window.scrollTo(0, topPosition)
    }
  }

  static smoothScroll (to, interval) {
    const currentScroll = document.body.scrollTop || document.documentElement.scrollTop || 0
    let tick = currentScroll + interval
    const int = setInterval(() => {
      window.scrollTo(0, tick)
      tick += interval

      if (interval < 0) {
        if (tick < to) {
          window.scrollTo(0, to)
          clearInterval(int)
        }
      } else if (tick > to) {
        window.scrollTo(0, to)
        clearInterval(int)
      }
    }, 20)
  }

  static smoothScrollLeft (element, to, interval) {
    const currentScroll = element.scrollLeft || 0
    let tick = currentScroll + interval
    const int = setInterval(() => {
      element.scrollLeft = tick
      tick += interval

      if (interval < 0) {
        if (tick < to) {
          element.scrollLeft = to
          clearInterval(int)
        }
      } else if (tick > to) {
        element.scrollLeft = to
        clearInterval(int)
      }
    }, 20)
  }
}

export default ScrollUtils
