import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { required, numeric, minLength, sameAs, not } from 'vuelidate/lib/validators'
import {
  requiredWithPhone,
  containsSpecialChar,
  containsUpperChar,
  containsLowerChar,
  containsNumber,
  containsForbidden,
  containsSpaces
} from 'utils/validators'
import AxiosUtils from 'utils/AxiosUtils'
import divolteProfile from 'services/AirBaltic/Divolte/Profile'
import EmailChangeStore from "store/modules/Pages/ProfilePages/EmailChange"
import ProfileServices from 'services/AirBaltic/Pax/Profile'
import ErrorHandler from 'utils/ErrorHandler'

function hasNoBackendError (value, obj) {
  return !obj.backendError
}

const errorMsg = {
  contacts: {
    phone: {
      required: 'reusable.phone.validation.invalid',
      hasNoBackendError: 'reusable.phone.validation.invalid',
      numeric: 'reusable.phone.validation.notNumeric'
    },
    intCode: {
      requiredWithPhone: 'reusable.phone.validation.invalid'
    },
  },
  password: {
    old: {
      required: 'reusable.password.validation.missing',
      minLength: 'reusable.password.validation.minLength',
      hasNoBackendError: 'reusable.password.validation.invalid'
    },
    confirm: {
      required: 'reusable.password.validation.missing',
      sameAsPassword: 'reusable.password.validation.confirm'
    }
  }
}

const passwordValidationRuleMapping = {
  minLength: {
    label: 'reusable.password.rules.countCharacters',
    rules: ['minLength', 'required']
  },
  containsLowerChar: {
    label: 'reusable.password.rules.lowerCase',
    rules: ['containsLowerChar']
  },
  containsUpperChar: {
    label: 'reusable.password.rules.upperCase',
    rules: ['containsUpperChar']
  },
  containsNumber: {
    label: 'reusable.password.rules.number',
    rules: ['containsNumber']
  },
  notSameAsEmail: {
    label: 'reusable.password.rules.matchingEmail',
    rules: ['notSameAsEmail']
  },
  notSameAsPassword: {
    label: 'reusable.password.rules.matchingPassword',
    rules: ['notSameAsPassword']
  },
  containsForbidden: {
    label: 'reusable.password.rules.forbiddenChars',
    rules: ['containsForbidden']
  },
  containsSpecialChar: {
    label: 'reusable.password.rules.special',
    rules: ['containsSpecialChar']
  },
  containsSpaces: {
    label: 'reusable.password.rules.spaces',
    rules: ['containsSpaces']
  },
  maxLength: {
    label: 'reusable.password.strengthRules.maxCharacters',
    rules: ['maxLength']
  }
}

const defaultState = {
  languages: [],
  countries: [],
  pageData: {},
  modalDeleteProfileTitle: '',
  modalDeleteProfileText: '',
  contacts: {
    preferredLanguage: '',
    intCode: '',
    phone: '',
    backendError: false,
    homeCountry: ''
  },
  password: {
    old: '',
    new: '',
    confirm: '',
    backendError: false,
    hasPassword: false
  },
  subscribe: {
    email: false,
    sms: false,
    whatsapp: false,
    checkin: false,
    boarding: false
  },
  formErrors: errorMsg,
  passwordValidationRuleMapping
}

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return defaultState
    }
  },
  validations () {
    if (this.state.password.hasPassword) {
      return {
        state: {
          contacts: {
            intCode: {
              requiredWithPhone
            },
            phone: {
              numeric,
              hasNoBackendError
            },
            homeCountry: {},
            preferredLanguage: {}
          },

          password: {
            old: {
              required,
              minLength: minLength(8),
              hasNoBackendError
            },
            new: {
              required,
              minLength: minLength(8),
              notSameAsEmail: not(sameAs('email')),
              notSameAsPassword: not(sameAs('old')),
              containsSpecialChar,
              containsUpperChar,
              containsLowerChar,
              containsNumber,
              containsForbidden: not(containsForbidden),
              containsSpaces: not(containsSpaces)
            },
            confirm: {
              required,
              sameAsPassword: sameAs('new')
            }
          }
        }
      }
    } else {
      return {
        state: {
          contacts: {
            intCode: {
              requiredWithPhone
            },
            phone: {
              numeric,
              hasNoBackendError
            },
            homeCountry: {},
            preferredLanguage: {},
          },

          password: {
            new: {
              required,
              minLength: minLength(8),
              notSameAsEmail: not(sameAs('email')),
              notSameAsPassword: not(sameAs('old')),
              containsSpecialChar,
              containsUpperChar,
              containsLowerChar,
              containsNumber,
              containsForbidden: not(containsForbidden),
              containsSpaces: not(containsSpaces)
            },
            confirm: {
              required,
              sameAsPassword: sameAs('new')
            }
          }
        }
      }
    }
  }
})

const ProfileSettingsStore = {
  state: defaultState,
  modules: {
    emailChange: EmailChangeStore
  },
  mutations: {
    setModalDeleteProfileTitle (state, value) {
      state.modalDeleteProfileTitle = value
    },
    setModalDeleteProfileText (state, value) {
      state.modalDeleteProfileText = value
    },
    setModalDeleteProfileDisclaimer (state, value) {
      state.modalDeleteProfileDisclaimer = value
    },
    setPreferredLanguage (state, value) {
      state.contacts.preferredLanguage = value
    },
    setOldPassword (state, value) {
      state.password.old = value
    },
    setNewPassword (state, value) {
      state.password.new = value
    },
    setPasswordError (state, value) {
      state.password.backendError = value
    },
    setHasPassword (state, value) {
      state.password.hasPassword = value
    },
    setConfirmPassword (state, value) {
      state.password.confirm = value
    },
    setHomeCountry (state, value) {
      state.contacts.homeCountry = value
    },
    setSubscribeEmail (state, value) {
      state.subscribe.email = value
    },
    setSubscribeSms (state, value) {
      state.subscribe.sms = value
    },
    setSubscribeWhatsapp (state, value) {
      state.subscribe.whatsapp = value
    },
    setSubscribeCheckin (state, value) {
      state.subscribe.checkin = value
    },
    setSubscribeBoarding (state, value) {
      state.subscribe.boarding = value
    },
    setLanguages (state, value) {
      state.languages = value
    },
    setCountries (state, value) {
      state.countries = value
    },
    setIntCode (state, value) {
      state.contacts.intCode = value
    },
    setPhone (state, value) {
      state.contacts.phone = value
    },
    setPhoneError (state, value) {
      state.contacts.backendError = value
    },
    setPageData (state, value) {
      state.pageData = value
    }
  },

  actions: {
    setOldPassword ({ commit }, data) {
      validator.$v.state.password.old.$touch()
      commit('setPasswordError', false)
      commit('setOldPassword', data)
    },
    setNewPassword ({ commit }, data) {
      validator.$v.state.password.new.$touch()
      commit('setNewPassword', data)
    },
    setConfirmPassword ({ commit }, data) {
      validator.$v.state.password.confirm.$touch()
      commit('setConfirmPassword', data)
    },
    setIntCode ({ commit, state }, intCode) {
      commit('setPhoneError', false)
      if (intCode.indexOf('|') >= 0) {
        commit('setIntCode', intCode)
      } else if (intCode.indexOf('+') === 0) {
        // eslint-disable-next-line consistent-return
        state.countries.forEach((entry) => {
          if (entry.dialingCode === intCode) {
            commit('setIntCode', entry.dialingCode + '|' + entry.value)
            return true
          }
        })
      } else if (intCode === '') {
        commit('setIntCode', '')
      } else {
        // eslint-disable-next-line consistent-return
        state.countries.forEach((entry) => {
          if (entry.value === intCode) {
            commit('setIntCode', entry.dialingCode + '|' + entry.value)
            return true
          }
        })
      }
    },
    setPhone ({ commit }, data) {
      commit('setPhone', data)
      commit('setPhoneError', false)
    },
    subscribeSms (context, data) {
      return new Promise((resolve, reject) => {
        const obj = {}
        obj.smsSubscribe = data

        return new ProfileServices().updatePaxProfile(obj)
          .then(() => resolve(true))
          .catch(() => reject(false))
      })
    },
    subscribeWhatsapp (context, data) {
      return new Promise((resolve, reject) => {
        const obj = {}
        obj.whatsAppSubscribe = data

        return new ProfileServices().updatePaxProfile(obj)
          .then(() => resolve(true))
          .catch(() => reject(false))
      })
    },
    subscribeEmail () {
      return new Promise((resolve, reject) => {
        return new ProfileServices().notificationEmailSubscribe()
          .then((data) => resolve(data))
          .catch((error) => reject(error))
      })
    },
    unsubscribeEmail () {
      return new Promise((resolve, reject) => {
        return new ProfileServices().notificationEmailUnSubscribe()
          .then((data) => resolve(data))
          .catch((error) => reject(error))
      })
    },

    saveContacts ({ dispatch, commit, state }, lang) {
      if (state.contacts.phone === '') {
        if (state.subscribe.sms) {
          dispatch('subscribe', {
            type: 'sms',
            data: false
          })
          commit('setSubscribeSms', false)
        }
        if (tate.subscribe.whatsapp) {
          dispatch('subscribe', {
            type: 'whatsapp',
            data: false
          })
          commit('setSubscribeWhatsapp', false)
        }
      }

      return new Promise((resolve, reject) => {
        const obj = {
          controller: 'Ajax_Vue_Pax_Settings_Contacts',
          action: 'update-Contacts',
          form: JSON.stringify({
            ...state.contacts,
            phoneNumber: {
              number: state.contacts.phone,
              countryCode: state.contacts.intCode
            },
            language: lang
          })
        }

        AxiosUtils.post(obj, '/ajax', true)
          .then((response) => {
            if (response.data.status) {
              divolteProfile.contactsUpdate(state.contacts)
              resolve(response.data.status)
            } else {
              dispatch('setFieldErrors', response.data.errors)
              reject(response.data.status)
            }
          })
          .catch(() => {
            reject(false)
          })
      })
    },

    setFieldErrors ({ commit }, errors) {
      if (!errors.length) {
        return
      }

      if (errors.indexOf('phoneNumber') !== -1) {
        commit('setPhoneError', true)
      }

      if (errors.indexOf('password') !== -1) {
        commit('setPasswordError', true)
      }
    },

    savePassword ({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        const form = {
          oldPassword: state.password.old,
          newPassword: state.password.new,
        }

        return new ProfileServices().updatePassword(form)
          .then((response) => {
            const status = response.message === 'SUCCESS'
            if (status) {
              setTimeout(() => {
                window.location.reload()
              }, 1000)
              resolve(true)
            } else {
              dispatch('setFieldErrors', response.errors)
              reject(false)
            }
          })
          .catch((e) => reject(e))
      })
    },

    deleteProfile () {
      return new Promise((resolve, reject) => {
        new ProfileServices().deletePaxProfile()
          .then((response) => resolve(response))
          .catch(err => {
            reject({
              type: 'delete',
              msg: ErrorHandler.getErrorTranslationKey({}, err.response)
            })
          })
      })
    }
  },

  getters: {
    $v () {
      return Object.assign({}, validator.$v.state)
    }
  },

  namespaced: true
}

export default ProfileSettingsStore
