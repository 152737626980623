import Divolte from 'services/AirBaltic/Divolte'
import UUID_Utils from 'utils/UUID_Utils'
import conf from 'conf'
import moment from 'moment'

class Profile {
  static push (name, data) {
    if (conf.divolte) {
      Divolte.doDataPush(name, data)
    }
  }

  static login (state) {
    const identity = {
      userUUID: UUID_Utils.create_UUID(),
      email: state.email,
      title: (state.title) ? state.title : null,
      firstname: state.firstName,
      surname: state.lastName,
    }

    this.push('login', {
      identity
    })
  }

  static profileUpdate (state) {
    const identity = {
      userUUID: UUID_Utils.create_UUID(),
      email: state.email,
      title: state.title,
      firstname: state.firstName,
      surname: state.familyName,
      dateOfBirth: Divolte.daysToEpoch( moment(state.dateOfBirth, 'YYYY-MM-DD')) + 1
    }

    this.push('passengerDetails', {
      identity
    })
  }

  static contactsUpdate (state) {
    const identity = {
      userUUID: UUID_Utils.create_UUID(),
      email: state.newEmail,
      language: state.preferredLanguage.toUpperCase(),
      country: state.homeCountry.toUpperCase()
    }

    this.push('passengerDetails', {
      identity
    })
  }

  static register (email) {
    const identity = {
      userUUID: UUID_Utils.create_UUID(),
      email
    }

    this.push('passengerDetails', {
      identity
    })
  }
}

export default Profile
