/* eslint-disable no-shadow */
import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { notEmpty, isValidDate, isDateTodayOrInFuture } from 'utils/validators'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import DateUtils from 'utils/DateUtils'

const missingDepartureValueError = 'searchForm.dateSelection.errors.missingDepartureValue'
const pastDateError = 'searchForm.dateSelection.errors.pastDate'
const missingReturnValueError = 'searchForm.dateSelection.errors.missingReturnValue'
const invalidDateError = 'searchForm.dateSelection.errors.invalid'
const wrongDateOrderError = 'searchForm.dateSelection.errors.wrongDateOrder'

const state = {
  departureDate: '',
  returnDate: '',
  departureInputText: '',
  returnInputText: '',
  visible: false,
  type: 'return',
  tripType: 'return',
  departureErrors: [],
  returnErrors: [],
  autoOpenDate: false
}

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return state
    }
  },
  validations () {
    let validations = {}

    validations =  {
        departureDate: {
          notEmpty
        },
        departureInputText: {
          required,
          isValidDate,
          isDateTodayOrInFuture
        },
        returnInputText: {
          required,
          isValidDate,
          isAfterFrom (value) {
            if (state.departureInputText === '') {
              return true
            } else {
              const date = DateUtils.getDateFromString(value)
              const date2 = DateUtils.getDateFromString(state.departureInputText)

              return date >= date2
            }
          }
        },
        returnDate: {
          notEmpty
        }
      }

    if (!['return', 'multi'].includes(state.tripType)) {
      delete validations.returnInputText
      delete validations.returnDate
    }

    return {
      state: validations
    }
  }
})

const Calendar = {
  state,
  getters: {
    $v () {
      return Object.assign({}, validator.$v)
    }
  },
  mutations: {
    openDepartureCalendar (state) {
      state.visible = true
      state.type = 'departure'
    },
    openReturnCalendar (state) {
      state.visible = true
      state.type = 'return'
    },
    setAutoOpenedDate (state, value) {
      state.autoOpenDate = value
    },
    closeCalendar (state) {
      state.visible = false
      state.isAutoOpened = false
    },
    updateCalendarDepartureDate (state, newDate) {
      state.departureInputText = moment(newDate).format('DD.MM.YYYY')
      state.departureDate = newDate
    },
    updateCalendarReturnDate (state, newDate) {
      state.returnInputText = newDate === '' ? '' : moment(newDate).format('DD.MM.YYYY')
      state.returnDate = newDate
    },
    updateCalendarDepartureText (state, newDate) {
      state.departureInputText = newDate

      if (!validator.$v.state.departureInputText.$error && newDate !== '') {
        state.departureDate = DateUtils.getDateFromString(newDate)
      } else if (newDate === '') {
        state.departureDate = ''
      }
    },
    updateCalendarReturnText (state, newDate) {
      state.returnInputText = newDate

      if (!validator.$v.state.returnInputText.$error && newDate !== '') {
        state.returnDate = DateUtils.getDateFromString(newDate)
      } else if (newDate === '') {
        state.returnDate = ''
      }
    },
    setDepartureErrors (state, errors) {
      state.departureErrors = errors
    },
    setReturnErrors (state, errors) {
      state.returnErrors = errors
    },
    setTripType (state, value) {
      state.tripType = value
    }
  },
  actions: {
    setTripType ({ commit, dispatch }, value) {
      commit('setTripType', value)
      if (validator.$v.state.departureInputText.$dirty) {
        dispatch('validateReturn')
      }
    },
    validateDeparturePromise({ dispatch }) {
      dispatch('validateDeparture')

      return new Promise((resolve, reject) => {
        const vState = validator.$v.state

        vState.departureInputText.$touch()
        !vState.departureInputText.$invalid ? resolve(true) : reject('calendar-departure')
      })
    },
    touchReturnDate () {
      const vState = validator.$v.state
      vState.returnInputText.$touch()
    },
    validateReturnPromise({ dispatch }) {
      dispatch('validateReturn')

      return new Promise((resolve, reject) => {
        const vState = validator.$v.state
        vState.returnInputText.$touch()

        !vState.returnInputText.$invalid ? resolve(true) : reject('calendar-return')

      })
    },
    updateDepartureDate ({ commit, dispatch }, value) {
      commit('updateCalendarDepartureDate', value)
      dispatch('validateDeparture')
      dispatch('validateReturn')
    },
    updateReturnDate ({ commit, dispatch }, value) {
      commit('updateCalendarReturnDate', value)
      if (validator.$v.state.returnInputText) {
        validator.$v.state.returnInputText.$touch()
      }
      dispatch('validateReturn')
    },
    updateDepartureText ({ commit, dispatch }, value) {
      commit('updateCalendarDepartureText', value)
      if (value !== '') {
        dispatch('validateDeparture')
        dispatch('validateReturn')
      }
    },
    updateReturnText ({ commit, dispatch }, value) {
      commit('updateCalendarReturnText', value)
      if (value !== '') {
        if (validator.$v.state.returnInputText) {
          validator.$v.state.returnInputText.$touch()
        }
        dispatch('validateReturn')
      }
    },
    validateDeparture ({ commit }) {
      validator.$v.state.departureInputText.$touch()
      const vState = validator.$v.state
      const errors = []

      if (!vState.departureInputText.required) {
        errors.push(missingDepartureValueError)
      } else if (!vState.departureInputText.isValidDate) {
        errors.push(invalidDateError)
      } else if (!vState.departureInputText.isDateTodayOrInFuture) {
        errors.push(pastDateError)
      }
      commit('setDepartureErrors', errors)
    },
    validateReturn ({ commit }) {
      const vState = validator.$v.state
      const errors = []
      if (['return', 'multi'].includes(state.tripType) && validator.$v.state.returnInputText && validator.$v.state.returnInputText.$dirty) {
        if (!vState.returnInputText.required) {
          errors.push(missingReturnValueError)
        } else if (!vState.returnInputText.isValidDate) {
          errors.push(invalidDateError)
        } else if (!vState.returnInputText.isAfterFrom) {
          errors.push(wrongDateOrderError)
        }
      }
      commit('setReturnErrors', errors)
    },
    validate ({ dispatch }) {
      dispatch('validateDeparture')
      if (validator.$v.state.returnInputText) {
        validator.$v.state.returnInputText.$touch()
        dispatch('validateReturn')
      }
    }
  },
  namespaced: true
}

export default Calendar
