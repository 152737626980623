import store from 'definitions/store'
import BookingCancelationModel from 'store/modules/Pages/BookingValidation'
import OrigDest from "utils/OrigDestData";

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule('bookingValidation', BookingCancelationModel)
    }

    if (typeof descriptionData !== "undefined") {
      store.commit(`bookingValidation/setFeatures`, descriptionData);
    }

    if (typeof origDestData !== "undefined") {
      store.commit(`bookingValidation/setOrigDestData`,  OrigDest.parseData(origDestData))
    }
  }
}

export default Init