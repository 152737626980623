import Divolte from 'services/AirBaltic/Divolte'
import UUID_Utils from 'utils/UUID_Utils'
import conf from 'conf'

class Claims {
  static push (name, data) {
    if (conf.divolte) {
      Divolte.doDataPush(name, data)
    }
  }

  static divoltePush (state) {
    this.push('passengerDetails', {
      identity: {
        userUUID: UUID_Utils.create_UUID(),
        language: state.preferredLanguage.toUpperCase(),
        firstname: state.passengers.passengers[0].firstName,
        surname: state.passengers.passengers[0].lastName,
        email: state.contactEmail,
      }
    })
  }
}

export default Claims
