import AbstractView from 'abstract';
import Vue from 'vue'
import store from 'definitions/store'
import siloPageInit from 'initializations/pages/DestinationsSiloPage'

export class Main extends AbstractView {
    constructor(domElementId) {
        return super(domElementId);
    }

    init() {
        siloPageInit.init("destinationsSilo")

        const DestinationsSiloPage = () => import(/* webpackChunkName: "destinations-silo" */ 'containers/Pages/DestinationsSilo')

        new Vue({
            el: "#" + this.elemId,
            store,
            render: h => h(DestinationsSiloPage)
        })
    }
}
