import { maxLength, between, required } from 'vuelidate/lib/validators';
var OwnWheelChairRequest = /** @class */ (function () {
    function OwnWheelChairRequest() {
        this.form = {
            guidanceAtAirport: '',
            useOwnWheelchair: '',
            wheelchairType: '',
            weight: '',
            length: '',
            width: '',
            height: '',
            carryToSeat: '',
            carryToPlane: '',
            requestMessage: ''
        };
        this.messageMaxLength = 1500;
        this.errorMsg = {
            guidanceAtAirport: {
                required: 'forms.wheelchair.guidanceAtAirport.validation.required'
            },
            useOwnWheelchair: {
                required: 'forms.wheelchair.useOwnWheelchair.validation.required'
            },
            carryToSeat: {
                required: 'forms.wheelchair.carryToSeat.validation.required'
            },
            carryToPlane: {
                required: 'forms.wheelchair.carryToPlane.validation.required'
            },
            wheelchairType: {
                required: 'forms.wheelchair.carryToPlane.validation.required'
            },
            weight: {
                required: 'forms.wheelchair.weight.validation.required',
                between: 'forms.wheelchair.weight.validation.between'
            },
            length: {
                required: 'forms.wheelchair.length.validation.required',
                between: 'forms.wheelchair.length.validation.between'
            },
            height: {
                required: 'forms.wheelchair.height.validation.required',
                between: 'forms.wheelchair.height.validation.between'
            },
            width: {
                required: 'forms.wheelchair.width.validation.required',
                between: 'forms.wheelchair.width.validation.between'
            },
            requestMessage: {
                allowedCharacters: 'forms.wheelchair.requestMessage.validation.allowedCharacters',
                maxLength: 'forms.wheelchair.requestMessage.validation.maxLength'
            }
        };
        this.validation = {
            form: {
                guidanceAtAirport: {
                    required: required
                },
                useOwnWheelchair: {
                    required: required
                },
                carryToSeat: {
                    required: required
                },
                carryToPlane: {
                    required: required
                },
                wheelchairType: {
                    required: required
                },
                weight: {
                    required: required,
                    between: between(1, 999)
                },
                length: {
                    required: required,
                    between: between(1, 999)
                },
                height: {
                    required: required,
                    between: between(1, 999)
                },
                width: {
                    required: required,
                    between: between(1, 999)
                },
                requestMessage: {
                    maxLength: maxLength(this.messageMaxLength)
                }
            }
        };
    }
    return OwnWheelChairRequest;
}());
export default OwnWheelChairRequest;
