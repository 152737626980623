import IndexTabsStore from 'store/modules/Tabs/eHorizon/IndexTabs'
import CookieUtils from 'utils/CookieUtils'
import Pimcore from 'services/AirBaltic/Pimcore'

const Init = {
  init(store, name) {
    if (name && !store[name]) {
      store.registerModule('indexTabs', IndexTabsStore)

      Init.setActiveTab(store, name)
      Init.setTabUrls(store, name)
      Init.setRipTabs(store, name)
      Init.setRipMessage(store, name)
      Init.setBanners(store, name)
      Init.setCssUrl(store, name)
      Init.setInfoBanner(store, name)
      Init.setAnyDestinationButtonData(store, name)
    }
  },
  setBanners(store, name) {
    if (typeof tabsBackground !== 'undefined') {
      store.commit(`${name}/setBannersData`, tabsBackground)

      if (typeof enableMoodBanner !== "undefined") {
        Init.getMoodBanner(store, name)
      }
    }
  },
  getMoodBanner(store, name) {
    const recentSearchCookie = CookieUtils.getCookie('recentSearch')
    if (recentSearchCookie && typeof LANGUAGE !== 'undefined') {
      try {
        let cookie = JSON.parse(recentSearchCookie)
        if (cookie.length) {
          const destin = cookie[0].toCode
          const lang = LANGUAGE
          return new Pimcore().get(`/mood-banner/${lang}/${destin}`)
            .then((response) => {
              if (response.data) {
                const tabs = { ...tabsBackground }
                const flt = {
                  ...response.data,
                  title: tabsBackground['FLT'].title,
                  rip: tabsBackground['FLT'].rip
                }
                tabs['FLT'] = flt
                store.commit(`${name}/setBannersData`, tabs)
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  setInfoBanner(store, name) {
    if (typeof eHorizonInfoBanner !== 'undefined') {
      store.commit(`${name}/setInfoBanner`, eHorizonInfoBanner)
    }
  },
  setCssUrl(store, name) {
    if (typeof selfServiceUrl !== 'undefined') {
      store.commit(`${name}/setSelfServiceUrl`, selfServiceUrl)
    }

    if (typeof selfCKIUrl !== 'undefined') {
      store.commit(`${name}/setSelfCKIUrl`, selfCKIUrl)
    }
  },
  setActiveTab(store, name) {
    const validTabs = ['CKI', 'FLT', 'MMB']
    let tab = 'FLT'
    const cookie = CookieUtils.getCookie('activeTab').toUpperCase()

    if (typeof activeIndexTab !== 'undefined' &&
      validTabs.includes(activeIndexTab.toUpperCase())
    ) {
      tab = activeIndexTab
    } else if (cookie && validTabs.includes(cookie)) {
      tab = cookie
    }

    store.commit(`${name}/setActiveTab`, tab)
    CookieUtils.setCookie('activeTab', tab, 0)

    if (typeof openFsfOvl !== 'undefined') {
      store.commit(`${name}/setOpenFsfOvl`, openFsfOvl)
    }
  },
  setTabUrls(store, name) {
    if (typeof carRentTabLink !== 'undefined') {
      store.commit(`${name}/setCarsUrl`, carRentTabLink)
      store.commit(`${name}/setCarsUrlTarget`, carRentTabLinkTarget)
    }
    if (typeof bookingTabLink !== 'undefined') {
      store.commit(`${name}/setBookingUrl`, bookingTabLink)
      store.commit(`${name}/setBookingUrlTarget`, bookingTabLinkTarget)
    }
  },

  setRipTabs(store, name) {
    if (typeof eHorizonRipTabs !== 'undefined') {
      store.commit(`${name}/setRipTabs`, eHorizonRipTabs)
    }
  },

  setRipMessage(store, name) {
    if (typeof eHorizonRipTabTexts !== 'undefined') {
      store.commit(`${name}/setRipTexts`, eHorizonRipTabTexts)
    }
  },

  setAnyDestinationButtonData(store, name) {
    if (typeof anyDestinationButtonData !== 'undefined') {
      store.commit(`${name}/setAnyDestinationButtonData`, anyDestinationButtonData)
    }
  }
}

export default Init
