import isEmpty from 'lodash-es/isEmpty'
import has from 'lodash-es/has'
import Api from 'services/AirBaltic/Api'
import Pimcore from 'services/AirBaltic/Pimcore'
import ErrorHandler from 'utils/ErrorHandler'
import conf from 'conf'

const LOGIN_FAILED_GENERIC_ERROR = 'header.loginForm.errors.loginFailed'
const LOGIN_FAILED_WRONG_CREDENTIALS = 'header.loginForm.errors.unauthorized'
const LOGIN_FAILED_BLACKLISTED = 'header.loginForm.errors.blacklisted'
const LOGIN_FAILED_VIOLATION = 'header.loginForm.errors.violation'
const LOGIN_FAILED_IP_BLOCKED = 'header.loginForm.errors.ipBlocked'
const LOGIN_FAILED_NOT_CLEAN = {
  title: 'header.loginForm.errors.notCleanTitle',
  content: 'header.loginForm.errors.notCleanContent',
  type: 'informal'
}

const ERRORS = {
  PINS_UNKNOWN: 'pins.errors.pinsGeneric',
  WRONG_USERNAME_OR_PASSWORD: 'pins.errors.wrongUsernameOrPassword',
  USER_NAME_IN_BLACK_LIST: 'pins.errors.usernameInBlacklist',
  PASSWORD_SIZE: 'pins.errors.passwordSize',
  PASSWORD_NOTNULL: 'generic.errors.fieldEmpty',
  USERNAME_SIZE: 'pins.errors.usernameSize',
  USERNAME_NOTNULL: 'generic.errors.fieldEmpty',
  PINS_EMPTY: 'pins.errors.pinsNumberNotSet',
  PINS_NUMBER_EXIST: 'pins.errors.pinsNumberExists'
}

const privateMethods = {
  parseLoginErrorResponse(response) {
    if (!has(response, 'status') || !has(response, 'data.code')) {
      return LOGIN_FAILED_GENERIC_ERROR
    }

    if (response.status === 401 || response.status === 403) {
      return this.mapErrorCodeWithTranslationKey(response.data.code)
    }

    return LOGIN_FAILED_GENERIC_ERROR
  },

  mapErrorCodeWithTranslationKey(errorCode) {
    switch (errorCode) {
      case 'WRONG_USERNAME_OR_PASSWORD':
        return LOGIN_FAILED_WRONG_CREDENTIALS
      case 'USER_NAME_IN_BLACK_LIST':
        return LOGIN_FAILED_BLACKLISTED
      case 'PAX_RULES_VIOLATION':
        return LOGIN_FAILED_VIOLATION
      case 'IP_IN_BLACK_LIST':
        return LOGIN_FAILED_IP_BLOCKED
      case 'NOT_CLEAN_EMAIL_SENT':
        return LOGIN_FAILED_NOT_CLEAN
      default:
        return LOGIN_FAILED_GENERIC_ERROR
    }
  }
}

class Login {
  doApiNativeLogin(loginData) {
    return new Api()
      .post('/profile/login', loginData)
      .then((response) => Promise.resolve(response.data))
      .catch((error) =>
        Promise.reject(privateMethods.parseLoginErrorResponse(error.response))
      )
  }

  doApiNativeLoginV3(loginData) {
    loginData = {
      ...loginData,
      loginTokenType: conf.jwtLoginTokenType
    }
    return new Api()
      .post('/profile/v3/login', loginData)
      .then((response) => {
        const refreshToken = response.data.refreshToken
        window.localStorage.setItem('refreshToken', refreshToken)

        if (!response) {
          return Promise.reject(privateMethods.parseLoginErrorResponse(response))
        }
        return Promise.resolve(response)
      })
      .catch((error) => Promise.reject(privateMethods.parseLoginErrorResponse(error.response)))
  }

  doPimcoreLoginPing() {
    return Promise.resolve(true)
    // const token = CookieUtils.getCookie(conf.jwtCookieName)

    // if (isEmpty(token)) {
    //   return Promise.reject(['header.loginForm.errors.loginFailed'])
    // }

    // return new Pimcore()
    //   .post('/jwt-login' + conf.btUrlCors, { token })
    //   .then((response) => Promise.resolve(has(response, 'status')))
    //   .catch((error) => Promise.reject(error))
  }

  doPinsLogin(loginData) {
    return new Api()
      .post('/profile/pins/login', loginData)
      .then((response) => {
        const refreshToken = response.data.refreshToken
        window.localStorage.setItem('refreshToken', refreshToken)

        return Promise.resolve(response.data)
      })
      .catch((error) =>
        Promise.reject(ErrorHandler.getErrorTranslationKey(ERRORS, error.response))
      )
  }

  requestPasswordReset(formData) {
    return new Api().post('/profile/forgotPassword/sendEmail', formData)
  }

  requestPasswordResetV2(formData) {
    return new Api().post('/profile/v2/forgotPassword/sendEmail', formData)
  }

  resetPassword(formData) {
    return new Api().post('/profile/forgotPassword/confirm', formData)
  }

  isTokenExpired(token) {
    return new Api()
      .post('/profile/forgotPassword/validate', { token })
      .then(() => Promise.resolve(false))
      .catch(() => Promise.resolve(true))
  }
}

export default Login
