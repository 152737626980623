import SignUp from 'services/AirBaltic/Pax/SignUp'
import SignUpPage from 'models/Pax/SignUpPage'
import FormValidationUtils from 'utils/FormValidation'
import divolteProfile from 'services/AirBaltic/Divolte/Profile'
import gtmProfile from 'services/AirBaltic/GTM/Profile'

const signUpPage = new SignUpPage()
const defaultState = Object.assign({}, signUpPage, {
  form: { email: '' },
  formErrors: signUpPage.errorMsg
})
const validator = FormValidationUtils.initValidation(defaultState, signUpPage.validations)

const CreateProfile = {
  state: defaultState,
  getters: {
    $v() {
      return Object.assign({}, validator.$v.state.form)
    },
    validator() {
      return validator.$v.state
    },
    formErrors(state) {
      return state.formErrors
    }
  },
  mutations: {
    setPageTitle(state, value) {
      state.pageTitle = value
    },
    setBenefitsListTitle(state, value) {
      state.benefitsListTitle = value
    },
    setBenefitsList(state, value) {
      state.benefitsList = value
    },
    setModalTitle(state, value) {
      state.modalTitle = value
    },
    setModalContent(state, value) {
      state.modalContent = value
    },
    setModalErrors(state, value) {
      state.modalErrors = value
    },
    setPassword(state, value) {
      state.password = value
    },
    setEmail(state, value) {
      state.form.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setShowPassword(state, value) {
      state.showPassword = value
    },
    setEditMyProfileUrl(state, value) {
      state.editMyProfileUrl = value
    },
    setModalType(state, value) {
      state.modalType = value
    },
    setSignUpConfirmationUrl(state, value) {
      state.signUpConfirmationUrl = value
    },
    setRegisterPageUrl(state, value) {
      state.registerPage = value
    },
    setLoginUrl(state, value) {
      state.loginUrl = value
    },
    setLoaderBackground(state, value) {
      state.loaderBackground = value
    }
  },
  actions: {
    setEmail({ commit }, data) {
      commit('setEmail', data)
    },
    clearForm({ commit }) {
      validator.$v.state.$reset()

      commit('setEmail', '')
    },
    validate() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        return !vState.$invalid ? resolve() : reject()
      })
    },
    submitForm({ state, rootState }) {
      return new Promise((resolve, reject) => {
        const data = {
          email: state.form.email,
          language: rootState.main.selectedLang.toUpperCase(),
          profileCreateLink: state.registerPage,
          forgotPasswordResetLink: rootState.loginForm.forgotPassword.url
        }
        SignUp.sendCleanEmail(data)
          .then(() => {
            divolteProfile.register(state.form.email)
            gtmProfile.signUpAction()
            return resolve(true)
          })
          .catch((e) => reject(e))
      })
    },
    redirectToEditProfile({ state }) {
      window.location = state.editMyProfileUrl
    }
  },
  namespaced: true
}

export default CreateProfile
