import { required, email } from 'vuelidate/lib/validators'
import PaxLogin from 'services/AirBaltic/Pax/Login'
import ErrorHandler from 'utils/ErrorHandler'
import FormValidationUtils from 'utils/FormValidation'

const missingEmail = 'header.loginForm.forgotPassword.errors.missingEmail'
const invalidEmail = 'header.loginForm.forgotPassword.errors.invalidEmail'

const defaultState = {
  form: {
    email: ''
  },
  genericErrors: [],
  formErrors: {
    email: {
      required: missingEmail,
      email: invalidEmail
    }
  },
  url: '',
  emailIsSentFromLoginType: null
}

const ERRORS = {
  EMAIL_ALREADY_SENT: 'generic.errors.emailAlreadySent'
}

const validator = FormValidationUtils.initValidation(defaultState, {
  form: {
    email: {
      email,
      required
    }
  }
})

const ForgotPasswordStore = {
  state: defaultState,
  getters: {
    $v() {
      return Object.assign({}, validator.$v.state.form)
    },
    formErrors(state) {
      return state.formErrors
    }
  },
  mutations: {
    setEmail(state, value) {
      state.form.email = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setGenericErrors(state, errors) {
      state.genericErrors = errors
    },
    setUrl(state, value) {
      state.url = value
    },
    setEmailIsSentFromLoginType(state, value) {
      state.emailIsSentFromLoginType = value
    }
  },
  actions: {
    validateEmail() {
      return new Promise((resolve, reject) => {
        validator.$v.$touch()

        return !validator.$v.state.$invalid
          ? resolve(true)
          : reject('validateForgotPassword ')
      })
    },
    submitForm({ state, dispatch, rootState }, internalLogin) {
      return dispatch('validateEmail').then(() => {
        const language = rootState.main.selectedLang.toUpperCase()
        const data = {
          email: state.form.email,
          language,
          resetLink: state.url,
          profileCreateLink: registerPageUrl
        }

        let action = 'requestPasswordResetV2'

        if (!internalLogin) {
          action = 'requestPasswordReset'
          delete data.profileCreateLink
        }

        return dispatch(action, data)
      })
    },
    requestPasswordReset({ commit }, data) {
      return new PaxLogin().requestPasswordReset(data).catch((error) => {
        commit('setGenericErrors', [
          ErrorHandler.getErrorTranslationKey(ERRORS, error.response)
        ])
        return Promise.reject()
      })
    },
    requestPasswordResetV2({ commit }, data) {
      return new PaxLogin().requestPasswordResetV2(data).catch((error) => {
        commit('setGenericErrors', [
          ErrorHandler.getErrorTranslationKey(ERRORS, error.response)
        ])
        return Promise.reject()
      })
    },
    resetForm({ commit }) {
      commit('setEmail', '')
      commit('setGenericErrors', [])
    }
  },
  namespaced: true
}

export default ForgotPasswordStore
