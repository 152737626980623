class Dropdown {
  static filterData (constraint, data) {
    function titleCompare (a, b) {
      if (typeof a.title !== 'undefined') {
        return a.title.localeCompare(b.title)
      }
      return 0
    }

    if (!constraint) {
      return data.slice()
        .sort(titleCompare)
    }

    function escapeRegExp (str) {
      return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
    }

    const matchesTitle = []
    const dividers = '^| |[(]|/|-|\'|`'
    const substrRegex = new RegExp(`(${dividers})${escapeRegExp(constraint)}`, 'i')

    data.forEach((value) => {
      if (substrRegex.test(value.title)) {
        matchesTitle.push(value)
      }
    })

    return matchesTitle.sort(titleCompare)
  }
}

export default Dropdown
