import Api from 'services/AirBaltic/Api'
import ErrorHandler from 'utils/ErrorHandler'

const ERRORS = {
  GENERIC: 'payments.booking.form.errors.generic.invalid',
  FLIGHTDATE_DATEAFTERTODAYTYPE: 'reusable.flightDate.validation.dateAfterToday'
}

const endpoints = {
  checkedBaggageForm: '/contact/service/baggage',
  heavyCabinBaggageForm: '/contact/service/heavyCabinBaggage',
  sportEquipmentForm: '/contact/service/baggage'
}

class Payment {
  validatePnr(number, surname) {
    const data = { pnrRl: number, surname }

    return new Api()
      .get(`/pnr-retrieval/retrieve`, data)
      .then((response) => Promise.resolve(response))
      .catch(() => Promise.reject(ERRORS.GENERIC))
  }

  submitDefaultForm(data, form) {
    let url = '/contact/service/other'
    if (form === 'invoices') {
      url = '/contact/service/invoices'
      delete data.serviceType
      delete data.protectedBaggage
    }
    if (form === 'booking_cancellation') {
      url = '/contact/service/v3/cancelBooking'
      delete data.serviceType
      delete data.protectedBaggage
    }
    if (form === 'change_flight_date_time') {
      url = '/contact/service/changeFlightDate'
      delete data.serviceType
      delete data.protectedBaggage
    }

    return new Api()
      .post(url, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(this.getErrors(error.response)))
  }

  submitBaggageForm(data) {
    return new Api()
      .post(endpoints[data.form], data.data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(this.getErrors(error.response)))
  }

  submitExtrasForm(data) {
    return new Api()
      .post('/contact/service/ticketsAndServices', data.data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(this.getErrors(error.response)))
  }

  getErrors(response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default Payment
