import { maxLength, required } from 'vuelidate/lib/validators';
var AirportWheelChairRequest = /** @class */ (function () {
    function AirportWheelChairRequest() {
        this.form = {
            useAirportWheelchair: '',
            carryToSeat: '',
            carryToPlane: '',
            requestMessage: ''
        };
        this.messageMaxLength = 1500;
        this.errorMsg = {
            useAirportWheelchair: {
                required: 'forms.wheelchair.useAirportWheelchair.validation.required'
            },
            carryToSeat: {
                required: 'forms.wheelchair.carryToSeat.validation.required'
            },
            carryToPlane: {
                required: 'forms.wheelchair.carryToPlane.validation.required'
            },
            requestMessage: {
                allowedCharacters: 'forms.wheelchair.requestMessage.validation.allowedCharacters',
                maxLength: 'forms.wheelchair.requestMessage.validation.maxLength'
            }
        };
        this.validation = {
            form: {
                useAirportWheelchair: {
                    required: required
                },
                carryToSeat: {
                    required: required
                },
                carryToPlane: {
                    required: required
                },
                requestMessage: {
                    maxLength: maxLength(this.messageMaxLength)
                }
            }
        };
    }
    return AirportWheelChairRequest;
}());
export default AirportWheelChairRequest;
