import LoginStore from 'store/modules/Pages/ProfilePages/Login'
import store from 'definitions/store'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, LoginStore)

      Init.initDefaultValues(name)
    }
  },

  initDefaultValues(name) {
    if (typeof pageData !== "undefined") {
      store.commit(`${name}/setPageTitle`, pageData.title)
      store.commit(`${name}/setDescription`, pageData.description)
      store.commit(`${name}/setLinkText`, pageData.resetPasswordLinkText)
    }

    if (typeof  indexUrl !== "undefined") {
      store.commit(`${name}/setIndexUrl`, indexUrl)
    }

    if (typeof profilePageUrl !== 'undefined') {
      store.commit(`${name}/setProfilePageUrl`, profilePageUrl)
    }

    if (typeof profilePageUrlOld !== 'undefined') {
      store.commit(`${name}/setProfilePageUrlOld`, profilePageUrlOld)
    }
  }
}

export default Init
