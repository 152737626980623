<template>
  <div
    class="error-notification-modal"
    :class="{ icon: showIcon }"
  >
    <heading
      :level="2"
      class="title"
    >
      {{ errors.title }}
    </heading>
    <div
      v-dompurify-html="errors.content"
      class="content"
    />
    <div
      v-if="showButton"
      class="btn btn-blue"
    >
      <button
        type="button"
        @click="close"
      >
        {{ $t('buttons.modal.ok') }}
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    components: {
      Heading: () => import(/* webpackChunkName: "heading" */ 'components/Heading')
    },
    props: {
      errors: {
        type: Object,
        required: true
      },
      showButton: {
        type: Boolean,
        required: false,
        default: true
      },
      close: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    computed: {
      showIcon() {
        return this.errors?.showIcon ?? true
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .error-notification-modal {
    padding: 0 0 68px;
    text-align: center;

    @include bp-mf-tablet {
      padding: 84px 0;
      margin-left: 0;
    }

    &.icon {
      &::before {
        width: 100px;
        height: 100px;
        font-size: em(58);
        line-height: 100px;
        color: $red-error;
        content: $filled-icon-cross;
        background-color: $danger-bg;
        border-radius: 50%;

        @include filled-icon-font();

        @include bp-mf-tablet {
          width: 128px;
          height: 128px;
          line-height: 128px;
        }
      }
    }
  }

  .title {
    margin: 36px auto 18px;
    font-size: em(24);
    font-weight: 500;
    line-height: 28px;
    color: $blue-dark;

    @include bp-mf-tablet {
      margin-top: 28px;
      font-size: em(28);
      line-height: 33px;
    }
  }

  /deep/ p,
  .content {
    margin: 0 auto;
    font-size: em(14);
    color: $blue-dark;

    @include bp-mf-tablet {
      line-height: 21px;
    }
  }

  .content {
    margin-bottom: 32px;
  }
</style>
