import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import Step2Modal from 'models/Pax/CharterForm/Step2'

const step2Modal = new Step2Modal()
const formState = Object.assign({}, { formErrors: step2Modal.errorMsg }, step2Modal)

const validator = new Vue({
  mixins: [
    validationMixin
  ],
  computed: {
    state () {
      return formState
    }
  },
  validations () {
    return {
      state: step2Modal.validations
    }
  }
})

const Step2 = {
  namespaced: true,
  state: formState,
  mutations: {
    setAdditionalInfo (state, value) {
      state.form.information = value
    },
    setCitizenship (state, value) {
      state.form.citizenship = value
    },
    setPassengerCount (state, value) {
      state.form.passengers = value
    },
  },
  actions: {
    clearStep ({ commit }) {
      validator.$v.state.form.$reset()

      commit('setCitizenship', '')
      commit('setAdditionalInfo', '')
      commit('setPassengerCount', '')
    },
    validateStep () {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form
        vState.$touch()

        return !vState.$invalid ? resolve() : reject()
      })
    },
    getData ({ state }) {
      const data = {
        passengers: state.form.passengers,
        citizenship: state.form.citizenship,
        information: state.form.information,
      }

      return Promise.resolve(data)
    }
  },
  getters: {
    $v () {
      return Object.assign({}, validator.$v.state.form)
    },
    step2FormErrors (state) {
      return state.formErrors
    }
  }
}

export default Step2
