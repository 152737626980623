import moment from 'moment'
import Vue from 'vue'
import OrigDestUtils from 'store/utils/eHorizonOrigDestUtils'
import Origin from 'store/modules/eHorizon/SearchForm/Origin'
import isEmpty from 'lodash-es/isEmpty'

const defaultState = {
  pageData: {
    desktopImg: '',
    tabletImg: '',
    mobileImg: '',
    title: '',
    introText: '',
    subTitle: '',
    flightDetails: {
      book: {
        title: '',
        from: '',
        to: ''
      },
      travel: {
        title: '',
        from: '',
        to: ''
      }
    }
  },
  originList: [],
  selectedSorter: '',
  destinationFilter: '',
  originFilter: '',
  deadline: '',
  timerTitle: '',
  timerEnabled: false,
  clpOrigDestData: [],
  flightType: '',
  selectedSorting: '',
  defaultFlightType: '',
  termsAndConditions: {},
  iconType: '',
  hideImages: false,
  isOndCampaign: false,
  openAllHistograms: false
}

const cityCompare = (a, b) => a.city.localeCompare(b.city)
const priceCompare = (a, b) => {
  const aPrice = a.priceOW ? parseInt(a.priceOW) : parseInt(a.priceRT)
  const bPrice = b.priceOW ? parseInt(b.priceOW) : parseInt(b.priceRT)
  return aPrice - bPrice
}

const CampaignLandingPage = {
  modules: {
    origin: Origin
  },
  state: defaultState,
  mutations: {
    setPageData(state, value) {
      state.pageData = value
    },

    setOriginList(state, value) {
      state.originList = value
    },

    setSelectedSorter(state, value) {
      state.selectedSorter = value
    },

    setDefaultFlightType(state, value) {
      state.defaultFlightType = value
    },

    setClpOrigDestData(state, value) {
      state.clpOrigDestData = value
    },

    setFlightDetails(state, value) {
      state.flightDetails = value
    },

    setSelectedDestinationFilter(state, value) {
      state.destinationFilter = value
    },

    setOriginFilter(state, value) {
      state.originFilter = value
    },

    setDeadline(state, value) {
      state.deadline = value
    },

    setTimerTitle(state, value) {
      state.timerTitle = value
    },

    setTimerEnabled(state, value) {
      state.timerEnabled = value
    },

    setIntroText(state, value) {
      state.introText = value
    },

    setTitle(state, value) {
      state.title = value
    },

    setTermsAndConditions(state, value) {
      state.termsAndConditions = value
    },

    setIconType(state, value) {
      state.iconType = value
    },

    hideFilterBlock(state, value) {
      state.hideFilterBlock = value
    },

    updateSelectedTripType(state, value) {
      const flight = state.clpOrigDestData.find(
        (item) => item.originCode === value.origin && item.code === value.destin
      )
      const index = state.clpOrigDestData.indexOf(flight)
      flight.tripType = value.tripType
      Vue.set(state.clpOrigDestData, index, flight)
    },

    setHideImages(state, value) {
      state.hideImages = value
    },

    setIsOndCampaign(state, value) {
      state.isOndCampaign = value
    },

    setOpenAllHistograms(state, value) {
      state.openAllHistograms = value
    }
  },

  actions: {
    setDeadline({ commit }, data) {
      const date = moment(data.date)
      if (data.time) {
        date.set({
          hours: data.time.split(':')[0],
          minutes: data.time.split(':')[1]
        })
      }

      commit('setDeadline', date)
    },

    setSelectedSorter({ commit }, data) {
      commit('setSelectedSorter', data)
    },

    updateSelectedTripType({ commit }, value) {
      commit('updateSelectedTripType', value)
    }
  },

  getters: {
    airports(state) {
      const filterValue = state.origin?.typeahead?.filter

      if (!isEmpty(filterValue)) {
        const origins = state.originList
        const typeaheadIgnoreFilter = filterValue.length <= 0
        const matchedTranslation = Vue.i18n.translate(
          'searchForm.originDestin.origin.matchedOrigins'
        )

        return OrigDestUtils.addHeaderToAirportList(
          matchedTranslation,
          OrigDestUtils.filterData(filterValue, origins, typeaheadIgnoreFilter)
        )
      }

      return state.originList
    },
    destinations(state) {
      let origData = Object.assign([], state.clpOrigDestData)
      const selectedOrigin = state.origin?.value?.code
      if (selectedOrigin) {
        origData = origData.filter((item) => item.originCode === selectedOrigin)
      }

      return origData
    },
    filteredFlights(state, getters) {
      let destinations = Object.assign([], getters.destinations)

      if (state.destinationFilter) {
        destinations = destinations.filter((item) => {
          const filter = state.destinationFilter.toLowerCase()
          return (
            item.code.toLowerCase().indexOf(filter) !== -1 ||
            item.city.toLowerCase().indexOf(filter) !== -1 ||
            item.country.toLowerCase().indexOf(filter) !== -1 ||
            item.originCode.toLowerCase().indexOf(filter) !== -1 ||
            item.originCity.toLowerCase().indexOf(filter) !== -1 ||
            item.originCountry.toLowerCase().indexOf(filter) !== -1
          )
        })
      }

      switch (state.selectedSorter) {
        case 'cityAsc':
          destinations.sort(cityCompare)
          break
        case 'cityDesc':
          destinations.sort(cityCompare).reverse()
          break
        case 'priceAsc':
          destinations.sort(priceCompare)
          break
        case 'priceDesc':
          destinations.sort(priceCompare).reverse()
          break
      }

      return destinations
    }
  },
  namespaced: true
}

export default CampaignLandingPage
