import Service from 'services/AirBaltic/Forms/Payment'
import Company from 'models/Payments/Company'
import DefaultForm from 'models/Payments/Forms/Default'
import moment from 'moment'

const Default = {
  namespaced: true,
  actions: {
    validateAndSubmit ({ commit, dispatch, rootState }) {
      const activeItem = rootState.payment.generic.activeItem.key
      commit('payment/flightList/setCategory', 'payment', { root: true })

      const rules = [
        dispatch('payment/contact/validateContacts', null, { root: true }),
        dispatch('payment/request/validateRequests', null, { root: true }),
        dispatch('payment/company/validateCompany', null, { root: true }),
        dispatch('payment/flightList/touch', null, { root: true })
      ]

      if (activeItem === 'booking_cancellation') {
        rules.push(
          dispatch('payment/gvo/validateGvo', null, { root: true })
        )
      }

      return dispatch('payment/generic/validateForm', rules, { root: true })
        .then(() => dispatch('getDataAndSubmitForm'))
        .catch(err => Promise.reject(err))
    },
    getDataAndSubmitForm ({ dispatch }) {
      return dispatch('getData')
        .then((data) => dispatch('submitForm', data))
        .catch(err => Promise.reject({ type: 'submit', msg: err }))
    },
    submitForm({ dispatch, rootState }, data) {
      const activeItem = rootState.payment.generic.activeItem.key

      return new Service().submitDefaultForm(data, activeItem)
        .then(response => {
          return dispatch('payment/generic/clearForms', 'default', { root: true })
            .then(() => Promise.resolve(response))
            .catch(err => Promise.reject(err))
        })
        .catch(err => Promise.reject({ type: 'submit', msg: err }))
    },
    getData ({ dispatch }) {
      return dispatch('collectDefaultFormData')
        .then(data => Promise.resolve(data))
        .catch(err => Promise.reject(err))
    },
    collectDefaultFormData ({ rootState }) {
      const formData = new DefaultForm()

      const generic = rootState.payment
      const contact = generic.contact.form
      const request = generic.request.form
      const formType = generic.generic.activeItem.key

      if (formType === 'booking_cancellation') {
        const gvo = generic.gvo.form

        formData.gvoInsteadOfRefund = gvo.gvo === 'Yes'
      }

      formData.serviceType = generic.formName
      formData.language = generic.language
      formData.bookingRef = generic.bookingRef
      formData.protectedBaggage = generic.generic.protectedBaggage

      formData.title = contact.title
      formData.firstName = contact.firstName.trim()
      formData.lastName = contact.lastName.trim()
      formData.email = contact.email

      const flights = []
      delete formData.flightNumber
      delete formData.flightDate
      const showSelectedFlights = ['booking_cancellation', 'change_flight_date_time'].includes(formType)

      let flightData = generic.flightList.flights
      if (showSelectedFlights) {
        if (generic.flightList.selectedFlights.length) {
          flightData = generic.flightList.selectedFlights
        }

        flightData.forEach((flight) => {
          flights.push({
            flightNumber: flight.flightNumber.trim().toUpperCase(),
            flightDate: moment(`${flight.flightYear}-${flight.flightMonth}-${flight.flightDay}`, 'YYYY-MM-DD').format('YYYY-MM-DD')
          })
        })
        formData.flights = flights
      } else {
        formData.flightDate = moment(`${flightData[0].flightYear}-${flightData[0].flightMonth}-${flightData[0].flightDay}`, 'YYYY-MM-DD').format('YYYY-MM-DD')
        formData.flightNumber = flightData[0].flightNumber.trim().toUpperCase()
      }

      formData.message = request.message

      if (generic.company.showBillingDetails) {
        const company = generic.company
        const companyData = new Company().getDataForSending()

        companyData.name =  company.form.name
        companyData.registrationNumber =  company.form.registrationNumber
        companyData.vatNumber = company.form.vatNumber
        companyData.address = company.form.address
        companyData.city = company.form.city
        companyData.postalCode = company.form.postalCode
        companyData.countryCode = company.form.countryCode

        formData.company = companyData
      }

      return formData
    }
  }
}

export default Default
