import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { maxLength, required } from 'vuelidate/lib/validators'
import ErrorHandler from 'utils/ErrorHandler'
import conf from 'conf'
import axios from 'axios'
import every from 'lodash-es/every'
import flatMap from 'lodash-es/flatMap'

const formErrors = {
  files: {
    maxLength: 'reusable.fileUpload.validation.maxLength',
    required: 'reusable.fileUpload.validation.required',
    fileSize: 'claims.files.validation.fileSize',
    fileFormat: 'claims.files.validation.fileFormat',
    totalFileSize: 'reusable.fileUpload.validation.totalFileSize',
  }
}

const ERRORS = {
  WRONG_FILE_EXTENSION: 'generic.errors.wrongFileExtension',
  WRONG_OR_EXPIRED_FILE_UPLOAD_TOKEN: 'generic.errors.wrongOrExpiredUploadToken',
  REQUEST_ENTITY_TOO_LARGE: 'generic.errors.requestEntityTooLarge'
}

const defaultState = {
  files: [],
  oldFiles: [],
  formErrors: formErrors,
  token: '',
  submitBtnDisabled: false,
  isRequired: false,
  maxLength: 3,
  totalFileSize: 0,
  checkEachFileSize: true
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    const data = {
      files: {
        maxLength: maxLength(this.state.maxLength),
        required,
        $each: {
          fileSize: (value) => {
            if (!this.state.checkEachFileSize) {
              return true
            }
            return (value.size / 1000 / 1000) <= 5
          },
          fileFormat: (value) => {
            let format = value.name.split('.').slice(-1)[0]?.toLowerCase()
            return ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'bmp', 'edoc'].includes(format)
          }
        },
        totalFileSize: (value, state) => {
          let total = 0
          state.files.forEach(file => {
            total += file.size
          })

          return (total / 1000 / 1000) <= 15
        }
      }
    }
    if (!this.state.isRequired) {
      delete data.files.required
    }
    return { state: data }
  }
})

const FilesForm = {
  state: defaultState,
  mutations: {
    disableBtn(state, value) {
      state.submitBtnDisabled = value
    },
    addFiles(state, value) {
      state.files = state.files.concat(value)
    },
    removeFile(state, index) {
      state.files.splice(index, 1)
    },
    removeFailedFile(state, index) {
      state.oldFiles.splice(index, 1)
      const statuses = flatMap(state.oldFiles, 'status')
      state.submitBtnDisabled = !every(statuses, (o) => o === 'success')
    },
    removeAllFiles(state) {
      state.files = []
    },
    addOldFile(state, value) {
      state.oldFiles = state.oldFiles.concat(value)
    },
    setOldFiles(state, value) {
      state.oldFiles = value
    },
    setToken(state, value) {
      state.token = value
    },
    setIsRequired(state, value) {
      state.isRequired = value
    },
    setMaxLength(state, value) {
      state.maxLength = value
    },
    setTotalFileSize(state, value) {
      state.totalFileSize = value
    },
    setCheckEachFileSize(state, value) {
      state.checkEachFileSize = value
    },
    setRequiredMessage(state, value) {
      state.formErrors.files.required = value
    }
  },

  actions: {
    submitForm({ state, dispatch, commit }, additionalFiles = []) {
      return new Promise((resolve) => {
        commit('disableBtn', true)
        dispatch('touchEverything')
        const vState = validator.$v.state
        const hasError = flatMap(state.oldFiles, 'status')
        if (!vState.$invalid) {
          const parsedFiles = state.oldFiles.length
          dispatch('sendFiles', additionalFiles).then((data) => {
            if (
              Number(parsedFiles + data.parsedFiles) ===
              state.files.length + state.oldFiles.length
            ) {
              const mergedData = hasError.concat(data.hasError)
              const allValid = every(mergedData, (o) => o === 'success')
              commit('disableBtn', !allValid)

              return resolve(allValid)
            }
          })
        }
      })
    },
    clearForm({ commit }) {
      commit('setToken', '')
      commit('setOldFiles', [])
      commit('removeAllFiles')
    },
    touchFiles({state}) {
      const vState = validator.$v.state
      vState.$touch()

      state.files.forEach((file, index) => {
        let fValidation = validator.$v.state.files.$each[index]
        if (fValidation.$error) {
          file.status = 'error'
          let errorMsg = ''
          if (!fValidation.fileSize) {
            errorMsg = 'fileSize'
          }
          if (!fValidation.fileFormat) {
            errorMsg = 'fileFormat'
          }
          file.errorMsg = formErrors.files[errorMsg]
        } else {
          file.status = 'success'
        }
      })
    },

    touchEverything() {
      const vState = validator.$v.state
      vState.$touch()
    },

    touch() {
      return new Promise((resolve, reject) => {
        let vState = validator.$v.state
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('attachment-claims')
      })
    },

    sendFiles({ state, commit, dispatch }, additionalFiles = []) {
      const response = []
      let parsedFiles = 0
      const hasError = []
      let totalFiles = state.files.concat(additionalFiles)

      totalFiles.forEach((file) => {
        const action = dispatch('sendFile', file)
          .then(() => (file.status = 'success'))
          .catch((error) => {
            file.status = 'error'
            file.errorMsg = ErrorHandler.getErrorTranslationKey(ERRORS, error.response)
          })
          .then(() => {
            commit('addOldFile', file)
            commit('removeFile', state.files.indexOf(file))
            hasError.push(file.status)
            parsedFiles++
          })

        response.push(action)
      })

      return Promise.all(response)
        .then(() => {
          return Promise.resolve({
            parsedFiles,
            hasError
          })
        })
        .catch(() => {
          return Promise.resolve({
            parsedFiles,
            hasError
          })
        })
    },
    sendFile({ state }, file) {
      const formData = new FormData()
      formData.append('file', file)

      return axios.post(conf.btApiUrl + '/contact/file', formData, {
        headers: {
          'file-upload-token': state.token,
          'Content-Type': 'multipart/form-data'
        }
      })
    }
  },

  getters: {
    validation() {
      return Object.assign({}, validator.$v.state)
    }
  },

  namespaced: true
}

export default FilesForm
