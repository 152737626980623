import { betweenLength, isValidDate, regex } from 'utils/validators';
import { required } from 'vuelidate/lib/validators';
var id = 0;
var minLength = 3;
var maxLength = 6;
var Flight = /** @class */ (function () {
    function Flight(obj) {
        this.flightNumber = '';
        this.flightDate = '';
        this.id = id;
        this.origin = '';
        this.destin = '';
        this.errorMsg = {
            flightDate: {
                isValidDate: 'reusable.flightDate.validation.invalid',
                isValidYear: 'reusable.flightDate.validation.invalidYear',
                required: 'reusable.flightDate.validation.required',
            },
            flightNumber: {
                between: 'reusable.flightNumber.validation.between',
                flightNumberInvalid: 'reusable.flightNumber.validation.invalid',
                required: 'reusable.flightNumber.validation.required',
            },
            selectedFlights: {
                checked: 'reusable.checked.validation.isTrue',
            },
        };
        this.validation = {
            flights: {
                $each: {
                    flightDate: {
                        isValidYear: function (date) {
                            var _a;
                            if (!date)
                                return true;
                            return (_a = (date === null || date === void 0 ? void 0 : date.year()) >= 2013) !== null && _a !== void 0 ? _a : true;
                        },
                        isValidDate: isValidDate,
                        required: required,
                    },
                    flightNumber: {
                        between: betweenLength(minLength, maxLength),
                        flightNumberInvalid: regex('flightNumber'),
                        required: required,
                    },
                },
            },
            selectedFlights: {
                checked: function (value) { return value.length; },
            },
        };
        if (typeof obj !== 'undefined') {
            this.parseData(obj);
        }
        id++;
    }
    Flight.prototype.parseData = function (data) {
        this.flightNumber = data.flightNumber.trim();
        this.flightDate = data.flightDate;
        this.origin = data.origin;
        this.destin = data.destin;
    };
    return Flight;
}());
export default Flight;
