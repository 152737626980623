var PnrValidationSection = /** @class */ (function () {
    function PnrValidationSection(obj) {
        if (obj === void 0) { obj = {}; }
        this.bookingTitle = '';
        this.bookingDescription = '';
        this.infoBoxDescription = '';
        this.infoBoxCTA = '';
        Object.assign(this, obj);
    }
    return PnrValidationSection;
}());
export default PnrValidationSection;
