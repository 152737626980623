import ForgotPassWordState from 'store/modules/Header/ForgotPassword'
import PaxLogin from 'services/AirBaltic/Pax/Login'
import CookieUtils from 'utils/CookieUtils'
import isObject from 'lodash-es/isObject'
import divolteProfile from 'services/AirBaltic/Divolte/Profile'
import gtmProfile from 'services/AirBaltic/GTM/Profile'
import FormValidationUtils from 'utils/FormValidation'
import LoginValidation from 'models/Pax/Login'
import get from 'lodash-es/get'

const loginValidation = new LoginValidation()

const formData = {
  genericErrors: [],
  isUserLogged: false,
  userData: {},
  form: loginValidation.form,
  formErrors: loginValidation.errorMsg,
  errors: loginValidation.errors,
  isLoginPage: false
}

const defaultState = Object.assign({}, formData)
const validator = FormValidationUtils.initValidation(
  defaultState,
  loginValidation.validations
)

const LoginStore = {
  modules: {
    forgotPassword: ForgotPassWordState
  },
  state: defaultState,
  getters: {
    $v() {
      return Object.assign({}, validator.$v.state.form)
    },
    loginFormErrors(state) {
      return state.formErrors
    }
  },
  mutations: {
    setEmail(state, email) {
      state.form.email = typeof email === 'string' ? email.replace(/ /g, '') : ''
    },
    setPass(state, value) {
      state.form.password = value
    },
    setGenericErrors(state, errors) {
      state.genericErrors = errors
    },
    setIsLoginPage(state, value) {
      state.isLoginPage = value
    }
  },
  actions: {
    setGenericErrors({ commit }, value) {
      const errors = !isObject(value) ? [`${value}`] : value
      commit('setGenericErrors', errors)
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        validator.$v.$touch()

        return !validator.$v.state.$invalid ? resolve(true) : reject('validateForm')
      })
    },
    resetForm({ commit }) {
      commit('setEmail', '')
      commit('setPass', '')
      commit('setGenericErrors', [])
      validator.$v.state.form.$reset()
    },
    doNativeLoginDataLayerPush({ state }) {
      CookieUtils.setCookie('loginMethod', 'email', 1)
      const page = state.isLoginPage ? 'login_page' : 'bt_pages'

      gtmProfile.loginSuccessAction('email', page)
      gtmProfile.loginEventEmail()
    },
    setUserData({ dispatch }, data) {
      const storeData = {
        title: data.title ? data.title : '',
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        pinsNumber: data.pinsNumber,
        img: '',
        clean: get(data, 'clean', false)
      }

      dispatch('main/user/init', storeData, { root: true })
      divolteProfile.login(storeData)
    },

    doNativeLogin({ dispatch }, data) {
      const PaxLoginService = new PaxLogin()
      let paxUserData = {}

      return PaxLoginService.doApiNativeLogin(data)
        .then((userData) => {
          paxUserData = userData.userData
          return PaxLoginService.doPimcoreLoginPing()
        })
        .then(() => {
          return Promise.resolve(dispatch('dispatchSuccessEvent', paxUserData))
        })
        .catch((error) => {
          dispatch('dispatchErrors', error)
          return Promise.reject(error)
        })
    },

    doNativeLoginV3({ dispatch, rootState }, data) {
      const PaxLoginService = new PaxLogin()
      let paxUserData = {}

      return PaxLoginService.doApiNativeLoginV3(data)
        .then((response) => {
          const redirectUrl = rootState.main.registerPageUrl
          const token = response.data.profileCreateToken

          if (response.status === 206) {
            return Promise.reject({
              type: 'informal',
              showHeader: true,
              title: 'profileClean.infoTitle',
              content: 'profileClean.infoContent',
              btnText: 'profileClean.btnText',
              close: () => (window.location.href = `${redirectUrl}?token=${token}`)
            })
          }

          paxUserData = response.data.userData
          return PaxLoginService.doPimcoreLoginPing()
        })
        .then(() => Promise.resolve(dispatch('dispatchSuccessEvent', paxUserData)))
        .catch((error) => {
          dispatch('dispatchErrors', error)
          return Promise.reject(error)
        })
    },
    dispatchSuccessEvent({ dispatch, commit, rootState }, paxUserData) {
      dispatch('setUserData', paxUserData)
      dispatch('doNativeLoginDataLayerPush')
      commit('header/closeAllPreHeaderItems', false, { root: true })
      dispatch('resetForm')

      const data = {
        redirectUrl: ''
      }

      if (typeof rootState.login !== 'undefined') {
        data.redirectUrl = get(paxUserData, 'clean', false)
          ? rootState.login.profilePageUrl
          : rootState.login.profilePageUrlOld
      }

      return data
    },
    dispatchErrors({ dispatch }, error) {
      if (error.title) {
        dispatch('setGenericErrors', [`${error.title}`])
      } else {
        dispatch('setGenericErrors', [`${error}`])
      }
      return Promise.reject(error)
    },
    submitForm({ dispatch, state, rootState }, internal) {
      const data = {
        userName: state.form.email,
        password: state.form.password,
        lang: rootState.main.selectedLang.toUpperCase()
      }

      let action = 'doNativeLoginV3'

      if (!internal) {
        action = 'doNativeLogin'
      }

      return dispatch(action, data)
    }
  },
  namespaced: true
}

export default LoginStore
