import AbstractView from 'abstract';
import Vue from 'vue'
import businessTravelInit from 'initializations/pages/Pax/BusinessTravel'
import SidebarInit from 'initializations/Sidebar'
import store from 'definitions/store'

export class Main extends AbstractView {
    constructor(domElementId) {
      return super(domElementId);
    }

    init() {
      SidebarInit.init("sidebar")
      businessTravelInit.init("businessTravel")
      const businessTravel = () => import(/* webpackChunkName: "business-travel-page" */ 'containers/Pages/Profile/BusinessTravel')

      new Vue({
        el: "#" + this.elemId,
        store,
        render: h => h(businessTravel)
      })
    }
}
