import FormNotification from 'components/Notifications/FormNotification'
import SingleReleaseStore from 'store/modules/Pages/PressReleases/SingleRelease'
import store from 'definitions/store'
import PressReleasesService from 'services/AirBaltic/PressReleases'

const Init = {
  init(name) {
    if (!store[name]) {
      store.registerModule(name, SingleReleaseStore)
      Init.initDefaultValues(name)
    }
  },
  initDefaultValues(name) {
    if (typeof singleReleaseInit !== "undefined") {
      if (singleReleaseInit) {
        new PressReleasesService().getSingleRelease(singleReleaseInit.releaseId, store.state.main.selectedLang)
          .then((data) => store.commit(`${name}/setRelease`, data.release))
          .catch((e) => {
            store.commit(`${name}/setRelease`, null)
            this.openModal(e)
          })
      }
    }

    if (typeof pressContacts !== 'undefined') {
      store.commit('sidebar/setPressContacts', pressContacts)
    }

    if (typeof noteSnippet !== 'undefined') {
      store.commit(`sidebar/setPleaseNoteTitle`, noteSnippet.title)
      store.commit(`sidebar/setPleaseNoteText`, noteSnippet.text)
    }
  },
  openModal(error) {
    store.dispatch('main/openModal')
    store.commit('main/setModalContent', FormNotification)
    store.commit('main/setModalCloseFn', this.closeModal)
    store.commit('main/setModalType', 'small')
    store.commit('main/setModalProps', {
      status: 'fail',
      errorMessage: `${error}`
    })

    store.commit('main/setModalClass', 'modal-white-small')
    store.commit('main/setShowCloseIcon', true)
    store.commit('main/setShowModalHeader', false)
  },
  closeModal () {
    store.dispatch('main/closeModal', { root: true })
  }
}

export default Init
