import filter from 'lodash-es/filter'
import isEmpty from 'lodash-es/isEmpty'
import OrigDestUtils from 'store/utils/eHorizonOrigDestUtils'
import UrlParams from 'utils/UrlParams'
import Vue from 'vue'
import FormValidationUtils from 'utils/FormValidation'
import LAD from 'models/LAD'
const ladModel = new LAD()
import FlightData from 'services/AirBaltic/FlightsData'
import head from 'lodash-es/head'

const DEFAULT_AIRPORT = 'RIX'
const defaultState = {
  allOrigins: {},
  btOrigins: [],
  airport: {},
  filter: '',
  value: '',
  type: 'dest',
  form: ladModel.form,
  formErrors: ladModel.errorMsg
}

const validator = FormValidationUtils.initValidation(defaultState, ladModel.validation)

const search = {
  state: defaultState,
  mutations: {
    setAllAirportData (state, value) {
      state.allOrigins = value
    },
    setBtOrigins (state, value) {
      state.btOrigins = value
    },
    changeAirport (state, value) {
      state.airport = value
    },
    setFilter (state, filter) {
      state.filter = filter
    },
    changeSearchType (state, value) {
      state.type = value
    },
    changeNumber (state, value) {
      state.form.number = value
    }
  },

  actions: {
    clearErrors ({commit}) {
      validator.$v.state.$reset()

      commit('changeNumber', '')
    },
    validateAndSubmit({ dispatch }) {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state

        vState.$touch()

        if (!vState.$invalid) {
          return dispatch('submitForm')
            .then(() => resolve())
            .catch((e) => reject({ type: 'submit', msg: e}))
        } else {
          return reject({ type: 'validation', msg: 'lad search validation failed'})
        }
      })
    },
    submitForm ({ commit, state }) {
      return new Promise((resolve, reject) => {

        const number = state.form.number.replace(/^(BT0+)/gi, 'BT')

        new FlightData().searchByNumber(number)
          .then((res) => {
            const origin = head(Object.values(res))[0].orig;
            const airport = state.allOrigins[`${origin}A`];
            if (typeof origin !== "undefined" && typeof airport !== "undefined") {
              commit('lad/setAirport', airport, { root: true })
            }
            commit('lad/setFlightData', res, { root: true })
            return resolve()
          })
          .catch((e) => reject(e))
      })
    },
    setOrigDestData ({ commit, state }, origDestData) {
      const btOrigins = OrigDestUtils.prepareData(filter(origDestData, o => o.isBtOrig))

      commit('setAllAirportData', origDestData)
      commit('setBtOrigins', OrigDestUtils.sortByCity(btOrigins))
      commit('changeAirport', btOrigins.find(o => o.code === DEFAULT_AIRPORT))

      const forcedOrigin = UrlParams.getUrlParam(window.location.href, 'flying_from')

      const defaultAirport = btOrigins.find(o => o.code === DEFAULT_AIRPORT)
      const forcedAirport = btOrigins.find(o => o.code === forcedOrigin)

      if (!isEmpty(forcedOrigin) && !isEmpty(forcedAirport) && !state.originInitFromUlrHappened) {
        commit('changeAirport', forcedAirport)
      } else {
        commit('changeAirport', defaultAirport)
      }
    }
  },
  getters: {
    airports (state) {
      const filter = state.filter

      if (!isEmpty(filter)) {
        const origins = state.btOrigins
        const typeaheadIgnoreFilter = state.filter.length <= 0
        const matchedTranslation = Vue.i18n.translate('searchForm.originDestin.origin.matchedOrigins')

        return OrigDestUtils.addHeaderToAirportList(matchedTranslation,
          OrigDestUtils.filterData(filter, origins, typeaheadIgnoreFilter)
        )
      }

      return state.btOrigins
    },
    validator () {
      return validator.$v.state
    },
    $vSearch () {
      return Object.assign({}, validator.$v.state.form)
    },
    formErrors (state) {
      return state.formErrors
    }
  },

  namespaced: true
}

export default search
